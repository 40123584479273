define("mobile-web/components/r-disclaimers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cDrJCyWN",
    "block": "[[[11,\"aside\"],[16,0,[29,[[28,[37,0],[\"root\"],[[\"from\"],[\"mobile-web/components/r-disclaimers/styles\"]]]]]],[24,\"data-test-disclaimers\",\"\"],[17,1],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,2]],null]],null],null,[[[1,\"    \"],[8,[39,3],null,[[\"@class\",\"@collapsible\"],[[28,[37,0],[\"disclaimer\"],[[\"from\"],[\"mobile-web/components/r-disclaimers/styles\"]]],true]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,3,[\"icon\"]],[[[1,\"        \"],[8,[39,5],null,[[\"@icon\"],[[30,3,[\"icon\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[1,[28,[35,6],[[30,3,[\"content\"]]],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[3]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"@disclaimers\",\"disclaimer\"],false,[\"local-class\",\"each\",\"-track-array\",\"r-inline-alert\",\"if\",\"r-custom-icon\",\"html-safe\"]]",
    "moduleName": "mobile-web/components/r-disclaimers/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});