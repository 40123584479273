define("mobile-web/services/reorder", ["exports", "ember-concurrency-ts"], function (_exports, _emberConcurrencyTs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var ReorderType;

  (function (ReorderType) {
    ReorderType[ReorderType["Favorite"] = 0] = "Favorite";
    ReorderType[ReorderType["Order"] = 1] = "Order";
    ReorderType[ReorderType["OrderSearchResult"] = 2] = "OrderSearchResult";
  })(ReorderType || (ReorderType = {}));

  let ReorderService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class ReorderService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      _initializerDefineProperty(this, "bus", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "storage", _descriptor4, this);
    }

    reorder(target, options = {}) {
      const result = this._parse(target);

      if (this._shouldConfirm(result)) {
        this._confirm(result, options);
      } else {
        this._reorder(result, options);
      }
    }

    _parse(target) {
      return 'orderGuid' in target ? 'orderSummary' in target ? {
        type: ReorderType.OrderSearchResult,
        order: target,
        slug: target.vendorSlug,
        intlPrefix: 'mwc.recentOrders.reorder'
      } : {
        type: ReorderType.Order,
        order: target,
        slug: target.vendor.slug,
        intlPrefix: 'mwc.recentOrders.reorder'
      } : {
        type: ReorderType.Favorite,
        favorite: target,
        slug: target.vendorSlug,
        intlPrefix: 'mwc.favorites.reorder'
      };
    }

    _shouldConfirm(result) {
      const lastSlug = this.storage.lastVendorSlug;
      return lastSlug && lastSlug !== result.slug || !!this.basket.basket;
    }

    _confirm(result, options) {
      const content = this._content(result);

      this.bus.trigger('confirm', {
        title: this.intl.t(`${result.intlPrefix}.confirmTitle`),
        content,
        buttonLabel: this.intl.t(`${result.intlPrefix}.confirmButton`),
        onConfirm: () => this._reorder(result, options),
        testSelector: 'confirmReorder',
        buttonTestSelector: 'confirmButton'
      });
    }

    _content(result) {
      var _basket$vendor, _basket$vendor2;

      const basket = this.basket.basket;
      const intlSuffix = basket ? ((_basket$vendor = basket.vendor) == null ? void 0 : _basket$vendor.get('slug')) === result.slug ? 'confirmBasketText' : 'confirmBasketDifferentVendorText' : 'confirmDifferentVendorText';
      const basketVendor = basket == null ? void 0 : (_basket$vendor2 = basket.vendor) == null ? void 0 : _basket$vendor2.get('name');
      const intlOptions = result.type === ReorderType.Favorite ? {
        basketVendor,
        favoriteVendor: result.favorite.vendorName
      } : {
        basketVendor,
        orderVendor: result.order.vendorName,
        orderSummary: result.type === ReorderType.Order ? result.order.basketProducts.map(bp => bp.productName).join(', ') : result.order.orderSummary
      };
      return this.intl.t(`${result.intlPrefix}.${intlSuffix}`, intlOptions);
    }

    _reorder(result, options) {
      if (result.type === ReorderType.Favorite) {
        (0, _emberConcurrencyTs.taskFor)(this.basket.orderFavoriteTask).perform(result.favorite);
      } else {
        (0, _emberConcurrencyTs.taskFor)(this.basket.reorderTask).perform(result.order, options.index);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "bus", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ReorderService;
});