define("mobile-web/lib/utilities/page-down", ["exports", "true-myth", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/keys"], function (_exports, _trueMyth, _, _keys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setUp = setUp;
  _exports.tearDown = tearDown;
  _exports.default = void 0;

  function setUp() {
    const selector = '[data-focusWhen="PageDown"]';

    const focusMainAction = _trueMyth.Maybe.of(document.querySelector(selector)).match({
      Just: el => () => el.focus(),
      Nothing: () => _.noop
    });

    const pageDownHandler = (0, _keys.bindActionToKeyEvent)(_keys.Key.PageDown, focusMainAction);
    document.addEventListener('keydown', pageDownHandler);
    return pageDownHandler;
  }

  function tearDown(handler) {
    document.removeEventListener('keydown', handler);
  }

  const PageDown = {
    setUp,
    tearDown
  };
  var _default = PageDown;
  _exports.default = _default;
});