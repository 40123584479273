define("mobile-web/lib/utilities/remove-scripts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.removeScripts = removeScripts;
  const eventHandlerAttributes = ['onabort', 'onanimationcancel', 'onanimationend', 'onanimationiteration', 'onanimationstart', 'onauxclick', 'onblur', 'onerror', 'onfocus', 'oncancel', 'oncanplay', 'oncanplaythrough', 'onchange', 'onclick', 'onclose', 'oncontextmenu', 'oncuechange', 'ondblclick', 'ondrag', 'ondragend', 'ondragenter', 'ondragexit', 'ondragleave', 'ondragover', 'ondragstart', 'ondrop', 'ondurationchange', 'onemptied', 'onended', 'onformdata', 'ongotpointercapture', 'oninput', 'oninvalid', 'onkeydown', 'onkeypress', 'onkeyup', 'onload', 'onloadeddata', 'onloadedmetadata', 'onloadend', 'onloadstart', 'onlostpointercapture', 'onmousedown', 'onmouseenter', 'onmouseleave', 'onmousemove', 'onmouseout', 'onmouseover', 'onmouseup', 'onmousewheel', 'onwheel', 'onpause', 'onplay', 'onplaying', 'onpointerdown', 'onpointermove', 'onpointerup', 'onpointercancel', 'onpointerover', 'onpointerout', 'onpointerenter', 'onpointerleave', 'onpointerlockchange', 'onpointerlockerror', 'onprogress', 'onratechange', 'onreset', 'onresize', 'onscroll', 'onseeked', 'onseeking', 'onselect', 'onselectstart', 'onselectionchange', 'onshow', 'onstalled', 'onsubmit', 'onsuspend', 'ontimeupdate', 'onvolumechange', 'ontouchcancel', 'ontouchend', 'ontouchmove', 'ontouchstart', 'ontransitioncancel', 'ontransitionend', 'ontransitionrun', 'ontransitionstart', 'onwaiting'];
  const eventHandlerAttributesSelector = `[${eventHandlerAttributes.join('], [')}]`;

  function removeEventHandlers(el) {
    var _el$getAttribute;

    eventHandlerAttributes.forEach(attr => {
      el.removeAttribute(attr);
    });

    if ((_el$getAttribute = el.getAttribute('href')) != null && _el$getAttribute.includes('javascript')) {
      el.removeAttribute('href');
    }
  }

  function removeElement(el) {
    var _el$parentElement;

    (_el$parentElement = el.parentElement) == null ? void 0 : _el$parentElement.removeChild(el);
  }

  function removeScripts(s) {
    const div = document.createElement('div');
    div.innerHTML = s; // Remove and script or form tags (which could be used to post content to a remote site)

    div.querySelectorAll('script, form').forEach(removeElement); // Remove any sneaky JS

    div.querySelectorAll(`[href*="javascript:"], ${eventHandlerAttributesSelector}`).forEach(removeEventHandlers);
    return div.innerHTML;
  }
});