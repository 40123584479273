define("mobile-web/services/notifications", ["exports", "ember-object-update", "mobile-web/lib/utilities/_"], function (_exports, _emberObjectUpdate, _) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.NotificationType = _exports.NotificationLevel = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let NotificationLevel;
  _exports.NotificationLevel = NotificationLevel;

  (function (NotificationLevel) {
    NotificationLevel[NotificationLevel["Success"] = 0] = "Success";
    NotificationLevel[NotificationLevel["Info"] = 1] = "Info";
    NotificationLevel[NotificationLevel["Warning"] = 2] = "Warning";
    NotificationLevel[NotificationLevel["Error"] = 3] = "Error";
  })(NotificationLevel || (_exports.NotificationLevel = NotificationLevel = {}));

  let NotificationType;
  _exports.NotificationType = NotificationType;

  (function (NotificationType) {
    NotificationType[NotificationType["ProductAdded"] = 0] = "ProductAdded";
    NotificationType[NotificationType["ProductUpdated"] = 1] = "ProductUpdated";
    NotificationType[NotificationType["ProductRemoved"] = 2] = "ProductRemoved";
    NotificationType[NotificationType["UpsellAdded"] = 3] = "UpsellAdded";
    NotificationType[NotificationType["CartTransferred"] = 4] = "CartTransferred";
  })(NotificationType || (_exports.NotificationType = NotificationType = {}));

  let NotificationsService = (_dec = Ember.computed.readOnly('_notifications'), (_class = class NotificationsService extends Ember.Service {
    constructor(...args) {
      super(...args);
      this._notifications = [];

      _initializerDefineProperty(this, "notifications", _descriptor, this);
    }

    dismiss(notification) {
      (0, _emberObjectUpdate.default)(this, '_notifications', notifications => (0, _.filter)(notifications, n => n !== notification));
    }

    success({
      message,
      meta,
      type
    }) {
      const msg = {
        level: NotificationLevel.Success,
        message,
        meta,
        type
      };
      (0, _emberObjectUpdate.default)(this, '_notifications', notifications => notifications.concat(msg));
      return msg;
    }

    info({
      message,
      meta,
      type
    }) {
      const msg = {
        level: NotificationLevel.Info,
        message,
        meta,
        type
      };
      (0, _emberObjectUpdate.default)(this, '_notifications', notifications => notifications.concat(msg));
      return msg;
    }

    warning({
      message,
      meta,
      type
    }) {
      const msg = {
        level: NotificationLevel.Warning,
        message,
        meta,
        type
      };
      (0, _emberObjectUpdate.default)(this, '_notifications', notifications => notifications.concat(msg));
      return msg;
    }

    error({
      message,
      meta,
      type
    }) {
      const msg = {
        level: NotificationLevel.Error,
        message,
        meta,
        type
      };
      (0, _emberObjectUpdate.default)(this, '_notifications', notifications => notifications.concat(msg));
      return msg;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = NotificationsService;
});