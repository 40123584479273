define("mobile-web/components/r-cart/upsell-group/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "ember-animated/transitions/fade", "ember-concurrency", "ember-concurrency-ts", "mobile-web/components/r-cart/upsell-item/component", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some", "mobile-web/services/analytics", "mobile-web/services/global-data", "mobile-web/services/global-events", "mobile-web/services/notifications", "mobile-web/components/r-cart/upsell-group/style"], function (_exports, _glimmerComponent, _fade, _emberConcurrency, _emberConcurrencyTs, _component, _, _isSome, _analytics, _globalData, _globalEvents, _notifications, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CartUpsellGroup = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class CartUpsellGroup extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "basket", _descriptor2, this);

      _initializerDefineProperty(this, "features", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "store", _descriptor5, this);

      _initializerDefineProperty(this, "notifications", _descriptor6, this);

      _initializerDefineProperty(this, "globalEvents", _descriptor7, this);

      this.transition = _fade.default;
      this.style = _style.default;

      _initializerDefineProperty(this, "rootElement", _descriptor8, this);

      _initializerDefineProperty(this, "listElement", _descriptor9, this);

      this.addUpsellItemTask = (0, _emberConcurrencyTs.taskFor)(this.addUpsellItemTaskInstance);
    }

    // Getters and setters
    get isScrollable() {
      return (0, _isSome.default)(this.listElement) && this.listElement.scrollWidth > this.listElement.clientWidth;
    } // Constructor
    // Other methods
    // Tasks


    *addUpsellItemTaskInstance(upsellItem) {
      yield this.basket.basket.addUpsellItem(upsellItem);
      const product = this.store.peekRecord('product', upsellItem.id);

      if (product) {
        this.globalEvents.trigger(_globalEvents.GlobalEventName.ClickProductLink, _extends({}, product.serializeForGlobalData(), {
          hasImages: (0, _isSome.default)(upsellItem.image),
          hasPrice: true
        }), _globalData.ProductClickFrom.CartUpsell);
      }

      this.analytics.trackEvent(_analytics.AnalyticsEvents.AddToCart, () => {
        var _product$category, _upsellItem$labels, _product$category2, _product$isFeatured;

        return {
          [_analytics.AnalyticsProperties.ProductName]: upsellItem.name,
          [_analytics.AnalyticsProperties.ProductCategory]: product == null ? void 0 : (_product$category = product.category) == null ? void 0 : _product$category.name,
          [_analytics.AnalyticsProperties.ProductQuantity]: 1,
          [_analytics.AnalyticsProperties.ProductBasePrice]: upsellItem.cost,
          [_analytics.AnalyticsProperties.AddToCartMethod]: 'Upsell',
          [_analytics.AnalyticsProperties.HasVisibleCalories]: (0, _isSome.default)(upsellItem.calorieLabel),
          [_analytics.AnalyticsProperties.VisibleLabels]: (_upsellItem$labels = upsellItem.labels) == null ? void 0 : _upsellItem$labels.map(l => l.name),
          [_analytics.AnalyticsProperties.HasProductImages]: (0, _isSome.default)(upsellItem.image),
          [_analytics.AnalyticsProperties.HasCategoryImages]: !(0, _.isEmpty)(product == null ? void 0 : (_product$category2 = product.category) == null ? void 0 : _product$category2.images),
          [_analytics.AnalyticsProperties.IsFeatured]: (_product$isFeatured = product == null ? void 0 : product.isFeatured) != null ? _product$isFeatured : false
        };
      });
      const message = this.intl.t('mwc.notifications.added', {
        quantity: upsellItem.quantity
      });
      this.notifications.success({
        message,
        type: _notifications.NotificationType.UpsellAdded
      });
    } // Actions


    scroll(direction) {
      if ((0, _isSome.default)(this.listElement)) {
        let newScrollLeft = this.listElement.scrollLeft;

        if (direction === 'left') {
          newScrollLeft -= _component.UPSELL_ITEM_BLOCK;
          const mod = newScrollLeft % _component.UPSELL_ITEM_BLOCK;

          if (mod > 0) {
            newScrollLeft += _component.UPSELL_ITEM_BLOCK - mod;
          }
        } else {
          newScrollLeft += _component.UPSELL_ITEM_BLOCK;
          const mod = newScrollLeft % _component.UPSELL_ITEM_BLOCK;

          if (mod > 0) {
            newScrollLeft -= mod;
          }
        }

        this.listElement.scrollLeft = newScrollLeft;
      }
    }

    addUpsellItem(upsellItem) {
      this.addUpsellItemTask.perform(upsellItem);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "features", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "globalEvents", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "rootElement", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "listElement", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addUpsellItemTaskInstance", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "addUpsellItemTaskInstance"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "scroll", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "scroll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addUpsellItem", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "addUpsellItem"), _class.prototype)), _class));
  _exports.default = CartUpsellGroup;
});