define("mobile-web/lib/dayjs", ["exports", "dayjs", "dayjs/plugin/advancedFormat", "dayjs/plugin/calendar", "dayjs/plugin/dayOfYear", "dayjs/plugin/isBetween", "dayjs/plugin/utc", "mobile-web/lib/utilities/math"], function (_exports, _dayjs, _advancedFormat, _calendar, _dayOfYear, _isBetween, _utc, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.roundUpTime = roundUpTime;
  _exports.timeIntervals = timeIntervals;
  _exports.dayIntervals = dayIntervals;
  Object.defineProperty(_exports, "Dayjs", {
    enumerable: true,
    get: function get() {
      return _dayjs.Dayjs;
    }
  });
  _exports.DAY_FORMAT = _exports.default = void 0;

  _dayjs.default.extend(_advancedFormat.default);

  _dayjs.default.extend(_calendar.default);

  _dayjs.default.extend(_dayOfYear.default);

  _dayjs.default.extend(_isBetween.default);

  _dayjs.default.extend(_utc.default);

  var _default = _dayjs.default;
  _exports.default = _default;
  const DAY_FORMAT = {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: 'MMM Do YYYY'
  };
  /**
   * Round up from a starting time to a given interval in minutes later.
   *
   * @param time      The time to start from.
   * @param interval  The amount of time (in minutes) to round up. Defaults to
   *                  rounding up to the next multiple-of-5 minute.
   *
   * @returns a new `Dayjs` instance representing the rounded-up time.
   */

  _exports.DAY_FORMAT = DAY_FORMAT;

  function roundUpTime(time, interval = 5) {
    const baseMinutes = time.minute();
    const nextInterval = (0, _math.roundUpToNext)(interval, baseMinutes);
    const diff = nextInterval - baseMinutes;
    return time.add(diff, 'minute').second(0).millisecond(0);
  }
  /**
   * Generate a set of time slots at even intervals, starting at the next even
   * interval from now.
   *
   * NOTE: this currently assumes that you are never generating more than a full
   * year of days.
   *
   * @param count The number of intervals to generate. Defaults to generating
   *        enough intervals for the next 12 hours given the default interval size.
   * @param interval  The length of the intervals (in minutes) to generate.
   *        Defaults to 15.
   * @param start The start time. Defaults to generating "now".
   * @param includeStart Whether to allow the start time to be included. Defaults
   *        to `false`; override e.g. when getting all intervals for a day
   *        starting at midnight.
   */


  function timeIntervals(count = 48, interval = 15, start = (0, _dayjs.default)(), includeStart = false) {
    const possibleFirst = roundUpTime(start, interval); // Account for the occasions that we're exactly on the interval when we start.

    const first = start.minute() === possibleFirst.minute() && !includeStart ? possibleFirst.add(interval, 'minute') : possibleFirst;
    const intervals = [first];

    for (let i = 0; i < count - 1; i++) {
      intervals.push(intervals[i].add(interval, 'minute'));
    }

    return intervals.filter(i => i.dayOfYear() === start.dayOfYear());
  }
  /**
   * Generate a number of days.
   *
   * NOTE: this currently assumes that you are never generating more than a full
   * year of days.
   *
   * @param count The number of days to generate. Defaults to 7 (a week).
   * @param start The first day in the interval. Defaults to "now", i.e. today.
   * @returns `count` `Dayjs` instances representing each day.
   */


  function dayIntervals(count = 7, start = (0, _dayjs.default)()) {
    const days = [start];

    for (let i = 0; i < count - 1; i++) {
      days.push(days[i].add(1, 'day').startOf('day'));
    }

    return days;
  }
});