define("mobile-web/components/r-vendor-info-modal/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "modal": "_modal_jk2c5s",
    "header": "_header_jk2c5s",
    "contactInfo": "_contactInfo_jk2c5s",
    "addressWrapper": "_addressWrapper_jk2c5s",
    "mapLink": "_mapLink_jk2c5s",
    "phoneWrapper": "_phoneWrapper_jk2c5s",
    "phoneHeader": "_phoneHeader_jk2c5s",
    "phoneButton": "_phoneButton_jk2c5s",
    "calendarList": "_calendarList_jk2c5s",
    "calendarWrapper": "_calendarWrapper_jk2c5s",
    "cardWrapper": "_cardWrapper_jk2c5s",
    "cards": "_cards_jk2c5s"
  };
  _exports.default = _default;
});