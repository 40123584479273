define("mobile-web/components/r-account-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EXN+bowe",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],null,[[\"@model\",\"@isHeader\"],[[30,0,[\"accountActions\"]],true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@label\",\"@icon\",\"@onClick\",\"@testSelector\"],[[28,[37,3],null,[[\"text\",\"size\"],[[28,[37,4],[\"mwc.header.accountButtonYourAccountLabel\"],null],\"small\"]]],[28,[37,3],null,[[\"svg\",\"position\",\"ariaLabel\"],[\"r-account-circle\",\"left\",\"\"]]],[30,0,[\"onClick\"]],\"account\"]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,2],null,[[\"@label\",\"@icon\",\"@onClick\",\"@testSelector\"],[[28,[37,3],null,[[\"text\",\"size\"],[[28,[37,4],[\"mwc.header.accountButtonSignInLabel\"],null],\"small\"]]],[28,[37,3],null,[[\"svg\",\"position\",\"ariaLabel\"],[\"r-account-circle\",\"left\",\"\"]]],[30,0,[\"onClick\"]],\"login\"]],null],[1,\"\\n\"]],[]]]],[\"@loggedIn\"],false,[\"if\",\"r-popover\",\"r-header-button\",\"hash\",\"t\"]]",
    "moduleName": "mobile-web/components/r-account-button/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});