define("mobile-web/components/r-cart/upsells/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QqrGy2kp",
    "block": "[[[41,[28,[37,1],[[30,0,[\"basket\",\"basket\",\"isUpsellEnabled\"]],[30,0,[\"availableUpsellGroups\"]]],null],[[[1,\"  \"],[10,0],[15,0,[30,0,[\"style\",\"root\"]]],[14,\"data-test-cart-upsells\",\"\"],[14,\"tabindex\",\"0\"],[15,\"aria-label\",[28,[37,2],[\"mwc.cart.upsellSectionLabel\"],null]],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@key\",\"@defaultMessage\"],[\"RESP_UPSELL_HEADER\",\"mwc.cart.singleUpsellGroupTitle\"]],[[\"default\"],[[[[1,\"\\n\"],[1,\"      \"],[8,[39,4],null,[[\"@headingLevel\",\"@startExpanded\",\"@testSelector\",\"@title\",\"@toggleClass\"],[3,true,\"upsells\",[30,1,[\"value\"]],[30,0,[\"style\",\"toggle\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[10,\"ul\"],[15,0,[30,0,[\"style\",\"list\"]]],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"availableUpsellGroups\"]]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[15,0,[30,0,[\"style\",\"listItem\"]]],[12],[1,\"\\n              \"],[8,[39,7],null,[[\"@upsellGroup\"],[[30,2]]],null],[1,\"\\n            \"],[13],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"x\",\"upsellGroup\"],false,[\"if\",\"and\",\"t\",\"x-content\",\"r-collapse\",\"each\",\"-track-array\",\"r-cart/upsell-group\"]]",
    "moduleName": "mobile-web/components/r-cart/upsells/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});