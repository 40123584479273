define("mobile-web/components/r-checkout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8tPxeLRR",
    "block": "[[[10,0],[15,0,[30,0,[\"style\",\"page\"]]],[14,\"data-test-checkout\",\"\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@defaultMessage\",\"@key\"],[\"mwc.checkout.header\",\"RESP_CHECKOUT_PAGE_TITLE\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,\"h1\"],[15,0,[30,0,[\"style\",\"heading\"]]],[12],[1,[30,1,[\"value\"]]],[13],[1,\"\\n  \"]],[1]]]]],[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"style\",\"content\"]]],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"x\",\"&default\"],false,[\"x-content\",\"yield\"]]",
    "moduleName": "mobile-web/components/r-checkout/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});