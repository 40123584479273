define("mobile-web/components/r-get-directions-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p6XlCgCp",
    "block": "[[[11,3],[16,0,[28,[37,0],[[30,0,[\"rootClass\"]],\"get-directions-button\"],null]],[16,6,[30,0,[\"href\"]]],[24,\"target\",\"_blank\"],[24,\"data-test-getDirectionsButton\",\"\"],[4,[38,1],[[30,0,[\"track\"]]],null],[12],[1,\"\\n  \"],[10,1],[15,0,[30,0,[\"style\",\"text\"]]],[12],[1,\"Get Directions\"],[13],[1,\"\\n  \"],[8,[39,2],null,[[\"@ariaLabel\",\"@class\",\"@icon\"],[\"\",[30,0,[\"style\",\"icon\"]],\"r-directions\"]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"classes\",\"did-insert\",\"r-svg\"]]",
    "moduleName": "mobile-web/components/r-get-directions-button/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});