define("mobile-web/components/r-inline-alert/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_10xe9m",
    "iconContainer": "_iconContainer_10xe9m",
    "icon": "_icon_10xe9m"
  };
  _exports.default = _default;
});