define("mobile-web/components/routes/dispatch-summary-route/component", ["exports", "@ember-decorators/component", "mobile-web/config/environment", "mobile-web/lib/utilities/is-some", "mobile-web/models/dispatch-status", "mobile-web/components/routes/dispatch-summary-route/style"], function (_exports, _component, _environment, _isSome, _dispatchStatus, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const REFRESH_INTERVAL = 60000;
  let DispatchSummaryRoute = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed('statuses.@each.courierStatus'), _dec5 = Ember._action, _dec(_class = (_class2 = class DispatchSummaryRoute extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      this.style = _style.default;
      this.statuses = [];
    }

    // Computed properties
    get hasActiveStatus() {
      return this.statuses.reduce((isActive, status) => isActive || status.courierStatus === _dispatchStatus.CourierStatus.Active, false);
    } // Init


    init() {
      super.init();
      (false && !((0, _isSome.default)(this.initialStatuses)) && Ember.assert('`initialStatuses` is required', (0, _isSome.default)(this.initialStatuses)));
      (false && !((0, _isSome.default)(this.order)) && Ember.assert('`order` is required', (0, _isSome.default)(this.order)));
      this.statuses.addObjects(this.initialStatuses);

      if (_environment.default.environment !== 'test') {
        Ember.run.later(this, this.refresh, REFRESH_INTERVAL);
      }
    } // Other methods


    async refresh() {
      if (!this.isDestroying && !this.isDestroyed && this.hasActiveStatus) {
        const newStatuses = await this.store.query('dispatch-status', {
          orderId: this.order.id,
          hash: this.hash
        });
        newStatuses.forEach(ns => {
          if (!this.statuses.find(s => s.id === ns.id)) {
            this.statuses.addObject(ns);
          }
        });
        Ember.run.later(this, this.refresh, REFRESH_INTERVAL);
      }
    } // Actions


    goToDetails(status) {
      this.router.transitionTo('dispatch-status', status.order.id, status.id, {
        queryParams: {
          hash: this.hash
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "hasActiveStatus", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "hasActiveStatus"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "goToDetails", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "goToDetails"), _class2.prototype)), _class2)) || _class);
  _exports.default = DispatchSummaryRoute;
});