define("mobile-web/components/r-gift-card-modal/component", ["exports", "@glimmer/component", "ember-concurrency", "ember-concurrency-ts", "mobile-web/lib/debounce", "mobile-web/lib/validation", "mobile-web/models/basket", "mobile-web/components/r-gift-card-modal/style"], function (_exports, _component, _emberConcurrency, _emberConcurrencyTs, _debounce, _validation, _basket, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class3, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Model = (_dec = Ember._tracked, _dec2 = Ember._tracked, (_class = class Model {
    constructor() {
      _initializerDefineProperty(this, "cardNumber", _descriptor, this);

      _initializerDefineProperty(this, "pin", _descriptor2, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "cardNumber", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "pin", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  })), _class));
  let GiftCardModal = (_dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._action, _dec14 = Ember._action, (_class3 = class GiftCardModal extends _component.default {
    // Service injections
    // Passthroughs
    // Tracked properties
    // Getters and setters
    get allowSavedCards() {
      var _this$basket$basket;

      return !!((_this$basket$basket = this.basket.basket) != null && _this$basket$basket.billingDetails.get('allowCardsOnFile')) && this.session.isLoggedIn;
    }

    get isSubmitDisabled() {
      return (0, _emberConcurrencyTs.taskFor)(this.checkPinRequired).isRunning || (0, _emberConcurrencyTs.taskFor)(this.saveTask).isRunning || !this.model.cardNumber || this.showPin && !this.model.pin;
    }

    get validationConfig() {
      const {
        accountNumberLengthLowerLimit: minCardLength,
        accountNumberLengthUpperLimit: maxCardLength,
        pinLengthLowerLimit: minPinLength,
        pinLengthUpperLimit: maxPinLength
      } = this.args.scheme;
      const validation = {
        bindings: [{
          targetProp: 'cardNumber',
          ruleName: 'giftCardLength',
          message: this.intl.t('mwc.giftCardModal.errors.cardNumber', {
            min: minCardLength,
            max: maxCardLength
          })
        }],
        customRules: [{
          name: 'giftCardLength',
          pattern: new RegExp(`^\\w{${minCardLength},${maxCardLength}}$`)
        }]
      };

      if (this.showPin) {
        var _this$store$peekRecor;

        validation.customRules.push({
          name: 'pinLength',
          pattern: new RegExp(`^\\w{${minPinLength},${maxPinLength}}$`)
        }); // okay to use peek because this is loaded in the template
        // using fetch ends up interpolating a promise string and not the actual value

        const pin = (_this$store$peekRecor = this.store.peekRecord('content', 'RESP_CHECKOUT_GIFT_CARD_PIN_LABEL')) == null ? void 0 : _this$store$peekRecor.value;
        const pinMessage = minPinLength === maxPinLength ? 'mwc.giftCardModal.errors.pinFixed' : !minPinLength ? 'mwc.giftCardModal.errors.pinMax' : 'mwc.giftCardModal.errors.pinRange';
        validation.bindings.push({
          targetProp: 'pin',
          ruleName: 'pinLength',
          message: this.intl.t(pinMessage, {
            pin,
            min: minPinLength,
            max: maxPinLength
          })
        });
      }

      return validation;
    } // Constructor


    constructor(owner, args) {
      var _card$giftCardNumber;

      super(owner, args);

      _initializerDefineProperty(this, "basket", _descriptor3, this);

      _initializerDefineProperty(this, "challenge", _descriptor4, this);

      _initializerDefineProperty(this, "features", _descriptor5, this);

      _initializerDefineProperty(this, "intl", _descriptor6, this);

      _initializerDefineProperty(this, "session", _descriptor7, this);

      _initializerDefineProperty(this, "store", _descriptor8, this);

      this.formId = 'gift-card-form';
      this.isGiftCard = true;
      this.style = _style.default;

      _initializerDefineProperty(this, "model", _descriptor9, this);

      _initializerDefineProperty(this, "validationResult", _descriptor10, this);

      _initializerDefineProperty(this, "showPin", _descriptor11, this);

      _initializerDefineProperty(this, "saveCard", _descriptor12, this);

      const card = this.args.card;
      const model = new Model();
      model.cardNumber = (_card$giftCardNumber = card == null ? void 0 : card.giftCardNumber) != null ? _card$giftCardNumber : '';
      this.model = model;
      this.saveCard = !!this.args.membership || !!(card != null && card.saveGiftCard);
      this.showPin = !!(card != null && card.giftCardPin);
    } // Other methods
    // Tasks


    *checkPinRequired() {
      const giftCardNumber = this.model.cardNumber;
      const scheme = this.args.scheme;

      if (Ember.isNone(giftCardNumber)) {
        return false;
      }

      yield (0, _emberConcurrency.timeout)(_debounce.DELAY_MS);
      const result = yield scheme.getPinIsRequired({
        cardNumber: giftCardNumber,
        billingSchemeId: scheme.id
      });
      this.showPin = result.required;
      this.isGiftCard = result.isGiftCard;
      return undefined;
    }

    *saveTask() {
      this.validationResult = _validation.default.validate(this.model, this.validationConfig);

      if (this.validationResult.isOk()) {
        const card = {
          giftCardNumber: this.model.cardNumber,
          saveGiftCard: this.saveCard
        };

        if (this.showPin) {
          card.giftCardPin = this.model.pin;
        }

        if (this.isGiftCard || !this.basket.basket.vendor.get('compCardsEnabled')) {
          this.args.onSave(card);
        } else {
          yield (0, _emberConcurrencyTs.taskFor)(this.challenge.request).perform(async () => {
            var _basket$discounts;

            const basket = await this.basket.basket.applyCompCard({
              cardNumber: this.model.cardNumber,
              pinNumber: this.model.pin
            });
            const compCardData = (_basket$discounts = basket.discounts) == null ? void 0 : _basket$discounts.find(d => d.type === _basket.DiscountType.COMP_CARD);

            if (compCardData) {
              this.args.onSave({
                description: compCardData.description,
                amount: compCardData.amount
              });
            } else {
              this.args.onSave(card);
            }
          }, () => this.args.onSave(card));
        }
      }
    } // Actions


    save(e) {
      e.preventDefault();
      (0, _emberConcurrencyTs.taskFor)(this.saveTask).perform();
    }

    clear() {
      this.model.cardNumber = '';
      this.model.pin = '';
      this.saveCard = false;
    }

  }, (_descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "basket", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "challenge", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class3.prototype, "features", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class3.prototype, "intl", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class3.prototype, "session", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class3.prototype, "store", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class3.prototype, "model", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class3.prototype, "validationResult", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class3.prototype, "showPin", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class3.prototype, "saveCard", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class3.prototype, "checkPinRequired", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class3.prototype, "checkPinRequired"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "saveTask", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class3.prototype, "saveTask"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "save", [_dec13], Object.getOwnPropertyDescriptor(_class3.prototype, "save"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "clear", [_dec14], Object.getOwnPropertyDescriptor(_class3.prototype, "clear"), _class3.prototype)), _class3));
  _exports.default = GiftCardModal;
});