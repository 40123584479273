define("mobile-web/components/r-link-rewards/button/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "buttonContent": "_buttonContent_khjesh",
    "starIcon": "_starIcon_khjesh"
  };
  _exports.default = _default;
});