define("mobile-web/components/r-contact-info/component", ["exports", "@glimmer/component", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-contact-info/style"], function (_exports, _component, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ContactInfo = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class ContactInfo extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "window", _descriptor, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "showModal", _descriptor2, this);
    }

    // Getters and setters
    get headerKey() {
      var _this$args$headerKey;

      return (_this$args$headerKey = this.args.headerKey) != null ? _this$args$headerKey : 'mwc.contactInfo.header';
    }

    get isEditable() {
      var _this$args$isEditable;

      return (_this$args$isEditable = this.args.isEditable) != null ? _this$args$isEditable : true;
    } // Constructor
    // Other methods
    // Tasks
    // Actions


    openModal() {
      this.showModal = true;
    }

    closeModal() {
      this.showModal = false;
    }

    edit() {
      if ((0, _isSome.default)(this.args.externalEditUrl)) {
        this.window.location().assign(this.args.externalEditUrl);
      } else {
        this.openModal();
      }
    }

    handleChange(user) {
      this.args.onChange(user);
      this.closeModal();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "window", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showModal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "openModal", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "openModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "edit", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleChange", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype)), _class));
  _exports.default = ContactInfo;
});