define("mobile-web/routes/secure/my-account", ["exports", "mobile-web/decorators/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MyAccountRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (0, _authenticated.default)(_class = (_class2 = class MyAccountRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "loyalty", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);
    }

    async model() {
      const user = this.session.currentUser;
      const memberships = this.loyalty.loadMemberships();
      const schemes = this.store.findAll('loyalty-scheme');
      const billingMemberships = await this.store.query('billing-membership', {});
      await Promise.all(billingMemberships.map(m => m.tryLoadBalance()));
      return Ember.RSVP.hash({
        user,
        memberships,
        billingMemberships,
        schemes
      });
    }

    titleToken({
      user
    }) {
      const {
        firstName,
        lastName
      } = Ember.getProperties(user, 'firstName', 'lastName');
      return `My Account - ${firstName} ${lastName}`;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "loyalty", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = MyAccountRoute;
});