define("mobile-web/components/r-time-wanted-form/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "ember-animated/transitions/fade", "ember-concurrency", "ember-concurrency-ts", "mobile-web/lib/dayjs", "mobile-web/lib/order-criteria", "mobile-web/lib/time/format", "mobile-web/lib/utilities/_", "mobile-web/components/r-time-wanted-form/style"], function (_exports, _glimmerComponent, _fade, _emberConcurrency, _emberConcurrencyTs, _dayjs, _orderCriteria, _format, _, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const DAY_LABEL_FORMAT = 'dddd M/D';
  let TimeWantedForm = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class TimeWantedForm extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      _initializerDefineProperty(this, "channel", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      this.style = _style.default;
      this.transition = _fade.default;
      this.previousHandoff = 'Unspecified';

      _initializerDefineProperty(this, "days", _descriptor4, this);

      _initializerDefineProperty(this, "timeSlots", _descriptor5, this);

      this.getDaysTask = (0, _emberConcurrencyTs.taskFor)(this.getDaysGenerator);
      this.getTimeSlotsTask = (0, _emberConcurrencyTs.taskFor)(this.getTimeSlotsGenerator);
    }

    // Getters and setters
    get name() {
      var _this$args$name;

      return (_this$args$name = this.args.name) != null ? _this$args$name : 'time-wanted-form';
    }

    get label() {
      var _this$args$label;

      return (_this$args$label = this.args.label) != null ? _this$args$label : this.name;
    }

    get handoffMode() {
      var _this$args$handoffMod;

      return (_this$args$handoffMod = this.args.handoffMode) != null ? _this$args$handoffMod : 'Unspecified';
    }

    get isAdvance() {
      return this.type.type === 'Advance';
    }

    get daySlotsDisabled() {
      return false;
    }

    get timeSlotsDisabled() {
      return (0, _.isEmpty)(this.timeSlots);
    }

    get day() {
      const fallbackValue = this.days.length > 0 ? this.days[0].date : '';

      if (this.isAdvance && this.args.timeWanted) {
        // If today, use the first of the day values we have available, so that we
        // match the value from when we constructed the form. If *not* today, use
        // the top-level day value (resetting to midnight UTC) so that we properly
        // select the "day" there. The fallback value will go all the way down to
        // being an empty string in the case that there are no loaded days
        // whatsoever.
        const isToday = (0, _dayjs.default)().dayOfYear() === this.args.timeWanted.dayOfYear();
        return isToday ? fallbackValue : this.args.timeWanted.startOf('day').toISOString();
      }

      return fallbackValue;
    }

    set day(newDay) {
      const dayWanted = (0, _dayjs.default)(newDay);
      const now = (0, _dayjs.default)(); // Use *right now* as the starting point if the selected day is today,
      // so that users always get all available future time slots.

      const startTime = dayWanted.isSame((0, _dayjs.default)(), 'day') ? now : dayWanted.startOf('day');
      this.getTimeSlotsTask.perform(startTime);
    }

    get timeSlot() {
      return this.isAdvance && this.args.timeWanted ? this.args.timeWanted.toISOString() : !(0, _.isEmpty)(this.timeSlots) ? this.timeSlots[0].date : '';
    }

    set timeSlot(newTimeSlot) {
      this.args.onTimeWantedChange((0, _dayjs.default)(newTimeSlot));
    }

    get type() {
      var _this$args$type;

      return (_this$args$type = this.args.type) != null ? _this$args$type : this.args.types[0];
    } // Constructor
    // Other methods


    setDefaultTimeSlot() {
      if (this.isAdvance && !(0, _.isEmpty)(this.timeSlots)) {
        if (this.args.timeWanted) {
          const tsMatch = this.timeSlots.find(ts => {
            const tsDate = (0, _dayjs.default)(ts.date);
            return tsDate.hour() === this.args.timeWanted.hour() && tsDate.minute() === this.args.timeWanted.minute();
          });

          if (tsMatch) {
            this.timeSlot = tsMatch.date;
          } else {
            this.timeSlot = this.timeSlots[0].date;
          }
        } else {
          this.timeSlot = this.timeSlots[0].date;
        }
      }
    } // Tasks


    *getDaysGenerator() {
      var _this$channel$setting, _this$channel$setting2;

      this.previousHandoff = this.handoffMode;
      const firstAvailable = (0, _dayjs.roundUpTime)((0, _dayjs.default)().add(15, 'minute'), 15);
      const advanceOrderDays = (_this$channel$setting = (_this$channel$setting2 = this.channel.settings) == null ? void 0 : _this$channel$setting2.advanceOrderDays) != null ? _this$channel$setting : 0;
      const totalDays = advanceOrderDays + 1;

      if (this.basket.basket && this.args.handoffMode) {
        yield this.basket.basket.getOrderDays({
          handoffModeChar: (0, _orderCriteria.getHandoffKey)(this.args.handoffMode)
        }).then(orderDays => {
          this.days = orderDays.map(d => ({
            date: (0, _dayjs.default)(d).startOf('day').toISOString(),
            label: _format.default.day((0, _dayjs.default)(d), {
              format: DAY_LABEL_FORMAT
            })
          }));
        });
      } else {
        this.days = (0, _dayjs.dayIntervals)(totalDays, firstAvailable).map(d => ({
          date: d.toISOString(),
          label: _format.default.day(d, {
            format: DAY_LABEL_FORMAT
          })
        }));
      } // If the time-wanted is already set, load the slots for the *selected* time.


      let newDay = '';

      if (this.args.timeWanted) {
        newDay = this.args.timeWanted.startOf('day').toISOString();
      } // If we didn't have a selected time or the selected time is invalid, load the *first* day's time-slots as a default.


      if (this.days.length > 0 && !this.days.find(d => d.date === newDay)) {
        newDay = this.days[0].date;
      }

      this.day = newDay;
    }

    get timeWantedAtStore() {
      var _this$basket$basket;

      const vendorUtcOffset = (_this$basket$basket = this.basket.basket) == null ? void 0 : _this$basket$basket.vendor.get('utcOffset');

      if (vendorUtcOffset) {
        const vendorUtcOffsetMinutes = vendorUtcOffset * 60;

        if (vendorUtcOffsetMinutes !== (0, _dayjs.default)().utcOffset()) {
          return `${this.intl.t('mwc.calendar.timeWantedAtStore', {
            time: (0, _dayjs.default)(this.timeSlot).utcOffset(vendorUtcOffsetMinutes).format(_format.default.TIME_FORMAT)
          })}`;
        }
      }

      return '';
    }

    get showTZDifference() {
      return !(0, _.isEmpty)(this.timeWantedAtStore) && !this.getDaysTask.isRunning && !this.getTimeSlotsTask.isRunning;
    }

    *getTimeSlotsGenerator(start) {
      if (this.basket.basket && this.args.handoffMode) {
        yield this.basket.basket.getTimeSlots({
          date: start.format('MM/DD/YYYY'),
          handoffModeChar: (0, _orderCriteria.getHandoffKey)(this.args.handoffMode)
        }).then(({
          timeSlots
        }) => {
          if (timeSlots) {
            this.timeSlots = timeSlots.map(ts => {
              const localBaseTime = (0, _dayjs.default)(`${ts}+00:00`);
              return {
                date: localBaseTime.toISOString(),
                label: localBaseTime.format(_format.default.TIME_FORMAT)
              };
            });
            this.setDefaultTimeSlot();
          }

          return [];
        });
      } else {
        this.timeSlots = (0, _dayjs.timeIntervals)(96, 15, start, true).map(t => ({
          date: t.toISOString(),
          label: t.format(_format.default.TIME_FORMAT)
        }));
        this.setDefaultTimeSlot();
      }
    } // Actions


    handleDayChange(dayValue) {
      var _this$args$timeWanted;

      const updatedDay = (0, _dayjs.default)(dayValue);
      const timeWanted = (_this$args$timeWanted = this.args.timeWanted) != null ? _this$args$timeWanted : (0, _dayjs.default)();
      timeWanted.day(updatedDay.day());
      this.args.onTimeWantedChange(timeWanted);
    }

    handleTimeSlotChange(timeSlotValue) {
      const updatedTime = (0, _dayjs.default)(timeSlotValue);
      this.args.onTimeWantedChange(updatedTime);
    }

    handoffUpdated() {
      var _this$args$type2;

      /**
       * This can be fired when the handoff mode didn't actually change,
       * so we need to keep track to make sure we only update the days
       * when it actually changes to a new value
       */
      if (((_this$args$type2 = this.args.type) == null ? void 0 : _this$args$type2.type) === 'Advance' && this.handoffMode !== this.previousHandoff) {
        this.getDaysTask.perform();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "days", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "timeSlots", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "getDaysGenerator", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "getDaysGenerator"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getTimeSlotsGenerator", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "getTimeSlotsGenerator"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDayChange", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "handleDayChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTimeSlotChange", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "handleTimeSlotChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handoffUpdated", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "handoffUpdated"), _class.prototype)), _class));
  _exports.default = TimeWantedForm;
});