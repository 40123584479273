define("mobile-web/components/r-product-customization/requirement/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "requirement": "_requirement_14eno2",
    "required": "_required_14eno2 _requirement_14eno2",
    "completed": "_completed_14eno2 _requirement_14eno2",
    "icon": "_icon_14eno2"
  };
  _exports.default = _default;
});