define("mobile-web/components/r-popover-content/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "popover": "_popover_12od1j",
    "arrow": "_arrow_12od1j",
    "list": "_list_12od1j",
    "item": "_item_12od1j",
    "action": "_action_12od1j",
    "headerPopover": "_headerPopover_12od1j"
  };
  _exports.default = _default;
});