define("mobile-web/components/r-notification/component", ["exports", "@glimmer/component", "mobile-web/lib/animation", "mobile-web/lib/time/durations", "mobile-web/services/notifications", "mobile-web/components/r-notification/style"], function (_exports, _component, _animation, _durations, _notifications, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const OPEN_DURATION = (0, _animation.getDuration)(400 * _durations.MILLISECONDS);
  const CLOSE_DURATION = (0, _animation.getDuration)(400 * _durations.MILLISECONDS);
  var NotificationState;

  (function (NotificationState) {
    NotificationState["Opening"] = "opening";
    NotificationState["Idling"] = "idling";
    NotificationState["Closing"] = "closing";
  })(NotificationState || (NotificationState = {}));

  const ICONS = {
    [_notifications.NotificationLevel.Success]: 'r-checkmark',
    [_notifications.NotificationLevel.Info]: 'r-info',
    [_notifications.NotificationLevel.Warning]: 'r-exclamation',
    [_notifications.NotificationLevel.Error]: 'r-flag'
  };
  const ICON_LABELS = {
    [_notifications.NotificationLevel.Success]: 'Success',
    [_notifications.NotificationLevel.Info]: 'Info',
    [_notifications.NotificationLevel.Warning]: 'Warning',
    [_notifications.NotificationLevel.Error]: 'Error'
  };
  const VARIANTS = {
    [_notifications.NotificationLevel.Success]: _style.default.success,
    [_notifications.NotificationLevel.Info]: _style.default.info,
    [_notifications.NotificationLevel.Warning]: _style.default.warning,
    [_notifications.NotificationLevel.Error]: _style.default.error
  };
  const STATES = {
    [NotificationState.Opening]: _style.default.opening,
    [NotificationState.Idling]: _style.default.idling,
    [NotificationState.Closing]: _style.default.closing
  };
  let Notification = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember.computed.alias('args.notification.message'), _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class Notification extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "features", _descriptor, this);

      _initializerDefineProperty(this, "notifications", _descriptor2, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "state", _descriptor3, this);

      _initializerDefineProperty(this, "message", _descriptor4, this);
    }

    // Getters and setters
    get icon() {
      const level = this.args.notification.level;
      return ICONS.hasOwnProperty(level) ? ICONS[level] : '';
    }

    get iconLabel() {
      const level = this.args.notification.level;
      return ICON_LABELS.hasOwnProperty(level) ? ICON_LABELS[level] : '';
    }

    get messageId() {
      return `notification-message-${this.uniqueId}`;
    }

    get notificationClass() {
      const classes = [_style.default.notification];
      classes.push(this.style.hybrid);
      const level = this.args.notification.level;

      if (VARIANTS.hasOwnProperty(level)) {
        classes.push(VARIANTS[level]);
      }

      const state = this.state;

      if (STATES.hasOwnProperty(state)) {
        classes.push(STATES[state]);
      }

      return classes.join(' ');
    }

    get uniqueId() {
      return Ember.guidFor(this);
    }

    get idleDuration() {
      return (0, _animation.getDuration)(5 * _durations.SECONDS);
    } // Constructor
    // Other methods


    next(method, wait) {
      if (this.timer) {
        Ember.run.cancel(this.timer);
      }

      this.timer = Ember.run.later(method, wait);
    } // Tasks
    // Actions


    open() {
      this.state = NotificationState.Opening;
      this.next(this.idle, OPEN_DURATION);
    }

    idle() {
      this.state = NotificationState.Idling;

      if (!this.args.testMode) {
        this.next(this.close, this.idleDuration);
      }
    }

    close() {
      this.state = NotificationState.Closing;
      this.next(this.dismiss, CLOSE_DURATION);
    }

    dismiss() {
      this.notifications.dismiss(this.args.notification);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "features", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "state", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return NotificationState.Opening;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "message", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "open", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "open"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "idle", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "idle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dismiss", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "dismiss"), _class.prototype)), _class));
  _exports.default = Notification;
});