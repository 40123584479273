define("mobile-web/lib/focus", ["exports", "mobile-web/lib/scroll"], function (_exports, _scroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.focusWithoutScroll = focusWithoutScroll;
  _exports.focusWithScroll = focusWithScroll;

  /**
   * Detect browser support for the `preventScroll` focus parameter
   */
  const canPreventScroll = (() => {
    const d = document.createElement('div');
    let preventScroll = false;
    d.focus({
      get preventScroll() {
        preventScroll = true;
        return true;
      }

    });
    return preventScroll;
  })();
  /**
   * Focus an element without automatically scrolling the element into view
   *
   * @param el the element to focus
   */


  function focusWithoutScroll(el) {
    if (canPreventScroll) {
      el.focus({
        preventScroll: true
      });
    } else {
      var _scrollElement$scroll;

      // Safari, iOS, and older browsers
      const scrollElement = (0, _scroll.getScrollParent)(el);
      const scrollTop = (_scrollElement$scroll = scrollElement == null ? void 0 : scrollElement.scrollTop) != null ? _scrollElement$scroll : 0;
      el.focus();
      if (scrollElement) scrollElement.scrollTop = scrollTop;
    }
  }
  /**
   * Focus an element and smooth scroll to the focused element
   *
   * @param el the element to focus
   */


  function focusWithScroll(el) {
    focusWithoutScroll(el);
    const behavior = matchMedia('(prefers-reduced-motion)').matches ? 'auto' : 'smooth';
    el.scrollIntoView({
      behavior
    });
  }
});