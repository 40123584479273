define("mobile-web/components/routes/dispatch-status-route/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_5zzy6u",
    "dispatchInfo": "_dispatchInfo_5zzy6u",
    "description": "_description_5zzy6u",
    "estimate": "_estimate_5zzy6u",
    "estimateText": "_estimateText_5zzy6u",
    "icon": "_icon_5zzy6u",
    "iconLabel": "_iconLabel_5zzy6u",
    "updates": "_updates_5zzy6u",
    "updatesInactive": "_updatesInactive_5zzy6u _updates_5zzy6u",
    "updatesActive": "_updatesActive_5zzy6u _updates_5zzy6u",
    "pulse": "_pulse_5zzy6u",
    "updatesActiveNoLocation": "_updatesActiveNoLocation_5zzy6u _updates_5zzy6u"
  };
  _exports.default = _default;
});