define("mobile-web/components/r-popover-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZDgivi7Z",
    "block": "[[[11,0],[16,0,[28,[37,0],[[30,0,[\"style\",\"popover\"]],[52,[30,1],[30,0,[\"style\",\"headerPopover\"]]]],null]],[24,\"data-test-popover-content\",\"root\"],[16,\"data-popover-content\",[30,0,[\"uniqueId\"]]],[4,[38,2],[\"click\",[30,0,[\"onClick\"]]],null],[4,[38,3],[[30,0,[\"didInsert\"]]],null],[12],[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"style\",\"arrow\"]]],[15,\"data-popover-content-arrow\",[30,0,[\"uniqueId\"]]],[12],[13],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"    \"],[10,\"ul\"],[15,0,[30,0,[\"style\",\"list\"]]],[14,\"data-test-popover-content\",\"list\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,2]],null]],null],null,[[[1,\"        \"],[10,\"li\"],[15,0,[28,[37,6],[[30,0,[\"style\",\"item\"]],\" \",[30,3,[\"class\"]]],null]],[14,\"data-test-popover-content\",\"item\"],[12],[1,\"\\n\"],[41,[28,[37,7],[[30,3],\"route\"],null],[[[1,\"            \"],[8,[39,8],[[16,0,[30,0,[\"style\",\"action\"]]],[24,\"data-test-popover-content\",\"action\"],[16,\"data-test-popover-content-action\",[30,3,[\"testSelector\"]]]],[[\"@route\"],[[30,3,[\"route\"]]]],[[\"default\"],[[[[1,\"\\n              \"],[8,[39,9],null,[[\"@model\"],[[30,3]]],null],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"            \"],[11,\"button\"],[16,0,[30,0,[\"style\",\"action\"]]],[24,\"data-test-popover-content\",\"action\"],[16,\"data-test-popover-content-action\",[30,3,[\"testSelector\"]]],[4,[38,2],[\"click\",[30,3,[\"action\"]]],null],[12],[1,\"\\n              \"],[8,[39,9],null,[[\"@model\"],[[30,3]]],null],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]]],[1,\"        \"],[13],[1,\"\\n\"]],[3]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[18,4,null],[1,\"\\n\"]],[]]],[13]],[\"@isHeader\",\"@model\",\"item\",\"&default\"],false,[\"classes\",\"if\",\"on\",\"did-insert\",\"each\",\"-track-array\",\"concat\",\"has-key\",\"r-link-to\",\"r-popover-item\",\"yield\"]]",
    "moduleName": "mobile-web/components/r-popover-content/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});