define("mobile-web/components/r-segmented-control/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "radioGroup": "_radioGroup_jlsyqh",
    "groupLabel": "_groupLabel_jlsyqh",
    "input": "_input_jlsyqh",
    "button": "_button_jlsyqh",
    "active": "_active_jlsyqh"
  };
  _exports.default = _default;
});