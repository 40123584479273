define("mobile-web/components/r-marketing-opt-in/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "checkboxRow": "_checkboxRow_7cet5",
    "optInP": "_optInP_7cet5"
  };
  _exports.default = _default;
});