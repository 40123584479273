define("mobile-web/components/r-sign-in-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9XxmC+B7",
    "block": "[[[10,\"form\"],[14,\"autocomplete\",\"on\"],[14,\"data-test-sign-in-form\",\"\"],[12],[1,\"\\n  \"],[8,[39,0],[[16,0,[30,0,[\"style\",\"heading\"]]]],[[\"@level\"],[[30,0,[\"headingLevel\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"mwc.accountHeading.signIn\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[1,[28,[35,2],null,[[\"key\",\"type\"],[\"LOGIN_MESSAGE\",\"html\"]]]],[1,\"\\n\\n  \"],[8,[39,3],null,[[\"@name\",\"@label\",\"@class\",\"@autocomplete\",\"@type\",\"@inputmode\",\"@inputValue\",\"@validationMessages\",\"@setValue\"],[\"email\",\"Email\",[30,0,[\"style\",\"row\"]],\"email\",\"email\",\"email\",[30,0,[\"model\",\"email\"]],[30,0,[\"validationResult\",\"error\",\"email\"]],[28,[37,4],[[28,[37,5],[[30,0,[\"model\",\"email\"]]],null]],null]]],null],[1,\"\\n\\n  \"],[8,[39,6],null,[[\"@name\",\"@label\",\"@class\",\"@minlength\",\"@value\",\"@onChange\"],[\"password\",\"Password\",[30,0,[\"style\",\"row\"]],7,[30,0,[\"model\",\"password\"]],[28,[37,4],[[28,[37,5],[[30,0,[\"model\",\"password\"]]],null]],null]]],null],[1,\"\\n\\n  \"],[8,[39,7],[[16,0,[30,0,[\"style\",\"submitButton\"]]],[16,\"disabled\",[30,0,[\"isSubmitDisabled\"]]],[24,\"data-test-loginButton\",\"olo\"],[24,4,\"submit\"]],[[\"@label\"],[\"Sign In\"]],null],[1,\"\\n\\n\"],[13],[1,\"\\n\\n\"],[10,0],[15,0,[30,0,[\"style\",\"forgotPasswordRow\"]]],[12],[1,\"\\n  \"],[10,1],[15,0,[30,0,[\"style\",\"forgotPasswordLabel\"]]],[12],[1,\"Forgot password?\"],[13],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[8,[39,8],[[24,\"data-test-selector-reset-link\",\"\"]],[[\"@route\"],[\"forgot-password\"]],[[\"default\"],[[[[1,\"Reset password\"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"r-heading\",\"t\",\"x-content\",\"r-form-field\",\"fn\",\"mut\",\"r-password\",\"r-button\",\"r-link-to\"]]",
    "moduleName": "mobile-web/components/r-sign-in-form/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});