define("mobile-web/serializers/basket", ["exports", "ember-data", "mobile-web/decorators/persistent", "mobile-web/lib/utilities/is-some", "mobile-web/models/billing-details", "mobile-web/serializers/basket-product", "mobile-web/serializers/product"], function (_exports, _emberData, _persistent, _isSome, _billingDetails, _basketProduct, _product) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let BasketSerializer = (_dec = (0, _persistent.default)(), _dec(_class = class BasketSerializer extends _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);
      this.primaryKey = 'guid';
      this.attrs = {
        deliveryAddress: {
          embedded: 'always'
        }
      };
    }

    normalize(modelClass, resourceHash, prop) {
      // Billing Details is not actually an ID'ed object, but there is
      // only ever 1 of them, so we just hard code the ID to 0 here and
      // in the Billing Details serializer. This lets us keep all the things
      // in Ember Data, and get the billing details off of the basket.
      resourceHash.billingDetails = _billingDetails.BILLING_DETAILS_ID;

      if (Ember.isNone(resourceHash.vendorDiscount)) {
        resourceHash.vendorDiscount = 0;
      } // If the totalTax is 0, the back end just doesn't send totalTax at all
      // but we always want totalTax to be present, so we need to set it to 0


      if (resourceHash.taxes) {
        resourceHash.taxes.forEach(t => {
          if (Ember.isNone(t.totalTax)) {
            t.totalTax = 0;
          }
        });
      } // `deliveryMode` has been deprecated, and will eventually be removed from the back end.
      // `handoffMode` will be added at the same time.
      // Until then, we need to map one to the other ourselves.


      if ((0, _isSome.default)(resourceHash.deliveryMode) && !(0, _isSome.default)(resourceHash.handoffMode)) {
        resourceHash.handoffMode = (0, _product.normalizeDeliveryMode)(resourceHash.deliveryMode);
        delete resourceHash.deliveryMode;
      } // `timeWanted` is in the vendor's time zone, but it doesn't come back indicating that
      // so add the time zone indicator to that value here if we can get the offset


      if (resourceHash.vendor && resourceHash.timeWanted) {
        const vendor = this.store.peekRecord('vendor', resourceHash.vendor);

        if (vendor && vendor.utcOffset) {
          const sign = vendor.utcOffset < 0 ? '-' : '+';
          const num = Math.abs(vendor.utcOffset).toString().padStart(2, '0');
          resourceHash.timeWanted = `${resourceHash.timeWanted}${sign}${num}:00`;
        }
      }

      return super.normalize(modelClass, resourceHash, prop);
    }

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      const newPayload = (0, _basketProduct.normalizeBasketProductCustomFields)(payload);
      return super.normalizeResponse(store, primaryModelClass, newPayload, id, requestType);
    }

  }) || _class);
  _exports.default = BasketSerializer;
});