define("mobile-web/adapters/user", ["exports", "mobile-web/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO: handle response *here* intead of making caller do it.
  class UserAdapter extends _application.default {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    login(email, password) {
      const baseUrl = this.buildURL('user'),
            url = `${baseUrl}/login`;
      return this.ajax(url, 'POST', {
        data: {
          email,
          password
        }
      });
    } // eslint-disable-next-line @typescript-eslint/no-explicit-any


    externalLogin(nextUrl, providerSlug, nativeUrlScheme) {
      const baseUrl = this.buildURL('user'),
            url = `${baseUrl}/oauthlogin?provider=${providerSlug}&url=${nextUrl}${nativeUrlScheme ? `&nativeUrlScheme=${nativeUrlScheme}` : ''}`;
      return this.ajax(url, 'GET');
    }

    logout() {
      const baseUrl = this.buildURL('user'),
            url = `${baseUrl}/logout`;
      return this.ajax(url, 'POST');
    }

    refreshToken(serveAppToken) {
      const baseUrl = this.buildURL('user'),
            url = `${baseUrl}/refreshToken`;
      return this.ajax(url, 'POST', {
        data: {
          serveAppToken
        }
      });
    }

    updateRecord(store, type, snapshot) {
      const data = {};
      const serializer = store.serializerFor('user');
      serializer.serializeIntoHash(data, type, snapshot);
      const id = snapshot.id;
      const url = this.buildURL('user', id, snapshot, 'updateRecord');
      return this.ajax(url, 'PUT', {
        data: data.user
      });
    }

  }

  _exports.default = UserAdapter;
});