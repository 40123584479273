define("mobile-web/helpers/membership-icon", ["exports", "true-myth", "mobile-web/lib/payment", "mobile-web/lib/payment/card"], function (_exports, _trueMyth, _payment, _card) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.membershipIcon = membershipIcon;
  _exports.default = void 0;

  function getIcon(membership) {
    switch (membership.cardType) {
      case _card.Type.VISA:
        return 'r-visa';

      case _card.Type.AMEX:
        return 'r-amex';

      case _card.Type.Discover:
        return 'r-discover';

      case _card.Type.Mastercard:
        return 'r-mastercard';

      default:
        if (Ember.isNone(membership.billingScheme)) {
          return membership.isGiftCard ? 'r-gift-card' : 'r-credit-card';
        }

        return membership.billingScheme.provider === _payment.Provider.CreditCard ? 'r-credit-card' : 'r-gift-card';
    }
  }

  function membershipIcon(params)
  /*, hash*/
  {
    return _trueMyth.Maybe.of(params).andThen(_trueMyth.Maybe.head).map(getIcon).unwrapOr('r-gift-card');
  }

  var _default = Ember.Helper.helper(membershipIcon);

  _exports.default = _default;
});