define("mobile-web/components/r-menu-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qC0vUqny",
    "block": "[[[41,[51,[30,0,[\"channel\",\"settings\",\"showFullMenu\"]]],[[[1,\"  \"],[11,0],[17,1],[12],[1,\"\\n    \"],[10,\"label\"],[15,0,[30,0,[\"style\",\"root\"]]],[14,\"data-test-menuFilter\",\"\"],[12],[1,\"\\n      \"],[10,1],[15,0,[30,0,[\"style\",\"label\"]]],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@ariaLabel\",\"@class\",\"@icon\"],[\"\",[30,0,[\"style\",\"icon\"]],\"r-restaurant-menu\"]],null],[1,\"\\n        \"],[10,1],[15,0,[30,0,[\"style\",\"text\"]]],[14,\"data-test-menuFilter-label\",\"\"],[12],[1,\"\\n          \"],[1,[28,[35,2],[\"mwc.menuFilter.fullMenuLabel\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,3],[[16,\"aria-label\",[28,[37,2],[\"mwc.menuFilter.fullMenuLabel\"],null]]],[[\"@value\",\"@onChange\"],[[30,0,[\"storage\",\"showFullMenu\"]],[30,0,[\"onChange\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"&attrs\"],false,[\"unless\",\"r-svg\",\"t\",\"r-toggle\"]]",
    "moduleName": "mobile-web/components/r-menu-filter/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});