define("mobile-web/components/r-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ajibd6Ai",
    "block": "[[[44,[[50,[28,[37,2],[[30,0,[\"tag\"]]],null],0,null,[[\"tagName\"],[[30,0,[\"tag\"]]]]]],[[[1,\"  \"],[8,[30,1],[[16,0,[30,0,[\"style\",\"card\"]]],[16,\"data-test-card\",[30,0,[\"testSelector\"]]],[17,2]],null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[15,0,[30,0,[\"style\",\"content\"]]],[14,\"data-test-card\",\"content\"],[12],[1,\"\\n      \"],[18,3,null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[15,0,[30,0,[\"style\",\"buttons\"]]],[14,\"data-test-card\",\"buttons\"],[12],[1,\"\\n      \"],[18,4,[[28,[37,4],null,[[\"class\"],[[30,0,[\"style\",\"button\"]]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]],[\"Tag\",\"&attrs\",\"&content\",\"&buttons\"],false,[\"let\",\"component\",\"-element\",\"yield\",\"hash\"]]",
    "moduleName": "mobile-web/components/r-card/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});