define("mobile-web/transforms/object", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ObjectTransform extends _emberData.default.Transform {
    deserialize(serialized) {
      return Ember.isNone(serialized) ? {} : serialized;
    }

    serialize(deserialized) {
      return Ember.isNone(deserialized) ? {} : deserialized;
    }

  }

  _exports.default = ObjectTransform;
});