define("mobile-web/templates/application-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hjjvFbRs",
    "block": "[[[8,[39,0],null,[[\"@errorData\"],[[30,0,[\"model\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,\"data-test-app-error\",\"\"],[14,5,\"height: 100%; padding: 100px 20px;\"],[12],[1,\"\\n\"],[41,[30,0,[\"model\",\"friendlyMessage\"]],[[[1,\"      \"],[10,\"h1\"],[14,5,\"padding-bottom: 10px;\"],[12],[1,[30,0,[\"model\",\"messageHeader\"]]],[13],[1,\"\\n      \"],[1,[30,0,[\"model\",\"friendlyMessage\"]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,\"h1\"],[14,5,\"text-align: center;\"],[12],[1,\"Sorry, something went wrong with your request. Please try again in a few minutes.\"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"r-application-frame\",\"if\"]]",
    "moduleName": "mobile-web/templates/application-error.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});