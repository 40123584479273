define("mobile-web/components/r-credit-card-elements/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "mobile-web/components/r-credit-card-elements/style"], function (_exports, _glimmerComponent, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var StripeError;

  (function (StripeError) {
    StripeError["Card"] = "Your card number is invalid.";
    StripeError["Cvc"] = "The CVC number is incorrect.";
    StripeError["Zip"] = "The ZIP/postal code is incorrect.";
  })(StripeError || (StripeError = {}));

  /**
   * @summary
   * `CreditCardElements` renders iframes in place of existing credit card form fields (number, expiry, cvc).
   * The elements are created by and managed through Olo Pay JS which wraps Stripe functionality.
   * GitHub: https://github.com/ololabs/olo-pay-js-sdk
   */
  let CreditCardElements = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, (_class = class CreditCardElements extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "numberComplete", _descriptor2, this);

      _initializerDefineProperty(this, "expiryComplete", _descriptor3, this);

      _initializerDefineProperty(this, "cvcComplete", _descriptor4, this);

      _initializerDefineProperty(this, "numberError", _descriptor5, this);

      _initializerDefineProperty(this, "expiryError", _descriptor6, this);

      _initializerDefineProperty(this, "cvcError", _descriptor7, this);
    }

    // Getters and setters
    // Constructor
    // Other methods
    mapError(message) {
      if (!message) return '';
      const errors = {
        [StripeError.Card]: this.intl.t('mwc.oloPay.errorMessages.card'),
        [StripeError.Cvc]: this.intl.t('mwc.oloPay.errorMessages.cvc'),
        [StripeError.Zip]: this.intl.t('mwc.oloPay.errorMessages.zip')
      }; // Map errors from Stripe => Serve

      return errors[message] || message;
    }

    updateValidation() {
      this.args.onValidationChange(this.numberComplete && this.expiryComplete && this.cvcComplete);
    } // Tasks
    // Actions


    setOloPayListeners() {
      const elements = this.args.cardElements;
      if (!elements) return;
      const numberInput = elements.cardNumber;
      const expiryInput = elements.cardExpiry;
      const cvcInput = elements.cardCvc;
      numberInput == null ? void 0 : numberInput.on('change', e => {
        var _e$complete, _e$error;

        // Update the card type on number change
        this.args.onBrandChange(e.brand);
        this.numberComplete = (_e$complete = e.complete) != null ? _e$complete : false;
        this.numberError = this.mapError((_e$error = e.error) == null ? void 0 : _e$error.message);
        this.updateValidation();
      });
      expiryInput == null ? void 0 : expiryInput.on('change', e => {
        var _e$complete2, _e$error2;

        this.expiryComplete = (_e$complete2 = e.complete) != null ? _e$complete2 : false;
        this.expiryError = this.mapError((_e$error2 = e.error) == null ? void 0 : _e$error2.message);
        this.updateValidation();
      });
      cvcInput == null ? void 0 : cvcInput.on('change', e => {
        var _e$complete3, _e$error3;

        this.cvcComplete = (_e$complete3 = e.complete) != null ? _e$complete3 : false;
        this.cvcError = this.mapError((_e$error3 = e.error) == null ? void 0 : _e$error3.message);
        this.updateValidation();
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "numberComplete", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "expiryComplete", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "cvcComplete", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "numberError", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "expiryError", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "cvcError", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setOloPayListeners", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setOloPayListeners"), _class.prototype)), _class));
  _exports.default = CreditCardElements;
});