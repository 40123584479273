define("mobile-web/components/routes/menu/vendor/rewards-route/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lk4fSEZY",
    "block": "[[[10,0],[14,\"data-test-routes-menu-vendor-rewardsRoute\",\"\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@vendor\",\"@showMenuLink\",\"@showVendorPhoneNumber\",\"@showInfoModal\",\"@onToggleFavorite\"],[[30,1],true,[30,0,[\"channel\",\"settings\",\"showVendorPhoneNumber\"]],[30,2],[30,0,[\"toggleFavorite\"]]]],null],[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"style\",\"descriptionContainer\"]]],[14,\"data-test-routes-menu-vendor-rewardsRoute-rewardDescription\",\"\"],[12],[1,\"\\n\"],[41,[30,0,[\"allRewards\"]],[[[41,[30,0,[\"managedReward\"]],[[[1,\"        \"],[8,[39,2],null,[[\"@href\",\"@target\",\"@testSelector\"],[[30,0,[\"managedReward\",\"manageRewardsUrl\"]],\"_blank\",\"manage-rewards-link\"]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,0,[\"managedReward\",\"manageRewardsText\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,2],[15,0,[30,0,[\"style\",\"rewardDescription\"]]],[12],[1,[28,[35,3],[\"mwc.rewards.availableRewardsText\"],null]],[13],[1,\"\\n\"]],[]]]],[]],[[[1,\"      \"],[10,2],[15,0,[30,0,[\"style\",\"noOffers\"]]],[12],[1,\"\\n        \"],[8,[39,4],null,[[\"@key\",\"@defaultMessage\"],[\"OFFERS_NOT_AVAILABLE\",[28,[37,3],[\"mwc.rewards.offersNotAvailableText\"],[[\"channelName\"],[[30,0,[\"channel\",\"current\",\"name\"]]]]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n  \"],[8,[39,5],null,[[\"@models\"],[[30,0,[\"allRewards\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],null,[[\"@class\",\"@reward\",\"@vendor\",\"@showScheme\"],[[30,3,[\"itemClass\"]],[30,3,[\"model\"]],[30,1],[28,[37,7],[[30,0,[\"accounts\",\"length\"]],1],null]]],null],[1,\"\\n  \"]],[3]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@vendor\",\"@showInfoModal\",\"grid\"],false,[\"r-vendor-info\",\"if\",\"r-href\",\"t\",\"x-content\",\"r-card/grid\",\"r-reward-card\",\"gt\"]]",
    "moduleName": "mobile-web/components/routes/menu/vendor/rewards-route/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});