define("mobile-web/instance-initializers/error-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
   * We use this instance initializer in order to eagerly instantiate the error service.
   * Please do not remove this.
   * It is needed in order for the error service init to run, which attaches a handler
   * to Ember.onerror, which lets us show error banners whenever there is an error in
   * the system anywhere. Global error handling is global and fun.
   */
  function initialize(appInstance) {
    const errorService = appInstance.lookup('service:error');

    if (!errorService) {
      throw new Error('Failed to instantiate the error service');
    }
  }

  var _default = {
    name: 'error-handler',
    initialize
  };
  _exports.default = _default;
});