define("mobile-web/serializers/qualifying-loyalty-reward", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class QualifyingLoyaltyRewardsSerializer extends _emberData.default.RESTSerializer {
    constructor(...args) {
      super(...args);
      this.isNewSerializerAPI = true;
      this.primaryKey = 'externalReference';
    }

  }

  _exports.default = QualifyingLoyaltyRewardsSerializer;
});