define("mobile-web/routes/arrival", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ArrivalRoute extends Ember.Route {
    constructor(...args) {
      super(...args);
      this.titleToken = 'Arrival Confirmed';
    }

    model(params) {
      return this.store.findRecord('order', params.id, {
        adapterOptions: {
          hash: params.hash
        }
      }).then(order => Ember.RSVP.hash({
        order,
        utmMedium: params.utm_medium
      }));
    }

  }

  _exports.default = ArrivalRoute;
});