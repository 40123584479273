define("mobile-web/lib/runloop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.safeLater = safeLater;
  _exports.safeNext = safeNext;

  function safeLater(context, action, wait) {
    return Ember.run.later(() => {
      if (!context.isDestroyed && !context.isDestroying) {
        action.bind(context)();
      }
    }, wait);
  }

  function safeNext(context, action) {
    return Ember.run.next(context, () => {
      if (!context.isDestroyed && !context.isDestroying) {
        action.bind(context)();
      }
    });
  }
});