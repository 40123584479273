define("mobile-web/components/r-product-customization/inline-choice/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_1nfm93",
    "isSelected": "_isSelected_1nfm93",
    "groupSelect": "_groupSelect_1nfm93",
    "segment": "_segment_1nfm93",
    "dropdown": "_dropdown_1nfm93"
  };
  _exports.default = _default;
});