define("mobile-web/adapters/account", ["exports", "mobile-web/adapters/application", "mobile-web/lib/utilities/_"], function (_exports, _application, _) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.AccountAction = void 0;
  let AccountAction;
  _exports.AccountAction = AccountAction;

  (function (AccountAction) {
    AccountAction["Register"] = "register";
    AccountAction["Upgrade"] = "upgrade";
  })(AccountAction || (_exports.AccountAction = AccountAction = {}));

  class AccountAdapter extends _application.default {
    register(userRegisterModel) {
      const url = this.buildURL('account');
      return this.ajax(url, 'POST', {
        data: (0, _.pick)(userRegisterModel, 'firstName', 'lastName', 'email', 'contactNumber', 'password', 'confirmPassword', 'acceptedTerms', 'optIn')
      });
    }

    upgrade(userRegisterModel) {
      const url = `${this.buildURL('account')}/upgrade`;
      return this.ajax(url, 'POST', {
        data: (0, _.pick)(userRegisterModel, 'password', 'confirmPassword', 'optIn', 'acceptedTerms', 'lastOrderId')
      });
    }

  }

  _exports.default = AccountAdapter;
});