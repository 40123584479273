define("mobile-web/models/billing-details", ["exports", "ember-data", "mobile-web/lib/payment"], function (_exports, _emberData, _payment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.BILLING_DETAILS_ID = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // Hardcoded ID for dynamic model.
  const BILLING_DETAILS_ID = 1;
  /**
   * This is a "dynamic model": a model that doesn't actually map to a persisted object.
   * It's used to represent a response from the API.
   *
   * This isn't technically the right use case for a model. Models should be objects
   * that are persisted in the backend. However, the `BasketModel` treats this as if
   * it were a persisted backend object; billing details live in a separate endpoint
   * from the parent basket model. We're making it work by assuming that there will
   * never be more than one `BillingDetails` in the store at any time, so the ID
   * doesn't matter.
   */

  _exports.BILLING_DETAILS_ID = BILLING_DETAILS_ID;
  let BillingDetails = (_dec = _emberData.default.attr('boolean', {
    defaultValue: false
  }), _dec2 = _emberData.default.attr('boolean', {
    defaultValue: false
  }), _dec3 = _emberData.default.attr('boolean', {
    defaultValue: false
  }), _dec4 = _emberData.default.attr('boolean', {
    defaultValue: false
  }), _dec5 = _emberData.default.attr('boolean', {
    defaultValue: false
  }), _dec6 = _emberData.default.belongsTo('basket'), _dec7 = _emberData.default.hasMany('billing-scheme', {
    async: false
  }), _dec8 = Ember.computed('billingSchemes.[]', 'canPayCash'), (_class = class BillingDetails extends _emberData.default.Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "allowCardsOnFile", _descriptor, this);

      _initializerDefineProperty(this, "allowSplitPayments", _descriptor2, this);

      _initializerDefineProperty(this, "canPayCash", _descriptor3, this);

      _initializerDefineProperty(this, "doCreditCardStreetAddressValidation", _descriptor4, this);

      _initializerDefineProperty(this, "showTip", _descriptor5, this);

      _initializerDefineProperty(this, "basket", _descriptor6, this);

      _initializerDefineProperty(this, "billingSchemes", _descriptor7, this);
    }

    get filteredBillingSchemes() {
      return this.billingSchemes.filter(scheme => {
        if (scheme.provider === _payment.Provider.PassThrough) {
          return this.canPayCash;
        } else if (scheme.provider === _payment.Provider.DigitalWallet) {
          return false;
        }

        return true;
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "allowCardsOnFile", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "allowSplitPayments", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "canPayCash", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "doCreditCardStreetAddressValidation", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showTip", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "billingSchemes", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "filteredBillingSchemes", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "filteredBillingSchemes"), _class.prototype)), _class));
  _exports.default = BillingDetails;
});