define("mobile-web/components/r-post-checkout/r-thanks-message/component", ["exports", "@ember-decorators/component", "mobile-web/components/r-post-checkout/r-thanks-message/style"], function (_exports, _component, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let ThanksMessage = (_dec = (0, _component.tagName)(''), _dec(_class = class ThanksMessage extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    } // Computed properties
    // Init
    // Other methods
    // Actions


  }) || _class);
  _exports.default = ThanksMessage;
});