define("mobile-web/templates/contact-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Os/KL5Wg",
    "block": "[[[1,[28,[35,0],null,[[\"model\"],[[33,1]]]]],[1,\"\\n\"]],[],false,[\"r-static-page\",\"model\"]]",
    "moduleName": "mobile-web/templates/contact-us.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});