define("mobile-web/routes/vendor-search-results", ["exports", "mobile-web/decorators/indexed", "mobile-web/lib/computed", "mobile-web/lib/dayjs", "mobile-web/lib/order-criteria", "mobile-web/lib/utilities/_"], function (_exports, _indexed, _computed, _dayjs, _orderCriteria, _) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.search = search;
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function search() {
    const localVendorSearchParams = this.localVendorSearchParams || {};
    const {
      latitude,
      longitude
    } = localVendorSearchParams;
    const hasCoords = !!latitude || !!longitude;
    const address = hasCoords ? '' : localVendorSearchParams.address;
    const noAddress = (0, _.isEmpty)(address);
    const noSearch = noAddress && !hasCoords;

    const queryParams = _extends({}, localVendorSearchParams, {
      address
    });

    const results = noSearch ? Ember.RSVP.resolve([]) : this.store.loadRecords('vendor-search-result', queryParams);
    return Ember.RSVP.hash({
      address,
      results,
      noSearch
    });
  }

  function criteriaFromVendorSearchParams({
    addressId,
    address,
    building,
    city,
    zipCode,
    latitude,
    longitude,
    timeWanted: time,
    timeWantedType,
    handoffMode
  }) {
    const invalidAddressOrHandoffMode = !(0, _orderCriteria.isDeliveryMode)(handoffMode) && !(0, _orderCriteria.isAtStoreMode)(handoffMode) || (0, _orderCriteria.isDeliveryMode)(handoffMode) && (Ember.isNone(address) || Ember.isNone(city) || Ember.isNone(zipCode)) || (0, _orderCriteria.isAtStoreMode)(handoffMode) && Ember.isNone(address) && (Ember.isNone(latitude) || Ember.isNone(longitude));

    if (invalidAddressOrHandoffMode) {
      return undefined;
    }

    let timeWanted;

    if (timeWantedType === 'Advance') {
      timeWanted = {
        timeWantedType: 'Advance',
        timeWanted: (0, _dayjs.default)(time)
      };
    } else {
      timeWanted = {
        timeWantedType
      };
    }

    let handoffModeCriteria;

    if ((0, _orderCriteria.isDeliveryMode)(handoffMode)) {
      handoffModeCriteria = {
        handoffMode,
        deliveryAddress: {
          id: addressId != null ? addressId : '',
          streetAddress: address != null ? address : '',
          building: building != null ? building : '',
          city: city != null ? city : '',
          zipCode: zipCode != null ? zipCode : ''
        }
      };
    } else if ((0, _orderCriteria.isAtStoreMode)(handoffMode)) {
      handoffModeCriteria = {
        handoffMode,
        searchAddress: latitude && longitude ? 'Your Current Location' : address != null ? address : '',
        searchCoords: latitude && longitude ? {
          latitude: Number(latitude),
          longitude: Number(longitude)
        } : undefined
      };
    } else {
      handoffModeCriteria = {
        handoffMode: 'Unspecified'
      };
    }

    return _extends({}, handoffModeCriteria, timeWanted);
  }

  let VendorSearchResultsRoute = (_dec = Ember.inject.service, _dec2 = Ember._action, (0, _indexed.default)(_class = (_class2 = class VendorSearchResultsRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "orderCriteria", _descriptor, this);

      _initializerDefineProperty(this, "localVendorSearchParams", _descriptor2, this);

      this.titleToken = 'Location Results';
    }

    beforeModel(transition) {
      var _this$localVendorSear;

      const criteria = criteriaFromVendorSearchParams((_this$localVendorSear = this.localVendorSearchParams) != null ? _this$localVendorSear : {});

      if (criteria) {
        this.orderCriteria.searchOrderCriteria = criteria;
      } else {
        transition.abort();
        this.transitionTo('application');
      }
    }

    model() {
      return search.call(this);
    }

    searchDidChange() {
      this.refresh();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "orderCriteria", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "localVendorSearchParams", [_computed.local], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "searchDidChange", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "searchDidChange"), _class2.prototype)), _class2)) || _class);
  _exports.default = VendorSearchResultsRoute;
});