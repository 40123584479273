define("mobile-web/components/routes/favorites-route/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "ember-concurrency", "ember-concurrency-ts", "mobile-web/components/routes/favorites-route/style"], function (_exports, _glimmerComponent, _emberConcurrency, _emberConcurrencyTs, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FavoritesRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class FavoritesRoute extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      _initializerDefineProperty(this, "bus", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "reorder", _descriptor4, this);

      _initializerDefineProperty(this, "router", _descriptor5, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "currentFavorite", _descriptor6, this);

      this.deleteFavoriteTask = (0, _emberConcurrencyTs.taskFor)(this.deleteFavoriteTaskInstance);
    }

    // Getters and setters
    get favoriteProducts() {
      var _this$currentFavorite, _this$currentFavorite2;

      return (_this$currentFavorite = (_this$currentFavorite2 = this.currentFavorite) == null ? void 0 : _this$currentFavorite2.favoriteProducts.toArray()) != null ? _this$currentFavorite : [];
    } // Constructor
    // Other methods
    // Tasks


    *deleteFavoriteTaskInstance(favorite) {
      yield favorite.destroyRecord();
    } // Actions


    favoriteAction(favorite) {
      if (favorite.isDisabled) {
        this.router.transitionTo('menu.vendor', favorite.vendorSlug);
      } else {
        this.reorder.reorder(favorite);
      }
    }

    deleteFavorite(favorite) {
      this.bus.trigger('confirm', {
        title: this.intl.t('mwc.favorites.delete.confirmTitle'),
        content: this.intl.t('mwc.favorites.delete.confirmText'),
        buttonLabel: this.intl.t('mwc.favorites.delete.confirmButton'),
        onConfirm: () => this.deleteFavoriteTask.perform(favorite),
        testSelector: 'confirmDelete',
        buttonTestSelector: 'confirmButton'
      });
    }

    startOrder() {
      this.router.transitionTo('index');
    }

    viewFavorite(favorite) {
      this.currentFavorite = favorite;
    }

    closeFavorite() {
      this.currentFavorite = undefined;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "bus", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "reorder", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "currentFavorite", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "deleteFavoriteTaskInstance", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "deleteFavoriteTaskInstance"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "favoriteAction", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "favoriteAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteFavorite", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "deleteFavorite"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startOrder", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "startOrder"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "viewFavorite", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "viewFavorite"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeFavorite", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "closeFavorite"), _class.prototype)), _class));
  _exports.default = FavoritesRoute;
});