define("mobile-web/helpers/format-phone", ["exports", "mobile-web/lib/contact-number-rules", "mobile-web/lib/utilities/is-some"], function (_exports, _contactNumberRules, _isSome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatPhone = formatPhone;
  _exports.default = void 0;

  function formatPhone(params)
  /*, hash*/
  {
    let value = params[0];

    if ((0, _isSome.default)(value)) {
      if (value.length === 11) {
        value = value.substr(1, 10);
      } else if (value.length === 12) {
        value = value.substr(2, 10);
      }

      return (0, _contactNumberRules.applyPhoneMask)(value);
    }

    return value;
  }

  var _default = Ember.Helper.helper(formatPhone);

  _exports.default = _default;
});