define("mobile-web/components/r-product-card/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_sux8ub",
    "isLandscape": "_isLandscape_sux8ub",
    "hasImage": "_hasImage_sux8ub",
    "menu": "_menu_sux8ub",
    "row": "_row_sux8ub",
    "image": "_image_sux8ub",
    "page": "_page_sux8ub",
    "modal": "_modal_sux8ub",
    "list": "_list_sux8ub",
    "featured": "_featured_sux8ub",
    "quickAdd": "_quickAdd_sux8ub",
    "animatedContainer": "_animatedContainer_sux8ub",
    "quickAddIcon": "_quickAddIcon_sux8ub",
    "doneIcon": "_doneIcon_sux8ub _quickAddIcon_sux8ub",
    "productInfo": "_productInfo_sux8ub",
    "hasFeatured": "_hasFeatured_sux8ub",
    "name": "_name_sux8ub",
    "hasQuickAdd": "_hasQuickAdd_sux8ub",
    "productButton": "_productButton_sux8ub",
    "priceLine": "_priceLine_sux8ub",
    "hasOverride": "_hasOverride_sux8ub",
    "overrideLabel": "_overrideLabel_sux8ub",
    "price": "_price_sux8ub",
    "calories": "_calories_sux8ub",
    "hasPrice": "_hasPrice_sux8ub",
    "description": "_description_sux8ub _row_sux8ub",
    "fullDescription": "_fullDescription_sux8ub",
    "availability": "_availability_sux8ub _row_sux8ub"
  };
  _exports.default = _default;
});