define("mobile-web/components/r-product-customization/display-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ubsI3N/z",
    "block": "[[[11,0],[16,0,[30,0,[\"style\",\"root\"]]],[24,\"data-test-productCustomization-displayGroup\",\"\"],[17,1],[12],[1,\"\\n\"],[41,[30,2,[\"hasChoiceQuantities\"]],[[[1,\"    \"],[10,2],[15,0,[30,0,[\"style\",\"header\"]]],[14,\"data-test-productCustomization-displayGroup-header\",\"\"],[12],[1,[30,3,[\"name\"]]],[13],[1,\"\\n    \"],[10,0],[15,0,[30,0,[\"style\",\"grid\"]]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"basketChoices\"]]],null]],null],null,[[[1,\"        \"],[8,[39,3],null,[[\"@basketChoice\",\"@basketProduct\",\"@choice\",\"@onClick\"],[[30,4],[30,5],[30,4,[\"choice\",\"content\"]],[30,6]]],null],[1,\"\\n\"]],[4]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,4],null,[[\"@basketProduct\",\"@optionGroup\",\"@displayGroup\",\"@onClick\"],[[30,5],[30,2],[30,3],[30,6]]],null],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"&attrs\",\"@optionGroup\",\"@displayGroup\",\"bc\",\"@basketProduct\",\"@onClick\"],false,[\"if\",\"each\",\"-track-array\",\"r-product-customization/choice\",\"r-product-customization/inline-choice\"]]",
    "moduleName": "mobile-web/components/r-product-customization/display-group/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});