define("mobile-web/initializers/custom-inflector-rules", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    const inflector = _emberInflector.default.inflector; // Tell the inflector that the plural of "billing-details" is "billing-details"

    inflector.uncountable('billing-details');
  }

  var _default = {
    name: 'custom-inflector-rules',
    initialize
  };
  _exports.default = _default;
});