define("mobile-web/components/r-product-customization/formation-group/component", ["exports", "@glimmer/component", "mobile-web/lib/menu", "mobile-web/lib/runloop", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/classes", "mobile-web/services/focus-manager", "mobile-web/components/r-product-customization/formation-group/style"], function (_exports, _component, _menu, _runloop, _, _classes, _focusManager, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProductCustomizationFormationGroup = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = class ProductCustomizationFormationGroup extends _component.default {
    // Service injections
    // Tracked properties
    // Getters and setters
    get name() {
      var _this$choice$name, _this$choice;

      return (_this$choice$name = (_this$choice = this.choice) == null ? void 0 : _this$choice.name) != null ? _this$choice$name : '';
    }

    get choice() {
      return this.args.optionGroup.choices.objectAt(0);
    }

    get rootClass() {
      return (0, _classes.classes)(this.style.root, {
        [this.style.highlight]: this.highlighted
      });
    }

    get siblingFormationGroups() {
      const optionGroup = this.modalOptionGroup;

      if (optionGroup) {
        const siblings = optionGroup.parentChoice ? optionGroup.parentChoice.optionGroups.content : optionGroup.product.firstLevelOptionGroupModels;
        return siblings.filter(og => !(0, _.isEmpty)(og.formationGroup));
      }

      return [];
    }

    get currentIndex() {
      return this.siblingFormationGroups.findIndex(og => og === this.modalOptionGroup);
    }

    get previousFormationGroup() {
      return this.currentIndex > 0 ? this.siblingFormationGroups[this.currentIndex - 1] : undefined;
    }

    get nextFormationGroup() {
      return this.currentIndex < this.siblingFormationGroups.length - 1 ? this.siblingFormationGroups[this.currentIndex + 1] : undefined;
    }

    get isModalSubmitDisabled() {
      var _this$modalOptionGrou;

      return !((_this$modalOptionGrou = this.modalOptionGroup) != null && _this$modalOptionGrou.canSubmit);
    } // Constructor


    constructor(owner, args) {
      super(owner, args); // The `next` is needed because we reference the basketChoices array in the optionGroup
      // component, and Ember doesn't like you to change arrays in the same runloop that you
      // reference them.

      _initializerDefineProperty(this, "focusManager", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "basketChoice", _descriptor3, this);

      _initializerDefineProperty(this, "highlighted", _descriptor4, this);

      _initializerDefineProperty(this, "isOpen", _descriptor5, this);

      _initializerDefineProperty(this, "modalOptionGroup", _descriptor6, this);

      _initializerDefineProperty(this, "modalBody", _descriptor7, this);

      (0, _runloop.safeNext)(this, () => {
        const choice = this.args.optionGroup.choices.objectAt(0);
        const basketProduct = this.args.optionGroup.activeBasketProduct;
        const {
          choice: basketChoice
        } = (0, _menu.getOrCreateBasketChoice)(this.store, basketProduct, choice);
        this.basketChoice = basketChoice;
        (0, _menu.loadNestedMenuItems)(this.store, basketProduct, choice, true);
      });
    } // Other methods


    initializeFormationGroup(optionGroup) {
      optionGroup.basketChoices.objectAt(0).quantity = 1;
    }

    deinitializeFormationGroup(optionGroup) {
      const basketChoice = optionGroup.basketChoices.objectAt(0);

      if (basketChoice.choice.content.optionGroups.every(og => og.selectedBasketChoices.length === 0)) {
        basketChoice.quantity = 0;
      }
    }

    goToError() {
      this.focusManager.focusFirst(_focusManager.CategoryName.OptionGroupError);
    } // Tasks
    // Actions


    handleNext() {
      if (this.isModalSubmitDisabled) {
        this.goToError();
        return;
      }

      this.deinitializeFormationGroup(this.modalOptionGroup);

      if (this.nextFormationGroup) {
        var _this$modalBody;

        this.initializeFormationGroup(this.nextFormationGroup);
        this.modalOptionGroup = this.nextFormationGroup;
        (_this$modalBody = this.modalBody) == null ? void 0 : _this$modalBody.scrollTo(0, 0);
      } else {
        this.closeModal();
      }
    }

    handlePrevious() {
      var _this$modalBody2;

      this.deinitializeFormationGroup(this.modalOptionGroup);
      this.initializeFormationGroup(this.previousFormationGroup);
      this.modalOptionGroup = this.previousFormationGroup;
      (_this$modalBody2 = this.modalBody) == null ? void 0 : _this$modalBody2.scrollTo(0, 0);
    }

    openModal() {
      var _this$args$onOpen, _this$args;

      this.initializeFormationGroup(this.args.optionGroup);
      this.modalOptionGroup = this.args.optionGroup;
      this.isOpen = true;
      (_this$args$onOpen = (_this$args = this.args).onOpen) == null ? void 0 : _this$args$onOpen.call(_this$args);
    }

    closeModal() {
      var _this$args$onClose, _this$args2;

      this.isOpen = false;
      this.deinitializeFormationGroup(this.args.optionGroup);
      (_this$args$onClose = (_this$args2 = this.args).onClose) == null ? void 0 : _this$args$onClose.call(_this$args2);
    }

    register(target) {
      this.focusData = {
        allowFocus: () => !this.args.optionGroup.canSubmit && !this.args.openFormationGroup,
        onFocus: () => {
          this.highlighted = true;
        },
        target
      };
      this.focusManager.register(_focusManager.CategoryName.OptionGroupError, this.focusData);
    }

    deregister() {
      if (this.focusData) {
        this.focusManager.deregister(_focusManager.CategoryName.OptionGroupError, this.focusData);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "focusManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "basketChoice", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "highlighted", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isOpen", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "modalOptionGroup", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "modalBody", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleNext", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "handleNext"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handlePrevious", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "handlePrevious"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openModal", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "openModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "register", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "register"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deregister", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "deregister"), _class.prototype)), _class));
  _exports.default = ProductCustomizationFormationGroup;
});