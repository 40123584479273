define("mobile-web/lib/time/timezone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.localOffset = localOffset;
  _exports.offsetDifferenceMessage = offsetDifferenceMessage;
  _exports.default = void 0;

  /** Gets the offset from UTC in hours. */
  function localOffset() {
    return new Date().getTimezoneOffset() / -60;
  }
  /**
   * Gets a message indicating the timezone offset difference between the target and source.
   */


  function offsetDifferenceMessage(target, targetOffset = undefined, source = 'you', sourceOffset = localOffset()) {
    if (targetOffset === undefined || targetOffset === sourceOffset) {
      return '';
    }

    const diff = Math.abs(sourceOffset - targetOffset);
    const preposition = targetOffset < sourceOffset ? 'behind' : 'ahead of';
    const hours = diff === 1 ? 'hour' : 'hours';
    return `${target} is ${diff} ${hours} ${preposition} ${source}`;
  }

  var _default = {
    localOffset,
    offsetDifferenceMessage
  };
  _exports.default = _default;
});