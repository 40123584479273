define("mobile-web/lib/utilities/capitalize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = s => s ? s[0].toUpperCase() + s.slice(1) : s;

  _exports.default = _default;
});