define("mobile-web/helpers/vendor-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.vendorTime = vendorTime;
  _exports.default = void 0;

  function vendorTime(utc, vendor) {
    return utc.add(vendor.utcOffset, 'hour');
  }

  function vendorTimeHelper(_params, hash) {
    return vendorTime(hash.utc, hash.vendor);
  }

  var _default = Ember.Helper.helper(vendorTimeHelper);

  _exports.default = _default;
});