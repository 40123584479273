define("mobile-web/models/user", ["exports", "ember-data", "mobile-web/decorators/collection-action"], function (_exports, _emberData, _collectionAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    attr,
    Model
  } = _emberData.default;
  let UserModel = (_dec = attr('string'), _dec2 = attr('string'), _dec3 = attr('string'), _dec4 = attr('string'), _dec5 = attr('boolean'), _dec6 = attr('boolean'), _dec7 = attr('boolean'), _dec8 = attr('boolean', {
    defaultValue: true
  }), _dec9 = attr('boolean'), _dec10 = attr('boolean'), _dec11 = Ember.computed.alias('contactPhone'), _dec12 = (0, _collectionAction.default)({
    type: 'post',
    path: 'forgotpassword'
  }), _dec13 = (0, _collectionAction.default)({
    type: 'post',
    path: 'resetpassword'
  }), _dec14 = (0, _collectionAction.default)({
    type: 'post',
    path: 'changepassword'
  }), _dec15 = (0, _collectionAction.default)({
    type: 'post',
    path: 'setguestuser'
  }), (_class = class UserModel extends Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "firstName", _descriptor, this);

      _initializerDefineProperty(this, "lastName", _descriptor2, this);

      _initializerDefineProperty(this, "emailAddress", _descriptor3, this);

      _initializerDefineProperty(this, "contactPhone", _descriptor4, this);

      _initializerDefineProperty(this, "notifyBySms", _descriptor5, this);

      _initializerDefineProperty(this, "notifyByEmail", _descriptor6, this);

      _initializerDefineProperty(this, "optIn", _descriptor7, this);

      _initializerDefineProperty(this, "optInToSms", _descriptor8, this);

      _initializerDefineProperty(this, "allowOrderFollowUps", _descriptor9, this);

      _initializerDefineProperty(this, "isUpsellEnabled", _descriptor10, this);

      _initializerDefineProperty(this, "contactNumber", _descriptor11, this);

      _initializerDefineProperty(this, "forgotPassword", _descriptor12, this);

      _initializerDefineProperty(this, "resetPassword", _descriptor13, this);

      _initializerDefineProperty(this, "changePassword", _descriptor14, this);

      _initializerDefineProperty(this, "setGuestUser", _descriptor15, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "firstName", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "lastName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "emailAddress", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "contactPhone", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "notifyBySms", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "notifyByEmail", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "optIn", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "optInToSms", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "allowOrderFollowUps", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isUpsellEnabled", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "contactNumber", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "forgotPassword", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "resetPassword", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "changePassword", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "setGuestUser", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = UserModel;
});