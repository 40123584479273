define("mobile-web/components/r-info-tag/component", ["exports", "mobile-web/components/r-info-tag/style"], function (_exports, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.block = _exports.Type = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let Type;
  _exports.Type = Type;

  (function (Type) {
    Type["Positive"] = "positive";
    Type["Cautionary"] = "cautionary";
    Type["Negative"] = "negative";
    Type["Neutral"] = "neutral";
  })(Type || (_exports.Type = Type = {}));

  const block = 'info-tag';
  _exports.block = block;
  let InfoTag = (_dec = Ember.computed('class', 'type'), (_class = class InfoTag extends Ember.Component.extend({
    tagName: ''
  }) {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    }

    get rootClass() {
      return this.class ? [this.class, _style.default[this.type]].join(' ') : _style.default[this.type];
    }

    init() {
      super.init();
      (false && !(Object.values(Type).includes(this.type)) && Ember.assert('`type` must be an `InfoTag.Type`', Object.values(Type).includes(this.type)));
      (false && !(typeof this.label === 'string') && Ember.assert('`label` is required', typeof this.label === 'string'));
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "rootClass", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "rootClass"), _class.prototype)), _class));
  _exports.default = InfoTag;
});