define("mobile-web/lib/self-invoking-callback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createSelfInvokingCallback = createSelfInvokingCallback;

  /**
   * Create a new function that will be called automatically after a
   * certain amount of time unless it is invoked before then.
   * @param cb
   * @param delay
   */
  function createSelfInvokingCallback(cb, delay = 1000) {
    let timeout; // eslint-disable-line prefer-const

    const callback = (...args) => {
      clearTimeout(timeout);
      cb(...args);
    };

    timeout = setTimeout(callback, delay);
    return callback;
  }
});