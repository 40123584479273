define("mobile-web/components/r-facebook-share-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gjyElOGe",
    "block": "[[[11,\"button\"],[24,4,\"button\"],[16,0,[30,0,[\"style\",\"root\"]]],[24,\"aria-label\",\"Share on Facebook\"],[24,\"data-test-facebookShareButton\",\"\"],[17,1],[4,[38,0],[\"click\",[30,0,[\"share\"]]],null],[12],[1,\"\\n  \"],[10,1],[15,0,[30,0,[\"style\",\"text\"]]],[12],[1,\"Share\"],[13],[1,\"\\n  \"],[8,[39,1],null,[[\"@ariaLabel\",\"@class\",\"@icon\"],[\"Facebook\",[30,0,[\"style\",\"icon\"]],\"r-facebook-logo\"]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\"],false,[\"on\",\"r-svg\"]]",
    "moduleName": "mobile-web/components/r-facebook-share-button/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});