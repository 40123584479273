define("mobile-web/components/routes/recent-orders-route/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HojyR0Ed",
    "block": "[[[10,0],[15,0,[30,0,[\"style\",\"root\"]]],[14,\"data-test-routes-recentOrdersRoute\",\"\"],[12],[1,\"\\n  \"],[10,\"h1\"],[15,0,[30,0,[\"style\",\"heading\"]]],[12],[1,[28,[35,0],[\"mwc.accountHeading.recentOrders\"],null]],[13],[1,\"\\n\\n\"],[41,[30,1,[\"length\"]],[[[1,\"    \"],[8,[39,2],null,[[\"@results\"],[[30,1]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[15,0,[30,0,[\"style\",\"empty\"]]],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@ariaLabel\",\"@class\",\"@icon\"],[\"History\",[30,0,[\"style\",\"icon\"]],\"r-history\"]],null],[1,\"\\n      \"],[10,2],[14,\"data-test-routes-recentOrdersRoute-emptyText\",\"\"],[15,0,[30,0,[\"style\",\"emptyText\"]]],[12],[1,\"\\n        \"],[10,\"strong\"],[12],[1,[28,[35,0],[\"mwc.recentOrders.emptyText\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,4],null,[[\"@testSelector\",\"@class\",\"@onClick\"],[\"emptyButton\",[30,0,[\"style\",\"emptyButton\"]],[30,0,[\"startOrder\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,0],[\"mwc.recentOrders.emptyButton\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"@model\"],false,[\"t\",\"if\",\"r-order-search-result-list\",\"r-svg\",\"r-button\"]]",
    "moduleName": "mobile-web/components/routes/recent-orders-route/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});