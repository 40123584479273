define("mobile-web/adapters/loyalty-account", ["exports", "mobile-web/lib/hybrid-util", "mobile-web/adapters/application"], function (_exports, _hybridUtil, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LoyaltyAccountAdapter extends _application.default.extend({
    coalesceFindRequests: true,

    urlForQueryRecord(args) {
      // We need this data in the serializer, so we store it off here
      this.membershipId = args.membershipId;
      this.vendorId = args.vendorId;
      const namespace = this.namespace;
      return `${(0, _hybridUtil.host)()}/${namespace}/loyalty/account`;
    }

  }) {}

  _exports.default = LoyaltyAccountAdapter;
});