define("mobile-web/components/r-address-form/component", ["exports", "@glimmer/component", "mobile-web/lib/location/address", "mobile-web/components/r-address-form/style"], function (_exports, _component, _address, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Mode = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * The editing modes of the form.
   * Only relevant when there are saved addresses.
   */
  let Mode;
  _exports.Mode = Mode;

  (function (Mode) {
    Mode["AddNew"] = "addNew";
    Mode["UseExisting"] = "useExisting";
  })(Mode || (_exports.Mode = Mode = {}));

  function makeAddressOptions(addresses) {
    return addresses.map(a => ({
      label: _address.default.toLabel(a),
      addressId: a.id
    }));
  }

  const modeSegments = [{
    label: 'Use Existing',
    value: Mode.UseExisting
  }, {
    label: 'Add New',
    value: Mode.AddNew
  }];
  let AddressForm = (_dec = Ember.inject.service, _dec2 = Ember.computed('args.model.mode'), _dec3 = Ember.computed('args.model.mode', 'hasAddresses'), _dec4 = Ember._action, _dec5 = Ember._action, (_class = class AddressForm extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      this.modeSegments = modeSegments;
      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get addNew() {
      return this.args.model.mode === Mode.AddNew;
    }

    get useExisting() {
      return this.hasAddresses && this.args.model.mode === Mode.UseExisting;
    }

    get savedAddresses() {
      // It is possible to get an address in the saved address list without an id.
      // I don't know how, but it is. So yeah, we gotta deal with that...
      return this.args.addresses.filter(a => !!a.id);
    }

    get addressOptions() {
      return makeAddressOptions(this.savedAddresses);
    }

    get hasAddresses() {
      return this.savedAddresses.length > 0;
    } // Constructor
    // Other methods
    // Tasks
    // Actions


    onModeChange(segment) {
      const mode = segment.value;
      const firstAddress = this.savedAddresses[0];
      const addressId = mode === Mode.UseExisting && firstAddress ? firstAddress.id.toString() : '';
      this.resetModel(addressId);
      Ember.set(this.args.model, 'mode', mode);
    }

    resetModel(addressId) {
      var _existing$streetAddre, _existing$building, _existing$city, _existing$zipCode;

      const existing = this.savedAddresses.find(a => a.id.toString() === addressId);
      Ember.setProperties(this.args.model, {
        id: addressId,
        streetAddress: (_existing$streetAddre = existing == null ? void 0 : existing.streetAddress) != null ? _existing$streetAddre : '',
        building: (_existing$building = existing == null ? void 0 : existing.building) != null ? _existing$building : '',
        city: (_existing$city = existing == null ? void 0 : existing.city) != null ? _existing$city : '',
        zipCode: (_existing$zipCode = existing == null ? void 0 : existing.zipCode) != null ? _existing$zipCode : ''
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addNew", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "addNew"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "useExisting", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "useExisting"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onModeChange", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onModeChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetModel", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "resetModel"), _class.prototype)), _class));
  _exports.default = AddressForm;
});