define("mobile-web/adapters/billing-scheme", ["exports", "mobile-web/lib/hybrid-util", "mobile-web/adapters/application"], function (_exports, _hybridUtil, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BillingSchemeAdapter extends _application.default {
    buildURL(_modelName, id, _snapshot, requestType) {
      if (requestType === 'balance') {
        return `${(0, _hybridUtil.host)()}/${this.namespace}/giftcards/${id}/balance`;
      }

      if (requestType === 'pinRequired') {
        return `${(0, _hybridUtil.host)()}/${this.namespace}/giftcards`;
      }

      return super.buildURL(...arguments);
    }

  }

  _exports.default = BillingSchemeAdapter;
});