define("mobile-web/routes/basket/basket-products/edit", ["exports", "mobile-web/lib/routing", "mobile-web/lib/runloop"], function (_exports, _routing, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EditRoute = (_dec = Ember.inject.service('basket'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.readOnly('vendor.vendor'), _dec5 = Ember._action, (_class = class EditRoute extends Ember.Route.extend({
    templateName: 'menu/vendor/products',
    controllerName: 'menu/vendor/products'
  }) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "basketService", _descriptor, this);

      _initializerDefineProperty(this, "routerScroll", _descriptor2, this);

      _initializerDefineProperty(this, "vendor", _descriptor3, this);

      _initializerDefineProperty(this, "currentVendor", _descriptor4, this);
    }

    titleToken(model) {
      return `Edit: ${model.basketProduct.productName}`;
    }

    beforeModel(transition) {
      if (!this.basketService.basket) {
        transition.abort();
        this.transitionTo('index');
      }
    }

    async model(params) {
      const vendor = this.currentVendor;
      const basketProductId = params.basket_product_id;
      Ember.set(this, 'previousRoute', (0, _routing.vendorToMenuRoute)(vendor));
      const bp = await this.store.findRecord('basket-product', basketProductId);
      const product = await bp.get('product');
      const displayInModal = !product.hasChoices;

      if (displayInModal) {
        this.routerScroll.set('preserveScrollPosition', true);
      }

      this.basketService.activeBasketProduct = bp;
      return {
        basketProduct: bp,
        displayInModal,
        product
      };
    }

    afterModel(model) {
      if (Ember.isNone(model.basketProduct.basket)) {
        // basket-product has been removed. In testing, model.isDestroyed did not work, but this did.
        this.replaceWith('menu.vendor', this.vendor.vendorSlug);
      }
    }

    loading(transition) {
      const controller = this.controllerFor('menu/vendor/products');
      controller.set('isLoading', true); // @ts-ignore - the types are wrong, there is a `finally` on `transition`

      transition.finally(() => {
        (0, _runloop.safeNext)(this, () => {
          controller.set('isLoading', false);
        });
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "basketService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "routerScroll", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "vendor", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentVendor", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loading", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype)), _class));
  _exports.default = EditRoute;
});