define("mobile-web/lib/strings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VALIDATION_PASSWORD_LENGTH = _exports.TOMORROW = _exports.TODAY = _exports.FORM_FIELD_OPTIONAL_LABEL = void 0;
  const FORM_FIELD_OPTIONAL_LABEL = '– Optional';
  _exports.FORM_FIELD_OPTIONAL_LABEL = FORM_FIELD_OPTIONAL_LABEL;
  const TODAY = 'Today';
  _exports.TODAY = TODAY;
  const TOMORROW = 'Tomorrow';
  _exports.TOMORROW = TOMORROW;
  const VALIDATION_PASSWORD_LENGTH = 'Password must be at least 8 characters long.';
  _exports.VALIDATION_PASSWORD_LENGTH = VALIDATION_PASSWORD_LENGTH;
});