define("mobile-web/adapters/dispatch-status", ["exports", "ember-inflector", "mobile-web/lib/hybrid-util", "mobile-web/adapters/application"], function (_exports, _emberInflector, _hybridUtil, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DispatchStatusAdapter extends _application.default.extend({
    pathForType(modelName) {
      return Ember.String.dasherize((0, _emberInflector.pluralize)(modelName));
    },

    sortQueryParams(params) {
      if (params.hasOwnProperty('hash')) {
        return {
          hash: params.hash
        };
      }

      return undefined;
    },

    urlForQuery(query, modelName) {
      const path = this.pathForType(modelName);
      return `${(0, _hybridUtil.host)()}/${this.namespace}/orders/${query.orderId}/${path}`;
    },

    urlForFindAll(modelName, snapshot) {
      const {
        // @ts-ignore -- it exists (this works at runtime) but we don't have enough
        //               info to meaningfully inform TS about DS.Snapshots.
        adapterOptions: {
          orderId,
          hash
        }
      } = snapshot;
      const path = this.pathForType(modelName);
      const query = hash === undefined ? '' : `?hash=${encodeURIComponent(hash)}`;
      return `${(0, _hybridUtil.host)()}/${this.namespace}/orders/${orderId}/${path}${query}`;
    },

    urlForFindRecord(id, modelName, snapshot) {
      const {
        // @ts-ignore -- it exists (this works at runtime) but we don't have enough
        //               info to meaningfully inform TS about DS.Snapshots.
        adapterOptions: {
          orderId,
          hash
        }
      } = snapshot;
      const path = this.pathForType(modelName);
      const query = hash === undefined ? '' : `?hash=${encodeURIComponent(hash)}`;
      return `${(0, _hybridUtil.host)()}/${this.namespace}/orders/${orderId}/${path}/${id}${query}`;
    }

  }) {}

  _exports.default = DispatchStatusAdapter;
});