define("mobile-web/components/r-animated-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z4OZPhhU",
    "block": "[[[11,0],[24,\"data-test-animatedModal\",\"\"],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"renderContent\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@wrapperClass\",\"@modalClass\",\"@title\",\"@buttons\",\"@onClose\",\"@onCancel\",\"@bodyDidInsert\",\"@testSelector\"],[[30,0,[\"wrapperClass\"]],[30,0,[\"modalClass\"]],[30,2],[30,3],[30,4],[30,5],[30,6],[30,7]]],[[\"default\"],[[[[1,\"\\n      \"],[18,10,[[30,8]]],[1,\"\\n    \"]],[8]]]]],[1,\"\\n\"]],[]],null],[41,[30,9],[[[1,\"    \"],[11,1],[4,[38,3],[[30,0,[\"beginOpenAnimation\"]]],null],[4,[38,4],[[30,0,[\"beginCloseAnimation\"]]],null],[12],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"@title\",\"@buttons\",\"@onClose\",\"@onOutsideClick\",\"@bodyDidInsert\",\"@testSelector\",\"modal\",\"@open\",\"&default\"],false,[\"if\",\"r-modal\",\"yield\",\"did-insert\",\"will-destroy\"]]",
    "moduleName": "mobile-web/components/r-animated-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});