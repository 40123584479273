define("mobile-web/components/r-vendor-frame/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nUKBJPj7",
    "block": "[[[11,0],[17,1],[24,\"data-test-vendorFrame\",\"\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@onEdit\"],[[30,0,[\"openOrderCriteriaModal\"]]]],null],[1,\"\\n\\n  \"],[8,[39,1],null,[[\"@vendor\",\"@onToggleFavorite\",\"@showInfoModal\"],[[30,2],[30,0,[\"toggleFavorite\"]],[30,3]]],null],[1,\"\\n\\n\"],[41,[30,2,[\"settings\",\"customerFacingMessage\"]],[[[1,\"    \"],[10,0],[15,0,[30,0,[\"style\",\"alertWrapper\"]]],[14,\"data-test-vendorFrame-cfm\",\"\"],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@class\",\"@iconClass\"],[[30,0,[\"style\",\"cfm\"]],[30,0,[\"style\",\"cfmIcon\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[15,0,[30,0,[\"style\",\"cfmContent\"]]],[12],[1,[30,0,[\"safeCustomerFacingMessage\"]]],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showRewards\"]],[[[1,\"    \"],[8,[39,4],null,null,[[\"default\"],[[[[1,\"\\n      \"],[10,0],[15,0,[30,0,[\"style\",\"row\"]]],[12],[1,\"\\n        \"],[8,[30,4,[\"button\"]],[[16,0,[30,0,[\"style\",\"linkButton\"]]]],null,null],[1,\"\\n\"],[41,[30,4,[\"isOpen\"]],[[[1,\"          \"],[8,[30,4,[\"schemes\"]],null,null,null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"]],[4]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showMenuBanner\"]],[[[1,\"    \"],[8,[39,5],null,null,null],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[18,5,null],[1,\"\\n\\n\"],[41,[30,0,[\"vendor\",\"disclaimers\"]],[[[1,\"    \"],[8,[39,7],null,[[\"@disclaimers\"],[[30,0,[\"vendor\",\"disclaimers\"]]]],null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"@vendor\",\"@showInfoModal\",\"link\",\"&default\"],false,[\"r-order-criteria-btn\",\"r-vendor-info\",\"if\",\"r-inline-alert\",\"r-link-rewards\",\"r-menu-banner\",\"yield\",\"r-disclaimers\"]]",
    "moduleName": "mobile-web/components/r-vendor-frame/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});