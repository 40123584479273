define("mobile-web/models/vendor-search-result", ["exports", "ember-data", "mobile-web/components/r-info-tag/component", "mobile-web/decorators/member-action", "mobile-web/lib/time/weekly-schedule", "mobile-web/models/vendor"], function (_exports, _emberData, _component, _memberAction, _weeklySchedule, _vendor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FailureCategory = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FailureCategory;
  _exports.FailureCategory = FailureCategory;

  (function (FailureCategory) {
    FailureCategory["None"] = "None";
    FailureCategory["Address"] = "Address";
    FailureCategory["HandoffMode"] = "HandoffMode";
    FailureCategory["TimeWantedMode"] = "TimeWantedMode";
    FailureCategory["TimeWanted"] = "TimeWanted";
  })(FailureCategory || (_exports.FailureCategory = FailureCategory = {}));

  const resultCategoryIsAvailable = category => category !== FailureCategory.HandoffMode && category !== FailureCategory.TimeWantedMode;

  let VendorSearchResultModel = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = _emberData.default.attr('string'), _dec7 = _emberData.default.attr('string'), _dec8 = _emberData.default.attr('string'), _dec9 = _emberData.default.attr('number'), _dec10 = _emberData.default.attr('number'), _dec11 = _emberData.default.attr('object'), _dec12 = _emberData.default.attr('object'), _dec13 = _emberData.default.attr('boolean'), _dec14 = _emberData.default.attr('boolean'), _dec15 = _emberData.default.attr('boolean'), _dec16 = _emberData.default.attr('boolean'), _dec17 = _emberData.default.attr('boolean'), _dec18 = _emberData.default.attr('boolean'), _dec19 = _emberData.default.attr('boolean'), _dec20 = _emberData.default.attr('array'), _dec21 = _emberData.default.attr('string'), _dec22 = _emberData.default.attr('object'), _dec23 = _emberData.default.attr('object'), _dec24 = Ember.computed.not('hasOnlineOrdering'), _dec25 = Ember.computed('locationAttributes'), _dec26 = Ember.computed('preCheckResult.{failureCategory,isValid}'), _dec27 = Ember.computed('isUnavailable', 'preCheckCategory'), _dec28 = (0, _memberAction.default)({
    type: 'post',
    path: 'toggleasfavorite'
  }), (_class = class VendorSearchResultModel extends _emberData.default.Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "userFeedback", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "error", _descriptor5, this);

      _initializerDefineProperty(this, "name", _descriptor6, this);

      _initializerDefineProperty(this, "slug", _descriptor7, this);

      _initializerDefineProperty(this, "phoneNumber", _descriptor8, this);

      _initializerDefineProperty(this, "utcOffset", _descriptor9, this);

      _initializerDefineProperty(this, "distance", _descriptor10, this);

      _initializerDefineProperty(this, "address", _descriptor11, this);

      _initializerDefineProperty(this, "timeWantedSchedule", _descriptor12, this);

      _initializerDefineProperty(this, "canDeliver", _descriptor13, this);

      _initializerDefineProperty(this, "canPickup", _descriptor14, this);

      _initializerDefineProperty(this, "isFavorite", _descriptor15, this);

      _initializerDefineProperty(this, "displayNationalMenu", _descriptor16, this);

      _initializerDefineProperty(this, "hasOnlineOrdering", _descriptor17, this);

      _initializerDefineProperty(this, "menuIsReadOnly", _descriptor18, this);

      _initializerDefineProperty(this, "isAcceptingOrders", _descriptor19, this);

      _initializerDefineProperty(this, "locationAttributes", _descriptor20, this);

      _initializerDefineProperty(this, "todaysHours", _descriptor21, this);

      _initializerDefineProperty(this, "preCheckResult", _descriptor22, this);

      _initializerDefineProperty(this, "overrideReason", _descriptor23, this);

      _initializerDefineProperty(this, "isUnavailable", _descriptor24, this);

      _initializerDefineProperty(this, "toggleFavoriteAction", _descriptor25, this);
    }

    get attributes() {
      return this.locationAttributes.map(({
        name: content
      }) => ({
        type: _component.Type.Neutral,
        content
      }));
    }

    get preCheckCategory() {
      return Ember.isNone(this.preCheckResult) || this.preCheckResult.isValid ? FailureCategory.None : this.preCheckResult.failureCategory;
    }

    get available() {
      return !this.isUnavailable && resultCategoryIsAvailable(this.preCheckCategory);
    }

    tags(day) {
      var _this$preCheckResult;

      const schedule = this.timeWantedSchedule ? _weeklySchedule.default.toTags(this.timeWantedSchedule, day.format('dddd')) : [];
      const tags = [...schedule, ...this.attributes];

      if ((_this$preCheckResult = this.preCheckResult) != null && _this$preCheckResult.hasOwnProperty('isValid') && !this.preCheckResult.isValid) {
        tags.push({
          type: _component.Type.Negative,
          content: this.preCheckResult.errorMessage
        });
      }

      if (this.overrideReason) {
        tags.push({
          type: _component.Type.Negative,
          content: (0, _vendor.getShortOverrideDesc)(this.overrideReason, this.intl)
        });
      }

      return tags;
    }

    toggleFavorite() {
      _vendor.toggleVendorFavorite.call(this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "userFeedback", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "error", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "slug", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "phoneNumber", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "utcOffset", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "distance", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "address", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "timeWantedSchedule", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "canDeliver", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "canPickup", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "isFavorite", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "displayNationalMenu", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "hasOnlineOrdering", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "menuIsReadOnly", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "isAcceptingOrders", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "locationAttributes", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "todaysHours", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "preCheckResult", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "overrideReason", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "isUnavailable", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "attributes", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "attributes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "preCheckCategory", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "preCheckCategory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "available", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "available"), _class.prototype), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "toggleFavoriteAction", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = VendorSearchResultModel;
});