define("mobile-web/controllers/checkin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CheckinController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      this.queryParams = ['hash'];
    }

  }

  _exports.default = CheckinController;
});