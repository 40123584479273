define("mobile-web/components/r-segmented-control/component", ["exports", "@ember-decorators/component", "mobile-web/lib/utilities/_", "mobile-web/components/r-segmented-control/style"], function (_exports, _component, _, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SegmentedControl = (_dec = (0, _component.tagName)(''), _dec2 = Ember.computed.alias('rootClass'), _dec3 = Ember.computed('segments.length'), _dec4 = Ember.computed('itemClass', 'segments', 'value'), _dec5 = Ember.computed('label', 'name'), _dec6 = Ember.computed('name', 'segments'), _dec7 = Ember._action, _dec(_class = (_class2 = class SegmentedControl extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "class", _descriptor, this);

      this.style = _style.default;
    }

    get hasSingleSegment() {
      return this.segments.length === 1;
    }

    get buttonClasses() {
      const value = this.value;
      return this.segments.map(s => [_style.default.button, this.itemClass || '', s.value === value ? _style.default.selected : ''].join(' '));
    }

    get groupLabel() {
      return this.label || this.name;
    }

    get inputIds() {
      const name = this.name;
      return this.segments.map((_s, i) => `${name}-${i}`);
    }

    handleChange(segment) {
      this.onChange(segment);
    }

    init() {
      super.init();
      (false && !((0, _.isString)(this.name)) && Ember.assert('`name` is required', (0, _.isString)(this.name)));
      (false && !((0, _.isArray)(this.segments)) && Ember.assert('`segments` is required', (0, _.isArray)(this.segments)));
      (false && !((0, _.isFunction)(this.onChange)) && Ember.assert('`onChange` must be a `function`', (0, _.isFunction)(this.onChange)));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "class", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "hasSingleSegment", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "hasSingleSegment"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "buttonClasses", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "buttonClasses"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "groupLabel", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "groupLabel"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "inputIds", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "inputIds"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleChange", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "handleChange"), _class2.prototype)), _class2)) || _class);
  _exports.default = SegmentedControl;
});