define("mobile-web/components/r-menu-banner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XDMYcMNc",
    "block": "[[[11,0],[16,0,[28,[37,0],[[30,0,[\"style\",\"container\"]],[52,[28,[37,2],[[30,0,[\"loadedImage\"]]],null],[30,0,[\"style\",\"placeholder\"]]]],null]],[24,\"data-test-menuBanner\",\"\"],[17,1],[4,[38,3],[[30,0,[\"handleImageLoad\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"urlDestination\"]],[[[1,\"    \"],[8,[39,4],[[24,\"data-test-menuBanner-link\",\"\"]],[[\"@href\",\"@target\",\"@class\"],[[30,0,[\"urlDestination\"]],[52,[30,0,[\"openUrlNewWindow\"]],\"_blank\",\"_self\"],[30,0,[\"style\",\"link\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,\"img\"],[15,0,[30,0,[\"style\",\"image\"]]],[15,\"src\",[30,0,[\"imageSrc\"]]],[15,\"alt\",[30,0,[\"altText\"]]],[12],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"img\"],[15,0,[30,0,[\"style\",\"image\"]]],[15,\"src\",[30,0,[\"imageSrc\"]]],[15,\"alt\",[30,0,[\"altText\"]]],[12],[13],[1,\"\\n\"]],[]]],[13]],[\"&attrs\"],false,[\"classes\",\"if\",\"not\",\"did-insert\",\"r-href\"]]",
    "moduleName": "mobile-web/components/r-menu-banner/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});