define("mobile-web/templates/privacy-policy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6IllJIFc",
    "block": "[[[1,[28,[35,0],null,[[\"model\"],[[33,1]]]]]],[],false,[\"r-static-page\",\"model\"]]",
    "moduleName": "mobile-web/templates/privacy-policy.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});