define("mobile-web/lib/location/address", ["exports", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/freeze"], function (_exports, _, _freeze) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.makeEmpty = makeEmpty;
  _exports.isAddress = isAddress;
  _exports.isVendorAddress = isVendorAddress;
  _exports.toLabel = toLabel;
  _exports.toMapsApplicationLink = toMapsApplicationLink;
  _exports.distanceBetweenCoords = distanceBetweenCoords;
  _exports.default = _exports.Address = _exports.EMPTY = _exports.isValid = void 0;

  // prettier-ignore
  const isValid = v => !(0, _.isEmpty)(v == null ? void 0 : v.streetAddress) && !(0, _.isEmpty)(v == null ? void 0 : v.city) && !(0, _.isEmpty)(v == null ? void 0 : v.zipCode);

  _exports.isValid = isValid;
  const EMPTY = (0, _freeze.default)({
    streetAddress: '',
    building: '',
    city: '',
    zipCode: ''
  });
  _exports.EMPTY = EMPTY;

  function makeEmpty() {
    return Object.assign({}, EMPTY);
  }

  function isAddress(a) {
    return 'zipCode' in a;
  }

  function isVendorAddress(a) {
    return 'postalCode' in a;
  }

  function toLabel(address) {
    // If the street address is empty, we assume everything else is empty.
    if (!address.streetAddress) {
      return '';
    }

    let label = address.streetAddress + ', ';

    if (isAddress(address) && address.building) {
      label += address.building + ', ';
    } else if (isVendorAddress(address) && address.streetAddress2) {
      label += address.streetAddress2 + ', ';
    }

    label += `${address.city} ${isAddress(address) ? address.zipCode : address.postalCode}`;
    return label;
  }

  const MAPS_WEB_HREF_BASE = 'https://maps.google.com/?q='; // https://stackoverflow.com/a/19464433/4326495

  const IOS_MAPS_NATIVE_HREF_BASE = 'https://maps.apple.com/?q=';

  function toMapsApplicationLink(address, isIOS) {
    const props = isAddress(address) ? (0, _.pick)(address, 'streetAddress', 'building', 'city', 'zipCode') : (0, _.pick)(address, 'streetAddress', 'streetAddress2', 'city', 'state', 'postalCode', 'country');
    const query = Object.values(props).filter(v => !(0, _.isEmpty)(v)).join(' ');
    const base = isIOS ? IOS_MAPS_NATIVE_HREF_BASE : MAPS_WEB_HREF_BASE;
    return base + encodeURIComponent(query);
  }

  function distanceBetweenCoords(start, end) {
    if (!(start && end && start.latitude && start.longitude && end.latitude && end.longitude)) {
      throw new Error('Tried to get coordinate distance without providing coordinates');
    } // Using Haversine formula
    // https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula


    const p = 0.017453292519943295; // Math.PI / 180

    const r = 7917; // 2 * R; R (earth radius) = 6371 km -> 3959 miles

    const c = Math.cos;
    const a = 0.5 - c((end.latitude - start.latitude) * p) / 2 + c(start.latitude * p) * c(end.latitude * p) * (1 - c((end.longitude - start.longitude) * p)) / 2;
    return Math.ceil(r * Math.asin(Math.sqrt(a)) * 10) / 10;
  }

  const Address = {
    EMPTY,
    makeEmpty,
    toLabel,
    toMapsApplicationLink,
    isValid
  };
  _exports.Address = Address;
  var _default = Address;
  _exports.default = _default;
});