define("mobile-web/lib/utilities/numeric", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.currencyToNumber = _exports.numberOr0 = _exports.numberOr = void 0;

  const numberOr = (defaultValue, value) => Number(value) || defaultValue;

  _exports.numberOr = numberOr;

  const numberOr0 = value => numberOr(0, value);
  /**
   * Converts a formatted currency string into a regular number.
   *
   * It does this by stripping out any characters other than
   * numbers, periods, and dashes (for negative numbers).
   *
   * @param currency a string formatted to look like currency
   */


  _exports.numberOr0 = numberOr0;

  const currencyToNumber = currency => Number(currency.replace(/[^0-9.-]+/g, ''));

  _exports.currencyToNumber = currencyToNumber;
});