define("mobile-web/components/r-icons/loading-spinner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L7DSOHmO",
    "block": "[[[8,[39,0],[[24,\"role\",\"status\"],[24,\"aria-busy\",\"true\"],[17,1]],[[\"@ariaLabel\",\"@icon\",\"@class\",\"@iconClass\"],[\"loading...\",\"loading-spinner\",[30,2],[28,[37,1],[[30,0,[\"style\",\"icon\"]],[30,3]],null]]],null]],[\"&attrs\",\"@class\",\"@iconClass\"],false,[\"r-svg\",\"classes\"]]",
    "moduleName": "mobile-web/components/r-icons/loading-spinner/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});