define("mobile-web/components/r-product-customization/option-group/component", ["exports", "@glimmer/component", "mobile-web/lib/menu", "mobile-web/lib/utilities/classes", "mobile-web/components/r-product-customization/option-group/style"], function (_exports, _component, _menu, _classes, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProductCustomizationOptionGroup = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class ProductCustomizationOptionGroup extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "highlighted", _descriptor3, this);
    }

    // Getters and setters
    get nested() {
      var _this$args$nested;

      return (_this$args$nested = this.args.nested) != null ? _this$args$nested : false;
    }

    get groupedChoices() {
      return (0, _menu.groupChoices)(this.args.optionGroup);
    }

    get groupedOptionGroups() {
      return (0, _menu.groupOptionGroups)(this.args.optionGroup.selectedOptionGroups);
    }

    get rootClass() {
      return (0, _classes.classes)(this.style.root, {
        [this.style.highlight]: this.highlighted,
        [this.style.isNested]: this.nested,
        [this.style.isInModal]: this.args.inModal
      });
    }
    /**
     * The label for the toggle button. Elements with `role=status` need all their
     * content as direct child text nodes or label attributes in order for some
     * screen readers to notify users that there was a change of status.
     *
     * This is the text-equivalent of the contents of the toggle button.
     */


    get toggleButtonLabel() {
      const {
        description,
        helpText,
        isRequired,
        isCompleted
      } = this.args.optionGroup;
      const labels = [description];

      if (helpText) {
        labels.push(helpText);
      }

      if (isRequired) {
        labels.push(this.intl.t(`mwc.productCustomization.${isCompleted ? 'completedText' : 'requiredText'}`));
      }

      return labels.join(' ');
    } // Constructor
    // Other methods
    // Tasks
    // Actions


    allowFocus() {
      return !this.args.optionGroup.canSubmit && !this.args.openFormationGroup;
    }

    toggle(basketChoice) {
      const optionGroup = this.args.optionGroup;

      if (basketChoice.quantity === 0) {
        if (optionGroup.canSelectNewChoices) {
          var _basketChoice$choice$;

          if (!optionGroup.multiSelect) {
            optionGroup.selectedBasketChoices.forEach(bc => {
              bc.quantity = 0;
            });
          }

          basketChoice.quantity = (_basketChoice$choice$ = basketChoice.choice.get('minQuantity')) != null ? _basketChoice$choice$ : 1;
        }
      } else {
        basketChoice.quantity = 0;
      }

      optionGroup.splitChoiceQuantities();
      this.args.optionGroup.quantityChanged();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "highlighted", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "allowFocus", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "allowFocus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggle", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype)), _class));
  _exports.default = ProductCustomizationOptionGroup;
});