define("mobile-web/components/r-inline-alert/component", ["exports", "@glimmer/component", "mobile-web/components/r-inline-alert/style"], function (_exports, _component, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class InlineAlert extends _component.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get hideIcon() {
      var _this$args$hideIcon;

      return (_this$args$hideIcon = this.args.hideIcon) != null ? _this$args$hideIcon : false;
    } // Constructor
    // Other methods
    // Tasks
    // Actions


  }

  _exports.default = InlineAlert;
});