define("mobile-web/templates/menu/vendor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KDBQWziy",
    "block": "[[[41,[30,0,[\"isFullScreenProductPage\"]],[[[1,\"  \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,3],null,[[\"@skipPreCheck\",\"@vendor\",\"@recentOrders\",\"@showInfoModal\"],[[30,0,[\"skipPreCheck\"]],[30,0,[\"model\",\"vendor\"]],[30,0,[\"model\",\"recentOrders\"]],[30,0,[\"showInfoModal\"]]]],null],[1,\"\\n\"],[41,[30,0,[\"isProductPage\"]],[[[1,\"    \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"]],[]],null]],[]]]],[],false,[\"if\",\"component\",\"-outlet\",\"routes/menu/vendor-route\"]]",
    "moduleName": "mobile-web/templates/menu/vendor.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});