define("mobile-web/lib/payment/index", ["exports", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some"], function (_exports, _, _isSome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.isMinimallyValid = _exports.isCreditCardWithAddress = _exports.isCreditCard = _exports.isGiftCard = _exports.isCompCard = _exports.GIFT_CARD_FIELDS = _exports.CREDIT_CARD_FIELDS = _exports.isCreateable = _exports.isTipType = _exports.isNonGiftCard = _exports.isCard = _exports.Provider = void 0;
  // \platform\MoboLogic\src\Data Structures\Enums.cs
  let Provider;
  _exports.Provider = Provider;

  (function (Provider) {
    Provider["CreditCard"] = "CreditCard";
    Provider["PassThrough"] = "PassThrough";
    Provider["PaytronixCard"] = "PaytronixCard";
    Provider["Chockstone"] = "Chockstone";
    Provider["RadiantGiftCard"] = "RadiantGiftCard";
    Provider["LevelUp"] = "LevelUp";
    Provider["SvsGiftCard"] = "SvsGiftCard";
    Provider["ElavonGiftCard"] = "ElavonGiftCard";
    Provider["MockGiftCard"] = "MockGiftCard";
    Provider["GiftCard"] = "GiftCard";
    Provider["Prepaid"] = "Prepaid";
    Provider["DigitalWallet"] = "DigitalWallet";
    Provider["CompCard"] = "CompCard";
    Provider["PayPal"] = "PayPal";
    Provider["PayPalMIS"] = "PayPalMIS";
  })(Provider || (_exports.Provider = Provider = {}));

  const CARD_TYPES = [Provider.CreditCard, Provider.PaytronixCard, Provider.RadiantGiftCard, Provider.SvsGiftCard, Provider.ElavonGiftCard, Provider.MockGiftCard, Provider.GiftCard, Provider.DigitalWallet];
  const NON_GIFT_CARD_TYPES = [Provider.CreditCard, Provider.PassThrough, Provider.LevelUp, Provider.DigitalWallet];
  /** Providers with methods that cannot include tip */

  const NO_TIP_TYPES = [Provider.PassThrough, Provider.CompCard];

  const isCard = provider => CARD_TYPES.includes(provider);

  _exports.isCard = isCard;

  const isNonGiftCard = provider => NON_GIFT_CARD_TYPES.includes(provider);

  _exports.isNonGiftCard = isNonGiftCard;

  const isTipType = scheme => !NO_TIP_TYPES.includes(scheme.provider);

  _exports.isTipType = isTipType;
  const isCreateable = isCard;
  _exports.isCreateable = isCreateable;
  const CREDIT_CARD_FIELDS = {
    Basic: ['creditCardNumber', 'cvv', 'expirationMonth', 'expirationYear', 'zipCode', 'countryCode'],
    Address: ['streetAddress', 'city', 'state']
  };
  _exports.CREDIT_CARD_FIELDS = CREDIT_CARD_FIELDS;
  const GIFT_CARD_FIELDS = ['giftCardNumber'];
  _exports.GIFT_CARD_FIELDS = GIFT_CARD_FIELDS;

  const isCompCard = card => card.hasOwnProperty('amount');

  _exports.isCompCard = isCompCard;

  const isGiftCard = card => card.hasOwnProperty('giftCardNumber');

  _exports.isGiftCard = isGiftCard;

  const isCreditCard = card => card.hasOwnProperty('creditCardNumber');

  _exports.isCreditCard = isCreditCard;

  const isCreditCardWithAddress = card => (0, _isSome.default)(card) && card.hasOwnProperty('streetAddress');

  _exports.isCreditCardWithAddress = isCreditCardWithAddress;

  const isMinimallyValid = card => {
    if (isGiftCard(card)) {
      return (0, _isSome.default)(card.giftCardNumber);
    } else if (isCreditCard(card)) {
      return CREDIT_CARD_FIELDS.Basic.every(field => (0, _.has)(card, field));
    }

    throw new Error('invalid card passed to `Payment.isMinimallyValid`');
  };

  _exports.isMinimallyValid = isMinimallyValid;
  const Payment = {
    Provider,
    isGiftCard,
    isCreditCard,
    isMinimallyValid
  };
  var _default = Payment;
  _exports.default = _default;
});