define("mobile-web/models/order-submission", ["exports", "ember-data", "mobile-web/lib/payment/method", "mobile-web/lib/utilities/is-some"], function (_exports, _emberData, _method, _isSome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.UserType = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UserType; // eslint-disable-next-line no-use-before-define

  _exports.UserType = UserType;

  (function (UserType) {
    UserType["Guest"] = "Guest";
    UserType["ExistingUser"] = "ExistingUser";
    UserType["CreateAccount"] = "CreateAccount";
  })(UserType || (_exports.UserType = UserType = {}));

  /**
   * This is another model that doesn't really "exist" in the sense that you can't
   * get an instance back from the server. It exists purely to integrate with Ember Data.
   * The only thing we do with it is generate an instance and call `save`; this submits
   * an order to the backend. It's needed because the data we send to the server
   * (this model) and the data we get back from the server (`OrderModel`) have
   * completely different shapes.
   */
  let OrderSubmission = (_dec = _emberData.default.attr('string'), _dec2 = _emberData.default.attr('string'), _dec3 = _emberData.default.attr('string'), _dec4 = _emberData.default.attr('boolean'), _dec5 = _emberData.default.attr('array'), _dec6 = _emberData.default.attr('array'), _dec7 = _emberData.default.attr('object'), _dec8 = _emberData.default.attr('object'), (_class = class OrderSubmission extends _emberData.default.Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "orderId", _descriptor, this);

      _initializerDefineProperty(this, "basketId", _descriptor2, this);

      _initializerDefineProperty(this, "userType", _descriptor3, this);

      _initializerDefineProperty(this, "guestNewsletterOptIn", _descriptor4, this);

      _initializerDefineProperty(this, "selectedBillingMethods", _descriptor5, this);

      _initializerDefineProperty(this, "customFields", _descriptor6, this);

      _initializerDefineProperty(this, "bookingUser", _descriptor7, this);

      _initializerDefineProperty(this, "receivingUser", _descriptor8, this);
    }

    serializeForStorage() {
      const basket = this.store.peekRecord('basket', this.basketId).serializeForStorage();
      let loyaltyAccount = undefined;

      if (basket.reward) {
        var _this$store$peekAll;

        const match = (_this$store$peekAll = this.store.peekAll('loyalty-account')) == null ? void 0 : _this$store$peekAll.find(a => a.qualifyingLoyaltyRewards.find(r => {
          var _basket$reward;

          return r.externalReference === ((_basket$reward = basket.reward) == null ? void 0 : _basket$reward.externalReference);
        }));

        if (match) {
          loyaltyAccount = {
            membershipId: match.membershipId,
            schemeProviderName: match.schemeProviderName
          };
        }
      }

      const selectedBillingMethods = this.selectedBillingMethods.toArray();
      const memberships = selectedBillingMethods.map(m => {
        if (m.variant === _method.Variant.Membership) {
          var _this$store$peekRecor;

          return {
            id: m.membershipId,
            isGiftCard: (_this$store$peekRecor = this.store.peekRecord('billing-scheme', m.schemeId)) == null ? void 0 : _this$store$peekRecor.isGiftCard
          };
        }

        return undefined;
      }).filter(_isSome.default);
      return {
        basket,
        selectedBillingMethods,
        memberships,
        loyaltyAccount
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "orderId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "basketId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "userType", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "guestNewsletterOptIn", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedBillingMethods", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "customFields", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "bookingUser", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "receivingUser", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OrderSubmission;
});