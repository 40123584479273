define("mobile-web/components/r-card/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "mobile-web/components/r-card/style"], function (_exports, _glimmerComponent, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Card extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get tag() {
      var _this$args$tag;

      return (_this$args$tag = this.args.tag) != null ? _this$args$tag : 'li';
    }

    get testSelector() {
      var _this$args$testSelect;

      return (_this$args$testSelect = this.args.testSelector) != null ? _this$args$testSelect : 'root';
    } // Constructor
    // Other methods
    // Tasks
    // Actions


  }

  _exports.default = Card;
});