define("mobile-web/lib/discount", ["exports", "mobile-web/lib/utilities/is-some"], function (_exports, _isSome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isDiscountApplied = isDiscountApplied;
  _exports.currentDiscountType = currentDiscountType;
  _exports.removeCurrentDiscounts = removeCurrentDiscounts;
  _exports.removeDiscount = removeDiscount;
  _exports.applyDiscount = applyDiscount;
  _exports.default = _exports.validChange = _exports.DiscountTypes = void 0;
  let DiscountTypes;
  _exports.DiscountTypes = DiscountTypes;

  (function (DiscountTypes) {
    DiscountTypes["Offer"] = "Offer";
    DiscountTypes["Coupon"] = "Coupon";
    DiscountTypes["None"] = "None";
  })(DiscountTypes || (_exports.DiscountTypes = DiscountTypes = {}));

  const validChange = change => [DiscountTypes.Coupon, DiscountTypes.Offer].includes(change);

  _exports.validChange = validChange;

  function isDiscountApplied(basketService) {
    if ((0, _isSome.default)(basketService.basket)) {
      return basketService.basket.get('hasReward') || (0, _isSome.default)(basketService.basket.coupon) && (0, _isSome.default)(basketService.basket.coupon.code);
    }

    return false;
  }

  function currentDiscountType(basketService) {
    const {
      basket
    } = basketService;

    if ((0, _isSome.default)(basket)) {
      if (basket.get('hasReward')) {
        return DiscountTypes.Offer;
      } else if ((0, _isSome.default)(basket.coupon) && (0, _isSome.default)(basket.coupon.code)) {
        return DiscountTypes.Coupon;
      }
    }

    return DiscountTypes.None;
  }
  /**
   * IMPURE-FUNCTION
   * This function alters the application state by removing any active
   * reward or coupon in the basket service.
   */


  function removeCurrentDiscounts(basketService) {
    const basket = basketService.basket;

    if ((0, _isSome.default)(basket)) {
      if (basket.hasReward) {
        // We can safely assert here because `reward` is always set if `hasReward`
        // is true.
        const {
          id,
          externalReference
        } = basket.reward;
        return basket.removeReward({
          membershipId: id,
          reference: externalReference
        });
      }

      const currentCouponCode = (0, _isSome.default)(basket.coupon) ? basket.coupon.code : '';

      if (!Ember.isEmpty(currentCouponCode)) {
        return basketService.basket.removeCoupon();
      }
    }

    return Ember.RSVP.resolve();
  }
  /**
   * IMPURE-FUNCTION
   * This function alters the application state by removing a reward or coupon
   * in the basket service.
   */


  function removeDiscount(basketService, change) {
    return change.type === DiscountTypes.Coupon ? basketService.basket.removeCoupon() : basketService.basket.removeReward({
      membershipId: change.detail.id,
      reference: change.detail.reference
    });
  }
  /**
   * IMPURE-FUNCTION
   * This function alters the application state by applying a reward or coupon
   * in the basket service.
   */


  function applyDiscount(basketService, change) {
    return basketService.createBasket().then(() => change.type === DiscountTypes.Coupon ? basketService.basket.applyCoupon(change.detail.code) : basketService.basket.applyReward({
      membershipId: change.detail.id,
      reference: change.detail.reference
    }));
  }

  const Discount = {
    apply: applyDiscount,
    remove: removeDiscount,
    removeAll: removeCurrentDiscounts,
    currentType: currentDiscountType,
    isApplied: isDiscountApplied,
    validChange
  };
  var _default = Discount;
  _exports.default = _default;
});