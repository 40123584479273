define("mobile-web/components/r-cart/upsell-group/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "header": "_header_12z7qt",
    "heading": "_heading_12z7qt",
    "list": "_list_12z7qt",
    "scrollContainer": "_scrollContainer_12z7qt",
    "icon": "_icon_12z7qt"
  };
  _exports.default = _default;
});