define("mobile-web/components/r-basket-transfer-action/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "warningList": "_warningList_1hsrb9"
  };
  _exports.default = _default;
});