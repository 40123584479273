define("mobile-web/templates/secure/checkout/auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Sb8XwAf9",
    "block": "[[[1,[34,0]]],[],false,[\"routes/secure/checkout/auth-route\"]]",
    "moduleName": "mobile-web/templates/secure/checkout/auth.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});