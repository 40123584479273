define("mobile-web/helpers/nonZeroIndex", ["exports", "@glint/environment-ember-loose/ember-component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function nonZeroIndex(index) {
    if (Array.isArray(index)) {
      return +index[0] + 1;
    }

    return +index + 1;
  }

  const nonZeroIndexHelper = (0, _helper.helper)(nonZeroIndex);
  var _default = nonZeroIndexHelper;
  _exports.default = _default;
});