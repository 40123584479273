define("mobile-web/components/r-editable-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lIZI/DrC",
    "block": "[[[11,0],[16,\"aria-labelledby\",[30,0,[\"ids\",\"heading\"]]],[16,\"data-test-editableSection\",[29,[[30,1]]]],[24,\"role\",\"group\"],[17,2],[12],[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"style\",\"headerRow\"]]],[12],[1,\"\\n    \"],[10,\"h2\"],[15,0,[30,0,[\"style\",\"heading\"]]],[15,\"data-test-editableSection-header\",[29,[[30,1]]]],[15,1,[30,0,[\"ids\",\"heading\"]]],[12],[1,\"\\n      \"],[1,[30,3]],[1,\"\\n\"],[41,[30,4],[[[1,\"        \"],[10,1],[15,0,[30,0,[\"style\",\"description\"]]],[12],[1,\"– \"],[1,[30,4]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"],[41,[30,5],[[[1,\"      \"],[11,\"button\"],[16,0,[30,0,[\"style\",\"button\"]]],[24,\"data-test-editableSection-button\",\"edit\"],[4,[38,1],[\"click\",[30,5]],null],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"mwc.editableSection.buttonLabel\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[14,\"data-test-editableSection-content\",\"\"],[12],[18,6,null],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@name\",\"&attrs\",\"@header\",\"@description\",\"@onEdit\",\"&default\"],false,[\"if\",\"on\",\"t\",\"yield\"]]",
    "moduleName": "mobile-web/components/r-editable-section/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});