define("mobile-web/components/r-card/grid/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "ember-animated/motions/move", "ember-animated/motions/opacity", "mobile-web/lib/animation", "mobile-web/lib/scroll", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-card/grid/style"], function (_exports, _glimmerComponent, _move, _opacity, _animation, _scroll, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const GRID_BREAK_5 = 1600;
  const GRID_BREAK_4 = 1280;
  const GRID_BREAK_3 = 960;
  const GRID_BREAK_2 = 640;
  const PAGE_SIZE = 20;
  let CardGrid = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class CardGrid extends _glimmerComponent.default {
    // Service injections
    // Passthroughs
    // Tracked properties
    // set in `onInsert`
    // Getters and setters
    // On the initial render, we see a brief flash of all the cards.
    // These cards then disappear and fade in.
    // If we give the list an initial hidden class, the flash disappears.
    get listClass() {
      let breakPointClass = '';

      if ((0, _isSome.default)(this.args.columnCount)) {
        if (this.args.columnCount >= 5) {
          breakPointClass = 'fiveColumns';
        } else if (this.args.columnCount === 4) {
          breakPointClass = 'fourColumns';
        } else if (this.args.columnCount === 3) {
          breakPointClass = 'threeColumns';
        } else if (this.args.columnCount === 2) {
          breakPointClass = 'twoColumns';
        }
      } else if (this.listElement) {
        const width = this.listElementWidth;

        if (width > GRID_BREAK_5) {
          breakPointClass = 'fiveColumns';
        } else if (width > GRID_BREAK_4) {
          breakPointClass = 'fourColumns';
        } else if (width > GRID_BREAK_3) {
          breakPointClass = 'threeColumns';
        } else if (width > GRID_BREAK_2) {
          breakPointClass = 'twoColumns';
        }
      }

      return `${this.style.list} ${this.args.gridClass || ''} ${_style.default[breakPointClass] || ''} ${this.hide ? _style.default.hidden : ''}`;
    }

    get cards() {
      var _this$args$models$sli, _this$args$models;

      return (_this$args$models$sli = (_this$args$models = this.args.models) == null ? void 0 : _this$args$models.slice(0, this.lastModelProcessed)) != null ? _this$args$models$sli : [];
    } // Constructor


    constructor(owner, args) {
      super(owner, args);
      this.style = _style.default;

      _initializerDefineProperty(this, "hide", _descriptor, this);

      _initializerDefineProperty(this, "lastModelProcessed", _descriptor2, this);

      _initializerDefineProperty(this, "listElement", _descriptor3, this);

      _initializerDefineProperty(this, "listElementWidth", _descriptor4, this);

      _initializerDefineProperty(this, "onScroll", _descriptor5, this);

      this.transition = this.transition.bind(this);
    } // Other methods


    *transition({
      insertedSprites,
      keptSprites,
      removedSprites
    }) {
      this.hide = false; // We yield Promise.all here because we want to wait for this
      // step before starting what comes after.

      yield Promise.all(removedSprites.map(s => (0, _opacity.fadeOut)(s, {
        duration: (0, _animation.getDuration)(500)
      })));

      for (const sprite of keptSprites) {
        if (!sprite.revealed) {
          // Sprites whose animations get disrupted will disappear
          // so we return if they have been interrupted. Bug in Ember animation.
          return;
        }

        (0, _opacity.fadeIn)(sprite);
        (0, _move.default)(sprite);
      }

      for (const sprite of insertedSprites) {
        sprite.startTranslatedBy(0, 30);
        (0, _opacity.fadeIn)(sprite);
        yield (0, _move.default)(sprite, {
          duration: (0, _animation.getDuration)(100)
        });
      }
    }

    loadMoreCards() {
      this.lastModelProcessed = this.lastModelProcessed + PAGE_SIZE;
    } // Tasks
    // Actions


    onResize() {
      this.listElementWidth = this.listElement.clientWidth;
    }

    onInsert(listElement) {
      this.listElement = listElement;
      this.listElementWidth = listElement.clientWidth;
      window.addEventListener('resize', this.onResize);
      this.onScroll = (0, _scroll.default)(() => {
        if (document.documentElement.scrollHeight - window.scrollY - window.innerHeight < 1000) {
          this.loadMoreCards();
        }
      });
      window.addEventListener('scroll', this.onScroll);
    }

    onDestroy() {
      window.removeEventListener('scroll', this.onScroll);
      window.removeEventListener('resize', this.onResize);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "hide", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "lastModelProcessed", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return PAGE_SIZE;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "listElement", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "listElementWidth", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "onScroll", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onResize", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onResize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onInsert", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDestroy", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onDestroy"), _class.prototype)), _class));
  _exports.default = CardGrid;
});