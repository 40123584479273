define("mobile-web/lib/utilities/gtm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bootUpGTMInstance = void 0;

  // Google Analytics loading as a separate file so we can mock it
  // All the ts-ignore because this is Google's crap.
  const bootUpGTMInstance = engineIdentifier => new Promise((res, rej) => {
    /* eslint-disable */
    // Google Tag Manager standard setup code.
    (function (w, d, s, l, i) {
      // @ts-ignore -- this is GA-generated stuff
      w[l] = w[l] || []; // @ts-ignore -- this is GA-generated stuff

      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
      let f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? `&l=${l}` : '';
      j.async = true;
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`; // Wait a frame to be done because GTM will load GA after it has been initialized
      // and if we don't delay then any non-GTM GA inits will happen _before_ the GTM GA inits
      // which will lead to multiple loads of `analytics.js`. Oof.

      j.onload = () => setTimeout(res, 0);

      j.onerror = rej; // @ts-ignore -- this is GA-generated stuff

      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', engineIdentifier);
    /* eslint-enable */

  });

  _exports.bootUpGTMInstance = bootUpGTMInstance;
});