define("mobile-web/models/loyalty-account", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LoyaltyAccountModel = (_dec = _emberData.default.attr('string'), _dec2 = _emberData.default.attr('string'), _dec3 = _emberData.default.attr('number'), _dec4 = _emberData.default.attr(), _dec5 = _emberData.default.attr('boolean'), _dec6 = _emberData.default.attr('string'), _dec7 = _emberData.default.attr('string'), _dec8 = _emberData.default.attr('boolean'), _dec9 = _emberData.default.attr('string'), _dec10 = _emberData.default.hasMany('qualifying-loyalty-reward', {
    async: false
  }), (_class = class LoyaltyAccountModel extends _emberData.default.Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "schemeName", _descriptor, this);

      _initializerDefineProperty(this, "schemeProviderName", _descriptor2, this);

      _initializerDefineProperty(this, "membershipId", _descriptor3, this);

      _initializerDefineProperty(this, "vendorId", _descriptor4, this);

      _initializerDefineProperty(this, "showManageRewardsLink", _descriptor5, this);

      _initializerDefineProperty(this, "manageRewardsUrl", _descriptor6, this);

      _initializerDefineProperty(this, "manageRewardsText", _descriptor7, this);

      _initializerDefineProperty(this, "hasPoints", _descriptor8, this);

      _initializerDefineProperty(this, "balance", _descriptor9, this);

      _initializerDefineProperty(this, "qualifyingLoyaltyRewards", _descriptor10, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "schemeName", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "schemeProviderName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "membershipId", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "vendorId", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showManageRewardsLink", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "manageRewardsUrl", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "manageRewardsText", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "hasPoints", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "balance", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "qualifyingLoyaltyRewards", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = LoyaltyAccountModel;
});