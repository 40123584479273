define("mobile-web/components/r-header-logo/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VdEhx16f",
    "block": "[[[44,[[52,[30,0,[\"disabled\"]],[50,[28,[37,3],[\"span\"],null],0,null,[[\"tagName\"],[\"span\"]]],[52,[28,[37,4],[[30,0,[\"useHref\"]],[30,0,[\"channel\",\"settings\",\"parentSiteUrl\"]]],null],[50,\"r-href\",0,null,[[\"href\",\"target\"],[[30,0,[\"href\"]],[52,[30,0,[\"channel\",\"settings\",\"parentSiteUrl\"]],\"_blank\"]]]],[50,\"r-link-to\",0,[\"index\"],null]]]],[[[1,\"  \"],[10,\"style\"],[12],[1,\"\\n    \"],[1,[30,0,[\"componentStyles\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[30,1],[[16,0,[30,0,[\"style\",\"link\"]]],[24,\"data-test-header-logo\",\"root\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,\"role\",\"img\"],[15,\"aria-labelledby\",[30,0,[\"ids\",\"label\"]]],[15,0,[30,0,[\"style\",\"logo\"]]],[14,\"data-test-header-logo\",\"header\"],[12],[1,\"\\n      \"],[10,1],[15,1,[30,0,[\"ids\",\"label\"]]],[15,0,[30,0,[\"style\",\"label\"]]],[12],[1,[30,0,[\"label\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]],[\"Link\"],false,[\"let\",\"if\",\"component\",\"-element\",\"or\"]]",
    "moduleName": "mobile-web/components/r-header-logo/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});