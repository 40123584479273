define("mobile-web/components/r-card/button/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "label": "_label_pg0at8",
    "button": "_button_pg0at8",
    "normal": "_normal_pg0at8 _button_pg0at8",
    "destructive": "_destructive_pg0at8 _button_pg0at8"
  };
  _exports.default = _default;
});