define("mobile-web/components/loading-indicator/component", ["exports", "@ember-decorators/component", "@glint/environment-ember-loose/glimmer-component", "mobile-web/components/loading-indicator/style"], function (_exports, _component, _glimmerComponent, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let LoadingIndicator = (_dec = (0, _component.tagName)(''), _dec(_class = class LoadingIndicator extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get id() {
      return Ember.guidFor(this);
    }

    get label() {
      var _this$args$label;

      return (_this$args$label = this.args.label) != null ? _this$args$label : 'Loading';
    } // Constructor
    // Other methods


    onInsert() {
      if (this.args.display) {
        var _document$querySelect;

        (_document$querySelect = document.querySelector('[data-focusWhen="loading"]')) == null ? void 0 : _document$querySelect.focus();
      }
    } // Tasks
    // Actions


  }) || _class);
  _exports.default = LoadingIndicator;
});