define("mobile-web/motion/keyframes", ["exports", "ember-animated", "mobile-web/lib/animation"], function (_exports, _emberAnimated, _animation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.keyframes = keyframes;
  _exports.Keyframes = void 0;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  class Keyframes extends _emberAnimated.Motion {
    *animate() {
      const sprite = this.sprite; // eslint-disable-next-line no-null/no-null

      yield (0, _animation.animateTo)(sprite.element, this.opts.keyframes || null, _extends({}, this.opts.keyframeOptions, {
        duration: this.duration
      }));
      yield (0, _emberAnimated.rAF)();
    }

    interrupted() {// noop
    }

  }

  _exports.Keyframes = Keyframes;

  function keyframes(sprite, opts) {
    return new Keyframes(sprite, opts).run();
  }
});