define("mobile-web/components/r-checkout/ordering-from/component", ["exports", "@glimmer/component", "mobile-web/components/r-checkout/ordering-from/style"], function (_exports, _component, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CheckoutOrderingFrom = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class CheckoutOrderingFrom extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "orderCriteria", _descriptor, this);

      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    // Constructor
    // Other methods
    // Tasks
    // Actions
    openModal() {
      this.orderCriteria.set('showAddressModal', true);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "orderCriteria", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openModal", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "openModal"), _class.prototype)), _class));
  _exports.default = CheckoutOrderingFrom;
});