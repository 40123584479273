define("mobile-web/components/r-side-menu-link/component", ["exports", "@glimmer/component", "mobile-web/components/r-side-menu-link/style"], function (_exports, _component, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SideMenuLink extends _component.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get translation() {
      return 'mwc.sideMenu.' + this.args.name + 'Link';
    }

    get testSelector() {
      if (this.args.route) {
        return 'side-menu-' + Ember.String.dasherize(this.args.name);
      }

      return Ember.String.dasherize(this.args.name);
    } // Constructor
    // Other methods
    // Tasks
    // Actions


  }

  _exports.default = SideMenuLink;
});