define("mobile-web/modifiers/is-visible", ["exports", "@glint/environment-ember-loose/ember-modifier", "mobile-web/lib/utilities/_"], function (_exports, _emberModifier, _) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IsVisibleModifier extends _emberModifier.default {
    /**
     * Callback to run when an item becomes visible.
     */
    get onItemsVisible() {
      const fn = this.args.positional[0];
      const fnType = typeof fn;
      (false && !(fnType === 'function') && Ember.assert(`first positional argument must be 'function' but ${fnType} was provided`, fnType === 'function'));
      return fn;
    }
    /**
     * Initialize the intersection observer then listen for intersections.
     */


    didInstall() {
      const {
        threshold = 0.5,
        rootMargin,
        root
      } = this.args.named;
      const opts = {
        threshold,
        rootMargin
      };
      this.rootElement = root && document.querySelector(root) || undefined;

      if (this.rootElement) {
        opts.root = this.rootElement;
      }

      this.obs = new IntersectionObserver(this.onIntersection.bind(this), opts);
      this.obs.observe(this.element);
    }
    /**
     * Stop observing.
     */


    willDestroy() {
      this.obs.disconnect();
    }
    /**
     * Determine if an entry should be considered visible or not.
     * Don't use `isIntersecting` because Firefox does NOT round well and it isn't reliable.
     */


    onIntersection(entries) {
      const [visible, hidden] = entries.reduce((acc, e) => {
        const isVisible = e.intersectionRatio >= (this.args.named.threshold || 0.5);
        acc[isVisible ? 0 : 1].push(e.target);
        return acc;
      }, [[], []]);

      (this.onItemsVisible || _.noop)(visible, hidden);
    }

  }

  _exports.default = IsVisibleModifier;
});