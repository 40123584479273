define("mobile-web/components/r-vendor-info/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "imageCard": "_imageCard_1936cc",
    "header": "_header_1936cc",
    "heading": "_heading_1936cc",
    "vendorProperties": "_vendorProperties_1936cc",
    "crossStreet": "_crossStreet_1936cc",
    "vendorLinks": "_vendorLinks_1936cc",
    "vendorDistance": "_vendorDistance_1936cc",
    "availability": "_availability_1936cc",
    "inlineLinkContainer": "_inlineLinkContainer_1936cc",
    "placeIcon": "_placeIcon_1936cc",
    "mapLink": "_mapLink_1936cc",
    "info": "_info_1936cc",
    "infoButton": "_infoButton_1936cc",
    "outerLinkContainer": "_outerLinkContainer_1936cc",
    "timezoneWarning": "_timezoneWarning_1936cc",
    "favoriteButton": "_favoriteButton_1936cc",
    "vendorMinTags": "_vendorMinTags_1936cc",
    "vendorMinTag": "_vendorMinTag_1936cc"
  };
  _exports.default = _default;
});