define("mobile-web/routes/menu/vendor/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class VendorInfoRoute extends Ember.Route {
    model(params) {
      this.replaceWith('menu.vendor', params.vendor_slug);
    }

  }

  _exports.default = VendorInfoRoute;
});