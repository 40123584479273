define("mobile-web/lib/utilities/classes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.classes = void 0;
  const WHITESPACE = /[ \r\n\f\t]/g;

  /**
   * @summary
   * Normalizes a list of strings and objects into a single list of class names.
   *
   *  - duplicate class names are removed
   *  - whitespace is normalized to single spaces
   *  - object keys are included when their values are truthy
   *
   * @param classLists the list of classes to normalize
   *
   * @returns a normalized list of classes
   *
   * @example <caption>Multiple parameters are merged into a single string</caption>
   *
   * classes('foo', 'bar', 'baz')
   * // 'foo bar baz'
   *
   * @example <caption>Object keys are merged when their values are truthy</caption>
   *
   * classes({
   *   'foo': true,
   *   'bar': false,
   *   'lorem': {},
   *   'ipsum': null,
   *   'fizz': 1,
   *   'buzz': 0,
   * })
   * // 'foo lorem fizz'
   */
  const classes = (...classLists) => {
    const set = new Set();

    for (const classList of classLists) {
      if (typeof classList === 'string') {
        classList.split(WHITESPACE).filter(c => c).forEach(c => set.add(c));
      } else if (classList) {
        Object.entries(classList).filter(([c, enabled]) => c && enabled).forEach(([c]) => set.add(c));
      }
    }

    return Array.from(set).join(' ');
  };

  _exports.classes = classes;
});