define("mobile-web/components/r-segmented-control/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "19pPwRWA",
    "block": "[[[10,\"form\"],[15,0,[30,0,[\"rootClass\"]]],[14,\"data-test-segmented-control\",\"\"],[12],[1,\"\\n  \"],[10,1],[15,1,[28,[37,0],[[30,0,[\"name\"]],\"-group-label\"],null]],[15,0,[30,0,[\"style\",\"groupLabel\"]]],[12],[1,\"\\n    \"],[1,[30,0,[\"groupLabel\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"style\",\"radioGroup\"]]],[14,\"role\",\"radiogroup\"],[15,\"aria-labelledby\",[28,[37,0],[[30,0,[\"name\"]],\"-group-label\"],null]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[33,3]],null]],null],null,[[[1,\"      \"],[1,[28,[35,4],null,[[\"type\",\"name\",\"class\",\"id\",\"checked\",\"onChange\",\"data-test-segmented-control-input-index\"],[\"radio\",[30,0,[\"name\"]],[30,0,[\"style\",\"input\"]],[28,[37,5],[[30,0,[\"inputIds\"]],[30,2]],null],[28,[37,6],[[33,7],[30,1,[\"value\"]]],null],[28,[37,8],[[30,0],\"handleChange\",[30,1]],null],[30,2]]]]],[1,\"\\n      \"],[10,\"label\"],[15,0,[28,[37,9],[[28,[37,5],[[30,0,[\"buttonClasses\"]],[30,2]],null],[52,[51,[30,0,[\"hasSingleSegment\"]]],[30,0,[\"style\",\"active\"]]]],null]],[15,\"for\",[28,[37,5],[[30,0,[\"inputIds\"]],[30,2]],null]],[15,\"data-test-segmented-control-label\",[30,1,[\"label\"]]],[12],[1,\"\\n        \"],[1,[30,1,[\"label\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1,2]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"segment\",\"index\"],false,[\"concat\",\"each\",\"-track-array\",\"segments\",\"r-form-field-input\",\"get\",\"eq\",\"value\",\"action\",\"classes\",\"unless\"]]",
    "moduleName": "mobile-web/components/r-segmented-control/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});