define("mobile-web/components/r-hero-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qP7ahkKb",
    "block": "[[[11,0],[16,0,[30,0,[\"style\",\"container\"]]],[24,\"data-test-heroImage\",\"\"],[17,1],[12],[1,\"\\n  \"],[10,\"img\"],[15,0,[30,0,[\"style\",\"image\"]]],[15,\"src\",[30,0,[\"heroImage\"]]],[15,\"alt\",[30,0,[\"heroImageAlt\"]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\"],false,[]]",
    "moduleName": "mobile-web/components/r-hero-image/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});