define("mobile-web/components/routes/menu/vendor/rewards-route/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "noOffers": "_noOffers_1vbqxl",
    "rewardDescription": "_rewardDescription_1vbqxl",
    "descriptionContainer": "_descriptionContainer_1vbqxl"
  };
  _exports.default = _default;
});