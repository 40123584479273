define("mobile-web/components/r-flyout/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "overlay": "_overlay_1yxqqu",
    "left": "_left_1yxqqu",
    "hidden": "_hidden_1yxqqu",
    "edge": "_edge_1yxqqu",
    "root": "_root_1yxqqu",
    "right": "_right_1yxqqu",
    "tag": "_tag_1yxqqu",
    "content": "_content_1yxqqu"
  };
  _exports.default = _default;
});