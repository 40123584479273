define("mobile-web/components/r-payment-card-modal/component", ["exports", "@glimmer/component", "ember-concurrency", "ember-concurrency-ts", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-payment-card-modal/style"], function (_exports, _component, _emberConcurrency, _emberConcurrencyTs, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PaymentCardModal = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class PaymentCardModal extends _component.default {
    constructor(...args) {
      super(...args);
      this.initialWidth = 0;

      _initializerDefineProperty(this, "bus", _descriptor, this);

      _initializerDefineProperty(this, "error", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "buttonElement", _descriptor4, this);

      _initializerDefineProperty(this, "buttonWidth", _descriptor5, this);
    }

    // Getters and setters
    get intlPrefix() {
      return `mwc.${this.args.intlPrefix}`;
    }

    get isEditing() {
      return (0, _isSome.default)(this.args.card) || (0, _isSome.default)(this.args.membership);
    }

    get modalTitle() {
      return `${this.intlPrefix}.${this.isEditing ? 'editHeader' : 'addHeader'}`;
    }

    get saveLabel() {
      return `${this.intlPrefix}.${this.isEditing ? 'editLabel' : 'addLabel'}`;
    } // Constructor
    // Other methods
    // Tasks


    *deleteTask() {
      try {
        yield this.args.membership.destroyRecord(); // Can't get here without a membership

        this.args.onClose();
      } catch (e) {
        this.error.reportError(undefined);
      }
    } // Actions


    confirmDelete() {
      this.bus.trigger('confirm', {
        title: this.intl.t(`${this.intlPrefix}.confirmDeleteTitle`),
        content: this.intl.t(`${this.intlPrefix}.confirmDeleteText`),
        buttonLabel: this.intl.t(`${this.intlPrefix}.confirmDeleteButton`),
        onConfirm: () => (0, _emberConcurrencyTs.taskFor)(this.deleteTask).perform(),
        testSelector: 'confirmDelete',
        buttonTestSelector: 'confirmDeleteButton'
      });
    }

    adjustButtonWidth() {
      if (!this.buttonElement) return;
      const currentWidth = this.buttonElement.offsetWidth;

      if (currentWidth > this.initialWidth) {
        this.buttonElement.style.width = 'unset';
      } else {
        this.buttonWidth = currentWidth || this.initialWidth;
        this.buttonElement.style.width = `${this.buttonWidth}px`;
        this.buttonElement.style.minWidth = `${this.buttonWidth}px`;
      }
    }

    setStableWidth(el) {
      this.initialWidth = el.offsetWidth; // Set a width based on the button initial width
      // so the loading spinner doesn't reduce button size

      el.style.width = `${this.initialWidth}px`;
      this.buttonElement = el;
      this.buttonWidth = this.initialWidth;
      window.addEventListener('resize', this.adjustButtonWidth);
    }

    onDestroy() {
      window.removeEventListener('resize', this.adjustButtonWidth);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "bus", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "error", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "buttonElement", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "buttonWidth", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "deleteTask", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "deleteTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "confirmDelete", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "confirmDelete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "adjustButtonWidth", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "adjustButtonWidth"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setStableWidth", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setStableWidth"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDestroy", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onDestroy"), _class.prototype)), _class));
  _exports.default = PaymentCardModal;
});