define("mobile-web/lib/computed/session", ["exports", "mobile-web/config/environment", "mobile-web/lib/utilities/is-some"], function (_exports, _environment, _isSome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.session = session;
  _exports.default = void 0;
  const {
    localStorageNameSpace
  } = _environment.default;

  function session(targetOrOptions, propertyKey) {
    if ((0, _isSome.default)(propertyKey)) {
      // called without options
      return sessionDecorator(targetOrOptions, propertyKey);
    } // called with options


    const decorator = (target, key) => {
      const options = targetOrOptions;
      return sessionDecorator(target, key, options);
    };

    return decorator;
  }

  function sessionDecorator(target, key, options = {}) {
    if (typeof key === 'string') {
      var _options$normalize;

      const normalize = (_options$normalize = options.normalize) != null ? _options$normalize : JSON.parse;
      Object.defineProperty(target, key, {
        get() {
          try {
            const value = sessionStorage.getItem(`${localStorageNameSpace}${key}`);
            return value ? normalize(value) : null; // eslint-disable-line no-null/no-null
          } catch (e) {
            return undefined;
          }
        },

        set(value) {
          try {
            if (Ember.isNone(value)) {
              sessionStorage.removeItem(`${localStorageNameSpace}${key}`);
            } else {
              sessionStorage.setItem(`${localStorageNameSpace}${key}`, JSON.stringify(value));
            }

            this.notifyPropertyChange(`${key}`);
            return true;
          } catch (e) {
            return false;
          }
        }

      });
    } else {
      (false && !(false) && Ember.assert('decorator key should be a string, but was ' + typeof key));
    } // Returning a descriptor is required for stage-2 decorators, even if it is just an empty object
    // Without the descriptor, this decorator will not work properly.


    return {};
  }

  var _default = session;
  _exports.default = _default;
});