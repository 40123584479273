define("mobile-web/adapters/billing-membership", ["exports", "mobile-web/lib/hybrid-util", "mobile-web/adapters/application"], function (_exports, _hybridUtil, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BillingMembershipAdapter extends _application.default {
    urlForDeleteRecord(id) {
      return `${(0, _hybridUtil.host)()}/${this.namespace}/billingaccounts?ids=${id}`;
    }

    pathForType() {
      return 'billingaccounts';
    }

    getGiftCard(id) {
      const url = `${(0, _hybridUtil.host)()}/${this.namespace}/giftcards/${id}`;
      return this.ajax(url, 'GET');
    }

  }

  _exports.default = BillingMembershipAdapter;
});