define("mobile-web/adapters/favorite", ["exports", "mobile-web/lib/hybrid-util", "mobile-web/adapters/application"], function (_exports, _hybridUtil, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FavoriteRequestType = void 0;
  let FavoriteRequestType;
  _exports.FavoriteRequestType = FavoriteRequestType;

  (function (FavoriteRequestType) {
    FavoriteRequestType["Basket"] = "basket";
  })(FavoriteRequestType || (_exports.FavoriteRequestType = FavoriteRequestType = {}));

  class FavoriteAdapter extends _application.default {
    query() {
      const baseUrl = this.buildURL('favorite'),
            url = `${baseUrl}/list`;
      return this.ajax(url, 'GET');
    }

    saveFavoriteOrder(orderId, name) {
      const url = this.buildURL('favorite');
      return this.ajax(url, 'POST', {
        data: {
          orderId,
          name
        }
      });
    }

    buildURL(_modelName, id, _snapshot, requestType) {
      if (requestType === FavoriteRequestType.Basket) {
        return `${(0, _hybridUtil.host)()}/${this.namespace}/baskets/favorite/${id}`;
      }

      return super.buildURL(...arguments);
    }

  }

  _exports.default = FavoriteAdapter;
});