define("mobile-web/components/r-external-login-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CNoJQz/a",
    "block": "[[[11,\"button\"],[24,\"data-test-externalLoginButton\",\"\"],[16,\"data-test-loginProvider\",[30,1,[\"slug\"]]],[16,0,[30,0,[\"style\",\"externalLoginButton\"]]],[24,4,\"button\"],[4,[38,0],[\"click\",[28,[37,1],[[30,2],[30,1]],null]],null],[12],[1,\"\\n\"],[41,[30,1,[\"displayText\"]],[[[1,\"    \"],[1,[30,1,[\"displayText\"]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,1,[\"name\"]]],[1,\"\\n\"]],[]]],[13]],[\"@provider\",\"@onClick\"],false,[\"on\",\"fn\",\"if\"]]",
    "moduleName": "mobile-web/components/r-external-login-button/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});