define("mobile-web/lib/utilities/is-some", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.isSome = void 0;

  const isSome = thing => !Ember.isNone(thing);

  _exports.isSome = isSome;
  var _default = isSome;
  _exports.default = _default;
});