define("mobile-web/components/r-dispatch-tracker/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "mobile-web/models/dispatch-status", "mobile-web/components/r-dispatch-tracker/style"], function (_exports, _glimmerComponent, _dispatchStatus, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var StepKey;

  (function (StepKey) {
    StepKey["OrderReceived"] = "orderReceived";
    StepKey["PickupInProgress"] = "pickupInProgress";
    StepKey["OutForDelivery"] = "outForDelivery";
    StepKey["Delivered"] = "delivered";
  })(StepKey || (StepKey = {}));

  var StepStatus;

  (function (StepStatus) {
    StepStatus["Pending"] = "pending";
    StepStatus["Active"] = "active";
    StepStatus["Complete"] = "complete";
    StepStatus["Error"] = "error";
    StepStatus["Info"] = "info";
  })(StepStatus || (StepStatus = {}));

  const PICKUP_IN_PROGRESS_MAP = {
    [_dispatchStatus.State.OrderReceived]: StepStatus.Pending,
    [_dispatchStatus.State.OrderError]: StepStatus.Pending,
    [_dispatchStatus.State.PickupInProgress]: StepStatus.Active,
    [_dispatchStatus.State.PickupError]: StepStatus.Error,
    [_dispatchStatus.State.PickupSucceeded]: StepStatus.Complete,
    [_dispatchStatus.State.DeliveryError]: StepStatus.Complete,
    [_dispatchStatus.State.DeliverySucceeded]: StepStatus.Complete,
    [_dispatchStatus.State.ReturnInProgress]: StepStatus.Complete,
    [_dispatchStatus.State.ReturnCompleted]: StepStatus.Complete
  };
  const OUT_FOR_DELIVERY_MAP = {
    [_dispatchStatus.State.OrderReceived]: StepStatus.Pending,
    [_dispatchStatus.State.OrderError]: StepStatus.Pending,
    [_dispatchStatus.State.PickupInProgress]: StepStatus.Pending,
    [_dispatchStatus.State.PickupError]: StepStatus.Pending,
    [_dispatchStatus.State.PickupSucceeded]: StepStatus.Active,
    [_dispatchStatus.State.DeliveryError]: StepStatus.Error,
    [_dispatchStatus.State.DeliverySucceeded]: StepStatus.Complete,
    [_dispatchStatus.State.ReturnInProgress]: StepStatus.Complete,
    [_dispatchStatus.State.ReturnCompleted]: StepStatus.Complete
  };
  const DELIVERED_MAP = {
    [_dispatchStatus.State.OrderReceived]: StepStatus.Pending,
    [_dispatchStatus.State.OrderError]: StepStatus.Pending,
    [_dispatchStatus.State.PickupInProgress]: StepStatus.Pending,
    [_dispatchStatus.State.PickupError]: StepStatus.Pending,
    [_dispatchStatus.State.PickupSucceeded]: StepStatus.Pending,
    [_dispatchStatus.State.DeliveryError]: StepStatus.Pending,
    [_dispatchStatus.State.DeliverySucceeded]: StepStatus.Complete,
    [_dispatchStatus.State.ReturnInProgress]: StepStatus.Info,
    [_dispatchStatus.State.ReturnCompleted]: StepStatus.Info
  };
  let DispatchTracker = (_dec = Ember.inject.service, (_class = class DispatchTracker extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get steps() {
      const state = this.args.status.status;
      return [{
        key: StepKey.OrderReceived,
        label: this.intl.t('mwc.dispatchTracker.orderReceivedLabel'),
        status: state === _dispatchStatus.State.OrderError ? StepStatus.Error : StepStatus.Complete
      }, {
        key: StepKey.PickupInProgress,
        label: this.intl.t('mwc.dispatchTracker.pickupInProgressLabel'),
        status: PICKUP_IN_PROGRESS_MAP[state]
      }, {
        key: StepKey.OutForDelivery,
        label: this.intl.t('mwc.dispatchTracker.outForDeliveryLabel'),
        status: OUT_FOR_DELIVERY_MAP[state]
      }, {
        key: StepKey.Delivered,
        label: state === _dispatchStatus.State.ReturnInProgress || state === _dispatchStatus.State.ReturnCompleted ? this.intl.t('mwc.dispatchTracker.returnLabel') : this.intl.t('mwc.dispatchTracker.deliveredLabel'),
        status: DELIVERED_MAP[state]
      }];
    } // Constructor
    // Other methods
    // Tasks
    // Actions


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = DispatchTracker;
});