define("mobile-web/components/r-carousel/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "ember-animated/transitions/fade", "mobile-web/lib/scroll", "mobile-web/lib/utilities/classes", "mobile-web/lib/utilities/guids", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-carousel/style"], function (_exports, _glimmerComponent, _fade, _scroll, _classes, _guids, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Carousel = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_class = class Carousel extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "media", _descriptor, this);

      this.ids = (0, _guids.guids)(this, 'heading');
      this.listResizeObserver = new ResizeObserver(this.setListState);
      this.style = _style.default;

      _initializerDefineProperty(this, "listElement", _descriptor2, this);

      _initializerDefineProperty(this, "scrollLeft", _descriptor3, this);

      _initializerDefineProperty(this, "transition", _descriptor4, this);

      _initializerDefineProperty(this, "isScrolling", _descriptor5, this);

      _initializerDefineProperty(this, "leftButton", _descriptor6, this);

      _initializerDefineProperty(this, "leftButtonHover", _descriptor7, this);

      _initializerDefineProperty(this, "rightButton", _descriptor8, this);

      _initializerDefineProperty(this, "rightButtonHover", _descriptor9, this);
    }

    // Getters and setters
    get leftButtonClass() {
      return (0, _classes.classes)(this.style.scrollButton, {
        [this.style.hover]: this.leftButtonHover
      });
    }

    get rightButtonClass() {
      return (0, _classes.classes)(this.style.scrollButton, {
        [this.style.hover]: this.rightButtonHover
      });
    }

    get isScrollable() {
      return (0, _isSome.default)(this.listElement) && this.listElement.scrollWidth > this.listElement.clientWidth;
    }

    get showHeader() {
      var _this$args$showHeader;

      return (_this$args$showHeader = this.args.showHeader) != null ? _this$args$showHeader : true;
    }

    get showLeftArrow() {
      return (0, _isSome.default)(this.listElement) && this.scrollLeft > 0;
    }

    get showRightArrow() {
      return (0, _isSome.default)(this.listElement) && this.isScrollable && Math.ceil(this.scrollLeft) + this.listElement.offsetWidth < this.listElement.scrollWidth;
    }

    get viewAllModels() {
      var _this$args$viewAll, _this$args$viewAll2;

      if ((_this$args$viewAll = this.args.viewAll) != null && _this$args$viewAll.model) {
        return [this.args.viewAll.model];
      }

      if ((_this$args$viewAll2 = this.args.viewAll) != null && _this$args$viewAll2.models) {
        return this.args.viewAll.models;
      }

      return [];
    }

    get visibleItems() {
      var _this$listElement;

      if (!((_this$listElement = this.listElement) != null && _this$listElement.children.length)) {
        return '';
      }

      const items = Array.from(this.listElement.children); // first fully visible item where the item's left boundary is past the left
      // button's right boundary

      const firstVisibleIndex = this.showLeftArrow && this.leftButton ? items.findIndex(item => item.getBoundingClientRect().left > this.leftButton.getBoundingClientRect().right) : -1; // first item partially obscured by the right button. This is the item after
      // the last fully visible item

      const firstOverhangingIndex = this.showRightArrow && this.rightButton ? items.findIndex(item => item.getBoundingClientRect().right > this.rightButton.getBoundingClientRect().left) : -1;
      const start = firstVisibleIndex === -1 ? // if no items were found or we're at the start
      1 : // convert from index to ordinal
      firstVisibleIndex + 1;
      const end = firstOverhangingIndex === -1 ? // if no items were found or we're at the end
      items.length : // convert from index to ordinal
      // the last item is `firstOverhangingIndex - 1`
      // the ordinal for the last item is `firstOverhangingIndex - 1 + 1`
      firstOverhangingIndex;
      return start >= end ? start.toString() : `${start}-${end}`;
    } // Other methods
    // Tasks
    // Actions


    scroll(direction, button) {
      var _this$listElement$fir, _this$listElement$las;

      if (!this.listElement || this.isScrolling) {
        return;
      }

      const items = Array.from(this.listElement.children);
      const firstItem = (_this$listElement$fir = this.listElement.firstElementChild) != null ? _this$listElement$fir : undefined;
      const lastItem = (_this$listElement$las = this.listElement.lastElementChild) != null ? _this$listElement$las : undefined;
      const buttonBounds = button.getBoundingClientRect();
      const buttonWidth = buttonBounds.width;
      let item;

      if (direction === 'right') {
        var _items$find;

        // Find the first carousel item whose right bound is past (greater
        // than) the right button's left bound (i.e. the item is overhanging
        // to the right). Otherwise use the last item in the carousel.
        item = (_items$find = items.find(i => i.getBoundingClientRect().right > buttonBounds.left)) != null ? _items$find : lastItem;
      } else {
        // Find the last carousel item whose left bound is past (less than)
        // the left button's right bound (i.e. the item is overhanging to the
        // left).
        const leftOverhang = items.slice().reverse().find(i => i.getBoundingClientRect().left < buttonBounds.right);

        if (!leftOverhang) {
          // if there aren't any items overhanging to the left, scroll to the
          // first item
          item = firstItem;
        } else {
          const rightOffset = leftOverhang.offsetLeft + leftOverhang.clientWidth; // +---+-+-------+---+
          // |   | |       |   |
          // | A |B|  D    | A |
          // |   | |       |   |
          // +---+-+-------+---+
          //
          // ^--------C--------^
          //
          // A = button width
          // B = item margin

          const margin = parseInt(getComputedStyle(leftOverhang).marginLeft, 10); // C = carousel width

          const carouselWidth = this.listElement.clientWidth; // D = viewport width
          // D = C - 2A - B

          const viewportWidth = carouselWidth - buttonWidth * 2 - margin; // Find the first carousel item whose left offset is past (greater than)
          // the left overhang item's right offset less the carousel viewport
          // width. That is, find the first item that can be scrolled to where the
          // left overhang item will be fully visible in the carousel.

          item = items.find(i => i.offsetLeft > rightOffset - viewportWidth);
        }
      }

      if (!item) return;
      const leftOffset = item.offsetLeft;
      const margin = parseInt(getComputedStyle(item).marginLeft, 10);
      const bufferWidth = margin + buttonWidth; // set the scroll position to the item's left offset less the buffer width

      this.listElement.scrollLeft = leftOffset - bufferWidth;
      this.isScrolling = true;
      (0, _scroll.onScrollStop)(this.listElement, () => {
        this.isScrolling = false;
      });
    }

    onCarouselClick(e) {
      if (this.leftButton && Carousel.isIntersecting(e.clientX, e.clientY, this.leftButton.getBoundingClientRect())) {
        e.stopPropagation();
        this.scroll('left', this.leftButton);
      }

      if (this.rightButton && Carousel.isIntersecting(e.clientX, e.clientY, this.rightButton.getBoundingClientRect())) {
        e.stopPropagation();
        this.scroll('right', this.rightButton);
      }
    }

    onCarouselHover(e) {
      this.leftButtonHover = Boolean(this.leftButton && Carousel.isIntersecting(e.clientX, e.clientY, this.leftButton.getBoundingClientRect()));
      this.rightButtonHover = Boolean(this.rightButton && Carousel.isIntersecting(e.clientX, e.clientY, this.rightButton.getBoundingClientRect()));
    }

    onCarouselLeave() {
      this.rightButtonHover = false;
      this.leftButtonHover = false;
    }

    setListState() {
      var _this$listElement$scr, _this$listElement2;

      this.scrollLeft = (_this$listElement$scr = (_this$listElement2 = this.listElement) == null ? void 0 : _this$listElement2.scrollLeft) != null ? _this$listElement$scr : 0;
    }

    static isIntersecting(clientX, clientY, {
      left,
      right,
      top,
      bottom
    }) {
      return left <= clientX && clientX <= right && top <= clientY && clientY <= bottom;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "media", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "listElement", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "scrollLeft", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "transition", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return _fade.default;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isScrolling", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "leftButton", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "leftButtonHover", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "rightButton", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "rightButtonHover", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "scroll", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "scroll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCarouselClick", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onCarouselClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCarouselHover", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "onCarouselHover"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCarouselLeave", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "onCarouselLeave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setListState", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "setListState"), _class.prototype)), _class));
  _exports.default = Carousel;
});