define("mobile-web/templates/menu/category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GosLVu0t",
    "block": "[[[8,[39,0],null,[[\"@category\"],[[30,0,[\"model\"]]]],null]],[],false,[\"routes/menu/category-route\"]]",
    "moduleName": "mobile-web/templates/menu/category.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});