define("mobile-web/transforms/array", ["exports", "ember-data", "mobile-web/lib/utilities/_"], function (_exports, _emberData, _) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Transform
  } = _emberData.default;

  class ArrayTransform extends Transform {
    deserialize(value) {
      return (0, _.isArray)(value) ? value : [];
    }

    serialize(value) {
      return (0, _.isArray)(value) ? value : [];
    }

  }

  _exports.default = ArrayTransform;
});