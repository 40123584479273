define("mobile-web/components/r-product-customization/choice/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_2n1zim",
    "isSelected": "_isSelected_2n1zim",
    "isDisabled": "_isDisabled_2n1zim",
    "nameRow": "_nameRow_2n1zim",
    "name": "_name_2n1zim",
    "check": "_check_2n1zim",
    "content": "_content_2n1zim",
    "labels": "_labels_2n1zim",
    "hasOneLabel": "_hasOneLabel_2n1zim",
    "overrideLabel": "_overrideLabel_2n1zim",
    "price": "_price_2n1zim",
    "calories": "_calories_2n1zim",
    "hasPrice": "_hasPrice_2n1zim",
    "hasOverride": "_hasOverride_2n1zim",
    "yieldedContent": "_yieldedContent_2n1zim",
    "quantity": "_quantity_2n1zim",
    "break": "_break_2n1zim"
  };
  _exports.default = _default;
});