define("mobile-web/components/r-feedback-banners/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "huh4efVL",
    "block": "[[[41,[30,0,[\"userFeedback\",\"messages\"]],[[[1,\"  \"],[10,0],[14,1,\"feedback-banners\"],[15,0,[30,0,[\"style\",\"bannerContainer\"]]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"userFeedback\",\"messages\"]]],null]],null],null,[[[1,\"      \"],[8,[39,3],null,[[\"@model\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"message\"],false,[\"if\",\"each\",\"-track-array\",\"r-feedback-banner\"]]",
    "moduleName": "mobile-web/components/r-feedback-banners/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});