define("mobile-web/components/r-card/link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jQYkAfDd",
    "block": "[[[10,1],[15,0,[30,0,[\"style\",\"label\"]]],[14,\"data-test-card-link\",\"label\"],[12],[1,[30,1]],[13],[1,\"\\n\"],[8,[39,0],null,[[\"@ariaLabel\",\"@class\",\"@icon\"],[\"\",[30,0,[\"style\",\"navIcon\"]],\"r-chevron-right\"]],null]],[\"@label\"],false,[\"r-svg\"]]",
    "moduleName": "mobile-web/components/r-card/link/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});