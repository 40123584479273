define("mobile-web/components/r-vendor-frame/component", ["exports", "@ember-decorators/component", "mobile-web/lib/utilities/is-some", "mobile-web/lib/utilities/remove-scripts", "mobile-web/components/r-vendor-frame/style"], function (_exports, _component, _isSome, _removeScripts, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let VendorFrame = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.computed('basket.basket.basketProducts.[]'), _dec9 = Ember.computed('orderCriteria.criteria'), _dec10 = Ember.computed('vendor.settings.customerFacingMessage'), _dec11 = Ember.computed('media.matchers.tablet', 'media.{isMobile,isTablet}'), _dec12 = Ember.computed('media.isDesktop', 'media.matchers.desktop', 'sideMenu.isEnabled'), _dec13 = Ember._action, _dec14 = Ember._action, _dec(_class = (_class2 = class VendorFrame extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "orderCriteria", _descriptor3, this);

      _initializerDefineProperty(this, "cmsContent", _descriptor4, this);

      _initializerDefineProperty(this, "media", _descriptor5, this);

      _initializerDefineProperty(this, "sideMenu", _descriptor6, this);

      this.style = _style.default;
    }

    // Computed properties
    get hasBasketProducts() {
      var _this$basket$basket$b, _this$basket$basket;

      return ((_this$basket$basket$b = (_this$basket$basket = this.basket.basket) == null ? void 0 : _this$basket$basket.basketProducts.length) != null ? _this$basket$basket$b : 0) > 0;
    }

    get criteria() {
      return this.orderCriteria.criteria;
    }

    get safeCustomerFacingMessage() {
      return Ember.String.htmlSafe((0, _removeScripts.removeScripts)(this.vendor.settings.customerFacingMessage));
    }

    get showMenuBanner() {
      var _this$cmsContent$getC, _this$cmsContent$getC2;

      if (this.media.isTablet && (_this$cmsContent$getC = this.cmsContent.getContent('menuBanner')) != null && _this$cmsContent$getC.imageUriTablet) {
        return true;
      } else if (this.media.isMobile && (_this$cmsContent$getC2 = this.cmsContent.getContent('menuBanner')) != null && _this$cmsContent$getC2.imageUriMobile) {
        return true;
      }

      return false;
    }

    get showRewards() {
      return this.sideMenu.isEnabled || !this.media.isDesktop;
    } // Init


    init() {
      super.init();
      (false && !((0, _isSome.default)(this.vendor)) && Ember.assert('`vendor` is required', (0, _isSome.default)(this.vendor)));
    } // Other methods
    // Actions


    openOrderCriteriaModal() {
      this.orderCriteria.openModal();
    }

    toggleFavorite() {
      this.vendor.toggleFavorite();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "basket", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "orderCriteria", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "cmsContent", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "media", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "sideMenu", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "hasBasketProducts", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "hasBasketProducts"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "criteria", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "criteria"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "safeCustomerFacingMessage", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "safeCustomerFacingMessage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "showMenuBanner", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "showMenuBanner"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "showRewards", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "showRewards"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "openOrderCriteriaModal", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "openOrderCriteriaModal"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleFavorite", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleFavorite"), _class2.prototype)), _class2)) || _class);
  _exports.default = VendorFrame;
});