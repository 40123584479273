define("mobile-web/routes/checkin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CheckInRoute extends Ember.Route {
    constructor(...args) {
      super(...args);
      this.titleToken = 'Successfully Checked In';
    }

    model(params) {
      const adapter = this.store.adapterFor('order');
      const checkIn = adapter.checkIn(params.id, params.hash);
      return this.store.findRecord('order', params.id, {
        adapterOptions: {
          hash: params.hash
        }
      }).then(order => Ember.RSVP.hash({
        order,
        checkIn
      }));
    }

  }

  _exports.default = CheckInRoute;
});