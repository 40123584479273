define("mobile-web/components/r-marketing-opt-in/component", ["exports", "@ember-decorators/component", "mobile-web/components/r-marketing-opt-in/style"], function (_exports, _component, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MarketingOptIn = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.computed('channel.settings.{hideMarketingOptIn,showGuestMarketingOptIn}', 'guest'), _dec4 = Ember.computed('guest'), _dec(_class = (_class2 = class MarketingOptIn extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "channel", _descriptor, this);

      this.guest = false;
      this.style = _style.default;
    }

    get hideMarketingOptIn() {
      var _this$channel$setting, _this$channel$setting2;

      if ((_this$channel$setting = this.channel.settings) != null && _this$channel$setting.hideMarketingOptIn) {
        return true;
      }

      if (this.guest && !((_this$channel$setting2 = this.channel.settings) != null && _this$channel$setting2.showGuestMarketingOptIn)) {
        return true;
      }

      return false;
    }

    get descriptionDictionaryKey() {
      return this.guest ? 'EMAIL_OPT_IN_GUEST_DESCRIPTION' : 'EMAIL_OPT_IN_DESCRIPTION';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "channel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "hideMarketingOptIn", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "hideMarketingOptIn"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "descriptionDictionaryKey", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "descriptionDictionaryKey"), _class2.prototype)), _class2)) || _class);
  _exports.default = MarketingOptIn;
});