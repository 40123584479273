define("mobile-web/components/r-radio/component", ["exports", "@ember-decorators/component", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-radio/style"], function (_exports, _component, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let Radio = (_dec = (0, _component.tagName)(''), _dec2 = Ember.computed('class'), _dec3 = Ember.computed('hideLabel'), _dec(_class = (_class2 = class Radio extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.checked = false;
      this.disabled = false;
      this.hideLabel = false;
      this.class = '';
      this.labelClass = '';
      this.style = _style.default;
    }

    get rootClass() {
      return `${_style.default.root} ${this.class}`;
    }

    get textClass() {
      return `${this.hideLabel ? _style.default.hidden : _style.default.text} ${this.labelClass}`;
    }

    init() {
      super.init();
      (false && !((0, _isSome.default)(this.label)) && Ember.assert('`label` is required', (0, _isSome.default)(this.label)));
      (false && !((0, _isSome.default)(this.name)) && Ember.assert('`name` is required', (0, _isSome.default)(this.name)));
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "rootClass", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "rootClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "textClass", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "textClass"), _class2.prototype)), _class2)) || _class);
  _exports.default = Radio;
});