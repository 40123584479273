define("mobile-web/components/routes/outage-route/component", ["exports", "@glimmer/component", "ember-concurrency", "ember-concurrency-ts", "mobile-web/lib/animation", "mobile-web/components/routes/outage-route/style"], function (_exports, _component, _emberConcurrency, _emberConcurrencyTs, _animation, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OutageRoute = (_dec = Ember.inject.service, _dec2 = Ember._tracked, (_class = class OutageRoute extends _component.default {
    // Service injections
    // Passthroughs
    // Tracked properties
    // Getters and setters
    // Constructor
    constructor(owner, args = {}) {
      super(owner, args);

      _initializerDefineProperty(this, "healthCheck", _descriptor, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "timesChecked", _descriptor2, this);

      this.refreshStatus();
    } // Other methods


    shouldRefresh() {
      return !this.isDestroying && !this.isDestroyed && !this.healthCheck.isHealthy;
    }

    refreshStatus() {
      if (!this.shouldRefresh() && this.timesChecked) return;
      (0, _emberConcurrencyTs.taskFor)(this.runHealthCheck).perform(); // After 30 seconds: query the healthCheck endpoint incrementally higher
      // until we reach 15 minutes of waiting time between requests

      const interval = (0, _animation.getDuration)(this.timesChecked <= 58 ? 15e3 * (this.timesChecked + 2) : 58);
      Ember.run.later(this, this.refreshStatus, interval);
    } // Tasks


    *runHealthCheck() {
      yield this.healthCheck.checkSystemHealth();
      this.timesChecked += 1;
    } // Actions


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "healthCheck", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "timesChecked", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "runHealthCheck", [_emberConcurrency.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "runHealthCheck"), _class.prototype)), _class));
  _exports.default = OutageRoute;
});