define("mobile-web/components/r-favorite-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Pi0Ft9NL",
    "block": "[[[11,\"button\"],[16,0,[30,0,[\"style\",\"button\"]]],[16,\"data-test-favoriteButton\",[52,[30,1],\"filled\",\"unfilled\"]],[17,2],[4,[38,1],[\"click\",[30,3]],null],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@ariaLabel\",\"@class\",\"@icon\"],[[52,[30,1],[28,[37,3],[\"mwc.saveFavorite.unsetFavorite\"],null],[28,[37,3],[\"mwc.saveFavorite.setFavorite\"],null]],[30,0,[\"style\",\"icon\"]],[52,[30,1],\"r-favorite-filled\",\"r-favorite\"]]],null],[1,\"\\n\"],[13]],[\"@isFavorite\",\"&attrs\",\"@onClick\"],false,[\"if\",\"on\",\"r-svg\",\"t\"]]",
    "moduleName": "mobile-web/components/r-favorite-button/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});