define("mobile-web/routes/menu/category", ["exports", "mobile-web/decorators/indexed", "mobile-web/lib/routing"], function (_exports, _indexed, _routing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CategoryRoute = (_dec = Ember.inject.service('vendor'), (0, _indexed.default)(_class = (_class2 = class CategoryRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "vendorService", _descriptor, this);
    }

    titleToken(model) {
      return model == null ? void 0 : model.name;
    }

    model(params) {
      return this.vendorService.ensureVendorLoaded(params.vendor_slug).then(vendor => {
        Ember.set(this, 'previousRoute', (0, _routing.vendorToMenuRoute)(vendor));
        return this.store.peekRecord('category', params.category_id);
      });
    }

    afterModel(model) {
      if (!model) {
        // vendorSlug is defined because of ensureVendorLoaded in model hook
        this.transitionTo('menu.vendor', this.vendorService.vendorSlug);
      }
    }

    shouldIndex() {
      const vendor = this.vendorService.vendor;
      return !Ember.isNone(vendor) && vendor.status.toLowerCase() === 'public';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "vendorService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = CategoryRoute;
});