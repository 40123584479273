define("mobile-web/services/geo", ["exports", "@capacitor/geolocation", "ember-concurrency", "ember-concurrency-ts", "true-myth/maybe", "mobile-web/components/r-button/component", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/type-helpers", "mobile-web/services/global-events", "mobile-web/services/user-feedback"], function (_exports, _geolocation, _emberConcurrency, _emberConcurrencyTs, _maybe, _component, _, _typeHelpers, _globalEvents, _userFeedback) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PositionErrorCode = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PositionErrorCode;
  _exports.PositionErrorCode = PositionErrorCode;

  (function (PositionErrorCode) {
    PositionErrorCode[PositionErrorCode["PERMISSION_DENIED"] = 1] = "PERMISSION_DENIED";
    PositionErrorCode[PositionErrorCode["POSITION_UNAVAILABLE"] = 2] = "POSITION_UNAVAILABLE";
    PositionErrorCode[PositionErrorCode["TIMEOUT"] = 3] = "TIMEOUT";
  })(PositionErrorCode || (_exports.PositionErrorCode = PositionErrorCode = {}));

  let GeoService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class GeoService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "device", _descriptor, this);

      _initializerDefineProperty(this, "globalEvents", _descriptor2, this);

      _initializerDefineProperty(this, "userFeedback", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      this.getGeoTask = (0, _emberConcurrencyTs.taskFor)(this.getGeolocationTask);
    }

    getGeolocation(timeout = 10000) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        if (this.device.isHybrid) {
          _geolocation.Geolocation.getCurrentPosition({
            timeout
          }).then(({
            coords
          }) => {
            this.globalEvents.trigger(_globalEvents.GlobalEventName.LocationAccessAllowed);
            return resolve((0, _maybe.just)(coords));
          }).catch(() => {
            _geolocation.Geolocation.checkPermissions().then(status => {
              if (status.location !== 'granted') {
                this.globalEvents.trigger(_globalEvents.GlobalEventName.LocationAccessDenied);
                reject({
                  code: PositionErrorCode.PERMISSION_DENIED
                });
              }

              reject({
                code: PositionErrorCode.POSITION_UNAVAILABLE
              });
            });
          });
        } else if ('geolocation' in navigator) {
          let hasTimedOut = false;
          const locationTimeout = setTimeout(() => {
            hasTimedOut = true;
            reject({
              code: PositionErrorCode.TIMEOUT
            });
          }, timeout + 20); // We time out later than what we pass to getCurrentPosition, to give the normal flow a chance to run

          navigator.geolocation.getCurrentPosition(({
            coords
          }) => {
            clearTimeout(locationTimeout); // We only resolve if we haven't timed out.
            // If we have timed out, reject has already been called.

            if (!hasTimedOut) {
              this.globalEvents.trigger(_globalEvents.GlobalEventName.LocationAccessAllowed);
              resolve((0, _maybe.just)(coords));
            }
          }, (...args) => {
            clearTimeout(locationTimeout); // We only reject if we haven't timed out.
            // If we have timed out, reject has already been called.

            if (!hasTimedOut) {
              this.globalEvents.trigger(_globalEvents.GlobalEventName.LocationAccessDenied);
              reject(...args);
            }
          }, {
            timeout
          });
        } else {
          resolve((0, _maybe.nothing)());
        }
      });
    }

    *getGeolocationTask(setCoords) {
      try {
        const coords = yield this.getGeolocation();

        if (coords.isJust()) {
          setCoords(coords.value);
        }
      } catch (e) {
        const reason = e;
        const title = this.intl.t('mwc.geo.lookUpFailedTitle');
        const type = _userFeedback.Type.Warning;

        switch (reason.code) {
          case PositionErrorCode.POSITION_UNAVAILABLE:
            this.userFeedback.add({
              title,
              message: this.intl.t('mwc.geo.locationUnavailableMessage'),
              type
            });
            break;

          case PositionErrorCode.TIMEOUT:
            this.userFeedback.add({
              title,
              message: this.intl.t('mwc.geo.timeoutMessage'),
              type,
              actions: [{
                label: 'Input address manually',
                variant: _component.Variant.Main,
                fn: _.noop
              }, {
                label: 'Try again',
                variant: _component.Variant.Minor,
                fn: () => {
                  (0, _emberConcurrencyTs.taskFor)(this.getGeolocationTask).perform(setCoords);
                }
              }]
            });
            break;

          case PositionErrorCode.PERMISSION_DENIED:
            this.userFeedback.add({
              title,
              message: this.intl.t('mwc.geo.turnOnLocationMessage'),
              type
            });
            break;

          default:
            (0, _typeHelpers.assertNever)(reason.code);
        }
      } // return this.onClose();

    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "device", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "globalEvents", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "userFeedback", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getGeolocationTask", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "getGeolocationTask"), _class.prototype)), _class));
  _exports.default = GeoService;
});