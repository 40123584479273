define("mobile-web/serializers/address", ["exports", "ember-data", "mobile-web/lib/utilities/_"], function (_exports, _emberData, _) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ADDRESS_COMPARISON_PROPS = ['streetAddress', 'building', 'city', 'zipCode'];

  const addressFieldsOf = input => (0, _.pick)(input, ADDRESS_COMPARISON_PROPS);

  function addressesMatch(a, b) {
    return (0, _.isEqual)(addressFieldsOf(a), addressFieldsOf(b));
  }

  class AddressSerializer extends _emberData.default.JSONSerializer {
    normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      const deduped = (0, _.uniqWith)(payload, addressesMatch);
      return super.normalizeArrayResponse(store, primaryModelClass, deduped, id, requestType);
    }

  }

  _exports.default = AddressSerializer;
});