define("mobile-web/components/r-animated-modal/component", ["exports", "@glimmer/component", "mobile-web/lib/animation", "mobile-web/components/r-animated-modal/style"], function (_exports, _component, _animation, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /** Matches $-animated-modal-transition-duration in the style */
  const TRANSITION_DURATION = 500;
  var TransitionType;

  (function (TransitionType) {
    TransitionType["Fade"] = "fade";
    TransitionType["SlideUp"] = "slideUp";
  })(TransitionType || (TransitionType = {}));

  let AnimatedModal = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class AnimatedModal extends _component.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;

      _initializerDefineProperty(this, "isOpen", _descriptor, this);

      _initializerDefineProperty(this, "renderContent", _descriptor2, this);
    }

    // Getters and setters
    get wrapperClass() {
      var _this$args$transition;

      return this.style[(_this$args$transition = this.args.transition) != null ? _this$args$transition : TransitionType.Fade];
    }

    get modalClass() {
      const classes = [this.style.modal, this.isOpen ? this.style.open : this.style.closed];

      if (this.args.modalClass) {
        classes.push(this.args.modalClass);
      }

      return classes.join(' ');
    } // Constructor
    // Other methods
    // Tasks
    // Actions


    beginOpenAnimation() {
      this.renderContent = true; // We need the component to render the content once before
      // setting isOpen to true, so that we can get the animation
      // from the close class to the open class.

      Ember.run.next(this, () => {
        this.isOpen = true;
      });
    }

    beginCloseAnimation() {
      if (!this.args.open) {
        // (∩｀-´)⊃━☆ﾟ.*・｡ﾟ
        // This is needed in order for setting `isOpen` to trigger
        // recalcs on things that observe it. We don't know why.
        Ember.run.next(this, () => {
          this.isOpen = false;
          Ember.run.later(() => {
            this.renderContent = false;

            if (this.args.onCloseComplete) {
              this.args.onCloseComplete();
            }
          }, (0, _animation.getDuration)(TRANSITION_DURATION));
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isOpen", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "renderContent", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "beginOpenAnimation", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "beginOpenAnimation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "beginCloseAnimation", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "beginCloseAnimation"), _class.prototype)), _class));
  _exports.default = AnimatedModal;
});