define("mobile-web/components/routes/secure/checkout/auth-route/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "wrapper": "_wrapper_1qcc2m"
  };
  _exports.default = _default;
});