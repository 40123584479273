define("mobile-web/components/r-post-checkout/r-order-contents/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "lineItem": "_lineItem_4gw37p",
    "quantity": "_quantity_4gw37p"
  };
  _exports.default = _default;
});