define("mobile-web/components/r-sign-in-form/component", ["exports", "@ember-decorators/component", "mobile-web/lib/utilities/_", "mobile-web/lib/validation", "mobile-web/services/analytics", "mobile-web/components/r-sign-in-form/style"], function (_exports, _component, _, _validation, _analytics, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const EMAIL_VALIDATION = 'Email address must be entered and valid';
  const PASSWORD_VALIDATION = 'You must enter a password';
  let // Needed because we can't handle DOM events in a tagless component
  SignInForm = ( // eslint-disable-next-line ember/require-tagless-components
  _dec = (0, _component.tagName)('div'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed('model.{email,password}'), _dec(_class = (_class2 = class // Needed because we can't handle DOM events in a tagless component
  SignInForm extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "error", _descriptor2, this);

      this.headingLevel = 1;
      this.model = {
        email: '',
        password: ''
      };
      this.validationConfig = {
        bindings: [{
          targetProp: 'email',
          ruleName: 'email',
          message: EMAIL_VALIDATION
        }, {
          targetProp: 'password',
          ruleName: 'notBlank',
          message: PASSWORD_VALIDATION
        }]
      };
      this.style = _style.default;
    }

    // Computeds
    get isSubmitDisabled() {
      const model = this.model;
      return (0, _.isEmpty)(model.email) || (0, _.isEmpty)(model.password);
    }

    init() {
      super.init();
      (false && !((0, _.isFunction)(this.onSubmit)) && Ember.assert('`onSubmit` is required', (0, _.isFunction)(this.onSubmit)));
    }

    async submit(event) {
      event.preventDefault();
      this.validationResult = _validation.default.validate(this.model, this.validationConfig);

      if (this.validationResult.isErr()) {
        return;
      }

      try {
        await this.analytics.trackEvent(_analytics.AnalyticsEvents.SignIn);
      } catch (err) {
        this.error.sendExternalError(err);
      }

      this.onSubmit(this.model.email, this.model.password);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "analytics", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "error", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "isSubmitDisabled", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "isSubmitDisabled"), _class2.prototype)), _class2)) || _class);
  _exports.default = SignInForm;
});