define("mobile-web/components/r-track-order-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xBEkm5Q6",
    "block": "[[[6,[39,0],null,[[\"classNames\",\"route\",\"model\"],[[30,0,[\"rootClass\"]],\"dispatch-summary\",[33,1]]],[[\"default\"],[[[[1,\"  \"],[10,1],[15,0,[30,0,[\"style\",\"text\"]]],[14,\"data-test-trackDispatch\",\"\"],[12],[1,\"Track Order Progress\"],[13],[1,\"\\n  \"],[8,[39,2],null,[[\"@ariaLabel\",\"@class\",\"@icon\"],[\"\",[30,0,[\"style\",\"icon\"]],\"r-track-changes\"]],null],[1,\"\\n\"]],[]]]]]],[],false,[\"link-to\",\"orderId\",\"r-svg\"]]",
    "moduleName": "mobile-web/components/r-track-order-button/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});