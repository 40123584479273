define("mobile-web/components/r-no-vendor-results/component", ["exports", "mobile-web/components/r-no-vendor-results/style"], function (_exports, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const LEGACY_WRAPPER_CLASS = 'no-vendor-results';
  let NoVendorResults = (_dec = Ember.computed('class'), (_class = class NoVendorResults extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.class = '';
      this.style = _style.default;
    }

    get wrapperClass() {
      return [this.style.wrapper, LEGACY_WRAPPER_CLASS, this.class].join(' ');
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "wrapperClass", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "wrapperClass"), _class.prototype)), _class));
  _exports.default = NoVendorResults;
});