define("mobile-web/components/r-product-customization/display-group/component", ["exports", "@glimmer/component", "mobile-web/lib/menu", "mobile-web/lib/runloop", "mobile-web/components/r-product-customization/display-group/style"], function (_exports, _component, _menu, _runloop, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProductCustomizationDisplayGroup = (_dec = Ember.inject.service, _dec2 = Ember._tracked, (_class = class ProductCustomizationDisplayGroup extends _component.default {
    // Service injections
    // Passthroughs
    // Tracked properties
    // Getters and setters
    // Constructor
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "basketChoices", _descriptor2, this);

      (0, _runloop.safeNext)(this, () => {
        let areAllNew = true;
        this.args.displayGroup.choices.forEach(c => {
          const {
            choice,
            isNew
          } = (0, _menu.getOrCreateBasketChoice)(this.store, this.args.basketProduct, c);
          this.basketChoices.pushObject(choice);
          areAllNew = areAllNew && isNew;
          (0, _menu.loadNestedMenuItems)(this.store, this.args.basketProduct, c, false);
        });

        if (areAllNew && (0, _menu.isParentNew)(this.args.basketProduct, this.args.displayGroup.choices[0])) {
          this.args.optionGroup.splitChoiceQuantities();
        }
      });
    } // Other methods
    // Tasks
    // Actions


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "basketChoices", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  })), _class));
  _exports.default = ProductCustomizationDisplayGroup;
});