define("mobile-web/components/r-typeahead/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "resultsContainer": "_resultsContainer_7q6hz4",
    "results": "_results_7q6hz4",
    "result": "_result_7q6hz4"
  };
  _exports.default = _default;
});