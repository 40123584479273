define("mobile-web/models/bootstrap-data", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BootstrapDataModel = (_dec = _emberData.default.attr('string'), _dec2 = _emberData.default.attr('string'), _dec3 = _emberData.default.attr('string'), _dec4 = _emberData.default.attr(), _dec5 = _emberData.default.attr(), _dec6 = _emberData.default.attr(), _dec7 = _emberData.default.attr(), _dec8 = _emberData.default.attr('string'), _dec9 = _emberData.default.attr('string'), _dec10 = _emberData.default.attr('string'), _dec11 = _emberData.default.attr('boolean'), _dec12 = _emberData.default.attr('boolean'), _dec13 = _emberData.default.attr('boolean'), _dec14 = _emberData.default.attr('boolean'), _dec15 = _emberData.default.attr('boolean'), _dec16 = _emberData.default.attr('boolean'), _dec17 = _emberData.default.attr('string'), _dec18 = _emberData.default.attr(), _dec19 = _emberData.default.attr('string'), _dec20 = _emberData.default.attr('string'), _dec21 = _emberData.default.attr(), _dec22 = _emberData.default.attr(), _dec23 = _emberData.default.attr(), _dec24 = _emberData.default.attr(), _dec25 = _emberData.default.attr(), _dec26 = _emberData.default.attr('string'), _dec27 = _emberData.default.attr('string'), _dec28 = _emberData.default.attr(), _dec29 = _emberData.default.attr(), _dec30 = Ember.computed('encodedTranslation'), (_class = class BootstrapDataModel extends _emberData.default.Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "channelSlug", _descriptor, this);

      _initializerDefineProperty(this, "basketGuid", _descriptor2, this);

      _initializerDefineProperty(this, "basketVendorSlug", _descriptor3, this);

      _initializerDefineProperty(this, "channel", _descriptor4, this);

      _initializerDefineProperty(this, "content", _descriptor5, this);

      _initializerDefineProperty(this, "cmsContent", _descriptor6, this);

      _initializerDefineProperty(this, "crossChannelAnalytics", _descriptor7, this);

      _initializerDefineProperty(this, "currentCountry", _descriptor8, this);

      _initializerDefineProperty(this, "appVersion", _descriptor9, this);

      _initializerDefineProperty(this, "error", _descriptor10, this);

      _initializerDefineProperty(this, "allowSearchEngineIndexing", _descriptor11, this);

      _initializerDefineProperty(this, "isRestrictedFlow", _descriptor12, this);

      _initializerDefineProperty(this, "isEmbeddedMode", _descriptor13, this);

      _initializerDefineProperty(this, "isEmbeddedLevelUp", _descriptor14, this);

      _initializerDefineProperty(this, "isFacebook", _descriptor15, this);

      _initializerDefineProperty(this, "isSsoLogin", _descriptor16, this);

      _initializerDefineProperty(this, "editAccountUrl", _descriptor17, this);

      _initializerDefineProperty(this, "payload", _descriptor18, this);

      _initializerDefineProperty(this, "registerAccountUrl", _descriptor19, this);

      _initializerDefineProperty(this, "loginProviderName", _descriptor20, this);

      _initializerDefineProperty(this, "guestUser", _descriptor21, this);

      _initializerDefineProperty(this, "userId", _descriptor22, this);

      _initializerDefineProperty(this, "handoffModes", _descriptor23, this);

      _initializerDefineProperty(this, "timeWantedModes", _descriptor24, this);

      _initializerDefineProperty(this, "customLinks", _descriptor25, this);

      _initializerDefineProperty(this, "mixpanelAnalyticsId", _descriptor26, this);

      _initializerDefineProperty(this, "mixpanelUniqueId", _descriptor27, this);

      _initializerDefineProperty(this, "translationTest", _descriptor28, this);

      _initializerDefineProperty(this, "encodedTranslation", _descriptor29, this);
    }

    get decodedTranslation() {
      return this.encodedTranslation ? atob(this.encodedTranslation) : undefined;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "channelSlug", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "basketGuid", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "basketVendorSlug", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "content", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "cmsContent", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "crossChannelAnalytics", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "currentCountry", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "appVersion", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "error", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "allowSearchEngineIndexing", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isRestrictedFlow", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "isEmbeddedMode", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "isEmbeddedLevelUp", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "isFacebook", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "isSsoLogin", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "editAccountUrl", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "payload", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "registerAccountUrl", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "loginProviderName", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "guestUser", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "userId", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "handoffModes", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "timeWantedModes", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "customLinks", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "mixpanelAnalyticsId", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "mixpanelUniqueId", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "translationTest", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "encodedTranslation", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "decodedTranslation", [_dec30], Object.getOwnPropertyDescriptor(_class.prototype, "decodedTranslation"), _class.prototype)), _class));
  _exports.default = BootstrapDataModel;
});