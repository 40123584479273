define("mobile-web/lib/country", ["exports", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some"], function (_exports, _, _isSome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Country = _exports.isValidConfig = _exports.defaultConfig = _exports.defaultSettings = _exports.COUNTRIES = _exports.Code = void 0;
  let Code;
  /**
   * The shape of the payload delivered in the bootstrap data.
   */

  _exports.Code = Code;

  (function (Code) {
    Code["US"] = "us";
    Code["CA"] = "ca";
  })(Code || (_exports.Code = Code = {}));

  const COUNTRIES = [Code.US, Code.CA];
  /**
   * The currently selected and available-to-select country options.
   */

  _exports.COUNTRIES = COUNTRIES;
  const DEFAULT_CODE = Code.US;
  const DEFAULT_NAME = 'United States';
  const DEFAULT_OPT_IN = false;
  const DEFAULT_SHOW_OPT_IN = true;
  const DEFAULT_SETTINGS = {
    us: {
      name: 'United States',
      optIn: DEFAULT_OPT_IN,
      showOptInDetails: DEFAULT_SHOW_OPT_IN
    }
  };
  const DEFAULT_CONFIG = {
    current: DEFAULT_CODE,
    supported: [{
      code: DEFAULT_CODE,
      name: DEFAULT_NAME
    }]
  };

  const defaultSettings = () => (0, _.cloneDeep)(DEFAULT_SETTINGS);

  _exports.defaultSettings = defaultSettings;

  const defaultConfig = () => (0, _.cloneDeep)(DEFAULT_CONFIG);

  _exports.defaultConfig = defaultConfig;

  const isValidCode = toCheck => (0, _isSome.default)(toCheck) && COUNTRIES.includes(toCheck);

  const toValidCountries = (othersValid, toCheck) => othersValid && (0, _.has)(toCheck, 'code') && isValidCode(toCheck.code) && (0, _.has)(toCheck, 'name');

  const isValidCountryArray = toCheck => (0, _.isArray)(toCheck) && (0, _.reduce)(toCheck, toValidCountries, true);

  const isValidConfig = toCheck => (0, _isSome.default)(toCheck) && (0, _.has)(toCheck, 'current') && isValidCode(toCheck.current) && (0, _.has)(toCheck, 'supported') && isValidCountryArray(toCheck.supported); // Using type merging here; both this object and the type will be `Country`.


  _exports.isValidConfig = isValidConfig;
  const Country = {
    Code,
    defaultConfig,
    defaultSettings,
    isValidConfig
  };
  _exports.Country = Country;
  var _default = Country;
  _exports.default = _default;
});