define("mobile-web/services/basket", ["exports", "dayjs", "ember-concurrency", "mobile-web/lib/debounce", "mobile-web/lib/utilities/_", "mobile-web/services/analytics", "mobile-web/services/global-events", "mobile-web/services/notifications", "mobile-web/services/user-feedback"], function (_exports, _dayjs, _emberConcurrency, _debounce, _, _analytics, _globalEvents, _notifications, _userFeedback) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BasketService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember.inject.service, _dec11 = Ember.inject.service, _dec12 = Ember.inject.service, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = (0, _emberConcurrency.task)({
    group: 'orderBasketGroup'
  }), _dec21 = (0, _emberConcurrency.task)({
    group: 'orderBasketGroup'
  }), _dec22 = (0, _emberConcurrency.task)({
    group: 'orderBasketGroup'
  }), _dec23 = (0, _emberConcurrency.task)({
    group: 'orderBasketGroup'
  }), (_class = class BasketService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "bus", _descriptor2, this);

      _initializerDefineProperty(this, "error", _descriptor3, this);

      _initializerDefineProperty(this, "device", _descriptor4, this);

      _initializerDefineProperty(this, "features", _descriptor5, this);

      _initializerDefineProperty(this, "globalEvents", _descriptor6, this);

      _initializerDefineProperty(this, "intl", _descriptor7, this);

      _initializerDefineProperty(this, "router", _descriptor8, this);

      _initializerDefineProperty(this, "store", _descriptor9, this);

      _initializerDefineProperty(this, "userFeedback", _descriptor10, this);

      _initializerDefineProperty(this, "notifications", _descriptor11, this);

      _initializerDefineProperty(this, "vendor", _descriptor12, this);

      _initializerDefineProperty(this, "basket", _descriptor13, this);

      _initializerDefineProperty(this, "disableAdd", _descriptor14, this);

      _initializerDefineProperty(this, "_isOpen", _descriptor15, this);

      _initializerDefineProperty(this, "trackedActiveBasketProduct", _descriptor16, this);

      _initializerDefineProperty(this, "orderBasketGroup", _descriptor17, this);
    }

    get isOpen() {
      return this._isOpen;
    }

    get activeBasketProduct() {
      return this.trackedActiveBasketProduct;
    }

    set activeBasketProduct(bp) {
      // See https://github.com/emberjs/ember.js/issues/19192#issuecomment-807796249
      this.trackedActiveBasketProduct = this.untrackedActiveBasketProduct = bp;
    } // Actions


    open() {
      if (!this._isOpen) {
        this._isOpen = true;
      }
    }

    close() {
      if (this._isOpen) {
        this._isOpen = false;
      }
    }

    toggle() {
      this._isOpen = !this._isOpen;
    }

    safeGetActiveBasketProduct() {
      return this.untrackedActiveBasketProduct;
    }

    clear() {
      var _this$basket;

      (_this$basket = this.basket) == null ? void 0 : _this$basket.unloadRecord();
      this.basket = undefined;
    }

    loadBasket(basketId) {
      return this.store.findRecord('basket', String(basketId).toUpperCase()).then(basket => {
        this.basket = basket;
        return basket;
      });
    }

    createBasket(vendor = this.vendor.vendor) {
      if (this.basket && this.basket.vendor.get('id') === (vendor == null ? void 0 : vendor.id)) {
        return Ember.RSVP.resolve(this.basket);
      }

      return this.store.createRecord('basket', {
        vendor
      }).save().then(basket => {
        this.basket = basket;
        this.globalEvents.trigger(_globalEvents.GlobalEventName.CreateBasket, basket.serializeForGlobalData());
        return basket;
      });
    }

    onVendorUpdate(vendorId) {
      var _this$basket2;

      if (((_this$basket2 = this.basket) == null ? void 0 : _this$basket2.vendor.get('id')) !== vendorId) {
        this.clear();
      }
    } // Private methods
    // For baskets created from orders (recent orders, faves).
    // These baskets have no handoff or timewanted information.


    async replaceOrderBasket(basket, openCart = true) {
      const vendorId = basket.belongsTo('vendor').id();

      if (vendorId) {
        const vendor = await this.store.findRecord('vendor', vendorId, {
          reload: true
        });
        this.vendor.set('vendor', vendor);
      }

      this.basket = basket;

      if (openCart) {
        if (this.router.currentRouteName === 'menu.vendor.index') {
          this.open();
        } else {
          this.router.transitionTo('menu.vendor', this.vendor.vendor.slug, {
            queryParams: {
              openBasket: true
            }
          });
        }
      }

      return basket;
    } // Tasks


    *updateTipTask(amount) {
      yield (0, _emberConcurrency.timeout)(_debounce.DELAY_MS);
      const basket = this.basket;
      const newBasket = yield basket.updateTip({
        tip: amount
      });
      this.basket = newBasket;
    }

    *orderFavoriteTask(favorite) {
      const basket = yield favorite.createBasket();
      yield this.replaceOrderBasket(basket);
    }

    *reorderTask(order, idx) {
      const orderProperties = {
        [_analytics.AnalyticsProperties.DaysSinceLastOrder]: 'localTimePlaced' in order ? (0, _dayjs.default)().diff(order.localTimePlaced, 'day') : (0, _dayjs.default)().diff(order.timePlacedLocal, 'day'),
        [_analytics.AnalyticsProperties.PreviousOrderID]: order.id
      };
      this.analytics.trackEvent(_analytics.AnalyticsEvents.ReorderNow, () => _extends({}, orderProperties, {
        [_analytics.AnalyticsProperties.ListIndex]: idx
      }));
      const basket = yield order.reorder();
      yield this.replaceOrderBasket(basket);
    }

    *editOrderTask(order) {
      const basket = yield order.edit();
      yield this.replaceOrderBasket(basket);
    }

    *transferTask(newVendor) {
      var _this$vendor$vendor;

      // It is possible to get here without a basket if the order criteria modal gets an error.
      // If we don't have a basket, then just say we successfully "transferred"
      if (!this.basket || ((_this$vendor$vendor = this.vendor.vendor) == null ? void 0 : _this$vendor$vendor.get('slug')) === newVendor.slug) {
        this.analytics.trackEvent(_analytics.AnalyticsEvents.ChangeStoreLocation, () => ({
          [_analytics.AnalyticsProperties.WarningShown]: false,
          [_analytics.AnalyticsProperties.HasBasket]: true
        }));
        yield this.basket;
      } else {
        try {
          const basket = yield this.basket.transfer({
            vendorId: newVendor.id
          });
          yield this.replaceOrderBasket(basket, false);
          const clean = (0, _.isEmpty)(this.basket.warnings);
          this.analytics.trackEvent(_analytics.AnalyticsEvents.ChangeStoreLocation, () => ({
            [_analytics.AnalyticsProperties.WarningShown]: !clean,
            [_analytics.AnalyticsProperties.HasBasket]: true
          }));

          if (clean) {
            const message = this.device.viewport !== 'Desktop' ? this.intl.t('mwc.basketTransferModal.successMessageShort') : this.intl.t('mwc.basketTransferModal.successMessage', {
              name: newVendor.name
            });
            this.notifications.success({
              message,
              type: _notifications.NotificationType.CartTransferred
            });
          } else {
            this.userFeedback.add({
              type: _userFeedback.Type.Warning,
              title: this.intl.t('mwc.basketTransferModal.warningPartialTitle'),
              message: this.intl.t('mwc.basketTransferModal.warningPartialBody', {
                name: newVendor.name,
                products: this.basket.warnings.join(', ')
              })
            });
          }
        } catch (e) {
          this.error.reportError(e);
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "bus", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "error", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "device", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "features", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "globalEvents", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "userFeedback", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "vendor", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "disableAdd", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "_isOpen", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "trackedActiveBasketProduct", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "open", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "open"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggle", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateTipTask", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "updateTipTask"), _class.prototype), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "orderBasketGroup", [_emberConcurrency.dropTaskGroup], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "orderFavoriteTask", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "orderFavoriteTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reorderTask", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "reorderTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editOrderTask", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "editOrderTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transferTask", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "transferTask"), _class.prototype)), _class));
  _exports.default = BasketService;
});