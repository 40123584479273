define("mobile-web/components/r-facebook-login-button/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "facebookLoginButton": "_facebookLoginButton_4repbg",
    "icon": "_icon_4repbg",
    "copy": "_copy_4repbg"
  };
  _exports.default = _default;
});