define("mobile-web/components/r-link-rewards/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wo3Z3Na2",
    "block": "[[[41,[30,0,[\"show\"]],[[[1,\"  \"],[11,0],[24,\"data-test-linkRewards\",\"\"],[17,1],[12],[1,\"\\n    \"],[18,2,[[28,[37,2],null,[[\"button\",\"schemes\",\"isOpen\",\"fullyLinked\"],[[50,\"r-link-rewards/button\",0,null,[[\"schemes\",\"memberships\",\"linkedSchemeNames\",\"vendor\",\"isOpen\",\"onLink\"],[[30,0,[\"schemes\"]],[30,0,[\"memberships\"]],[30,0,[\"linkedSchemeNames\"]],[30,0,[\"vendorService\",\"vendor\"]],[30,0,[\"isOpen\"]],[28,[37,4],[[28,[37,5],[[30,0,[\"isOpen\"]]],null]],null]]]],[50,\"r-link-rewards/schemes\",0,null,[[\"schemes\",\"memberships\",\"linkedSchemeNames\",\"vendor\",\"onLink\"],[[30,0,[\"schemes\"]],[30,0,[\"memberships\"]],[30,0,[\"linkedSchemeNames\"]],[30,0,[\"vendorService\",\"vendor\"]],[28,[37,4],[[28,[37,5],[[30,0,[\"isOpen\"]]],null],false],null]]]],[30,0,[\"isOpen\"]],[30,0,[\"fullyLinked\"]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"&attrs\",\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\",\"fn\",\"mut\"]]",
    "moduleName": "mobile-web/components/r-link-rewards/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});