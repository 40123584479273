define("mobile-web/components/r-cart/totals/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "container": "_container_171qas",
    "deliveryFeeLabel": "_deliveryFeeLabel_171qas",
    "customFeeLabel": "_customFeeLabel_171qas",
    "lineItem": "_lineItem_171qas",
    "discountLineItem": "_discountLineItem_171qas _lineItem_171qas",
    "feeDesc": "_feeDesc_171qas",
    "infoIcon": "_infoIcon_171qas"
  };
  _exports.default = _default;
});