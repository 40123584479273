define("mobile-web/controllers/thank-you", ["exports", "mobile-web/controllers/order-controller", "mobile-web/services/user-feedback"], function (_exports, _orderController, _userFeedback) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  // Necessary because actions need to be merged. Will remove when we add in the
  // relevant decorators which merge prototypal properties.
  let ThankYouController = (_dec = Ember._action, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed.alias('channel.settings.showSocialIntegration'), (_class = class ThankYouController extends _orderController.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "favorite", _descriptor, this);

      _initializerDefineProperty(this, "channel", _descriptor2, this);

      _initializerDefineProperty(this, "userFeedback", _descriptor3, this);

      _initializerDefineProperty(this, "analytics", _descriptor4, this);

      _initializerDefineProperty(this, "showSocialIntegration", _descriptor5, this);

      this.saveFavorite = (orderId, name) => this.favorite.saveFavorite(orderId, name).then(() => {
        Ember.set(this.model, 'isFavorite', true);
        this.userFeedback.add({
          message: `Your order has been saved as ${name}!`,
          title: 'Success!',
          type: _userFeedback.Type.Positive
        });
      });
    }

    onUserOrderEvent(id, hash, event) {
      this.transitionToRoute(event, id, {
        queryParams: {
          hash
        }
      });
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onUserOrderEvent", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onUserOrderEvent"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "favorite", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "userFeedback", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showSocialIntegration", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ThankYouController;
});