define("mobile-web/models/order-search-result", ["exports", "ember-data", "mobile-web/adapters/order", "mobile-web/decorators/member-action", "mobile-web/lib/dayjs", "mobile-web/models/basket"], function (_exports, _emberData, _order, _memberAction, _dayjs, _basket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.OrderSearchResultStatus = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // Built from the magic strings in https://github.com/ololabs/platform/blob/develop/MoboLogic/src/OrderHistory/OrderSearchResult.cs
  let OrderSearchResultStatus;
  _exports.OrderSearchResultStatus = OrderSearchResultStatus;

  (function (OrderSearchResultStatus) {
    OrderSearchResultStatus["Complete"] = "Complete";
    OrderSearchResultStatus["Failed"] = "Failed";
    OrderSearchResultStatus["Canceled"] = "Canceled";
    OrderSearchResultStatus["InProgress"] = "In Progress";
  })(OrderSearchResultStatus || (_exports.OrderSearchResultStatus = OrderSearchResultStatus = {}));

  let OrderSearchResultModel = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = _emberData.default.attr('number'), _dec4 = _emberData.default.attr('string'), _dec5 = _emberData.default.attr('string'), _dec6 = _emberData.default.attr('string'), _dec7 = _emberData.default.attr('string'), _dec8 = _emberData.default.attr('string'), _dec9 = _emberData.default.attr('string'), _dec10 = _emberData.default.attr('string'), _dec11 = _emberData.default.attr('number'), _dec12 = _emberData.default.attr('string'), _dec13 = _emberData.default.attr('string'), _dec14 = _emberData.default.attr('string'), _dec15 = _emberData.default.attr('boolean'), _dec16 = _emberData.default.attr('boolean'), _dec17 = _emberData.default.attr('string'), _dec18 = (0, _memberAction.default)({
    type: 'post',
    urlType: _order.OrderRequestType.Reorder,
    after: _basket.pushBasketPayload
  }), _dec19 = Ember.computed('localTimePlaced'), (_class = class OrderSearchResultModel extends _emberData.default.Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "bus", _descriptor, this);

      _initializerDefineProperty(this, "mwcIntl", _descriptor2, this);

      _initializerDefineProperty(this, "favoriteId", _descriptor3, this);

      _initializerDefineProperty(this, "localTimePlaced", _descriptor4, this);

      _initializerDefineProperty(this, "orderDescription", _descriptor5, this);

      _initializerDefineProperty(this, "orderGuid", _descriptor6, this);

      _initializerDefineProperty(this, "displayId", _descriptor7, this);

      _initializerDefineProperty(this, "orderSummary", _descriptor8, this);

      _initializerDefineProperty(this, "status", _descriptor9, this);

      _initializerDefineProperty(this, "statusDescription", _descriptor10, this);

      _initializerDefineProperty(this, "total", _descriptor11, this);

      _initializerDefineProperty(this, "vendorSlug", _descriptor12, this);

      _initializerDefineProperty(this, "vendorName", _descriptor13, this);

      _initializerDefineProperty(this, "orderHash", _descriptor14, this);

      _initializerDefineProperty(this, "canUserCheckin", _descriptor15, this);

      _initializerDefineProperty(this, "canSupportArrival", _descriptor16, this);

      _initializerDefineProperty(this, "vendorCurrency", _descriptor17, this);

      _initializerDefineProperty(this, "reorder", _descriptor18, this);
    }

    get timePlaced() {
      return this.mwcIntl.relativeDateTime((0, _dayjs.default)(this.localTimePlaced));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "bus", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "mwcIntl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "favoriteId", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "localTimePlaced", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "orderDescription", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "orderGuid", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "displayId", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "orderSummary", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "statusDescription", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "total", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "vendorSlug", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "vendorName", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "orderHash", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "canUserCheckin", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "canSupportArrival", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "vendorCurrency", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "reorder", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "timePlaced", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "timePlaced"), _class.prototype)), _class));
  _exports.default = OrderSearchResultModel;
});