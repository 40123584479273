define("mobile-web/components/r-form-edit-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JiZCaG8c",
    "block": "[[[11,\"button\"],[24,4,\"button\"],[16,0,[30,0,[\"style\",\"button\"]]],[16,\"aria-label\",[30,0,[\"ariaLabel\"]]],[16,\"data-test-formEditButton\",[30,1]],[17,2],[4,[38,0],[\"click\",[30,0,[\"handleClick\"]]],null],[12],[1,\"\\n  \"],[10,1],[15,0,[30,0,[\"style\",\"labelInputContainer\"]]],[12],[1,\"\\n\\n    \"],[10,1],[15,0,[30,0,[\"labelClassName\"]]],[12],[1,\"\\n      \"],[46,[30,3],null,[[\"class\"],[[30,0,[\"style\",\"labelIcon\"]]]],null],[1,\"\\n\"],[41,[51,[30,4]],[[[1,\"        \"],[10,1],[15,0,[30,0,[\"labelTextClass\"]]],[12],[1,[30,5]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n\"],[41,[30,6],[[[1,\"      \"],[10,1],[15,0,[30,0,[\"style\",\"input\"]]],[12],[1,[30,6]],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,1],[15,0,[30,0,[\"style\",\"placeholder\"]]],[12],[1,[30,0,[\"placeholder\"]]],[13],[1,\"\\n\"]],[]]],[1,\"\\n    \"],[8,[39,4],null,[[\"@ariaLabel\",\"@class\",\"@icon\"],[\"\",[30,0,[\"style\",\"icon\"]],\"r-pencil\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"isEditorOpen\"]],[[[1,\"  \"],[18,7,[[28,[37,6],null,[[\"closeEditor\"],[[30,0,[\"closeEditor\"]]]]]]],[1,\"\\n\"]],[]],null]],[\"@name\",\"&attrs\",\"@icon\",\"@hideLabel\",\"@label\",\"@value\",\"&default\"],false,[\"on\",\"component\",\"unless\",\"if\",\"r-svg\",\"yield\",\"hash\"]]",
    "moduleName": "mobile-web/components/r-form-edit-button/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});