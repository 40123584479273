define("mobile-web/components/r-static-content-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Jivl5TIH",
    "block": "[[[41,[30,0,[\"isOpen\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@title\",\"@onClose\",\"@buttons\"],[[30,0,[\"title\"]],[30,0,[\"close\"]],\"yield\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1,[\"body\"]],[[[41,[30,0,[\"content\"]],[[[1,\"        \"],[8,[39,2],null,[[\"@model\"],[[30,0,[\"content\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,3],null,[[\"@display\",\"@inline\"],[true,true]],null],[1,\"\\n\"]],[]]]],[]],[[[41,[30,1,[\"footer\"]],[[[1,\"      \"],[8,[39,4],[[16,0,[30,1,[\"buttonClass\"]]]],[[\"@onClick\"],[[30,0,[\"closeButton\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,0,[\"buttonLabel\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[]],null]],[]]],[1,\"  \"]],[1]]]]],[1,\"\\n\"]],[]],null]],[\"modal\"],false,[\"if\",\"r-modal\",\"r-static-page\",\"loading-indicator\",\"r-button\"]]",
    "moduleName": "mobile-web/components/r-static-content-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});