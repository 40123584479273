define("mobile-web/components/r-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mhvU0UgM",
    "block": "[[[8,[39,0],[[24,\"data-test-password\",\"\"],[17,1]],[[\"@autocomplete\",\"@buttonData\",\"@class\",\"@inputMode\",\"@inputValue\",\"@label\",\"@minlength\",\"@name\",\"@trimOnChange\",\"@type\",\"@validationMessages\"],[[30,2],[28,[37,1],null,[[\"icon\",\"label\",\"onClick\"],[[30,0,[\"icon\"]],[30,0,[\"iconLabel\"]],[30,0,[\"toggleVisibility\"]]]]],[30,0,[\"class\"]],\"latin\",[30,0,[\"value\"]],[30,0,[\"label\"]],[30,0,[\"minlength\"]],[30,0,[\"name\"]],false,[30,0,[\"type\"]],[30,3]]],null],[1,\"\\n\"]],[\"&attrs\",\"@autocomplete\",\"@validationMessages\"],false,[\"r-form-field\",\"hash\"]]",
    "moduleName": "mobile-web/components/r-password/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});