define("mobile-web/components/r-product-modal/component", ["exports", "@glimmer/component", "mobile-web/components/r-product-modal/style"], function (_exports, _component, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProductModal = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class ProductModal extends _component.default {
    // Service injections
    // Passthroughs
    // Tracked properties
    // Getters and setters
    // Constructor
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "bus", _descriptor, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "eventArgs", _descriptor2, this);

      _initializerDefineProperty(this, "isOpen", _descriptor3, this);

      this.bus.on('showProductModal', eventArgs => {
        this.eventArgs = eventArgs;
        this.isOpen = true;
      });
    } // Other methods
    // Tasks
    // Actions


    close() {
      this.isOpen = false;
    }

    onCloseComplete() {
      var _this$eventArgs;

      (_this$eventArgs = this.eventArgs) == null ? void 0 : _this$eventArgs.onClose == null ? void 0 : _this$eventArgs.onClose();
      this.eventArgs = undefined;
    }

    onSave() {
      var _this$eventArgs2;

      this.close();
      (_this$eventArgs2 = this.eventArgs) == null ? void 0 : _this$eventArgs2.onSave == null ? void 0 : _this$eventArgs2.onSave();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "bus", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "eventArgs", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isOpen", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "close", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCloseComplete", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onCloseComplete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSave", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onSave"), _class.prototype)), _class));
  _exports.default = ProductModal;
});