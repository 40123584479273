define("mobile-web/components/r-dispatch-tracker/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_1xo1a0",
    "steps": "_steps_1xo1a0",
    "step": "_step_1xo1a0",
    "activeStep": "_activeStep_1xo1a0",
    "completedStep": "_completedStep_1xo1a0",
    "errorStep": "_errorStep_1xo1a0",
    "infoStep": "_infoStep_1xo1a0",
    "stepIcon": "_stepIcon_1xo1a0",
    "iconSvg": "_iconSvg_1xo1a0",
    "stepLabel": "_stepLabel_1xo1a0"
  };
  _exports.default = _default;
});