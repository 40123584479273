define("mobile-web/components/r-breadcrumbs/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_15s4d4",
    "crumb": "_crumb_15s4d4",
    "link": "_link_15s4d4"
  };
  _exports.default = _default;
});