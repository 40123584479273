define("mobile-web/components/r-side-menu/component", ["exports", "@glimmer/component", "mobile-web/components/r-footer/component", "mobile-web/lib/data", "mobile-web/components/r-side-menu/style"], function (_exports, _component, _component2, _data, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SideMenu = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SideMenu extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "sideMenu", _descriptor, this);

      _initializerDefineProperty(this, "channel", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get footerModel() {
      if (!this.channel.current) {
        return new _data.Loading();
      }

      return _data.Loaded.withValue({
        showContent: true,
        location: _component2.Location.SideMenu
      });
    }

    get isGuest() {
      return !this.session.isLoggedIn;
    }

    get userFirstName() {
      var _this$session$current, _this$session$current2;

      return (_this$session$current = (_this$session$current2 = this.session.currentUser) == null ? void 0 : _this$session$current2.firstName) != null ? _this$session$current : '';
    }

    get signOnAllowed() {
      return this.session.signOnAllowed;
    }

    get showOffersInbox() {
      var _this$channel$setting, _this$channel$setting2;

      return (_this$channel$setting = (_this$channel$setting2 = this.channel.settings) == null ? void 0 : _this$channel$setting2.showOffersInbox) != null ? _this$channel$setting : false;
    } // Constructor
    // Other methods
    // Tasks
    // Actions


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sideMenu", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SideMenu;
});