define("mobile-web/serializers/loyalty-account", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LoyaltyAccountSerializer extends _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);
      this.primaryKey = 'schemeId';
      this.attrs = {
        qualifyingLoyaltyRewards: {
          key: 'qualifyingRewards',
          embedded: 'always'
        }
      };
    }

    normalize(modelClass, resourceHash, prop) {
      const {
        membershipId,
        vendorId
      } = this.store.adapterFor('loyalty-account');
      resourceHash.membershipId = membershipId;
      resourceHash.vendorId = vendorId;
      return super.normalize(modelClass, resourceHash, prop);
    }

  }

  _exports.default = LoyaltyAccountSerializer;
});