define("mobile-web/helpers/route-action", ["exports", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some"], function (_exports, _, _isSome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RouteAction extends Ember.Helper {
    compute([routeName, actionName]) {
      const owner = Ember.getOwner(this);
      const route = owner.lookup(`route:${routeName}`);
      (false && !((0, _isSome.default)(route)) && Ember.assert(`first parameter should be a route`, (0, _isSome.default)(route)));
      const action = route[actionName];
      (false && !((0, _.isFunction)(action)) && Ember.assert(`second parameter should be a function on the route`, (0, _.isFunction)(action)));
      return action;
    }

  }

  _exports.default = RouteAction;
});