define("mobile-web/instance-initializers/mixin-storefront", ["exports", "ember-data-storefront/instance-initializers/mixin-storefront"], function (_exports, _mixinStorefront) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _mixinStorefront.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _mixinStorefront.initialize;
    }
  });
});