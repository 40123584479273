define("mobile-web/templates/vendor-search-results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QXXhd1Mm",
    "block": "[[[8,[39,0],null,[[\"@model\",\"@onSearchChange\"],[[30,0,[\"model\"]],[28,[37,1],[\"vendor-search-results\",\"searchDidChange\"],null]]],null]],[],false,[\"routes/vendor-search-results-route\",\"route-action\"]]",
    "moduleName": "mobile-web/templates/vendor-search-results.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});