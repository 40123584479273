define("mobile-web/components/r-application-loading/menu/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_1qzl7r",
    "infoContainer": "_infoContainer_1qzl7r",
    "img": "_img_1qzl7r",
    "info": "_info_1qzl7r",
    "header": "_header_1qzl7r",
    "text": "_text_1qzl7r",
    "nonProductText": "_nonProductText_1qzl7r",
    "productText": "_productText_1qzl7r",
    "optionGroups": "_optionGroups_1qzl7r",
    "menuToggle": "_menuToggle_1qzl7r",
    "category": "_category_1qzl7r",
    "categoryImg": "_categoryImg_1qzl7r",
    "categoryTitle": "_categoryTitle_1qzl7r",
    "categoryDescription": "_categoryDescription_1qzl7r",
    "viewAllGrid": "_viewAllGrid_1qzl7r",
    "viewAllItem": "_viewAllItem_1qzl7r",
    "menu": "_menu_1qzl7r",
    "menuItem": "_menuItem_1qzl7r"
  };
  _exports.default = _default;
});