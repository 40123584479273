define("mobile-web/components/r-application-loading/password/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_vv96mo",
    "header": "_header_vv96mo",
    "text": "_text_vv96mo",
    "input": "_input_vv96mo"
  };
  _exports.default = _default;
});