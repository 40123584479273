define("mobile-web/lib/utilities/math", ["exports", "true-myth/maybe"], function (_exports, _maybe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.splitNicely = splitNicely;
  _exports.truncateDecimals = truncateDecimals;
  _exports.roundDecimals = roundDecimals;
  _exports.roundUpToNext = roundUpToNext;
  _exports.roundDownToPrevious = roundDownToPrevious;

  /**
    Find the nearest multiple of a given factor size to a given division result.
  
    For example: getting the nearest multiple of 2 for 50 divided by 3:
  
    ```ts
    nearestUpperMultiple(50, 3, 2); // 18
    ```
  
    And the same, but for a multiple of 5:
  
    ```ts
    nearestUpperMultiple(50, 3, 5); // 20
    ```
  
    @param number The number to find the nearest interval of some divisor for.
    @param divisor The divisor to split the number by.
    @param factor The size of the interval to step upwards by.
   */
  const nearestUpperMultiple = (number, divisor, factor) => Math.ceil(number / divisor / factor) * factor;

  const splitBy1 = (total, splits) => {
    if (splits === 1) {
      return (0, _maybe.just)([total]);
    }

    const baseValue = Math.floor(total / splits);

    if (total % splits === 0) {
      return (0, _maybe.just)(Array(splits).fill(baseValue));
    }

    const first = baseValue + 1;
    const rest = splitNicely(total - first, {
      into: splits - 1
    });
    return rest.map(x => [first, ...x]);
  };
  /**
    Take an integer and split it into as near equal values as possible for a given
    number of components, with an optional interval specifying the increment.
  
    For example, to split the value `10` into 4 components:
  
    ```ts
    split(10, { into: 4 }); // Just([3, 3, 2, 2])
    ```
  
    And the same with an interval of 2:
  
    ```ts
    split(10, { into: 4, by: 2 }); // Just([4, 2, 2, 2])
    ```
  
    And the same with a non-solveable combination of component number and interval
    (you cannot evenly divide 10 into even intervals of 4 no matter what):
  
    ```ts
    split(10, { into: 7, by: 4 }); // Nothing
    ```
  
    @param total The number to split into components which add up to it.
    @param config.into The number of components to split the total into.
    @param config.by The size of interval to split the total. Optional.
   */


  function splitNicely(total, {
    into: splits,
    by: size = (0, _maybe.nothing)()
  }) {
    if (splits < 1 || splits > total) {
      return (0, _maybe.nothing)();
    } // If there is no specified interval, it is always possible to divide the
    // total into a number of components by including one odd number unless the
    // number of components requested is greater than the value itself. By
    // contrast, if there is an interval specified, some scenarios are
    // mathematically impossible to resolve, e.g. dividing 50 into 9 parts by
    // intervals of 4.


    return size.match({
      Just: interval => {
        if (interval < 1) {
          return (0, _maybe.nothing)();
        }

        if (splits === 1 && interval === total) {
          return (0, _maybe.just)([total]);
        }

        if (interval === 1) {
          return splitBy1(total, splits);
        }

        const first = nearestUpperMultiple(total, splits, interval);

        if (splits === 1 && first === total) {
          return (0, _maybe.just)([total]);
        }

        const rest = splitNicely(total - first, {
          into: splits - 1,
          by: _maybe.default.just(interval)
        });
        return rest.map(x => [first, ...x]);
      },
      Nothing: () => splitBy1(total, splits)
    });
  }

  function truncateDecimals(rawValue, decimalPlaces) {
    if (decimalPlaces < 0) {
      throw new RangeError('`decimalPlaces` must be a positive number');
    }

    let value = rawValue.replace(/[^\d.]/g, '');
    const firstDecimalPoint = value.indexOf('.');

    if (firstDecimalPoint < 0) {
      return value;
    }

    const secondDecimalPoint = value.indexOf('.', firstDecimalPoint + 1);

    if (secondDecimalPoint >= 0) {
      value = value.slice(0, secondDecimalPoint);
    }

    const trimIndex = firstDecimalPoint + decimalPlaces + 1;
    return value.slice(0, trimIndex);
  }

  function roundDecimals(value, decimals = 2) {
    return +value.toFixed(decimals);
  }

  function roundUpToNext(interval, value) {
    return Math.ceil(value / interval) * interval;
  }

  function roundDownToPrevious(interval, value) {
    return Math.floor(value / interval) * interval;
  }
});