define("mobile-web/lib/utilities/freeze", ["exports", "mobile-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = freeze;

  function freeze(obj) {
    if (_environment.default.environment === 'production') {
      return obj;
    }

    return Object.freeze(obj);
  }
});