define("mobile-web/lib/debounce", ["exports", "mobile-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DELAY_MS = _exports.TEST_DELAY_MS = _exports.PROD_DELAY_MS = void 0;
  const PROD_DELAY_MS = 250;
  _exports.PROD_DELAY_MS = PROD_DELAY_MS;
  const TEST_DELAY_MS = 10;
  _exports.TEST_DELAY_MS = TEST_DELAY_MS;
  const DELAY_MS = _environment.default.environment === 'test' ? TEST_DELAY_MS : PROD_DELAY_MS;
  _exports.DELAY_MS = DELAY_MS;
});