define("mobile-web/components/r-discount-form/component", ["exports", "@glimmer/component", "mobile-web/lib/discount", "mobile-web/lib/errors", "mobile-web/lib/utilities/is-some", "mobile-web/services/user-feedback", "mobile-web/components/r-discount-form/style"], function (_exports, _component, _discount, _errors, _isSome, _userFeedback, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DiscountForm = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class DiscountForm extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "userFeedback", _descriptor3, this);

      this.style = _style.default;
      this.TYPES = _discount.DiscountTypes;

      _initializerDefineProperty(this, "couponCode", _descriptor4, this);

      _initializerDefineProperty(this, "discountToApply", _descriptor5, this);

      _initializerDefineProperty(this, "isValidating", _descriptor6, this);

      _initializerDefineProperty(this, "removeButtonDisabled", _descriptor7, this);

      _initializerDefineProperty(this, "showConfirmation", _descriptor8, this);
    }

    // Getters and setters
    get coupon() {
      var _this$basket$basket;

      return (_this$basket$basket = this.basket.basket) == null ? void 0 : _this$basket$basket.coupon;
    }

    get basketCouponCode() {
      var _this$coupon;

      return (_this$coupon = this.coupon) == null ? void 0 : _this$coupon.code;
    }

    get couponApplied() {
      return !!this.basketCouponCode;
    }

    get rewardLabel() {
      var _this$basket$basket2, _this$basket$basket2$;

      return (_this$basket$basket2 = this.basket.basket) == null ? void 0 : (_this$basket$basket2$ = _this$basket$basket2.reward) == null ? void 0 : _this$basket$basket2$.label;
    }

    get hasReward() {
      var _this$basket$basket$h, _this$basket$basket3;

      return (_this$basket$basket$h = (_this$basket$basket3 = this.basket.basket) == null ? void 0 : _this$basket$basket3.hasReward) != null ? _this$basket$basket$h : false;
    }

    get applyButtonDisabled() {
      return this.isValidating || Ember.isEmpty(this.couponCode);
    }

    get discountType() {
      return this.couponApplied ? 'coupon' : 'reward';
    }

    get discountLabel() {
      // this.coupon can be assumed if this.couponApplied is true
      return this.couponApplied ? this.coupon.description : this.rewardLabel;
    }

    get confirmationText() {
      if (this.hasReward && this.couponCode) {
        return this.intl.t('mwc.checkout.rewardAlreadyApplied', {
          discountLabel: this.discountLabel
        });
      }

      return this.intl.t('mwc.checkout.removeRewardDefault', {
        discountType: this.discountType,
        discountLabel: this.discountLabel
      });
    } // Constructor
    // Other methods
    // Tasks
    // Actions


    applyDiscount(change) {
      if (Ember.isEmpty(this.couponCode)) {
        return Ember.RSVP.reject('Discount not valid');
      }

      if (change.type !== _discount.DiscountTypes.Coupon) {
        return Ember.RSVP.reject('invalid discount type');
      }

      this.isValidating = true;

      if (_discount.default.isApplied(this.basket)) {
        this.showConfirmation = true;
        this.discountToApply = change;
        return Ember.RSVP.resolve();
      }

      return _discount.default.apply(this.basket, change).then(() => {
        var _this$args$onDiscount, _this$args;

        // TYPE INVARIANT: this `!` is correct because we only get called here
        // when the basket was successfully updated with an applied coupon.
        this.couponCode = this.basketCouponCode;
        const message = {
          type: _userFeedback.Type.Positive,
          title: this.intl.t('mwc.checkout.addCouponSuccessTitle'),
          message: this.intl.t('mwc.checkout.addCouponSuccessMessage', {
            code: this.basketCouponCode
          }),
          actions: []
        };
        (_this$args$onDiscount = (_this$args = this.args).onDiscountChange) == null ? void 0 : _this$args$onDiscount.call(_this$args, () => {
          this.removeDiscount();
          this.userFeedback.clear(message);
        });
        this.userFeedback.add(message);
      }).catch((0, _errors.buildOloErrorHandler)('apply-discount-failure', reason => Ember.RSVP.reject(reason))).finally(() => {
        this.isValidating = false;
      });
    }

    removeDiscount() {
      this.removeButtonDisabled = true;
      return _discount.default.removeAll(this.basket).then(() => {
        if ((0, _isSome.default)(this.discountToApply)) {
          this.applyDiscount(this.discountToApply);
        } else {
          var _this$args$onDiscount2, _this$args2;

          this.couponCode = '';
          (_this$args$onDiscount2 = (_this$args2 = this.args).onDiscountChange) == null ? void 0 : _this$args$onDiscount2.call(_this$args2);
        }
      }).catch((0, _errors.buildOloErrorHandler)('remove-discount-error', reason => Ember.RSVP.reject(reason))).finally(() => {
        this.removeButtonDisabled = false;
        this.showConfirmation = false;
      });
    }

    beginRemoveDiscount() {
      this.showConfirmation = true;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "userFeedback", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "couponCode", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "discountToApply", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isValidating", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "removeButtonDisabled", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showConfirmation", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "applyDiscount", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "applyDiscount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeDiscount", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "removeDiscount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "beginRemoveDiscount", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "beginRemoveDiscount"), _class.prototype)), _class));
  _exports.default = DiscountForm;
});