define("mobile-web/serializers/basket-product", ["exports", "mobile-web/decorators/persistent", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some", "mobile-web/serializers/application"], function (_exports, _persistent, _, _isSome, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.normalizeBasketProductCustomFields = normalizeBasketProductCustomFields;
  _exports.default = void 0;

  var _dec, _class;

  function normalizeBasketProductCustomFields(payload) {
    // TODO: THE TYPES ARE A LIE! [MWC-2560]
    // It is possible that the payload is actually a Basket payload, rather
    // than a sideloaded Basket payload, in which case basketChoices won't exist.
    // Even if the array does exist, if there are no basket choices present there
    // is no reason for us to clone and iterate over things, so bail.
    if ((0, _.isEmpty)(payload.basketChoices)) {
      return payload;
    }

    const newPayload = (0, _.cloneDeep)(payload); // MWC-2440: Can't edit item with custom message
    // Custom field values are represented at the basket product level, but the choice editor
    // needs a way to handle intermediate states. Custom field values must be copied back into
    // the basket-choice model when loading a basket or basket product.
    // When a basket product is first created (initial product load) and added to basket,
    // the server populates basket customValues.
    // When saving a basket product, serializing the custom field happens here:
    // https://github.com/ololabs/mobile-web-client/blob/5253cca1d361866a01267ddceb6f0f8c701c4521/app/serializers/basket-product.ts#L61-L62

    for (const basketProduct of newPayload.basketProducts) {
      for (const choice of newPayload.basketChoices.filter(bpChoice => basketProduct.basketChoices.includes(bpChoice.id))) {
        choice.customFieldValues = (basketProduct.customValues || []).filter(bpCustomValue => Number(bpCustomValue.choiceId) === Number(choice.choiceId)).map(x => ({
          id: x.customFieldId,
          label: x.label,
          value: x.value
        }));
      }
    }

    return newPayload;
  }

  let BasketProductSerializer = (_dec = (0, _persistent.default)(), _dec(_class = class BasketProductSerializer extends _application.default {
    // TODO: delete this override of super.normalizeDeleteRecordResponse when FF 'olo-578-use-unavailable-upsells-service' is removed
    normalizeDeleteRecordResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.basket) {
        // If there is a basket property, this is a BasketSideLoadModel, and we don't have to do anything special
        return super.normalizeDeleteRecordResponse(store, primaryModelClass, payload, id, requestType);
      } // The payload is the raw updated basket, so handle that


      delete payload.upsellGroups;
      this.store.pushPayload('basket', {
        basket: payload
      });
      return super.normalizeDeleteRecordResponse(store, primaryModelClass, {}, id, requestType);
    }

    normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType) {
      const basketProducts = payload.basketProducts;
      payload.basketProducts = basketProducts.filter(bp => Ember.isNone(store.peekRecord('basket-product', bp.id)));
      return super.normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType);
    }

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      const newPayload = normalizeBasketProductCustomFields(payload);
      return super.normalizeResponse(store, primaryModelClass, newPayload, id, requestType);
    }

    serializeIntoHash(data, _type, record) {
      Object.assign(data, this.serialize(record));
    }

    serialize(s) {
      const snapshot = s;
      const basketProduct = snapshot.record;
      const choices = [];
      const customFieldIds = [];
      const customFieldTexts = [];
      basketProduct.basketChoices.forEach(bc => {
        if (bc.isSelected) {
          var _bc$choice$get;

          choices.push({
            id: bc.choice.get('id'),
            quantity: bc.quantity,
            optionGroupId: (_bc$choice$get = bc.choice.get('optionGroup')) == null ? void 0 : _bc$choice$get.get('id')
          });
          bc.customFieldValues.forEach(cfv => {
            if ((0, _isSome.default)(cfv.value)) {
              customFieldIds.push(`${bc.choice.get('id')}|${cfv.id}`);
              customFieldTexts.push(cfv.value);
            }
          });
        }
      });
      return {
        vendorProductId: basketProduct.product.get('id'),
        quantity: basketProduct.quantity,
        choices,
        customFieldIds,
        customFieldTexts,
        vendorId: basketProduct.vendor.get('id'),
        instructions: basketProduct.specialInstructions,
        recipientName: basketProduct.recipientName
      };
    }

  }) || _class);
  _exports.default = BasketProductSerializer;
});