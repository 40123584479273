define("mobile-web/routes/frequently-asked-questions", ["exports", "mobile-web/decorators/indexed"], function (_exports, _indexed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  let FrequentlyAskedQuestionsRoute = (0, _indexed.default)(_class = class FrequentlyAskedQuestionsRoute extends Ember.Route {
    constructor(...args) {
      super(...args);
      this.titleToken = 'Frequently Asked Questions';
    }

  }) || _class;

  _exports.default = FrequentlyAskedQuestionsRoute;
});