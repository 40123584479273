define("mobile-web/components/r-checkout/tip/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "percentages": "_percentages_vqp7ln",
    "methodContainer": "_methodContainer_vqp7ln",
    "method": "_method_vqp7ln"
  };
  _exports.default = _default;
});