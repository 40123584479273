define("mobile-web/components/routes/region-results-route/component", ["exports", "@glimmer/component", "mobile-web/lib/dayjs", "mobile-web/lib/state", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some", "mobile-web/lib/vendor", "mobile-web/services/analytics", "mobile-web/components/routes/region-results-route/style"], function (_exports, _component, _dayjs, _state, _, _isSome, _vendor, _analytics, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let RegionResultsRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service('order-criteria'), _dec7 = Ember.inject.service, _dec8 = Ember._action, (_class = class RegionResultsRoute extends _component.default {
    // Service injections
    // Passthroughs
    // Tracked properties
    // Getters and setters
    get regionCode() {
      var _this$router$currentR;

      return (_this$router$currentR = this.router.currentRoute.params.region_code) != null ? _this$router$currentR : '';
    }

    get breadcrumbs() {
      var _stateFullName;

      return [{
        label: this.intl.t('mwc.locations.crumbRootLabel'),
        route: 'locations',
        models: []
      }, {
        label: (_stateFullName = (0, _state.stateFullName)(this.regionCode)) != null ? _stateFullName : ''
      }];
    }

    get vendors() {
      return this.args.results.sortBy('address.city');
    }

    get heading() {
      const state = (0, _state.stateFullName)(this.regionCode);
      return `${this.intl.t('mwc.locations.heading')} ${this.intl.t('mwc.locations.headingPreposition')} ${state}`;
    }

    get searchMapURL() {
      var _this$channel$current;

      const url = new URL('https://www.google.com/maps/search/?api=1');
      const regionInfo = (0, _state.allStatesWithCountry)()[this.regionCode];
      url.searchParams.append('query', `${(_this$channel$current = this.channel.current) == null ? void 0 : _this$channel$current.name} in ${regionInfo.name}, ${regionInfo.countryCode.toUpperCase()}`);
      return url.toString();
    }

    get vendorScheduleDay() {
      return (0, _dayjs.default)();
    } // Constructor


    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "analytics", _descriptor3, this);

      _initializerDefineProperty(this, "channel", _descriptor4, this);

      _initializerDefineProperty(this, "basket", _descriptor5, this);

      _initializerDefineProperty(this, "orderCriteriaService", _descriptor6, this);

      _initializerDefineProperty(this, "UserFeedback", _descriptor7, this);

      this.noop = _.noop;
      this.style = _style.default;
      (false && !((0, _isSome.isSome)((0, _state.stateFullName)(this.regionCode))) && Ember.assert('valid `region_code` required', (0, _isSome.isSome)((0, _state.stateFullName)(this.regionCode))));
    } // Other methods
    // Tasks
    // Actions


    viewVendor(newVendor, order = true) {
      const search = this.orderCriteriaService.criteria;
      const handoffMode = search && order ? search.handoffMode : 'N/A';
      const timeWantedMode = search && order ? _analytics.TIME_WANTED_ANALYTICS_LABELS[search.timeWantedType] : 'N/A';
      this.analytics.trackEvent(order ? _analytics.AnalyticsEvents.BeginOrder : _analytics.AnalyticsEvents.ViewMenu, () => ({
        [_analytics.AnalyticsProperties.StoreName]: newVendor.name,
        [_analytics.AnalyticsProperties.StoreCity]: newVendor.address.city,
        [_analytics.AnalyticsProperties.StoreState]: newVendor.address.state,
        [_analytics.AnalyticsProperties.StorePostalCode]: newVendor.address.postalCode,
        [_analytics.AnalyticsProperties.LinkLocation]: 'View Locations Page',
        [_analytics.AnalyticsProperties.SelectedHandoffMode]: handoffMode,
        [_analytics.AnalyticsProperties.SelectedTimeWantedMode]: timeWantedMode
      }));
      this.UserFeedback.clearAll();
      this.router.transitionTo(_vendor.VendorResultRoutes.Menu, newVendor.slug);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "orderCriteriaService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "UserFeedback", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "viewVendor", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "viewVendor"), _class.prototype)), _class));
  _exports.default = RegionResultsRoute;
});