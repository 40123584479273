define("mobile-web/models/dispatch-status", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Event = _exports.DispatchState = _exports.isError = _exports.isDelivered = _exports.CourierStatus = _exports.State = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    attr,
    belongsTo,
    Model
  } = _emberData.default;
  let State;
  _exports.State = State;

  (function (State) {
    State["OrderReceived"] = "OrderReceived";
    State["OrderError"] = "OrderError";
    State["PickupInProgress"] = "PickupInProgress";
    State["PickupError"] = "PickupError";
    State["PickupSucceeded"] = "PickupSucceeded";
    State["DeliveryError"] = "DeliveryError";
    State["DeliverySucceeded"] = "DeliverySucceeded";
    State["ReturnInProgress"] = "ReturnInProgress";
    State["ReturnCompleted"] = "ReturnCompleted";
  })(State || (_exports.State = State = {}));

  let CourierStatus;
  _exports.CourierStatus = CourierStatus;

  (function (CourierStatus) {
    CourierStatus["Active"] = "Active";
    CourierStatus["Completed"] = "Completed";
    CourierStatus["Error"] = "Error";
  })(CourierStatus || (_exports.CourierStatus = CourierStatus = {}));

  const ERROR_STATES = [State.OrderError, State.PickupError, State.DeliveryError];

  const isDelivered = state => state === State.DeliverySucceeded;

  _exports.isDelivered = isDelivered;

  const isError = state => ERROR_STATES.includes(state);

  _exports.isError = isError;
  const DispatchState = {
    isDelivered,
    isError
  };
  _exports.DispatchState = DispatchState;
  let Event;
  _exports.Event = Event;

  (function (Event) {
    Event["StartPickup"] = "StartPickup";
    Event["FailPickup"] = "FailPickup";
    Event["SucceedPickup"] = "SucceedPickup";
    Event["FailDelivery"] = "FailDelivery";
    Event["SucceedDelivery"] = "SucceedDelivery";
  })(Event || (_exports.Event = Event = {}));

  let DispatchStatusModel = (_dec = attr('string'), _dec2 = attr('string'), _dec3 = attr('string'), _dec4 = attr('string'), _dec5 = attr('date'), _dec6 = attr('date'), _dec7 = _emberData.default.attr('number'), _dec8 = _emberData.default.attr('number'), _dec9 = belongsTo('order', {
    async: false
  }), _dec10 = Ember.computed('status'), (_class = class DispatchStatusModel extends Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "deliveryService", _descriptor, this);

      _initializerDefineProperty(this, "status", _descriptor2, this);

      _initializerDefineProperty(this, "statusDescription", _descriptor3, this);

      _initializerDefineProperty(this, "driverName", _descriptor4, this);

      _initializerDefineProperty(this, "estimatedDropoffTimeLocal", _descriptor5, this);

      _initializerDefineProperty(this, "estimatedPickupTimeLocal", _descriptor6, this);

      _initializerDefineProperty(this, "latitude", _descriptor7, this);

      _initializerDefineProperty(this, "longitude", _descriptor8, this);

      _initializerDefineProperty(this, "order", _descriptor9, this);
    }

    get courierStatus() {
      switch (this.status) {
        case State.OrderReceived:
        case State.PickupInProgress:
        case State.PickupSucceeded:
        case State.ReturnInProgress:
          return CourierStatus.Active;

        case State.DeliverySucceeded:
          return CourierStatus.Completed;

        default:
          return CourierStatus.Error;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "deliveryService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "statusDescription", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "driverName", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "estimatedDropoffTimeLocal", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "estimatedPickupTimeLocal", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "latitude", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "longitude", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "order", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "courierStatus", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "courierStatus"), _class.prototype)), _class));
  _exports.default = DispatchStatusModel;
});