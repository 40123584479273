define("mobile-web/models/option-group", ["exports", "ember-data", "true-myth", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some", "mobile-web/lib/utilities/math"], function (_exports, _emberData, _trueMyth, _, _isSome, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.QuantityChoiceViewTypes = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let QuantityChoiceViewTypes;
  _exports.QuantityChoiceViewTypes = QuantityChoiceViewTypes;

  (function (QuantityChoiceViewTypes) {
    QuantityChoiceViewTypes["Slider"] = "Slider";
    QuantityChoiceViewTypes["Input"] = "Input";
  })(QuantityChoiceViewTypes || (_exports.QuantityChoiceViewTypes = QuantityChoiceViewTypes = {}));

  let OptionGroupModel = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = _emberData.default.attr('string'), _dec5 = _emberData.default.attr('string'), _dec6 = _emberData.default.attr('string'), _dec7 = _emberData.default.attr('boolean'), _dec8 = _emberData.default.attr('boolean'), _dec9 = _emberData.default.attr('number'), _dec10 = _emberData.default.attr('number'), _dec11 = _emberData.default.attr('number'), _dec12 = _emberData.default.attr('string', {
    defaultValue: QuantityChoiceViewTypes.Slider
  }), _dec13 = _emberData.default.attr('boolean'), _dec14 = _emberData.default.attr('boolean'), _dec15 = _emberData.default.attr('boolean'), _dec16 = _emberData.default.attr('boolean'), _dec17 = _emberData.default.attr('boolean'), _dec18 = _emberData.default.attr('boolean', {
    defaultValue: true
  }), _dec19 = _emberData.default.attr('boolean'), _dec20 = _emberData.default.attr('number'), _dec21 = _emberData.default.attr('number'), _dec22 = _emberData.default.attr('number'), _dec23 = _emberData.default.attr('number'), _dec24 = _emberData.default.hasMany('choice', {
    async: false
  }), _dec25 = _emberData.default.belongsTo('product', {
    async: false
  }), _dec26 = _emberData.default.belongsTo('choice', {
    async: false
  }), _dec27 = Ember._dependentKeyCompat, _dec28 = Ember._dependentKeyCompat, _dec29 = Ember._dependentKeyCompat, _dec30 = Ember.computed('aggregateQuantity', 'hasChoiceQuantities', 'maxAggregateQuantity', 'maxSelects', 'minAggregateQuantity', 'minSelects', 'selectedBasketChoices.[]', 'selectedCustomFields.@each.value'), _dec31 = Ember._dependentKeyCompat, (_class = class OptionGroupModel extends _emberData.default.Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      _initializerDefineProperty(this, "channel", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "description", _descriptor4, this);

      _initializerDefineProperty(this, "explanationText", _descriptor5, this);

      _initializerDefineProperty(this, "formationGroup", _descriptor6, this);

      _initializerDefineProperty(this, "hasChoiceQuantities", _descriptor7, this);

      _initializerDefineProperty(this, "treatAsProductQuantity", _descriptor8, this);

      _initializerDefineProperty(this, "minChoiceQuantity", _descriptor9, this);

      _initializerDefineProperty(this, "maxChoiceQuantity", _descriptor10, this);

      _initializerDefineProperty(this, "choiceQuantityIncrement", _descriptor11, this);

      _initializerDefineProperty(this, "quantityChoiceView", _descriptor12, this);

      _initializerDefineProperty(this, "hideChoicePrices", _descriptor13, this);

      _initializerDefineProperty(this, "isHidden", _descriptor14, this);

      _initializerDefineProperty(this, "hasNestedGroups", _descriptor15, this);

      _initializerDefineProperty(this, "hasCustomFields", _descriptor16, this);

      _initializerDefineProperty(this, "hasCustomFieldsWithNestedGroups", _descriptor17, this);

      _initializerDefineProperty(this, "isAvailable", _descriptor18, this);

      _initializerDefineProperty(this, "forceSingleSelect", _descriptor19, this);

      _initializerDefineProperty(this, "configuredMinSelect", _descriptor20, this);

      _initializerDefineProperty(this, "configuredMaxSelect", _descriptor21, this);

      _initializerDefineProperty(this, "configuredMinAggregateQuantity", _descriptor22, this);

      _initializerDefineProperty(this, "configuredMaxAggregateQuantity", _descriptor23, this);

      _initializerDefineProperty(this, "choices", _descriptor24, this);

      _initializerDefineProperty(this, "product", _descriptor25, this);

      _initializerDefineProperty(this, "parentChoice", _descriptor26, this);
    }

    get minSelects() {
      var _this$configuredMinSe;

      if (this.forceSingleSelect) {
        return 1;
      }

      let minSelect = (_this$configuredMinSe = this.configuredMinSelect) != null ? _this$configuredMinSe : 0;

      if (minSelect === 0 && this.minAggregateQuantity > 0) {
        minSelect = 1;
      }

      return minSelect;
    }
    /** maxSelects of 0 or undefined means unlimited */


    get maxSelects() {
      return this.forceSingleSelect ? 1 : this.configuredMaxSelect;
    }

    get isRequired() {
      return this.minSelects > 0;
    }

    get multiSelect() {
      return this.maxSelects !== 1;
    }

    get minAggregateQuantity() {
      if (this.treatAsProductQuantity) {
        const primaryGroup = this.product.primaryProductQuantityOptionGroup;

        if (primaryGroup && primaryGroup !== this) {
          return primaryGroup.aggregateQuantity;
        }

        return (0, _math.roundUpToNext)(this.choiceQuantityIncrement, this.product.minimumQuantity);
      }

      return this.configuredMinAggregateQuantity;
    }

    get maxAggregateQuantity() {
      if (this.treatAsProductQuantity) {
        const primaryGroup = this.product.primaryProductQuantityOptionGroup;

        if (primaryGroup && primaryGroup !== this) {
          return primaryGroup.aggregateQuantity;
        }

        return (0, _math.roundDownToPrevious)(this.choiceQuantityIncrement, this.product.maximumQuantity);
      }

      return this.configuredMaxAggregateQuantity;
    }

    get displayGroups() {
      return this.choices.filter(c => c.displayGroup).reduce((groups, c) => {
        // For some odd reason, in display groups, `optionName` is the overall name
        // of the group, and `groupName` is the name of any particular option within
        // the group. We didn't make the rules, we just play by them.
        const existingGroup = groups.find(g => g.name === c.displayGroup.optionName);

        if (existingGroup) {
          existingGroup.choices.push(c);
        } else {
          groups.push({
            name: c.displayGroup.optionName,
            choices: [c]
          });
        }

        return groups;
      }, []);
    }

    get helpText() {
      var _this$maxSelects;

      const max = (_this$maxSelects = this.maxSelects) != null ? _this$maxSelects : 0;
      const min = this.minSelects;
      let selectedHelpText = '';

      if (min === 0 && max > 1) {
        selectedHelpText = this.intl.t('mwc.productCustomization.helpText.maxOnlyLabel', {
          max
        });
      } else if (min > 0 && max === 0) {
        selectedHelpText = this.intl.t('mwc.productCustomization.helpText.minOnlyLabel', {
          min
        });
      } else if (min === max && max > 1) {
        selectedHelpText = this.intl.t('mwc.productCustomization.helpText.bothEqualLabel', {
          max,
          min
        });
      } else if (min !== max && max > 1) {
        selectedHelpText = this.intl.t('mwc.productCustomization.helpText.bothNotEqualLabel', {
          max,
          min
        });
      }

      if (this.selectedBasketChoices.length < min) {
        return selectedHelpText;
      }

      if (this.hasChoiceQuantities && this.minAggregateQuantity > 0 && this.minAggregateQuantity !== min) {
        if (this.minAggregateQuantity === this.maxAggregateQuantity) {
          return this.intl.t('mwc.productCustomization.helpText.aggregateLabel', {
            aggregate: this.minAggregateQuantity
          });
        }

        return this.intl.t('mwc.productCustomization.helpText.minAggregateLabel', {
          min: this.minAggregateQuantity
        });
      }

      return selectedHelpText;
    } // ****** Active basket properties, do not use outside product page ******


    get activeBasketProduct() {
      (false && !((0, _isSome.default)(this.basket.activeBasketProduct)) && Ember.assert('Do not reference `activeBasketProduct` when one has not been set', (0, _isSome.default)(this.basket.activeBasketProduct)));
      return this.basket.activeBasketProduct;
    }

    get basketChoices() {
      if (!this.activeBasketProduct) {
        return [];
      }

      return this.activeBasketProduct.basketChoices.filter(bc => {
        var _bc$choice$content, _bc$choice$content$op;

        return ((_bc$choice$content = bc.choice.content) == null ? void 0 : (_bc$choice$content$op = _bc$choice$content.optionGroup) == null ? void 0 : _bc$choice$content$op.id) === this.id;
      });
    }

    get selectedBasketChoices() {
      return this.basketChoices.filter(bc => bc.isSelected);
    }

    get selectedOptionGroups() {
      return this.selectedBasketChoices.reduce((optionGroups, selectedChoice) => {
        var _selectedChoice$choic;

        if (!((_selectedChoice$choic = selectedChoice.choice.content) != null && _selectedChoice$choic.hasInlineChoices)) {
          var _selectedChoice$optio;

          (_selectedChoice$optio = selectedChoice.optionGroups) == null ? void 0 : _selectedChoice$optio.forEach(og => {
            optionGroups.push(og);
          });
        }

        return optionGroups;
      }, []);
    }

    get selectedCustomFields() {
      return this.selectedBasketChoices.reduce((customFields, selectedChoice) => {
        customFields.push(...selectedChoice.customFieldValues);
        return customFields;
      }, []);
    }

    get aggregateQuantity() {
      return (0, _.sumBy)(this.selectedBasketChoices, c => c.quantity);
    }

    get maxAggregateReached() {
      return this.aggregateQuantity >= this.maxAggregateQuantity;
    }

    get canSelectNewChoices() {
      var _this$channel$setting;

      if (!this.multiSelect) {
        return true;
      }

      const maxSelectsReached = this.maxSelects && this.selectedBasketChoices.length >= this.maxSelects;
      const selectedMin = Math.max(this.minChoiceQuantity, this.choiceQuantityIncrement);
      const maxAggregateReached = this.hasChoiceQuantities && ((_this$channel$setting = this.channel.settings) != null && _this$channel$setting.defaultChoicesToMax ? selectedMin * (this.selectedBasketChoices.length + 1) > this.maxAggregateQuantity : this.aggregateQuantity + selectedMin > this.maxAggregateQuantity);
      return !maxSelectsReached && !maxAggregateReached;
    }

    get selectedChoiceCost() {
      let price = 0;
      this.selectedBasketChoices.forEach(bc => {
        var _bc$choice$get, _bc$optionGroups;

        const choicePrice = (_bc$choice$get = bc.choice.get('priceDifference')) != null ? _bc$choice$get : 0;
        price += choicePrice * bc.quantity;
        (_bc$optionGroups = bc.optionGroups) == null ? void 0 : _bc$optionGroups.forEach(og => {
          price += og.selectedChoiceCost;
        });
      });
      return price;
    }

    get isCompleted() {
      if (this.hasChoiceQuantities && (this.aggregateQuantity < this.minAggregateQuantity || this.aggregateQuantity > this.maxAggregateQuantity)) {
        return false;
      }

      if (this.selectedBasketChoices.length < this.minSelects || this.maxSelects && this.selectedBasketChoices.length > this.maxSelects) {
        return false;
      }

      return this.selectedCustomFields.every(cf => !cf.isRequired || cf.value);
    }

    get canSubmit() {
      return this.isCompleted && this.selectedBasketChoices.every(bc => {
        var _bc$choice$content2;

        return (_bc$choice$content2 = bc.choice.content) == null ? void 0 : _bc$choice$content2.optionGroups.every(og => og.canSubmit);
      });
    }

    get completedOptionsLabel() {
      if ((0, _isSome.default)(this.parentChoice)) {
        const selectedChoices = this.selectedBasketChoices;

        if (!Ember.isEmpty(selectedChoices)) {
          const choicesDescriptions = selectedChoices.map(c => c.choiceLabel);
          return `${this.description}: ${choicesDescriptions.join(', ')}`;
        }
      }

      return '';
    }

    quantityChanged() {
      var _this$activeBasketPro;

      (_this$activeBasketPro = this.activeBasketProduct) == null ? void 0 : _this$activeBasketPro.setQuantityBasedOnChoiceQuantities();

      if (this.product.primaryProductQuantityOptionGroup === this) {
        this.product.firstLevelOptionGroupModels.forEach(og => {
          if (og.treatAsProductQuantity && og !== this) {
            og.selectedBasketChoices.forEach(bc => {
              bc.quantity = 0;
            });
          }
        });
      }
    }

    splitChoiceQuantities() {
      var _this$channel$setting2;

      if ((_this$channel$setting2 = this.channel.settings) != null && _this$channel$setting2.defaultChoicesToMax && this.maxAggregateQuantity) {
        (0, _math.splitNicely)(this.maxAggregateQuantity, {
          into: this.selectedBasketChoices.length,
          by: _trueMyth.Maybe.of(this.choiceQuantityIncrement)
        }).match({
          Just: values => {
            values.forEach((quantity, index) => {
              this.selectedBasketChoices[index].quantity = Math.min(quantity, this.maxChoiceQuantity);
            });
          },
          Nothing: _.noop
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "explanationText", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "formationGroup", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "hasChoiceQuantities", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "treatAsProductQuantity", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "minChoiceQuantity", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "maxChoiceQuantity", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "choiceQuantityIncrement", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "quantityChoiceView", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "hideChoicePrices", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "isHidden", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "hasNestedGroups", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "hasCustomFields", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "hasCustomFieldsWithNestedGroups", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "isAvailable", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "forceSingleSelect", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "configuredMinSelect", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "configuredMaxSelect", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "configuredMinAggregateQuantity", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "configuredMaxAggregateQuantity", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "choices", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "product", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "parentChoice", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "selectedBasketChoices", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "selectedBasketChoices"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedCustomFields", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "selectedCustomFields"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "aggregateQuantity", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "aggregateQuantity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isCompleted", [_dec30], Object.getOwnPropertyDescriptor(_class.prototype, "isCompleted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "completedOptionsLabel", [_dec31], Object.getOwnPropertyDescriptor(_class.prototype, "completedOptionsLabel"), _class.prototype)), _class));
  _exports.default = OptionGroupModel;
});