define("mobile-web/components/r-account-button/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "mobile-web/lib/utilities/_", "mobile-web/components/r-account-button/style"], function (_exports, _glimmerComponent, _, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AccountButton = (_dec = Ember.inject.service, (_class = class AccountButton extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get accountActions() {
      const base = [{
        label: this.intl.t('mwc.header.accountButtonRecentOrdersLabel'),
        route: 'recent-orders',
        icon: 'r-history',
        class: this.args.isEmbedded ? this.style.embeddedRecentOrders : undefined
      }, {
        label: this.intl.t('mwc.saveFavorite.accountButtonFavoriteOrders'),
        route: 'favorites',
        icon: 'r-favorite',
        class: this.args.isEmbedded ? this.style.embeddedFavoriteOrders : this.style.favoriteOrders
      }, {
        label: this.intl.t('mwc.header.accountButtonSettingsLabel'),
        route: 'secure.my-account',
        icon: 'r-account',
        testSelector: 'my-account',
        class: this.args.isEmbedded ? this.style.embeddedAccountSettings : undefined
      }, {
        label: this.intl.t('mwc.header.accountButtonSignOutLabel'),
        action: this.args.onLogout,
        icon: 'r-sign-out',
        testSelector: 'sign-out',
        class: this.args.isEmbedded ? this.style.embeddedSignOut : undefined
      }];
      const offersPosition = base.length - 1;
      return this.args.showOffersInbox ? [...base.slice(0, offersPosition), {
        label: this.intl.t('mwc.header.accountButtonOffersLabel'),
        route: 'rewards-search',
        icon: 'r-loyalty',
        testSelector: 'rewards-search',
        class: this.args.isEmbedded ? this.style.embeddedOffersInbox : undefined
      }, base[offersPosition]] : base;
    }

    get onClick() {
      return this.args.loggedIn ? _.noop : this.args.onLogin;
    } // Constructor
    // Other methods
    // Tasks
    // Actions


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AccountButton;
});