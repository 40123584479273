define("mobile-web/lib/state", ["exports", "mobile-web/lib/country", "mobile-web/lib/utilities/is-some"], function (_exports, _country, _isSome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.states = states;
  _exports.allStatesWithCountry = allStatesWithCountry;
  _exports.stateFullName = stateFullName;
  _exports.default = void 0;
  const STATES = {
    /**
     * https://pe.usps.com/text/pub28/28apb.htm
     *
     * Note:
     *
     * If you check Wikipedia, it lists 50 states, 1 district, and 5 inhabited territories:
     * https://en.wikipedia.org/wiki/List_of_states_and_territories_of_the_United_States
     *
     * How do we get 59? Micronesia, Marshall Islands, and Palau, the freely associated states.
     * https://en.wikipedia.org/wiki/Compact_of_Free_Association
     *
     * This matches the list of 59 from Ghost.
     */
    [_country.Code.US]: {
      AL: 'Alabama',
      AK: 'Alaska',
      AS: 'American Samoa',
      AZ: 'Arizona',
      AR: 'Arkansas',
      CA: 'California',
      CO: 'Colorado',
      CT: 'Connecticut',
      DE: 'Delaware',
      DC: 'District of Columbia',
      FM: 'Federated States of Micronesia',
      FL: 'Florida',
      GA: 'Georgia',
      GU: 'Guam',
      HI: 'Hawaii',
      ID: 'Idaho',
      IL: 'Illinois',
      IN: 'Indiana',
      IA: 'Iowa',
      KS: 'Kansas',
      KY: 'Kentucky',
      LA: 'Louisiana',
      ME: 'Maine',
      MH: 'Marshall Islands',
      MD: 'Maryland',
      MA: 'Massachusetts',
      MI: 'Michigan',
      MN: 'Minnesota',
      MS: 'Mississippi',
      MO: 'Missouri',
      MT: 'Montana',
      NE: 'Nebraska',
      NV: 'Nevada',
      NH: 'New Hampshire',
      NJ: 'New Jersey',
      NM: 'New Mexico',
      NY: 'New York',
      NC: 'North Carolina',
      ND: 'North Dakota',
      MP: 'Northern Mariana Islands',
      OH: 'Ohio',
      OK: 'Oklahoma',
      OR: 'Oregon',
      PW: 'Palau',
      PA: 'Pennsylvania',
      PR: 'Puerto Rico',
      RI: 'Rhode Island',
      SC: 'South Carolina',
      SD: 'South Dakota',
      TN: 'Tennessee',
      TX: 'Texas',
      UT: 'Utah',
      VT: 'Vermont',
      VI: 'Virgin Islands',
      VA: 'Virginia',
      WA: 'Washington',
      WV: 'West Virginia',
      WI: 'Wisconsin',
      WY: 'Wyoming'
    },

    /**
     * https://en.wikipedia.org/wiki/Provinces_and_territories_of_Canada
     */
    [_country.Code.CA]: {
      AB: 'Alberta',
      BC: 'British Columbia',
      MB: 'Manitoba',
      NB: 'New Brunswick',
      NL: 'Newfoundland and Labrador',
      NS: 'Nova Scotia',
      NT: 'Northwest Territories',
      NU: 'Nunavut',
      ON: 'Ontario',
      PE: 'Prince Edward Island',
      QC: 'Quebec',
      SK: 'Saskatchewan',
      YT: 'Yukon'
    }
  };

  function states(country) {
    const stateDictionary = STATES[country];

    if ((0, _isSome.default)(stateDictionary)) {
      return Object.entries(stateDictionary).map(([abbreviation, name]) => ({
        name,
        abbreviation
      }));
    }

    return [];
  }

  function allStatesWithCountry() {
    const allStates = {};

    for (const code of _country.COUNTRIES) {
      states(code).forEach(state => allStates[state.abbreviation] = {
        name: state.name,
        countryCode: code
      });
    }

    return allStates;
  }

  function stateFullName(abbreviation) {
    for (const code of _country.COUNTRIES) {
      var _states$find;

      const name = (_states$find = states(code).find(item => item.abbreviation === abbreviation)) == null ? void 0 : _states$find.name;

      if (name) {
        return name;
      }
    }

    return undefined;
  }

  const State = {
    states
  };
  var _default = State;
  _exports.default = _default;
});