define("mobile-web/components/r-inline-alert/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Mn+wPo2l",
    "block": "[[[10,0],[15,0,[28,[37,0],[[30,0,[\"style\",\"root\"]],[30,1]],null]],[14,\"data-test-inlineAlert\",\"\"],[12],[1,\"\\n\"],[41,[51,[30,0,[\"hideIcon\"]]],[[[1,\"    \"],[10,0],[15,0,[30,0,[\"style\",\"iconContainer\"]]],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"        \"],[1,[30,2]],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,3],null,[[\"@class\",\"@icon\"],[[28,[37,0],[[30,0,[\"style\",\"icon\"]],[30,3]],null],\"r-info\"]],null],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[8,[39,4],null,[[\"@collapsible\",\"@maxHeight\"],[[30,4],[30,5]]],[[\"default\"],[[[[1,\"\\n    \"],[18,6,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"@icon\",\"@iconClass\",\"@collapsible\",\"@maxHeight\",\"&default\"],false,[\"classes\",\"unless\",\"if\",\"r-svg\",\"r-expandable-text\",\"yield\"]]",
    "moduleName": "mobile-web/components/r-inline-alert/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});