define("mobile-web/components/r-link-rewards/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pog98QiX",
    "block": "[[[10,0],[15,0,[30,0,[\"style\",\"root\"]]],[14,\"data-test-linkRewards-form\",\"\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@name\",\"@label\",\"@hideLabel\",\"@placeholder\",\"@inputValue\",\"@class\"],[[28,[37,1],[\"scheme-\",[30,1,[\"id\"]]],null],[28,[37,2],[\"mwc.linkRewards.linkAccountLabel\"],[[\"name\"],[[30,1,[\"schemeName\"]]]]],true,[30,1,[\"identifierFieldName\"]],[30,0,[\"memberId\"]],[30,0,[\"style\",\"field\"]]]],null],[1,\"\\n  \"],[8,[39,3],[[16,\"disabled\",[30,0,[\"isDisabledLink\"]]]],[[\"@onClick\",\"@variant\",\"@testSelector\"],[[28,[37,4],[[30,0,[\"linkAccountTask\"]]],null],\"minor\",[28,[37,1],[\"linkRewards-\",[30,1,[\"id\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],[\"mwc.linkRewards.linkAccountButton\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@scheme\"],false,[\"r-form-field\",\"concat\",\"t\",\"r-button\",\"perform\"]]",
    "moduleName": "mobile-web/components/r-link-rewards/form/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});