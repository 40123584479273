define("mobile-web/lib/utilities/type-helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assertNever = assertNever;

  function assertNever(n) {
    throw new Error(`Unexpected item ${n}`);
  }
});