define("mobile-web/components/r-cart/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "header": "_header_ytkl91 _header_1u092y",
    "headerText": "_headerText_ytkl91",
    "orderTime": "_orderTime_ytkl91",
    "headerButton": "_headerButton_ytkl91",
    "notifications": "_notifications_ytkl91",
    "content": "_content_ytkl91",
    "productList": "_productList_ytkl91",
    "icon": "_icon_ytkl91",
    "iconLg": "_iconLg_ytkl91",
    "emptyCart": "_emptyCart_ytkl91",
    "emptyText": "_emptyText_ytkl91",
    "minimums": "_minimums_ytkl91",
    "minimumMessage": "_minimumMessage_ytkl91",
    "footer": "_footer_ytkl91",
    "checkoutButtonContainer": "_checkoutButtonContainer_ytkl91",
    "checkoutButton": "_checkoutButton_ytkl91",
    "debugButton": "_debugButton_ytkl91",
    "debugInput": "_debugInput_ytkl91"
  };
  _exports.default = _default;
});