define("mobile-web/components/routes/menu/vendor-route/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "filterContainer": "_filterContainer_1jmxu1",
    "filter": "_filter_1jmxu1",
    "typeahead": "_typeahead_1jmxu1",
    "active": "_active_1jmxu1",
    "searchResult": "_searchResult_1jmxu1",
    "recentOrders": "_recentOrders_1jmxu1",
    "recentOrderCard": "_recentOrderCard_1jmxu1",
    "recentOrderBody": "_recentOrderBody_1jmxu1",
    "title": "_title_1jmxu1",
    "categoryContainer": "_categoryContainer_1jmxu1"
  };
  _exports.default = _default;
});