define("mobile-web/components/x-content/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "mobile-web/lib/utilities/is-some", "mobile-web/lib/utilities/transform-content", "mobile-web/components/x-content/style"], function (_exports, _glimmerComponent, _isSome, _transformContent, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let XContent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, (_class = class XContent extends _glimmerComponent.default {
    // Service injections
    // Passthroughs
    // Tracked properties
    // Getters and setters
    get defaultValue() {
      return this.intl.t('mwc.dictionary.loading');
    }

    get type() {
      var _this$args$type;

      return (_this$args$type = this.args.type) != null ? _this$args$type : _transformContent.ContentType.TEXT;
    }

    get substitutions() {
      var _this$args$substituti;

      return (_this$args$substituti = this.args.substitutions) != null ? _this$args$substituti : {};
    }

    get defaultMessage() {
      var _this$args$defaultMes;

      return (_this$args$defaultMes = this.args.defaultMessage) != null ? _this$args$defaultMes : '';
    }

    get isLoaded() {
      return (0, _isSome.isSome)(this.value) && this.value !== this.defaultValue;
    } // Constructor


    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "value", _descriptor3, this);

      const inTeardown = () => this.isDestroyed || this.isDestroying;

      if ((0, _isSome.isSome)(this.args.value) && !inTeardown()) {
        this.value = (0, _transformContent.transformContent)(this.args.value.toString(), this.type, this.substitutions);
        return;
      } // force render if no value on the page already


      if (!this.args.value) {
        this.value = this.defaultValue;
      }

      this.store.findRecord('content', this.args.key).then(content => {
        if (!inTeardown()) {
          const loadedValue = content.value;
          const transformed = (0, _isSome.isSome)(loadedValue) ? (0, _transformContent.transformContent)(loadedValue, this.type, this.substitutions) : this.checkForTranslation(this.defaultMessage);
          this.value = transformed;
        }
      }).catch(() => {
        if (!inTeardown()) {
          this.value = this.checkForTranslation(this.defaultMessage);
        }
      });
    } // Other methods


    checkForTranslation(message) {
      if (Ember.isNone(message)) {
        return '';
      }

      if (this.intl.exists(message)) {
        return this.intl.t(message);
      }

      return message;
    } // Tasks
    // Actions


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = XContent;
});