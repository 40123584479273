define("mobile-web/services/bootstrap", ["exports", "mobile-web/lib/errors", "mobile-web/lib/hybrid-util", "mobile-web/lib/utilities/http", "mobile-web/lib/utilities/is-some"], function (_exports, _errors, _hybridUtil, _http, _isSome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.primeTheStore = primeTheStore;
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // Pull objects from bootstrap data that are needed directly in the store
  function primeTheStore(store, data) {
    const bootstrapChannel = data.channel;
    const timeWantedModes = data.timeWantedModes;
    const handoffModes = data.handoffModes;
    const customLinks = data.customLinks;
    store.pushPayload({
      timeWantedModes,
      handoffModes,
      channel: bootstrapChannel,
      customLinks
    });
  }

  let BootstrapService = (_dec = Ember.inject.service('error'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service('basket'), _dec5 = Ember.inject.service('features'), _dec6 = Ember.inject.service('vendor'), _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember._tracked, _dec10 = Ember._tracked, (_class = class BootstrapService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "error", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "basketService", _descriptor4, this);

      _initializerDefineProperty(this, "features", _descriptor5, this);

      _initializerDefineProperty(this, "vendorService", _descriptor6, this);

      _initializerDefineProperty(this, "orderCriteria", _descriptor7, this);

      _initializerDefineProperty(this, "storage", _descriptor8, this);

      _initializerDefineProperty(this, "data", _descriptor9, this);

      _initializerDefineProperty(this, "initBootstrapFailed", _descriptor10, this);
    }

    get isTranslated() {
      var _this$data, _this$data2;

      return ((_this$data = this.data) == null ? void 0 : _this$data.translationTest) !== ((_this$data2 = this.data) == null ? void 0 : _this$data2.decodedTranslation);
    }

    async initBootstrap() {
      this.initBootstrapFailed = false; // if these bootstrap promises fail continue to app with no saved state.

      const logError = errorOrMessage => {
        const err = errorOrMessage instanceof Error ? errorOrMessage : new Error(errorOrMessage); // @ts-ignore

        if (err && Array.isArray(err.errors) && err.errors.filter(x => x.status === '401').length) {
          return;
        }

        this.error.sendExternalError(err, {
          cause: err.message,
          stack: err.stack,
          cookiesEnabled: navigator.cookieEnabled
        }, ['application-route']);
      };

      await this.session.asyncInit();
      return this.store.findRecord('bootstrap-data', (0, _hybridUtil.channelSlug)()).then(async bootstrapData => {
        primeTheStore(this.store, bootstrapData);
        Ember.set(this, 'data', bootstrapData);
        await this.session.validateServeAppToken(); // Ensure feature flags are setup first.

        await this.features.setupFeatureFlags();
        await this.storage.rehydrateStore();
        const bootstrapUserId = bootstrapData.userId;
        const bootstrapBasketId = bootstrapData.basketGuid;
        const bootstrapBasketVendor = bootstrapData.basketVendorSlug;
        const maybeUser = bootstrapUserId && !this.session.isLoggedIn ? this.session.loadUser(bootstrapUserId) : Ember.RSVP.resolve(undefined);
        const maybeVendor = bootstrapBasketVendor ? this.vendorService.ensureVendorLoaded(bootstrapBasketVendor) : Ember.RSVP.resolve(undefined);
        return Ember.RSVP.hash({
          user: maybeUser,
          vendor: maybeVendor,
          bootstrapData
        }).then(() => {
          const currentlyLoadedBasket = this.basketService.basket;

          if ((0, _isSome.default)(currentlyLoadedBasket)) {
            return Ember.RSVP.resolve(currentlyLoadedBasket);
          }

          if ((0, _isSome.default)(bootstrapBasketId)) {
            return this.basketService.loadBasket(bootstrapBasketId);
          }

          return Ember.RSVP.resolve(undefined);
        });
      }).catch((0, _errors.buildOloErrorHandler)('app-load-failure', reason => {
        if ((0, _isSome.default)(reason) && !Ember.isEmpty(reason.errors)) {
          const status = parseInt(reason.errors[0].status, 10);

          if (status === _http.Status.Forbidden && !navigator.cookieEnabled) {
            return Ember.RSVP.reject(new _errors.FriendlyError('Please enable cookies', 'It appears that you have cookies disabled. In order to use this site, please enable cookies and refresh the page.'));
          }
        }

        logError(reason);
        this.initBootstrapFailed = true;
        return Ember.RSVP.reject(reason);
      }));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "error", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "basketService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "features", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "vendorService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "orderCriteria", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "data", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "initBootstrapFailed", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  })), _class));
  _exports.default = BootstrapService;
});