define("mobile-web/components/r-product-customization/inline-choice/component", ["exports", "@glimmer/component", "mobile-web/lib/menu", "mobile-web/lib/runloop", "mobile-web/lib/utilities/_", "mobile-web/components/r-product-customization/inline-choice/style"], function (_exports, _component, _menu, _runloop, _, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function isDisplayGroup(args) {
    return 'displayGroup' in args;
  }

  const TWO_CHOICE_MAX_CHARS = 11;
  const THREE_CHOICE_MAX_CHARS = 7;
  let ProductCustomizationInlineChoice = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class ProductCustomizationInlineChoice extends _component.default {
    // Service injections
    // Passthroughs
    // Tracked properties
    // Getters and setters

    /**
     * The `activeChoice` is the selected, active child choice of this group.
     * When using displayGroups, it is always the first child with a quantity,
     *  or the first child if all are quantity 0.
     * When using inline choices, if the parent choice is not selected,
     *  then there is no active choice.
     * If the parent choice is selected, then it is the first child with a quantity,
     *  or the first child if all are quantity 0.
     */
    get activeChoice() {
      var _this$basketChoice$qu, _this$basketChoice, _this$basketChoices$f;

      return isDisplayGroup(this.args) || ((_this$basketChoice$qu = (_this$basketChoice = this.basketChoice) == null ? void 0 : _this$basketChoice.quantity) != null ? _this$basketChoice$qu : 0) > 0 ? (_this$basketChoices$f = this.basketChoices.find(bc => bc.quantity > 0)) != null ? _this$basketChoices$f : this.basketChoices[0] : undefined;
    }

    get choiceModel() {
      var _this$activeChoice$ch, _this$activeChoice, _this$activeChoice$ch2;

      return (_this$activeChoice$ch = (_this$activeChoice = this.activeChoice) == null ? void 0 : (_this$activeChoice$ch2 = _this$activeChoice.choice) == null ? void 0 : _this$activeChoice$ch2.content) != null ? _this$activeChoice$ch : this.args.choice;
    }

    get choiceName() {
      return isDisplayGroup(this.args) ? this.args.displayGroup.name : this.args.choice.name;
    }

    get segments() {
      return this.basketChoices.map(bc => ({
        label: bc.choice.content.groupedName,
        value: bc
      }));
    }

    get parentOptionGroup() {
      return isDisplayGroup(this.args) ? this.args.optionGroup : this.args.choice.optionGroup;
    }

    get optionGroup() {
      return isDisplayGroup(this.args) ? this.args.optionGroup : this.args.choice.optionGroups.get('firstObject');
    }

    get choices() {
      var _this$optionGroup$cho, _this$optionGroup, _this$optionGroup$cho2;

      return isDisplayGroup(this.args) ? this.args.displayGroup.choices : (_this$optionGroup$cho = (_this$optionGroup = this.optionGroup) == null ? void 0 : (_this$optionGroup$cho2 = _this$optionGroup.choices) == null ? void 0 : _this$optionGroup$cho2.toArray()) != null ? _this$optionGroup$cho : [];
    }

    get useSegmentedControl() {
      const choiceCount = this.choices.length;
      const maxCharLength = choiceCount === 2 ? TWO_CHOICE_MAX_CHARS : THREE_CHOICE_MAX_CHARS;
      return choiceCount < 4 && this.choices.every(c => {
        var _this$parentOptionGro;

        return (((_this$parentOptionGro = this.parentOptionGroup) == null ? void 0 : _this$parentOptionGro.hideChoicePrices) || !c.priceDifference) && (0, _.isEmpty)(c.calorieLabel) && c.groupedName.length <= maxCharLength;
      });
    } // Constructor


    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "basketChoice", _descriptor2, this);

      _initializerDefineProperty(this, "basketChoices", _descriptor3, this);

      (0, _runloop.safeNext)(this, () => {
        if (!isDisplayGroup(this.args)) {
          this.basketChoice = (0, _menu.getOrCreateBasketChoice)(this.store, this.args.basketProduct, this.args.choice).choice;
        }

        this.updateChoices();
      });
    } // Other methods
    // Tasks
    // Actions


    setActiveChoice(choiceId) {
      this.basketChoices.forEach(bc => {
        bc.quantity = bc.choice.get('id') === choiceId ? 1 : 0;
      });
    }

    setActiveSegment(choice) {
      this.setActiveChoice(choice.value.choice.get('id'));
    }

    updateChoices() {
      if (this.basketChoices.length === 0) {
        let areAllNew = true;
        this.choices.forEach(c => {
          const {
            choice,
            isNew
          } = (0, _menu.getOrCreateBasketChoice)(this.store, this.args.basketProduct, c);
          this.basketChoices.pushObject(choice);
          areAllNew = areAllNew && isNew;
          (0, _menu.loadNestedMenuItems)(this.store, this.args.basketProduct, c, false);
        });

        if (areAllNew && this.choices.length && (0, _menu.isParentNew)(this.args.basketProduct, this.choices[0])) {
          var _this$optionGroup2;

          (_this$optionGroup2 = this.optionGroup) == null ? void 0 : _this$optionGroup2.splitChoiceQuantities();
        }
      }
    }

    toggleChoice(basketChoice) {
      if (isDisplayGroup(this.args)) {
        var _this$args$onClick, _this$args;

        (_this$args$onClick = (_this$args = this.args).onClick) == null ? void 0 : _this$args$onClick.call(_this$args, basketChoice);
      } else {
        // This happens when selecting the choice, so make sure we default child quantities
        if (this.basketChoice === basketChoice) {
          var _this$args$onClick2, _this$args2;

          (_this$args$onClick2 = (_this$args2 = this.args).onClick) == null ? void 0 : _this$args$onClick2.call(_this$args2, basketChoice);

          if (!(0, _.isEmpty)(this.basketChoices) && this.basketChoices.every(bc => bc.quantity === 0)) {
            var _this$basketChoices$, _this$basketChoices$2;

            this.basketChoices[0].quantity = (_this$basketChoices$ = (_this$basketChoices$2 = this.basketChoices[0].choice.content) == null ? void 0 : _this$basketChoices$2.minQuantity) != null ? _this$basketChoices$ : 1;
          }
        } else {
          var _this$args$onClick3, _this$args3;

          // This happens when deselecting the choice, so kick the parent choice up the chain to be deselected
          (_this$args$onClick3 = (_this$args3 = this.args).onClick) == null ? void 0 : _this$args$onClick3.call(_this$args3, this.basketChoice);
        }
      }
    }

    preventPropagation(e) {
      e.stopPropagation();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "basketChoice", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "basketChoices", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setActiveChoice", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setActiveChoice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setActiveSegment", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setActiveSegment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateChoices", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "updateChoices"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleChoice", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "toggleChoice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "preventPropagation", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "preventPropagation"), _class.prototype)), _class));
  _exports.default = ProductCustomizationInlineChoice;
});