define("mobile-web/components/r-vendor-info-modal/calendar/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_93gckt",
    "header": "_header_93gckt",
    "button": "_button_93gckt",
    "minimum": "_minimum_93gckt",
    "tooltip": "_tooltip_93gckt",
    "icon": "_icon_93gckt",
    "upIcon": "_upIcon_93gckt _icon_93gckt",
    "downIcon": "_downIcon_93gckt _icon_93gckt",
    "scheduleDay": "_scheduleDay_93gckt"
  };
  _exports.default = _default;
});