define("mobile-web/components/r-sign-in-create-account-form/component", ["exports", "@ember-decorators/component", "mobile-web/components/r-sign-in-create-account-form/style"], function (_exports, _component, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Mode = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const LABEL_SIGN_IN = 'Sign In';
  const LABEL_CREATE_ACCOUNT = 'Create Account';
  let Mode;
  _exports.Mode = Mode;

  (function (Mode) {
    Mode[Mode["SignIn"] = 0] = "SignIn";
    Mode[Mode["CreateAccount"] = 1] = "CreateAccount";
  })(Mode || (_exports.Mode = Mode = {}));

  const MODES = [{
    label: LABEL_SIGN_IN,
    value: Mode.SignIn
  }, {
    label: LABEL_CREATE_ACCOUNT,
    value: Mode.CreateAccount
  }];
  let // Needed because we can't handle DOM events in a tagless component
  SignInCreateAccountForm = (_dec = (0, _component.tagName)('div'), _dec2 = Ember.computed('mode'), _dec3 = Ember._action, _dec(_class = (_class2 = class // Needed because we can't handle DOM events in a tagless component
  SignInCreateAccountForm extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.mode = Mode.SignIn;
      this.headingLevel = 1;
      this.modes = MODES;
      this.style = _style.default;
    }

    // Computeds
    get isSignInMode() {
      return this.mode === Mode.SignIn;
    }

    switchModes(mode) {
      const onModeChange = this.onModeChange;

      if (onModeChange) {
        onModeChange(mode);
      }
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "isSignInMode", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "isSignInMode"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "switchModes", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "switchModes"), _class2.prototype)), _class2)) || _class);
  _exports.default = SignInCreateAccountForm;
});