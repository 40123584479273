define("mobile-web/modifiers/has-visible", ["exports", "ember-modifier", "mobile-web/lib/utilities/_"], function (_exports, _emberModifier, _) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class HasVisibleModifier extends _emberModifier.default {
    constructor(...args) {
      super(...args);
      this.priorChildren = [];
    }

    /**
     * Callback to run when an item becomes visible.
     */
    get onItemsVisible() {
      const fn = this.args.positional[0];
      const fnType = typeof fn;
      (false && !(fnType === 'function') && Ember.assert(`first positional argument must be 'function' but ${fnType} was provided`, fnType === 'function'));
      return fn;
    }
    /**
     * Get the list of children to observe.
     */


    get children() {
      const children = this.args.positional[1];
      (false && !(Array.isArray(children)) && Ember.assert(`second positional argument must be 'Element[]' but an array was not provided`, Array.isArray(children)));
      return children;
    }
    /**
     * Runs on install and update.
     */


    didReceiveArguments() {
      this.setupObserver();
      this.bindObservers();
    }
    /**
     * Stop observing.
     */


    willDestroy() {
      this.obs.disconnect();
    }
    /**
     * Initialize the intersection observer if we haven't already.
     */


    setupObserver() {
      if (!this.obs) {
        const {
          threshold = 0.5,
          rootMargin,
          root
        } = this.args.named;
        const opts = {
          threshold,
          rootMargin
        };
        this.rootElement = root && document.querySelector(root) || undefined;

        if (this.rootElement) {
          opts.root = this.rootElement;
        }

        this.obs = new IntersectionObserver(this.onIntersection.bind(this), opts);
      }
    }
    /**
     * Stop listening to children that no longer exist, start listening to
     * children that were added.
     */


    bindObservers() {
      this.children.concat(this.priorChildren).forEach(child => {
        const inChildren = this.children.includes(child);
        const inPrior = this.priorChildren.includes(child);

        if (inChildren && !inPrior) {
          this.obs.observe(child);
        } else if (!inChildren && inPrior) {
          this.obs.unobserve(child);
        }
      });
      this.priorChildren = [...this.children];
    }
    /**
     * Determine if an entry should be considered visible. For all items that become visible,
     * run the callback.
     * Don't use `isIntersecting` because Firefox does NOT round well and it isn't reliable.
     */


    onIntersection(entries) {
      const [visible, hidden] = entries.reduce((acc, e) => {
        const isVisible = e.intersectionRatio >= (this.args.named.threshold || 0.5);
        acc[isVisible ? 0 : 1].push(e.target);
        return acc;
      }, [[], []]);

      (this.onItemsVisible || _.noop)(visible, hidden);
    }

  }

  _exports.default = HasVisibleModifier;
});