define("mobile-web/components/routes/order-summary-route/component", ["exports", "@ember-decorators/component", "mobile-web/services/user-feedback", "mobile-web/components/routes/order-summary-route/style"], function (_exports, _component, _userFeedback, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OrderSummaryRoute = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec(_class = (_class2 = class OrderSummaryRoute extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "favorite", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "userFeedback", _descriptor3, this);

      _initializerDefineProperty(this, "vendor", _descriptor4, this);

      _initializerDefineProperty(this, "analytics", _descriptor5, this);

      this.style = _style.default;
    }

    // Computed properties
    // Init
    // Other methods
    // Actions
    onUserOrderEvent(id, hash, event) {
      this.router.transitionTo(event, id, {
        queryParams: {
          hash
        }
      });
    }

    onCancelOrder() {
      this.userFeedback.add({
        type: _userFeedback.Type.Neutral,
        title: 'Cancellation Complete',
        message: 'Your order has been successfully cancelled.'
      });
      this.router.transitionTo('application');
    }

    saveFavorite(orderId, name) {
      return this.favorite.saveFavorite(orderId, name).then(() => {
        this.order.set('isFavorite', true);
        this.userFeedback.add({
          message: `Your order has been saved as ${name}!`,
          title: 'Success!',
          type: _userFeedback.Type.Positive
        });
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "favorite", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "userFeedback", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "vendor", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "analytics", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "onUserOrderEvent", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "onUserOrderEvent"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onCancelOrder", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "onCancelOrder"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "saveFavorite", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "saveFavorite"), _class2.prototype)), _class2)) || _class);
  _exports.default = OrderSummaryRoute;
});