define("mobile-web/components/r-sticky-element/component", ["exports", "@ember-decorators/component", "mobile-web/components/r-header/component", "mobile-web/lib/scroll", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-sticky-element/style"], function (_exports, _component, _component2, _scroll, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  /**
   * We need a delay because the animations take time to complete; for accurate calculations,
   * we need the animations to be done so that the page length is settled.
   * We got this number by experimentation; it's fast enough to not be noticeable,
   * but slow enough that the page length is correct.
   */
  const MUTATION_OBSERVER_DELAY = 100;
  let StickyElement = (_dec = (0, _component.tagName)(''), _dec2 = Ember.computed('class', 'isSticky', 'scrollableContainer'), _dec(_class = (_class2 = class StickyElement extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.class = '';
      this.placeholderClass = '';
      this.disabled = false;
      this.isSticky = false;
      this.style = _style.default;
    }

    // Computed properties
    get stickyElementClass() {
      const isStickyClass = this.isSticky === 'bottom' ? _style.default.isStickyBottom : this.isSticky === 'top' ? _style.default.isStickyTop : '';
      const absoluteClass = this.isSticky && (0, _isSome.default)(this.scrollableContainer) ? _style.default.absolute : '';
      return [_style.default.stickyElement, isStickyClass, absoluteClass, this.class].join(' ');
    }

    get uniqueId() {
      return Ember.guidFor(this);
    } // Init (remove if not needed)


    init() {
      super.init(); // asserts for required properties
    } // Lifecycle methods


    didInsertElement() {
      this.setSticky();
      this.onScroll = (0, _scroll.default)(() => {
        this.setSticky();
      });
      this.scrollLengthObserver = new MutationObserver(() => {
        Ember.run.later(this.onScroll, MUTATION_OBSERVER_DELAY);
      });
      const observedElement = (0, _isSome.default)(this.scrollableContainer) ? this.scrollableContainer : document;
      this.scrollLengthObserver.observe(observedElement, {
        childList: true,
        subtree: true
      });
      (this.scrollableContainer || window).addEventListener('scroll', this.onScroll);
    }

    willDestroyElement() {
      if ((0, _isSome.default)(this.scrollLengthObserver)) {
        this.scrollLengthObserver.disconnect();
      }

      (this.scrollableContainer || window).removeEventListener('scroll', this.onScroll);
    } // Other methods


    setSticky() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      if (this.disabled) {
        this.set('isSticky', false);
        return;
      }
      /** This element occupies the space that the sticky content should slot into. */


      const placeholderElement = document.getElementById(this.uniqueId);
      const stickyElement = placeholderElement.children[0];
      const placeholderBottom = placeholderElement.offsetTop + stickyElement.offsetHeight;
      /**
       * This only matters if the scrollable container is the window
       * because only in that case do we have to account for the height of the app header.
       */

      const headerElement = (0, _isSome.default)(this.scrollableContainer) ? undefined : document.getElementById(_component2.HEADER_ID);
      const headerHeight = headerElement ? headerElement.offsetHeight : 0;
      const finalBottom = placeholderBottom + headerHeight;
      /**
       * We need scrollTop separate because document.body.scrollTop doesn't work.
       * We need window.scrollY here, but everything else works from document.body.
       */

      const scrollTop = (0, _isSome.default)(this.scrollableContainer) ? this.scrollableContainer.scrollTop : window.scrollY;
      const scrollableContainer = (0, _isSome.default)(this.scrollableContainer) ? this.scrollableContainer : document.body;
      const scrollContainerVisibleHeight = window.innerHeight - scrollableContainer.offsetTop;
      const scrollContainerVisibleBottom = scrollTop + scrollContainerVisibleHeight;
      const isAtBottom = scrollContainerVisibleBottom === scrollableContainer.scrollHeight;
      const isAtTop = window.scrollY >= placeholderElement.offsetTop;

      if (scrollContainerVisibleBottom < finalBottom && !isAtBottom) {
        this.setProperties({
          isSticky: 'bottom',
          stickyStyle: Ember.String.htmlSafe('')
        });
      } else if (isAtTop) {
        this.setProperties({
          isSticky: 'top',
          stickyStyle: Ember.String.htmlSafe(`top: ${headerHeight}px`)
        });
      } else {
        this.setProperties({
          isSticky: false,
          stickyStyle: Ember.String.htmlSafe('')
        });
      }
    } // Actions


  }, (_applyDecoratedDescriptor(_class2.prototype, "stickyElementClass", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "stickyElementClass"), _class2.prototype)), _class2)) || _class);
  _exports.default = StickyElement;
});