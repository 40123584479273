define("mobile-web/components/r-app-store-badges/badge/component", ["exports", "@glimmer/component", "mobile-web/lib/app-store", "mobile-web/services/analytics", "mobile-web/components/r-app-store-badges/badge/style"], function (_exports, _component, _appStore, _analytics, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var BadgeType;

  (function (BadgeType) {
    BadgeType["Android"] = "android";
    BadgeType["IOS"] = "ios";
  })(BadgeType || (BadgeType = {}));

  let Badge = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class Badge extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get rootClass() {
      var _style$this$args$type;

      return [_style.default.root, (_style$this$args$type = _style.default[this.args.type]) != null ? _style$this$args$type : ''].join(' ');
    }

    get linkId() {
      return `${this.args.type}-store-link`;
    }

    get href() {
      return this.args.type === BadgeType.IOS ? (0, _appStore.appleStoreLink)(this.args.appIdentifier) : (0, _appStore.googleStoreLink)(this.args.appIdentifier);
    }

    get imgSrc() {
      return this.args.type === BadgeType.IOS ? 'https://apple-resources.s3.amazonaws.com/media-badges/download-on-the-app-store/black/en-us.svg' : 'https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png';
    }

    get imgAltTextKey() {
      return `mwc.footer.${this.args.type}BadgeAltText`;
    } // Constructor
    // Other methods
    // Tasks
    // Actions


    track() {
      this.analytics.trackEvent(_analytics.AnalyticsEvents.DownloadOnAppStore, () => ({
        [_analytics.AnalyticsProperties.AppStore]: this.args.type
      }), {
        isLink: true,
        domQuery: `#${this.linkId}`
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "track", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "track"), _class.prototype)), _class));
  _exports.default = Badge;
});