define("mobile-web/components/r-post-checkout/r-handoff-message/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "handoffMessage": "_handoffMessage_gddu46",
    "noWrap": "_noWrap_gddu46",
    "manualFireInstructions": "_manualFireInstructions_gddu46",
    "instructions": "_instructions_gddu46",
    "dateTime": "_dateTime_gddu46"
  };
  _exports.default = _default;
});