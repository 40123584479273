define("mobile-web/components/routes/region-results-route/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "nav": "_nav_11z7r8",
    "title": "_title_11z7r8",
    "heading": "_heading_11z7r8",
    "breadcrumbs": "_breadcrumbs_11z7r8",
    "grid": "_grid_11z7r8"
  };
  _exports.default = _default;
});