define("mobile-web/routes/favorites", ["exports", "mobile-web/decorators/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  let Favorites = (0, _authenticated.default)(_class = class Favorites extends Ember.Route {
    constructor(...args) {
      super(...args);
      this.titleToken = 'Favorite Orders';
    }

    model() {
      return this.store.query('favorite', {});
    }

  }) || _class;

  _exports.default = Favorites;
});