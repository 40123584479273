define("mobile-web/components/-private/masked-input", ["exports", "ember-text-mask/components/masked-input"], function (_exports, _maskedInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _maskedInput.default.extend({
    update() {
      Ember.run.schedule('afterRender', () => {
        this.get('textMaskInputElement').update(...arguments);
      });
    }

  });

  _exports.default = _default;
});