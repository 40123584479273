define("mobile-web/components/r-application-loading/thank-you/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_161rg8",
    "header": "_header_161rg8",
    "content": "_content_161rg8",
    "button": "_button_161rg8",
    "grid": "_grid_161rg8",
    "gridContent": "_gridContent_161rg8",
    "gridButton": "_gridButton_161rg8"
  };
  _exports.default = _default;
});