define("mobile-web/components/r-product-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kf38xJ1O",
    "block": "[[[8,[39,0],null,[[\"@open\",\"@title\",\"@transition\",\"@modalClass\",\"@testSelector\",\"@onCloseComplete\",\"@onClose\",\"@onOutsideClick\"],[[30,0,[\"isOpen\"]],[30,0,[\"eventArgs\",\"product\",\"name\"]],[52,[28,[37,2],[\"isTablet\"],null],\"fade\",\"slideUp\"],[30,0,[\"style\",\"modal\"]],\"productModal\",[30,0,[\"onCloseComplete\"]],[30,0,[\"close\"]],[30,0,[\"close\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,3],null,[[\"@basketProduct\",\"@product\",\"@displayInModal\",\"@previousRoute\",\"@onSave\"],[[30,0,[\"eventArgs\",\"basketProduct\"]],[30,0,[\"eventArgs\",\"product\"]],true,[30,0,[\"eventArgs\",\"previousRoute\"]],[30,0,[\"onSave\"]]]],null],[1,\"\\n\"]],[]]]]]],[],false,[\"r-animated-modal\",\"if\",\"media\",\"r-product-customization\"]]",
    "moduleName": "mobile-web/components/r-product-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});