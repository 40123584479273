define("mobile-web/templates/order-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O6KK+ulm",
    "block": "[[[8,[39,0],null,[[\"@order\"],[[33,1,[\"order\"]]]],null]],[],false,[\"routes/order-summary-route\",\"model\"]]",
    "moduleName": "mobile-web/templates/order-summary.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});