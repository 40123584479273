define("mobile-web/serializers/order-submission", ["exports", "ember-data", "mobile-web/lib/payment", "mobile-web/lib/payment/method", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some", "mobile-web/lib/utilities/math"], function (_exports, _emberData, _payment, _method, _, _isSome, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OrderSubmissionSerializer = (_dec = Ember.inject.service, (_class = class OrderSubmissionSerializer extends _emberData.default.JSONSerializer {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      this.primaryKey = 'orderId';
    }

    serialize(s, options) {
      const snapshot = s;
      const json = (0, _.cloneDeep)(super.serialize(snapshot, options));
      delete json.orderId;

      if (snapshot.record.guestNewsletterOptIn === undefined) {
        delete json.guestNewsletterOptIn;
      }

      const tip = (0, _isSome.default)(this.basket.basket) ? this.basket.basket.tip : 0;

      if ((0, _isSome.default)(json.customFields)) {
        json.customFields.forEach(c => {
          delete c.valueMessages;
        });
      }

      json.selectedBillingMethods.forEach(m => {
        if (m.variant === _method.Variant.NewCard && (0, _payment.isCreditCardWithAddress)(m.paymentCard)) {
          m.paymentCard.countryCode = m.paymentCard.countryCode.toUpperCase();
        }

        if (tip === 0) {
          Ember.set(m, 'includesTip', false);
        } else if (m.includesTip && json.selectedBillingMethods.length > 1) {
          Ember.set(m, 'amount', m.amount + tip);
        }

        Ember.set(m, 'amount', (0, _math.roundDecimals)(m.amount));
      });
      return _extends({}, json, json.bookingUser);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OrderSubmissionSerializer;
});