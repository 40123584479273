define("mobile-web/templates/components/r-icons/r-shopping-bag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1JTL1KGG",
    "block": "[[[10,0],[15,0,[30,0,[\"rootClass\"]]],[12],[1,\"\\n\"],[1,\"  \"],[1,[28,[35,0],[\"r-shopping-bag\"],[[\"class\"],[[30,0,[\"style\",\"icon\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"svg-jar\"]]",
    "moduleName": "mobile-web/templates/components/r-icons/r-shopping-bag.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});