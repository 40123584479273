define("mobile-web/components/r-typeahead/component", ["exports", "@glimmer/component", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-typeahead/style"], function (_exports, _component, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const MAX_RESULTS = 20;
  let Typeahead = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class Typeahead extends _component.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;

      _initializerDefineProperty(this, "active", _descriptor, this);

      _initializerDefineProperty(this, "search", _descriptor2, this);

      _initializerDefineProperty(this, "rootElement", _descriptor3, this);
    }

    // Getters and setters
    get matchedItems() {
      if ('filter' in this.args) {
        return this.args.items.filter(obj => this.args.filter(this.search, obj)).slice(0, MAX_RESULTS);
      } else if (this.args.searcher) {
        return this.args.searcher.search(this.search).slice(0, MAX_RESULTS);
      }

      return [];
    } // Constructor
    // Other methods
    // Tasks
    // Actions


    onFocusIn() {
      var _this$args$onOpen, _this$args;

      this.active = true;
      (_this$args$onOpen = (_this$args = this.args).onOpen) == null ? void 0 : _this$args$onOpen.call(_this$args);
    }

    close() {
      var _this$args$onClose, _this$args2;

      this.active = false;
      (_this$args$onClose = (_this$args2 = this.args).onClose) == null ? void 0 : _this$args$onClose.call(_this$args2);
    }

    onSelect(item) {
      var _this$args$onSelect, _this$args3;

      (_this$args$onSelect = (_this$args3 = this.args).onSelect) == null ? void 0 : _this$args$onSelect.call(_this$args3, item);
      this.close();
    }

    setupOutsideClick(rootElement) {
      this.rootElement = rootElement;
      document.addEventListener('click', this.handleOutsideClick);
    }

    teardownOutsideClick() {
      this.rootElement = undefined;
      document.removeEventListener('click', this.handleOutsideClick);
    }

    handleOutsideClick(event) {
      if (!this.active) {
        return;
      }

      const target = event.target;

      if ((0, _isSome.default)(target) && !this.rootElement.contains(target)) {
        this.close();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "active", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "rootElement", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onFocusIn", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onFocusIn"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelect", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupOutsideClick", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setupOutsideClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "teardownOutsideClick", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "teardownOutsideClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleOutsideClick", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "handleOutsideClick"), _class.prototype)), _class));
  _exports.default = Typeahead;
});