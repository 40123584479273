define("mobile-web/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UiB7pda9",
    "block": "[[[8,[39,0],null,[[\"@onLogin\",\"@onLogout\"],[[30,0,[\"goToLogin\"]],[30,0,[\"logout\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,\"main\"],[15,0,[28,[37,1],[[30,0,[\"style\",\"main\"]],[30,0,[\"session\",\"className\"]]],null]],[14,\"role\",\"main\"],[12],[1,\"\\n    \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"r-application-frame\",\"classes\",\"component\",\"-outlet\"]]",
    "moduleName": "mobile-web/templates/application.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});