define("mobile-web/components/r-sticky-element/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "placeholder": "_placeholder_13dze5",
    "stickyElement": "_stickyElement_13dze5",
    "isSticky": "_isSticky_13dze5",
    "isStickyBottom": "_isStickyBottom_13dze5 _isSticky_13dze5",
    "isStickyTop": "_isStickyTop_13dze5 _isSticky_13dze5",
    "absolute": "_absolute_13dze5"
  };
  _exports.default = _default;
});