define("mobile-web/components/r-post-checkout/r-thanks-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tok8gza1",
    "block": "[[[8,[39,0],null,[[\"@heading\",\"@class\"],[[28,[37,1],[\"mwc.postCheckout.thankyouHeader\"],null],[30,0,[\"style\",\"section\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"subsection\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@icon\"],[[50,\"r-svg\",0,null,[[\"icon\",\"ariaLabel\"],[\"r-comment\",\"Comment\"]]]]],[[\"content\"],[[[[1,\"\\n        \"],[10,0],[15,0,[30,0,[\"style\",\"thanksMessage\"]]],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"            \"],[10,2],[14,\"data-test-post-checkout-thanks-handoff-instructions\",\"\"],[12],[1,\"\\n              \"],[1,[28,[35,5],[[30,3]],null]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n          \"],[10,2],[15,0,[52,[30,2],[30,0,[\"style\",\"dispatchThanks\"]],\"\"]],[14,\"data-test-post-checkout-thanks-message-text\",\"\"],[12],[1,\"\\n            \"],[8,[39,6],null,[[\"@type\",\"@key\"],[\"html\",\"THANKS_ALLORDERMESSAGE\"]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"h\",\"@isDispatch\",\"@handoffInstructions\"],false,[\"r-heading-section\",\"t\",\"r-post-checkout/r-subsection\",\"component\",\"if\",\"trusted-unsafe-html\",\"x-content\"]]",
    "moduleName": "mobile-web/components/r-post-checkout/r-thanks-message/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});