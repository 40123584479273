define("mobile-web/routes/menu/vendor/products", ["exports", "mobile-web/decorators/indexed"], function (_exports, _indexed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProductIndexRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (0, _indexed.default)(_class = (_class2 = class ProductIndexRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "vendor", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "routerScroll", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);
    }

    titleToken(model) {
      return model.product.name;
    }

    async model(params) {
      var _this$router$currentR;

      const vendorSlug = this.paramsFor('menu.vendor').vendor_slug;
      const previousRoute = {
        label: '',
        route: '',
        models: []
      };
      const product = await this.store.findRecord('product', params.product_id);
      const category = product.category;

      if (((_this$router$currentR = this.router.currentRoute) == null ? void 0 : _this$router$currentR.name) === 'menu.category' && category) {
        previousRoute.label = 'Category';
        previousRoute.route = 'menu.category';
        previousRoute.models = [vendorSlug, category.get('id')];
      } else {
        previousRoute.label = 'Menu';
        previousRoute.route = 'menu.vendor';
        previousRoute.models = [vendorSlug];
      }

      const displayInModal = !product.hasChoices;

      if (displayInModal) {
        this.routerScroll.set('preserveScrollPosition', true);
      }

      return {
        displayInModal,
        previousRoute,
        product
      };
    }

    resetController() {
      // We want to reset the value of preserveScrollPosition, but we
      // need to wait till the transition fully completes before doing
      // so. Putting it in a `next` gives the transition time to complete.
      Ember.run.next(this, () => {
        this.routerScroll.set('preserveScrollPosition', false);
      });
    }

    shouldIndex() {
      const vendor = this.vendor.vendor;

      if (Ember.isNone(vendor)) {
        return false;
      }

      return vendor.status.toLowerCase() === 'public';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "vendor", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "routerScroll", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = ProductIndexRoute;
});