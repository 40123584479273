define("mobile-web/components/r-checkout/order-type/component", ["exports", "@glimmer/component", "mobile-web/lib/order-criteria", "mobile-web/components/r-checkout/order-type/style"], function (_exports, _component, _orderCriteria, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CheckoutOrderType = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service('order-criteria'), _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._action, (_class = class CheckoutOrderType extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      _initializerDefineProperty(this, "error", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "mwcIntl", _descriptor4, this);

      _initializerDefineProperty(this, "orderCriteriaService", _descriptor5, this);

      _initializerDefineProperty(this, "session", _descriptor6, this);

      _initializerDefineProperty(this, "store", _descriptor7, this);

      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get orderCriteria() {
      return this.orderCriteriaService.basketOrderCriteria;
    }

    get isDelivery() {
      return (0, _orderCriteria.isDelivery)(this.orderCriteria);
    }

    get orderTime() {
      if ((0, _orderCriteria.isAdvance)(this.orderCriteria) && this.orderCriteria.timeWanted) {
        return this.mwcIntl.relativeDateTime(this.orderCriteria.timeWanted);
      }

      if (this.orderCriteria.timeWantedType === 'Immediate') {
        var _leadTimeEstimate;

        return this.intl.t('mwc.checkout.immediateLabel', {
          description: (0, _orderCriteria.getTimeWantedTypeLabel)(this.orderCriteria.timeWantedType, this.store),
          leadTime: (_leadTimeEstimate = this.basket.basket.leadTimeEstimate) != null ? _leadTimeEstimate : 0
        });
      }

      return (0, _orderCriteria.getTimeWantedTypeLabel)(this.orderCriteria.timeWantedType, this.store);
    } // Constructor
    // Other methods
    // Tasks
    // Actions


    async openModal() {
      this.orderCriteriaService.openModal({
        onChange: () => {
          var _this$args$onBasketCh, _this$args;

          (_this$args$onBasketCh = (_this$args = this.args).onBasketChange) == null ? void 0 : _this$args$onBasketCh.call(_this$args);
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "error", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "mwcIntl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "orderCriteriaService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openModal", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "openModal"), _class.prototype)), _class));
  _exports.default = CheckoutOrderType;
});