define("mobile-web/components/r-link-rewards/form/component", ["exports", "@ember-decorators/component", "ember-concurrency", "ember-concurrency-ts", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some", "mobile-web/services/user-feedback", "mobile-web/components/r-link-rewards/form/style"], function (_exports, _component, _emberConcurrency, _emberConcurrencyTs, _, _isSome, _userFeedback, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LinkRewardsForm = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed('linkAccountTask.isRunning', 'memberId'), _dec(_class = (_class2 = class LinkRewardsForm extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "loyalty", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "userFeedback", _descriptor4, this);

      this.memberId = '';
      this.style = _style.default;
    }

    // Computed properties
    get isDisabledLink() {
      return (0, _emberConcurrencyTs.taskFor)(this.linkAccountTask).isRunning || (0, _.isEmpty)(this.memberId);
    } // Init


    init() {
      super.init();
      (false && !((0, _isSome.default)(this.scheme)) && Ember.assert('`schemes` are required', (0, _isSome.default)(this.scheme)));
    } // Other methods
    // Tasks


    *linkAccountTask() {
      var _this$onLink;

      yield this.loyalty.linkMembershipAccount(this.memberId, this.scheme);
      this.userFeedback.add({
        type: _userFeedback.Type.Positive,
        title: this.intl.t('mwc.feedbackBanner.successTitle'),
        message: this.intl.t('mwc.linkRewards.linkMembershipSuccessMessage', {
          provider: this.scheme.loyaltyProvider
        }),
        actions: [{
          label: this.intl.t('mwc.linkRewards.linkMembershipSuccessButton'),
          fn: () => {
            this.router.transitionTo('menu.vendor.rewards', this.scheme.vendor.slug);
          }
        }]
      });
      (_this$onLink = this.onLink) == null ? void 0 : _this$onLink.call(this);
    } // Actions


  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "loyalty", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "userFeedback", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "isDisabledLink", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "isDisabledLink"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "linkAccountTask", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class2.prototype, "linkAccountTask"), _class2.prototype)), _class2)) || _class);
  _exports.default = LinkRewardsForm;
});