define("mobile-web/lib/app-store", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.appleStoreLink = appleStoreLink;
  _exports.appleStoreListingLink = appleStoreListingLink;
  _exports.googleStoreLink = googleStoreLink;
  _exports.googleStoreListingLink = googleStoreListingLink;

  function appleStoreLink(appIdentifier) {
    return `https://itunes.apple.com/us/app/id${appIdentifier}`;
  }

  function appleStoreListingLink(appIdentifier) {
    return `itms-apps://itunes.apple.com/us/app/id${appIdentifier}`;
  }

  function googleStoreLink(appIdentifier) {
    return `https://play.google.com/store/apps/details?id=${appIdentifier}`;
  }

  function googleStoreListingLink(appIdentifier) {
    return `market://details?id=${appIdentifier}`;
  }
});