define("mobile-web/services/window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class WindowService extends Ember.Service {
    location() {
      return window.location;
    }

    get OLO() {
      if (Ember.isNone(window.OLO)) {
        window.OLO = {};
      }

      return window.OLO;
    }

    set OLO(value) {
      window.OLO = value;
    }

  }

  _exports.default = WindowService;
});