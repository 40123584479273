define("mobile-web/components/r-digital-wallets-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pNoGmK1E",
    "block": "[[[10,0],[14,\"data-olo-pay-payment-button\",\"\"],[12],[13],[1,\"\\n\"],[10,1],[14,\"data-olo-pay-payment-button-separator\",\"\"],[15,0,[30,0,[\"style\",\"separator\"]]],[12],[1,\"Or\"],[13]],[],false,[]]",
    "moduleName": "mobile-web/components/r-digital-wallets-button/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});