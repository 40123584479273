define("mobile-web/lib/time/iso8601", ["exports", "true-myth", "mobile-web/lib/dayjs"], function (_exports, _trueMyth, _dayjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A known valid ISO8601-formatted string. Useful for making sure the time data
   * you're using is reliable.
   */
  class ISO8601 {
    /**
     * Construct an ISO8601 value safely
     * @param value A string to validate as ISO8601.
     * @returns `Just<ISO8601>` if the string is valid; `Nothing` otherwise.
     */
    static fromString(value) {
      const time = (0, _dayjs.default)(value);
      return time.isValid() ? _trueMyth.Maybe.just(new ISO8601(time.toISOString())) : _trueMyth.Maybe.nothing();
    }
    /**
     * Construct an ISO8601 value given a Dayjs instance.
     * @param time A `Dayjs` instance to construct the value with.
     * @returns `Just<ISO8601>` if `time.isValid()` is `true`, otherwise `Nothing`
     */


    static fromDayjs(time) {
      return time.isValid() ? _trueMyth.Maybe.just(new ISO8601(time.toISOString())) : _trueMyth.Maybe.nothing();
    }

    constructor(value) {
      this.value = value;
    }

  }

  _exports.default = ISO8601;
});