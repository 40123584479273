define("mobile-web/components/r-money-form-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "16aQOjSB",
    "block": "[[[8,[39,0],[[24,\"data-test-moneyFormField\",\"\"],[17,1]],[[\"@name\",\"@label\",\"@hideLabel\",\"@icon\",\"@inputValue\",\"@onInput\",\"@formatValue\"],[[30,2],[30,3],true,\"usd\",[30,0,[\"displayValue\"]],[28,[37,1],[[30,0],[30,0,[\"setAmount\"]]],null],[28,[37,1],[[30,0],[30,0,[\"formatValue\"]]],null]]],null],[1,\"\\n\"]],[\"&attrs\",\"@name\",\"@label\"],false,[\"r-form-field\",\"action\"]]",
    "moduleName": "mobile-web/components/r-money-form-field/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});