define("mobile-web/components/r-application-loading/password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y5+VIHUO",
    "block": "[[[8,[39,0],[[16,0,[30,0,[\"style\",\"root\"]]]],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"header\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"textEachArray\"]]],null]],null],null,[[[1,\"    \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"text\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n\"]],[]],null],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"inputEachArray\"]]],null]],null],null,[[[1,\"    \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"input\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n\"]],[]],null]],[1]]]]]],[\"placeholder\"],false,[\"content-placeholders\",\"each\",\"-track-array\"]]",
    "moduleName": "mobile-web/components/r-application-loading/password/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});