define("mobile-web/instance-initializers/collection-action", ["exports", "ember-data/store"], function (_exports, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  async function collectionAction(modelName, key, params) {
    const model = this.createRecord(modelName);

    try {
      const action = model[key]; // `.name` is minified out in production builds, so this check can only be done in an assert.

      (false && !(action && typeof action === 'function' && action.name === 'runCollectionOp') && Ember.assert(`${key} should be a collection action on ${modelName}`, action && typeof action === 'function' && action.name === 'runCollectionOp')); // The above assert proves this type conversion is OK.

      const promiseAction = action;
      return await promiseAction.call(model, params);
    } finally {
      model.deleteRecord();
    }
  }

  function initialize(appInstance) {
    const store = appInstance.lookup('service:store');
    store.reopen({
      collectionAction
    });
  }

  var _default = {
    name: 'collection-action',
    initialize
  };
  _exports.default = _default;
});