define("mobile-web/components/r-post-checkout/r-share-section/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_pc1mad",
    "icon": "_icon_pc1mad",
    "text": "_text_pc1mad",
    "buttons": "_buttons_pc1mad"
  };
  _exports.default = _default;
});