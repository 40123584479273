define("mobile-web/routes/menu/index", ["exports", "mobile-web/lib/computed", "mobile-web/services/analytics", "mobile-web/routes/vendor-search-results"], function (_exports, _computed, _analytics, _vendorSearchResults) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MenuRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class MenuRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "localVendorSearchParams", _descriptor4, this);
    }

    model() {
      return _vendorSearchResults.search.call(this);
    }

    afterModel(resolvedModel) {
      if (!this.localVendorSearchParams) {
        this.router.transitionTo('index');
      }

      const isNearby = vendor => {
        const nearbyDistance = 6.0;
        const {
          hasOnlineOrdering,
          displayNationalMenu,
          distance
        } = vendor;

        if (!distance) {
          return false;
        }

        return (hasOnlineOrdering || displayNationalMenu) && distance <= nearbyDistance;
      };

      const nearbyVendors = resolvedModel.results.filter(isNearby);
      const availableVendor = nearbyVendors.length === 1 ? nearbyVendors.filter(v => {
        var _v$preCheckResult;

        return v.available && ((_v$preCheckResult = v.preCheckResult) == null ? void 0 : _v$preCheckResult.isValid);
      })[0] : undefined;
      let skippedLocation = false;

      if (availableVendor) {
        skippedLocation = true;
        this.store.adapterFor('telemetry').searchLocationsSkipped();
        this.router.transitionTo('menu.vendor', availableVendor.slug);
      } else {
        this.router.transitionTo('vendor-search-results');
      }

      this.analytics.trackEvent(_analytics.AnalyticsEvents.ManualLocationSelectionSkipped, () => ({
        [_analytics.AnalyticsProperties.ManualLocationSelectionSkipped]: skippedLocation ? 'Yes' : 'No'
      }));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "localVendorSearchParams", [_computed.local], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = MenuRoute;
});