define("mobile-web/components/r-application-loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A6pXzJfH",
    "block": "[[[11,0],[16,0,[30,0,[\"style\",\"root\"]]],[24,\"data-test-applicationLoading\",\"\"],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"loading\",\"isAuth\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@isCheckoutAuth\",\"@isCreateAccount\"],[[30,0,[\"loading\",\"isAuthCheckout\"]],[30,0,[\"loading\",\"isAuthCreateAccount\"]]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"loading\",\"isCheckout\"]],[[[1,\"    \"],[8,[39,2],null,null,null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"loading\",\"isLocations\"]],[[[1,\"    \"],[8,[39,3],null,null,null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"loading\",\"isMenu\"]],[[[1,\"    \"],[8,[39,4],null,[[\"@isProduct\",\"@isViewAll\"],[[30,0,[\"loading\",\"isMenuProduct\"]],[30,0,[\"loading\",\"isMenuViewAll\"]]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"loading\",\"isMyAccount\"]],[[[1,\"    \"],[8,[39,5],null,null,null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"loading\",\"isPassword\"]],[[[1,\"    \"],[8,[39,6],null,[[\"@isChange\",\"@isForgot\",\"@isReset\"],[[30,0,[\"loading\",\"isPasswordChange\"]],[30,0,[\"loading\",\"isPasswordForgot\"]],[30,0,[\"loading\",\"isPasswordReset\"]]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"loading\",\"isSearch\"]],[[[1,\"    \"],[8,[39,7],null,null,null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"loading\",\"isSplash\"]],[[[1,\"    \"],[8,[39,8],null,null,null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"loading\",\"isThankYou\"]],[[[1,\"    \"],[8,[39,9],null,null,null],[1,\"\\n  \"]],[]],null]],[]]]],[]]]],[]]]],[]]]],[]]]],[]]]],[]]]],[]]],[13]],[\"&attrs\"],false,[\"if\",\"r-application-loading/auth\",\"r-application-loading/checkout\",\"r-application-loading/locations\",\"r-application-loading/menu\",\"r-application-loading/my-account\",\"r-application-loading/password\",\"r-application-loading/search\",\"r-application-loading/splash\",\"r-application-loading/thank-you\"]]",
    "moduleName": "mobile-web/components/r-application-loading/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});