define("mobile-web/components/r-image-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "csryC4JS",
    "block": "[[[11,0],[16,0,[30,0,[\"style\",\"background\"]]],[16,5,[30,0,[\"inlineStyle\"]]],[24,\"data-test-image-card\",\"\"],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "mobile-web/components/r-image-card/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});