define("mobile-web/models/order-failure", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    Model
  } = _emberData.default;

  class OrderFailureModel extends Model.extend({
    order: belongsTo('order'),
    basket: belongsTo('basket'),
    // Note: should be mapped from Retryable.Encouraged -> True, .Pointless or .Unsafe -> False
    canRetry: attr('boolean'),
    customerFriendlyMessage: attr('string')
  }) {}

  _exports.default = OrderFailureModel;
});