define("mobile-web/helpers/has-key", ["exports", "@glint/environment-ember-loose/ember-component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable @typescript-eslint/no-explicit-any */
  var _default = (0, _helper.helper)(([object, key]) => typeof object === 'object' && !!object && key in object);

  _exports.default = _default;
});