define("mobile-web/components/r-money-form-field/component", ["exports", "@glimmer/component", "mobile-web/lib/utilities/is-some", "mobile-web/lib/utilities/math", "mobile-web/components/r-money-form-field/style"], function (_exports, _component, _isSome, _math, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MoneyFormField = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class MoneyFormField extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "value", _descriptor2, this);
    }

    // Getters and setters
    get displayValue() {
      if ((0, _isSome.default)(this.value) && Object.is(MoneyFormField.sanitizeDecimal(this.value), this.args.inputValue)) {
        return this.value;
      }

      if ((0, _isSome.default)(this.args.inputValue)) {
        return this.args.inputValue.toLocaleString(this.intl.locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        });
      }

      return '';
    }

    set displayValue(rawValue) {
      this.value = this.formatValue(rawValue);
    } // Constructor
    // Other methods


    static sanitizeDecimal(value = '') {
      return value === '.' ? 0 : +value;
    } // Tasks
    // Actions


    setAmount(value = '') {
      var _this$args$onInput, _this$args;

      (_this$args$onInput = (_this$args = this.args).onInput) == null ? void 0 : _this$args$onInput.call(_this$args, MoneyFormField.sanitizeDecimal(value));
    }

    formatValue(value = '') {
      return (0, _math.truncateDecimals)(value, 2);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setAmount", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setAmount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "formatValue", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "formatValue"), _class.prototype)), _class));
  _exports.default = MoneyFormField;
});