define("mobile-web/components/r-sticky-nav/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_brf3ve",
    "stickyElement": "_stickyElement_brf3ve",
    "stickyElementPlaceholder": "_stickyElementPlaceholder_brf3ve",
    "content": "_content_brf3ve",
    "stickyNav": "_stickyNav_brf3ve",
    "isSticky": "_isSticky_brf3ve",
    "isLoading": "_isLoading_brf3ve",
    "navItemContainer": "_navItemContainer_brf3ve",
    "button": "_button_brf3ve",
    "active": "_active_brf3ve",
    "pushLeft": "_pushLeft_brf3ve",
    "trigger": "_trigger_brf3ve",
    "popoverButton": "_popoverButton_brf3ve _button_brf3ve",
    "moreLabel": "_moreLabel_brf3ve",
    "moreIcon": "_moreIcon_brf3ve",
    "moreIconOpen": "_moreIconOpen_brf3ve _moreIcon_brf3ve",
    "moreIconClosed": "_moreIconClosed_brf3ve _moreIcon_brf3ve",
    "moreActiveNavItem": "_moreActiveNavItem_brf3ve"
  };
  _exports.default = _default;
});