define("mobile-web/templates/dispatch-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y5UibsrQ",
    "block": "[[[8,[39,0],null,[[\"@order\",\"@hash\",\"@status\"],[[30,0,[\"model\",\"order\"]],[30,0,[\"hash\"]],[33,1,[\"dispatchStatus\"]]]],null]],[],false,[\"routes/dispatch-status-route\",\"model\"]]",
    "moduleName": "mobile-web/templates/dispatch-status.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});