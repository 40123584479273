define("mobile-web/components/r-checkout/payment/membership/component", ["exports", "@glimmer/component", "mobile-web/lib/payment", "mobile-web/lib/payment/card", "mobile-web/components/r-checkout/payment/style"], function (_exports, _component, _payment, _card, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CheckoutPaymentMembership extends _component.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get useLabel() {
      var _this$args$useLabel;

      return (_this$args$useLabel = this.args.useLabel) != null ? _this$args$useLabel : 'mwc.checkout.useMembershipButton';
    }

    get icon() {
      switch (this.args.membership.cardType) {
        case _card.Type.VISA:
          return 'r-visa';

        case _card.Type.AMEX:
          return 'r-amex';

        case _card.Type.Discover:
          return 'r-discover';

        case _card.Type.Mastercard:
          return 'r-mastercard';

        default:
          return this.args.membership.billingScheme.provider === _payment.Provider.CreditCard ? 'r-credit-card' : 'r-gift-card';
      }
    } // Constructor
    // Other methods
    // Tasks
    // Actions


  }

  _exports.default = CheckoutPaymentMembership;
});