define("mobile-web/adapters/option-group", ["exports", "ember-data-url-templates", "mobile-web/lib/utilities/_", "mobile-web/lib/hybrid-util", "mobile-web/adapters/application"], function (_exports, _emberDataUrlTemplates, _, _hybridUtil, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OptionGroupAdapter = (_dec = Ember.inject.service, (_class = class OptionGroupAdapter extends _application.default.extend(_emberDataUrlTemplates.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "vendor", _descriptor, this);

      this.coalesceFindRequests = true;
      this.urlTemplate = (0, _hybridUtil.host)() + '/{+namespace}/vendors/{vendorId}/optiongroups/{optionGroupId}';
      this.urlSegments = {
        vendorId(_type, _id, snapshot) {
          var _this$vendor$vendor;

          const record = (0, _.isArray)(snapshot) ? snapshot[0] : snapshot;
          /**
           * These types are technically wrong, but they are wrong from the DefinitelyTyped repo.
           * The snapshot belongsTo method actually returns a DS.Snapshot, but is typed as returning
           * a DS.Model. Since all we need this for is to get the productVendor.id, the wrong types
           * happen to work and it is cleaner than a bunch of weird casts.
           */

          const product = record.belongsTo('product');
          const productVendor = product == null ? void 0 : product.belongsTo('vendor');
          const productVendorId = productVendor == null ? void 0 : productVendor.id;
          const vendorIdFromService = (_this$vendor$vendor = this.vendor.vendor) == null ? void 0 : _this$vendor$vendor.id;

          if (!productVendorId && !vendorIdFromService) {
            throw new Error('Vendor Id for Option Group Not Found');
          }

          return productVendorId != null ? productVendorId : vendorIdFromService;
        },

        optionGroupId(_type, _id, snapshot) {
          return snapshot.id;
        },

        namespace() {
          return this.namespace;
        }

      };
    }

    findMany(store, _type, ids, snapshots) {
      this.findManyIds = ids.map(id => id.toString());
      return super.findMany(store, _type, ids, snapshots);
    }
    /**
     * There is a single option group endpoint in the back end, but it is well and truly
     * borked. Seriously, don't go there. Evil awaits. So, we need to make sure that
     * the findRecord ends up calling the findMany endpoint, because they both have the
     * same return value and the findMany endpoint can accept a single id _and_ isn't,
     * y'know, borked.
     */


    findRecord(store, type, id, snapshot) {
      return this.findMany(store, type, [id], [snapshot]);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "vendor", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OptionGroupAdapter;
});