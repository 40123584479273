define("mobile-web/lib/utilities/device-detect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.IS_IE = _exports.IS_OLD_EDGE = void 0;
  // https://docs.microsoft.com/en-us/microsoft-edge/web-platform/user-agent-string
  const IS_OLD_EDGE = /Edge\//.test(navigator.userAgent); // https://www.whatismybrowser.com/guides/the-latest-user-agent/internet-explorer

  _exports.IS_OLD_EDGE = IS_OLD_EDGE;
  const IS_IE = /Trident\//.test(navigator.userAgent);
  _exports.IS_IE = IS_IE;
});