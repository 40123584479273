define("mobile-web/templates/secure/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DCQWeewt",
    "block": "[[[10,0],[14,5,\"padding: 16px\"],[12],[1,\"\\n\"],[41,[30,0,[\"channel\",\"settings\",\"supportsOloLogin\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@headingLevel\",\"@mode\",\"@onModeChange\",\"@onSignIn\",\"@guestUser\",\"@isUpgrade\",\"@lastOrderId\",\"@onCreateAccount\",\"@onCreateAccountError\",\"@optIn\"],[1,[30,0,[\"signInCreateAccountMode\"]],[30,0,[\"switchModes\"]],[30,0,[\"login\"]],[30,1,[\"guestUser\"]],[30,0,[\"upgrade\"]],[30,1,[\"lastOrderId\"]],[30,0,[\"afterCreateAccount\"]],[30,0,[\"afterCreateAccountError\"]],[30,0,[\"channel\",\"currentCountrySettings\",\"optIn\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[8,[39,2],null,[[\"@externalLogin\",\"@loginProviders\"],[[30,0,[\"externalLogin\"]],[30,0,[\"channel\",\"current\",\"loginProviders\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@model\"],false,[\"if\",\"r-sign-in-create-account-form\",\"r-external-login-providers\"]]",
    "moduleName": "mobile-web/templates/secure/login.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});