define("mobile-web/services/global-events", ["exports", "mobile-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.GlobalEventName = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let GlobalEventName;
  _exports.GlobalEventName = GlobalEventName;

  (function (GlobalEventName) {
    GlobalEventName["AddToCart"] = "v1.addToCart";
    GlobalEventName["RemoveFromCart"] = "v1.removeFromCart";
    GlobalEventName["Checkout"] = "v1.checkout";
    GlobalEventName["Transaction"] = "v1.transaction";
    GlobalEventName["ClickProductLink"] = "v1.clickProductLink";
    GlobalEventName["ViewProductDetail"] = "v1.viewProductDetail";
    GlobalEventName["ProductsVisible"] = "v1.productsVisible";
    GlobalEventName["AppFirstOpen"] = "v1.appFirstOpen";
    GlobalEventName["LocationAccessAllowed"] = "v1.locationAccessAllowed";
    GlobalEventName["LocationAccessDenied"] = "v1.locationAccessDenied";
    GlobalEventName["UserLogin"] = "v1.userLogin";
    GlobalEventName["CreateBasket"] = "v1.createBasket";
  })(GlobalEventName || (_exports.GlobalEventName = GlobalEventName = {}));

  const GlobalEventNames = Object.values(GlobalEventName);
  const Events = Ember.Object.extend(Ember.Evented);
  let GlobalEventsService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class GlobalEventsService extends Ember.Service {
    /**
     * Set the `Olo.on` method globally.
     */
    get globalOn() {
      return window.Olo.on;
    }

    set globalOn(on) {
      window.Olo = window.Olo || {};
      window.Olo.on = on;
    }
    /**
     * Set the `Olo.off` method globally.
     */


    get globalOff() {
      return window.Olo.off;
    }

    set globalOff(off) {
      window.Olo = window.Olo || {};
      window.Olo.off = off;
    }
    /**
     * Instance of an event bus.
     */


    /**
     * Create an instance of the events mixin.
     * "Favor object composition over class inheritance." - GoF
     */
    // eslint-disable-next-line @typescript-eslint/ban-types
    constructor(args) {
      super(args);

      _initializerDefineProperty(this, "error", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      this.callbacks = new WeakMap();
      this.triggerHistory = new Map();
      this.initialized = false;
      this.events = Events.create();
    }
    /**
     * Add listeners globally.
     */


    setup() {
      if (this.initialized) {
        return;
      }

      this.initialized = true;
      this.globalOn = this.handleOn.bind(this);
      this.globalOff = this.handleOff.bind(this);
    }
    /**
     * Proxy events to `on`.
     * If `replay` is `true`, re-run all previous triggers of that event.
     * Private because no other parts of the system should listen to events this way.
     */


    on(name, method, {
      replay
    } = {
      replay: true
    }) {
      this.events.on(name, method);

      if (replay) {
        (this.triggerHistory.get(name) || []).forEach(args => method(...args));
      }

      return this;
    }
    /**
     * Proxy events to `off`.
     * Private because no other parts of the system should listen to events this way.
     */


    off(name, method) {
      this.events.off(name, method);
      return this;
    }
    /**
     * Has a listener of a certain name been set?
     * This is used for things like deferring the transition to checkout if there is a checkout listener in place
     * so that analytics have enough time to run.
     */


    has(name) {
      return this.events.has(name);
    }
    /**
     * Track events emitted via trigger so that we can replay the to new subscribers.
     * This is required because otherwise events emitted when the page is first loading may fire
     * before any third-party scripts that listen for them are initialized.
     */


    trigger(name, ...args) {
      if (_environment.default.environment !== 'production') {
        // eslint-disable-next-line no-console
        console.log('Triggering Serve global event', name, ...args);
      }

      this.events.trigger(name, ...args);
      this.triggerHistory.set(name, (this.triggerHistory.get(name) || []).concat([args]));
    }

    handleOn(name, cb, opts) {
      this.checkEventName(name);
      this.on(name, this.getSafelyBoundCallback(cb), opts);
    }

    handleOff(name, cb) {
      this.checkEventName(name);
      this.off(name, this.getSafelyBoundCallback(cb));
    }

    checkEventName(name) {
      if (!GlobalEventNames.includes(name)) {
        throw new Error(`The event ${name} is not supported by Olo.on.`);
      }
    }
    /**
     * Create a new callback function that will execute with a `this` that is `undefined`
     * to prevent the ability to accidentally access some part of the app scope.
     * Use `setTimeout` to wait a frame to run so that we aren't blocking any rendering
     * with the callback. Wrap the whole thing in a `try/catch` in case whatever is registered
     * blows up. If we've already made a callback from the given function, just return that
     * instead.
     */


    getSafelyBoundCallback(cb) {
      const boundCb = this.callbacks.get(cb) || ((...args) => {
        setTimeout(() => {
          try {
            cb.apply(undefined, args); // eslint-disable-line prefer-spread
          } catch (e) {
            this.error.sendExternalError(e, {
              isGlobalEventCallback: true
            });
          }
        });
      });

      this.callbacks.set(cb, boundCb);
      return boundCb;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "error", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GlobalEventsService;
});