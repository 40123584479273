define("mobile-web/components/r-post-checkout/component", ["exports", "@ember-decorators/component", "mobile-web/components/r-sign-in-create-account-form/component", "mobile-web/lib/dayjs", "mobile-web/lib/order", "mobile-web/lib/order-criteria", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-post-checkout/style"], function (_exports, _component, _component2, _dayjs, _order, _orderCriteria, _, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.UserOrderEvent = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UserOrderEvent;
  _exports.UserOrderEvent = UserOrderEvent;

  (function (UserOrderEvent) {
    UserOrderEvent["CheckIn"] = "checkin";
    UserOrderEvent["Arrival"] = "arrival";
  })(UserOrderEvent || (_exports.UserOrderEvent = UserOrderEvent = {}));

  let PostCheckout = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.computed.bool('session.isLoggedIn'), _dec10 = Ember.computed.bool('session.signOnAllowed'), _dec11 = Ember.computed.bool('session.isRestrictedFlow'), _dec12 = Ember.computed.bool('channel.settings.allowUserFeedback'), _dec13 = Ember.computed.alias('order.vendor'), _dec14 = Ember.computed('order.feedbackUrl', 'channel.settings.genericFeedbackUrl'), _dec15 = Ember.computed.alias('order.deliveryMode'), _dec16 = Ember.computed.bool('order.isDelivery'), _dec17 = Ember.computed('handoffMode'), _dec18 = Ember.computed.bool('order.isAdvance'), _dec19 = Ember.computed('order.{canManualFire,canSupportArrival}'), _dec20 = Ember.computed('channel.settings.mobileSecureUrl', 'isPendingManualFire', 'order.{orderGuid,orderHash}'), _dec21 = Ember.computed('isDelivery'), _dec22 = Ember.computed('allowUserFeedback', 'feedbackUrl'), _dec23 = Ember.computed('isLoggedIn', 'isRestrictedFlow', 'onCancelOrder', 'allowModify', 'order.{canCancel,canModify}'), _dec24 = Ember.computed('isLoggedIn', 'signOnAllowed'), _dec25 = Ember.computed.bool('order.isPastOrder'), _dec26 = Ember.computed('mwcIntl.intl', 'order.timeReadyLocal'), _dec27 = Ember.computed('allowReorder', 'features.flags', 'order.canReorder'), _dec28 = Ember.computed('showTracking', 'isDispatch'), _dec29 = Ember.computed('isDelivery', 'isPastOrder', 'isPendingManualFire', 'order.canSupportArrival', 'showDispatchingTracking'), _dec30 = Ember.computed('showReorder', 'showCurrentOrderButtons'), _dec31 = Ember._action, _dec32 = Ember._action, _dec33 = Ember._action, _dec(_class = (_class2 = class PostCheckout extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      _initializerDefineProperty(this, "channel", _descriptor2, this);

      _initializerDefineProperty(this, "mwcIntl", _descriptor3, this);

      _initializerDefineProperty(this, "reorder", _descriptor4, this);

      _initializerDefineProperty(this, "router", _descriptor5, this);

      _initializerDefineProperty(this, "session", _descriptor6, this);

      _initializerDefineProperty(this, "features", _descriptor7, this);

      this.showTracking = true;
      this.allowModify = true;
      this.socialLink = window.location.origin;
      this.style = _style.default;

      _initializerDefineProperty(this, "isLoggedIn", _descriptor8, this);

      _initializerDefineProperty(this, "signOnAllowed", _descriptor9, this);

      _initializerDefineProperty(this, "isRestrictedFlow", _descriptor10, this);

      _initializerDefineProperty(this, "allowUserFeedback", _descriptor11, this);

      _initializerDefineProperty(this, "vendor", _descriptor12, this);

      _initializerDefineProperty(this, "handoffMode", _descriptor13, this);

      _initializerDefineProperty(this, "isDelivery", _descriptor14, this);

      _initializerDefineProperty(this, "isAdvance", _descriptor15, this);

      _initializerDefineProperty(this, "isPastOrder", _descriptor16, this);
    }

    get feedbackUrl() {
      var _this$channel$setting;

      const orderSpecificFeedbackUrl = this.order.feedbackUrl;
      const genericFeedbackUrl = (_this$channel$setting = this.channel.settings) == null ? void 0 : _this$channel$setting.genericFeedbackUrl;
      return orderSpecificFeedbackUrl || genericFeedbackUrl;
    }

    get isDispatch() {
      return this.handoffMode === _orderCriteria.DISPATCH_KEY;
    }

    get isPendingManualFire() {
      // Manual fire automatically triggered by arrival
      return this.order.canManualFire && !this.order.canSupportArrival;
    }

    get substitutions() {
      var _this$channel$setting2, _this$channel$setting3;

      const url = (_this$channel$setting2 = (_this$channel$setting3 = this.channel.settings) == null ? void 0 : _this$channel$setting3.mobileSecureUrl) != null ? _this$channel$setting2 : '';
      const orderGuid = this.order.orderGuid;
      const hash = encodeURIComponent(this.order.orderHash);
      return {
        ManualFireUrl: `https://${url}/order/checkin/${orderGuid}?hash=${hash}`
      };
    }

    get handoffDetailsHeading() {
      return this.isDelivery ? 'Delivery Details' : 'Pickup Details';
    }

    get showFeedbackSection() {
      return this.allowUserFeedback && !(0, _.isEmpty)(this.feedbackUrl);
    }

    get canChangeOrder() {
      return !this.isRestrictedFlow && this.isLoggedIn && (this.order.canCancel && (0, _.isFunction)(this.onCancelOrder) || this.allowModify && this.order.canModify);
    }

    get showCreateAccountButton() {
      return !this.isLoggedIn && this.signOnAllowed;
    }

    get handoffMessageParts() {
      const timeReady = (0, _dayjs.default)(this.order.timeReadyLocal);
      const relativeDate = this.mwcIntl.checkoutHandoffRelativeDate(timeReady);
      const dateTime = this.mwcIntl.intl.t('mwc.postCheckout.timeWanted', {
        date: relativeDate,
        time: timeReady
      });
      return (0, _order.handoffMessageParts)(this.order, dateTime);
    }

    get showReorder() {
      var _this$allowReorder2;

      if (this.features.flags['favorite-order-card-updates']) {
        var _this$allowReorder;

        return (_this$allowReorder = this.allowReorder) != null ? _this$allowReorder : false;
      }

      return ((_this$allowReorder2 = this.allowReorder) != null ? _this$allowReorder2 : false) && this.order.canReorder;
    }

    get showDispatchingTracking() {
      return this.showTracking && this.isDispatch;
    }

    get showCurrentOrderButtons() {
      return !this.isPastOrder && (this.showDispatchingTracking || !this.isDelivery || this.isPendingManualFire || this.order.canSupportArrival);
    }

    get showButtonContainer() {
      return this.showReorder || this.showCurrentOrderButtons;
    } // Init


    init() {
      super.init();
      (false && !((0, _isSome.isSome)(this.order)) && Ember.assert('`order` is required', (0, _isSome.isSome)(this.order)));
      (false && !(!this.isPendingManualFire || !this.order.canSupportArrival || (0, _isSome.isSome)(this.onUserOrderEvent)) && Ember.assert('`onUserOrderEvent` is required if is manual fire or arrival notifications', !this.isPendingManualFire || !this.order.canSupportArrival || (0, _isSome.isSome)(this.onUserOrderEvent)));
    } // Other methods
    // Actions


    login() {
      this.router.transitionTo('secure.login');
    }

    upgradeAccount() {
      this.router.transitionTo('secure.login', {
        queryParams: {
          mode: _component2.Mode.CreateAccount,
          upgrade: true
        }
      });
    }

    doReorder() {
      this.reorder.reorder(this.order);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "basket", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "channel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "mwcIntl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "reorder", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "features", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "isLoggedIn", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "signOnAllowed", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "isRestrictedFlow", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "allowUserFeedback", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "vendor", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "feedbackUrl", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "feedbackUrl"), _class2.prototype), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "handoffMode", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class2.prototype, "isDelivery", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "isDispatch", [_dec17], Object.getOwnPropertyDescriptor(_class2.prototype, "isDispatch"), _class2.prototype), _descriptor15 = _applyDecoratedDescriptor(_class2.prototype, "isAdvance", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "isPendingManualFire", [_dec19], Object.getOwnPropertyDescriptor(_class2.prototype, "isPendingManualFire"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "substitutions", [_dec20], Object.getOwnPropertyDescriptor(_class2.prototype, "substitutions"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handoffDetailsHeading", [_dec21], Object.getOwnPropertyDescriptor(_class2.prototype, "handoffDetailsHeading"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "showFeedbackSection", [_dec22], Object.getOwnPropertyDescriptor(_class2.prototype, "showFeedbackSection"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "canChangeOrder", [_dec23], Object.getOwnPropertyDescriptor(_class2.prototype, "canChangeOrder"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "showCreateAccountButton", [_dec24], Object.getOwnPropertyDescriptor(_class2.prototype, "showCreateAccountButton"), _class2.prototype), _descriptor16 = _applyDecoratedDescriptor(_class2.prototype, "isPastOrder", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "handoffMessageParts", [_dec26], Object.getOwnPropertyDescriptor(_class2.prototype, "handoffMessageParts"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "showReorder", [_dec27], Object.getOwnPropertyDescriptor(_class2.prototype, "showReorder"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "showDispatchingTracking", [_dec28], Object.getOwnPropertyDescriptor(_class2.prototype, "showDispatchingTracking"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "showCurrentOrderButtons", [_dec29], Object.getOwnPropertyDescriptor(_class2.prototype, "showCurrentOrderButtons"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "showButtonContainer", [_dec30], Object.getOwnPropertyDescriptor(_class2.prototype, "showButtonContainer"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "login", [_dec31], Object.getOwnPropertyDescriptor(_class2.prototype, "login"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "upgradeAccount", [_dec32], Object.getOwnPropertyDescriptor(_class2.prototype, "upgradeAccount"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "doReorder", [_dec33], Object.getOwnPropertyDescriptor(_class2.prototype, "doReorder"), _class2.prototype)), _class2)) || _class);
  _exports.default = PostCheckout;
});