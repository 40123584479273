define("mobile-web/routes/additional-info", ["exports", "mobile-web/lib/utilities/_"], function (_exports, _) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AdditionalInfoRoute extends Ember.Route {
    model(params) {
      const firstMatch = link => params.linkTitle === Ember.String.dasherize(link.linkLabelText);

      return this.store.findAll('custom-link').then(links => links.toArray()).then(links => links.find(firstMatch)).then(matchingLink => {
        if (!matchingLink || !(0, _.isEmpty)(matchingLink.url)) {
          this.transitionTo('application'); // This technically returns the wrong thing, but because the model
          // will never resolve (courtesy of the `transitionTo` immediately
          // above), it comes out correct; the semantics here are "abort" rather
          // than proper return.
          // eslint-disable-next-line @typescript-eslint/no-explicit-any

          return undefined;
        }

        return matchingLink;
      });
    }

    serialize(model) {
      return {
        linkTitle: Ember.String.dasherize(model.linkLabelText)
      };
    }

  }

  _exports.default = AdditionalInfoRoute;
});