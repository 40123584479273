define("mobile-web/components/r-header/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_5a34vi",
    "left": "_left_5a34vi",
    "navigationButton": "_navigationButton_5a34vi",
    "home": "_home_5a34vi",
    "homeLoading": "_homeLoading_5a34vi",
    "center": "_center_5a34vi",
    "logo": "_logo_5a34vi _center_5a34vi",
    "logoLoading": "_logoLoading_5a34vi",
    "title": "_title_5a34vi _center_5a34vi",
    "right": "_right_5a34vi",
    "accountLoading": "_accountLoading_5a34vi",
    "cartLoading": "_cartLoading_5a34vi",
    "accountButton": "_accountButton_5a34vi",
    "basket": "_basket_5a34vi",
    "notifications": "_notifications_5a34vi",
    "background": "_background_5a34vi"
  };
  _exports.default = _default;
});