define("mobile-web/lib/routing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.vendorToMenuRoute = _exports.currentTransitionArgs = _exports.homeRoute = void 0;
  const homeRoute = {
    label: 'Home',
    route: 'index',
    models: []
  };
  /**
   * Returns an array with the following format:
   * `['name of current route', 'dynamic segment 1', 'dynamic segment 2', . . ., { queryParams }]`.
   * The array always has at least one element.
   */

  _exports.homeRoute = homeRoute;

  const currentTransitionArgs = currentRoute => {
    if (!currentRoute) {
      return ['index'];
    }

    const transitionArgs = [currentRoute.name];

    if (!Ember.isEmpty(Object.keys(currentRoute.params))) {
      transitionArgs.push(...Object.values(currentRoute.params));
    }

    if (!Ember.isEmpty(Object.keys(currentRoute.queryParams))) {
      transitionArgs.push({
        queryParams: currentRoute.queryParams
      });
    }

    return transitionArgs;
  };

  _exports.currentTransitionArgs = currentTransitionArgs;

  const vendorToMenuRoute = vendor => {
    const label = 'Menu';
    const route = 'menu.vendor';
    return {
      label,
      route,
      models: [Ember.get(vendor, 'slug')]
    };
  };

  _exports.vendorToMenuRoute = vendorToMenuRoute;
});