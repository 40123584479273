define("mobile-web/components/r-app-store-badges/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RZ97mItM",
    "block": "[[[41,[30,0,[\"show\"]],[[[1,\"  \"],[11,0],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"showAndroidBadge\"]],[[[1,\"      \"],[8,[39,1],null,[[\"@appIdentifier\",\"@type\"],[[30,0,[\"androidAppIdentifier\"]],\"android\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showiOSBadge\"]],[[[1,\"      \"],[8,[39,1],null,[[\"@appIdentifier\",\"@type\"],[[30,0,[\"iOSAppIdentifier\"]],\"ios\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"&attrs\"],false,[\"if\",\"r-app-store-badges/badge\"]]",
    "moduleName": "mobile-web/components/r-app-store-badges/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});