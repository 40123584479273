define("mobile-web/components/r-checkout/additional-details/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "field": "_field_1a1km3"
  };
  _exports.default = _default;
});