define("mobile-web/components/r-header-home/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jujG4g7L",
    "block": "[[[41,[30,0,[\"channel\",\"settings\",\"parentSiteUrl\"]],[[[1,\"  \"],[10,0],[12],[1,\"\\n    \"],[8,[39,1],[[24,\"data-test-headerHome\",\"\"]],[[\"@label\",\"@showLabel\",\"@onClick\",\"@icon\",\"@testSelector\"],[[28,[37,2],null,[[\"text\"],[[28,[37,3],[\"mwc.header.homeAltText\"],null]]]],false,[30,0,[\"onClick\"]],[28,[37,2],null,[[\"svg\",\"ariaLabel\",\"position\"],[\"home\",\"\",\"left\"]]],\"home\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"r-header-button\",\"hash\",\"t\"]]",
    "moduleName": "mobile-web/components/r-header-home/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});