define("mobile-web/components/r-credit-card-elements/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "cardElementContainer": "_cardElementContainer_323s4l",
    "inputRow": "_inputRow_323s4l",
    "errorIcon": "_errorIcon_323s4l",
    "errorMessage": "_errorMessage_323s4l"
  };
  _exports.default = _default;
});