define("mobile-web/components/r-sign-in-form/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "row": "_row_1mv0qm",
    "submitButton": "_submitButton_1mv0qm",
    "forgotPasswordRow": "_forgotPasswordRow_1mv0qm",
    "forgotPasswordLabel": "_forgotPasswordLabel_1mv0qm",
    "heading": "_heading_1mv0qm"
  };
  _exports.default = _default;
});