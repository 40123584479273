define("mobile-web/components/routes/reset-password-route/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_1jiyp8",
    "instructions": "_instructions_1jiyp8",
    "field": "_field_1jiyp8",
    "button": "_button_1jiyp8"
  };
  _exports.default = _default;
});