define("mobile-web/components/r-product-customization/requirement/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "mobile-web/components/r-product-customization/requirement/style"], function (_exports, _glimmerComponent, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ProductCustomizationRequirement extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get icon() {
      return this.args.isCompleted ? 'r-check' : 'r-exclamation';
    }

    get rootStyle() {
      return this.args.isCompleted ? this.style.completed : this.style.required;
    }

    get testState() {
      return this.args.isCompleted ? 'completed' : 'required';
    } // Constructor
    // Other methods
    // Tasks
    // Actions


  }

  _exports.default = ProductCustomizationRequirement;
});