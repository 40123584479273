define("mobile-web/lib/mirage-util", ["exports", "mobile-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.useMirage = useMirage;

  function useMirage() {
    return _environment.default.completeMock || window.location.host.includes('localhost') || window.location.host.includes('teamcity.olobuild.net');
  }
});