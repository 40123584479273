define("mobile-web/lib/utilities/tracking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.doNotTrackSet = void 0;

  const doNotTrackSet = () => window.doNotTrack === '1' || navigator.doNotTrack === 'yes' || navigator.doNotTrack === '1' || navigator.msDoNotTrack === '1' || 'external' in window && 'msTrackingProtectionEnabled' in window.external && window.external.msTrackingProtectionEnabled(); // eslint-disable-line


  _exports.doNotTrackSet = doNotTrackSet;
});