define("mobile-web/adapters/region", ["exports", "mobile-web/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RegionAdapter extends _application.default {
    pathForType() {
      return 'vendors/regions?excludeCities=true';
    }

  }

  _exports.default = RegionAdapter;
});