define("mobile-web/components/r-basket-transfer-modal/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "hide": "_hide_qx4tzv",
    "form": "_form_qx4tzv",
    "input": "_input_qx4tzv",
    "noResults": "_noResults_qx4tzv",
    "grid": "_grid_qx4tzv"
  };
  _exports.default = _default;
});