define("mobile-web/services/error", ["exports", "ember-data", "true-myth/maybe", "mobile-web/config/environment", "mobile-web/lib/errors", "mobile-web/lib/perimeterx", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/http", "mobile-web/lib/utilities/is-some", "mobile-web/services/analytics", "mobile-web/services/user-feedback"], function (_exports, _emberData, _maybe, _environment, _errors, _perimeterx, _, Http, _isSome, _analytics, _userFeedback) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.extractErrorDetail = _exports.FORBIDDEN_RESPONSE_TEXT = _exports.NO_BASKET_ERR = _exports.MOBO_ERR = _exports.DEFAULT_TITLE = _exports.errorForUser = _exports.Errors = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Errors; // TODO: This is a *weak* error type; we should check all uses and make sure
  // they do as we think they should so we can upgrade this to actually be a
  // more useful type.

  _exports.Errors = Errors;

  (function (Errors) {
    Errors["ForUser"] = "ErrorForUser";
  })(Errors || (_exports.Errors = Errors = {}));

  const hasErrorMessage = err => !Ember.isNone(err) && (0, _.has)(err, 'errors') && (0, _.isArray)(err.errors) && err.errors.length > 0 && (0, _isSome.default)(err.errors[0].message);

  const errorForUser = error => (0, _.has)(error, 'errors') && Array.isArray(error.errors) && error.errors.some(err => (err == null ? void 0 : err.title) === Errors.ForUser);

  _exports.errorForUser = errorForUser;

  const getErrObj = err => Ember.isNone(err) || typeof err === 'string' ? undefined : 'errors' in err ? err.errors[0] : err;

  function isOrderCriteriaErrorCategory(category) {
    return (0, _isSome.default)(category) && [_errors.ErrorCategory.HandoffRequirementsNotMet, _errors.ErrorCategory.TooFarInAdvance, _errors.ErrorCategory.VendorOutOfHours, _errors.ErrorCategory.WantedTimeNotSpecified, _errors.ErrorCategory.TimeWantedModeNotSpecified].includes(category);
  } // See MWC-2237


  const DEFAULT_TITLE = 'Something Is Wrong';
  _exports.DEFAULT_TITLE = DEFAULT_TITLE;
  const MOBO_ERR = 'Sorry, something went wrong with your request. Please try again in a few minutes.';
  _exports.MOBO_ERR = MOBO_ERR;
  const NO_BASKET_ERR = 'You must have an item in your basket before you can check out.';
  _exports.NO_BASKET_ERR = NO_BASKET_ERR;
  const UNAUTHORIZED_ERR = 'You must be logged in to do that.';
  const FORBIDDEN_RESPONSE_TEXT = 'Sorry, we ran into an issue. Try reloading this page – that should do the trick!';
  _exports.FORBIDDEN_RESPONSE_TEXT = FORBIDDEN_RESPONSE_TEXT;

  const getErrorStatus = error => {
    var _error$errors$;

    return Ember.isNone(error) || typeof error === 'string' ? undefined : 'status' in error ? error.status : 'errors' in error ? (_error$errors$ = error.errors[0]) == null ? void 0 : _error$errors$.status : undefined;
  };

  const isStatus = (status, error) => {
    var _getErrorStatus;

    return Http.isStatus(status, (_getErrorStatus = getErrorStatus(error)) != null ? _getErrorStatus : '');
  };

  const isServerError = error => {
    const errorStatus = getErrorStatus(error); // get status of the health check endpoint on errors above 500

    return errorStatus ? errorStatus >= Http.Status.InternalServerError : false;
  };
  /** Check error format and extract the human readable message */


  const extractErrorDetail = err => hasErrorMessage(err) ? _maybe.default.just(err.errors[0].message) : typeof err === 'string' ? _maybe.default.just(err) : 'detail' in err ? _maybe.default.just(err.detail) : _maybe.default.nothing();

  _exports.extractErrorDetail = extractErrorDetail;
  let ErrorService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, (_class = class ErrorService extends Ember.Service {
    get basketId() {
      try {
        var _this$basket$basket;

        return String((_this$basket$basket = this.basket.basket) == null ? void 0 : _this$basket$basket.id);
      } catch (_unused) {
        // If we're already handling an error, the app might not be in a state
        // where it's possible to look up the basket. Silently fail in this case.
        return undefined;
      }
    }
    /**
     * This method is the primary error reporting method.
     * It will cause a debug point in development, log the error out to the console,
     * send any relevant user messaging, redirect to login page if needed,
     * and send events to mixpanel and raygun. This should be the go-to method
     * unless you have a solid reason to use the other methods.
     */


    /**
     * This method sends an error directly to Raygun. It does not cause any other
     * side effects within the system.
     */
    sendExternalError(error, extraProperties, tags = []) {
      if (this.isIgnoredError(error)) {
        return;
      }

      rg4js('send', {
        error,
        customData: _extends({}, extraProperties || {}, {
          basketId: this.basketId,
          flags: this.features.flags
        }),
        tags: tags.concat(this.getErrorTag(error))
      });
    }
    /**
     * Send a message to the user. If the error has an associated `detail` field,
     * use it as the message to the user; otherwise, default to `moboErr`.
     *
     * This method will send an event to Mixpanel, but will not log to raygun.
     * It will not give any additional debugging information.
     */


    sendUserMessage(err, defaultMsg) {
      var _getErrObj;

      const message = defaultMsg || extractErrorDetail(err).unwrapOr(MOBO_ERR);
      this.trackError(message, (_getErrObj = getErrObj(err)) == null ? void 0 : _getErrObj.category);
      this.userFeedback.add({
        type: _userFeedback.Type.Negative,
        title: DEFAULT_TITLE,
        message,
        actions: []
      });
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "orderCriteria", _descriptor2, this);

      _initializerDefineProperty(this, "userFeedback", _descriptor3, this);

      _initializerDefineProperty(this, "basket", _descriptor4, this);

      _initializerDefineProperty(this, "features", _descriptor5, this);

      _initializerDefineProperty(this, "router", _descriptor6, this);

      _initializerDefineProperty(this, "challenge", _descriptor7, this);

      _initializerDefineProperty(this, "healthCheck", _descriptor8, this);

      this.reportError = (0, _errors.buildOloErrorHandler)('Olo.reportError', error => {
        if (_environment.default.APP.PAUSE_ON_ERRS) {
          debugger; // eslint-disable-line no-debugger
        }

        if (_environment.default.APP.LOG_ALL_ERRS) {
          try {
            console.error(error); // eslint-disable-line no-console
          } catch (e) {// ignore errors from logging
          }
        }

        this.handleError(error);
      });
      Ember.onerror = (0, _errors.buildOloErrorHandler)('Ember.onerror', error => {
        this.reportError(error);
      });
    }

    willDestroy() {
      // @ts-ignore -- this is required by Ember's error handling checking
      Ember.onerror = undefined;
    }

    async handleError(error) {
      try {
        // empty promise rejections (exposes unhandled promises)
        if (!error) {
          this.appError(MOBO_ERR);
          this.sendExternalError(error);
          return;
        } // errors for the user


        if (errorForUser(error)) {
          this.appError(error);
          return;
        } // user is forbidden


        if (isStatus(Http.Status.Forbidden, error)) {
          if (!this.challenge.isOpen) {
            const response = (0, _perimeterx.asPerimeterXResponse)(error);

            if (response) {
              // Last catch-all if we missed a challenge lower in the stack
              this.challenge.open(response);
            } else {
              // We don't want users to see both a challenge and an error banner
              this.sendUserMessage(undefined, FORBIDDEN_RESPONSE_TEXT);
            }
          }

          return;
        } // user is unauthorized


        if (isStatus(Http.Status.Unauthorized, error)) {
          this.sendUserMessage(error, UNAUTHORIZED_ERR);
          this.router.transitionTo('secure.login');
          return;
        } // server errors


        if (isServerError(error)) {
          const systemIsHealthy = await this.healthCheck.checkSystemHealth();

          if (!systemIsHealthy) {
            this.router.transitionTo('outage');
          } else {
            this.appError(MOBO_ERR);
          }

          this.sendExternalError(error);
          return;
        } // JS errors that we don't want to expose to the user


        this.appError(MOBO_ERR);
        this.sendExternalError(error);
      } catch (err) {
        // The error service itself errored; make one last attempt to report the
        // error to Raygun. Don't attempt to do anything else because at this
        // point we can't trust that anything else in the error service is working
        // correctly.
        this.sendExternalError(err, undefined, ['error-service']);
      }
    }
    /**
     * Communicates the given error to the user either through an error
     * banner or via the order criteria modal.
     */


    appError(err) {
      const errObj = getErrObj(err);

      if ((0, _isSome.default)(errObj) && isOrderCriteriaErrorCategory(errObj.category)) {
        this.trackError(errObj.message, errObj.category);
        this.orderCriteria.openModal({
          error: errObj.message
        });
      } else {
        this.sendUserMessage(err || MOBO_ERR);
      }
    }

    isIgnoredError(err) {
      // This happens when we get non-error API responses that aren't JSON.
      // Ember Data tries to parse as JSON, but that fails and throws an error.
      // This seems to be an intermittent back-end issue that we can't repro reliably,
      // but thankfully the error is easy to identify and ignore here.
      // Unfortunately, it relies on knowing that Ember Data sets a custom `payload`
      // property when JSON.parse fails:
      // https://github.com/emberjs/data/blob/v3.25.0/packages/adapter/addon/-private/utils/determine-body-promise.ts#L50
      const isEmberDataParseNoise = err.name === 'SyntaxError' && !!err.payload; // We ignore these Ember Data statuses because they almost always generate
      // unique errors with only one instance, which is unhelpful and noisy.

      const isEmberDataStatusNoise = [// E.g. the user's session timing out
      403, // E.g. the user typoed something in the address bar
      404].some(s => new RegExp(`Ember Data Request .* returned a ${s}`).test(err.message)); // We ignore TransitionAborted "error" Promises and the extremely troll-ish
      // `DS.AbortError` because both of them indicate *normal* behavior of the
      // program. Both occur when the user triggers navigation actions; neither is
      // an error that Ember.js *should* be propagating to this level. But they
      // are, so we need to ignore them.

      const isTransitionAbortedError = err.name === 'TransitionAborted' || err instanceof _emberData.default.AbortError;
      return isEmberDataParseNoise || isEmberDataStatusNoise || isTransitionAbortedError;
    }

    getErrorTag(error) {
      if (!error || typeof error === 'string' || !error.stack) {
        return 'no-stack';
      }

      if (error.stack.includes('gtm.js')) {
        return 'gtm';
      }

      if (error.stack.includes('assets/mobile-web')) {
        return 'internal';
      }

      if (error.stack.includes('assets/vendor')) {
        return 'vendor';
      }

      return 'external';
    }

    trackError(description, type) {
      this.analytics.trackEvent(_analytics.AnalyticsEvents.ErrorShown, () => ({
        [_analytics.AnalyticsProperties.ErrorDescription]: description,
        [_analytics.AnalyticsProperties.ErrorType]: type
      }));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "orderCriteria", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "userFeedback", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "features", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "challenge", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "healthCheck", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ErrorService;
});