define("mobile-web/services/mwc-intl", ["exports", "mobile-web/lib/dayjs"], function (_exports, _dayjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MwcIntl = (_dec = Ember.inject.service, (_class = class MwcIntl extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);
    }

    now() {
      return (0, _dayjs.default)();
    }

    relativeDateTime(dateTime, opts) {
      const date = this.relativeDate(dateTime, opts);
      return this.intl.t(`mwc.calendar.timeWanted${opts != null && opts.html ? 'Html' : ''}`, {
        date,
        time: dateTime
      });
    }

    relativeDate(dateTime, opts = {
      titleCase: true
    }) {
      const dayFormat = {
        sameDay: this.intl.t('mwc.calendar.sameDay', opts),
        nextDay: this.intl.t('mwc.calendar.nextDay', opts),
        nextWeek: this.intl.t('mwc.calendar.nextWeek', opts),
        lastDay: this.intl.t('mwc.calendar.lastDay', opts),
        lastWeek: this.intl.t('mwc.calendar.lastWeek', opts),
        sameElse: this.intl.t('mwc.calendar.sameElse', opts)
      };
      return dateTime.calendar(this.now(), dayFormat);
    }

    checkoutHandoffRelativeDate(dateTime) {
      return dateTime.isSame(this.now(), 'day') ? '' : dateTime.format(this.intl.t('mwc.calendar.sameElse'));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = MwcIntl;
});