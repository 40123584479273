define("mobile-web/decorators/authenticated", ["exports", "mobile-web/lib/routing", "mobile-web/services/user-feedback"], function (_exports, _routing, _userFeedback) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(route) {
    const original = route.prototype.beforeModel;

    route.prototype.beforeModel = function (transition) {
      const session = Ember.getOwner(this).lookup('service:session');
      const userFeedback = Ember.getOwner(this).lookup('service:user-feedback');
      const intl = Ember.getOwner(this).lookup('service:intl');

      if (!session.isLoggedIn) {
        transition.abort();
        session.set('nextRoute', (0, _routing.currentTransitionArgs)(transition.to));
        userFeedback.add({
          type: _userFeedback.Type.Warning,
          title: intl.t('mwc.errors.loginRequiredTitle'),
          message: intl.t('mwc.errors.loginRequiredMessage'),
          actions: []
        });
        this.transitionTo('secure.login');
      } else if (original) {
        original.call(this, transition);
      }
    };
  }
});