define("mobile-web/models/qualifying-loyalty-reward", ["exports", "ember-data", "mobile-web/lib/utilities/is-some"], function (_exports, _emberData, _isSome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let QualifyingLoyaltyRewardModel = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = _emberData.default.attr('string'), _dec4 = _emberData.default.attr('boolean'), _dec5 = _emberData.default.attr('boolean'), _dec6 = _emberData.default.attr(), _dec7 = _emberData.default.attr('number'), _dec8 = _emberData.default.attr(), _dec9 = _emberData.default.attr('date'), _dec10 = Ember.computed('externalReference', 'basket.basket.reward.externalReference'), _dec11 = Ember.computed('membershipId'), (_class = class QualifyingLoyaltyRewardModel extends _emberData.default.Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "label", _descriptor3, this);

      _initializerDefineProperty(this, "isApplied", _descriptor4, this);

      _initializerDefineProperty(this, "isAvailable", _descriptor5, this);

      _initializerDefineProperty(this, "externalReference", _descriptor6, this);

      _initializerDefineProperty(this, "membershipId", _descriptor7, this);

      _initializerDefineProperty(this, "loyaltyRewardRule", _descriptor8, this);

      _initializerDefineProperty(this, "expiresOn", _descriptor9, this);
    }

    get isActiveReward() {
      return (0, _isSome.default)(this.basket.basket) && (0, _isSome.default)(this.basket.basket.reward) && this.externalReference === this.basket.basket.reward.externalReference;
    }

    get schemeName() {
      const membership = this.store.peekAll('loyalty-membership').find(m => m.membershipId === this.membershipId);

      if ((0, _isSome.default)(membership)) {
        return membership.schemeName;
      }

      return '';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "label", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isApplied", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isAvailable", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "externalReference", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "membershipId", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "loyaltyRewardRule", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "expiresOn", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isActiveReward", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "isActiveReward"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "schemeName", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "schemeName"), _class.prototype)), _class));
  _exports.default = QualifyingLoyaltyRewardModel;
});