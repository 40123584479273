define("mobile-web/components/r-gift-card-modal/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_sd3u36",
    "input": "_input_sd3u36"
  };
  _exports.default = _default;
});