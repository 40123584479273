define("mobile-web/routes/feedback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FeedbackRoute extends Ember.Route {
    constructor(...args) {
      super(...args);
      this.titleToken = 'Feedback';
    }

  }

  _exports.default = FeedbackRoute;
});