define("mobile-web/components/r-order-search-result-list/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_anrc6v",
    "content": "_content_anrc6v",
    "description": "_description_anrc6v"
  };
  _exports.default = _default;
});