define("mobile-web/components/r-post-checkout/r-additional-details/component", ["exports", "@ember-decorators/component", "mobile-web/lib/utilities/_", "mobile-web/components/r-post-checkout/r-additional-details/style"], function (_exports, _component, _, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PostCheckoutAdditionalDetails = (_dec = (0, _component.tagName)(''), _dec2 = Ember.computed.alias('order.billingComponents'), _dec3 = Ember.computed('paymentMethods.length'), _dec4 = Ember.computed('isLoggedIn', 'order.isFavorite', 'isRestrictedFlow', 'saveFavorite'), _dec5 = Ember.computed('order.fees', 'order.vendorTax'), _dec6 = Ember._action, _dec7 = Ember._action, _dec(_class = (_class2 = class PostCheckoutAdditionalDetails extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.class = '';
      this.isLoggedIn = false;
      this.isRestrictedFlow = false;

      _initializerDefineProperty(this, "paymentMethods", _descriptor, this);

      this.showSaveFavoriteModal = false;
      this.style = _style.default;
    }

    get onlyOnePaymentMethod() {
      return this.paymentMethods.length === 1;
    }

    get showSaveFavorite() {
      return !this.isRestrictedFlow && !this.order.isFavorite && (0, _.isFunction)(this.saveFavorite) && this.isLoggedIn;
    }

    get feesTotal() {
      const fees = this.order.fees.map(fee => {
        var _fee$amount;

        return (_fee$amount = fee.amount) != null ? _fee$amount : 0;
      });
      return fees.reduce((acc, cur) => acc + cur) + this.order.vendorTax;
    }

    openSaveFavoriteModal() {
      Ember.set(this, 'showSaveFavoriteModal', true);
    }

    closeSaveFavoriteModal() {
      Ember.set(this, 'showSaveFavoriteModal', false);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "paymentMethods", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "onlyOnePaymentMethod", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "onlyOnePaymentMethod"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "showSaveFavorite", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "showSaveFavorite"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "feesTotal", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "feesTotal"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "openSaveFavoriteModal", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "openSaveFavoriteModal"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "closeSaveFavoriteModal", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "closeSaveFavoriteModal"), _class2.prototype)), _class2)) || _class);
  _exports.default = PostCheckoutAdditionalDetails;
});