define("mobile-web/components/routes/secure/checkout/auth-route/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OP+rgBrM",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"style\",\"wrapper\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"showGuestCheckout\"]],[[[1,\"      \"],[8,[39,2],null,[[\"@onSubmit\",\"@requirePhoneNumber\",\"@optIn\",\"@guestUser\"],[[30,0,[\"saveGuestUser\"]],[30,0,[\"requirePhoneNumber\"]],[30,0,[\"optIn\"]],[30,0,[\"guestDetails\"]]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"supportsOloLogin\"]],[[[1,\"      \"],[8,[39,3],null,[[\"@headingLevel\",\"@mode\",\"@onModeChange\",\"@onSignIn\",\"@guestUser\",\"@onCreateAccount\",\"@onCreateAccountError\",\"@optIn\"],[2,[30,0,[\"currentMode\"]],[28,[37,4],[[28,[37,5],[[30,0,[\"currentMode\"]]],null]],null],[30,0,[\"login\"]],[30,0,[\"guestDetails\"]],[30,0,[\"afterCreateAccount\"]],[30,0,[\"afterCreateAccountError\"]],[30,0,[\"channel\",\"currentCountrySettings\",\"optIn\"]]]],null],[1,\"\\n    \"]],[]],null]],[]]],[41,[30,0,[\"showGuestToggle\"]],[[[1,\"      \"],[8,[39,6],null,[[\"@isGuest\",\"@onToggle\"],[[30,0,[\"showGuestCheckout\"]],[28,[37,4],[[28,[37,5],[[30,0,[\"showGuestCheckout\"]]],null]],null]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[8,[39,7],null,[[\"@externalLogin\",\"@loginProviders\"],[[30,0,[\"externalLogin\"]],[30,0,[\"loginProviders\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"r-checkout\",\"if\",\"r-guest-checkout-form\",\"r-sign-in-create-account-form\",\"fn\",\"mut\",\"r-checkout/guest-toggle\",\"r-external-login-providers\"]]",
    "moduleName": "mobile-web/components/routes/secure/checkout/auth-route/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});