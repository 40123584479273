define("mobile-web/components/r-modal-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M6C5gvYc",
    "block": "[[[10,0],[15,0,[28,[37,0],[[30,0,[\"style\",\"container\"]],[52,[28,[37,2],[[33,3]],null],[30,0,[\"style\",\"noTitle\"]]],[30,1]],null]],[14,\"data-test-modal-section\",\"root\"],[12],[1,\"\\n\"],[41,[33,3],[[[1,\"    \"],[10,\"h2\"],[14,\"data-test-modal-section\",\"title\"],[15,0,[30,0,[\"style\",\"title\"]]],[12],[1,[34,3]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,\"data-test-modal-section\",\"content\"],[15,0,[28,[37,0],[[30,0,[\"style\",\"content\"]],[30,2]],null]],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"@contentClass\",\"&default\"],false,[\"classes\",\"if\",\"not\",\"title\",\"yield\"]]",
    "moduleName": "mobile-web/components/r-modal-section/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});