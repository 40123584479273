define("mobile-web/models/billing-component", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BillingComponentModel extends _emberData.default.Model.extend({
    amount: _emberData.default.attr('number'),
    description: _emberData.default.attr('string')
  }) {}

  _exports.default = BillingComponentModel;
});