define("mobile-web/components/r-digital-wallets-button/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "separator": "_separator_1delha",
    "hide": "_hide_1delha"
  };
  _exports.default = _default;
});