define("mobile-web/components/r-payment-card-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WHJMEa+Q",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@onClose\",\"@buttons\"],[[28,[37,1],[[30,0,[\"modalTitle\"]]],null],[30,1],\"yield\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,2,[\"body\"]],[[[1,\"    \"],[18,8,null],[1,\"\\n\"]],[]],[[[41,[30,2,[\"footer\"]],[[[41,[30,0,[\"isEditing\"]],[[[1,\"      \"],[8,[39,4],[[16,0,[30,2,[\"buttonClass\"]]]],[[\"@onClick\",\"@testSelector\",\"@variant\"],[[52,[30,3],[30,3],[30,0,[\"confirmDelete\"]]],[52,[30,3],\"clearCard\",\"deleteCard\"],[52,[30,3],\"minor\",\"destructive\"]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[52,[30,3],[28,[37,1],[[28,[37,5],[[30,0,[\"intlPrefix\"]],\".clearLabel\"],null]],null],[28,[37,1],[[28,[37,5],[[30,0,[\"intlPrefix\"]],\".deleteLabel\"],null]],null]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[8,[39,4],[[16,0,[30,2,[\"buttonClass\"]]],[16,\"disabled\",[28,[37,6],[[30,4],[30,5]],null]],[16,\"form\",[30,6]],[24,4,\"submit\"],[4,[38,7],[[30,0,[\"setStableWidth\"]]],null],[4,[38,8],[[30,0,[\"onDestroy\"]]],null]],[[\"@onClick\",\"@testSelector\"],[[30,7],\"saveCard\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,5],[[[1,\"        \"],[8,[39,9],null,[[\"@class\"],[[30,0,[\"style\",\"loadingIcon\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,1],[[30,0,[\"saveLabel\"]]],null]],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[]],null]],[]]]],[2]]]]],[1,\"\\n\\n\"],[8,[39,10],null,[[\"@display\"],[[30,0,[\"deleteTask\",\"isRunning\"]]]],null],[1,\"\\n\"]],[\"@onClose\",\"modal\",\"@onClear\",\"@isSubmitDisabled\",\"@isTaskRunning\",\"@formId\",\"@onSave\",\"&default\"],false,[\"r-modal\",\"t\",\"if\",\"yield\",\"r-button\",\"concat\",\"or\",\"did-insert\",\"will-destroy\",\"r-icons/loading-spinner\",\"loading-indicator\"]]",
    "moduleName": "mobile-web/components/r-payment-card-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});