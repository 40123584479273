define("mobile-web/lib/utilities/local-storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLocalStorage = void 0;

  /**
   * Export localStorage so this file can be stubbed out to replicate an environment w/o localStorage
   */
  const getLocalStorage = () => window.localStorage;

  _exports.getLocalStorage = getLocalStorage;
});