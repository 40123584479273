define("mobile-web/components/r-expandable-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lwz8gNNQ",
    "block": "[[[11,0],[16,0,[28,[37,0],[[30,0,[\"style\",\"root\"]],[52,[30,0,[\"isCollapsed\"]],[30,0,[\"style\",\"isCollapsed\"]]]],null]],[17,1],[12],[1,\"\\n  \"],[11,0],[16,0,[30,0,[\"style\",\"content\"]]],[16,5,[30,0,[\"contentStyle\"]]],[4,[38,2],[[30,0,[\"setup\"]]],null],[12],[1,\"\\n    \"],[11,0],[16,0,[30,0,[\"style\",\"innerContent\"]]],[4,[38,3],[[30,0,[\"update\"]]],[[\"subtree\",\"characterData\"],[true,true]]],[12],[1,\"\\n      \"],[18,2,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showExpand\"]],[[[1,\"    \"],[11,\"button\"],[16,0,[30,0,[\"style\",\"showMore\"]]],[4,[38,5],[\"click\",[28,[37,6],[[28,[37,7],[[30,0,[\"isCollapsed\"]]],null],[28,[37,8],[[30,0,[\"isCollapsed\"]]],null]],null]],null],[12],[1,\"\\n      \"],[1,[28,[35,9],[\"mwc.expandableText.collapseButton\"],[[\"isCollapsed\"],[[30,0,[\"isCollapsed\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"classes\",\"if\",\"did-insert\",\"observe-mutation\",\"yield\",\"on\",\"fn\",\"mut\",\"not\",\"t\"]]",
    "moduleName": "mobile-web/components/r-expandable-text/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});