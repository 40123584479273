define("mobile-web/components/r-vendor-info-modal/component", ["exports", "@ember-decorators/component", "mobile-web/helpers/vendor-time", "mobile-web/lib/dayjs", "mobile-web/lib/location/address", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-vendor-info-modal/style"], function (_exports, _component, _vendorTime, _dayjs, _address, _, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let VendorInfoModal = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed('vendor'), _dec6 = Ember.computed('device.isIOS', 'vendor.address'), _dec(_class = (_class2 = class VendorInfoModal extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "channel", _descriptor2, this);

      _initializerDefineProperty(this, "device", _descriptor3, this);

      this.style = _style.default;
    }

    // Computed properties
    get currentDate() {
      return (0, _vendorTime.vendorTime)((0, _dayjs.default)().utc(), this.vendor).format(this.intl.t('mwc.vendorInfoModal.currentDayFormat'));
    }

    get mapsUrl() {
      return _address.default.toMapsApplicationLink(this.vendor.address, this.device.isIOS);
    } // Init


    init() {
      super.init();
      (false && !((0, _.isFunction)(this.onClose)) && Ember.assert('`onClose` is required', (0, _.isFunction)(this.onClose)));
      (false && !((0, _isSome.default)(this.vendor)) && Ember.assert('`vendor` is required', (0, _isSome.default)(this.vendor)));
    } // Other methods
    // Actions


  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "channel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "device", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "currentDate", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "currentDate"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "mapsUrl", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "mapsUrl"), _class2.prototype)), _class2)) || _class);
  _exports.default = VendorInfoModal;
});