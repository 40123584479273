define("mobile-web/templates/four-oh-four", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HmxI2fcb",
    "block": "[[[10,\"section\"],[14,5,\"padding: 16px;\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,\"Page Not Found\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Sorry, that doesn't seem to be a valid URL. Your best bet is to head\\n    \"],[8,[39,0],null,[[\"@route\"],[\"index\"]],[[\"default\"],[[[[1,\"back to the home page\"]],[]]]]],[1,\"!\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"r-link-to\"]]",
    "moduleName": "mobile-web/templates/four-oh-four.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});