define("mobile-web/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Application extends _emberData.default.RESTSerializer.extend({}) {
    normalize(modelClass, resourceHash, prop) {
      return super.normalize(modelClass, resourceHash, prop);
    }

  } // DO NOT DELETE: this is how TypeScript knows how to look up your serializers.


  _exports.default = Application;
});