define("mobile-web/models/choice", ["exports", "ember-data", "mobile-web/lib/utilities/math"], function (_exports, _emberData, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChoiceModel = (_dec = Ember.inject.service, _dec2 = _emberData.default.attr('string'), _dec3 = _emberData.default.attr('string'), _dec4 = _emberData.default.attr('number'), _dec5 = _emberData.default.attr('string'), _dec6 = _emberData.default.attr('boolean'), _dec7 = _emberData.default.attr('boolean'), _dec8 = _emberData.default.attr('boolean'), _dec9 = _emberData.default.attr('number', {
    defaultValue: 0
  }), _dec10 = _emberData.default.attr('boolean'), _dec11 = _emberData.default.attr('boolean'), _dec12 = _emberData.default.attr('array'), _dec13 = _emberData.default.attr('string'), _dec14 = _emberData.default.attr('array'), _dec15 = _emberData.default.attr('object'), _dec16 = _emberData.default.belongsTo('option-group', {
    inverse: 'choices',
    async: false
  }), _dec17 = _emberData.default.hasMany('option-group', {
    inverse: 'parentChoice'
  }), (_class = class ChoiceModel extends _emberData.default.Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "channel", _descriptor, this);

      _initializerDefineProperty(this, "name", _descriptor2, this);

      _initializerDefineProperty(this, "displayName", _descriptor3, this);

      _initializerDefineProperty(this, "priceDifference", _descriptor4, this);

      _initializerDefineProperty(this, "priceDifferenceOverrideLabel", _descriptor5, this);

      _initializerDefineProperty(this, "isDefault", _descriptor6, this);

      _initializerDefineProperty(this, "isBaseProduct", _descriptor7, this);

      _initializerDefineProperty(this, "isSelected", _descriptor8, this);

      _initializerDefineProperty(this, "quantity", _descriptor9, this);

      _initializerDefineProperty(this, "hasCustomFields", _descriptor10, this);

      _initializerDefineProperty(this, "hasInlineChoices", _descriptor11, this);

      _initializerDefineProperty(this, "customFieldValues", _descriptor12, this);

      _initializerDefineProperty(this, "calorieLabel", _descriptor13, this);

      _initializerDefineProperty(this, "labels", _descriptor14, this);

      _initializerDefineProperty(this, "displayGroup", _descriptor15, this);

      _initializerDefineProperty(this, "optionGroup", _descriptor16, this);

      _initializerDefineProperty(this, "optionGroups", _descriptor17, this);
    }

    get minQuantity() {
      var _this$optionGroup$min, _this$optionGroup, _this$optionGroup$cho, _this$optionGroup2;

      const quantity = Math.max(this.quantity, (_this$optionGroup$min = (_this$optionGroup = this.optionGroup) == null ? void 0 : _this$optionGroup.minChoiceQuantity) != null ? _this$optionGroup$min : 0, 1);
      const stepSize = (_this$optionGroup$cho = (_this$optionGroup2 = this.optionGroup) == null ? void 0 : _this$optionGroup2.choiceQuantityIncrement) != null ? _this$optionGroup$cho : 1;
      return (0, _math.roundUpToNext)(stepSize, quantity);
    }

    get defaultQuantity() {
      var _this$channel$setting, _this$optionGroup3;

      const preferredStartingValue = (_this$channel$setting = this.channel.settings) != null && _this$channel$setting.defaultChoicesToMax ? ((_this$optionGroup3 = this.optionGroup) == null ? void 0 : _this$optionGroup3.maxChoiceQuantity) || this.minQuantity : this.minQuantity;
      return this.isDefault ? preferredStartingValue : 0;
    }

    get groupedName() {
      var _this$displayGroup$gr, _this$displayGroup;

      return (_this$displayGroup$gr = (_this$displayGroup = this.displayGroup) == null ? void 0 : _this$displayGroup.groupName) != null ? _this$displayGroup$gr : this.displayName;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "displayName", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "priceDifference", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "priceDifferenceOverrideLabel", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isDefault", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isBaseProduct", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isSelected", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "quantity", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "hasCustomFields", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "hasInlineChoices", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "customFieldValues", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "calorieLabel", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "labels", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "displayGroup", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "optionGroup", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "optionGroups", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ChoiceModel;
});