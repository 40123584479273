define("mobile-web/components/r-sticky-nav/component", ["exports", "@glimmer/component", "ember-animated/motions/move", "ember-animated/motions/opacity", "mobile-web/components/r-header/component", "mobile-web/lib/animation", "mobile-web/lib/scroll", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some", "mobile-web/services/analytics", "mobile-web/components/r-sticky-nav/section/component", "mobile-web/components/r-sticky-nav/style"], function (_exports, _component, _move, _opacity, _component2, _animation, _scroll, _, _isSome, _analytics, _component3, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STICKY_NAV_ID = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function navItemSelector(id) {
    return `[data-section-nav-item="${id}"]`;
  }

  const ICON_WIDTH = 24;
  const OVERFLOW_NAV_ITEMS_ALLOWED = 4;
  const STICKY_NAV_ID = 'olo-sticky-nav';
  _exports.STICKY_NAV_ID = STICKY_NAV_ID;
  let StickyNav = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember.computed('sections.@each.isHidden'), _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, (_class = class StickyNav extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "media", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "analytics", _descriptor3, this);

      this.stickyNavId = STICKY_NAV_ID;
      this.style = _style.default;

      _initializerDefineProperty(this, "activeSection", _descriptor4, this);

      _initializerDefineProperty(this, "contentElement", _descriptor5, this);

      _initializerDefineProperty(this, "navElement", _descriptor6, this);

      _initializerDefineProperty(this, "navItemWidths", _descriptor7, this);

      _initializerDefineProperty(this, "observer", _descriptor8, this);

      _initializerDefineProperty(this, "sections", _descriptor9, this);

      _initializerDefineProperty(this, "visibleSectionIds", _descriptor10, this);

      _initializerDefineProperty(this, "isLoading", _descriptor11, this);

      _initializerDefineProperty(this, "isAutoScrolling", _descriptor12, this);
    }

    // Getters and setters
    get headerHeight() {
      var _document$getElementB, _document$getElementB2;

      return (_document$getElementB = (_document$getElementB2 = document.getElementById(_component2.HEADER_ID)) == null ? void 0 : _document$getElementB2.offsetHeight) != null ? _document$getElementB : 0;
    }

    get inlineButtonStyle() {
      // Allow four nav items to overflow/ellipsis into their containers.
      // This fills in the gaps without getting too crowded.
      if (this.visibleNavItemCount > OVERFLOW_NAV_ITEMS_ALLOWED) {
        return Ember.String.htmlSafe('overflow: unset; text-overflow: unset');
      }

      return Ember.String.htmlSafe('');
    } // We still need computed here until we have a tracked array type for this.sections
    // and isHidden is tracked.


    get nonHiddenSections() {
      return this.sections.filter(s => !s.isHidden);
    }

    get visibleNavItems() {
      // Include one more element in the nav to fill in the gap.
      const navItemsToShow = this.visibleNavItemCount + 1; // Until navItemWidths has been fully set up, we need to be rendering every section, since
      // setupNav only runs on rendered items. That's OK because the items will have opacity: 0.
      // Once we know all the widths, we can filter down to just the items that should be visible;
      // at that point, opacity will also no longer be 0.

      return this.navItemWidths.length < this.sections.length ? this.sections : this.nonHiddenSections.slice(0, navItemsToShow);
    }

    get moreNavItems() {
      return (0, _isSome.default)(this.visibleNavItemCount) ? this.nonHiddenSections.slice(this.visibleNavItemCount).map((s, idx) => ({
        label: s.title,
        action: () => this.goToSection(s, this.visibleNavItemCount + idx, true),
        class: s === this.activeSection ? this.style.moreActiveNavItem : ''
      })) : [];
    }

    get shouldCollapse() {
      return this.media.isTablet;
    }

    get isMoreActive() {
      return (0, _isSome.default)(this.activeSection) && !this.visibleNavItems.includes(this.activeSection) && this.nonHiddenSections.includes(this.activeSection);
    }

    get moreLabel() {
      return this.isMoreActive ? this.activeSection.title : this.intl.t('mwc.stickyNav.moreLabel');
    }

    get visibleNavItemCount() {
      if ((0, _isSome.default)(this.args.visibleNavItemCount)) {
        return this.args.visibleNavItemCount;
      }

      if (!this.navElement || !this.contentElement || (0, _.isEmpty)(this.nonHiddenSections) || (0, _.isEmpty)(this.navElement.children) || this.navItemWidths.length < this.sections.length) {
        return this.nonHiddenSections.length;
      } // Keeps track of how many items can be visible without overflowing


      let visibleCount;

      if (this.shouldCollapse) {
        // Gets the widths of all nav items that could be visible
        const visibleWidths = this.navItemWidths.filter((_w, idx) => !this.sections[idx].isHidden); // Keeps track of how much visible width we've used so far

        let totalWidth = this.navElement.children[0].offsetLeft;

        for (visibleCount = 0; visibleCount < visibleWidths.length; visibleCount++) {
          // Calculates max width by taking the width of the parent container, subtracting
          // the widest possible nav item in the more dropdown, and subtracting the icon
          // width to account for the more arrow.
          const maxMoreWidth = Math.max(...visibleWidths.slice(visibleCount));
          const maxWidth = this.contentElement.clientWidth - maxMoreWidth - ICON_WIDTH;
          totalWidth += visibleWidths[visibleCount];

          if (totalWidth > maxWidth) {
            // If we've overflowed the max width, we can't display any more items.
            // We don't need to decrement because visibleCount is 0-based, but the
            // actual count of visible items is 1-based.
            break;
          }
        }
      } else {
        visibleCount = this.sections.length;
      }

      return visibleCount;
    } // Constructor
    // Other methods


    registerSection(section) {
      this.sections = [...this.sections, section];
    }

    setActiveSection(section) {
      if (!this.shouldCollapse && (0, _isSome.default)(section)) {
        var _navItem$parentElemen;

        const navItem = document.querySelector(navItemSelector(section.id));
        this.navElement.scrollTo({
          left: navItem == null ? void 0 : (_navItem$parentElemen = navItem.parentElement) == null ? void 0 : _navItem$parentElemen.offsetLeft,
          behavior: 'smooth'
        });
      }

      this.activeSection = section;
    } // Tasks
    // Actions


    *transition({
      insertedSprites,
      keptSprites,
      removedSprites
    }) {
      yield Promise.all(removedSprites.map(s => {
        (0, _opacity.fadeOut)(s, {
          duration: (0, _animation.getDuration)(200)
        });
        s.applyStyles({
          display: 'none'
        });
      }));
      yield Promise.all(keptSprites.map(s => {
        (0, _move.default)(s, {
          duration: (0, _animation.getDuration)(200)
        });
      }));
      yield Promise.all(insertedSprites.map(s => {
        (0, _opacity.fadeIn)(s, {
          duration: (0, _animation.getDuration)(200)
        });
        s.applyStyles({
          display: ''
        });
      }));
      this.isLoading = false;
    }

    setupContent(e) {
      this.contentElement = e;
      this.setup();
    }

    setupNav(e) {
      this.navElement = e;
      this.setup();
    }

    setup() {
      if (!this.contentElement || !this.navElement) {
        return;
      }

      window.addEventListener('resize', this.contentElementChanged);
      this.observer = new IntersectionObserver(this.visibleSectionsChanged, {
        // We don't know the height of the navElement yet, so we just need something that is reasonably close.
        // Doing headerHeight * 3 gives us enough wiggle room to work at all resolutions,
        // but still look reasonable to the user when scrolling.
        // (∩｀-´)⊃━☆ﾟ.*・｡ﾟ
        rootMargin: `-${this.headerHeight * 3}px 0px 0px 0px`,
        threshold: 0
      });
      this.sections.forEach(s => {
        this.observer.observe(s.element);
      });
    }

    setupNavItem(e) {
      if (this.navItemWidths.length < this.sections.length) {
        this.navItemWidths = [...this.navItemWidths, e.offsetWidth];
      }
    }

    teardown() {
      var _this$observer;

      window.removeEventListener('resize', this.contentElementChanged);
      (_this$observer = this.observer) == null ? void 0 : _this$observer.disconnect == null ? void 0 : _this$observer.disconnect();
    }

    visibleSectionsChanged(entries) {
      entries.forEach(entry => {
        const id = (0, _component3.sectionId)(entry.target);

        if (entry.isIntersecting) {
          this.visibleSectionIds.add(id);
        } else {
          this.visibleSectionIds.delete(id);
        }
      });

      if (!this.isAutoScrolling) {
        const firstVisibleSection = this.nonHiddenSections.find(s => this.visibleSectionIds.has(s.id));
        this.setActiveSection(firstVisibleSection);
      }
    }

    contentElementChanged() {
      // eslint-disable-next-line no-self-assign
      this.contentElement = this.contentElement;
    }

    goToSection(section, idx, isMoreCategory) {
      this.analytics.trackEvent(_analytics.AnalyticsEvents.StickyNavCategoryClick, () => ({
        [_analytics.AnalyticsProperties.CategoryName]: section.title,
        [_analytics.AnalyticsProperties.CategoryIndex]: idx + 1,
        [_analytics.AnalyticsProperties.CategoryIsWithinViewMore]: isMoreCategory,
        [_analytics.AnalyticsProperties.TotalCategories]: this.sections.length
      }));
      this.isAutoScrolling = true;
      Ember.run.next(this, () => {
        this.setActiveSection(section);
        window.scrollTo({
          top: section.element.offsetTop - this.navElement.offsetHeight,
          behavior: 'smooth'
        });
      });
      (0, _scroll.onScrollStop)(window, () => {
        var _section$element;

        (_section$element = section.element) == null ? void 0 : _section$element.focus({
          preventScroll: true
        });
        this.isAutoScrolling = false;
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "media", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "activeSection", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "contentElement", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "navElement", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "navItemWidths", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "observer", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "sections", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "visibleSectionIds", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return new Set();
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isAutoScrolling", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "nonHiddenSections", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "nonHiddenSections"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transition", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "transition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupContent", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "setupContent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupNav", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "setupNav"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setup", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupNavItem", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "setupNavItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "teardown", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "teardown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "visibleSectionsChanged", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "visibleSectionsChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "contentElementChanged", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "contentElementChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToSection", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "goToSection"), _class.prototype)), _class));
  _exports.default = StickyNav;
});