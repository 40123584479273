define("mobile-web/components/r-no-valid-vendor-results/component", ["exports", "@glimmer/component", "mobile-web/lib/utilities/_", "mobile-web/components/r-no-valid-vendor-results/style"], function (_exports, _component, _, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let NoValidVendorResults = (_dec = Ember._action, (_class = class NoValidVendorResults extends _component.default {
    // Service injections
    // Passthroughs
    // Tracked properties
    // Getters and setters
    get isAdditional() {
      var _this$args$isAddition;

      return (_this$args$isAddition = this.args.isAdditional) != null ? _this$args$isAddition : false;
    }

    get message() {
      const label = this.args.label;
      const nearbyVendorCount = this.args.nearbyVendorCount;

      if (this.isAdditional) {
        if (nearbyVendorCount > 1) {
          return `There are ${nearbyVendorCount} additional locations that are similar to your search.`;
        }

        return `There is ${nearbyVendorCount} additional location that is similar to your search.`;
      }

      if (nearbyVendorCount > 1) {
        return `There are ${nearbyVendorCount} locations near you, but none of them support ordering ${label}!`;
      }

      return `There is ${nearbyVendorCount} location near you, but it does not support ordering ${label}!`;
    } // Constructor


    constructor(owner, args) {
      super(owner, args);
      this.style = _style.default;
      (false && !(args.isAdditional || (0, _.isString)(args.label)) && Ember.assert('`label` is required if not `isAdditional`', args.isAdditional || (0, _.isString)(args.label)));
      (false && !(args.nearbyVendorCount > 0) && Ember.assert('`nearbyVendorCount` is required and > 0', args.nearbyVendorCount > 0));
      (false && !((0, _.isFunction)(args.onEdit)) && Ember.assert('`onEdit` action is required', (0, _.isFunction)(args.onEdit)));
    } // Other methods
    // Tasks
    // Actions


    clickEdit() {
      this.args.onEdit();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "clickEdit", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "clickEdit"), _class.prototype)), _class));
  _exports.default = NoValidVendorResults;
});