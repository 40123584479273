define("mobile-web/components/r-checkbox/component", ["exports", "@glimmer/component", "mobile-web/components/r-checkbox/style"], function (_exports, _component, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let Checkbox = (_dec = Ember._action, (_class = class Checkbox extends _component.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get disabled() {
      var _this$args$disabled;

      return (_this$args$disabled = this.args.disabled) != null ? _this$args$disabled : false;
    }

    get textClass() {
      return this.args.hideLabel ? _style.default.hidden : _style.default.text;
    } // Constructor
    // Other methods
    // Tasks
    // Actions


    onInput() {
      var _this$args$onInput, _this$args;

      (_this$args$onInput = (_this$args = this.args).onInput) == null ? void 0 : _this$args$onInput.call(_this$args, !this.args.checked);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onInput", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onInput"), _class.prototype)), _class));
  _exports.default = Checkbox;
});