define("mobile-web/components/r-radio/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JiUotXzu",
    "block": "[[[10,\"label\"],[15,0,[30,0,[\"rootClass\"]]],[12],[1,\"\\n  \"],[10,\"input\"],[15,\"data-test-radio-input\",[30,1]],[15,3,[30,2]],[15,\"disabled\",[30,3]],[15,0,[30,0,[\"style\",\"input\"]]],[15,\"checked\",[30,4]],[15,\"onchange\",[30,5]],[14,4,\"radio\"],[12],[13],[1,\"\\n  \"],[10,1],[15,0,[30,0,[\"style\",\"inputAccent\"]]],[14,\"aria-hidden\",\"true\"],[12],[13],[1,\"\\n  \"],[10,1],[15,\"data-test-radio-label\",[30,1]],[15,0,[30,0,[\"textClass\"]]],[12],[1,[30,1]],[13],[1,\"\\n\"],[13]],[\"@label\",\"@name\",\"@disabled\",\"@checked\",\"@onChange\"],false,[]]",
    "moduleName": "mobile-web/components/r-radio/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});