define("mobile-web/components/r-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vSroC/QP",
    "block": "[[[10,\"label\"],[15,0,[30,0,[\"style\",\"root\"]]],[12],[1,\"\\n  \"],[8,[39,0],[[16,0,[30,0,[\"style\",\"input\"]]],[16,\"disabled\",[30,0,[\"disabled\"]]],[24,\"data-test-checkbox-input\",\"\"],[17,1],[4,[38,1],[\"input\",[30,0,[\"onInput\"]]],null]],[[\"@checked\",\"@type\"],[[30,2],\"checkbox\"]],null],[1,\"\\n  \"],[10,1],[15,0,[30,0,[\"style\",\"inputAccent\"]]],[14,\"aria-hidden\",\"true\"],[12],[13],[1,\"\\n  \"],[10,1],[14,\"data-test-checkbox-label\",\"\"],[15,0,[30,0,[\"textClass\"]]],[12],[1,[30,3]],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@checked\",\"@label\"],false,[\"input\",\"on\"]]",
    "moduleName": "mobile-web/components/r-checkbox/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});