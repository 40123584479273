define("mobile-web/controllers/menu/vendor/products", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MenuVendorProductsController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      this.isLoading = false;
    }

  }

  _exports.default = MenuVendorProductsController;
});