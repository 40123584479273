define("mobile-web/components/r-rewards-search-form/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_1q6cxx",
    "row": "_row_1q6cxx",
    "label": "_label_1q6cxx",
    "submit": "_submit_1q6cxx _row_1q6cxx"
  };
  _exports.default = _default;
});