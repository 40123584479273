define("mobile-web/controllers/secure/login", ["exports", "mobile-web/lib/errors", "mobile-web/router"], function (_exports, _errors, _router) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LoginController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class LoginController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "error", _descriptor, this);

      _initializerDefineProperty(this, "channel", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      this.queryParams = ['mode', 'upgrade'];

      _initializerDefineProperty(this, "mode", _descriptor4, this);

      _initializerDefineProperty(this, "upgrade", _descriptor5, this);
    }

    // Getters and setters
    get signInCreateAccountMode() {
      return Number(this.mode) || 0;
    } // Constructor
    // Other methods


    invalidLogin(reason) {
      this.error.sendUserMessage({
        detail: reason
      });
    }

    transitionOut() {
      const session = this.session;
      const {
        nextRoute
      } = session;

      if (nextRoute) {
        this.session.set('nextRoute', undefined); // If there is a next route, the first entry is *always* a string
        // that is the route name we are going to.

        if ((0, _router.isNoRedirectRoute)(nextRoute[0])) {
          this.transitionToRoute('application');
        } else {
          // @ts-ignore -- until TS 3.0 when tuples/argument work correctly
          this.transitionToRoute(...nextRoute);
        }
      } else {
        this.transitionToRoute('application');
      }
    } // Tasks
    // Actions


    afterCreateAccount() {
      const nextRoute = this.session.nextRoute;
      let routeArgs;

      if (nextRoute) {
        this.session.set('nextRoute', undefined);
        routeArgs = nextRoute;
      } else {
        routeArgs = ['secure.my-account'];
      } // @ts-ignore -- till 3.0 when tuple types work.


      this.transitionToRoute(...routeArgs);
    }

    afterCreateAccountError(error) {
      this.error.sendUserMessage(error);
    }

    login(email, password) {
      this.session.internalLogin(email, password, () => this.transitionOut(), (0, _errors.buildOloErrorHandler)('login-failure', reason => this.invalidLogin(reason)));
    }

    externalLogin(loginProvider) {
      this.session.externalLogin(loginProvider.slug);
    }

    switchModes(mode) {
      // @ts-ignore -- types need to be updated
      this.transitionToRoute({
        queryParams: {
          mode
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "error", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "mode", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "upgrade", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "afterCreateAccount", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "afterCreateAccount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterCreateAccountError", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "afterCreateAccountError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "login", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "login"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "externalLogin", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "externalLogin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "switchModes", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "switchModes"), _class.prototype)), _class));
  _exports.default = LoginController;
});