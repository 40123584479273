define("mobile-web/templates/components/r-icons/r-eye", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DHmTu79+",
    "block": "[[[10,0],[15,0,[30,0,[\"rootClass\"]]],[12],[1,\"\\n\"],[41,[33,1],[[[1,\"    \"],[10,\"title\"],[12],[1,[34,1]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[1,[28,[35,2],[\"r-eye\"],[[\"class\"],[[30,0,[\"style\",\"icon\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"title\",\"svg-jar\"]]",
    "moduleName": "mobile-web/templates/components/r-icons/r-eye.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});