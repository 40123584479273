define("mobile-web/services/order-criteria", ["exports", "mobile-web/lib/computed", "mobile-web/lib/dayjs", "mobile-web/lib/location/address", "mobile-web/lib/order-criteria", "mobile-web/lib/utilities/_", "mobile-web/models/vendor"], function (_exports, _computed, _dayjs, _address, _orderCriteria, _, _vendor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function normalizeOrderCriteria(input) {
    const jsonData = JSON.parse(input);

    if (jsonData.timeWanted) {
      jsonData.timeWanted = (0, _dayjs.default)(jsonData.timeWanted);
    }

    return jsonData;
  }

  let OrderCriteriaService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = (0, _computed.session)({
    normalize: normalizeOrderCriteria
  }), _dec12 = Ember.computed('basket.basket.{deliveryAddress,handoffMode,handoffLabel,isAdvance,isImmediate,isManualFire,timeWanted,vendor}'), (_class = class OrderCriteriaService extends Ember.Service {
    /**
     * Search order criteria is a bit complicated. There are three parts to it.
     *
     * `storedOrderCriteria` is the session-storage part of it. We use this to actually
     * store the search in session storage. However, `session` annotated variables
     * cannot be annotated with `tracked`.
     *
     * `_searchOrderCriteria` is the tracked part of it. We use this to drive the dynamic
     * updating of all the things when search criteria changes. We set this private variable
     * in the constructor from `storedOrderCriteria`, loading up saved session searches.
     *
     * `searchOrderCriteria` is the third part of it, and it is a getter/setter pair. The
     * getter is simply a wrapper for `_searchOrderCriteria`, but the setter is the magic.
     * It sets both `_searchOrderCriteria` and `storedOrderCriteria`, keeping them both
     * in sync after the constructor has run.
     *
     * Eventually we probably want to add similar two-variable tracking to the `session`
     * decorator directly so we don't need all this wiring to make a session variable
     * tracked, but that is a project for another 10% time day.
     */
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      _initializerDefineProperty(this, "channel", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "vendor", _descriptor5, this);

      _initializerDefineProperty(this, "onOrderCriteriaChange", _descriptor6, this);

      _initializerDefineProperty(this, "searchOnly", _descriptor7, this);

      _initializerDefineProperty(this, "orderCriteriaError", _descriptor8, this);

      _initializerDefineProperty(this, "showAddressModal", _descriptor9, this);

      _initializerDefineProperty(this, "_searchOrderCriteria", _descriptor10, this);

      _initializerDefineProperty(this, "storedOrderCriteria", _descriptor11, this);

      this._searchOrderCriteria = this.storedOrderCriteria;
    }

    get searchOrderCriteria() {
      return this._searchOrderCriteria;
    }

    set searchOrderCriteria(c) {
      this._searchOrderCriteria = c;
      this.storedOrderCriteria = c;
    }

    get showModal() {
      return this.onOrderCriteriaChange !== undefined;
    }

    get criteria() {
      var _ref, _this$basketOrderCrit;

      return (_ref = (_this$basketOrderCrit = this.basketOrderCriteria) != null ? _this$basketOrderCrit : this.searchOrderCriteria) != null ? _ref : this.defaultOrderCriteria;
    }

    get defaultOrderCriteria() {
      var _allChannelModes$find, _defaultHandoffMode$t;

      const channel = this.channel.current;
      const allChannelModes = channel == null ? void 0 : channel.handoffModes;
      const defaultHandoffMode = (_allChannelModes$find = allChannelModes == null ? void 0 : allChannelModes.find(m => (0, _orderCriteria.handoffMatches)(m.type, channel == null ? void 0 : channel.defaultHandoffMode))) != null ? _allChannelModes$find : allChannelModes == null ? void 0 : allChannelModes[0];
      const defaultHandoffType = defaultHandoffMode == null ? void 0 : defaultHandoffMode.type;
      const defaultTimeWantedMode = defaultHandoffMode == null ? void 0 : (_defaultHandoffMode$t = defaultHandoffMode.timeWantedModes) == null ? void 0 : _defaultHandoffMode$t.firstObject;
      let timeWanted;

      if ((defaultTimeWantedMode == null ? void 0 : defaultTimeWantedMode.type) === 'Advance') {
        timeWanted = {
          timeWantedType: 'Advance',
          timeWanted: (0, _dayjs.roundUpTime)((0, _dayjs.default)())
        };
      } else {
        var _defaultTimeWantedMod;

        timeWanted = {
          timeWantedType: (_defaultTimeWantedMod = defaultTimeWantedMode == null ? void 0 : defaultTimeWantedMode.type) != null ? _defaultTimeWantedMod : 'Immediate'
        };
      }

      let handoffMode;

      if ((0, _orderCriteria.isDeliveryMode)(defaultHandoffType)) {
        handoffMode = {
          handoffMode: defaultHandoffType,
          deliveryAddress: _address.EMPTY
        };
      } else if ((0, _orderCriteria.isAtStoreMode)(defaultHandoffType)) {
        handoffMode = {
          handoffMode: defaultHandoffType,
          searchAddress: ''
        };
      } else {
        handoffMode = {
          handoffMode: 'Unspecified'
        };
      }

      return _extends({}, handoffMode, timeWanted, {
        isDefault: true
      });
    } // Migrated verbatim from `r-edit-order-criteria-modal`, untested


    get isSearch() {
      return this.searchOnly || !this.basketOrderCriteria;
    } // Migrated verbatim from `r-edit-order-criteria-modal`, untested


    get showVendorModes() {
      return this.router.isActive('menu') || !this.isSearch;
    } // Migrated verbatim from `r-edit-order-criteria-modal`, untested


    get showPrivateModes() {
      var _this$vendor$vendor;

      return this.showVendorModes && ((_this$vendor$vendor = this.vendor.vendor) == null ? void 0 : _this$vendor$vendor.status) === _vendor.VendorStatus.Private;
    } // We still need `computed` here because the basket dependencies are not `tracked`


    get basketOrderCriteria() {
      const basket = this.basket.basket;

      if (!basket) {
        return undefined;
      }

      let timeWanted;

      if (basket.isAdvance) {
        timeWanted = {
          timeWantedType: 'Advance',
          timeWanted: basket.timeWanted ? (0, _dayjs.default)(basket.timeWanted) : undefined
        };
      } else {
        timeWanted = {
          timeWantedType: basket.isManualFire ? 'ManualFire' : 'Immediate'
        };
      }

      let handoffMode;

      if ((0, _orderCriteria.isDeliveryMode)(basket.handoffMode)) {
        handoffMode = {
          handoffMode: basket.handoffMode,
          deliveryAddress: basket.deliveryAddress ? {
            id: basket.deliveryAddress.id,
            streetAddress: basket.deliveryAddress.streetAddress,
            building: basket.deliveryAddress.building,
            city: basket.deliveryAddress.city,
            zipCode: basket.deliveryAddress.zipCode
          } : undefined
        };
      } else {
        handoffMode = {
          handoffMode: basket.handoffMode,
          searchAddress: basket.vendor.get('name')
        };
      }

      return _extends({}, handoffMode, timeWanted);
    }

    async updateBasket() {
      if (this.basketOrderCriteria) {
        var _this$basket$basket;

        await ((_this$basket$basket = this.basket.basket) == null ? void 0 : _this$basket$basket.updateCriteria());
        this.searchOrderCriteria = undefined;
      }
    }

    openModal(args = {}) {
      var _ref2, _args$onChange, _ref3, _args$searchOnly, _args$error;

      this.onOrderCriteriaChange = (_ref2 = (_args$onChange = args.onChange) != null ? _args$onChange : this.onOrderCriteriaChange) != null ? _ref2 : _.noop;
      this.searchOnly = (_ref3 = (_args$searchOnly = args.searchOnly) != null ? _args$searchOnly : this.searchOnly) != null ? _ref3 : false;
      this.orderCriteriaError = (_args$error = args.error) != null ? _args$error : '';
    }

    closeModal(orderCriteria) {
      var _this$onOrderCriteria;

      if (orderCriteria) (_this$onOrderCriteria = this.onOrderCriteriaChange) == null ? void 0 : _this$onOrderCriteria.call(this, orderCriteria);
      this.onOrderCriteriaChange = undefined;
      this.searchOnly = false;
      this.orderCriteriaError = '';
    }

    updateSearchOrderCriteria(handoffMode) {
      var _this$vendor$vendor$a, _this$vendor$vendor2, _this$basket$basket2;

      if (!this.isValidHandoffMode(handoffMode)) {
        return;
      }

      const timeWantedType = this.getFirstValidTimeWantedType(handoffMode);

      if (!timeWantedType) {
        // In theory this should never be hit because `isValidHandoffMode` should
        // have already checked that at least one valid timeWantedType exists
        return;
      }

      switch (handoffMode) {
        case 'Delivery':
        case 'Dispatch':
        case 'MultiDelivery':
          this.searchOrderCriteria = {
            handoffMode: 'MultiDelivery',
            isDefault: true,
            timeWantedType
          };
          break;

        case 'CounterPickup':
        case 'CurbsidePickup':
        case 'DriveThru':
        case 'DineIn':
          this.searchOrderCriteria = {
            handoffMode,
            searchAddress: (_this$vendor$vendor$a = (_this$vendor$vendor2 = this.vendor.vendor) == null ? void 0 : _this$vendor$vendor2.address.postalCode) != null ? _this$vendor$vendor$a : 'auto',
            timeWantedType
          };
          break;

        default:
          break;
      } // Open the modal when:


      if ( // a delivery handoff mode is used, because we need an address
      (0, _orderCriteria.isDeliveryMode)(handoffMode) || // there is a basket with a handoff mode that doesn't match the specified
      // handoff mode, so the guest can confirm how they want their order
      (_this$basket$basket2 = this.basket.basket) != null && _this$basket$basket2.handoffMode && this.basket.basket.handoffMode !== handoffMode || // the timeWantedType is advance but timeWanted isn't provided--such as
      // when ASAP isn't available--so the guest can confirm when they want
      // their order
      (0, _orderCriteria.isAdvance)(this.searchOrderCriteria) && !this.searchOrderCriteria.timeWanted) {
        this.openModal({
          searchOnly: true,
          onChange: () => {
            this.updateBasket();
          }
        });
      }
    }
    /**
     * Determine if a handoff mode is valid for the current vendor
     * @param handoffMode the handoff mode to check
     * @returns whether the handoff is valid
     */


    isValidHandoffMode(handoffMode) {
      var _this$vendor$vendor$s, _this$vendor$vendor3;

      // Unspecified handoff modes are never valid
      if (handoffMode === 'Unspecified') {
        return false;
      } // The handoff mode must be included in the vendor's supported handoff modes


      const supportedHandoffModes = (_this$vendor$vendor$s = (_this$vendor$vendor3 = this.vendor.vendor) == null ? void 0 : _this$vendor$vendor3.settings.supportedHandoffModes) != null ? _this$vendor$vendor$s : [];

      if (!supportedHandoffModes.some(hm => (0, _orderCriteria.handoffMatches)(hm, handoffMode))) {
        return false;
      }

      const handoffModeModel = this.store.peekAll('handoff-mode').find(hm => (0, _orderCriteria.handoffMatches)(hm.type, handoffMode) && ( // If the handoff mode is private, it is only valid if the vendor is
      // also private. There may be a second matching handoff mode that is not
      // private--such as when MultiDelivery matches Delivery and then
      // Dispatch--so continue looking for a matching handoff mode.
      !hm.isPrivate || this.showPrivateModes));

      if (!handoffModeModel) {
        return false;
      } // The vendor must have at least one valid time wanted mode


      if (!handoffModeModel.timeWantedModes.toArray().some(timeWantedMode => this.isValidTimeWantedType(timeWantedMode.type))) {
        return false;
      }

      return true;
    }
    /**
     * Determine if a time wanted type is valid for the current vendor
     * @param timeWantedType the time wanted type to check
     * @returns whether the time wanted type is valid
     */


    isValidTimeWantedType(timeWantedType) {
      var _this$vendor$vendor$a2, _this$vendor$vendor4, _this$vendor$vendor$a3, _this$vendor$vendor5, _this$vendor$vendor$a4, _this$vendor$vendor6;

      const timeWantedMode = this.store.peekAll('time-wanted-mode').find(twm => twm.type === timeWantedType); // If the time wanted type is private, it is only valid if the vendor is
      // also private

      if (timeWantedMode != null && timeWantedMode.isPrivate && !this.showPrivateModes) {
        return false;
      } // The time wanted type must be allowed by the vendor


      switch (timeWantedType) {
        case 'Advance':
          return (_this$vendor$vendor$a2 = (_this$vendor$vendor4 = this.vendor.vendor) == null ? void 0 : _this$vendor$vendor4.allowAdvanceOrders) != null ? _this$vendor$vendor$a2 : false;

        case 'Immediate':
          return (_this$vendor$vendor$a3 = (_this$vendor$vendor5 = this.vendor.vendor) == null ? void 0 : _this$vendor$vendor5.allowImmediateOrders) != null ? _this$vendor$vendor$a3 : false;

        case 'ManualFire':
          return (_this$vendor$vendor$a4 = (_this$vendor$vendor6 = this.vendor.vendor) == null ? void 0 : _this$vendor$vendor6.allowManualFireOrders) != null ? _this$vendor$vendor$a4 : false;

        default:
          return false;
      }
    }
    /**
     * Gets the first timeWantedType for a handoffMode that's valid for the
     * current vendor if one exists.
     * @param handoffMode the handoff mode to access
     * @returns the time wanted mode if a valid one exists, otherwise `undefined`
     */


    getFirstValidTimeWantedType(handoffMode) {
      const handoffModeModel = this.store.peekAll('handoff-mode').find(hm => (0, _orderCriteria.handoffMatches)(hm.type, handoffMode));

      if (!handoffModeModel) {
        return undefined;
      }

      const timeWantedMode = handoffModeModel.timeWantedModes.toArray().find(twm => this.isValidTimeWantedType(twm.type));
      return timeWantedMode == null ? void 0 : timeWantedMode.type;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "vendor", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "onOrderCriteriaChange", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "searchOnly", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "orderCriteriaError", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "showAddressModal", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "_searchOrderCriteria", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "storedOrderCriteria", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "basketOrderCriteria", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "basketOrderCriteria"), _class.prototype)), _class));
  _exports.default = OrderCriteriaService;
});