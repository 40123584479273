define("mobile-web/components/r-tag-list/component", ["exports", "mobile-web/components/r-tag-list/style"], function (_exports, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TagList extends Ember.Component.extend({
    tagName: ''
  }) {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    }

  }

  _exports.default = TagList;
});