define("mobile-web/components/r-side-menu-link/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "link": "_link_9essc9",
    "icon": "_icon_9essc9",
    "text": "_text_9essc9"
  };
  _exports.default = _default;
});