define("mobile-web/components/r-quantity-input/component", ["exports", "@glimmer/component", "mobile-web/lib/animation", "mobile-web/models/option-group", "mobile-web/components/r-quantity-input/style"], function (_exports, _component, _animation, _optionGroup, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const HOLD_INTERVAL = 50;
  const DEFAULT_MAX = 100;
  let QuantityInput = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class QuantityInput extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "device", _descriptor, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "_quantity", _descriptor2, this);

      _initializerDefineProperty(this, "inputElement", _descriptor3, this);

      _initializerDefineProperty(this, "thumbElement", _descriptor4, this);

      _initializerDefineProperty(this, "isMouseDown", _descriptor5, this);

      _initializerDefineProperty(this, "callCount", _descriptor6, this);
    }

    // Getters and setters
    get quantity() {
      var _this$args$quantity;

      return (_this$args$quantity = this.args.quantity) != null ? _this$args$quantity : this._quantity;
    }

    set quantity(val) {
      // This can happen if you direct enter a value that is not a valid step size,
      // and it rounds up to the same value that we used to have.
      if (this.inputElement && this._quantity === val) {
        this.inputElement.value = `${val}`;
      }

      this._quantity = val;
      this.quantityChanged(val);
    }

    get decreaseDisabled() {
      return this.quantity - this.stepSize < this.minQuantity;
    }

    get increaseDisabled() {
      return this.limit ? this.quantity + this.stepSize > this.limit : false;
    }

    get minQuantity() {
      return this.args.minimum && this.args.minimum > this.stepSize ? this.args.minimum : this.stepSize;
    }

    get maxQuantity() {
      var _this$args$maximum;

      return (_this$args$maximum = this.args.maximum) != null ? _this$args$maximum : DEFAULT_MAX;
    }

    get limit() {
      var _this$args$limit;

      return Math.min((_this$args$limit = this.args.limit) != null ? _this$args$limit : this.maxQuantity, this.maxQuantity);
    }

    get stepSize() {
      return this.args.stepSize || 1;
    }

    get type() {
      var _this$args$type;

      return (_this$args$type = this.args.type) != null ? _this$args$type : _optionGroup.QuantityChoiceViewTypes.Input;
    }

    get isSlider() {
      return this.type === _optionGroup.QuantityChoiceViewTypes.Slider;
    }

    get thumbClass() {
      const classes = [this.style.thumb];

      if (!this.maxQuantity || this.maxQuantity > 9) {
        classes.push(this.style.thumbLarge);
      }

      return classes.join(' ');
    }

    get thumbStyle() {
      if (this.inputElement && this.thumbElement && this.maxQuantity) {
        const tw = this.thumbElement.offsetWidth;
        const w = this.inputElement.offsetWidth;
        const xPX = (this.quantity - this.minQuantity) * (w - tw) / (this.maxQuantity - this.minQuantity);
        return Ember.String.htmlSafe(`left: ${xPX}px`);
      }

      return undefined;
    }

    get hideThumbFromAssistiveTech() {
      return !this.device.isAndroid;
    } // Constructor
    // Other methods


    quantityChanged(quantity) {
      this.args.updateQuantity(quantity);
    } // Tasks
    // Actions


    onChange(e) {
      let value = parseInt(e.target.value, 10) || this.minQuantity;

      if (value === this.quantity) {
        return;
      }

      const extra = value % this.stepSize;

      if (extra > 0) {
        value += this.stepSize - extra;
      }

      const maximum = this.limit;

      if (maximum && value > maximum) {
        this.quantity = maximum;
      } else if (value < this.minQuantity) {
        this.quantity = this.minQuantity;
      } else {
        this.quantity = value;
      }
    }

    increase() {
      if (!this.increaseDisabled) {
        this.isMouseDown = true;
        this.quantity += this.stepSize;
        Ember.run.later(() => {
          if (this.isMouseDown) {
            this.increase();
          } else {
            this.callCount = 0;
          }
        }, (0, _animation.getDuration)(HOLD_INTERVAL * Math.max(10 - this.callCount, 1)));
        this.callCount++;
      }
    }

    decrease() {
      if (this.quantity > this.minQuantity) {
        this.isMouseDown = true;
        this.quantity -= this.stepSize;
        Ember.run.later(() => {
          if (this.isMouseDown) {
            this.decrease();
          } else {
            this.callCount = 0;
          }
        }, (0, _animation.getDuration)(HOLD_INTERVAL * Math.max(10 - this.callCount, 1)));
        this.callCount++;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "device", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_quantity", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return this.minQuantity;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "inputElement", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "thumbElement", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isMouseDown", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "callCount", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "increase", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "increase"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "decrease", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "decrease"), _class.prototype)), _class));
  _exports.default = QuantityInput;
});