define("mobile-web/lib/utilities/_", ["exports", "lodash.at", "lodash.clone", "lodash.clonedeep", "lodash.debounce", "lodash.filter", "lodash.flatten", "lodash.has", "lodash.isequal", "lodash.map", "lodash.merge", "lodash.pick", "lodash.property", "lodash.reduce", "lodash.sumby", "lodash.toarray", "lodash.truncate", "lodash.unionby", "lodash.uniqby", "lodash.uniqwith", "lodash.zip"], function (_exports, _lodash, _lodash2, _lodash3, _lodash4, _lodash5, _lodash6, _lodash7, _lodash8, _lodash9, _lodash10, _lodash11, _lodash12, _lodash13, _lodash14, _lodash15, _lodash16, _lodash17, _lodash18, _lodash19, _lodash20) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sortBy = sortBy;
  _exports.without = _exports.toString = _exports.sum = _exports.stubTrue = _exports.range = _exports.noop = _exports.isString = _exports.isNumber = _exports.isObjectLike = _exports.isObject = _exports.isFunction = _exports.identity = _exports.isBoolean = _exports.isArray = _exports.defaultTo = _exports.compact = _exports.zip = _exports.uniqBy = _exports.uniqWith = _exports.unionBy = _exports.truncate = _exports.toArray = _exports.sumBy = _exports.reduce = _exports.property = _exports.pick = _exports.merge = _exports.map = _exports.isEmpty = _exports.isEqual = _exports.flatten = _exports.filter = _exports.debounce = _exports.has = _exports.cloneDeep = _exports.clone = _exports.at = void 0;
  const at = _lodash.default;
  _exports.at = at;
  const clone = _lodash2.default;
  _exports.clone = clone;
  const cloneDeep = _lodash3.default;
  _exports.cloneDeep = cloneDeep;
  const has = _lodash7.default;
  _exports.has = has;
  const debounce = _lodash4.default;
  _exports.debounce = debounce;
  const filter = _lodash5.default;
  _exports.filter = filter;
  const flatten = _lodash6.default;
  _exports.flatten = flatten;
  const isEqual = _lodash8.default;
  _exports.isEqual = isEqual;
  const isEmpty = Ember.isEmpty;
  _exports.isEmpty = isEmpty;
  const map = _lodash9.default;
  _exports.map = map;
  const merge = _lodash10.default;
  _exports.merge = merge;
  const pick = _lodash11.default;
  _exports.pick = pick;
  const property = _lodash12.default;
  _exports.property = property;
  const reduce = _lodash13.default;
  _exports.reduce = reduce;
  const sumBy = _lodash14.default;
  _exports.sumBy = sumBy;
  const toArray = _lodash15.default;
  _exports.toArray = toArray;
  const truncate = _lodash16.default;
  _exports.truncate = truncate;
  const unionBy = _lodash17.default;
  _exports.unionBy = unionBy;
  const uniqWith = _lodash19.default;
  _exports.uniqWith = uniqWith;
  const uniqBy = _lodash18.default;
  _exports.uniqBy = uniqBy;
  const zip = _lodash20.default; // These functions were referenced from https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore
  // Lodash also supports treating objects as arrays. For brevity, that functionality has been omitted.

  _exports.zip = zip;

  function getTag(value) {
    if (Ember.isNone(value)) {
      return value === undefined ? '[object Undefined]' : '[object Null]';
    }

    return Object.prototype.toString.call(value);
  }

  const compact = array => array.filter(Boolean);

  _exports.compact = compact;

  const defaultTo = (value, defaultValue) => Ember.isNone(value) || value !== value ? defaultValue : value; // eslint-disable-line no-self-compare


  _exports.defaultTo = defaultTo;

  const isArray = value => Array.isArray(value);

  _exports.isArray = isArray;

  const isBoolean = x => x === true || x === false || isObjectLike(x) && getTag(x) === '[object Boolean]';

  _exports.isBoolean = isBoolean;

  const identity = input => input;

  _exports.identity = identity;

  const isFunction = x => typeof x === 'function';

  _exports.isFunction = isFunction;

  const isObject = x => !Ember.isNone(x) && typeof x === 'object' || typeof x === 'function';

  _exports.isObject = isObject;

  const isObjectLike = value => typeof value === 'object' && !Ember.isNone(value);

  _exports.isObjectLike = isObjectLike;

  const isNumber = x => typeof x === 'number' || isObjectLike(x) && getTag(x) === '[object Number]';

  _exports.isNumber = isNumber;

  const isString = value => typeof value === 'string' || !isArray(value) && isObjectLike(value) && getTag(value) === '[object String]';

  _exports.isString = isString;

  const noop = (..._args) => undefined;
  /** range(5,10) => [5, 6, 7, 8, 9]. Produces empty set if from is larger than to, where lodash will generate a set that decrements, eg [10, 9, 8, 7, 6] */


  _exports.noop = noop;

  const range = (from, to) => Array.from({
    length: to - from
  }, (_, i) => i + from);

  _exports.range = range;

  function sortBy(input, sortKey) {
    const sortByFunc = key => (a, b) => a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0;

    return [...input].sort(sortByFunc(sortKey));
  }

  const stubTrue = () => true;

  _exports.stubTrue = stubTrue;

  const sum = input => input.reduce((a, b) => a + b, 0);

  _exports.sum = sum;

  const toString = x => Ember.isNone(x) ? '' : x.toString();

  _exports.toString = toString;

  const without = (input, ...values) => input.filter(x => !values.includes(x));

  _exports.without = without;
});