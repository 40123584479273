define("mobile-web/templates/thank-you", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DNfWzrZX",
    "block": "[[[1,[28,[35,0],null,[[\"order\",\"onUserOrderEvent\",\"onCancelOrder\",\"saveFavorite\",\"showSocialIntegration\"],[[33,1],[28,[37,2],[[30,0],[30,0,[\"onUserOrderEvent\"]]],null],[28,[37,2],[[30,0],[30,0,[\"onCancelOrder\"]]],null],[30,0,[\"saveFavorite\"]],[33,3]]]]],[1,\"\\n\"]],[],false,[\"r-post-checkout\",\"model\",\"action\",\"showSocialIntegration\"]]",
    "moduleName": "mobile-web/templates/thank-you.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});