define("mobile-web/components/r-menu-filter/component", ["exports", "@ember-decorators/component", "mobile-web/services/analytics", "mobile-web/components/r-menu-filter/style"], function (_exports, _component, _analytics, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MenuFilter = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._action, _dec(_class = (_class2 = class MenuFilter extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "channel", _descriptor, this);

      _initializerDefineProperty(this, "orderCriteria", _descriptor2, this);

      _initializerDefineProperty(this, "storage", _descriptor3, this);

      _initializerDefineProperty(this, "analytics", _descriptor4, this);

      this.style = _style.default;
    }

    // Computed properties
    // Init
    init() {
      var _this$channel$setting;

      super.init();

      if ((_this$channel$setting = this.channel.settings) != null && _this$channel$setting.showFullMenu) {
        this.storage.set('showFullMenu', true);
      }

      if (Ember.isNone(this.storage.showFullMenu)) {
        this.storage.set('showFullMenu', !!this.orderCriteria.criteria.isDefault);
      }
    } // Other methods
    // Actions


    onChange(showFullMenu) {
      this.analytics.trackEvent(_analytics.AnalyticsEvents.MenuTypeFilter, () => ({
        [_analytics.AnalyticsProperties.FilterName]: showFullMenu ? 'full' : 'available'
      }));
      this.storage.set('showFullMenu', showFullMenu);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "channel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "orderCriteria", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "storage", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "analytics", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "onChange", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "onChange"), _class2.prototype)), _class2)) || _class);
  _exports.default = MenuFilter;
});