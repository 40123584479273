define("mobile-web/components/r-cart/product-list/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "ember-animated/motions/move", "ember-animated/motions/opacity", "mobile-web/lib/animation", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-cart/product-list/style"], function (_exports, _glimmerComponent, _move, _opacity, _animation, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CartProductList = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class CartProductList extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);
      this.animationDuration = (0, _animation.getDuration)(40);
      this.style = _style.default;

      _initializerDefineProperty(this, "isExpanded", _descriptor, this);
    }

    // Getters and setters
    // Getters and setters
    get shownProducts() {
      return this.isExpanded || Ember.isNone(this.args.initialCount) ? this.args.products : this.args.products.slice(0, this.args.initialCount);
    }

    get hiddenProductCount() {
      return Ember.isNone(this.args.initialCount) ? this.args.products.length : this.args.products.length - this.args.initialCount;
    }

    get showExpandButton() {
      return !this.isExpanded && (0, _isSome.default)(this.args.initialCount) && this.args.products.length > this.args.initialCount;
    } // Constructor
    // Other methods


    *transition({
      insertedSprites,
      removedSprites
    }) {
      for (const sprite of insertedSprites) {
        sprite.startTranslatedBy(0, 30);
        (0, _opacity.fadeIn)(sprite, {
          duration: 200
        });
        (0, _move.default)(sprite, {
          duration: 0
        });
      }

      for (const sprite of removedSprites) {
        sprite.endTranslatedBy(sprite.initialBounds.width, 0);
        (0, _move.default)(sprite, {
          duration: 200
        });
        (0, _opacity.fadeOut)(sprite, {
          duration: 200
        });
      }
    } // Tasks
    // Actions


    expandProducts() {
      this.isExpanded = true;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isExpanded", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "expandProducts", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "expandProducts"), _class.prototype)), _class));
  _exports.default = CartProductList;
});