define("mobile-web/lib/utilities/transform-content", ["exports", "mobile-web/lib/utilities/remove-scripts"], function (_exports, _removeScripts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.transformContent = _exports.ContentType = void 0;
  let ContentType;
  _exports.ContentType = ContentType;

  (function (ContentType) {
    ContentType["HTML"] = "html";
    ContentType["TEXT"] = "text";
  })(ContentType || (_exports.ContentType = ContentType = {}));

  const substitute = (value, substitutions) => {
    const subKeys = Object.keys(substitutions);
    return value.replace(/%(\w+)%/gi, (match, key) => {
      const substitutionKey = subKeys.find(subKey => subKey.localeCompare(key, undefined, {
        sensitivity: 'base'
      }) === 0);
      return substitutionKey ? substitutions[substitutionKey] : match;
    });
  };

  const transformContent = (rawValue, type, substitutions = {}) => {
    const subbedValue = substitute(rawValue, substitutions);

    if (type === ContentType.TEXT) {
      return subbedValue;
    }

    const value = (0, _removeScripts.removeScripts)(subbedValue);
    return Ember.String.htmlSafe(value);
  };

  _exports.transformContent = transformContent;
});