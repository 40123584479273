define("mobile-web/components/r-cart/product/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "ember-animated/motions/move", "ember-animated/motions/opacity", "ember-concurrency", "ember-concurrency-ts", "mobile-web/lib/animation", "mobile-web/lib/utilities/keys", "mobile-web/models/basket-product", "mobile-web/services/global-events", "mobile-web/services/notifications", "mobile-web/components/r-cart/product/style"], function (_exports, _glimmerComponent, _move, _opacity, _emberConcurrency, _emberConcurrencyTs, _animation, _keys, _basketProduct, _globalEvents, _notifications, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const COUNT_SHOWN_BY_DEFAULT = 3;
  let CartProduct = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class CartProduct extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "features", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "notifications", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "basket", _descriptor5, this);

      _initializerDefineProperty(this, "vendor", _descriptor6, this);

      _initializerDefineProperty(this, "globalEvents", _descriptor7, this);

      this.animationDuration = (0, _animation.getDuration)(40);
      this.style = _style.default;

      _initializerDefineProperty(this, "isExpanded", _descriptor8, this);

      this.removeProductTask = (0, _emberConcurrencyTs.taskFor)(this.removeProductTaskInstance);
    }

    // Getters and setters
    get editable() {
      var _this$args$editable;

      return (_this$args$editable = this.args.editable) != null ? _this$args$editable : false;
    }

    get displayableChoices() {
      var _Ember$get$toArray;

      if ((0, _basketProduct.isBasketProduct)(this.args.product)) {
        var _Ember$get$filter, _Ember$get;

        return (// eslint-disable-next-line ember/no-get
          (_Ember$get$filter = (_Ember$get = Ember.get(this.args.product, 'basketChoices')) == null ? void 0 : _Ember$get.filter(bc => bc.displayInBasket && !bc.isNew)) != null ? _Ember$get$filter : []
        );
      }

      return (// eslint-disable-next-line ember/no-get
        (_Ember$get$toArray = Ember.get(this.args.product, 'favoriteChoices').toArray()) != null ? _Ember$get$toArray : []
      );
    }

    get shownChoices() {
      const choices = this.displayableChoices;
      return this.isExpanded ? choices : choices.slice(0, COUNT_SHOWN_BY_DEFAULT);
    }

    get hiddenChoiceCount() {
      return this.displayableChoices.length - COUNT_SHOWN_BY_DEFAULT;
    }

    get showToggleChoicesButton() {
      return this.displayableChoices.length > COUNT_SHOWN_BY_DEFAULT;
    }

    get quantity() {
      return (0, _basketProduct.isBasketProduct)(this.args.product) ? this.args.product.savedAttributes.quantity : this.args.product.quantity;
    }

    get recipientName() {
      return (0, _basketProduct.isBasketProduct)(this.args.product) ? this.args.product.savedAttributes.recipientName : this.args.product.recipient;
    }

    get specialInstructions() {
      return (0, _basketProduct.isBasketProduct)(this.args.product) ? this.args.product.savedAttributes.specialInstructions : this.args.product.specialInstructions;
    } // Constructor
    // Other methods


    *transition({
      insertedSprites,
      removedSprites
    }) {
      for (const sprite of insertedSprites) {
        sprite.startTranslatedBy(0, 30);
        (0, _opacity.fadeIn)(sprite, {
          duration: 200
        });
        (0, _move.default)(sprite, {
          duration: 0
        });
      }

      for (const sprite of removedSprites) {
        (0, _opacity.fadeOut)(sprite, {
          duration: 100
        });
      }
    } // Tasks


    *removeProductTaskInstance(product) {
      const vendorSlug = product.vendor.get('slug');
      const basketChoices = product.basketChoices.toArray();
      yield product.destroyRecord();

      for (const choice of basketChoices) {
        yield choice.unloadRecord();
      }

      this.globalEvents.trigger(_globalEvents.GlobalEventName.RemoveFromCart, product.serializeForGlobalData());
      const currentRoute = this.router.currentRoute;

      if (currentRoute.name === 'basket.basket-products.edit' && currentRoute.params.basket_product_id === product.id) {
        this.router.transitionTo('menu.vendor', vendorSlug);
      }

      const message = this.intl.t('mwc.notifications.removed', {
        quantity: product.quantity
      });
      this.notifications.success({
        message,
        type: _notifications.NotificationType.ProductRemoved
      });
    } // Actions


    toggleChoices() {
      this.isExpanded = !this.isExpanded;
    }

    editProduct() {
      if (this.editable) {
        var _this$args$onClose, _this$args, _this$args$onEdit, _this$args2;

        (_this$args$onClose = (_this$args = this.args).onClose) == null ? void 0 : _this$args$onClose.call(_this$args);
        (_this$args$onEdit = (_this$args2 = this.args).onEdit) == null ? void 0 : _this$args$onEdit.call(_this$args2, this.args.product);
      }
    }

    editProductWithKey(event) {
      if ((0, _keys.isToggleKey)(event)) {
        this.editProduct();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "features", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "vendor", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "globalEvents", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isExpanded", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "removeProductTaskInstance", [_emberConcurrency.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "removeProductTaskInstance"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleChoices", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "toggleChoices"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editProduct", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "editProduct"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editProductWithKey", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "editProductWithKey"), _class.prototype)), _class));
  _exports.default = CartProduct;
});