define("mobile-web/components/r-modal-section/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "title": "_title_x6j6xq",
    "container": "_container_x6j6xq",
    "noTitle": "_noTitle_x6j6xq",
    "content": "_content_x6j6xq"
  };
  _exports.default = _default;
});