define("mobile-web/controllers/menu/vendor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MenuVendorController = (_dec = Ember.inject.service, _dec2 = Ember.computed('router.currentRouteName'), _dec3 = Ember.computed('isProductPage', 'router.currentRoute.attributes'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, (_class = class MenuVendorController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      this.queryParams = ['showInfoModal', 'handoff', {
        openCriteria: 'open-criteria'
      }];

      _initializerDefineProperty(this, "showInfoModal", _descriptor2, this);

      _initializerDefineProperty(this, "handoff", _descriptor3, this);

      _initializerDefineProperty(this, "openCriteria", _descriptor4, this);

      _initializerDefineProperty(this, "skipPreCheck", _descriptor5, this);
    }

    get isProductPage() {
      return this.router.currentRouteName === 'menu.vendor.products';
    }

    get isFullScreenProductPage() {
      var _routeModel$displayIn;

      if (!this.isProductPage) {
        return false;
      } // @ts-ignore - Ember DefinitelyTyped types are incomplete here. The router.currentRoute
      // property here _should be_ RouteInfo | RouteInfoWithAttributes, but is instead just
      // RouteInfo, so it lacks `.attributes`. This could be fixed with a upstream type change,
      // but for now just gonna go with the ts-ignore fix.


      const routeModel = this.router.currentRoute.attributes;
      const displayInModal = (_routeModel$displayIn = routeModel == null ? void 0 : routeModel.displayInModal) != null ? _routeModel$displayIn : false;
      return !displayInModal;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isProductPage", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isProductPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isFullScreenProductPage", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isFullScreenProductPage"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showInfoModal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "handoff", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "openCriteria", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "skipPreCheck", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  })), _class));
  _exports.default = MenuVendorController;
});