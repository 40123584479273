define("mobile-web/components/r-link-rewards/button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xhh4R6w2",
    "block": "[[[41,[30,0,[\"show\"]],[[[1,\"  \"],[11,0],[24,\"data-test-linkRewards-button\",\"\"],[17,1],[12],[1,\"\\n\"],[41,[28,[37,1],[\"isDesktop\"],null],[[[1,\"      \"],[8,[39,2],null,[[\"@label\",\"@icon\",\"@onClick\",\"@testSelector\"],[[28,[37,3],null,[[\"text\",\"size\"],[[30,0,[\"buttonText\"]],\"small\"]]],[28,[37,3],null,[[\"svg\",\"ariaLabel\",\"position\"],[\"r-star\",\"\",\"left\"]]],[30,0,[\"onClick\"]],\"rewardButton\"]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,4],null,[[\"@variant\",\"@testSelector\",\"@fullWidth\",\"@onClick\"],[\"minor\",\"rewardButton\",true,[30,0,[\"onClick\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[15,0,[30,0,[\"style\",\"buttonContent\"]]],[12],[1,\"\\n          \"],[8,[39,5],null,[[\"@ariaLabel\",\"@icon\",\"@class\"],[\"\",\"r-star\",[30,0,[\"style\",\"starIcon\"]]]],null],[1,\"\\n          \"],[1,[30,0,[\"buttonText\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"&attrs\"],false,[\"if\",\"media\",\"r-header-button\",\"hash\",\"r-button\",\"r-svg\"]]",
    "moduleName": "mobile-web/components/r-link-rewards/button/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});