define("mobile-web/lib/computed/local", ["exports", "mobile-web/config/environment", "mobile-web/lib/utilities/is-some", "mobile-web/lib/utilities/local-storage"], function (_exports, _environment, _isSome, _localStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.local = local;
  _exports.default = void 0;
  const {
    localStorageNameSpace
  } = _environment.default;

  function local(targetOrOptions, propertyKey) {
    if ((0, _isSome.default)(propertyKey)) {
      // called without options
      return localDecorator(targetOrOptions, propertyKey);
    } // called with options


    const decorator = (target, key) => {
      const ls = (0, _localStorage.getLocalStorage)();

      if (typeof key === 'string') {
        const options = targetOrOptions;

        if (Ember.isNone(ls.getItem(`${localStorageNameSpace}${key}`)) && (0, _isSome.default)(options == null ? void 0 : options.defaultValue)) {
          ls.setItem(`${localStorageNameSpace}${key}`, JSON.stringify(options == null ? void 0 : options.defaultValue));
        }
      }

      return localDecorator(target, key);
    };

    return decorator;
  }

  function localDecorator(target, key) {
    if (typeof key === 'string') {
      Object.defineProperty(target, key, {
        get() {
          const ls = (0, _localStorage.getLocalStorage)();

          try {
            const value = ls.getItem(`${localStorageNameSpace}${key}`);
            return value ? JSON.parse(value) : null; // eslint-disable-line no-null/no-null
          } catch (e) {
            return undefined;
          }
        },

        set(value) {
          const ls = (0, _localStorage.getLocalStorage)();

          try {
            if (Ember.isNone(value)) {
              ls.removeItem(`${localStorageNameSpace}${key}`);
            } else {
              ls.setItem(`${localStorageNameSpace}${key}`, JSON.stringify(value));
            }

            this.notifyPropertyChange(`${key}`);
            return true;
          } catch (e) {
            return false;
          }
        }

      });
    } else {
      (false && !(false) && Ember.assert('decorator key should be a string, but was ' + typeof key));
    } // Returning a descriptor is required for stage-2 decorators, even if it is just an empty object
    // Without the descriptor, this decorator will not work properly.


    return {};
  }

  var _default = local;
  _exports.default = _default;
});