define("mobile-web/models/basket-product", ["exports", "ember-data", "ember-concurrency", "ember-concurrency-ts", "lodash.pick", "mobile-web/decorators/saved-attributes", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some", "mobile-web/lib/utilities/numeric", "mobile-web/services/analytics"], function (_exports, _emberData, _emberConcurrency, _emberConcurrencyTs, _lodash, _savedAttributes, _2, _isSome, _numeric, _analytics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.combineMatchingBasketProductsForEcommerce = combineMatchingBasketProductsForEcommerce;
  _exports.isBasketProduct = isBasketProduct;
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  let BasketProductModel = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('basket'), _dec3 = Ember.inject.service, _dec4 = _emberData.default.attr('string'), _dec5 = _emberData.default.attr('string'), _dec6 = _emberData.default.attr('string'), _dec7 = _emberData.default.attr('string'), _dec8 = _emberData.default.attr('number'), _dec9 = _emberData.default.attr('number'), _dec10 = _emberData.default.attr('number'), _dec11 = _emberData.default.attr('number'), _dec12 = _emberData.default.attr('string'), _dec13 = _emberData.default.attr('array', {
    defaultValue: () => []
  }), _dec14 = _emberData.default.belongsTo('vendor', {
    async: false
  }), _dec15 = _emberData.default.belongsTo('basket', {
    async: false
  }), _dec16 = _emberData.default.hasMany('basket-choice', {
    async: false
  }), _dec17 = _emberData.default.belongsTo('product'), _dec18 = Ember.computed.alias('product.optionGroups'), _dec19 = Ember.computed('product.baseCost', 'basketChoices.@each.quantity'), (_class = class BasketProductModel extends _emberData.default.Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "basketService", _descriptor2, this);

      _initializerDefineProperty(this, "challenge", _descriptor3, this);

      _initializerDefineProperty(this, "productName", _descriptor4, this);

      _initializerDefineProperty(this, "categoryName", _descriptor5, this);

      _initializerDefineProperty(this, "recipientName", _descriptor6, this);

      _initializerDefineProperty(this, "specialInstructions", _descriptor7, this);

      _initializerDefineProperty(this, "quantity", _descriptor8, this);

      _initializerDefineProperty(this, "menuViewId", _descriptor9, this);

      _initializerDefineProperty(this, "totalCost", _descriptor10, this);

      _initializerDefineProperty(this, "unitCost", _descriptor11, this);

      _initializerDefineProperty(this, "customizeDescription", _descriptor12, this);

      _initializerDefineProperty(this, "customValues", _descriptor13, this);

      _initializerDefineProperty(this, "vendor", _descriptor14, this);

      _initializerDefineProperty(this, "basket", _descriptor15, this);

      _initializerDefineProperty(this, "basketChoices", _descriptor16, this);

      _initializerDefineProperty(this, "product", _descriptor17, this);

      _initializerDefineProperty(this, "savedAttributes", _descriptor18, this);

      _initializerDefineProperty(this, "optionGroups", _descriptor19, this);
    }

    get canSubmit() {
      var _this$product$content, _this$product$content2;

      return this.quantity > 0 && ((_this$product$content = (_this$product$content2 = this.product.content) == null ? void 0 : _this$product$content2.firstLevelOptionGroupModels.every(og => og.canSubmit)) != null ? _this$product$content : true);
    }

    get estimatedUnitCost() {
      var _this$product$get, _ogs$reduce;

      const baseCost = (_this$product$get = this.product.get('baseCost')) != null ? _this$product$get : 0;
      const ogs = this.product.get('firstLevelOptionGroupModels');
      return (_ogs$reduce = ogs == null ? void 0 : ogs.reduce((sum, og) => sum + og.selectedChoiceCost, baseCost)) != null ? _ogs$reduce : baseCost;
    }

    setQuantityBasedOnChoiceQuantities() {
      var _this$product$content3;

      const firstQuantityOg = (_this$product$content3 = this.product.content) == null ? void 0 : _this$product$content3.primaryProductQuantityOptionGroup;

      if (firstQuantityOg) {
        this.quantity = firstQuantityOg.aggregateQuantity;
      }
    }

    serializeForEcommerce(orderId) {
      const sku = this.belongsTo('product').id() + '';
      const name = this.productName;
      const category = this.categoryName;
      const price = this.unitCost ? this.unitCost : 0.0;
      const quantity = this.quantity;
      const id = orderId + '';
      return {
        id,
        sku,
        name,
        category,
        price,
        quantity
      };
    }

    serializeForGlobalData() {
      var _this$product$content4;

      return _extends({}, (0, _lodash.default)(this, 'productName', 'categoryName', 'recipientName', 'specialInstructions', 'quantity', 'menuViewId', 'totalCost', 'unitCost', 'customizeDescription'), {
        // eslint-disable-next-line ember/no-get, ember/classic-decorator-no-classic-methods
        product: this.get('isLoaded') && ((_this$product$content4 = this.product.content) == null ? void 0 : _this$product$content4.serializeForGlobalData())
      });
    }

    *saveTask(options) {
      yield (0, _emberConcurrencyTs.taskFor)(this.challenge.request).perform(async () => {
        this.basketService.set('disableAdd', true);

        if (!this.basketService.basket) {
          await this.basketService.createBasket();
        }

        try {
          await this.save();
          this.analytics.trackEvent(_analytics.AnalyticsEvents.AddToCart, () => {
            var _this$product$get2, _this$optionGroups, _this$product$get3, _this$product$get4;

            return {
              [_analytics.AnalyticsProperties.ProductName]: this.product.get('name'),
              [_analytics.AnalyticsProperties.ProductCategory]: (_this$product$get2 = this.product.get('category')) == null ? void 0 : _this$product$get2.name,
              [_analytics.AnalyticsProperties.ProductQuantity]: this.quantity,
              [_analytics.AnalyticsProperties.ProductBasePrice]: this.product.get('baseCost'),
              [_analytics.AnalyticsProperties.ProductAvailableOptionGroupCount]: (0, _numeric.numberOr0)((_this$optionGroups = this.optionGroups) == null ? void 0 : _this$optionGroups.length),
              [_analytics.AnalyticsProperties.AddToCartMethod]: options.eventName,
              [_analytics.AnalyticsProperties.HasVisibleCalories]: (0, _isSome.default)(this.vendor.settings.showCalories) && (0, _isSome.default)(this.product.get('calorieLabel')),
              [_analytics.AnalyticsProperties.VisibleLabels]: (_this$product$get3 = this.product.get('labels')) == null ? void 0 : _this$product$get3.map(l => l.name),
              [_analytics.AnalyticsProperties.HasProductImages]: !(0, _2.isEmpty)(this.product.get('images')),
              [_analytics.AnalyticsProperties.HasCategoryImages]: !(0, _2.isEmpty)((_this$product$get4 = this.product.get('category')) == null ? void 0 : _this$product$get4.images),
              [_analytics.AnalyticsProperties.IsFeatured]: this.product.get('isFeatured')
            };
          });
          options.onSuccess == null ? void 0 : options.onSuccess();
        } finally {
          this.basketService.set('disableAdd', false);
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "basketService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "challenge", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "productName", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "categoryName", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "recipientName", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "specialInstructions", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "quantity", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "menuViewId", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "totalCost", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "unitCost", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "customizeDescription", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "customValues", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "vendor", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "basketChoices", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "product", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "savedAttributes", [_savedAttributes.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "optionGroups", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "estimatedUnitCost", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "estimatedUnitCost"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveTask", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveTask"), _class.prototype)), _class));
  _exports.default = BasketProductModel;

  // Needed because GA does not like when multiple products have the same SKU (applies to analytics.js and ga.js)
  // https://developers.google.com/analytics/devguides/collection/gajs/methods/gaJSApiEcommerce#_additem:~:text=This%20method%20performs%20no%20additional%20calculations%2C%20such%20as%20quantity%20calculations.
  function combineMatchingBasketProductsForEcommerce(basketProducts) {
    const combinedBasketProducts = basketProducts.reduce((acc, bp) => {
      if (!bp) return acc;
      const cur = acc[bp.sku]; // No basket product with this ID, store it an continue

      if (!cur) {
        acc[bp.sku] = bp;
        return acc;
      } // Combine this basket product with the last one
      // `price` is a per-unit price, and because different instances of the same SKU
      // can have different prices (due to modifiers being added), we do our best
      // and find an average price per unit so that the Google Analytics revenue is correct.
      // Calculate the new average price by taking the current price and quantity for this item,
      // multiplying it out to the total cost, adding the total cost of the new item, and averaging
      // both the existing total cost and the new total cost by the new quantity and rounding to two decimal places.


      const newQuantity = cur.quantity + bp.quantity;
      const newAveragePrice = Math.round(100 * ((cur.price * cur.quantity + bp.price * bp.quantity) / newQuantity)) / 100;
      acc[bp.sku] = _extends({}, cur, {
        price: newAveragePrice,
        quantity: newQuantity
      });
      return acc;
    }, {});
    return Object.keys(combinedBasketProducts).map(k => _extends({}, combinedBasketProducts[k], {
      quantity: combinedBasketProducts[k].quantity.toFixed(0),
      price: combinedBasketProducts[k].price.toFixed(2)
    }));
  }

  function isBasketProduct(product) {
    return 'totalCost' in product;
  }
});