define("mobile-web/components/-private/modal-dialog", ["exports", "ember-modal-dialog/components/modal-dialog", "mobile-web/components/r-application-frame/component", "mobile-web/lib/utilities/keys"], function (_exports, _modalDialog, _component, _keys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ModalDialog = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class ModalDialog extends _modalDialog.default.extend({
    init() {
      this._super(...arguments);

      this.containerClassNames = this.containerClassNames.concat(Ember.guidFor(this));
    },

    didInsertElement(...args) {
      this._super(...args);

      const modal = {
        element: this.modalEl,
        lastFocus: document.activeElement
      };

      if (this.onClose) {
        modal.onClose = (0, _keys.bindActionToKeyEvent)(_keys.Key.Escape, this.onClose);
      }

      this.modalStack.push(modal);
      Ember.run.next(this, () => this.initialize(modal));
    },

    initialize(modal, focus) {
      var _document$getElementB, _this$modalStack$prev;

      (_document$getElementB = document.getElementById(_component.APPLICATION_FRAME_ID)) == null ? void 0 : _document$getElementB.setAttribute('aria-hidden', 'true');
      this.focus.addTrap(modal.element);

      if ((_this$modalStack$prev = this.modalStack.previous) != null && _this$modalStack$prev.onClose) {
        document.removeEventListener('keydown', this.modalStack.previous.onClose, true);
      }

      if (modal.onClose) {
        document.addEventListener('keydown', modal.onClose, true);
      }

      modal.element.setAttribute('tabindex', '0');
      this.focus.focus(focus != null ? focus : modal.element);
    },

    willDestroyElement() {
      this._super(...arguments);

      const modal = this.modalStack.pop();

      if (this.modalStack.length === 0) {
        var _document$getElementB2;

        (_document$getElementB2 = document.getElementById(_component.APPLICATION_FRAME_ID)) == null ? void 0 : _document$getElementB2.removeAttribute('aria-hidden');
      }

      this.focus.removeTrap(modal.element);

      if (modal.onClose) {
        document.removeEventListener('keydown', modal.onClose, true);
      }

      if (this.modalStack.top) {
        this.initialize(this.modalStack.top, modal.lastFocus);
      } else {
        this.focus.focus(modal.lastFocus);
      }
    }

  }) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "focus", _descriptor, this);

      _initializerDefineProperty(this, "modalStack", _descriptor2, this);
    }

    get modalSelector() {
      return `.${Ember.guidFor(this)}`;
    }

    get modalEl() {
      return document.querySelector(this.modalSelector);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "focus", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modalStack", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ModalDialog;
});