define("mobile-web/services/image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const isOldIE = i => i.hasOwnProperty('attachEvent');

  class ImageService extends Ember.Service {
    load(filename) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        if (!filename) {
          reject('no image file name specified');
          return;
        }

        const img = new Image();

        const loaded = () => resolve(img);

        const failed = () => reject('image failed to load');

        if (isOldIE(img)) {
          img.attachEvent('onload', loaded);
          img.attachEvent('onerror', failed);
        } else {
          img.addEventListener('load', loaded, true);
          img.addEventListener('error', failed, true);
        }

        img.src = filename; // image is cached

        if (img.complete || img.readyState === 4) {
          loaded();
        }
      });
    }

  }

  _exports.default = ImageService;
});