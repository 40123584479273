define("mobile-web/lib/utilities/http", ["exports", "mobile-web/lib/utilities/is-some"], function (_exports, _isSome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.displayUrl = _exports.addClass = _exports.ensureProtocol = _exports.isStatus = _exports.Status = void 0;
  let Status;
  _exports.Status = Status;

  (function (Status) {
    Status[Status["Continue"] = 100] = "Continue";
    Status[Status["SwitchingProtocols"] = 101] = "SwitchingProtocols";
    Status[Status["Ok"] = 200] = "Ok";
    Status[Status["Created"] = 201] = "Created";
    Status[Status["Accepted"] = 202] = "Accepted";
    Status[Status["NonAuthoritativeInformation"] = 203] = "NonAuthoritativeInformation";
    Status[Status["NoContent"] = 204] = "NoContent";
    Status[Status["ResetContent"] = 205] = "ResetContent";
    Status[Status["PartialContent"] = 206] = "PartialContent";
    Status[Status["MultipleChoices"] = 300] = "MultipleChoices";
    Status[Status["MovedPermanently"] = 301] = "MovedPermanently";
    Status[Status["Found"] = 302] = "Found";
    Status[Status["SeeOther"] = 303] = "SeeOther";
    Status[Status["NotModified"] = 304] = "NotModified";
    Status[Status["UseProxy"] = 305] = "UseProxy";
    Status[Status["TemporaryRedirect"] = 307] = "TemporaryRedirect";
    Status[Status["BadRequest"] = 400] = "BadRequest";
    Status[Status["Unauthorized"] = 401] = "Unauthorized";
    Status[Status["PaymentRequired"] = 402] = "PaymentRequired";
    Status[Status["Forbidden"] = 403] = "Forbidden";
    Status[Status["NotFound"] = 404] = "NotFound";
    Status[Status["MethodNotAllowed"] = 405] = "MethodNotAllowed";
    Status[Status["NotAcceptable"] = 406] = "NotAcceptable";
    Status[Status["ProxyAuthenticationRequired"] = 407] = "ProxyAuthenticationRequired";
    Status[Status["RequestTimeout"] = 408] = "RequestTimeout";
    Status[Status["Conflict"] = 409] = "Conflict";
    Status[Status["Gone"] = 410] = "Gone";
    Status[Status["LengthRequired"] = 411] = "LengthRequired";
    Status[Status["PreconditionFailed"] = 412] = "PreconditionFailed";
    Status[Status["RequestEntityTooLarge"] = 413] = "RequestEntityTooLarge";
    Status[Status["RequestUriTooLong"] = 414] = "RequestUriTooLong";
    Status[Status["UnsupportedMediaType"] = 415] = "UnsupportedMediaType";
    Status[Status["RequestedRangeNotSatisfiable"] = 416] = "RequestedRangeNotSatisfiable";
    Status[Status["ExpectationFailed"] = 417] = "ExpectationFailed";
    Status[Status["UnprocessableEntity"] = 422] = "UnprocessableEntity";
    Status[Status["TooManyRequests"] = 429] = "TooManyRequests";
    Status[Status["InternalServerError"] = 500] = "InternalServerError";
    Status[Status["NotImplemented"] = 501] = "NotImplemented";
    Status[Status["BadGateway"] = 502] = "BadGateway";
    Status[Status["ServiceUnavailable"] = 503] = "ServiceUnavailable";
    Status[Status["GatewayTimeout"] = 504] = "GatewayTimeout";
    Status[Status["HttpVersionNotSupported"] = 505] = "HttpVersionNotSupported";
  })(Status || (_exports.Status = Status = {}));

  const isStatus = (status, toCheck) => toCheck.toString() === status.toString();

  _exports.isStatus = isStatus;

  const ensureProtocol = url => {
    const protocol = /^(https?:)?\/\//;
    return protocol.test(url) ? url : `https://${url}`;
  };

  _exports.ensureProtocol = ensureProtocol;

  const addClass = (selector, className) => {
    const element = document.querySelector(selector);

    if ((0, _isSome.default)(element) && !Ember.isEmpty(className)) {
      element.classList.add(...className.split(' '));
    }
  };

  _exports.addClass = addClass;

  const displayUrl = url => {
    try {
      return new URL(url).host.replace('www.', '');
    } catch (_unused) {
      return '';
    }
  };

  _exports.displayUrl = displayUrl;
});