define("mobile-web/adapters/order-submission", ["exports", "mobile-web/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class OrderAubmissionAdapter extends _application.default {
    pathForType() {
      return 'orders';
    }

  }

  _exports.default = OrderAubmissionAdapter;
});