define("mobile-web/services/session", ["exports", "ember-concurrency-ts", "mobile-web/config/environment", "mobile-web/lib/computed", "mobile-web/lib/country", "mobile-web/lib/errors", "mobile-web/lib/hybrid-util", "mobile-web/lib/perimeterx", "mobile-web/lib/routing", "mobile-web/lib/utilities/classes", "mobile-web/lib/utilities/http", "mobile-web/plugins/serve-apple-signin", "mobile-web/services/device", "mobile-web/services/global-events"], function (_exports, _emberConcurrencyTs, _environment, _computed, _country, _errors, _hybridUtil, _perimeterx, _routing, _classes, _http, _serveAppleSignin, _device, _globalEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SessionService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember.inject.service, _dec11 = Ember.inject.service, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember.computed('bootstrap.data.guestUser', 'localGuestUser'), _dec17 = Ember.computed('nextRoute'), (_class = class SessionService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "bootstrap", _descriptor, this);

      _initializerDefineProperty(this, "challenge", _descriptor2, this);

      _initializerDefineProperty(this, "channel", _descriptor3, this);

      _initializerDefineProperty(this, "device", _descriptor4, this);

      _initializerDefineProperty(this, "error", _descriptor5, this);

      _initializerDefineProperty(this, "globalEvents", _descriptor6, this);

      _initializerDefineProperty(this, "intl", _descriptor7, this);

      _initializerDefineProperty(this, "router", _descriptor8, this);

      _initializerDefineProperty(this, "storage", _descriptor9, this);

      _initializerDefineProperty(this, "store", _descriptor10, this);

      _initializerDefineProperty(this, "window", _descriptor11, this);

      _initializerDefineProperty(this, "localGuestUser", _descriptor12, this);

      _initializerDefineProperty(this, "nextRoute", _descriptor13, this);

      _initializerDefineProperty(this, "lastOrderId", _descriptor14, this);

      _initializerDefineProperty(this, "isEditingOrder", _descriptor15, this);

      _initializerDefineProperty(this, "editedOrderFromCheckout", _descriptor16, this);

      _initializerDefineProperty(this, "savedNewCard", _descriptor17, this);

      _initializerDefineProperty(this, "usedSavedCard", _descriptor18, this);

      _initializerDefineProperty(this, "viewedCustomFeesTooltip", _descriptor19, this);

      _initializerDefineProperty(this, "loginTracked", _descriptor20, this);

      _initializerDefineProperty(this, "currentUser", _descriptor21, this);

      _initializerDefineProperty(this, "src", _descriptor22, this);

      _initializerDefineProperty(this, "restrictedFlow", _descriptor23, this);

      _initializerDefineProperty(this, "serveAppToken", _descriptor24, this);
    }

    // Getters and setters
    get guestUser() {
      var _this$bootstrap$data;

      const bootstrapGuestUser = (_this$bootstrap$data = this.bootstrap.data) == null ? void 0 : _this$bootstrap$data.guestUser;
      const localGuestUser = this.localGuestUser;
      return bootstrapGuestUser != null ? bootstrapGuestUser : localGuestUser;
    }

    get editAccountUrl() {
      var _this$bootstrap$data$, _this$bootstrap$data2;

      return (_this$bootstrap$data$ = (_this$bootstrap$data2 = this.bootstrap.data) == null ? void 0 : _this$bootstrap$data2.editAccountUrl) != null ? _this$bootstrap$data$ : undefined;
    }

    get registerAccountUrl() {
      var _this$bootstrap$data$2, _this$bootstrap$data3;

      return (_this$bootstrap$data$2 = (_this$bootstrap$data3 = this.bootstrap.data) == null ? void 0 : _this$bootstrap$data3.registerAccountUrl) != null ? _this$bootstrap$data$2 : undefined;
    }

    get isEmbeddedLevelUp() {
      var _this$bootstrap$data$3, _this$bootstrap$data4;

      return (_this$bootstrap$data$3 = (_this$bootstrap$data4 = this.bootstrap.data) == null ? void 0 : _this$bootstrap$data4.isEmbeddedLevelUp) != null ? _this$bootstrap$data$3 : false;
    }

    get isSsoLogin() {
      var _this$bootstrap$data$4, _this$bootstrap$data5;

      return (_this$bootstrap$data$4 = (_this$bootstrap$data5 = this.bootstrap.data) == null ? void 0 : _this$bootstrap$data5.isSsoLogin) != null ? _this$bootstrap$data$4 : false;
    }

    get isFacebook() {
      var _this$bootstrap$data$5, _this$bootstrap$data6;

      return (_this$bootstrap$data$5 = (_this$bootstrap$data6 = this.bootstrap.data) == null ? void 0 : _this$bootstrap$data6.isFacebook) != null ? _this$bootstrap$data$5 : false;
    }

    get currentCountry() {
      var _ref, _this$bootstrap$data7;

      return (_ref = (_this$bootstrap$data7 = this.bootstrap.data) == null ? void 0 : _this$bootstrap$data7.currentCountry) != null ? _ref : _country.Code.US;
    }

    get mixpanelUniqueId() {
      var _this$bootstrap$data8;

      return (_this$bootstrap$data8 = this.bootstrap.data) == null ? void 0 : _this$bootstrap$data8.mixpanelUniqueId;
    }

    get isRestrictedFlow() {
      var _this$bootstrap$data9;

      return ((_this$bootstrap$data9 = this.bootstrap.data) == null ? void 0 : _this$bootstrap$data9.isRestrictedFlow) || this.restrictedFlow;
    }

    get isEmbeddedMode() {
      var _this$bootstrap$data10, _this$src;

      return ((_this$bootstrap$data10 = this.bootstrap.data) == null ? void 0 : _this$bootstrap$data10.isEmbeddedMode) || ((_this$src = this.src) == null ? void 0 : _this$src.toLowerCase()) === 'fb';
    }

    get isLoggedIn() {
      return !!this.currentUser;
    }

    get className() {
      const classNames = (0, _classes.classes)({
        embedded: this.isEmbeddedMode,
        'embedded-levelUp': this.isEmbeddedLevelUp,
        'sso-login': this.isSsoLogin,
        'facebook-referrer': this.isFacebook,
        'logged-in': this.isLoggedIn
      });
      (0, _http.addClass)('body', classNames);
      return classNames;
    }

    get user() {
      var _this$currentUser;

      return (_this$currentUser = this.currentUser) != null ? _this$currentUser : this.guestUser;
    }

    get referrer() {
      if (Ember.isNone(this.nextRoute)) {
        return '/';
      }

      try {
        const routeName = this.nextRoute[0];
        const params = this.nextRoute.slice(1);
        return this.router.urlFor(routeName, ...params);
      } catch (e) {
        return '/';
      }
    }

    get currentChannel() {
      return this.channel.current;
    }

    get channelSettings() {
      var _this$currentChannel;

      return (_this$currentChannel = this.currentChannel) == null ? void 0 : _this$currentChannel.settings;
    }

    get loginProviders() {
      var _this$currentChannel2;

      return (_this$currentChannel2 = this.currentChannel) == null ? void 0 : _this$currentChannel2.loginProviders;
    }

    get supportsOloLogin() {
      var _this$channelSettings;

      return (_this$channelSettings = this.channelSettings) == null ? void 0 : _this$channelSettings.supportsOloLogin;
    }

    get useThirdPartyLogin() {
      var _this$channelSettings2;

      return (_this$channelSettings2 = this.channelSettings) == null ? void 0 : _this$channelSettings2.useThirdPartyLogin;
    }

    get hasMultipleLoginProviders() {
      var _this$loginProviders$, _this$loginProviders;

      return ((_this$loginProviders$ = (_this$loginProviders = this.loginProviders) == null ? void 0 : _this$loginProviders.length) != null ? _this$loginProviders$ : 0) > 1;
    }

    get hasThirdPartyRegistration() {
      return !!this.useThirdPartyLogin && !!this.registerAccountUrl;
    }

    get hasLoginProvider() {
      var _this$loginProviders2;

      return !!((_this$loginProviders2 = this.loginProviders) != null && _this$loginProviders2.length);
    }

    get signOnAllowed() {
      var _this$supportsOloLogi;

      return this.hasLoginProvider || ((_this$supportsOloLogi = this.supportsOloLogin) != null ? _this$supportsOloLogi : false);
    }

    get internalSignOnAllowed() {
      return this.supportsOloLogin || this.useThirdPartyLogin || this.hasMultipleLoginProviders;
    }

    get createAccountAllowed() {
      return this.supportsOloLogin || this.hasThirdPartyRegistration;
    }

    get isExternalAccount() {
      return this.isSsoLogin || this.isEmbeddedMode || this.isFacebook;
    }

    get singleSso() {
      var _this$loginProviders3;

      return !this.supportsOloLogin && ((_this$loginProviders3 = this.loginProviders) == null ? void 0 : _this$loginProviders3.length) === 1;
    } // Methods


    async asyncInit() {
      if (this.device.isHybrid) {
        const token = await this.device.storageGet(_device.SERVE_APP_TOKEN_KEY);

        if (token) {
          this.serveAppToken = token;
        }
      }
    }

    async validateServeAppToken() {
      if (this.device.isHybrid) {
        var _this$bootstrap$data11;

        if (!((_this$bootstrap$data11 = this.bootstrap.data) != null && _this$bootstrap$data11.userId)) {
          await this.device.storageRemove(_device.SERVE_APP_TOKEN_KEY);
          this.serveAppToken = undefined;
        }
      }
    }

    setGuestUser(userModel) {
      Ember.set(this, 'localGuestUser', _extends({}, userModel, {
        optInToSms: true
      })); // If local storage isn't enabled, the above set will silently fail.
      // We need to use back end session state if that happens, which this
      // collectionAction does.

      return !this.localGuestUser ? this.store.collectionAction('user', 'setGuestUser', userModel).then(() => userModel) : Ember.RSVP.resolve(userModel);
    } // The act of reading `this.guestUser` loads guest user data from local storage


    loadGuestUser() {
      return this.guestUser;
    }

    async loadUser(userId) {
      // Models that are only for logged in users but should not block app load if unsuccessful
      const results = await Promise.all([this.store.findRecord('user', userId), this.safeLoadModels('loyalty-membership', 'mwc.errors.loadMembership'), this.safeLoadModels('address', 'mwc.errors.loadAddresses')]);
      this.currentUser = results[0];

      if (!this.loginTracked) {
        this.globalEvents.trigger(_globalEvents.GlobalEventName.UserLogin, this.serializeUserForGlobalData());
        this.loginTracked = true;
      }

      return results[0];
    }

    safeLoadModels(modelName, errorKey) {
      return this.store.findAll(modelName).catch(e => {
        if ((0, _perimeterx.asPerimeterXResponse)(e)) {
          throw e;
        }

        this.error.sendExternalError(e);
        this.error.sendUserMessage(this.intl.t(errorKey));
      });
    }

    async setUserFromPayload(user) {
      this.store.pushPayload('user', {
        user
      });

      if (this.device.isHybrid && user.serveAppToken) {
        await this.device.storageSet(_device.SERVE_APP_TOKEN_KEY, user.serveAppToken);
        this.serveAppToken = user.serveAppToken;
      }

      return this.loadUser(user.id);
    }

    async updateCountryCodeFromZip(zipCode) {
      if (zipCode && zipCode.toUpperCase().trim().match(/^[ABCEFGHJKLMNPRSTVXY][0-9][ABCEFGHJKLMNPRSTVWXYZ][0-9][ABCEFGHJKLMNPRSTVWXYZ][0-9]$/)) {
        // Unfortunately, the backend does not support setting the country code directly on the delivery address
        // Instead, it reads it off of the current Session data, which can be changed only by posting to a special route.
        await this.store.adapterFor('country').post(_country.Code.CA);
      } else {
        await this.store.adapterFor('country').post(_country.Code.US);
      }
    }

    externalLogin(providerSlug) {
      (0, _emberConcurrencyTs.taskFor)(this.challenge.request).perform(() => this.store.adapterFor('user').externalLogin(this.referrer, providerSlug, _environment.default.isHybrid ? 'serveapp' : undefined).then(async result => {
        if (!result) {
          throw new Error(`No response from ${providerSlug}`);
        } // Redirect to SSO. This flow will reload the page and therefore spin
        // the app back up, bootstrapping in new data supplied by the server.


        if (result.success) {
          if (_environment.default.isHybrid) {
            await this.handleHybridOauthFlow(result.data.url, this.referrer, providerSlug);
          } else {
            this.window.location().assign(result.data.url);
          }
        }
      }), (0, _errors.buildOloErrorHandler)('external-login-failure', detail => {
        this.error.sendUserMessage({
          detail: typeof detail === 'string' ? detail : undefined
        });
      }));
    }

    async handleNativeAppleSignin(providerSlug) {
      const signInResult = await _serveAppleSignin.default.handleSignIn();
      const {
        state
      } = (await this.store.adapterFor('user').externalLogin('', providerSlug)).data;
      return await (0, _hybridUtil.fetchNativeAppleLogin)(signInResult.appleIdToken, signInResult.givenName, signInResult.familyName, state);
    }

    async handleNativeOauthSignin(oauthUrl, providerSlug) {
      let oauthCallbackParts = {};

      if (this.device.isHybridIOS) {
        if (!oauthCallbackParts.state) {
          const oauthTokenString = await new Promise((resolve, reject) => window.plugins.ASWebAuthSession.start('serveapp', oauthUrl, resolve, reject));
          oauthCallbackParts = JSON.parse(atob(oauthTokenString.split(':')[1]));
        }
      } else if (this.device.isHybridAndroid) {
        // @ts-ignore
        const inAppBrowser = cordova.InAppBrowser;
        const oauthTokenString = await new Promise((resolve, reject) => {
          const instance = inAppBrowser.open(oauthUrl, '_blank', 'location=yes');
          instance.addEventListener('loadstart', e => {
            if (e && e.url.indexOf('http://serveapp:') === 0) {
              instance.close();
              resolve(e.url.split('http://')[1]);
            }
          });
          instance.addEventListener('exit', () => reject(''));
        });
        oauthCallbackParts = JSON.parse(atob(oauthTokenString.split(':')[1]));
      }

      if (!oauthCallbackParts.state || !oauthCallbackParts.code) {
        throw new Error('Missing data in callback');
      }

      return await (0, _hybridUtil.fetchOAuthCallback)(`${window.Olo.hybridAppHost}/user/oauthcallback?state=${oauthCallbackParts.state}&code=${oauthCallbackParts.code}&hybridCallbackComplete=true`, providerSlug === 'apple');
    }

    async handleHybridOauthFlow(oauthUrl, referrer, providerSlug) {
      try {
        var _oauthCallbackRespons;

        let oauthCallbackResponse = undefined;

        if (this.device.isHybridIOS && providerSlug === 'apple' && (await _serveAppleSignin.default.isAvailable()).result === 'true') {
          oauthCallbackResponse = await this.handleNativeAppleSignin(providerSlug);
        } else {
          oauthCallbackResponse = await this.handleNativeOauthSignin(oauthUrl, providerSlug);
        }

        if ((_oauthCallbackRespons = oauthCallbackResponse) != null && _oauthCallbackRespons.data) {
          const {
            serveAppToken
          } = JSON.parse(oauthCallbackResponse.data);
          await this.device.storageSet(_device.SERVE_APP_TOKEN_KEY, serveAppToken);
          this.serveAppToken = serveAppToken;
        }

        this.window.location().assign(referrer);
      } catch (e) {
        this.error.sendUserMessage({
          detail: 'Could not log in, please try again.'
        });
      }
    }

    transitionToLogin() {
      const loginRoute = 'secure.login';
      let args = (0, _routing.currentTransitionArgs)(this.router.currentRoute);

      if (args[0] === 'menu.vendor.index') {
        args = (0, _routing.currentTransitionArgs)(this.router.currentRoute.parent);
      }

      if (args[0] !== loginRoute) {
        Ember.set(this, 'nextRoute', args);
        this.router.transitionTo(loginRoute);
      }
    }

    internalLogin(email, password, onSuccess, onError) {
      (0, _emberConcurrencyTs.taskFor)(this.challenge.request).perform(() => this.store.adapterFor('user').login(email, password).then(data => this.setUserFromPayload(data)).then(onSuccess), e => {
        const message = this.handleCloudflareChallenge(e) || this.intl.t('mwc.errors.loginFailed');
        onError(message);
      });
    }

    handleCloudflareChallenge(e) {
      var _e$errors, _e$errors$;

      if ((e == null ? void 0 : (_e$errors = e.errors) == null ? void 0 : (_e$errors$ = _e$errors[0]) == null ? void 0 : _e$errors$.status) === '503') {
        // cloudflare block, page reload required.
        this.window.location().reload();
        return 'Please wait, reloading the page...';
      }

      return undefined;
    }

    async logout() {
      if (this.device.isHybrid) {
        await this.device.storageRemove(_device.SERVE_APP_TOKEN_KEY);
      }

      return this.store.adapterFor('user').logout().then(() => {
        this.currentUser = undefined;
        sessionStorage.clear();
      });
    }

    async refreshServeAppToken() {
      try {
        var _await$this$store$ada;

        if (!this.serveAppToken) {
          return;
        }

        const newToken = (_await$this$store$ada = await this.store.adapterFor('user').refreshToken(this.serveAppToken)) == null ? void 0 : _await$this$store$ada.serveAppToken;

        if (newToken) {
          await this.device.storageSet(_device.SERVE_APP_TOKEN_KEY, newToken);
          this.serveAppToken = newToken;
        }
      } catch (e) {}
    }

    serializeUserForGlobalData() {
      var _this$user;

      return {
        isLoggedIn: this.isLoggedIn,
        optIn: (_this$user = this.user) == null ? void 0 : _this$user.optIn,
        isEmbeddedLevelUp: this.isEmbeddedLevelUp,
        isSsoLogin: this.isSsoLogin,
        isFacebook: this.isFacebook,
        currentCountry: this.currentCountry,
        uniqueId: this.mixpanelUniqueId
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "bootstrap", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "challenge", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "device", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "error", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "globalEvents", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "window", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "localGuestUser", [_computed.local], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "nextRoute", [_computed.local], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "lastOrderId", [_computed.local], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "isEditingOrder", [_computed.session], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "editedOrderFromCheckout", [_computed.session], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "savedNewCard", [_computed.session], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "usedSavedCard", [_computed.session], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "viewedCustomFeesTooltip", [_computed.session], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "loginTracked", [_computed.session], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "src", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "restrictedFlow", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "serveAppToken", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "guestUser", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "guestUser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "referrer", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "referrer"), _class.prototype)), _class));
  _exports.default = SessionService;
});