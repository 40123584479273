define("mobile-web/components/r-header/component", ["exports", "@glimmer/component", "mobile-web/lib/data", "mobile-web/lib/utilities/is-some", "mobile-web/services/notifications", "mobile-web/components/r-notifications/component", "mobile-web/components/r-header/style"], function (_exports, _component, _data, _isSome, _notifications, _component2, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.State = _exports.CART_BUTTON_ID = _exports.HEADER_ID = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const HEADER_ID = 'olo-header';
  _exports.HEADER_ID = HEADER_ID;
  const CART_BUTTON_ID = 'olo-cart-button';
  _exports.CART_BUTTON_ID = CART_BUTTON_ID;
  let State;
  _exports.State = State;

  (function (State) {
    State["Default"] = "default";
    State["Home"] = "home";
    State["Checkout"] = "checkout";
  })(State || (_exports.State = State = {}));

  let Header = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, (_class = class Header extends _component.default {
    // Service injections
    // Passthroughs
    // Tracked properties
    // Getters and setters
    get isLoading() {
      return (0, _data.isLoading)(this.args.model) || this.router.currentRouteName === 'application_loading';
    }

    get showHome() {
      return (0, _data.isLoaded)(this.args.model) && !this.args.model.value.isRestrictedFlow && !this.args.model.value.isEmbedded && this.showLogo && !this.device.isHybrid;
    }

    get showLogo() {
      return (0, _data.isError)(this.args.model) || (0, _data.isLoaded)(this.args.model) && [State.Default, State.Home, State.Checkout].includes(this.args.model.value.state) && !this.args.model.value.isEmbedded;
    }

    get showRewards() {
      return (0, _data.isLoaded)(this.args.model) && !this.args.model.value.isRestrictedFlow && [State.Default, State.Home].includes(this.args.model.value.state) && this.media.isDesktop && this.router.currentRouteName !== 'menu.vendor.rewards' && !this.sideMenu.isEnabled;
    }

    get showAccount() {
      return (0, _data.isLoaded)(this.args.model) && !this.args.model.value.isRestrictedFlow && [State.Default, State.Home].includes(this.args.model.value.state) && this.args.model.value.signOnAllowed && !this.sideMenu.isEnabled;
    }

    get showRightSide() {
      return !(0, _data.isError)(this.args.model) && (this.showRewards || this.showAccount || this.showBasket);
    }

    get showBasket() {
      return !(0, _data.isLoaded)(this.args.model) || [State.Default, State.Home].includes(this.args.model.value.state) && !this.args.model.value.isRestrictedFlow;
    }

    get showMenu() {
      const menuRouteNames = ['index', 'vendor-search-results', 'menu.vendor.index', 'thank-you'];
      return this.sideMenu.isEnabled && (this.device.isWeb || this.device.isHybrid && menuRouteNames.includes(this.router.currentRouteName));
    }

    get showBack() {
      return this.sideMenu.isEnabled && !this.showMenu // this ensures will only happen on hybrid device
      ;
    }

    get showNotifications() {
      return !this.media.isMobile;
    }

    get notificationsClass() {
      return this.style.notifications;
    }

    get notificationsOrder() {
      return _component2.NotificationOrder.Descending;
    } // Constructor


    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "features", _descriptor, this);

      _initializerDefineProperty(this, "media", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _initializerDefineProperty(this, "sideMenu", _descriptor5, this);

      _initializerDefineProperty(this, "device", _descriptor6, this);

      this.headerId = HEADER_ID;
      this.cartButtonId = CART_BUTTON_ID;
      this.style = _style.default;
      (false && !((0, _isSome.isSome)(this.args.model)) && Ember.assert('`model` is required', (0, _isSome.isSome)(this.args.model)));
    } // Other methods


    notificationsFilter(notification) {
      switch (notification.type) {
        case _notifications.NotificationType.CartTransferred:
        case _notifications.NotificationType.ProductAdded:
          return true;

        default:
          return false;
      }
    } // Tasks
    // Actions


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "features", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "media", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sideMenu", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "device", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Header;
});