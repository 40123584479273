define("mobile-web/components/r-challenge-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PLnogOZI",
    "block": "[[[41,[30,0,[\"challenge\",\"isOpen\"]],[[[1,\"  \"],[8,[39,1],[[24,\"data-test-challengeModal\",\"\"]],[[\"@title\",\"@onClose\"],[[28,[37,2],[\"mwc.challenge.title\"],null],[30,0,[\"challenge\",\"close\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"section\"]],null,[[\"@class\"],[[30,0,[\"style\",\"container\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[11,0],[24,1,\"px-captcha\"],[4,[38,3],[[30,0,[\"challenge\",\"setup\"]]],null],[12],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"]],[]],null]],[\"modal\"],false,[\"if\",\"r-modal\",\"t\",\"did-insert\"]]",
    "moduleName": "mobile-web/components/r-challenge-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});