define("mobile-web/components/r-reward-card/component", ["exports", "@ember-decorators/component", "ember-concurrency", "ember-concurrency-ts", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-reward-card/style"], function (_exports, _component, _emberConcurrency, _emberConcurrencyTs, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let RewardCard = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.computed('basket.basket.hasCoupon'), _dec9 = Ember.computed('basket.basket.hasCoupon'), _dec10 = Ember._action, _dec(_class = (_class2 = class RewardCard extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      _initializerDefineProperty(this, "bus", _descriptor2, this);

      _initializerDefineProperty(this, "challenge", _descriptor3, this);

      _initializerDefineProperty(this, "error", _descriptor4, this);

      _initializerDefineProperty(this, "intl", _descriptor5, this);

      _initializerDefineProperty(this, "store", _descriptor6, this);

      this.showScheme = false;
      this.style = _style.default;
    }

    // Computed properties
    get removeDiscountTitle() {
      return this.basket.basket.hasCoupon ? 'mwc.rewardCard.removeCouponTitle' : 'mwc.rewardCard.removeRewardTitle';
    }

    get removeDiscountText() {
      return this.basket.basket.hasCoupon ? 'mwc.rewardCard.removeCouponText' : 'mwc.rewardCard.removeRewardText';
    } // Init


    init() {
      super.init();
      (false && !((0, _isSome.default)(this.reward)) && Ember.assert('`reward` is required', (0, _isSome.default)(this.reward)));
      (false && !((0, _isSome.default)(this.vendor)) && Ember.assert('`vendor` is required', (0, _isSome.default)(this.vendor)));
    } // Other methods
    // Tasks


    *applyRewardTask() {
      yield (0, _emberConcurrencyTs.taskFor)(this.challenge.request).perform(async () => {
        if (!this.basket.basket) {
          await this.basket.createBasket(this.vendor);
        }

        await this.basket.basket.applyReward({
          membershipId: this.reward.membershipId,
          reference: this.reward.externalReference
        });
        this.reward.set('isApplied', true);
        const basket = this.basket.basket;

        try {
          await basket.validate();
        } catch (e) {
          this.error.sendUserMessage(e);
          (0, _emberConcurrencyTs.taskFor)(this.removeRewardTask).perform();
        }
      });
    }

    *removeRewardTask() {
      const basket = this.basket.basket;
      const reward = basket.reward;

      if ((0, _isSome.default)(reward)) {
        yield basket.removeReward({
          membershipId: reward.id,
          reference: reward.externalReference
        });

        if (this.reward.externalReference === reward.externalReference) {
          this.reward.set('isApplied', false);
        } else {
          // Sorry
          const oldReward = this.store.peekAll('qualifying-loyalty-reward').find(r => r.externalReference === reward.externalReference);

          if ((0, _isSome.default)(oldReward)) {
            oldReward.set('isApplied', false);
          }
        }
      }
    }

    *removeCouponTask() {
      yield this.basket.basket.removeCoupon();
    }

    *removeAndApplyTask() {
      const basket = this.basket.basket;
      yield basket.hasCoupon ? (0, _emberConcurrencyTs.taskFor)(this.removeCouponTask).perform() : (0, _emberConcurrencyTs.taskFor)(this.removeRewardTask).perform();
      yield (0, _emberConcurrencyTs.taskFor)(this.applyRewardTask).perform();
    } // Actions


    applyReward() {
      const basket = this.basket.basket;

      if ((0, _isSome.default)(basket) && (basket.hasCoupon || basket.hasReward)) {
        this.bus.trigger('confirm', {
          title: this.intl.t(this.removeDiscountTitle),
          content: this.intl.t(this.removeDiscountText),
          buttonLabel: this.intl.t('mwc.rewardCard.confirmRemoveDiscountButton'),
          cancelLabel: this.intl.t('mwc.rewardCard.cancelRemoveDiscountButton'),
          onConfirm: () => (0, _emberConcurrencyTs.taskFor)(this.removeAndApplyTask).perform(),
          testSelector: 'removeDiscount',
          buttonTestSelector: 'confirmRemoveDiscount',
          cancelTestSelector: 'cancelRemoveDiscount'
        });
      } else {
        (0, _emberConcurrencyTs.taskFor)(this.applyRewardTask).perform();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "basket", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "bus", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "challenge", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "error", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "removeDiscountTitle", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "removeDiscountTitle"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeDiscountText", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "removeDiscountText"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "applyRewardTask", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class2.prototype, "applyRewardTask"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeRewardTask", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class2.prototype, "removeRewardTask"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeCouponTask", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class2.prototype, "removeCouponTask"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeAndApplyTask", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class2.prototype, "removeAndApplyTask"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "applyReward", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "applyReward"), _class2.prototype)), _class2)) || _class);
  _exports.default = RewardCard;
});