define("mobile-web/components/r-reward-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZspHfz3r",
    "block": "[[[8,[39,0],[[16,0,[28,[37,1],[[30,1],[30,0,[\"style\",\"cardContainer\"]]],null]]],[[\"@testSelector\"],[\"reward\"]],[[\"content\",\"buttons\"],[[[[1,\"\\n    \"],[10,0],[15,0,[30,0,[\"style\",\"card\"]]],[12],[1,\"\\n      \"],[10,2],[15,0,[30,0,[\"style\",\"label\"]]],[14,\"data-test-rewardCard-label\",\"\"],[12],[1,[30,2,[\"label\"]]],[13],[1,\"\\n\"],[41,[30,2,[\"expiresOn\"]],[[[1,\"        \"],[10,2],[15,0,[30,0,[\"style\",\"exp\"]]],[14,\"data-test-rewardCard-expDate\",\"\"],[12],[1,\"\\n          \"],[1,[28,[35,3],[\"mwc.rewardCard.expirationText\"],[[\"exp\"],[[28,[37,4],[[30,2,[\"expiresOn\"]]],null]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[41,[28,[37,5],[[30,0,[\"showScheme\"]],[30,2,[\"schemeName\"]]],null],[[[1,\"        \"],[8,[39,6],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[39,7],null,[[\"@class\",\"@type\",\"@label\"],[[30,3,[\"tagClass\"]],\"neutral\",[30,2,[\"schemeName\"]]]],null],[1,\"\\n        \"]],[3]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n    \"],[8,[39,8],null,[[\"@display\"],[[28,[37,9],[[30,0,[\"applyRewardTask\",\"isRunning\"]],[30,0,[\"removeRewardTask\",\"isRunning\"]]],null]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n\"],[41,[30,2,[\"isActiveReward\"]],[[[1,\"      \"],[8,[39,10],[[16,0,[30,4,[\"class\"]]]],[[\"@testSelector\",\"@variant\",\"@label\",\"@onClick\"],[\"removeReward\",\"destructive\",[28,[37,3],[\"mwc.rewardCard.removeButton\"],null],[28,[37,11],[[30,0,[\"removeRewardTask\"]]],null]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,10],[[16,0,[30,4,[\"class\"]]]],[[\"@testSelector\",\"@label\",\"@onClick\"],[\"applyReward\",[28,[37,3],[\"mwc.rewardCard.applyButton\"],null],[30,0,[\"applyReward\"]]]],null],[1,\"\\n\"]],[]]],[1,\"  \"]],[4]]]]],[1,\"\\n\"]],[\"@class\",\"@reward\",\"list\",\"button\"],false,[\"r-card\",\"classes\",\"if\",\"t\",\"format-relative-date\",\"and\",\"r-tag-list\",\"r-info-tag\",\"loading-indicator\",\"or\",\"r-card/button\",\"perform\"]]",
    "moduleName": "mobile-web/components/r-reward-card/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});