define("mobile-web/components/r-application-loading/splash/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_1lwl6o",
    "image": "_image_1lwl6o",
    "controls": "_controls_1lwl6o",
    "mainHeader": "_mainHeader_1lwl6o",
    "subHeader": "_subHeader_1lwl6o",
    "input": "_input_1lwl6o",
    "button": "_button_1lwl6o"
  };
  _exports.default = _default;
});