define("mobile-web/components/r-link-rewards/schemes/component", ["exports", "@ember-decorators/component", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-link-rewards/schemes/style"], function (_exports, _component, _, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LinkRewardsSchemes = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed('schemes.@each.canLink', 'linkedSchemeNames.[]'), _dec5 = Ember.computed('linkedSchemeNames.[]', 'memberships', 'schemes.[]', 'vendor.id'), _dec6 = Ember._action, _dec(_class = (_class2 = class LinkRewardsSchemes extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      this.style = _style.default;
    }

    // Computed properties
    get linkableSchemes() {
      return this.schemes.filter(s => s.canLink && !this.linkedSchemeNames.includes(s.schemeName));
    }

    get linkedSchemes() {
      const schemeArray = this.schemes.filter(s => this.linkedSchemeNames.includes(s.schemeName));
      const qualifyingRewards = schemeArray.map(s => {
        var _account$qualifyingLo, _account$qualifyingLo2;

        const account = this.store.peekRecord('loyalty-account', s.id);

        if (s.vendor.id !== this.vendor.id) {
          return false;
        }

        return ((_account$qualifyingLo = account == null ? void 0 : (_account$qualifyingLo2 = account.qualifyingLoyaltyRewards) == null ? void 0 : _account$qualifyingLo2.length) != null ? _account$qualifyingLo : 0) > 0;
      });
      return (0, _.zip)(schemeArray, qualifyingRewards);
    } // Init


    init() {
      super.init();
      (false && !((0, _isSome.default)(this.memberships)) && Ember.assert('`memberships` are required', (0, _isSome.default)(this.memberships)));
      (false && !((0, _isSome.default)(this.schemes)) && Ember.assert('`schemes` are required', (0, _isSome.default)(this.schemes)));
      (false && !((0, _isSome.default)(this.vendor)) && Ember.assert('`vendor` is required', (0, _isSome.default)(this.vendor)));
      (false && !((0, _isSome.default)(this.linkedSchemeNames)) && Ember.assert('`linkedSchemeNames` are required', (0, _isSome.default)(this.linkedSchemeNames)));
    } // Other methods
    // Actions


    viewRewards(scheme) {
      this.router.transitionTo('menu.vendor.rewards', scheme.vendor.slug);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "linkableSchemes", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "linkableSchemes"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "linkedSchemes", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "linkedSchemes"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "viewRewards", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "viewRewards"), _class2.prototype)), _class2)) || _class);
  _exports.default = LinkRewardsSchemes;
});