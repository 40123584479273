define("mobile-web/components/r-checkout/guest-toggle/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_wse3p5",
    "label": "_label_wse3p5",
    "button": "_button_wse3p5"
  };
  _exports.default = _default;
});