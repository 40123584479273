define("mobile-web/services/title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TitleService extends Ember.Service {
    constructor(...args) {
      super(...args);
      this.pageTitle = 'Order Online';
    }

  }

  _exports.default = TitleService;
});