define("mobile-web/lib/time/durations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.HOURS = _exports.MINUTES = _exports.SECONDS = _exports.MILLISECONDS = void 0;
  const MILLISECONDS = 1;
  _exports.MILLISECONDS = MILLISECONDS;
  const SECONDS = 1000 * MILLISECONDS;
  _exports.SECONDS = SECONDS;
  const MINUTES = 60 * SECONDS;
  _exports.MINUTES = MINUTES;
  const HOURS = 60 * MINUTES;
  _exports.HOURS = HOURS;
});