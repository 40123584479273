define("mobile-web/components/r-checkout/basket-contents/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JoVz4kc1",
    "block": "[[[8,[39,0],null,[[\"@defaultMessage\",\"@key\"],[\"mwc.checkout.orderSummaryHeader\",\"RESP_CHECKOUT_ORDER_SUMMARY_LABEL\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@name\",\"@header\",\"@onEdit\"],[\"BasketContents\",[30,1,[\"value\"]],[30,2]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@products\",\"@initialCount\"],[[30,3],3]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"x\",\"@onEdit\",\"@products\"],false,[\"x-content\",\"r-editable-section\",\"r-cart/product-list\"]]",
    "moduleName": "mobile-web/components/r-checkout/basket-contents/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});