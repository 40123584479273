define("mobile-web/components/r-twitter-share-button/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_bs126v",
    "text": "_text_bs126v",
    "icon": "_icon_bs126v"
  };
  _exports.default = _default;
});