define("mobile-web/components/r-tooltip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lUOLd/XD",
    "block": "[[[10,0],[14,\"data-test-tooltip\",\"\"],[12],[1,\"\\n  \"],[10,0],[14,\"data-test-tooltip-trigger\",\"\"],[15,\"aria-label\",[36,0]],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n    \"],[8,[39,2],null,[[\"@arrowClass\",\"@event\",\"@side\",\"@tooltipClass\",\"@keepInWindow\"],[[30,0,[\"style\",\"customArrow\"]],[30,0,[\"event\"]],[30,0,[\"side\"]],[30,0,[\"style\",\"customTooltip\"]],true]],[[\"default\"],[[[[1,\"\\n      \"],[18,2,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&trigger\",\"&content\"],false,[\"text\",\"yield\",\"ember-tooltip\"]]",
    "moduleName": "mobile-web/components/r-tooltip/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});