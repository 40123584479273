define("mobile-web/components/r-heading-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iravbIX/",
    "block": "[[[10,\"section\"],[15,0,[30,0,[\"rootClass\"]]],[15,\"data-test-heading-section\",[36,0]],[12],[1,\"\\n  \"],[8,[39,1],[[16,0,[30,0,[\"style\",\"heading\"]]],[16,\"data-test-heading-section-heading\",[36,0]]],[[\"@level\"],[[30,0,[\"headingLevel\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[34,0]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[18,1,[[28,[37,3],null,[[\"subsection\"],[[50,\"r-heading-section/r-subsection\",0,null,null]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"heading\",\"r-heading\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "mobile-web/components/r-heading-section/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});