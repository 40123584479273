define("mobile-web/components/r-basket-transfer-action/component", ["exports", "@glimmer/component", "ember-concurrency-ts", "mobile-web/lib/utilities/_", "mobile-web/components/r-basket-transfer-action/style"], function (_exports, _component, _emberConcurrencyTs, _, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BasketTransferAction = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class BasketTransferAction extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      _initializerDefineProperty(this, "error", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "newBasketData", _descriptor5, this);

      _initializerDefineProperty(this, "newVendor", _descriptor6, this);

      _initializerDefineProperty(this, "warnings", _descriptor7, this);

      _initializerDefineProperty(this, "showConfirmationPrompt", _descriptor8, this);
    }

    // Getters and setters
    // Constructor
    // Other methods
    setBasket() {
      const basketData = this.newBasketData;
      const store = this.store;
      store.pushPayload('basket', basketData);
      this.basket.set('basket', store.peekRecord('basket', basketData.basket.guid));
    } // Actions


    async transfer(newVendor) {
      var _this$basket$basket;

      await (0, _emberConcurrencyTs.taskFor)(this.basket.transferTask).perform(newVendor);
      this.args.onTransfer(newVendor, (0, _.isEmpty)((_this$basket$basket = this.basket.basket) == null ? void 0 : _this$basket$basket.warnings));
    }

    confirmationClose() {
      this.showConfirmationPrompt = false;
    }

    continueSwitch() {
      this.showConfirmationPrompt = false;
      this.setBasket();
      this.args.onTransfer(this.newVendor, false);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "error", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "newBasketData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "newVendor", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "warnings", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showConfirmationPrompt", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "transfer", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "transfer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "confirmationClose", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "confirmationClose"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "continueSwitch", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "continueSwitch"), _class.prototype)), _class));
  _exports.default = BasketTransferAction;
});