define("mobile-web/helpers/criteria-label", ["exports", "@glint/environment-ember-loose/ember-component/helper", "mobile-web/lib/order-criteria", "mobile-web/lib/time/format"], function (_exports, _helper, _orderCriteria, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CriteriaLabel = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class CriteriaLabel extends _helper.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "mwcIntl", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);
    }

    compute(_params, {
      type,
      criteria,
      html
    } = {}) {
      if (type && criteria) {
        if (type === 'time') {
          if ((0, _orderCriteria.isAdvance)(criteria) && criteria.timeWanted) {
            var _this$basket$basket, _this$basket$basket$v;

            let timeLabel = this.mwcIntl.relativeDateTime(criteria.timeWanted, {
              html,
              titleCase: true
            });

            if ((_this$basket$basket = this.basket.basket) != null && (_this$basket$basket$v = _this$basket$basket.vendor) != null && _this$basket$basket$v.get('utcOffset')) {
              const vendorOffset = this.basket.basket.vendor.get('utcOffset');

              if (criteria.timeWanted.utcOffset() !== vendorOffset * 60) {
                timeLabel += ` (${this.intl.t(`mwc.calendar.timeWantedAtStore${html ? 'Html' : ''}`, {
                  time: criteria.timeWanted.utcOffset(vendorOffset * 60).format(_format.default.TIME_FORMAT)
                })})`;
              }
            }

            return timeLabel;
          }

          return html ? `<strong>${(0, _orderCriteria.getTimeWantedTypeLabel)(criteria.timeWantedType, this.store)}</strong>` : (0, _orderCriteria.getTimeWantedTypeLabel)(criteria.timeWantedType, this.store);
        }

        if (type === 'handoff') {
          return (0, _orderCriteria.getHandoffLabel)(criteria.handoffMode, this.store);
        }
      }

      return '';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "mwcIntl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CriteriaLabel;
});