define("mobile-web/components/r-checkout/guest-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0wqfuVDu",
    "block": "[[[10,0],[15,0,[30,0,[\"style\",\"root\"]]],[14,\"data-test-checkout-guestToggle\",\"\"],[12],[1,\"\\n  \"],[10,2],[15,0,[30,0,[\"style\",\"label\"]]],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[1,[28,[35,1],[\"mwc.checkoutGuestToggle.toAccountPrompt\"],null]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,1],[\"mwc.checkoutGuestToggle.toGuestPrompt\"],null]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n  \"],[8,[39,2],[[16,0,[30,0,[\"style\",\"button\"]]]],[[\"@variant\",\"@onClick\",\"@testSelector\"],[\"minor\",[30,0,[\"clicked\"]],\"guestToggleButton\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[1,[28,[35,1],[\"mwc.checkoutGuestToggle.toAccountButton\"],null]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,1],[\"mwc.checkoutGuestToggle.toGuestButton\"],null]],[1,\"\\n\"]],[]]],[1,\"  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@isGuest\"],false,[\"if\",\"t\",\"r-button\"]]",
    "moduleName": "mobile-web/components/r-checkout/guest-toggle/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});