define("mobile-web/adapters/vendor-search-result", ["exports", "mobile-web/lib/hybrid-util", "mobile-web/adapters/application"], function (_exports, _hybridUtil, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class VendorSearchResultAdapter extends _application.default {
    pathForType() {
      // This path is used by member actions, like toggleFavorite
      return 'vendors';
    }

    urlForQuery() {
      const path = this.pathForType();
      return `${(0, _hybridUtil.host)()}/${this.namespace}/${path}/search`;
    }

    query(_store, _typeClass, query) {
      if (Ember.isNone(query)) {
        return Ember.RSVP.resolve({
          'vendor-search-results': []
        });
      }

      let request;

      if (typeof query === 'string') {
        // Used when query is made by RegionResultsRoute
        const url = `${this.urlForQuery()}/${query}`;
        request = this.ajax(url, 'GET');
      } else {
        // Used when query is made by VendorSearchResultsRoute
        const url = `${this.urlForQuery()}`;
        request = this.ajax(url, 'POST', {
          data: query
        });
      }

      return request.then(data => {
        let results = data['vendor-search-results'];

        if (data.hasOwnProperty('favorite-vendor-search-results')) {
          results = data['favorite-vendor-search-results'].concat(data['vendor-search-results']);
        }

        return {
          'vendor-search-results': results
        };
      });
    }

  }

  _exports.default = VendorSearchResultAdapter;
});