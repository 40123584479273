define("mobile-web/components/r-button/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "mobile-web/lib/animation", "mobile-web/lib/time/durations", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/classes", "mobile-web/transitions/slide", "mobile-web/components/r-button/style"], function (_exports, _glimmerComponent, _animation, _durations, _, _classes, _slide, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Variant = void 0;
  let Variant;
  _exports.Variant = Variant;

  (function (Variant) {
    Variant["Main"] = "main";
    Variant["Minor"] = "minor";
    Variant["Destructive"] = "destructive";
    Variant["SoftDisabled"] = "softDisabled";
  })(Variant || (_exports.Variant = Variant = {}));

  class Button extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);
      this.duration = (0, _animation.getDuration)(100 * _durations.MILLISECONDS);
      this.elementId = Ember.guidFor(this);
      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get onClick() {
      var _this$args$onClick;

      return (_this$args$onClick = this.args.onClick) != null ? _this$args$onClick : _.noop;
    }

    get iconClass() {
      return (0, _classes.classes)(_style.default.icon, _style.default.iconSvg);
    }

    get dataTestButton() {
      return this.args.testSelector || this.elementId;
    }

    get variant() {
      var _this$args$variant;

      return (_this$args$variant = this.args.variant) != null ? _this$args$variant : Variant.Main;
    }

    get size() {
      var _this$args$size;

      return (_this$args$size = this.args.size) != null ? _this$args$size : 500;
    }

    get rootClass() {
      return (0, _classes.classes)(_style.default.button, _style.default[this.variant], _style.default[`size-${this.size}`], {
        [_style.default.fullWidth]: this.args.fullWidth,
        [_style.default.hasHighlightContent]: this.args.highlightContent !== undefined
      });
    } // Constructor
    // Other methods


    rules({
      oldItems,
      newItems
    }) {
      if (oldItems[0] < newItems[0]) {
        return _slide.up;
      }

      return _slide.down;
    } // Tasks
    // Actions


  }

  _exports.default = Button;
});