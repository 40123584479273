define("mobile-web/components/r-post-checkout/r-order-location/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5f/XYnKg",
    "block": "[[[10,0],[15,0,[36,0]],[14,\"data-test-post-checkout-order-location\",\"\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@heading\",\"@icon\"],[[30,0,[\"heading\"]],[50,\"r-svg\",0,null,[[\"icon\",\"ariaLabel\"],[\"r-store\",\"\"]]]]],[[\"content\",\"buttons\"],[[[[1,\"\\n      \"],[10,2],[12],[1,\"\\n        \"],[8,[39,3],null,[[\"@route\",\"@model\",\"@testSelector\"],[\"menu.vendor\",[30,0,[\"vendor\",\"slug\"]],\"vendorLink\"]],[[\"default\"],[[[[1,\"\\n          \"],[1,[33,4,[\"name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n\"],[41,[51,[33,6]],[[[1,\"          \"],[10,\"br\"],[12],[13],[1,\"\\n          \"],[8,[39,7],null,[[\"@model\"],[[30,1,[\"address\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"]],[]],[[[1,\"\\n      \"],[8,[30,2,[\"buttonContainer\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[10,3],[15,0,[29,[[30,0,[\"style\",\"button\"]],\" \",[30,2,[\"buttonClass\"]]]]],[15,6,[29,[\"tel:\",[33,4,[\"phoneNumber\"]]]]],[14,\"data-test-link\",\"vendorPhoneNumber\"],[12],[1,\"\\n          \"],[1,[28,[35,8],[[33,4,[\"phoneNumber\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@vendor\",\"s\"],false,[\"class\",\"r-post-checkout/r-subsection\",\"component\",\"r-link-to\",\"vendor\",\"unless\",\"isDelivery\",\"r-address-display\",\"format-phone\"]]",
    "moduleName": "mobile-web/components/r-post-checkout/r-order-location/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});