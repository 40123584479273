define("mobile-web/serializers/bootstrap-data", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BootstrapDataSerializer extends _emberData.default.RESTSerializer {
    constructor(...args) {
      super(...args);
      this.primaryKey = 'channelSlug';
    }

    modelNameFromPayloadKey() {
      return 'bootstrap-data';
    }

    normalize(modelClass, resourceHash) {
      const channel = resourceHash.channel;

      if (Ember.isNone(channel.settings.showMenuCategoryNames)) {
        channel.settings.showMenuCategoryNames = true;
      }

      if (Ember.isNone(channel.settings.showProductMenuPrices)) {
        channel.settings.showProductMenuPrices = true;
      }

      if (Ember.isNone(channel.settings.showProductPriceTicker)) {
        channel.settings.showProductPriceTicker = true;
      } // Handle cases where no public vendors have some handoff modes
      // Arguably we could adjust the backend to filter the handoff modes on the channel itself,
      // but that would be a fairly risky change and possibly difficult to roll back than a client-side filter.


      const availableModeIds = resourceHash.handoffModes.map(x => x.id);
      resourceHash.channel.handoffModes = resourceHash.channel.handoffModes.filter(x => availableModeIds.includes(x));
      return super.normalize(modelClass, resourceHash);
    }

  }

  _exports.default = BootstrapDataSerializer;
});