define("mobile-web/components/r-heading-section/r-subsection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IxYZFpEH",
    "block": "[[[10,\"section\"],[15,0,[30,0,[\"className\"]]],[14,\"data-test-heading-section-subsection\",\"\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "mobile-web/components/r-heading-section/r-subsection/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});