define("mobile-web/components/r-cart-button/component", ["exports", "@glimmer/component", "mobile-web/lib/animation", "mobile-web/lib/time/durations", "mobile-web/lib/utilities/_", "mobile-web/transitions/slide", "mobile-web/components/r-cart-button/style"], function (_exports, _component, _animation, _durations, _, _slide, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const TRANSITION_DURATION = (0, _animation.getDuration)(100 * _durations.MILLISECONDS);
  let CartButton = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = class CartButton extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      this.duration = TRANSITION_DURATION;
      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get hasProducts() {
      return this.numBasketProducts > 0;
    }

    get isLoading() {
      return this.basket.orderBasketGroup.isRunning;
    }

    get ariaLabel() {
      return this.intl.t('mwc.header.cartAriaLabel', {
        count: this.numBasketProducts
      });
    }

    get allSavedAttributes() {
      var _this$basket$basket, _this$basket$basket$b;

      return (_this$basket$basket = this.basket.basket) == null ? void 0 : (_this$basket$basket$b = _this$basket$basket.basketProducts) == null ? void 0 : _this$basket$basket$b.map(p => p.savedAttributes);
    }

    get numBasketProducts() {
      var _this$allSavedAttribu, _this$allSavedAttribu2;

      return (0, _.sum)((_this$allSavedAttribu = (_this$allSavedAttribu2 = this.allSavedAttributes) == null ? void 0 : _this$allSavedAttribu2.map(a => a.quantity)) != null ? _this$allSavedAttribu : [0]);
    }

    get buttonStyle() {
      return this.hasProducts ? this.style.major : this.style.minor;
    } // Constructor
    // Other methods


    rules({
      oldItems,
      newItems
    }) {
      const [oldItem = 0] = oldItems;
      const [newItem = 0] = newItems;
      return oldItem < newItem ? _slide.up : _slide.down;
    } // Tasks
    // Actions


    onClick() {
      if (!this.args.disabled) {
        this.basket.toggle();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onClick", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class));
  _exports.default = CartButton;
});