define("mobile-web/transitions/slide", ["exports", "mobile-web/motion/keyframes"], function (_exports, _keyframes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.up = up;
  _exports.down = down;

  function* up({
    insertedSprites,
    removedSprites
  }) {
    for (const sprite of removedSprites) {
      (0, _keyframes.keyframes)(sprite, {
        keyframes: {
          opacity: 0,
          transform: 'translateY(-100%)'
        }
      });
    }

    for (const sprite of insertedSprites) {
      (0, _keyframes.keyframes)(sprite, {
        keyframes: {
          offset: 0,
          opacity: 0,
          transform: 'translateY(100%)'
        }
      });
    }
  }

  function* down({
    insertedSprites,
    removedSprites
  }) {
    for (const sprite of removedSprites) {
      (0, _keyframes.keyframes)(sprite, {
        keyframes: {
          opacity: 0,
          transform: 'translateY(100%)'
        }
      });
    }

    for (const sprite of insertedSprites) {
      (0, _keyframes.keyframes)(sprite, {
        keyframes: {
          offset: 0,
          opacity: 0,
          transform: 'translateY(-100%)'
        }
      });
    }
  }
});