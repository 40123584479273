define("mobile-web/components/r-post-checkout/r-change-order/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Mq5TqxyR",
    "block": "[[[10,0],[15,0,[36,0]],[14,\"data-test-post-checkout-change-order\",\"\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@heading\",\"@icon\"],[\"Need to Change Something?\",[50,\"r-svg\",0,null,[[\"icon\",\"ariaLabel\"],[\"r-pencil\",\"\"]]]]],[[\"buttons\"],[[[[1,\"\\n      \"],[8,[30,1,[\"buttonContainer\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,2,[\"canModify\"]],[[[1,\"          \"],[8,[39,4],[[16,0,[30,1,[\"buttonClass\"]]],[24,\"data-test-editOrder\",\"\"]],[[\"@label\",\"@onClick\",\"@variant\"],[\"Edit Order\",[28,[37,5],[[30,0,[\"basket\",\"editOrderTask\"]],[30,0,[\"order\"]]],null],\"minor\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,2,[\"canCancel\"]],[[[1,\"          \"],[8,[39,4],[[16,0,[30,1,[\"buttonClass\"]]],[24,\"data-test-cancelOrder\",\"\"]],[[\"@label\",\"@onClick\",\"@variant\"],[\"Cancel Order\",[30,0,[\"cancelOrder\"]],\"minor\"]],null],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"s\",\"@order\"],false,[\"class\",\"r-post-checkout/r-subsection\",\"component\",\"if\",\"r-button\",\"perform\"]]",
    "moduleName": "mobile-web/components/r-post-checkout/r-change-order/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});