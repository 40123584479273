define("mobile-web/components/r-geolocation-prompt/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j95CsKyB",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@buttons\",\"@onClose\",\"@testSelector\"],[[28,[37,1],[\"mwc.geolocationPrompt.title\"],null],\"yield\",[30,1],\"searchNearby\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,2,[\"body\"]],[[[1,\"    \"],[10,0],[15,0,[30,0,[\"style\",\"container\"]]],[14,\"data-test-geolocationPrompt\",\"\"],[12],[1,\"\\n      \"],[10,2],[15,0,[30,0,[\"style\",\"text\"]]],[12],[1,[28,[35,1],[\"mwc.geolocationPrompt.bodyText\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[30,2,[\"footer\"]],[[[1,\"    \"],[8,[39,3],[[16,0,[30,2,[\"buttonClass\"]]]],[[\"@onClick\",\"@testSelector\",\"@variant\"],[[30,1],\"geolocation-cancel\",\"minor\"]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,1],[\"mwc.geolocationPrompt.cancelButton\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,3],[[16,0,[30,2,[\"buttonClass\"]]]],[[\"@onClick\",\"@testSelector\"],[[28,[37,4],[[30,0,[\"geo\",\"getGeolocationTask\"]],[30,3]],null],\"geolocation-search\"]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,1],[\"mwc.geolocationPrompt.searchButton\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]],null]],[]]]],[2]]]]],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@label\",\"@display\"],[[28,[37,1],[\"mwc.geolocationPrompt.loadingText\"],null],[30,0,[\"geo\",\"getGeolocationTask\",\"isRunning\"]]]],null],[1,\"\\n\"]],[\"@onClose\",\"modal\",\"@setCoords\"],false,[\"r-modal\",\"t\",\"if\",\"r-button\",\"perform\",\"loading-indicator\"]]",
    "moduleName": "mobile-web/components/r-geolocation-prompt/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});