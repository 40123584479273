define("mobile-web/components/r-application-loading/password/component", ["exports", "@glimmer/component", "mobile-web/lib/utilities/_", "mobile-web/components/r-application-loading/password/style"], function (_exports, _component, _, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApplicationLoadingPassword extends _component.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get inputEachArray() {
      const length = this.args.isChange ? 5 : 3;
      return (0, _.range)(0, length);
    }

    get textEachArray() {
      const length = this.args.isForgot ? 6 : this.args.isReset ? 2 : 0;
      return (0, _.range)(0, length);
    } // Constructor
    // Other methods
    // Tasks
    // Actions


  }

  _exports.default = ApplicationLoadingPassword;
});