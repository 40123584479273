define("mobile-web/serializers/vendor", ["exports", "ember-data", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some"], function (_exports, _emberData, _, _isSome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class VendorSerializer extends _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);
      this.attrs = {
        embeddedProductGroups: {
          embedded: 'always'
        }
      };
    }

    normalize(modelClass, resourceHash) {
      if ((0, _isSome.default)(resourceHash.settings)) {
        // acceptedCreditCardTypes comes to us as a single comma separated string, like so:
        // 'Amex, Discover, Mastercard, Visa'
        // This logic splits it into an array and matches case to the enum in lib/payment/card.ts
        const acceptedCreditCardTypes = (0, _.isEmpty)(resourceHash.settings.acceptedCreditCardTypes) ? [] : resourceHash.settings.acceptedCreditCardTypes.split(', ');
        const strCardTypes = acceptedCreditCardTypes.map(cardType => {
          if (cardType === 'Amex' || cardType === 'Visa') {
            return cardType.toUpperCase();
          }

          return cardType;
        }); // We're purposefully transforming from a string to a string[]
        // eslint-disable-next-line @typescript-eslint/no-explicit-any

        resourceHash.settings.acceptedCreditCardTypes = strCardTypes;
      }

      if ((0, _isSome.default)(resourceHash.embeddedProductGroups)) {
        resourceHash.embeddedProductGroups.forEach(upsellGroup => {
          upsellGroup.items.forEach(upsellItem => {
            delete upsellItem.cost;
          });
        });
      }

      if (resourceHash.address) {
        var _resourceHash$address;

        resourceHash.address.crossStreet = (_resourceHash$address = resourceHash.address.crossStreet) == null ? void 0 : _resourceHash$address.trim();
      }

      return super.normalize(modelClass, resourceHash);
    }

  }

  _exports.default = VendorSerializer;
});