define("mobile-web/components/r-disclaimers/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_1k2w38",
    "disclaimer": "_disclaimer_1k2w38"
  };
  _exports.default = _default;
});