define("mobile-web/components/r-basket-transfer-modal/component", ["exports", "@glimmer/component", "ember-concurrency", "ember-concurrency-ts", "mobile-web/lib/dayjs", "mobile-web/lib/order-criteria", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-basket-transfer-modal/style"], function (_exports, _component, _emberConcurrency, _emberConcurrencyTs, _dayjs, _orderCriteria, _, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BasketTransferModal = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, (_class = class BasketTransferModal extends _component.default {
    // Service injections
    // Passthroughs
    // Tracked properties
    // Getters and setters
    get currentVendor() {
      return this.vendor.vendor;
    }

    get timeWanted() {
      return (0, _orderCriteria.isAdvance)(this.orderCriteria.criteria) ? this.orderCriteria.criteria.timeWanted : undefined;
    }

    get wrapperClass() {
      return this.showGeolocationPrompt ? _style.default.hide : '';
    }

    get vendorScheduleDay() {
      return (0, _orderCriteria.isAdvance)(this.orderCriteria.criteria) && this.orderCriteria.criteria.timeWanted ? this.orderCriteria.criteria.timeWanted : (0, _dayjs.default)();
    } // Constructor


    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "bus", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "basket", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "orderCriteria", _descriptor5, this);

      _initializerDefineProperty(this, "vendor", _descriptor6, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "searchResults", _descriptor7, this);

      _initializerDefineProperty(this, "latitude", _descriptor8, this);

      _initializerDefineProperty(this, "longitude", _descriptor9, this);

      _initializerDefineProperty(this, "address", _descriptor10, this);

      _initializerDefineProperty(this, "showGeolocationPrompt", _descriptor11, this);

      _initializerDefineProperty(this, "hasRequestedGeolocation", _descriptor12, this);

      _initializerDefineProperty(this, "hasSearched", _descriptor13, this);

      this.searchResults = this.store.peekAll('vendor-search-result');
    } // Tasks


    *searchVendorsTask() {
      const {
        address,
        latitude,
        longitude
      } = this;

      if (!(0, _.isEmpty)(address) || (0, _isSome.default)(latitude) || (0, _isSome.default)(longitude)) {
        const queryParams = {
          address,
          latitude,
          longitude
        };
        const searchResults = yield this.store.loadRecords('vendor-search-result', queryParams);
        this.searchResults = searchResults;
        this.hasSearched = true;
      }
    }

    tryPrompt() {
      if (this.searchResults.length === 0) {
        this.promptForGeolocation();
      }
    }

    promptForGeolocation() {
      if (!this.hasRequestedGeolocation) {
        this.showGeolocationPrompt = true;
        this.hasRequestedGeolocation = true;
      }
    }

    hideGeolocationPrompt() {
      this.showGeolocationPrompt = false;
    }

    search(e) {
      e == null ? void 0 : e.preventDefault();

      if (!(0, _.isEmpty)(this.address)) {
        this.latitude = undefined;
        this.longitude = undefined;
      }

      (0, _emberConcurrencyTs.taskFor)(this.searchVendorsTask).perform();
    }

    setCoords(coords) {
      this.latitude = coords.latitude;
      this.longitude = coords.longitude;
      this.hideGeolocationPrompt();
      this.search();
    }

    transferComplete(newVendor, clean) {
      if (clean && this.router.currentRouteName === 'secure.checkout') {
        // This behavior is only valid on the checkout route, otherwise we want to redirect
        this.onTransfer();
      } else {
        this.onClose();
        this.router.transitionTo('menu.vendor', newVendor.slug);
      }
    }

    onClose() {
      this.orderCriteria.set('showAddressModal', false);
    }

    onTransfer() {
      this.bus.trigger('onBasketChange');
      this.onClose();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "bus", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "orderCriteria", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "vendor", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "searchResults", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "latitude", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "longitude", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "address", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "showGeolocationPrompt", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "hasRequestedGeolocation", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "hasSearched", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "searchVendorsTask", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchVendorsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "tryPrompt", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "tryPrompt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "promptForGeolocation", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "promptForGeolocation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hideGeolocationPrompt", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "hideGeolocationPrompt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "search", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setCoords", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "setCoords"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transferComplete", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "transferComplete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "onClose"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTransfer", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "onTransfer"), _class.prototype)), _class));
  _exports.default = BasketTransferModal;
});