define("mobile-web/components/r-product-card/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "ember-animated/transitions/fade", "ember-concurrency-ts", "mobile-web/lib/animation", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some", "mobile-web/services/analytics", "mobile-web/services/global-data", "mobile-web/services/global-events", "mobile-web/services/notifications", "mobile-web/components/r-product-card/style"], function (_exports, _glimmerComponent, _fade, _emberConcurrencyTs, _animation, _, _isSome, _analytics, _globalData, _globalEvents, _notifications, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.QuickAddState = _exports.ProductCardLocation = _exports.ProductCardMode = _exports.MAX_DESC_LENGTH = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const MAX_DESC_LENGTH = 70;
  _exports.MAX_DESC_LENGTH = MAX_DESC_LENGTH;
  let ProductCardMode;
  _exports.ProductCardMode = ProductCardMode;

  (function (ProductCardMode) {
    ProductCardMode["Menu"] = "menu";
    ProductCardMode["Modal"] = "modal";
    ProductCardMode["Page"] = "page";
    ProductCardMode["List"] = "list";
  })(ProductCardMode || (_exports.ProductCardMode = ProductCardMode = {}));

  let ProductCardLocation;
  _exports.ProductCardLocation = ProductCardLocation;

  (function (ProductCardLocation) {
    ProductCardLocation["Category"] = "category";
    ProductCardLocation["VendorMenu"] = "vendor-menu";
  })(ProductCardLocation || (_exports.ProductCardLocation = ProductCardLocation = {}));

  let QuickAddState;
  _exports.QuickAddState = QuickAddState;

  (function (QuickAddState) {
    QuickAddState["Default"] = "default";
    QuickAddState["Active"] = "active";
    QuickAddState["Success"] = "success";
  })(QuickAddState || (_exports.QuickAddState = QuickAddState = {}));

  let ProductCard = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service('-ea-motion'), _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember.inject.service, _dec11 = Ember.inject.service, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._action, _dec15 = Ember._action, (_class = class ProductCard extends _glimmerComponent.default {
    // Service injections
    // Passthroughs
    // Tracked properties
    // Getters and setters
    get quickAddState() {
      if (this.showSuccess) {
        return QuickAddState.Success;
      }

      if (this.basketProduct && (0, _emberConcurrencyTs.taskFor)(this.basketProduct.saveTask).isRunning) {
        return QuickAddState.Active;
      }

      return QuickAddState.Default;
    }

    get isQuickAddInProgress() {
      return this.quickAddState !== QuickAddState.Default;
    }

    get modeClass() {
      return this.style[this.mode];
    }

    get isProductPage() {
      return this.mode === ProductCardMode.Page || this.mode === ProductCardMode.Modal;
    }

    get showProductName() {
      return this.mode !== ProductCardMode.Modal;
    }

    get showFeatured() {
      return this.mode === ProductCardMode.Menu && this.args.product.isFeatured;
    }

    get canQuickAdd() {
      return this.mode === ProductCardMode.Menu && this.args.product.canQuickAdd;
    }

    get showAvailabilityDescription() {
      var _this$channel$setting, _this$channel$setting2;

      const showProductAvailability = (_this$channel$setting = (_this$channel$setting2 = this.channel.settings) == null ? void 0 : _this$channel$setting2.showProductAvailability) != null ? _this$channel$setting : true;
      const hasDescription = Boolean(this.args.product.availabilityDescription);
      return this.mode !== ProductCardMode.List && showProductAvailability && hasDescription;
    }

    get showImage() {
      return !(0, _.isEmpty)(this.args.product.menuImage);
    }

    get showPrice() {
      var _this$channel$setting3, _this$channel$setting4;

      return this.args.product.baseCost > 0 && (this.fullDescription || ((_this$channel$setting3 = (_this$channel$setting4 = this.channel.settings) == null ? void 0 : _this$channel$setting4.showProductMenuPrices) != null ? _this$channel$setting3 : true));
    }

    get showDescription() {
      return this.mode !== ProductCardMode.List && !(0, _.isEmpty)(this.description);
    }

    get showLabels() {
      return this.mode !== ProductCardMode.List;
    }

    get description() {
      const product = this.args.product;

      if (this.fullDescription) {
        return product.description;
      }

      if ((0, _.isEmpty)(product.shortDescription)) {
        return product.description.length > MAX_DESC_LENGTH ? `${product.description.slice(0, MAX_DESC_LENGTH)}...` : product.description;
      }

      return product.shortDescription;
    }

    get fullDescription() {
      return this.mode !== ProductCardMode.Menu;
    }

    get isLandscape() {
      var _this$args$isLandscap;

      return (_this$args$isLandscap = this.args.isLandscape) != null ? _this$args$isLandscap : false;
    }

    get mode() {
      var _this$args$mode;

      return (_this$args$mode = this.args.mode) != null ? _this$args$mode : ProductCardMode.Menu;
    } // Constructor


    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "basket", _descriptor2, this);

      _initializerDefineProperty(this, "bus", _descriptor3, this);

      _initializerDefineProperty(this, "channel", _descriptor4, this);

      _initializerDefineProperty(this, "features", _descriptor5, this);

      _initializerDefineProperty(this, "intl", _descriptor6, this);

      _initializerDefineProperty(this, "motion", _descriptor7, this);

      _initializerDefineProperty(this, "notifications", _descriptor8, this);

      _initializerDefineProperty(this, "router", _descriptor9, this);

      _initializerDefineProperty(this, "store", _descriptor10, this);

      _initializerDefineProperty(this, "globalEvents", _descriptor11, this);

      this.style = _style.default;
      this.transition = _fade.default;

      _initializerDefineProperty(this, "basketProduct", _descriptor12, this);

      _initializerDefineProperty(this, "showSuccess", _descriptor13, this);

      (false && !((0, _isSome.default)(this.args.product)) && Ember.assert('`product` is required', (0, _isSome.default)(this.args.product)));
    } // Other methods
    // Tasks
    // Actions


    quickAdd(e) {
      e.stopPropagation(); // We need to check this.motion.isAnimating because the quick-add animation
      // is nested under the r-card-grid animation, and ember-animated gets confused
      // if you try to begin an animation nested under a currently active animation.

      if (this.isQuickAddInProgress || this.motion.isAnimating) {
        return;
      }

      const basketProduct = this.store.createRecord('basket-product', {
        quantity: 1,
        product: this.args.product,
        vendor: this.args.product.get('vendor')
      });
      this.basketProduct = basketProduct;
      (0, _emberConcurrencyTs.taskFor)(basketProduct.saveTask).perform({
        eventName: 'Quick Add',
        onSuccess: () => {
          const message = this.intl.t('mwc.notifications.added', {
            quantity: basketProduct.quantity
          });
          this.notifications.success({
            message,
            type: _notifications.NotificationType.ProductAdded
          });
          this.showSuccess = true;
          this.globalEvents.trigger(_globalEvents.GlobalEventName.AddToCart, basketProduct.serializeForGlobalData());
          Ember.run.later(() => {
            this.showSuccess = false;
          }, (0, _animation.getDuration)(2000));
        }
      });
    }

    goToProduct(e) {
      if (!this.isProductPage) {
        if (this.mode !== ProductCardMode.List) {
          e.stopPropagation();
        }

        const product = this.args.product;
        this.globalEvents.trigger(_globalEvents.GlobalEventName.ClickProductLink, _extends({}, product.serializeForGlobalData(), {
          hasImages: this.showImage,
          hasPrice: this.showPrice
        }), this.args.location === ProductCardLocation.Category ? _globalData.ProductClickFrom.Category : _globalData.ProductClickFrom.VendorMenu);
        this.analytics.trackEvent(_analytics.AnalyticsEvents.ViewProductCustomization, () => {
          var _product$category, _product$category2;

          return {
            [_analytics.AnalyticsProperties.ProductName]: product.name,
            [_analytics.AnalyticsProperties.ProductCategory]: (_product$category = product.category) == null ? void 0 : _product$category.name,
            [_analytics.AnalyticsProperties.ProductBasePrice]: product.baseCost,
            [_analytics.AnalyticsProperties.HasVisibleCalories]: !(0, _.isEmpty)(product.calorieLabel),
            [_analytics.AnalyticsProperties.VisibleLabels]: product.labels.map(l => l.name),
            [_analytics.AnalyticsProperties.HasProductImages]: this.showImage,
            [_analytics.AnalyticsProperties.HasCategoryImages]: !(0, _.isEmpty)((_product$category2 = product.category) == null ? void 0 : _product$category2.bannerImage),
            [_analytics.AnalyticsProperties.HasVisiblePrice]: this.showPrice,
            [_analytics.AnalyticsProperties.ProductAvailableOptionGroupCount]: product.hasMany('optionGroups').ids().length
          };
        });

        if (product.optionGroups.length === 0 && this.router.currentRouteName !== 'menu.vendor.index') {
          this.bus.trigger('showProductModal', {
            product,
            onSave: () => {
              this.basket.open();
            }
          });
        } else {
          this.router.transitionTo('menu.vendor.products', product.vendor.slug, product.id);
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "bus", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "features", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "motion", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "globalEvents", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "basketProduct", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "showSuccess", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "quickAdd", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "quickAdd"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToProduct", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "goToProduct"), _class.prototype)), _class));
  _exports.default = ProductCard;
});