define("mobile-web/components/r-post-checkout/r-subsection/r-line-item-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hJAzpV+4",
    "block": "[[[10,0],[15,0,[30,0,[\"style\",\"root\"]]],[14,\"data-test-post-checkout-subsection-line-item-container\",\"\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "mobile-web/components/r-post-checkout/r-subsection/r-line-item-container/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});