define("mobile-web/lib/utilities/clear-raygun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Raygun stores unsent events in `localStorage`.
   * This can build up over time and cause issues for Serve.
   */
  function clearRaygunStorage() {
    try {
      Object.keys(localStorage).forEach(key => {
        if (key.includes('raygunjs+')) {
          localStorage.removeItem(key);
        }
      });
    } catch (error) {// noop
    }
  }

  var _default = clearRaygunStorage;
  _exports.default = _default;
});