define("mobile-web/adapters/bootstrap-data", ["exports", "mobile-web/adapters/application", "mobile-web/lib/utilities/_"], function (_exports, _application, _) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BootstrapDataAdapter extends _application.default.extend({}) {
    urlForFindRecord(id, modelName, snapshot) {
      const baseUrl = super.urlForFindRecord(id, modelName, snapshot);
      const includeAnalytics = (0, _.isBoolean)(window.Olo.includeAnalytics) ? window.Olo.includeAnalytics : false;
      return `${baseUrl}?includeAnalytics=${includeAnalytics}`;
    }

  }

  _exports.default = BootstrapDataAdapter;
});