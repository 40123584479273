define("mobile-web/routes/recent-orders", ["exports", "mobile-web/decorators/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  let RecentOrderRoute = (0, _authenticated.default)(_class = class RecentOrderRoute extends Ember.Route {
    constructor(...args) {
      super(...args);
      this.titleToken = 'Recent Orders';
    }

    model() {
      return this.store.findAll('order-search-result');
    }

  }) || _class;

  _exports.default = RecentOrderRoute;
});