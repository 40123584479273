define("mobile-web/helpers/ie-only", ["exports", "@glint/environment-ember-loose/ember-component/helper", "mobile-web/lib/utilities/device-detect"], function (_exports, _helper, _deviceDetect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ieOnlyHelper = (0, _helper.helper)(([cb]) => _deviceDetect.IS_IE ? cb : undefined);
  var _default = ieOnlyHelper;
  _exports.default = _default;
});