define("mobile-web/components/r-checkout/ordering-from/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "merbIUji",
    "block": "[[[8,[39,0],null,[[\"@name\",\"@header\",\"@onEdit\"],[\"Ordering From\",[28,[37,1],[\"mwc.checkout.orderingFromHeader\"],null],[30,0,[\"openModal\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"style\",\"root\"]]],[12],[1,\"\\n    \"],[10,2],[15,0,[30,0,[\"style\",\"name\"]]],[14,\"data-test-checkout-orderingFrom-name\",\"\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n    \"],[10,2],[14,\"data-test-checkout-orderingFrom-address\",\"\"],[12],[1,\"\\n\"],[41,[30,1,[\"address\"]],[[[1,\"        \"],[8,[39,3],null,[[\"@model\"],[[30,1,[\"address\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@vendor\"],false,[\"r-editable-section\",\"t\",\"if\",\"r-address-display\"]]",
    "moduleName": "mobile-web/components/r-checkout/ordering-from/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});