define("mobile-web/components/r-header-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KHytMSZh",
    "block": "[[[11,\"button\"],[16,\"aria-label\",[52,[51,[30,0,[\"showLabel\"]]],[30,1,[\"text\"]]]],[16,0,[30,0,[\"buttonStyle\"]]],[16,\"data-test-header-button\",[30,0,[\"testSelector\"]]],[24,4,\"button\"],[17,2],[4,[38,1],[\"click\",[30,3]],null],[12],[1,\"\\n  \"],[10,1],[15,0,[30,0,[\"style\",\"buttonContent\"]]],[14,\"data-test-header-button\",\"content\"],[12],[1,\"\\n    \"],[10,1],[14,\"data-test-header-button\",\"icon\"],[15,0,[30,0,[\"iconWrapperStyle\"]]],[12],[1,\"\\n\"],[1,\"      \"],[8,[39,2],[[16,0,[30,0,[\"style\",\"icon\"]]]],[[\"@icon\",\"@ariaLabel\",\"@iconClass\"],[[30,4,[\"svg\"]],[30,4,[\"ariaLabel\"]],[30,4,[\"iconClass\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showLabel\"]],[[[1,\"      \"],[10,1],[14,\"data-test-header-button\",\"label\"],[15,0,[30,0,[\"labelStyle\"]]],[12],[1,[30,1,[\"text\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@label\",\"&attrs\",\"@onClick\",\"@icon\"],false,[\"unless\",\"on\",\"r-svg\",\"if\"]]",
    "moduleName": "mobile-web/components/r-header-button/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});