define("mobile-web/components/r-side-menu/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "header": "_header_15cjv9 _root_5a34vi",
    "headerText": "_headerText_15cjv9",
    "closeButton": "_closeButton_15cjv9",
    "upper": "_upper_15cjv9",
    "greeting": "_greeting_15cjv9",
    "linkList": "_linkList_15cjv9"
  };
  _exports.default = _default;
});