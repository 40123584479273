define("mobile-web/components/r-form-field/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "mobile-web/lib/strings", "mobile-web/lib/utilities/classes", "mobile-web/lib/utilities/guids", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-form-field/style"], function (_exports, _glimmerComponent, _strings, _classes, _guids, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Size = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Size;
  _exports.Size = Size;

  (function (Size) {
    Size["Standard"] = "standard";
    Size["Small"] = "small";
    Size["Large"] = "large";
  })(Size || (_exports.Size = Size = {}));

  let FormField = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class FormField extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      this.style = _style.default;
      this.optionalIndicatorText = _strings.FORM_FIELD_OPTIONAL_LABEL;

      _initializerDefineProperty(this, "focused", _descriptor2, this);
    }

    // Getters and setters
    get aria() {
      return {
        describedby: this.ariaDescribedby,
        label: this.args.hideLabel ? this.args.label : undefined
      };
    }

    get ariaDescribedby() {
      const ids = [];

      if (this.args.helpMessage) {
        ids.push(this.ids.help);
      }

      if (this.invalid) {
        ids.push(...this.args.validationMessages.map((_, index) => `${this.ids.error}-${index}`));
      }

      if (this.showRemainingCharacterCount) {
        ids.push(this.ids.characterCount);
      }

      return ids.length ? ids.join(' ') : undefined;
    }

    get characterCountLabel() {
      return this.intl.t('mwc.formField.characterCount', {
        remaining: this.remainingCharacterCount
      });
    } // TODO: remove this obsolete alias and use `setValue` directly.


    get directSetValue() {
      return this.setValue;
    }

    get buttonClass() {
      var _this$args$buttonData, _this$args$buttonData2;

      const cls = [_style.default.button];

      if ((_this$args$buttonData = this.args.buttonData) != null && _this$args$buttonData.variant) {
        cls.push(_style.default[`button--` + this.args.buttonData.variant]);
      }

      if ((_this$args$buttonData2 = this.args.buttonData) != null && _this$args$buttonData2.class) {
        cls.push(this.args.buttonData.class);
      }

      return (0, _classes.classes)(...cls);
    }

    get buttonIconClass() {
      var _this$args$buttonData3;

      const cls = [_style.default.icon];

      if ((_this$args$buttonData3 = this.args.buttonData) != null && _this$args$buttonData3.iconClass) {
        cls.push(this.args.buttonData.iconClass);
      }

      return (0, _classes.classes)(...cls);
    }

    get ids() {
      return (0, _guids.guids)(this, 'characterCount', 'error', 'help', 'input');
    }

    get inline() {
      return this.args.inlineLabel || (0, _isSome.default)(this.args.icon);
    }

    get inlineLabelTextClass() {
      return (0, _classes.classes)(this.style.inlineLabelText, {
        [this.style.responsive]: this.args.responsiveLabel
      });
    }

    get inputClass() {
      return (0, _classes.classes)({
        [this.style.textInput]: !this.isSelect && !this.inline,
        [this.style.inlineTextInput]: !this.isSelect && this.inline,
        [this.style.selectInput]: this.isSelect && !this.inline,
        [this.style.inlineSelectInput]: this.isSelect && this.inline
      }, this.args.fieldClass);
    }

    get inputTag() {
      switch (this.type) {
        case 'select':
        case 'textarea':
          return this.type;

        default:
          return 'input';
      }
    }

    get invalid() {
      var _this$args$validation, _this$args$validation2;

      return !this.args.novalidate && ((_this$args$validation = (_this$args$validation2 = this.args.validationMessages) == null ? void 0 : _this$args$validation2.length) != null ? _this$args$validation : 0) > 0;
    }

    get isSelect() {
      return this.type === 'select';
    }

    get labelClassName() {
      return (0, _classes.classes)({
        [this.style.inlineLabel]: this.inline,
        [this.style.hiddenLabel]: !this.inline && this.args.hideLabel,
        [this.style.label]: !this.inline && !this.args.hideLabel
      }, this.args.labelClass);
    }

    get labelInputContainerClass() {
      return this.inline ? this.style.inlineLabelInputContainer : this.style.labelInputContainer;
    }

    get remainingCharacterCount() {
      var _this$args$inputValue, _this$args$inputValue2;

      const characterCount = (_this$args$inputValue = (_this$args$inputValue2 = this.args.inputValue) == null ? void 0 : _this$args$inputValue2.length) != null ? _this$args$inputValue : 0;
      return this.args.maxlength ? this.args.maxlength - characterCount : 0;
    }

    get showRemainingCharacterCount() {
      return Boolean(this.args.showCount && this.args.maxlength);
    }

    get tagClass() {
      return (0, _classes.classes)(this.style.container, this.args.class, {
        [this.style.disabled]: this.args.disabled,
        [this.style.focused]: this.focused,
        [this.style.inline]: this.inline,
        [this.style.standard]: !this.inline,
        [this.style.formGroup]: this.args.formGroup,
        [this.style.reverse]: this.args.reverseLayout,
        [this.style.error]: this.invalid && !this.args.useAltErrorStyle,
        [this.style.errorAlt]: this.invalid && this.args.useAltErrorStyle
      }, this.args.size ? this.style[this.args.size] : this.style[Size.Standard]);
    }

    get trimOnChange() {
      var _this$args$trimOnChan;

      return (_this$args$trimOnChan = this.args.trimOnChange) != null ? _this$args$trimOnChan : true;
    }

    get type() {
      var _this$args$type;

      return (_this$args$type = this.args.type) != null ? _this$args$type : 'text';
    } // Constructor
    // Other methods


    clearMessages() {
      if ((0, _isSome.default)(this.args.validationMessages)) {
        // TODO: update this to use unidirectional dataflow
        this.args.validationMessages.clear();
      }
    } // Tasks
    // Actions


    focusIn() {
      if (!this.args.disabled) {
        Ember.run.next(this, () => {
          this.focused = true;
        });
      }
    }

    focusOut() {
      if (!this.args.disabled) {
        var _this$args$onFocusOut, _this$args;

        Ember.run.next(this, () => {
          this.focused = false;
        });
        (_this$args$onFocusOut = (_this$args = this.args).onFocusOut) == null ? void 0 : _this$args$onFocusOut.call(_this$args);
      }
    }

    handleButtonClick() {
      var _this$args$buttonData4;

      (_this$args$buttonData4 = this.args.buttonData) == null ? void 0 : _this$args$buttonData4.onClick == null ? void 0 : _this$args$buttonData4.onClick();
    }

    setFocus() {
      var _this$args$onFocus, _this$args2;

      (_this$args$onFocus = (_this$args2 = this.args).onFocus) == null ? void 0 : _this$args$onFocus.call(_this$args2);
    }

    setValue(value) {
      var _this$args$setValue, _this$args3;

      this.clearMessages();
      const trimmedValue = this.trimOnChange ? value == null ? void 0 : value.trim() : value;
      const formattedValue = this.args.formatValue ? this.args.formatValue(trimmedValue) : trimmedValue;
      (_this$args$setValue = (_this$args3 = this.args).setValue) == null ? void 0 : _this$args$setValue.call(_this$args3, formattedValue);
    }

    setValueImmediate(value) {
      var _this$args$onInput, _this$args4;

      this.clearMessages();
      const formattedValue = this.args.formatValue ? this.args.formatValue(value) : value;
      (_this$args$onInput = (_this$args4 = this.args).onInput) == null ? void 0 : _this$args$onInput.call(_this$args4, formattedValue);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "focused", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "focusIn", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "focusIn"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "focusOut", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "focusOut"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleButtonClick", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "handleButtonClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setFocus", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setFocus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setValue", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setValueImmediate", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setValueImmediate"), _class.prototype)), _class));
  _exports.default = FormField;
});