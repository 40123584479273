define("mobile-web/services/analytics", ["exports", "lodash.throttle", "true-myth", "mobile-web/config/environment", "mobile-web/lib/computed", "mobile-web/lib/dayjs", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/ga", "mobile-web/lib/utilities/gtm", "mobile-web/lib/utilities/is-some", "mobile-web/lib/utilities/tracking", "mobile-web/router", "mobile-web/services/global-events"], function (_exports, _lodash, _trueMyth, _environment, _computed, _dayjs, _2, _ga, _gtm, _isSome, _tracking, _router, _globalEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.GAEventAction = _exports.GAEventCategory = _exports.TIME_WANTED_ANALYTICS_LABELS = _exports.ANALYTICS_TYPES = _exports.AnalyticsProperties = _exports.AnalyticsEvents = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _asyncIterator(iterable) { var method; if (typeof Symbol !== "undefined") { if (Symbol.asyncIterator) method = iterable[Symbol.asyncIterator]; if (method == null && Symbol.iterator) method = iterable[Symbol.iterator]; } if (method == null) method = iterable["@@asyncIterator"]; if (method == null) method = iterable["@@iterator"]; if (method == null) throw new TypeError("Object is not async iterable"); return method.call(iterable); }

  let AnalyticsEvents;
  _exports.AnalyticsEvents = AnalyticsEvents;

  (function (AnalyticsEvents) {
    AnalyticsEvents["AddToCart"] = "Add to Cart";
    AnalyticsEvents["AskedForParkingLocation"] = "Asked For Parking Location";
    AnalyticsEvents["BeginOrder"] = "Begin Order";
    AnalyticsEvents["ChangeOrderCriteria"] = "Change Order Criteria";
    AnalyticsEvents["ChangeStoreLocation"] = "Change Store Location";
    AnalyticsEvents["ContactUs"] = "Contact Us";
    AnalyticsEvents["CreateAccount"] = "Create Account Submit";
    AnalyticsEvents["DownloadOnAppStore"] = "Download on App Store";
    AnalyticsEvents["ErrorShown"] = "Error Shown";
    AnalyticsEvents["GetDirections"] = "Get Directions";
    AnalyticsEvents["LoadDispatchMapData"] = "Load Dispatch Map Data";
    AnalyticsEvents["ManualLocationSelectionSkipped"] = "Manual Location Selection Skipped";
    AnalyticsEvents["MenuTypeFilter"] = "Menu Type Filter";
    AnalyticsEvents["OrderPlaced"] = "Order Placed";
    AnalyticsEvents["ProceedToCheckout"] = "Proceed to Checkout";
    AnalyticsEvents["ReorderNow"] = "Reorder Now";
    AnalyticsEvents["SearchForLocations"] = "Search For Locations";
    AnalyticsEvents["SignIn"] = "Sign In Submit";
    AnalyticsEvents["StickyNavCategoryClick"] = "Sticky Nav Category Click";
    AnalyticsEvents["UserFiredOrderEvent"] = "User Fired Order Event";
    AnalyticsEvents["ViewAllLocations"] = "View All Locations?";
    AnalyticsEvents["ViewMenu"] = "View Menu";
    AnalyticsEvents["ViewPage"] = "View Page";
    AnalyticsEvents["ViewProductCustomization"] = "View Product Customization";
    AnalyticsEvents["ViewRegionsLocations"] = "View Region's Locations?";
  })(AnalyticsEvents || (_exports.AnalyticsEvents = AnalyticsEvents = {}));

  let AnalyticsProperties;
  _exports.AnalyticsProperties = AnalyticsProperties;

  (function (AnalyticsProperties) {
    AnalyticsProperties["AddToCartMethod"] = "Add to Cart Method";
    AnalyticsProperties["AppStore"] = "AppStore";
    AnalyticsProperties["BasketDeliveryCharge"] = "Basket Delivery Charge";
    AnalyticsProperties["BasketDiscount"] = "Basket Discount";
    AnalyticsProperties["BasketHandoffMode"] = "Basket Handoff Mode";
    AnalyticsProperties["BasketHasDiscount"] = "Basket Has Discount";
    AnalyticsProperties["BasketID"] = "Basket ID";
    AnalyticsProperties["BasketProductQuantity"] = "Basket Product Quantity";
    AnalyticsProperties["BasketProducts"] = "Basket Products";
    AnalyticsProperties["BasketSubtotal"] = "Basket Subtotal";
    AnalyticsProperties["BasketTax"] = "Basket Tax";
    AnalyticsProperties["BasketTimeWantedMode"] = "Basket Time Wanted Mode";
    AnalyticsProperties["BasketTotal"] = "Basket Total";
    AnalyticsProperties["CategoryIndex"] = "Category Index";
    AnalyticsProperties["CategoryIsWithinViewMore"] = "Category Is Within View More?";
    AnalyticsProperties["CategoryName"] = "Category Name";
    AnalyticsProperties["ChangeLocationShown"] = "Change Location Shown?";
    AnalyticsProperties["ConsecutiveLoads"] = "Consecutive Loads";
    AnalyticsProperties["CouponCode"] = "Coupon Code";
    AnalyticsProperties["CurrentUserTime"] = "Current User Time";
    AnalyticsProperties["CurrentUserTimeZone"] = "Current User Time Zone";
    AnalyticsProperties["DaysSinceLastOrder"] = "Days Since Last Order";
    AnalyticsProperties["EditedOrderFromCheckout"] = "Edited Order From Checkout";
    AnalyticsProperties["ElapsedSeconds"] = "Elapsed Seconds";
    AnalyticsProperties["ErrorDescription"] = "Error Description";
    AnalyticsProperties["ErrorType"] = "Error Type";
    AnalyticsProperties["FilterName"] = "Filter Name";
    AnalyticsProperties["HasBasket"] = "Has Basket?";
    AnalyticsProperties["HasCategoryImages"] = "Has Category Images?";
    AnalyticsProperties["HasProductImages"] = "Has Product Images?";
    AnalyticsProperties["HasVisibleCalories"] = "Has Visible Calories?";
    AnalyticsProperties["HasVisiblePrice"] = "Has Visible Price?";
    AnalyticsProperties["IsFeatured"] = "Is Featured?";
    AnalyticsProperties["IsGuest"] = "Is Guest?";
    AnalyticsProperties["IsResponsive"] = "Is Responsive?";
    AnalyticsProperties["IsTranslated"] = "Is Translated?";
    AnalyticsProperties["LinkLocation"] = "Link Location";
    AnalyticsProperties["ListIndex"] = "List Index";
    AnalyticsProperties["LoyaltyMembershipID"] = "Loyalty Membership ID";
    AnalyticsProperties["LoyaltyProvider"] = "Loyalty Provider";
    AnalyticsProperties["LoyaltyRedemption"] = "Loyalty Redemption?";
    AnalyticsProperties["LoyaltyRewardDiscountAmount"] = "Loyalty Reward Discount Amount";
    AnalyticsProperties["LoyaltyRewardID"] = "Loyalty Reward ID";
    AnalyticsProperties["ManualLocationSelectionSkipped"] = "Manual Location Selection Skipped?";
    AnalyticsProperties["NumberOfPaymentTypesUsed"] = "Number of Payment Types Used";
    AnalyticsProperties["OrderEvent"] = "Order Event";
    AnalyticsProperties["OrderId"] = "Order Id";
    AnalyticsProperties["OrderID"] = "Order ID";
    AnalyticsProperties["PageName"] = "Page Name";
    AnalyticsProperties["PageTitle"] = "Page Title";
    AnalyticsProperties["PaymentTypes"] = "Payment Types";
    AnalyticsProperties["PreviousOrderID"] = "Previous Order ID";
    AnalyticsProperties["ProductAvailableOptionGroupCount"] = "Product Available Option Group Count";
    AnalyticsProperties["ProductBasePrice"] = "Product Base Price";
    AnalyticsProperties["ProductCategory"] = "Product Category";
    AnalyticsProperties["ProductName"] = "Product Name";
    AnalyticsProperties["ProductQuantity"] = "Product Quantity";
    AnalyticsProperties["SavedANewCard"] = "Saved a New Card";
    AnalyticsProperties["SelectedHandoffMode"] = "Selected Handoff Mode";
    AnalyticsProperties["SelectedTimeWantedMode"] = "Selected Time Wanted Mode";
    AnalyticsProperties["StoreCity"] = "Store City";
    AnalyticsProperties["StoreName"] = "Store Name";
    AnalyticsProperties["StoreNumber"] = "Store Number";
    AnalyticsProperties["StorePostalCode"] = "Store Postal Code";
    AnalyticsProperties["StoreState"] = "Store State";
    AnalyticsProperties["SupportsArrivalNotifications"] = "Supports Arrival Notifications";
    AnalyticsProperties["SupportsParkingLocation"] = "Supports Parking Location";
    AnalyticsProperties["TimeWantedShown"] = "Time Wanted Shown?";
    AnalyticsProperties["TotalCategories"] = "Total Categories";
    AnalyticsProperties["UsedASavedCard"] = "Used a Saved Card";
    AnalyticsProperties["UtmMedium"] = "utm_medium";
    AnalyticsProperties["ViewedCustomFeesTooltip"] = "Viewed Custom Fees Tooltip";
    AnalyticsProperties["VisibleLabels"] = "Visible Labels";
    AnalyticsProperties["WarningShown"] = "Warning Shown?";
  })(AnalyticsProperties || (_exports.AnalyticsProperties = AnalyticsProperties = {}));

  const ANALYTICS_TYPES = Object.freeze({
    UA: 'ua',
    GTM: 'gtm'
  });
  _exports.ANALYTICS_TYPES = ANALYTICS_TYPES;
  const TIME_WANTED_ANALYTICS_LABELS = {
    Advance: 'Scheduled',
    Immediate: 'ASAP',
    ManualFire: 'Manual'
  };
  _exports.TIME_WANTED_ANALYTICS_LABELS = TIME_WANTED_ANALYTICS_LABELS;
  const NAME_CROSS_CHANNEL = 'crosschannel';
  let GAEventCategory;
  _exports.GAEventCategory = GAEventCategory;

  (function (GAEventCategory) {})(GAEventCategory || (_exports.GAEventCategory = GAEventCategory = {}));

  let GAEventAction;
  _exports.GAEventAction = GAEventAction;

  (function (GAEventAction) {})(GAEventAction || (_exports.GAEventAction = GAEventAction = {}));

  // eslint-disable-next-line ember/no-classic-classes
  let AnalyticsService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember.inject.service, _dec11 = Ember.inject.service, _dec12 = Ember.inject.service, _dec13 = Ember.computed.readOnly('channel.current'), (_class = class AnalyticsService extends Ember.Service.extend({
    _trackerCount: 0,

    newTrackerNumber() {
      const number = this._trackerCount + 1;
      this._trackerCount = number;
      return number;
    },

    _saveEngine(engine) {
      const trackerName = engine.trackerName || `tracker${this.newTrackerNumber()}`;
      const tracker = Object.assign({}, engine, {
        trackerName
      });
      this.analyticsEngines.push(tracker);
      return trackerName;
    },

    _configureUAInstance(engine) {
      var _this$currentChannel$, _this$currentChannel2, _this$currentChannel3;

      if (!this.ga) {
        return _trueMyth.Result.err('Attempted to configure Google Universal Analytics, but it was not loaded on the page');
      }

      const allowLinker = true;
      const internalName = (_this$currentChannel$ = (_this$currentChannel2 = this.currentChannel) == null ? void 0 : _this$currentChannel2.internalName) != null ? _this$currentChannel$ : '';
      const name = engine.isCrossChannel ? NAME_CROSS_CHANNEL : `perchannel${this.newTrackerNumber()}`;
      const options = {
        allowLinker,
        name
      };
      const currentChannelSettings = (_this$currentChannel3 = this.currentChannel) == null ? void 0 : _this$currentChannel3.settings;
      const fullSiteUrl = currentChannelSettings == null ? void 0 : currentChannelSettings.fullSiteUrl;

      if (!engine.isCrossChannel && (0, _isSome.isSome)(fullSiteUrl)) {
        options.cookieDomain = `http://${fullSiteUrl}`;
      }

      if (engine.isCrossChannel) {
        options.sampleRate = _environment.default.googleAnalyticsSampleRate;
      }

      this.ga('create', engine.identifier, options);
      this.ga(`${name}.set`, 'forceSSL', true);
      this.ga(`${name}.require`, 'linker');
      this.ga(`${name}.linker:autoLink`, new RegExp(internalName, 'gi'));
      return _trueMyth.Result.ok(Object.assign({}, engine, {
        trackerName: name
      }));
    },

    _extractConfig(bootstrapData) {
      const analyticsConfig = [];
      const crossChannelAnalytics = bootstrapData.crossChannelAnalytics;

      if ((0, _isSome.isSome)(crossChannelAnalytics)) {
        analyticsConfig.push(crossChannelAnalytics);
      }

      const channelAnalytics = (0, _isSome.isSome)(bootstrapData.channel.analytics) ? bootstrapData.channel.analytics : [];
      return analyticsConfig.concat(channelAnalytics);
    }

  }) {
    constructor(...args) {
      super(...args);
      this.analyticsEngines = [];
      this._initialized = false;
      this._wontInitialize = false;
      this._gtmInitialized = false;
      this._eventQueue = [];
      this._gaQueue = [];
      this._pageviewQueue = [];
      this._ecommerceQueue = [];

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "channel", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _initializerDefineProperty(this, "basket", _descriptor5, this);

      _initializerDefineProperty(this, "vendor", _descriptor6, this);

      _initializerDefineProperty(this, "error", _descriptor7, this);

      _initializerDefineProperty(this, "storage", _descriptor8, this);

      _initializerDefineProperty(this, "bootstrap", _descriptor9, this);

      _initializerDefineProperty(this, "globalData", _descriptor10, this);

      _initializerDefineProperty(this, "device", _descriptor11, this);

      _initializerDefineProperty(this, "globalEvents", _descriptor12, this);

      _initializerDefineProperty(this, "currentChannel", _descriptor13, this);

      _initializerDefineProperty(this, "trackedAppOpen", _descriptor14, this);

      this.loadMixpanelScript = () => {
        if (Ember.testing) {
          // This method can be patched out by integration tests, so use noop methods to avoid loading the real thing
          const mixpanelInstanceStub = {
            track: _2.noop,
            track_links: _2.noop,
            people: {
              set_once: _2.noop,
              increment: _2.noop,
              set: _2.noop
            },
            register: _2.noop,
            register_once: _2.noop,
            identify: _2.noop
          };
          const mixpanelStub = {
            init: () => mixpanelInstanceStub
          };
          return Promise.resolve(mixpanelStub);
        }

        return emberAutoImportDynamic("mixpanel-browser");
      };
    }

    async setup(data) {
      if (!this._initialized && data && !(0, _tracking.doNotTrackSet)() && (0, _isSome.isSome)(this.router.currentRouteName)) {
        try {
          const promises = [];

          if (data.mixpanelAnalyticsId) {
            promises.push(this.bootupMixpanel(data.mixpanelAnalyticsId));
          }

          if (!this.router.currentRouteName.includes('secure')) {
            promises.push(this._configureEngines(data));
          }

          await Promise.all(promises);
        } catch (e) {
          return Promise.reject(e);
        }
      } else {
        this._wontInitialize = true;
        this.flushQueues(); // Ensures any waiting promises are fulfilled even though these will be noops
      }

      return Promise.resolve();
    }

    async _configureEngines(bootstrapData) {
      if (this.isTearingDown) {
        return Promise.resolve();
      } // Initialize GTM instances first because GTM will also try to load GA


      const engines = this._extractConfig(bootstrapData).sort(engine => engine.type === ANALYTICS_TYPES.GTM ? -1 : 1); // Setup each analytics service in sequence


      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;

      var _iteratorError;

      try {
        for (var _iterator = _asyncIterator(engines), _step, _value; _step = await _iterator.next(), _iteratorNormalCompletion = _step.done, _value = await _step.value, !_iteratorNormalCompletion; _iteratorNormalCompletion = true) {
          const engine = _value;

          switch (engine.type) {
            case ANALYTICS_TYPES.UA:
              await this._setupUAEngine(engine);
              break;

            case ANALYTICS_TYPES.GTM:
              await this._setupGTMEngine(engine);
              break;

            default:
              break;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            await _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      if (this.isTearingDown) {
        return Promise.resolve();
      }

      this._initialized = true;
      this.flushQueues();
      return Promise.resolve();
    }

    bootupMixpanel(mixpanelAnalyticsId) {
      return this.loadMixpanelScript().then(x => {
        var _this$currentChannel;

        if (this.isTearingDown) {
          return;
        }

        if (!mixpanelAnalyticsId) {
          return;
        }

        const instance = x.init(mixpanelAnalyticsId, {
          secure_cookie: true,
          debug: true,
          track_events: false,
          cross_subdomain_cookie: false,
          persistence: 'localStorage'
        }, 'mwc');
        Ember.set(this, 'mixpanel', instance);

        if (this.session.currentUser) {
          const mixpanelId = this.session.mixpanelUniqueId || this.session.currentUser.id;
          instance.people.set({
            'User ID': mixpanelId
          });
          instance.identify(mixpanelId);
        }

        instance.people.increment('Page Views');
        instance.register({
          'Client Platform': 'MobileWeb',
          Channel: (_this$currentChannel = this.currentChannel) == null ? void 0 : _this$currentChannel.internalName,
          'Is Hybrid?': _environment.default.isHybrid ? true : false
        });

        if (!this.trackedAppOpen) {
          this.trackedAppOpen = true;
          instance.track('App Open');
        }

        if (this.device.isHybrid && Ember.isEmpty(this.storage.hybridEverOpened)) {
          this.storage.hybridEverOpened = true;
          instance.track('First App Open');
          this.globalEvents.trigger(_globalEvents.GlobalEventName.AppFirstOpen);
        }
      });
    }

    get isTearingDown() {
      return this.isDestroying || this.isDestroyed;
    } // NOTE: this assumes there is only one Google Tag Manager instance.


    async _setupGTMEngine(engine) {
      if (this.isTearingDown) {
        return Promise.resolve();
      }

      this.dataLayer = this.dataLayer || [];

      if (!this._gtmInitialized) {
        this._gtmInitialized = true;
        await (0, _gtm.bootUpGTMInstance)(engine.identifier);
      }

      if (this.isTearingDown) {
        return Promise.resolve();
      }

      this._saveEngine(engine);

      this.updateDataLayer(); // Throttle responding to data changes so we aren't spamming `dataLayer`.
      // GTM (which uses dataLayer for its variables and events) only holds ~250
      // layers at a time, so it's better to update in batches. Also, globalData
      // can be a little noisy when the app is booting up, so better to batch then.
      // eslint-disable-next-line ember/no-observers

      this.globalData.addObserver('data', this, (0, _lodash.default)(this.updateDataLayer, 100, {
        leading: true,
        trailing: true
      }));
      return Promise.resolve();
    }

    async _setupUAEngine(engine) {
      if (Ember.isNone(this.ga)) {
        await (0, _ga.bootUpUAEngine)();
      }

      if (this.isTearingDown) {
        return Promise.resolve();
      }

      this._configureUAInstance(engine).match({
        Ok: configuredEngine => {
          this._saveEngine(configuredEngine);
        },
        Err: e => this.error.reportError(e)
      });

      return Promise.resolve();
    } // Track a Mixpanel event assuming the service is initialized
    // If it is not yet initialized, store the event to track upon initialization
    // Return a promise so that action can be taken _after_ the tracking is complete (like cleanup code)


    trackEvent(eventName, customProperties = () => ({}), opts = {}, resolve = _2.noop, reject = _2.noop) {
      // Track an event or push it into the event queue
      // Accepts a resolve and reject callback
      const track = (res, rej) => {
        if (!this.mixpanel) {
          // If we aren't waiting for initialization, just say things worked so no promises are left unresolved
          if (this._wontInitialize) {
            res();
          } else {
            this._eventQueue.push([eventName, customProperties, opts, res, rej]);
          }

          return;
        }

        const {
          isLink,
          domQuery
        } = opts;

        try {
          const properties = this.getTrackedEventProperties(customProperties());

          if (isLink) {
            this.mixpanel.track_links(domQuery, eventName, properties);
          } else {
            this.mixpanel.track(eventName, properties);
          }

          res();
        } catch (e) {
          this.error.sendExternalError(e);
          rej(e);
        }
      }; // If this event had been pushed into the queue, it will already have
      // resolve and reject callbacks provided from an earlier promise
      // and we need to fulfill those too.


      return new Promise((res, rej) => {
        track(() => {
          res();
          resolve();
        }, e => {
          rej(e);
          reject(e);
        });
      });
    }

    getTrackedEventProperties(customProperties) {
      var _this$channel$setting, _this$basket$basket, _this$storage$orderSu, _vendor;

      // formatted to match Ghost, see PLAT-20752
      function getTimeProperties() {
        return {
          [AnalyticsProperties.CurrentUserTime]: (0, _dayjs.default)().format('HH:mm:ss'),
          [AnalyticsProperties.CurrentUserTimeZone]: (0, _dayjs.default)().format('ZZ')
        };
      }

      function dashToSpace(source) {
        return Object.keys(source).reduce((prev, curr) => _extends({}, prev, {
          [curr.replace(/-/g, ' ')]: source[curr]
        }), {});
      }

      let properties = {};
      properties = _extends({}, dashToSpace(customProperties), getTimeProperties(), {
        [AnalyticsProperties.PageTitle]: document.title,
        [AnalyticsProperties.PageName]: (0, _router.routeToPageName)(this.router.currentRouteName),
        [AnalyticsProperties.IsTranslated]: this.bootstrap.isTranslated,
        [AnalyticsProperties.IsResponsive]: (_this$channel$setting = this.channel.settings) == null ? void 0 : _this$channel$setting.isResponsive,
        [AnalyticsProperties.IsGuest]: !this.session.isLoggedIn
      });
      const basket = (_this$basket$basket = this.basket.basket) != null ? _this$basket$basket : (_this$storage$orderSu = this.storage.orderSubmission) == null ? void 0 : _this$storage$orderSu.basket;

      if (basket) {
        const basketProducts = 'belongsTo' in basket ? basket.basketProducts.toArray() : basket.basketProducts;
        const products = basketProducts.map(product => product.productName);
        const taxTotal = basket.taxes.reduce((currentValue, tax) => currentValue + tax.totalTax, 0);

        const safeFixed = value => (0, _isSome.isSome)(value) ? value.toFixed(2) : undefined;

        properties = _extends({}, properties, {
          [AnalyticsProperties.BasketID]: basket.guid,
          [AnalyticsProperties.BasketProducts]: products,
          [AnalyticsProperties.BasketProductQuantity]: basketProducts.reduce((currentValue, product) => currentValue + product.quantity, 0),
          [AnalyticsProperties.BasketHasDiscount]: basket.vendorDiscount > 0,
          [AnalyticsProperties.BasketSubtotal]: safeFixed(basket.subTotal),
          [AnalyticsProperties.BasketDiscount]: safeFixed(basket.vendorDiscount),
          [AnalyticsProperties.BasketTax]: safeFixed(taxTotal),
          [AnalyticsProperties.BasketDeliveryCharge]: safeFixed(basket.deliveryCharge),
          [AnalyticsProperties.BasketTotal]: safeFixed(basket.total),
          [AnalyticsProperties.BasketTimeWantedMode]: TIME_WANTED_ANALYTICS_LABELS[basket.timeWantedType],
          [AnalyticsProperties.BasketHandoffMode]: basket.handoffMode
        });
      }

      let vendor;

      if (basket && 'belongsTo' in basket) {
        vendor = basket.belongsTo('vendor').value();
      } else {
        vendor = basket == null ? void 0 : basket.vendor;
      }

      vendor = (_vendor = vendor) != null ? _vendor : this.vendor.vendor;

      if (vendor) {
        properties = _extends({}, properties, {
          [AnalyticsProperties.StoreName]: vendor.name,
          [AnalyticsProperties.StoreCity]: vendor.address.city,
          [AnalyticsProperties.StoreState]: vendor.address.state,
          [AnalyticsProperties.StorePostalCode]: vendor.address.postalCode,
          [AnalyticsProperties.StoreNumber]: vendor.externalReference
        });
      }

      return properties;
    }

    get ga() {
      return window.ga;
    }

    get dataLayer() {
      return window.dataLayer;
    }

    set dataLayer(val) {
      window.dataLayer = val;
    }

    trackGAEvent(event) {
      if (!this._initialized && !this._wontInitialize) {
        this._gaQueue.push(event);

        return;
      }

      if (this.ga) {
        this.ga(`${NAME_CROSS_CHANNEL}.send`, {
          hitType: 'event',
          eventCategory: event.category,
          eventAction: event.action,
          eventLabel: event.label,
          eventValue: event.value
        });
      }
    }

    trackPageviewGoogle(page) {
      if (!this._initialized && !this._wontInitialize) {
        this._pageviewQueue.push(page);

        return;
      }

      this.analyticsEngines.forEach(engine => {
        switch (engine.type) {
          case ANALYTICS_TYPES.UA:
            {
              if (this.ga) {
                this.ga(`${engine.trackerName}.send`, 'pageview', page);
              }

              break;
            }

          case ANALYTICS_TYPES.GTM:
            if (this.dataLayer) {
              /**
               * @deprecated as of 10/2020 - remove this when we can verify no brands are using it
               */
              this.dataLayer.push({
                event: 'vpv',
                virtualPagePath: page
              });
            }

            break;

          default:
            break;
        }
      });
    }

    trackPageview(page) {
      this.trackEvent(AnalyticsEvents.ViewPage);
      this.trackPageviewGoogle(page);
    }

    trackEcommerce(order) {
      if (!this._initialized && !this._wontInitialize) {
        this._ecommerceQueue.push(order);

        return;
      }

      this.analyticsEngines.forEach(engine => {
        switch (engine.type) {
          case ANALYTICS_TYPES.UA:
            if (this.ga) {
              const ga = this.ga;
              ga(`${engine.trackerName}.require`, 'ecommerce');
              ga(`${engine.trackerName}.ecommerce:addTransaction`, {
                id: order.transactionId,
                affiliation: order.transactionAffiliation,
                revenue: order.transactionTotal,
                shipping: order.transactionShipping,
                tax: order.transactionTax,
                currency: order.transactionCurrency
              });
              order.transactionProducts.forEach(p => ga(`${engine.trackerName}.ecommerce:addItem`, p));
              ga(`${engine.trackerName}.ecommerce:send`);
            }

            break;

          case ANALYTICS_TYPES.GTM:
            if (this.dataLayer) {
              this.dataLayer.push(order);
            }

            break;

          default:
            break;
        }
      });
    }
    /**
     * @deprecated since 10/2020 in favor of using global events
     */


    updateDataLayer() {
      var _this$dataLayer;

      if (this.isTearingDown) {
        return;
      }

      (_this$dataLayer = this.dataLayer) == null ? void 0 : _this$dataLayer.push(this.globalData.data);
    }
    /*
     * Flush any queued events that were fired before we initialized
     */


    flushQueues() {
      this._gaQueue.forEach(event => this.trackGAEvent(event));

      this._gaQueue = [];

      this._pageviewQueue.forEach(event => this.trackPageviewGoogle(event));

      this._pageviewQueue = [];

      this._ecommerceQueue.forEach(event => this.trackEcommerce(event));

      this._ecommerceQueue = [];

      this._eventQueue.forEach(event => this.trackEvent(...event));

      this._eventQueue = [];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "vendor", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "error", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "bootstrap", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "globalData", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "device", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "globalEvents", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "currentChannel", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "trackedAppOpen", [_computed.session], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AnalyticsService;
});