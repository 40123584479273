define("mobile-web/components/r-custom-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EVTK95dS",
    "block": "[[[11,\"img\"],[16,\"src\",[30,1,[\"url\"]]],[16,\"alt\",[30,1,[\"alt\"]]],[16,\"width\",[30,1,[\"width\"]]],[16,\"height\",[30,1,[\"height\"]]],[24,5,\"max-width: 100%\"],[24,\"data-test-customIcon\",\"\"],[17,2],[12],[13],[1,\"\\n\"]],[\"@icon\",\"&attrs\"],false,[]]",
    "moduleName": "mobile-web/components/r-custom-icon/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});