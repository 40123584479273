define("mobile-web/adapters/telemetry", ["exports", "mobile-web/lib/hybrid-util", "mobile-web/adapters/application"], function (_exports, _hybridUtil, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TelemetryAdapter extends _application.default {
    searchLocationsSkipped() {
      const url = `${(0, _hybridUtil.host)()}/api/telemetry/search-locations-skipped`;
      return this.ajax(url, 'POST');
    }

  }

  _exports.default = TelemetryAdapter;
});