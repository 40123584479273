define("mobile-web/components/r-toggle/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "switch": "_switch_b1c9z7",
    "thumb": "_thumb_b1c9z7"
  };
  _exports.default = _default;
});