define("mobile-web/routes/accessibility", ["exports", "mobile-web/lib/utilities/transform-content"], function (_exports, _transformContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AccessiblityRoute = (_dec = Ember.inject.service, (_class = class AccessiblityRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "channel", _descriptor, this);

      this.titleToken = 'Accessibility Statement';
    }

    beforeModel(transition) {
      var _this$channel$setting;

      if (!((_this$channel$setting = this.channel.settings) != null && _this$channel$setting.enableAudioEye)) {
        transition.abort();
        this.transitionTo('index');
      }
    }

    async model() {
      var _this$channel$current, _this$channel$current2, _this$channel$setting2, _this$channel$setting3, _this$channel$current3, _this$channel$current4;

      const content = await this.store.findRecord('content', 'ACCESSIBILITY_STATEMENT');
      return (0, _transformContent.transformContent)(content.value, _transformContent.ContentType.HTML, {
        ChannelName: (_this$channel$current = (_this$channel$current2 = this.channel.current) == null ? void 0 : _this$channel$current2.name) != null ? _this$channel$current : '',
        ChannelDomain: (_this$channel$setting2 = (_this$channel$setting3 = this.channel.settings) == null ? void 0 : _this$channel$setting3.fullSiteUrl) != null ? _this$channel$setting2 : '',
        ChannelCDN: (_this$channel$current3 = (_this$channel$current4 = this.channel.current) == null ? void 0 : _this$channel$current4.cdnUrl) != null ? _this$channel$current3 : ''
      });
    } // Private AudioEye API to init AE specific links


    afterModel() {
      Ember.run.scheduleOnce('afterRender', this, this.initAudioEye);
    }

    initAudioEye() {
      if (window.hasOwnProperty('AudioEye')) {
        var _window$AudioEye, _window$AudioEye$Clie;

        // @ts-ignore
        (_window$AudioEye = window.AudioEye) == null ? void 0 : (_window$AudioEye$Clie = _window$AudioEye.ClientElementApi) == null ? void 0 : _window$AudioEye$Clie.init();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AccessiblityRoute;
});