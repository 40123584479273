define("mobile-web/app", ["exports", "ember-load-initializers", "ember-resolver", "mobile-web/config/environment", "mobile-web/lib/errors"], function (_exports, _emberLoadInitializers, _emberResolver, _environment, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class App extends Ember.Application {
    constructor(...args) {
      super(...args);
      this.modulePrefix = _environment.default.modulePrefix;
      this.podModulePrefix = _environment.default.podModulePrefix;
      this.Resolver = _emberResolver.default;
    }

  }

  _exports.default = App;

  if (_environment.default.environment === 'development') {
    // Leaving this unsquelched in dev mode leads to infinite loops
    (0, _errors.squelchAllErrorHandlers)();
  }

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
});