define("mobile-web/components/r-credit-card-modal/loading/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "mobile-web/lib/utilities/_", "mobile-web/components/r-credit-card-modal/loading/style"], function (_exports, _glimmerComponent, _, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CreditCardModalLoading extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get inputEachArray() {
      const length = this.args.addressFields ? 9 : 4;
      return (0, _.range)(0, length);
    } // Constructor
    // Other methods
    // Tasks
    // Actions


  }

  _exports.default = CreditCardModalLoading;
});