define("mobile-web/components/r-post-checkout/r-subsection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OBA9XkG8",
    "block": "[[[10,0],[15,0,[30,0,[\"rootClass\"]]],[14,\"data-test-post-checkout-subsection\",\"\"],[12],[1,\"\\n\"],[41,[33,1],[[[1,\"    \"],[46,[33,1],null,[[\"class\"],[[30,0,[\"style\",\"icon\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[15,0,[30,0,[\"style\",\"content\"]]],[12],[1,\"\\n\"],[41,[33,3],[[[1,\"      \"],[10,\"h3\"],[15,0,[30,0,[\"style\",\"heading\"]]],[15,\"data-test-subsection-heading\",[36,3]],[12],[1,[34,3]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[18,1,[[28,[37,5],null,[[\"lineItemContainer\",\"lineItem\"],[[50,\"r-post-checkout/r-subsection/r-line-item-container\",0,null,null],[50,\"r-post-checkout/r-subsection/r-line-item\",0,null,null]]]]]],[1,\"\\n    \"],[18,2,[[28,[37,5],null,[[\"buttonContainer\",\"buttonClass\"],[[50,\"r-post-checkout/r-subsection/r-button-container\",0,null,null],[30,0,[\"style\",\"button\"]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&content\",\"&buttons\"],false,[\"if\",\"icon\",\"component\",\"heading\",\"yield\",\"hash\"]]",
    "moduleName": "mobile-web/components/r-post-checkout/r-subsection/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});