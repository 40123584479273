define("mobile-web/components/r-vendor-info-modal/calendar/component", ["exports", "@ember-decorators/component", "ember-animated/transitions/fade", "mobile-web/lib/animation", "mobile-web/lib/order-criteria", "mobile-web/lib/time/calendar", "mobile-web/lib/time/weekly-schedule", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-vendor-info-modal/calendar/style"], function (_exports, _component, _fade, _animation, _orderCriteria, _calendar, _weeklySchedule, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let VendorInfoModalCalendar = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.computed.alias('vendor.weeklySchedule.currentWeekDay'), _dec4 = Ember.computed('calendar.schedule.@each.weekDay', 'weekDay'), _dec5 = Ember.computed('calendar.scheduleDescription'), _dec6 = Ember.computed('handoffMode', 'vendor.{minimumPickupOrder,minimumDeliveryOrder}'), _dec7 = Ember.computed('calendar.schedule.@each.weekDay', 'weekDay'), _dec(_class = (_class2 = class VendorInfoModalCalendar extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      this.animationDuration = (0, _animation.getDuration)(400);
      this.transition = _fade.default;
      this.style = _style.default;

      _initializerDefineProperty(this, "weekDay", _descriptor2, this);
    }

    get currentSchedule() {
      return (0, _weeklySchedule.hoursForDay)(this.weekDay, this.calendar);
    }

    get handoffMode() {
      var _this$store$peekAll$f;

      if (this.calendar.scheduleDescription === _calendar.CalendarType.Business) {
        return 'CounterPickup';
      }

      return (_this$store$peekAll$f = this.store.peekAll('handoff-mode').find(h => h.get('label') === this.calendar.scheduleDescription)) == null ? void 0 : _this$store$peekAll$f.get('type');
    }

    get handoffMinimum() {
      if ((0, _isSome.default)(this.handoffMode)) {
        const minimumDelivery = this.vendor.minimumDeliveryOrder;
        const minimumPickup = this.vendor.minimumPickupOrder;

        if ((0, _orderCriteria.isDeliveryMode)(this.handoffMode)) {
          return minimumDelivery;
        }

        if ((0, _orderCriteria.isAtStoreMode)(this.handoffMode)) {
          return minimumPickup;
        }
      }

      return 0;
    }

    get sortedSchedule() {
      const currentIdx = this.calendar.schedule.findIndex(s => s.weekDay === this.weekDay);
      return [...this.calendar.schedule.slice(currentIdx), ...this.calendar.schedule.slice(0, currentIdx)];
    } // Init


    init() {
      super.init();
      (false && !((0, _isSome.default)(this.calendar)) && Ember.assert('`calendar` is required', (0, _isSome.default)(this.calendar)));
      (false && !((0, _isSome.default)(this.vendor)) && Ember.assert('`vendor` is required', (0, _isSome.default)(this.vendor)));
    } // Other methods
    // Action


  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "weekDay", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "currentSchedule", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "currentSchedule"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handoffMode", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "handoffMode"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handoffMinimum", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "handoffMinimum"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "sortedSchedule", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "sortedSchedule"), _class2.prototype)), _class2)) || _class);
  _exports.default = VendorInfoModalCalendar;
});