define("mobile-web/serializers/order", ["exports", "ember-data", "mobile-web/lib/utilities/is-some"], function (_exports, _emberData, _isSome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class OrderSerializer extends _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);
      this.attrs = {
        deliveryAddress: {
          embedded: 'always'
        }
      };
    }

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var _payload$vendor;

      if ((0, _isSome.default)(payload == null ? void 0 : payload.order) && Ember.isNone(payload.order.currency) && (0, _isSome.default)(payload == null ? void 0 : (_payload$vendor = payload.vendor) == null ? void 0 : _payload$vendor.currency)) {
        payload.order.currency = payload.vendor.currency;
      }

      return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
    }

  }

  _exports.default = OrderSerializer;
});