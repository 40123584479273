define("mobile-web/components/r-no-vendor-results/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "wrapper": "_wrapper_1e4idf",
    "content": "_content_1e4idf"
  };
  _exports.default = _default;
});