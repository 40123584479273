define("mobile-web/components/r-map-window/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gmW5RcoL",
    "block": "[[[11,0],[16,0,[30,0,[\"style\",\"root\"]]],[24,\"data-test-mapWindow\",\"\"],[17,1],[12],[1,\"\\n  \"],[11,0],[16,0,[30,0,[\"style\",\"map\"]]],[4,[38,0],[[30,0,[\"insertElement\"]]],null],[4,[38,1],[[30,0,[\"updateMap\"]],[30,2]],null],[12],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@markers\"],false,[\"did-insert\",\"did-update\"]]",
    "moduleName": "mobile-web/components/r-map-window/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});