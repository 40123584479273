define("mobile-web/adapters/order", ["exports", "mobile-web/lib/hybrid-util", "mobile-web/adapters/application"], function (_exports, _hybridUtil, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildOrderURL = buildOrderURL;
  _exports.default = _exports.OrderRequestType = void 0;
  let OrderRequestType;
  _exports.OrderRequestType = OrderRequestType;

  (function (OrderRequestType) {
    OrderRequestType["Reorder"] = "reorder";
    OrderRequestType["Edit"] = "edit";
    OrderRequestType["Arrival"] = "arrival";
  })(OrderRequestType || (_exports.OrderRequestType = OrderRequestType = {}));

  function buildOrderURL(namespace, requestType, id) {
    if (requestType === OrderRequestType.Reorder) {
      return `${(0, _hybridUtil.host)()}/${namespace}/baskets/order/${id}`;
    }

    if (requestType === OrderRequestType.Edit) {
      return `${(0, _hybridUtil.host)()}/${namespace}/orders/${id}/update`;
    }

    if (requestType === OrderRequestType.Arrival) {
      return `${(0, _hybridUtil.host)()}/${namespace}/orders/${id}/arrived`;
    }

    return undefined;
  }

  class OrderAdapter extends _application.default {
    urlForFindRecord(id, modelName, snapshot) {
      const hash = snapshot.adapterOptions ? snapshot.adapterOptions.hash : undefined;

      if (hash) {
        const baseUrl = this.buildURL('order');
        return `${baseUrl}/${id}?hash=${encodeURIComponent(hash)}`;
      }

      return super.urlForFindRecord(id, modelName, snapshot);
    }

    checkIn(id, hash) {
      const baseUrl = this.buildURL('order');
      const url = `${baseUrl}/${id}/checkin`;
      const data = {
        id,
        hash
      };
      return this.ajax(url, 'POST', {
        data
      });
    }

    arrived(id, hash, message) {
      const baseUrl = this.buildURL('order');
      const url = `${baseUrl}/${id}/arrived`;
      const data = {
        id,
        hash,
        message
      };
      return this.ajax(url, 'POST', {
        data
      });
    }

    cancel(id) {
      const baseUrl = this.buildURL('order');
      const url = `${baseUrl}/${id}/cancel`;
      return this.ajax(url, 'POST');
    }

    buildURL(_modelName, id, _snapshot, requestType) {
      const url = buildOrderURL(this.namespace, requestType, id);
      return url != null ? url : super.buildURL(...arguments);
    }

  }

  _exports.default = OrderAdapter;
});