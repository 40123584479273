define("mobile-web/routes/region-results", ["exports", "mobile-web/lib/state"], function (_exports, _state) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let RegionResultsRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class RegionResultsRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "challenge", _descriptor, this);

      _initializerDefineProperty(this, "channel", _descriptor2, this);
    }

    titleToken() {
      const params = this.paramsFor(this.routeName);
      return (0, _state.stateFullName)(params.region_code) + ' Locations';
    }

    beforeModel(transition) {
      var _this$channel$setting;

      if (!((_this$channel$setting = this.channel.settings) != null && _this$channel$setting.showParticipatingRestaurants)) {
        transition.abort();
        this.transitionTo('index');
      }

      const region = transition.to.params.region_code;

      if (region !== region.toUpperCase()) {
        transition.abort();
        this.transitionTo('region-results', region.toUpperCase());
      }
    }

    model(params) {
      return this.store.query('vendor-search-result', params.region_code);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "challenge", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = RegionResultsRoute;
});