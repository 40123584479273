define("mobile-web/components/r-feedback-banner/component", ["exports", "@glimmer/component", "mobile-web/config/environment", "mobile-web/lib/animation", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/keys", "mobile-web/services/user-feedback", "mobile-web/components/r-feedback-banner/style"], function (_exports, _component, _environment, _animation, _, _keys, _userFeedback, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const FOCUS_ROOT = '[data-focus-root]';
  let FeedbackBanner = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class FeedbackBanner extends _component.default {
    // Service injections
    // Passthroughs
    // Tracked properties
    // Getters and setters
    get feedbackActions() {
      return this.args.model.actions.map(({
        label,
        fn: original,
        variant
      }) => ({
        label,
        fn: () => {
          this.userFeedback.clearAll();
          return original();
        },
        variant
      }));
    }

    get cancelAction() {
      return this.args.model.actions.find(a => a.cancelAction);
    }

    get variantStyle() {
      return _style.default[this.args.model.type];
    }

    get icon() {
      return _userFeedback.ICONS[this.args.model.type];
    }

    get titleId() {
      return Ember.String.camelize(this.args.model.title);
    } // Constructor


    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "announcer", _descriptor, this);

      _initializerDefineProperty(this, "focus", _descriptor2, this);

      _initializerDefineProperty(this, "userFeedback", _descriptor3, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "escapeHandler", _descriptor4, this);

      _initializerDefineProperty(this, "rootElement", _descriptor5, this);

      _initializerDefineProperty(this, "runLater", _descriptor6, this);

      if (this.args.model.actions.length === 0 && _environment.default.environment !== 'test') {
        this.runLater = Ember.run.later(this, () => {
          this.close();
        }, (0, _animation.getDuration)(8000));
      }

      Ember.run.next(this, () => {
        document.addEventListener('click', this.onClick, true);
      });
    } // Other methods
    // Tasks
    // Actions


    onClick(e) {
      if (!this.rootElement.contains(e.target)) {
        var _this$cancelAction;

        this.close((_this$cancelAction = this.cancelAction) == null ? void 0 : _this$cancelAction.fn);
      }
    }

    didInsert() {
      this.announcer.announce(`${this.args.model.title} ${this.args.model.message}`, 'polite');
      this.escapeHandler = (0, _keys.bindActionToKeyEvent)(_keys.Key.Escape, () => {
        var _this$cancelAction2;

        return this.close((_this$cancelAction2 = this.cancelAction) == null ? void 0 : _this$cancelAction2.fn);
      });
      document.addEventListener('keydown', this.escapeHandler, true);
      const firstFocusSelector = this.args.model.actions.length > 0 ? '[data-button="0"]' : '[data-close]';
      const firstFocusEl = document.querySelector(firstFocusSelector);
      const focusRootEl = document.querySelector(FOCUS_ROOT);

      if (focusRootEl) {
        this.focus.addTrap(focusRootEl, firstFocusEl != null ? firstFocusEl : focusRootEl);
      }

      const focuser = firstFocusEl ? () => {
        if (!this.isDestroying && !this.isDestroyed) {
          this.focus.setLastFocus();
          firstFocusEl.focus();
        }
      } : _.noop; // Schedule setting focus to run *after* animation executes.

      Ember.run.later(this, focuser, 1);
    }

    willDestroy() {
      const el = document.querySelector(FOCUS_ROOT);

      if (el) {
        this.focus.removeTrap(el);
      }

      document.removeEventListener('keydown', this.escapeHandler, true);
      document.removeEventListener('click', this.onClick, true);
      Ember.run.cancel(this.runLater);
      this.focus.focusLast();
    }

    close(callback) {
      this.rootElement.classList.add(_style.default.closeAnimation);
      Ember.run.later(this, () => {
        callback == null ? void 0 : callback();
        this.userFeedback.clear(this.args.model);
      }, //wait for closing animation to end
      (0, _animation.getDuration)(200));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "announcer", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "focus", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "userFeedback", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "escapeHandler", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "rootElement", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "runLater", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onClick", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroy", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype)), _class));
  _exports.default = FeedbackBanner;
});