define("mobile-web/components/r-link-rewards/button/component", ["exports", "@ember-decorators/component", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-link-rewards/button/style"], function (_exports, _component, _, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LinkRewardsButton = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed('memberships.[]', 'schemes.@each.canLink'), _dec7 = Ember.computed('schemes.@each.canLink', 'linkedSchemeNames', 'session.isLoggedIn'), _dec8 = Ember.computed('canLink', 'hasRewardsAvailable'), _dec9 = Ember.computed('schemes', 'session.isLoggedIn', 'vendor.id'), _dec10 = Ember._action, _dec(_class = (_class2 = class LinkRewardsButton extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      this.isOpen = false;
      this.style = _style.default;
    }

    // Computed properties
    get show() {
      return !(0, _.isEmpty)(this.memberships) || this.schemes.any(s => s.canLink);
    }

    get canLink() {
      return this.session.isLoggedIn && this.schemes.any(s => s.canLink && !this.linkedSchemeNames.includes(s.schemeName));
    }

    get buttonText() {
      if (this.canLink) {
        return this.intl.t('mwc.linkRewards.linkRewardsButton');
      }

      if (this.hasRewardsAvailable) {
        return this.intl.t('mwc.linkRewards.rewardsAvailableButton');
      }

      return this.intl.t('mwc.linkRewards.viewRewardsButton');
    }

    get hasRewardsAvailable() {
      return this.session.isLoggedIn && this.store.peekAll('loyalty-account').filter(a => {
        var _this$schemes$findBy;

        return ((_this$schemes$findBy = this.schemes.findBy('id', a.id)) == null ? void 0 : _this$schemes$findBy.vendor.id) === this.vendor.id;
      }).any(a => a.qualifyingLoyaltyRewards.length > 0);
    } // Init


    init() {
      super.init();
      (false && !((0, _isSome.default)(this.memberships)) && Ember.assert('`memberships` are required', (0, _isSome.default)(this.memberships)));
      (false && !((0, _isSome.default)(this.schemes)) && Ember.assert('`schemes` are required', (0, _isSome.default)(this.schemes)));
      (false && !((0, _isSome.default)(this.vendor)) && Ember.assert('`vendor` is required', (0, _isSome.default)(this.vendor)));
      (false && !((0, _isSome.default)(this.onLink)) && Ember.assert('`onLink` is required', (0, _isSome.default)(this.onLink)));
      (false && !((0, _isSome.default)(this.linkedSchemeNames)) && Ember.assert('`linkedSchemeNames` are required', (0, _isSome.default)(this.linkedSchemeNames)));
    } // Other methods
    // Actions


    onClick() {
      if (this.session.isLoggedIn) {
        if (this.canLink) {
          this.onLink(!this.isOpen);
        } else {
          this.router.transitionTo('menu.vendor.rewards', this.vendor.slug);
        }
      } else {
        this.session.transitionToLogin();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "show", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "show"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "canLink", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "canLink"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "buttonText", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "buttonText"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "hasRewardsAvailable", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "hasRewardsAvailable"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onClick", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "onClick"), _class2.prototype)), _class2)) || _class);
  _exports.default = LinkRewardsButton;
});