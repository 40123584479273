define("mobile-web/components/r-menu-category/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "mobile-web/lib/utilities/_", "mobile-web/services/global-events", "mobile-web/components/r-menu-category/style"], function (_exports, _glimmerComponent, _, _globalEvents, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const PRODUCT_VISIBLE_THRESHOLD = 0.5;
  let MenuCategory = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember.computed('products', 'storage.showFullMenu'), _dec6 = Ember._action, _dec7 = Ember._action, (_class = class MenuCategory extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "channel", _descriptor, this);

      _initializerDefineProperty(this, "globalEvents", _descriptor2, this);

      _initializerDefineProperty(this, "storage", _descriptor3, this);

      this.style = _style.default;
      this.categoryIntersectionObserver = new IntersectionObserver(this.activate);
      this.productIntersectionObserver = new IntersectionObserver(this.trackVisibleProducts, {
        threshold: [PRODUCT_VISIBLE_THRESHOLD]
      });
      this.viewedProducts = new Set();

      _initializerDefineProperty(this, "isActive", _descriptor4, this);
    }

    // Getters and setters

    /**
     * All of the products in the category.
     */
    get products() {
      return this.args.category.products;
    }
    /**
     * All of the products to be shown in the category. This filters out the
     * unavailable products when the "View Full Menu" toggle is off.
     */


    get shownProducts() {
      return this.products.filter(product => this.storage.showFullMenu || product.isAvailable);
    }
    /**
     * The total number of products to show in the category.
     */


    get productCount() {
      return this.shownProducts.length;
    }

    get showCategoryName() {
      var _this$channel$setting;

      return (0, _.isEmpty)(this.args.category.images) || ((_this$channel$setting = this.channel.settings) == null ? void 0 : _this$channel$setting.showMenuCategoryNames) || false;
    } // Constructor
    // Other methods
    // Tasks
    // Actions


    activate(entries) {
      if (this.isActive) {
        return;
      }

      for (const entry of entries) {
        if (entry.isIntersecting) {
          this.isActive = true;
          break;
        }
      }
    }

    trackVisibleProducts(entries) {
      const products = entries.filter(entry => entry.intersectionRatio > PRODUCT_VISIBLE_THRESHOLD).map(entry => entry.target).map(target => {
        var _target$dataset$index;

        return +((_target$dataset$index = target.dataset.index) != null ? _target$dataset$index : '');
      }).map(index => {
        var _this$shownProducts$o;

        return (_this$shownProducts$o = this.shownProducts.objectAt(index)) == null ? void 0 : _this$shownProducts$o.serializeForGlobalData();
      }).filter(product => {
        var _ref;

        return (_ref = product && !this.viewedProducts.has(product.id)) != null ? _ref : false;
      });

      if (!products.length) {
        return;
      }

      products.forEach(product => this.viewedProducts.add(product.id));
      this.globalEvents.trigger(_globalEvents.GlobalEventName.ProductsVisible, products);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "globalEvents", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isActive", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      var _this$args$isActive;

      return (_this$args$isActive = this.args.isActive) != null ? _this$args$isActive : false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "shownProducts", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "shownProducts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "activate", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "activate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "trackVisibleProducts", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "trackVisibleProducts"), _class.prototype)), _class));
  _exports.default = MenuCategory;
});