define("mobile-web/components/r-distance-display/component", ["exports", "@glimmer/component", "mobile-web/components/r-distance-display/style"], function (_exports, _component, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DistanceDisplay extends _component.default {
    constructor(...args) {
      super(...args);
      this.ariaUnit = 'miles';
      this.unit = 'mi';
      this.style = _style.default;
    }

    get formattedValue() {
      var _this$args$value;

      return ((_this$args$value = this.args.value) != null ? _this$args$value : 0).toFixed(1);
    }

    get ariaLabel() {
      return `${this.formattedValue} ${this.ariaUnit}`;
    }

    get label() {
      return `${this.formattedValue} ${this.unit}`;
    }

  }

  _exports.default = DistanceDisplay;
});