define("mobile-web/components/r-product-customization/requirement/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6S39qhtq",
    "block": "[[[10,1],[15,0,[30,0,[\"rootStyle\"]]],[15,\"data-test-productCustomization-requirement\",[30,0,[\"testState\"]]],[12],[1,\"\\n  \"],[1,[52,[30,1],[28,[37,1],[\"mwc.productCustomization.completedText\"],null]]],[1,\"\\n  \"],[8,[39,2],null,[[\"@ariaLabel\",\"@class\",\"@icon\"],[\"\",[30,0,[\"style\",\"icon\"]],[30,0,[\"icon\"]]]],null],[1,\"\\n  \"],[1,[52,[51,[30,1]],[28,[37,1],[\"mwc.productCustomization.requiredText\"],null]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@isCompleted\"],false,[\"if\",\"t\",\"r-svg\",\"unless\"]]",
    "moduleName": "mobile-web/components/r-product-customization/requirement/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});