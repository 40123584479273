define("mobile-web/components/routes/locations-route/component", ["exports", "@glimmer/component", "mobile-web/lib/state", "mobile-web/services/analytics", "mobile-web/components/routes/locations-route/style"], function (_exports, _component, _state, _analytics, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LocationsRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class LocationsRoute extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "analytics", _descriptor3, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "mapElement", _descriptor4, this);
    }

    // Getters and setters
    get locationsByCountry() {
      const locationsByCountry = {};
      const allStates = (0, _state.allStatesWithCountry)();
      this.args.model.regions.sortBy('name').forEach(region => {
        var _locationsByCountry$c;

        const code = allStates[region.code].countryCode;
        (locationsByCountry[code] = (_locationsByCountry$c = locationsByCountry[code]) != null ? _locationsByCountry$c : []).push(region);
      });
      return Object.entries(locationsByCountry).sort();
    }

    get hasIntlLocations() {
      return Object.keys(this.locationsByCountry).length > 1;
    }

    get spansMultipleRegions() {
      return this.args.model.regions.length > 1;
    }

    get mapSvg() {
      return Ember.String.htmlSafe(this.args.model.map);
    }

    get mapGraphicRegions() {
      if (this.mapElement) {
        return Array.from(this.mapElement.querySelectorAll('[id^="map"]').values()).map(ele => ({
          element: ele,
          name: (0, _state.stateFullName)(this.stripMapIdPrefix(ele.id))
        }));
      }

      return [];
    }

    get popperOptions() {
      return {
        modifiers: {
          flip: {
            boundariesElement: this.mapElement
          }
        }
      };
    } // Constructor
    // Other methods


    stripMapIdPrefix(id) {
      return id.split('-').pop();
    } // Tasks
    // Actions


    renderMap(element) {
      var _this$mapElement, _this$mapElement$firs;

      this.mapElement = element;
      (_this$mapElement = this.mapElement) == null ? void 0 : (_this$mapElement$firs = _this$mapElement.firstElementChild) == null ? void 0 : _this$mapElement$firs.classList.add(this.style.mapSvg);
      const modelRegions = this.args.model.regions.map(region => region.id);
      this.mapGraphicRegions.filter(region => modelRegions.includes(this.stripMapIdPrefix(region.element.id))).forEach(region => {
        region.element.addEventListener('click', this.redirectToRegion);
        region.element.classList.add(this.style.activeRegion);
      });
    }

    redirectToRegion(event) {
      this.analytics.trackEvent(_analytics.AnalyticsEvents.ViewRegionsLocations, () => ({
        [_analytics.AnalyticsProperties.LinkLocation]: 'Map'
      }));
      const target = event.currentTarget;
      this.router.transitionTo('region-results', this.stripMapIdPrefix(target.id));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "mapElement", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "renderMap", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "renderMap"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "redirectToRegion", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "redirectToRegion"), _class.prototype)), _class));
  _exports.default = LocationsRoute;
});