define("mobile-web/services/device", ["exports", "@capacitor/app", "@capacitor/app-launcher", "@capacitor/browser", "@capacitor/core", "@capacitor/dialog", "@capacitor/storage", "compare-versions", "mobile-web/config/environment", "mobile-web/lib/app-store", "mobile-web/lib/utilities/_"], function (_exports, _app, _appLauncher, _browser, _core, _dialog, _storage, _compareVersions, _environment, _appStore, _) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SERVE_APP_TOKEN_KEY = _exports.LAST_BACKGROUNDED_KEY = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // https://stackoverflow.com/a/9039885/4326495
  const IS_IOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream; // https://stackoverflow.com/a/28236817/4326495

  const IS_ANDROID = /(android)/i.test(navigator.userAgent);
  const LAST_BACKGROUNDED_KEY = 'lastBackgrounded';
  _exports.LAST_BACKGROUNDED_KEY = LAST_BACKGROUNDED_KEY;
  const SERVE_APP_TOKEN_KEY = 'serveAppToken';
  _exports.SERVE_APP_TOKEN_KEY = SERVE_APP_TOKEN_KEY;
  let DeviceService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, (_class = class DeviceService extends Ember.Service {
    get platform() {
      return _core.Capacitor.getPlatform();
    }

    get isIOS() {
      return this.platform === 'ios' || IS_IOS;
    }

    get isAndroid() {
      return this.platform === 'android' || IS_ANDROID;
    }

    get isWeb() {
      return this.platform === 'web';
    }

    get isHybrid() {
      return _environment.default.isHybrid || _core.Capacitor.isNativePlatform();
    }

    get isWebIOS() {
      return this.isWeb && this.isIOS;
    }

    get isWebAndroid() {
      return this.isWeb && this.isAndroid;
    }

    get isWebOnly() {
      return this.isWeb && !this.isAndroid && !this.isIOS;
    }

    get isHybridIOS() {
      return this.isHybrid && this.isIOS;
    }

    get isHybridAndroid() {
      return this.isHybrid && this.isAndroid;
    }

    get appId() {
      var _this$appInfo$id, _this$appInfo, _this$channel$current, _this$channel$current2;

      return (_this$appInfo$id = (_this$appInfo = this.appInfo) == null ? void 0 : _this$appInfo.id) != null ? _this$appInfo$id : this.isHybridAndroid ? (_this$channel$current = this.channel.current) == null ? void 0 : _this$channel$current.androidAppIdentifier : this.isHybridIOS ? (_this$channel$current2 = this.channel.current) == null ? void 0 : _this$channel$current2.iOSAppIdentifier : undefined;
    }

    get nativeAppVersion() {
      var _this$appInfo$version, _this$appInfo2;

      return (_this$appInfo$version = (_this$appInfo2 = this.appInfo) == null ? void 0 : _this$appInfo2.version) != null ? _this$appInfo$version : undefined;
    }

    get storeId() {
      var _this$appInfo3;

      return this.isHybridAndroid ? (_this$appInfo3 = this.appInfo) == null ? void 0 : _this$appInfo3.id : this.isHybridIOS ? window.Olo.appStoreId : undefined;
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "channel", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "media", _descriptor4, this);

      _initializerDefineProperty(this, "appInfo", _descriptor5, this);

      _initializerDefineProperty(this, "nativeUpdateRequired", _descriptor6, this);

      _initializerDefineProperty(this, "viewport", _descriptor7, this);

      if (_core.Capacitor.isNativePlatform()) {
        (async () => {
          // App.getInfo throws an error in web
          this.appInfo = await _app.App.getInfo();
        })();
      }

      this.nativeUpdateRequired = false;
      this.viewport = this.determineViewport();
      this.listenForViewportChange();
    }

    exitApp() {
      if (this.isHybridAndroid) {
        _app.App.exitApp();
      }
    }

    async openInAppBrowser(url) {
      await _browser.Browser.open({
        url
      });
    }

    async launchApp(url) {
      await _appLauncher.AppLauncher.openUrl({
        url
      });
    }

    serializeDeviceForGlobalData() {
      return {
        isHybrid: this.isHybrid,
        isHybridAndroid: this.isHybridAndroid,
        isHybridIOS: this.isHybridIOS
      };
    }

    async checkForNativeUpdate(skipMinWaitTime = false) {
      if (this.isHybrid && this.appId && ((await this.shouldCheckForNativeUpdate()) || skipMinWaitTime) && (await this.isNativeUpdateRequired())) {
        this.nativeUpdateRequired = true;
        await _dialog.Dialog.alert({
          title: this.intl.t('mwc.nativeUpdateModal.title'),
          message: this.intl.t('mwc.nativeUpdateModal.message'),
          buttonTitle: this.isHybridAndroid ? this.intl.t('mwc.nativeUpdateModal.positiveButtonAndroid') : this.intl.t('mwc.nativeUpdateModal.positiveButtoniOS')
        });
        const appStoreLink = this.isAndroid ? (0, _appStore.googleStoreListingLink)(this.appId) : (0, _appStore.appleStoreListingLink)(this.appId);
        await _appLauncher.AppLauncher.openUrl({
          url: appStoreLink
        });
      } else {
        this.nativeUpdateRequired = false;
      }
    }

    async storageGet(key) {
      if (this.isHybrid) {
        var _result$value;

        const result = await _storage.Storage.get({
          key
        });
        return (_result$value = result == null ? void 0 : result.value) != null ? _result$value : undefined;
      }

      return Promise.resolve(undefined);
    }

    async storageRemove(key) {
      if (this.isHybrid) {
        await _storage.Storage.remove({
          key
        });
      }
    }

    async storageSet(key, value) {
      if (this.isHybrid) {
        await _storage.Storage.set({
          key,
          value
        });
      }
    }

    async shouldCheckForNativeUpdate() {
      const lastBackgrounded = await _storage.Storage.get({
        key: LAST_BACKGROUNDED_KEY
      });
      const minBackgroundingTime = this.nativeUpdateRequired ? 0 : 30;
      return (Date.now() - new Date(parseInt(lastBackgrounded.value, 10)).valueOf()) / 60000 > minBackgroundingTime;
    }

    async isNativeUpdateRequired() {
      var _this$nativeAppVersio;

      const nativeVersion = (_this$nativeAppVersio = this.nativeAppVersion) != null ? _this$nativeAppVersio : '';
      const nativeAppManifestResponse = await fetch(`${_environment.default.scriptBaseUrl + (_environment.default.environment === 'production' ? '../mobile-web-client-hybrid-app-manifest/' : '')}olo-native-app-manifest.json?t=${Date.now()}`);

      if (!nativeAppManifestResponse.ok) {
        return false;
      }

      const nativeAppManifest = JSON.parse(await nativeAppManifestResponse.text());
      const appEntry = nativeAppManifest.apps.find(app => {
        var _this$channel$current3;

        return app.internalName === ((_this$channel$current3 = this.channel.current) == null ? void 0 : _this$channel$current3.internalName);
      });

      if (appEntry === undefined) {
        return false;
      }

      const minimumVersion = this.isAndroid ? appEntry.android.minimumVersion : appEntry.ios.minimumVersion;
      return (0, _compareVersions.compare)(minimumVersion, nativeVersion, '>');
    }

    listenForViewportChange() {
      let matches;
      const cb = (0, _.debounce)(() => {
        if (matches !== this.media.matches) {
          this.viewport = this.determineViewport();
        }

        matches = this.media.matches;
      }, 100);
      window.addEventListener('resize', cb);
    }

    determineViewport() {
      if (this.media.isDesktop) {
        return 'Desktop';
      } else if (this.media.isTablet) {
        return 'Tablet';
      }

      return 'Mobile';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "media", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "appInfo", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "nativeUpdateRequired", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "viewport", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = DeviceService;
});