define("mobile-web/lib/utilities/add-script", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addScript = addScript;

  function addScript(src, onLoad) {
    const existing = document.querySelector(`script[src="${src}"]`);

    if (existing) {
      onLoad == null ? void 0 : onLoad();
      return;
    }

    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.type = 'text/javascript';
    script.src = src;

    if (onLoad) {
      script.onload = onLoad;
    }

    document.body.appendChild(script);
  }
});