define("mobile-web/components/r-link-to/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // eslint-disable-next-line ember/no-classic-classes, ember/require-tagless-components
  let LinkTo = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class LinkTo extends Ember.LinkComponent.extend({
    attributeBindings: ['testSelector:data-test-link-to']
  }) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "sideMenu", _descriptor, this);

      _initializerDefineProperty(this, "userFeedback", _descriptor2, this);
    }

    click() {
      this.sideMenu.close();
      this.userFeedback.clearAll();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sideMenu", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "userFeedback", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = LinkTo;
});