define("mobile-web/routes/thank-you", ["exports", "lodash.clonedeep", "mobile-web/lib/payment/method", "mobile-web/services/analytics", "mobile-web/services/global-events"], function (_exports, _lodash, _method, _analytics, _globalEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ThankYouRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.computed.readOnly('session.isLoggedIn'), _dec10 = Ember._action, (_class = class ThankYouRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "analytics", _descriptor3, this);

      _initializerDefineProperty(this, "window", _descriptor4, this);

      _initializerDefineProperty(this, "vendor", _descriptor5, this);

      _initializerDefineProperty(this, "storage", _descriptor6, this);

      _initializerDefineProperty(this, "store", _descriptor7, this);

      _initializerDefineProperty(this, "globalEvents", _descriptor8, this);

      _initializerDefineProperty(this, "isLoggedIn", _descriptor9, this);

      this.titleToken = 'Order Placed';
    }

    model(params) {
      return this.store.findRecord('order', params.order_id).then(order => {
        order.vendor.wasSideloaded = true;
        this.vendor.set('vendor', order.vendor);
        return order;
      });
    }
    /**
     * @deprecated since 10/2020 in favor of global events
     */


    setEcomTrackingObj(trackingObj) {
      const OLO = this.window.OLO;
      Ember.set(OLO, 'ecomTrackingObj', [(0, _lodash.default)(trackingObj)]); // Clone this to prevent it being exposed globally and accidentally mutated
    }

    trackEcommerce(trackingObj) {
      this.analytics.trackEcommerce(trackingObj);
    }

    trackTransaction(order) {
      const ecomModel = order.serializeForEcommerce();
      const orderSubmission = this.storage.orderSubmission;
      this.setEcomTrackingObj(ecomModel);

      if (orderSubmission && orderSubmission.basket) {
        this.analytics.trackEcommerce(ecomModel);
        this.globalEvents.trigger(_globalEvents.GlobalEventName.Transaction, order.serializeForGlobalData(), (0, _lodash.default)(orderSubmission), this.session.serializeUserForGlobalData());
        this.trackMixpanel(order, orderSubmission);
      }
    }

    trackMixpanel(model, {
      basket,
      memberships,
      selectedBillingMethods,
      loyaltyAccount
    }) {
      return this.analytics.trackEvent(_analytics.AnalyticsEvents.OrderPlaced, () => {
        var _this$session$savedNe, _this$session$editedO, _this$session$usedSav, _this$session$viewedC;

        const properties = {
          [_analytics.AnalyticsProperties.OrderID]: model.id,
          [_analytics.AnalyticsProperties.SavedANewCard]: (_this$session$savedNe = this.session.savedNewCard) != null ? _this$session$savedNe : false,
          [_analytics.AnalyticsProperties.EditedOrderFromCheckout]: (_this$session$editedO = this.session.editedOrderFromCheckout) != null ? _this$session$editedO : false,
          [_analytics.AnalyticsProperties.UsedASavedCard]: (_this$session$usedSav = this.session.usedSavedCard) != null ? _this$session$usedSav : false,
          [_analytics.AnalyticsProperties.ViewedCustomFeesTooltip]: (_this$session$viewedC = this.session.viewedCustomFeesTooltip) != null ? _this$session$viewedC : false,
          [_analytics.AnalyticsProperties.NumberOfPaymentTypesUsed]: selectedBillingMethods.length,
          [_analytics.AnalyticsProperties.SupportsParkingLocation]: model.requireArrivalMessage,
          [_analytics.AnalyticsProperties.SupportsArrivalNotifications]: model.canSupportArrival
        };
        properties[_analytics.AnalyticsProperties.PaymentTypes] = selectedBillingMethods.map(method => {
          if (method.variant === _method.Variant.Cash || method.variant === _method.Variant.LevelUp) {
            return method.variant;
          }

          if (method.variant === _method.Variant.NewCard) {
            if ('giftCardNumber' in method.paymentCard) {
              return 'Gift Card';
            }

            return 'Credit Card';
          }

          if (method.variant === _method.Variant.OloPay) {
            if (method.paymentCard.isDigitalWallet) {
              return 'Olo Pay Digital Wallet';
            }

            return 'Olo Pay Card';
          }

          if (method.variant === _method.Variant.CompCard) {
            // Actually impossible, but needed to make the below typesafe
            return '';
          }

          const membership = memberships == null ? void 0 : memberships.find(m => m.id === method.membershipId);
          return membership != null && membership.isGiftCard ? 'Gift Card' : 'Credit Card';
        }).sort();

        if (basket.coupon) {
          properties[_analytics.AnalyticsProperties.CouponCode] = basket.coupon.code;
        }

        if (basket.reward && loyaltyAccount) {
          properties[_analytics.AnalyticsProperties.LoyaltyRedemption] = true;
          properties[_analytics.AnalyticsProperties.LoyaltyProvider] = loyaltyAccount.schemeProviderName;
          properties[_analytics.AnalyticsProperties.LoyaltyMembershipID] = loyaltyAccount.membershipId;
          properties[_analytics.AnalyticsProperties.LoyaltyRewardID] = basket.reward.externalReference;
          properties[_analytics.AnalyticsProperties.LoyaltyRewardDiscountAmount] = basket.vendorDiscount.toFixed(2);
        } else {
          properties[_analytics.AnalyticsProperties.LoyaltyRedemption] = false;
        }

        return properties;
      });
    }

    afterModel(model) {
      this.session.lastOrderId = model.id;
      Ember.run.scheduleOnce('afterRender', this, 'trackTransaction', model);

      if (!this.isLoggedIn) {
        this.session.loadGuestUser();
      }

      this.basket.clear();
      const dispatchIds = model.hasMany('dispatchStatuses').ids();

      if (dispatchIds.length === 1) {
        this.replaceWith('dispatch-status', model.id, dispatchIds[0]);
      }
    }

    willTransition() {
      // Clean up Mixpanel session properties
      this.session.savedNewCard = undefined;
      this.session.editedOrderFromCheckout = undefined;
      this.session.usedSavedCard = undefined;
      this.session.viewedCustomFeesTooltip = undefined;
    }

    deactivate() {
      this.storage.orderSubmission = undefined;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "window", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "vendor", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "globalEvents", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isLoggedIn", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "willTransition", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype)), _class));
  _exports.default = ThankYouRoute;
});