define("mobile-web/lib/dom", ["exports", "mobile-web/lib/utilities/is-some"], function (_exports, _isSome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setTemporaryStyle = setTemporaryStyle;
  _exports.unsetTemporaryStyle = unsetTemporaryStyle;
  _exports.getParents = getParents;
  const ORIGINAL_STYLES = new Map();

  function setTemporaryStyle(element, styleName, style) {
    let map = ORIGINAL_STYLES.get(element);

    if (!map) {
      map = new Map();
      ORIGINAL_STYLES.set(element, map);
    }

    if (!map.has(styleName)) {
      map.set(styleName, element.style[styleName]);
      element.style[styleName] = style;
    }
  }

  function unsetTemporaryStyle(element, styleName) {
    const map = ORIGINAL_STYLES.get(element);
    const originalStyle = map == null ? void 0 : map.get(styleName);

    if (map && originalStyle !== undefined) {
      element.style[styleName] = originalStyle;
      map.delete(styleName);
    }
  }
  /**
   * Gets the parent elements of the specified element
   *
   * @param el the element to start at
   * @yields the parent elements of the specified element
   */


  function* getParents(el) {
    for (let parent = el.parentElement; (0, _isSome.default)(parent); parent = parent.parentElement) {
      yield parent;
    }
  }
});