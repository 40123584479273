define("mobile-web/components/r-popover/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nZ6BDqHD",
    "block": "[[[8,[39,0],[[4,[38,1],[[30,0,[\"didInsert\"]]],null]],[[\"@renderInPlace\",\"@horizontalPosition\",\"@verticalPosition\"],[true,\"right\",\"auto\"]],[[\"default\"],[[[[1,\"\\n\"],[1,\"  \"],[8,[30,1,[\"Trigger\"]],[[16,0,[28,[37,2],[[30,0,[\"triggerSelectorClass\"]],[30,2]],null]]],null,[[\"default\"],[[[[1,\"\\n    \"],[18,5,[[28,[37,4],null,[[\"isOpen\"],[[30,1,[\"isOpen\"]]]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"Content\"]],[[16,0,[30,0,[\"style\",\"content\"]]]],null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,3],[[[1,\"      \"],[8,[39,6],null,[[\"@model\",\"@target\",\"@onClick\",\"@isHeader\"],[[30,3],[30,0,[\"triggerEl\"]],[30,1,[\"actions\",\"close\"]],[30,4]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,6],null,[[\"@target\",\"@isHeader\"],[[30,0,[\"triggerEl\"]],[30,4]]],[[\"default\"],[[[[1,\"\\n        \"],[18,6,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]]],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"dd\",\"@triggerClass\",\"@model\",\"@isHeader\",\"&default\",\"&content\"],false,[\"basic-dropdown\",\"did-insert\",\"classes\",\"yield\",\"hash\",\"if\",\"r-popover-content\"]]",
    "moduleName": "mobile-web/components/r-popover/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});