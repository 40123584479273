define("mobile-web/components/r-twitter-share-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ulM11BD5",
    "block": "[[[10,\"button\"],[15,0,[30,0,[\"rootClass\"]]],[15,\"onclick\",[28,[37,0],[[30,0],\"share\"],null]],[14,\"aria-label\",\"Share on Twitter\"],[14,\"data-test-twitter-share-button\",\"\"],[14,4,\"button\"],[12],[1,\"\\n  \"],[10,1],[15,0,[30,0,[\"style\",\"text\"]]],[12],[1,\"Tweet\"],[13],[1,\"\\n  \"],[8,[39,1],null,[[\"@ariaLabel\",\"@class\",\"@icon\"],[\"Twitter\",[30,0,[\"style\",\"icon\"]],\"r-twitter-logo\"]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"action\",\"r-svg\"]]",
    "moduleName": "mobile-web/components/r-twitter-share-button/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});