define("mobile-web/helpers/live-utc", ["exports", "mobile-web/config/environment", "mobile-web/lib/dayjs"], function (_exports, _environment, _dayjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LiveUtc extends Ember.Helper {
    compute(_params, hash) {
      if (_environment.default.environment !== 'test') {
        var _hash$interval;

        Ember.run.later(() => {
          if (!this.isDestroyed && !this.isDestroying) {
            this.recompute();
          }
        }, (_hash$interval = hash.interval) != null ? _hash$interval : 1000);
      }

      return _dayjs.default.utc();
    }

  }

  _exports.default = LiveUtc;
});