define("mobile-web/components/r-product-customization/footer/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_1elnbc",
    "buttonContainer": "_buttonContainer_1elnbc",
    "addToCartButton": "_addToCartButton_1elnbc",
    "modal": "_modal_1elnbc",
    "quantityLabel": "_quantityLabel_1elnbc",
    "quantityContainer": "_quantityContainer_1elnbc",
    "quantityText": "_quantityText_1elnbc",
    "spinnerIcon": "_spinnerIcon_1elnbc"
  };
  _exports.default = _default;
});