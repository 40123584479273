define("mobile-web/components/r-save-favorite-modal/component", ["exports", "@ember-decorators/component", "ember-concurrency", "ember-concurrency-ts", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-save-favorite-modal/style"], function (_exports, _component, _emberConcurrency, _emberConcurrencyTs, _, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let SaveFavoriteModal = (_dec = (0, _component.tagName)(''), _dec2 = Ember.computed('submit.isRunning', 'name'), _dec(_class = (_class2 = class SaveFavoriteModal extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.isLoggedIn = false;
      this.name = '';
      this.style = _style.default;
    }

    get isSubmitDisabled() {
      return (0, _emberConcurrencyTs.taskFor)(this.submit).isRunning || (0, _.isEmpty)(this.name);
    }

    init() {
      super.init();
      (false && !((0, _.isFunction)(this.createAccount)) && Ember.assert('`createAccount` is required', (0, _.isFunction)(this.createAccount)));
      (false && !((0, _.isFunction)(this.login)) && Ember.assert('`login` is required', (0, _.isFunction)(this.login)));
      (false && !((0, _.isFunction)(this.onClose)) && Ember.assert('`onClose` is required', (0, _.isFunction)(this.onClose)));
      (false && !((0, _isSome.isSome)(this.order)) && Ember.assert('`order` is required', (0, _isSome.isSome)(this.order)));
      (false && !((0, _.isFunction)(this.saveFavorite)) && Ember.assert('`saveFavorite` is required', (0, _.isFunction)(this.saveFavorite)));
    }

    *submit(saveFavorite, orderId, name, close) {
      yield saveFavorite(orderId, name);
      close();
    }

    doSubmit() {
      (0, _emberConcurrencyTs.taskFor)(this.submit).perform(this.saveFavorite, this.order.id, this.name, this.onClose);
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "isSubmitDisabled", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "isSubmitDisabled"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "submit", [_emberConcurrency.dropTask], Object.getOwnPropertyDescriptor(_class2.prototype, "submit"), _class2.prototype)), _class2)) || _class);
  _exports.default = SaveFavoriteModal;
});