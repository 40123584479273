define("mobile-web/components/r-quantity-input/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_1q42a2",
    "isSlider": "_isSlider_1q42a2",
    "inputContainer": "_inputContainer_1q42a2",
    "buttonText": "_buttonText_1q42a2",
    "inputWrapper": "_inputWrapper_1q42a2",
    "label": "_label_1q42a2",
    "button": "_button_1q42a2",
    "isStepper": "_isStepper_1q42a2",
    "leftButton": "_leftButton_1q42a2 _button_1q42a2",
    "rightButton": "_rightButton_1q42a2 _button_1q42a2",
    "icon": "_icon_1q42a2",
    "input": "_input_1q42a2",
    "thumb": "_thumb_1q42a2",
    "thumbLarge": "_thumbLarge_1q42a2"
  };
  _exports.default = _default;
});