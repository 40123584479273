define("mobile-web/styles/routes/application-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "error": "_error_fbthz8"
  };
  _exports.default = _default;
});