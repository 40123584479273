define("mobile-web/modifiers/observe", ["exports", "@glint/environment-ember-loose/ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const observe = (0, _emberModifier.modifier)((element, [observer]) => {
    observer.observe(element);
    return () => {
      observer.unobserve == null ? void 0 : observer.unobserve(element);
    };
  });
  var _default = observe;
  _exports.default = _default;
});