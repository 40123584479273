define("mobile-web/components/r-digital-wallets-button/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "ember-concurrency", "ember-concurrency-ts", "mobile-web/lib/country", "mobile-web/lib/payment/card", "mobile-web/lib/utilities/is-some", "mobile-web/lib/utilities/math", "mobile-web/components/r-digital-wallets-button/style"], function (_exports, _glimmerComponent, _emberConcurrency, _emberConcurrencyTs, _country, _card, _isSome, _math, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DigitalWalletType = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Country;

  (function (Country) {
    Country["US"] = "US";
    Country["CA"] = "CA";
  })(Country || (Country = {}));

  let DigitalWalletType;
  _exports.DigitalWalletType = DigitalWalletType;

  (function (DigitalWalletType) {
    DigitalWalletType["GooglePay"] = "GooglePay";
    DigitalWalletType["ApplePay"] = "ApplePay";
  })(DigitalWalletType || (_exports.DigitalWalletType = DigitalWalletType = {}));

  /**
   * @summary
   * `DigitalWalletsButton` renders a Google or Apple Pay button depending on whether a user is accessing
   *  Serve from Google Chrome or Safari.
   *
   * The mobile wallets button is created by and managed through Olo Pay JS which wraps Stripe functionality.
   * GitHub: https://github.com/ololabs/olo-pay-js-sdk
   */
  let DigitalWalletsButton = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, (_class = class DigitalWalletsButton extends _glimmerComponent.default {
    // Service injections
    // Tracked properties
    // Passthroughs
    // Getters and setters
    get country() {
      var _this$vendor$vendor$a, _this$vendor$vendor;

      return (_this$vendor$vendor$a = (_this$vendor$vendor = this.vendor.vendor) == null ? void 0 : _this$vendor$vendor.address.country) != null ? _this$vendor$vendor$a : Country.US;
    } // Returns the merchant ID for ApplePay (which will render "Pay [merchant ID]" on the ApplePay payment sheet)
    // Returns "Total" for GooglePay (which will be used in the itemized breakdown section of the GooglePay payment sheet)


    get payLabel() {
      if (this.args.digitalWalletType === DigitalWalletType.GooglePay) {
        return this.intl.t('mwc.checkout.totalLabel');
      }

      if (this.args.digitalWalletType === DigitalWalletType.ApplePay) {
        var _this$channel$current, _this$channel$current2;

        return (_this$channel$current = (_this$channel$current2 = this.channel.current) == null ? void 0 : _this$channel$current2.name) != null ? _this$channel$current : '';
      }

      return '';
    }

    get currency() {
      var _this$vendor$currency, _this$vendor$currency2;

      return (_this$vendor$currency = (_this$vendor$currency2 = this.vendor.currency) == null ? void 0 : _this$vendor$currency2.toLowerCase()) != null ? _this$vendor$currency : 'usd';
    }

    get total() {
      return this.format(this.payLabel, this.basket.basket.total);
    }

    get discountLabel() {
      var _ref, _coupon, _reward;

      return (_ref = this.basket.basket.hasCoupon ? (_coupon = this.basket.basket.coupon) == null ? void 0 : _coupon.code : (_reward = this.basket.basket.reward) == null ? void 0 : _reward.label) != null ? _ref : '';
    } // Constructor


    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      _initializerDefineProperty(this, "channel", _descriptor2, this);

      _initializerDefineProperty(this, "error", _descriptor3, this);

      _initializerDefineProperty(this, "device", _descriptor4, this);

      _initializerDefineProperty(this, "vendor", _descriptor5, this);

      _initializerDefineProperty(this, "bus", _descriptor6, this);

      _initializerDefineProperty(this, "intl", _descriptor7, this);

      _initializerDefineProperty(this, "wallet", _descriptor8, this);

      this.style = _style.default;
      (0, _emberConcurrencyTs.taskFor)(this.initializeDigitalWallet).perform();
      this.bus.on('adjustAmounts', () => (0, _emberConcurrencyTs.taskFor)(this.updatePaymentSheet).perform());
    } // Tasks


    *initializeDigitalWallet() {
      var _this$wallet$paymentB;

      this.wallet = this.args.digitalWallets;
      yield this.wallet.initialize({
        style: {
          type: 'default',
          theme: 'dark',
          // Adjust height to smaller side on mobile devices.
          // Currently, the height can not be adjusted dynamically via CSS class / media queries
          height: this.device.isWebIOS || this.device.isWebAndroid ? '42px' : '53px'
        },
        options: {
          country: this.country,
          currency: this.currency,
          total: this.total,
          requestPayerName: true,
          displayItems: this.formatDisplayItems(this.basket.basket)
        }
      });

      if (!this.args.digitalWalletType) {
        this.wallet.destroy();
        return;
      }

      this.wallet.mountButton(this.submitPayment.bind(this));
      (_this$wallet$paymentB = this.wallet.paymentButton) == null ? void 0 : _this$wallet$paymentB.on('click', event => {
        const areCheckoutFieldsValid = this.args.validateCheckoutFields();

        if (!areCheckoutFieldsValid) {
          // Prevent the payment sheet from opening
          event.preventDefault();
        }
      });
    }
    /**
     * Updates the payment sheet order details section.
     * https://stripe.com/docs/js/payment_request/create
     */


    *updatePaymentSheet() {
      if (!this.wallet || !this.basket.basket) return;
      this.wallet.updatePaymentDetails({
        currency: this.currency,
        total: this.total,
        displayItems: this.formatDisplayItems(this.basket.basket)
      });
    }
    /**
     * Convert to cents and format with a label for Stripe API.
     */


    format(label, amount) {
      const item = {
        label,
        amount: (0, _math.roundDecimals)(amount * 100, 2)
      };

      if (label.startsWith('Discount')) {
        return amount < 0 ? item : undefined;
      }

      return amount > 0 ? item : undefined;
    }

    formatDisplayItems(basket) {
      var _basket$discounts$map, _basket$discounts;

      const tip = this.format(this.intl.t('mwc.checkout.tipLabel'), basket.tip);
      const taxesItem = basket.taxes.map(t => this.format(t.label, t.totalTax));
      const subTotal = this.format(this.intl.t('mwc.checkout.subtotalLabel'), basket.subTotal);
      const deliveryCharge = this.format(this.intl.t('mwc.checkout.deliveryFeeLabel'), basket.deliveryCharge); // Display discounts as negative (this will not affect the total)

      const discountsItem = (_basket$discounts$map = (_basket$discounts = basket.discounts) == null ? void 0 : _basket$discounts.map(d => this.format(this.intl.t('mwc.checkout.totalsDiscountLabel', {
        label: this.discountLabel
      }), d.amount * -1))) != null ? _basket$discounts$map : [];
      return [subTotal, ...discountsItem, ...taxesItem, deliveryCharge, tip].filter(_isSome.default);
    }

    async submitPayment(paymentMethod) {
      var _paymentMethod$card$l, _paymentMethod$card, _paymentMethod$card$b, _paymentMethod$card2, _paymentMethod$billin, _paymentMethod$billin2, _this$channel$current3, _paymentMethod$card3, _paymentMethod$card4, _paymentMethod$billin3, _paymentMethod$billin4, _paymentMethod$billin5, _paymentMethod$billin6, _paymentMethod$billin7, _paymentMethod$billin8;

      const oloPayData = {
        cardLastFour: (_paymentMethod$card$l = (_paymentMethod$card = paymentMethod.card) == null ? void 0 : _paymentMethod$card.last4) != null ? _paymentMethod$card$l : '',
        cardType: (_paymentMethod$card$b = (_paymentMethod$card2 = paymentMethod.card) == null ? void 0 : _paymentMethod$card2.brand) != null ? _paymentMethod$card$b : _card.Type.Unknown,
        city: (_paymentMethod$billin = (_paymentMethod$billin2 = paymentMethod.billing_details.address) == null ? void 0 : _paymentMethod$billin2.city) != null ? _paymentMethod$billin : '',
        countryCode: (_this$channel$current3 = this.channel.currentCountry) != null ? _this$channel$current3 : _country.Code.US,
        expirationMonth: String((_paymentMethod$card3 = paymentMethod.card) == null ? void 0 : _paymentMethod$card3.exp_month).padStart(2, '0'),
        expirationYear: String((_paymentMethod$card4 = paymentMethod.card) == null ? void 0 : _paymentMethod$card4.exp_year),
        isDigitalWallet: true,
        saveCreditCard: false,
        schemeId: this.args.digitalWalletId,
        state: (_paymentMethod$billin3 = (_paymentMethod$billin4 = paymentMethod.billing_details.address) == null ? void 0 : _paymentMethod$billin4.state) != null ? _paymentMethod$billin3 : '',
        streetAddress: (_paymentMethod$billin5 = paymentMethod.billing_details.address) == null ? void 0 : _paymentMethod$billin5.line1,
        streetAddress2: (_paymentMethod$billin6 = paymentMethod.billing_details.address) == null ? void 0 : _paymentMethod$billin6.line2,
        token: paymentMethod.id,
        zipCode: (_paymentMethod$billin7 = (_paymentMethod$billin8 = paymentMethod.billing_details.address) == null ? void 0 : _paymentMethod$billin8.postal_code) != null ? _paymentMethod$billin7 : ''
      };
      this.args.saveOloPayCard(oloPayData);

      try {
        var _this$wallet;

        await this.args.placeDigitalWalletsOrder();
        (_this$wallet = this.wallet) == null ? void 0 : _this$wallet.completePaymentEvent();
      } catch (error) {
        // We need to force-close the Google Pay sheet to show the toast message.
        // The Apple Pay payment sheet auto-closes when experiencing an error
        if (this.args.digitalWalletType === DigitalWalletType.GooglePay) {
          var _this$wallet2;

          (_this$wallet2 = this.wallet) == null ? void 0 : _this$wallet2.completePaymentEvent();
        } else {
          var _this$wallet3;

          (_this$wallet3 = this.wallet) == null ? void 0 : _this$wallet3.failPaymentEvent();
        }

        this.args.clearDigitalWalletsMethods();
        this.error.reportError(error);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "error", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "device", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "vendor", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "bus", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "wallet", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "initializeDigitalWallet", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "initializeDigitalWallet"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatePaymentSheet", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "updatePaymentSheet"), _class.prototype)), _class));
  _exports.default = DigitalWalletsButton;
});