define("mobile-web/routes/user-agreement", ["exports", "mobile-web/decorators/indexed", "mobile-web/lib/utilities/transform-content"], function (_exports, _indexed, _transformContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  let UserAgreementRoute = (0, _indexed.default)(_class = class UserAgreementRoute extends Ember.Route {
    constructor(...args) {
      super(...args);
      this.titleToken = 'User Agreement';
    }

    model() {
      return this.store.findRecord('content', 'USERAGREEMENT_HTML').then(content => (0, _transformContent.transformContent)(Ember.get(content, 'value'), _transformContent.ContentType.HTML));
    }

  }) || _class;

  _exports.default = UserAgreementRoute;
});