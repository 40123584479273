define("mobile-web/templates/secure/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fG+On3eH",
    "block": "[[[8,[39,0],null,[[\"@id\"],[[30,0,[\"id\"]]]],null],[1,\"\\n\"]],[],false,[\"routes/reset-password-route\"]]",
    "moduleName": "mobile-web/templates/secure/reset-password.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});