define("mobile-web/components/r-product-customization/component", ["exports", "@glimmer/component", "mobile-web/lib/menu", "mobile-web/lib/runloop", "mobile-web/lib/utilities/is-some", "mobile-web/services/global-events", "mobile-web/components/r-product-customization/style"], function (_exports, _component, _menu, _runloop, _isSome, _globalEvents, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ProductCustomizationType = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProductCustomizationType;
  _exports.ProductCustomizationType = ProductCustomizationType;

  (function (ProductCustomizationType) {
    ProductCustomizationType["Modal"] = "modal";
    ProductCustomizationType["Page"] = "page";
  })(ProductCustomizationType || (_exports.ProductCustomizationType = ProductCustomizationType = {}));

  let ProductCustomization = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, (_class = class ProductCustomization extends _component.default {
    // Service injections
    // Passthroughs
    // Tracked properties
    // Getters and setters
    get rootStyle() {
      if (this.args.displayInModal) {
        var _this$footerElement$o, _this$footerElement;

        const footerHeight = (_this$footerElement$o = (_this$footerElement = this.footerElement) == null ? void 0 : _this$footerElement.offsetHeight) != null ? _this$footerElement$o : 0;
        const marginBottom = footerHeight + 16; // $spacing-lg

        return Ember.String.htmlSafe(`margin-bottom: ${marginBottom}px`);
      }

      return undefined;
    }

    get showBreadcrumbs() {
      return this.device.isWeb && !this.args.displayInModal;
    }

    get breadcrumbs() {
      var _product$vendor, _this$args$previousRo;

      const product = this.args.product;
      const vendor = (_product$vendor = product.vendor) != null ? _product$vendor : this.vendor.vendor;
      const breadcrumbs = [{
        label: `${vendor.name} Menu`,
        route: 'menu.vendor',
        models: [vendor.slug]
      }];

      if (((_this$args$previousRo = this.args.previousRoute) == null ? void 0 : _this$args$previousRo.route) === 'menu.category' && product.category) {
        const category = product.category;
        breadcrumbs.push({
          label: category.name,
          route: 'menu.category',
          models: [vendor.slug, category.id]
        });
      }

      return [...breadcrumbs, {
        label: product.name
      }];
    }

    get basketProduct() {
      var _this$args$basketProd;

      const bp = (_this$args$basketProd = this.args.basketProduct) != null ? _this$args$basketProd : this.newBasketProduct;

      if (!bp) {
        var _this$args$product;

        this.error.sendExternalError(new Error('basketProduct was undefined'), {
          productId: (_this$args$product = this.args.product) == null ? void 0 : _this$args$product.id
        });
      }

      return bp;
    }

    get showOptionGroups() {
      return (0, _isSome.default)(this.basket.activeBasketProduct) && this.hasInitialized && !this.args.isLoading;
    } // Constructor


    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      _initializerDefineProperty(this, "channel", _descriptor2, this);

      _initializerDefineProperty(this, "device", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "error", _descriptor5, this);

      _initializerDefineProperty(this, "vendor", _descriptor6, this);

      _initializerDefineProperty(this, "globalEvents", _descriptor7, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "newBasketProduct", _descriptor8, this);

      _initializerDefineProperty(this, "footerElement", _descriptor9, this);

      _initializerDefineProperty(this, "hasInitialized", _descriptor10, this);

      if (this.args.basketProduct) {
        this.hasInitialized = true; // This may seem like we're duplicating work done in the edit route model hook,
        // but actually we need this for when we open the product modal to edit a simple product.

        this.previousActiveBasketProduct = this.basket.safeGetActiveBasketProduct();
        this.basket.activeBasketProduct = this.args.basketProduct;
      } else {
        const product = this.args.product;
        const bp = this.store.createRecord('basket-product', {
          quantity: product.defaultQuantity,
          product,
          vendor: product.vendor
        });
        this.newBasketProduct = bp;
        (0, _menu.createDefaultBasketChoices)(this.store, bp, product.optionGroups).then(() => {
          product.optionGroups.forEach(og => {
            if (bp.get('isNew')) {
              og.splitChoiceQuantities();
            }
          });
          bp.setQuantityBasedOnChoiceQuantities();
          this.hasInitialized = true;
          (0, _runloop.safeNext)(this, () => {
            // Setting footerElement to itself causes rootStyle to recalculate, which
            // is needed because hasInitialized causes the footer height to change.
            // Sorry.
            // eslint-disable-next-line no-self-assign
            this.footerElement = this.footerElement;
          });
        });
        this.previousActiveBasketProduct = this.basket.safeGetActiveBasketProduct();
        this.basket.activeBasketProduct = bp;
      }
    } // Other methods
    // eslint-disable-next-line ember/require-super-in-lifecycle-hooks


    willDestroy() {
      var _this$args$basketProd2;

      const bp = (_this$args$basketProd2 = this.args.basketProduct) != null ? _this$args$basketProd2 : this.newBasketProduct;

      if (bp && !bp.isDestroying && !bp.isDestroyed) {
        bp.rollbackAttributes();
        this.store.peekAll('basket-choice').forEach(bc => {
          bc.rollbackAttributes();

          if (Ember.isNone(bc.basketProduct)) {
            // If a basket choice becomes orphaned, we need to clean it up.
            // Done in a later to not cause errors with watchers
            Ember.run.later(() => bc.unloadRecord(), 100);
          }
        });
      }

      this.basket.activeBasketProduct = this.previousActiveBasketProduct;
    }

    didInsertNode() {
      this.globalEvents.trigger(_globalEvents.GlobalEventName.ViewProductDetail, this.args.product.serializeForGlobalData(), this.args.displayInModal ? ProductCustomizationType.Modal : ProductCustomizationType.Page);
    } // Tasks
    // Actions


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "device", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "error", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "vendor", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "globalEvents", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "newBasketProduct", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "footerElement", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "hasInitialized", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  })), _class));
  _exports.default = ProductCustomization;
});