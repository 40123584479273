define("mobile-web/components/r-checkout/booking-and-receiving-customer/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "iconButton": "_iconButton_jd0cr7",
    "icon": "_icon_jd0cr7",
    "removeIcon": "_removeIcon_jd0cr7 _icon_jd0cr7",
    "removeText": "_removeText_jd0cr7",
    "toggle": "_toggle_jd0cr7",
    "receivingCustomer": "_receivingCustomer_jd0cr7",
    "smsOptIn": "_smsOptIn_jd0cr7"
  };
  _exports.default = _default;
});