define("mobile-web/components/r-popover-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IFspoPsH",
    "block": "[[[41,[30,1,[\"icon\"]],[[[1,\"  \"],[10,1],[15,0,[30,0,[\"style\",\"icon\"]]],[14,\"data-test-popover-item\",\"icon\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@ariaLabel\",\"@icon\"],[\"\",[30,1,[\"icon\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[10,1],[15,0,[30,0,[\"style\",\"label\"]]],[14,\"data-test-popover-item\",\"label\"],[12],[1,\"\\n  \"],[1,[30,1,[\"label\"]]],[1,\"\\n\"],[13]],[\"@model\"],false,[\"if\",\"r-svg\"]]",
    "moduleName": "mobile-web/components/r-popover-item/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});