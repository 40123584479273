define("mobile-web/helpers/classes", ["exports", "@glint/environment-ember-loose/ember-component/helper", "mobile-web/lib/utilities/is-some"], function (_exports, _helper, _isSome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const classesHelper = (0, _helper.helper)(params => params.filter(_isSome.default).join(' '));
  var _default = classesHelper;
  _exports.default = _default;
});