define("mobile-web/services/indexeddb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RequestStatus;

  (function (RequestStatus) {
    RequestStatus[RequestStatus["PENDING"] = 0] = "PENDING";
    RequestStatus[RequestStatus["RESOLVED"] = 1] = "RESOLVED";
    RequestStatus[RequestStatus["REJECTED"] = 2] = "REJECTED";
  })(RequestStatus || (RequestStatus = {}));

  class IndexedDBService extends Ember.Service {
    constructor(...args) {
      super(...args);
      this.dbs = {};
    }

    open(opts) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        if (this.dbs.hasOwnProperty(opts.name)) {
          resolve(this.dbs[opts.name]);
        } else {
          let status = RequestStatus.PENDING;
          const openRequest = indexedDB.open(opts.name, opts.version);

          openRequest.onupgradeneeded = () => {
            opts.onUpgradeNeeded(openRequest.result);
          };

          openRequest.onsuccess = () => {
            if (status === RequestStatus.PENDING) {
              status = RequestStatus.RESOLVED;
              this.dbs[opts.name] = openRequest.result;
              resolve(this.dbs[opts.name]);
            }
          };

          openRequest.onerror = evt => {
            if (status === RequestStatus.PENDING) {
              status = RequestStatus.REJECTED;
              reject(evt);
            }
          }; // Ensure that if `open` hangs, the user won't hang


          Ember.run.later(() => {
            if (status === RequestStatus.PENDING) {
              status = RequestStatus.REJECTED;
              reject('Open request timed out');
            }
          }, 500);
        }
      });
    }

    delete(name) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        let status = RequestStatus.PENDING;
        const deleteRequest = indexedDB.deleteDatabase(name);

        deleteRequest.onsuccess = () => {
          if (status === RequestStatus.PENDING) {
            status = RequestStatus.RESOLVED;
            resolve();
          }
        };

        deleteRequest.onerror = evt => {
          if (status === RequestStatus.PENDING) {
            status = RequestStatus.REJECTED;
            reject(evt);
          }
        }; // Ensure that if `delete` hangs, the user won't hang


        Ember.run.later(() => {
          if (status === RequestStatus.PENDING) {
            status = RequestStatus.REJECTED;
            reject('Delete request timed out');
          }
        }, 500);
      });
    }

    start(db, storeName, type) {
      const tr = db.transaction(storeName, type);
      return tr.objectStore(storeName);
    }

    getAllKeys(store) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        const request = store.getAllKeys();

        request.onsuccess = () => {
          resolve(request.result);
        };

        request.onerror = () => {
          reject();
        };
      });
    }

    getObject(store, modelName) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        const request = store.get(modelName);

        request.onsuccess = () => {
          resolve(request.result);
        };

        request.onerror = () => {
          reject();
        };
      });
    }

    put(store, key, val) {
      store.put(val, key);
    }

  }

  _exports.default = IndexedDBService;
});