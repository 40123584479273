define("mobile-web/components/r-static-content-modal/component", ["exports", "@glimmer/component", "mobile-web/lib/utilities/transform-content", "mobile-web/components/r-static-content-modal/style"], function (_exports, _component, _transformContent, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.StaticContentType = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let StaticContentType;
  _exports.StaticContentType = StaticContentType;

  (function (StaticContentType) {
    StaticContentType["CONTACT_US"] = "contact-us";
    StaticContentType["USER_AGREEMENT"] = "user-agreement";
    StaticContentType["PRIVACY_POLICY"] = "privacy-policy";
    StaticContentType["OPT_OUT_GUIDE"] = "opt-out-guide";
  })(StaticContentType || (_exports.StaticContentType = StaticContentType = {}));

  let StaticContentModal = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class StaticContentModal extends _component.default {
    // Service injections
    // Passthroughs
    // Tracked properties
    // Getters and setters
    // Constructor
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "bus", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "isOpen", _descriptor4, this);

      _initializerDefineProperty(this, "content", _descriptor5, this);

      _initializerDefineProperty(this, "title", _descriptor6, this);

      _initializerDefineProperty(this, "buttonLabel", _descriptor7, this);

      _initializerDefineProperty(this, "onButtonClick", _descriptor8, this);

      this.bus.on('showStaticContent', eventArgs => {
        var _eventArgs$buttonLabe;

        this.buttonLabel = (_eventArgs$buttonLabe = eventArgs.buttonLabel) != null ? _eventArgs$buttonLabe : this.intl.t('mwc.footer.closeText');
        this.onButtonClick = eventArgs.onButtonClick;
        this.open(eventArgs.type);
      });
    } // Other methods


    load(key) {
      this.store.findRecord('content', key).then(content => {
        this.content = (0, _transformContent.transformContent)(content.get('value'), _transformContent.ContentType.HTML);
      });
    }

    async open(modalType) {
      // Reset static content in case there is old data in there
      this.content = '';
      this.isOpen = true;

      switch (modalType) {
        case StaticContentType.CONTACT_US:
          this.title = this.intl.t('mwc.footer.contactUsText');
          this.load('MOBILE_CONTACTUS_HTML');
          break;

        case StaticContentType.USER_AGREEMENT:
          this.title = this.intl.t('mwc.footer.userAgreementText');
          this.load('USERAGREEMENT_HTML');
          break;

        case StaticContentType.PRIVACY_POLICY:
          this.title = this.intl.t('mwc.footer.privacyPolicyText');
          this.load('PRIVACY_HTML');
          break;

        case StaticContentType.OPT_OUT_GUIDE:
          {
            const title = await this.store.findRecord('content', 'ONLINE_TRACKING_PAGE_LINK_NAME');
            const brandContent = await this.store.findRecord('content', 'ONLINE_TRACKING_PAGE_BRAND_HTML');
            const baseContent = await this.store.findRecord('content', 'ONLINE_TRACKING_PAGE_HTML');
            this.title = title.value;
            this.content = (0, _transformContent.transformContent)(baseContent.value, _transformContent.ContentType.HTML, {
              BrandContent: brandContent.value
            });
          }
          break;

        default:
          {
            this.isOpen = false;
            this.title = '';
          }
      }
    } // Tasks
    // Actions


    close() {
      this.isOpen = false;
      this.content = '';
      this.title = '';
    }

    closeButton() {
      var _this$onButtonClick;

      this.close();
      (_this$onButtonClick = this.onButtonClick) == null ? void 0 : _this$onButtonClick.call(this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "bus", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isOpen", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "content", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "buttonLabel", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "onButtonClick", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "close", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeButton", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "closeButton"), _class.prototype)), _class));
  _exports.default = StaticContentModal;
});