define("mobile-web/lib/validation", ["exports", "true-myth", "mobile-web/lib/utilities/_"], function (_exports, _trueMyth, _) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toMessage = toMessage;
  _exports.default = _exports.Validation = _exports.validate = _exports.buildValidations = _exports.CARD_NUMBER_PATTERN = void 0;
  const CARD_NUMBER_PATTERN = /^\d{13,16}$/;
  _exports.CARD_NUMBER_PATTERN = CARD_NUMBER_PATTERN;
  const STANDARD_RULES = [{
    name: 'cardNumber',
    pattern: CARD_NUMBER_PATTERN
  }, {
    name: 'email',
    pattern: /.+@.+\..+/
  }, {
    name: 'minLength',
    pattern: /.{8,}/
  }, {
    name: 'month',
    pattern: /^\d{2}$/
  }, {
    name: 'notBlank',
    pattern: /.+/
  }, {
    name: 'phone',
    pattern: /^(?:(?:\+1|1)[\s-]?)?\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{4}\s?$/
  }, {
    name: 'securityCode',
    pattern: /^\d{3,4}$/
  }, {
    name: 'year',
    pattern: /^\d{4}$/
  }, {
    name: 'expDate',
    pattern: /^\d{2}\/\d{2}$/
  }, {
    name: 'cvv',
    pattern: /^\d{3}|\d{4}$/
  }, {
    name: 'zip',
    pattern: /^(\d{5}(-?\d{4})?|[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ]\s?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9])$/i
  }, {
    name: 'match'
  }, {
    name: 'truthful',

    check(value) {
      return value === true || value === 'true';
    }

  }];

  const nameToRule = ruleName => (previouslyFoundRule, rule) => {
    if (previouslyFoundRule) {
      return previouslyFoundRule;
    }

    if (rule.name === ruleName) {
      return rule;
    }

    return undefined;
  };

  const byRuleNameIncluded = ruleNames => binding => ruleNames.includes(binding.ruleName);

  const toValidationRules = rules => binding => ({
    binding,
    rule: rules.reduce(nameToRule(binding.ruleName), undefined),
    state: _trueMyth.Result.err(_trueMyth.Maybe.nothing())
  });

  const _validate = (validation, model, valueToValidate) => {
    const success = {
      state: _trueMyth.Result.ok()
    };
    const failure = {
      state: _trueMyth.Result.err(_trueMyth.Maybe.of(validation.binding.message))
    };

    if (Ember.isNone(valueToValidate)) {
      return Object.assign(validation, failure);
    }

    const value = ((0, _.isString)(valueToValidate) ? valueToValidate : valueToValidate.toString()).trim();
    let valid;

    if (validation.rule) {
      if (validation.rule.pattern) {
        valid = value.match(validation.rule.pattern);
      } else if (validation.rule.name === 'match') {
        valid = value === Ember.get(model, validation.binding.match);
      } else if (validation.rule.check) {
        valid = validation.rule.check(value);
      }
    }

    const outcome = valid ? success : failure;
    return Object.assign(validation, outcome);
  };

  const setErrorMessages = (model, validations, property) => {
    validations.forEach(v => {
      if (v.state.isErr() && (Ember.isNone(property) || property === v.binding.targetProp)) {
        const target = v.binding.validationMessagesProp || `${v.binding.targetProp}Messages`;
        const validationMessage = [toMessage(v)]; // It's hard to make the types understand that this is safe, but it is.

        Ember.set(model, target, validationMessage);
      }
    });
  };

  function toMessage(validation) {
    const target = `${validation.binding.targetProp}Messages`;
    const message = validation.binding.message ? validation.binding.message : '';
    return {
      target,
      message
    };
  }

  const buildValidations = config => {
    const bindings = config.bindings;
    const additionalRules = config.customRules || [];
    const rules = additionalRules.concat(STANDARD_RULES);
    const ruleNames = rules.map(rule => rule.name);
    return bindings.filter(byRuleNameIncluded(ruleNames)).map(toValidationRules(rules));
  };
  /**
   * Validate a model against a validation configuration
   * @param model - the model you want to validate
   * @param validationConfig - the ValidationConfig to determine validation rules
   * @param setErrors - if true, sets error messages directly onto the model in addition to returning them
   * @returns a Result object that, if an error, has a ValidationErrors object with all error messages
   */


  _exports.buildValidations = buildValidations;

  const validate = (model, validationConfig, setErrors = false) => {
    const toState = validation => _validate(validation, model, Ember.get(model, validation.binding.targetProp));

    const toError = (errorObj, validation) => {
      if (validation.state.isErr()) {
        const errorKey = validation.binding.targetProp.replace(/\./g, '_');

        if (errorObj.hasOwnProperty(errorKey)) {
          Ember.get(errorObj, errorKey).push(toMessage(validation));
        } else {
          Ember.set(errorObj, errorKey, [toMessage(validation)]);
        }
      }

      return errorObj;
    };

    const validationResults = buildValidations(validationConfig).map(toState);

    if (setErrors) {
      setErrorMessages(model, validationResults);
    }

    const results = validationResults.reduce(toError, {});
    return Object.keys(results).length === 0 ? _trueMyth.Result.ok() : _trueMyth.Result.err(results);
  };

  _exports.validate = validate;
  const Validation = {
    buildValidations,
    toMessage,
    validate
  };
  _exports.Validation = Validation;
  var _default = Validation;
  _exports.default = _default;
});