define("mobile-web/components/routes/menu/category-route/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "row": "_row_3qwlqr",
    "filter": "_filter_3qwlqr",
    "breadcrumbsRow": "_breadcrumbsRow_3qwlqr",
    "imageContainer": "_imageContainer_3qwlqr",
    "image": "_image_3qwlqr",
    "data": "_data_3qwlqr",
    "description": "_description_3qwlqr"
  };
  _exports.default = _default;
});