define("mobile-web/components/r-no-valid-vendor-results/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "wrapper": "_wrapper_1x93tx",
    "content": "_content_1x93tx"
  };
  _exports.default = _default;
});