define("mobile-web/components/routes/index-route/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_yulb1d",
    "headerWrapper": "_headerWrapper_yulb1d",
    "mainHeader": "_mainHeader_yulb1d",
    "subHeader": "_subHeader_yulb1d",
    "formContainer": "_formContainer_yulb1d",
    "form": "_form_yulb1d",
    "linkContainer": "_linkContainer_yulb1d",
    "link": "_link_yulb1d"
  };
  _exports.default = _default;
});