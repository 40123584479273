define("mobile-web/routes/compat/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ResetPasswordRoute extends Ember.Route {
    beforeModel() {
      this.replaceWith('secure.reset-password');
    }

  }

  _exports.default = ResetPasswordRoute;
});