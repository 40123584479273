define("mobile-web/components/r-form-field/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "container": "_container_v279ns",
    "labelInputContainerBase": "_labelInputContainerBase_v279ns",
    "labelInputContainer": "_labelInputContainer_v279ns _labelInputContainerBase_v279ns",
    "inlineLabelInputContainer": "_inlineLabelInputContainer_v279ns _labelInputContainerBase_v279ns",
    "reverse": "_reverse_v279ns",
    "inlineTextInput": "_inlineTextInput_v279ns _textInputBase_v279ns _inputBase_v279ns _inlineInputBase_v279ns",
    "inputContainer": "_inputContainer_v279ns",
    "focused": "_focused_v279ns",
    "error": "_error_v279ns",
    "errorAlt": "_errorAlt_v279ns",
    "label": "_label_v279ns",
    "inlineLabel": "_inlineLabel_v279ns",
    "disabled": "_disabled_v279ns",
    "icon": "_icon_v279ns",
    "optionalIndicatorText": "_optionalIndicatorText_v279ns",
    "inlineLabelText": "_inlineLabelText_v279ns",
    "responsive": "_responsive_v279ns",
    "hiddenLabel": "_hiddenLabel_v279ns",
    "inputBase": "_inputBase_v279ns",
    "inlineInputBase": "_inlineInputBase_v279ns",
    "selectInputBase": "_selectInputBase_v279ns _inputBase_v279ns",
    "selectInput": "_selectInput_v279ns _selectInputBase_v279ns _inputBase_v279ns",
    "inlineSelectInput": "_inlineSelectInput_v279ns _selectInputBase_v279ns _inputBase_v279ns _inlineInputBase_v279ns",
    "selectArrow": "_selectArrow_v279ns",
    "textInputBase": "_textInputBase_v279ns _inputBase_v279ns",
    "textInput": "_textInput_v279ns _textInputBase_v279ns _inputBase_v279ns",
    "button": "_button_v279ns",
    "button--main": "_button--main_v279ns",
    "button--minor": "_button--minor_v279ns",
    "button--destructive": "_button--destructive_v279ns",
    "button--softDisabled": "_button--softDisabled_v279ns",
    "helpMessageContainer": "_helpMessageContainer_v279ns",
    "helpMessage": "_helpMessage_v279ns",
    "characterCount": "_characterCount_v279ns",
    "errorMessage": "_errorMessage_v279ns",
    "errorIcon": "_errorIcon_v279ns",
    "errorMessageText": "_errorMessageText_v279ns",
    "standard": "_standard_v279ns",
    "small": "_small_v279ns",
    "large": "_large_v279ns",
    "formGroup": "_formGroup_v279ns"
  };
  _exports.default = _default;
});