define("mobile-web/components/routes/check-in-route/component", ["exports", "@glimmer/component", "@ember-decorators/component", "mobile-web/components/r-post-checkout/component", "mobile-web/lib/order-criteria", "mobile-web/services/analytics", "mobile-web/components/routes/check-in-route/style"], function (_exports, _component, _component2, _component3, _orderCriteria, _analytics, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CheckInRoute = (_dec = (0, _component2.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec(_class = (_class2 = class CheckInRoute extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      this.style = _style.default;
    }

    // Computed properties
    get analyticsProperties() {
      return {
        [_analytics.AnalyticsProperties.BasketHandoffMode]: (0, _orderCriteria.getHandoffMode)(this.args.order.deliveryMode)
      };
    } // Init
    // Other methods
    // Actions


    onInsert() {
      this.analytics.trackEvent(_analytics.AnalyticsEvents.UserFiredOrderEvent, () => _extends({
        [_analytics.AnalyticsProperties.OrderEvent]: _component3.UserOrderEvent.CheckIn
      }, this.analyticsProperties));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "analytics", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "onInsert", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "onInsert"), _class2.prototype)), _class2)) || _class);
  _exports.default = CheckInRoute;
});