define("mobile-web/models/product", ["exports", "ember-data", "lodash.pick", "mobile-web/lib/dayjs", "mobile-web/lib/order-criteria"], function (_exports, _emberData, _lodash, _dayjs, _orderCriteria) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MIMGroup = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MIMGroup;
  _exports.MIMGroup = MIMGroup;

  (function (MIMGroup) {
    MIMGroup["Menu"] = "mobile-webapp-menu";
    MIMGroup["Customize"] = "mobile-webapp-customize";
    MIMGroup["ResponsiveLarge"] = "responsive-large";
  })(MIMGroup || (_exports.MIMGroup = MIMGroup = {}));

  let ProductModel = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = _emberData.default.attr('string'), _dec4 = _emberData.default.attr('string'), _dec5 = _emberData.default.attr('string'), _dec6 = _emberData.default.attr('number'), _dec7 = _emberData.default.attr('string'), _dec8 = _emberData.default.attr('string'), _dec9 = _emberData.default.attr('number'), _dec10 = _emberData.default.attr('number'), _dec11 = _emberData.default.attr('boolean'), _dec12 = _emberData.default.attr('array'), _dec13 = _emberData.default.attr('array'), _dec14 = _emberData.default.attr('array'), _dec15 = _emberData.default.attr('string'), _dec16 = _emberData.default.attr('object'), _dec17 = _emberData.default.belongsTo('vendor', {
    async: false
  }), _dec18 = _emberData.default.belongsTo('category', {
    async: false
  }), _dec19 = _emberData.default.hasMany('option-group'), _dec20 = Ember.computed('optionGroups.@each.isLoaded'), _dec21 = Ember.computed('firstLevelOptionGroupModels'), _dec22 = Ember.computed('optionGroups.@each.{isAvailable,parentChoice}'), _dec23 = Ember.computed('optionGroups.[]'), _dec24 = Ember.computed('hasChoices', 'minimumQuantity'), _dec25 = Ember.computed('availability', 'orderCriteria.criteria.value', 'orderCriteria.hasCriteria', 'unavailableHandoffModes.[]', 'vendor.utcOffset'), _dec26 = Ember.computed('images.[]'), _dec27 = Ember.computed('images.[]'), (_class = class ProductModel extends _emberData.default.Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "orderCriteria", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "name", _descriptor3, this);

      _initializerDefineProperty(this, "description", _descriptor4, this);

      _initializerDefineProperty(this, "shortDescription", _descriptor5, this);

      _initializerDefineProperty(this, "baseCost", _descriptor6, this);

      _initializerDefineProperty(this, "baseCostOverrideLabel", _descriptor7, this);

      _initializerDefineProperty(this, "calorieLabel", _descriptor8, this);

      _initializerDefineProperty(this, "maximumQuantity", _descriptor9, this);

      _initializerDefineProperty(this, "minimumQuantity", _descriptor10, this);

      _initializerDefineProperty(this, "isFeatured", _descriptor11, this);

      _initializerDefineProperty(this, "images", _descriptor12, this);

      _initializerDefineProperty(this, "labels", _descriptor13, this);

      _initializerDefineProperty(this, "unavailableHandoffModes", _descriptor14, this);

      _initializerDefineProperty(this, "availabilityDescription", _descriptor15, this);

      _initializerDefineProperty(this, "availability", _descriptor16, this);

      _initializerDefineProperty(this, "vendor", _descriptor17, this);

      _initializerDefineProperty(this, "category", _descriptor18, this);

      _initializerDefineProperty(this, "optionGroups", _descriptor19, this);
    }

    get isFullyLoaded() {
      return this.hasMany('optionGroups').ids().every(ogId => {
        var _this$store$peekRecor;

        return (_this$store$peekRecor = this.store.peekRecord('option-group', ogId)) == null ? void 0 : _this$store$peekRecor.isLoaded;
      });
    }

    get totalQuantityBasedOnChoiceQuantities() {
      return this.firstLevelOptionGroupModels.any(og => og.treatAsProductQuantity);
    }

    get firstLevelOptionGroupModels() {
      return this.optionGroups.filter(optionGroup => Ember.isNone(optionGroup.parentChoice) && optionGroup.isAvailable);
    }

    get hasChoices() {
      return this.hasMany('optionGroups').ids().length > 0;
    }

    get canQuickAdd() {
      return !this.hasChoices && this.minimumQuantity < 2;
    }

    get isAvailable() {
      const userOffset = -new Date().getTimezoneOffset();
      const vendorOffset = this.vendor.utcOffset * 60;
      const offsetDifference = userOffset - vendorOffset; // how far ahead of the vendor the user is

      let timeWanted = (0, _dayjs.default)();

      if (this.orderCriteria.criteria) {
        const criteria = this.orderCriteria.criteria;

        if (!Ember.isEmpty(this.unavailableHandoffModes) && criteria.handoffMode !== 'Unspecified' && this.unavailableHandoffModes.includes(criteria.handoffMode)) {
          return false;
        }

        if ((0, _orderCriteria.isAdvance)(criteria) && criteria.timeWanted) {
          timeWanted = criteria.timeWanted;
        }
      }

      if (Ember.isNone(this.availability)) {
        return true;
      }

      const {
        startDate,
        endDate,
        weekTimes
      } = this.availability;
      const startDateParsed = (0, _dayjs.default)(startDate).add(offsetDifference, 'm');
      let endDayParsed = (0, _dayjs.default)(endDate).add(offsetDifference, 'm'); // If no hour, minute, or second data is passed (the Menu API should never send this data)
      // then make assume the endDate should be considered inclusive of today and change it to have the
      // same hours, minutes and seconds (plus one) so we can compare to today.

      if (endDayParsed.hour() === 0 && endDayParsed.minute() === 0 && endDayParsed.second() === 0) {
        endDayParsed = endDayParsed.hour(timeWanted.hour()).minute(timeWanted.minute()).second(timeWanted.second() + 1);
      }

      if (startDate && timeWanted.isBefore(startDateParsed)) {
        return false;
      }

      if (endDate && timeWanted.isAfter(endDayParsed)) {
        return false;
      }

      return weekTimes.any(w => {
        let start = timeWanted;
        /**
         * Setting .day as we do below does not reliably go forward or backward,
         * but adjusts to that day within the same calendar week. Since we want
         * to find the start that is before the timeWanted, we need to
         * go back a week if the start day is later than the wanted day.
         */

        if (timeWanted.day() < w.start.dayOfWeek) {
          start = start.add(-1, 'week');
        }

        start = start.day(w.start.dayOfWeek).hour(w.start.hour).minute(w.start.minute).add(offsetDifference, 'm');
        let end = start;
        /**
         * Similarly, we want to find the first end *after* the start,
         * so if the end day is before the start day, we add a week.
         */

        if (w.end.dayOfWeek < w.start.dayOfWeek) {
          end = end.add(1, 'week');
        }

        end = end.day(w.end.dayOfWeek).hour(w.end.hour).minute(w.end.minute).add(offsetDifference, 'm'); // The '[]' means that the isBetween comparison is inclusive of start and end.

        return timeWanted.isBetween(start, end, undefined, '[]');
      });
    }

    get menuImage() {
      return this.getImage(MIMGroup.Menu);
    }

    get customizeImage() {
      return this.getImage(MIMGroup.Customize);
    }

    get defaultQuantity() {
      return this.totalQuantityBasedOnChoiceQuantities ? 0 : this.minimumQuantity;
    }

    get primaryProductQuantityOptionGroup() {
      return this.firstLevelOptionGroupModels.find(og => og.treatAsProductQuantity);
    }

    getImage(group) {
      var _this$images$find$fil, _this$images$find;

      return (_this$images$find$fil = (_this$images$find = this.images.find(i => i.groupName === group)) == null ? void 0 : _this$images$find.filename) != null ? _this$images$find$fil : '';
    }

    serializeForGlobalData() {
      let category = undefined; // Wrapping this in a try/catch because there are instances where Ember
      // will throw a fit (during some tests) that the category hasn't been loaded
      // and it wants it to be `async: true`.

      try {
        var _this$category;

        category = (_this$category = this.category) == null ? void 0 : _this$category.serializeForGlobalData();
      } catch (_unused) {}

      return _extends({}, (0, _lodash.default)(this, 'id', 'name', 'description', 'shortDescription', 'baseCost', 'baseCostOverrideLabel', 'calorieLabel', 'maximumQuantity', 'minimumQuantity', 'isFeatured', 'images', 'labels', 'availability'), {
        category
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "orderCriteria", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "shortDescription", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "baseCost", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "baseCostOverrideLabel", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "calorieLabel", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "maximumQuantity", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "minimumQuantity", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isFeatured", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "images", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "labels", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "unavailableHandoffModes", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "availabilityDescription", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "availability", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "vendor", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "category", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "optionGroups", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isFullyLoaded", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "isFullyLoaded"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "totalQuantityBasedOnChoiceQuantities", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "totalQuantityBasedOnChoiceQuantities"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "firstLevelOptionGroupModels", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "firstLevelOptionGroupModels"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasChoices", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "hasChoices"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "canQuickAdd", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "canQuickAdd"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isAvailable", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "isAvailable"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "menuImage", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "menuImage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "customizeImage", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "customizeImage"), _class.prototype)), _class));
  _exports.default = ProductModel;
});