define("mobile-web/lib/time/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DayOfWeek = _exports.Weekday = _exports.CalendarType = void 0;
  let CalendarType;
  _exports.CalendarType = CalendarType;

  (function (CalendarType) {
    CalendarType["Business"] = "Business";
  })(CalendarType || (_exports.CalendarType = CalendarType = {}));

  let Weekday;
  _exports.Weekday = Weekday;

  (function (Weekday) {
    Weekday["Sunday"] = "Sunday";
    Weekday["Monday"] = "Monday";
    Weekday["Tuesday"] = "Tuesday";
    Weekday["Wednesday"] = "Wednesday";
    Weekday["Thursday"] = "Thursday";
    Weekday["Friday"] = "Friday";
    Weekday["Saturday"] = "Saturday";
  })(Weekday || (_exports.Weekday = Weekday = {}));

  let DayOfWeek;
  _exports.DayOfWeek = DayOfWeek;

  (function (DayOfWeek) {
    DayOfWeek[DayOfWeek["Sunday"] = 0] = "Sunday";
    DayOfWeek[DayOfWeek["Monday"] = 1] = "Monday";
    DayOfWeek[DayOfWeek["Tuesday"] = 2] = "Tuesday";
    DayOfWeek[DayOfWeek["Wednesday"] = 3] = "Wednesday";
    DayOfWeek[DayOfWeek["Thursday"] = 4] = "Thursday";
    DayOfWeek[DayOfWeek["Friday"] = 5] = "Friday";
    DayOfWeek[DayOfWeek["Saturday"] = 6] = "Saturday";
  })(DayOfWeek || (_exports.DayOfWeek = DayOfWeek = {}));
});