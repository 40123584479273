define("mobile-web/components/r-rewards-search-form/component", ["exports", "@ember-decorators/component", "mobile-web/lib/utilities/_", "mobile-web/components/r-rewards-search-form/style"], function (_exports, _component, _, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let RewardsSearchForm = (_dec = (0, _component.tagName)(''), _dec(_class = class RewardsSearchForm extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    }

    // Computed properties
    // Init
    init() {
      super.init();
      (false && !((0, _.isFunction)(this.onSubmit)) && Ember.assert('`onSubmit` is required and must be a function', (0, _.isFunction)(this.onSubmit)));
      this._address = (0, _.clone)(this.address) || '';
    } // Other methods
    // Actions


  }) || _class);
  _exports.default = RewardsSearchForm;
});