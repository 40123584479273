define("mobile-web/components/r-checkout/payment/method/component", ["exports", "@glimmer/component", "mobile-web/lib/payment", "mobile-web/lib/payment/card", "mobile-web/lib/payment/method", "mobile-web/components/r-checkout/payment/style"], function (_exports, _component, _payment, _card, _method, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CheckoutPaymentMethod = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed('args.method.{balance,variant}', 'membership.{balance,canCheckBalance}'), _dec6 = Ember._action, (_class = class CheckoutPaymentMethod extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "features", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "bus", _descriptor4, this);

      this.style = _style.default;
    }

    // Getters and setters
    get isEditable() {
      return this.args.method.variant === _method.Variant.NewCard;
    }

    get membership() {
      if (this.args.method.variant === _method.Variant.Membership) {
        return this.store.peekRecord('billing-membership', this.args.method.membershipId);
      }

      return null; // eslint-disable-line no-null/no-null
    }

    get isCompCard() {
      return this.args.method.variant === _method.Variant.CompCard;
    }

    get balance() {
      if (this.membership) {
        var _this$membership$bala;

        return this.membership.canCheckBalance ? (_this$membership$bala = this.membership.balance) != null ? _this$membership$bala : 0 : undefined;
      }

      if (this.args.method.variant === _method.Variant.NewCard || this.args.method.variant === _method.Variant.CompCard) {
        return this.args.method.balance;
      }

      return undefined;
    }

    get icon() {
      var _membership$billingSc;

      const membership = this.membership;

      switch (this.args.method.variant) {
        case _method.Variant.Cash:
          return 'r-cash';

        case _method.Variant.LevelUp:
          return 'r-levelup';

        case _method.Variant.OloPay:
          return this.getIconForType((0, _card.matchBrandWithType)(this.args.method.paymentCard.cardType));

        case _method.Variant.Membership:
          if ((membership == null ? void 0 : (_membership$billingSc = membership.billingScheme) == null ? void 0 : _membership$billingSc.get('provider')) === _payment.Provider.CreditCard) {
            return this.getIconForType(membership.cardType);
          }

          return 'r-gift-card';

        case _method.Variant.NewCard:
          if ((0, _payment.isCreditCard)(this.args.method.paymentCard)) {
            return (0, _card.detectType)(this.args.method.paymentCard.creditCardNumber).mapOr('r-credit-card', this.getIconForType);
          }

          return 'r-gift-card';

        default:
          return 'r-gift-card';
      }
    } // Constructor
    // Other methods


    getIconForType(cardType) {
      switch (cardType) {
        case _card.Type.VISA:
          return 'r-visa';

        case _card.Type.AMEX:
          return 'r-amex';

        case _card.Type.Discover:
          return 'r-discover';

        case _card.Type.Mastercard:
          return 'r-mastercard';

        default:
          return 'r-credit-card';
      }
    } // Tasks
    // Actions


    handleInput(val) {
      Ember.set(this.args.method, 'amount', val);
      Ember.set(this.args.method, 'hasManualEdit', true);
      this.bus.trigger('adjustAmounts');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "features", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "bus", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "balance", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "balance"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleInput", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype)), _class));
  _exports.default = CheckoutPaymentMethod;
});