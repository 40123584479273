define("mobile-web/breakpoints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DESKTOP_XL = _exports.DESKTOP_LG = _exports.DESKTOP = _exports.TABLET = void 0;
  // These properties are defined in line with the values in _breakpoints.scsss.
  const TABLET = 768;
  _exports.TABLET = TABLET;
  const DESKTOP = 1024;
  _exports.DESKTOP = DESKTOP;
  const DESKTOP_LG = 1280;
  _exports.DESKTOP_LG = DESKTOP_LG;
  const DESKTOP_XL = 1440;
  _exports.DESKTOP_XL = DESKTOP_XL;
  var _default = {
    mobile: `(max-width: ${TABLET - 1}px)`,
    tablet: `(min-width: ${TABLET}px)`,
    desktop: `(min-width: ${DESKTOP}px)`,
    desktopLg: `(min-width: ${DESKTOP_LG}px)`,
    desktopXl: `(min-width: ${DESKTOP_XL}px)`
  };
  _exports.default = _default;
});