define("mobile-web/lib/perimeterx", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.asPerimeterXResponse = asPerimeterXResponse;

  function asPerimeterXResponse(err) {
    var _err$errors$0$message, _err$errors, _err$errors$;

    // Any valid JSON string would work here as a fallback
    const message = (_err$errors$0$message = err == null ? void 0 : (_err$errors = err.errors) == null ? void 0 : (_err$errors$ = _err$errors[0]) == null ? void 0 : _err$errors$.message) != null ? _err$errors$0$message : '""';

    try {
      // Try in case of JSON.parse errors
      const response = JSON.parse(message);

      if (typeof response === 'object' && 'blockScript' in response) {
        return response;
      }
    } catch (_unused) {
      return undefined;
    }

    return undefined;
  }
});