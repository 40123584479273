define("mobile-web/components/r-no-valid-vendor-results/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eSb4BaqI",
    "block": "[[[10,0],[14,\"data-test-no-valid-vendor-results\",\"\"],[15,0,[30,0,[\"style\",\"wrapper\"]]],[12],[1,\"\\n  \"],[10,2],[14,\"data-test-no-valid-vendor-message\",\"\"],[15,0,[30,0,[\"style\",\"content\"]]],[12],[1,\"\\n    \"],[1,[30,0,[\"message\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,0],null,[[\"@fullWidth\",\"@size\",\"@onClick\"],[true,400,[30,0,[\"clickEdit\"]]]],[[\"default\"],[[[[1,\"\\n    Edit Your Search\\n  \"]],[]]]]],[1,\"\\n\\n\"],[13]],[],false,[\"r-button\"]]",
    "moduleName": "mobile-web/components/r-no-valid-vendor-results/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});