define("mobile-web/components/r-application-loading/splash/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z5QXDJf0",
    "block": "[[[8,[39,0],[[16,0,[30,0,[\"style\",\"root\"]]]],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"img\"]],[[16,0,[30,0,[\"style\",\"image\"]]]],null,null],[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"style\",\"controls\"]]],[12],[1,\"\\n    \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"mainHeader\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n    \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"subHeader\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n    \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"input\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n    \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"input\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n    \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"input\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n    \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"button\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]]]]]],[\"placeholder\"],false,[\"content-placeholders\"]]",
    "moduleName": "mobile-web/components/r-application-loading/splash/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});