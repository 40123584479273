define("mobile-web/components/loading-indicator/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "loadingIndicator": "_loadingIndicator_mhvtic",
    "overlay": "_overlay_mhvtic",
    "spinner": "_spinner_mhvtic",
    "spinnerIcon": "_spinnerIcon_mhvtic",
    "loadingText": "_loadingText_mhvtic"
  };
  _exports.default = _default;
});