define("mobile-web/adapters/loyalty-membership", ["exports", "mobile-web/lib/hybrid-util", "mobile-web/adapters/application"], function (_exports, _hybridUtil, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LoyaltyMembershipAdapter extends _application.default {
    deleteRecord(_store, _type, snapshot) {
      const model = snapshot.record;
      return this.ajax(this.buildURL('loyalty-membership', snapshot.id, snapshot, 'deleteRecord'), 'DELETE', {
        // If we just do `data: model.membershipId` (no string), 0 is interpreted as null
        data: `${model.membershipId}`
      });
    }

    pathForType() {
      return 'loyalty/memberships';
    }

    urlForDeleteRecord() {
      return `${(0, _hybridUtil.host)()}/${this.namespace}/${this.pathForType()}`;
    }

  }

  _exports.default = LoyaltyMembershipAdapter;
});