define("mobile-web/components/r-application-loading/locations/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "mainHeader": "_mainHeader_1ugdph",
    "img": "_img_1ugdph",
    "main": "_main_1ugdph",
    "header": "_header_1ugdph",
    "grid": "_grid_1ugdph",
    "state": "_state_1ugdph"
  };
  _exports.default = _default;
});