define("mobile-web/components/routes/arrival-route/component", ["exports", "@glimmer/component", "mobile-web/components/r-post-checkout/component", "mobile-web/lib/dayjs", "mobile-web/lib/order-criteria", "mobile-web/lib/utilities/_", "mobile-web/services/analytics", "mobile-web/components/routes/arrival-route/style"], function (_exports, _component, _component2, _dayjs, _orderCriteria, _, _analytics, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ArrivalRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class ArrivalRoute extends _component.default {
    // Service injections
    // Passthroughs
    // Tracked properties
    // Getters and setters
    get showParkingLocationForm() {
      return !this.parkingLocationShown && this.args.order.requireArrivalMessage;
    }

    get analyticsProperties() {
      return {
        [_analytics.AnalyticsProperties.UtmMedium]: (0, _.isEmpty)(this.args.utmMedium) ? 'serve' : this.args.utmMedium,
        [_analytics.AnalyticsProperties.BasketHandoffMode]: (0, _orderCriteria.getHandoffMode)(this.args.order.deliveryMode),
        [_analytics.AnalyticsProperties.SupportsParkingLocation]: this.args.order.requireArrivalMessage
      };
    } // Constructor


    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "analytics", _descriptor2, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "parkingLocationShown", _descriptor3, this);

      _initializerDefineProperty(this, "parkingLocation", _descriptor4, this);

      this.startTime = (0, _dayjs.default)().utc();
    } // Other methods
    // Tasks
    // Actions


    onSubmit(event) {
      event.preventDefault();
      this.parkingLocationShown = true;
      this.args.order.arrived({
        id: this.args.order.orderGuid,
        hash: this.args.order.orderHash,
        message: this.parkingLocation
      }).then(() => {
        const elasped = Math.round((0, _dayjs.default)().utc().diff(this.startTime) / 1000);
        this.analytics.trackEvent(_analytics.AnalyticsEvents.UserFiredOrderEvent, () => _extends({
          [_analytics.AnalyticsProperties.OrderEvent]: _component2.UserOrderEvent.Arrival,
          [_analytics.AnalyticsProperties.ElapsedSeconds]: elasped
        }, this.analyticsProperties));
      });
    }

    onInsert() {
      if (!this.showParkingLocationForm) {
        this.args.order.arrived({
          id: this.args.order.orderGuid,
          hash: this.args.order.orderHash
        }).then(() => {
          this.analytics.trackEvent(_analytics.AnalyticsEvents.UserFiredOrderEvent, () => _extends({
            [_analytics.AnalyticsProperties.OrderEvent]: _component2.UserOrderEvent.Arrival
          }, this.analyticsProperties));
        });
      } else {
        this.analytics.trackEvent(_analytics.AnalyticsEvents.AskedForParkingLocation, () => _extends({}, this.analyticsProperties));
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "parkingLocationShown", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "parkingLocation", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onSubmit", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onInsert", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onInsert"), _class.prototype)), _class));
  _exports.default = ArrivalRoute;
});