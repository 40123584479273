define("mobile-web/components/r-side-menu-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oFPhuCI4",
    "block": "[[[11,0],[16,\"data-test-sideMenuLink\",[30,0,[\"args\",\"testSelector\"]]],[17,1],[12],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"    \"],[8,[39,1],[[16,0,[30,0,[\"style\",\"link\"]]]],[[\"@route\",\"@testSelector\"],[[30,2],[30,0,[\"testSelector\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,2],null,[[\"@icon\",\"@class\",\"@ariaLabel\"],[[30,0,[\"args\",\"svg\"]],[30,0,[\"style\",\"icon\"]],\"\"]],null],[1,\"\\n      \"],[10,1],[15,0,[30,0,[\"style\",\"text\"]]],[12],[1,\"\\n        \"],[1,[28,[35,3],[[30,0,[\"translation\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n\"]],[]],[[[41,[30,3],[[[1,\"    \"],[11,\"button\"],[16,0,[30,0,[\"style\",\"link\"]]],[16,\"data-test-sideMenu\",[30,0,[\"testSelector\"]]],[4,[38,4],[\"click\",[30,3]],null],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@icon\",\"@class\",\"@ariaLabel\"],[[30,0,[\"args\",\"svg\"]],[30,0,[\"style\",\"icon\"]],\"\"]],null],[1,\"\\n      \"],[10,1],[15,0,[30,0,[\"style\",\"text\"]]],[12],[1,\"\\n        \"],[1,[28,[35,3],[[30,0,[\"translation\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n\\n    \"]],[]],null]],[]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@route\",\"@onClick\"],false,[\"if\",\"r-link-to\",\"r-svg\",\"t\",\"on\"]]",
    "moduleName": "mobile-web/components/r-side-menu-link/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});