define("mobile-web/decorators/saved-attributes", ["exports", "mobile-web/lib/utilities/is-some"], function (_exports, _isSome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const savedAttributes = (target, key) => {
    (false && !(key === 'savedAttributes') && Ember.assert('`key` must be equal to `savedAttributes` to keep a consistent API', key === 'savedAttributes'));
    Ember.defineProperty(target, key, Ember.computed('hasDirtyAttributes', function () {
      // eslint-disable-next-line ember/no-get
      if (this.get('hasDirtyAttributes')) {
        const changes = this.changedAttributes();
        const result = {};
        target.constructor.eachAttribute(name => {
          const change = changes[name];
          result[name] = (0, _isSome.default)(change) ? change[0] : this[name];
        }, this);
        return result;
      }

      return this;
    }));
    return {};
  };

  var _default = savedAttributes;
  _exports.default = _default;
});