define("mobile-web/components/routes/forgot-password-route/component", ["exports", "@glimmer/component", "ember-concurrency", "ember-concurrency-ts", "mobile-web/lib/validation", "mobile-web/services/user-feedback", "mobile-web/components/routes/forgot-password-route/style"], function (_exports, _component, _emberConcurrency, _emberConcurrencyTs, _validation, _userFeedback, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ForgotPasswordRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._action, (_class = class ForgotPasswordRoute extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "challenge", _descriptor, this);

      _initializerDefineProperty(this, "error", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "session", _descriptor5, this);

      _initializerDefineProperty(this, "store", _descriptor6, this);

      _initializerDefineProperty(this, "userFeedback", _descriptor7, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "email", _descriptor8, this);

      _initializerDefineProperty(this, "validationResult", _descriptor9, this);
    }

    // Getters and setters
    get validationConfig() {
      return {
        bindings: [{
          targetProp: 'email',
          ruleName: 'email',
          message: this.intl.t('mwc.forgotPassword.errors.email')
        }]
      };
    }

    get isContinueDisabled() {
      return Ember.isEmpty(this.email);
    } // Constructor
    // Other methods
    // Tasks


    *continueTask(event) {
      event.preventDefault();
      this.validationResult = _validation.default.validate(this, this.validationConfig);

      if (this.validationResult.isOk()) {
        (0, _emberConcurrencyTs.taskFor)(this.challenge.request).perform(async () => {
          await this.store.collectionAction('user', 'forgotPassword', {
            email: this.email
          });
          this.userFeedback.add({
            type: _userFeedback.Type.Positive,
            title: this.intl.t('mwc.forgotPassword.successTitle'),
            message: this.intl.t('mwc.forgotPassword.successMessage')
          });
        }, e => {
          this.error.reportError(e);
        });
      }
    } // Actions


    onCancel() {
      this.router.transitionTo('secure.login');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "challenge", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "error", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "userFeedback", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "email", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "validationResult", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "continueTask", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "continueTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCancel", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onCancel"), _class.prototype)), _class));
  _exports.default = ForgotPasswordRoute;
});