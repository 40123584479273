define("mobile-web/components/r-post-checkout/r-feedback-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YBoDLe4G",
    "block": "[[[10,\"aside\"],[15,\"title\",[28,[37,0],[\"mwc.postCheckout.feedbackLabel\"],null]],[15,0,[30,0,[\"rootClass\"]]],[15,\"data-test-post-checkout-feedback-section\",[30,0,[\"url\"]]],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@icon\"],[[50,\"r-svg\",0,null,[[\"icon\",\"ariaLabel\"],[\"r-very-satisfied\",\"\"]]]]],[[\"content\",\"buttons\"],[[[[1,\"\\n      \"],[10,2],[12],[1,\"\\n        \"],[8,[39,3],null,[[\"@key\",\"@defaultMessage\"],[\"RESP_POSTCHECKOUT_FEEDBACK_PROMPT\",[28,[37,0],[\"mwc.postCheckout.feedbackPrompt\"],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]],[[[1,\"\\n      \"],[8,[30,1,[\"buttonContainer\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],[[16,0,[30,1,[\"buttonClass\"]]]],[[\"@onClick\",\"@variant\"],[[30,0,[\"giveFeedback\"]],\"minor\"]],[[\"default\"],[[[[1,\"\\n          \"],[8,[39,3],null,[[\"@key\",\"@defaultMessage\"],[\"RESP_POSTCHECKOUT_FEEDBACK_BUTTON\",[28,[37,0],[\"mwc.postCheckout.feedbackLabel\"],null]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"s\"],false,[\"t\",\"r-post-checkout/r-subsection\",\"component\",\"x-content\",\"r-button\"]]",
    "moduleName": "mobile-web/components/r-post-checkout/r-feedback-section/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});