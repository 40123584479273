define("mobile-web/components/r-edit-order-criteria-modal/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "form": "_form_1hb5py",
    "changeLocationLink": "_changeLocationLink_1hb5py"
  };
  _exports.default = _default;
});