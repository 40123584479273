define("mobile-web/routes/menu/vendor/reward", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RewardRoute extends Ember.Route {
    beforeModel(transition) {
      this.replaceWith('menu.vendor.rewards', transition.to.params.vendor_slug);
    }

  }

  _exports.default = RewardRoute;
});