define("mobile-web/modifiers/ref", ["exports", "@glint/environment-ember-loose/ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RefModifier extends _emberModifier.default {
    get context() {
      return this.args.positional[0];
    }

    get name() {
      const name = this.args.positional[1];
      (false && !(typeof name === 'string' && name in this.context) && Ember.assert(`second positional argument must be a valid property name of ${this.context}`, typeof name === 'string' && name in this.context));
      return name;
    }

    get isArray() {
      return Array.isArray(this.value);
    }

    get value() {
      return this.context[this.name];
    }

    set value(el) {
      if (!this.isArray) {
        this.context[this.name] = el;
      } else {
        let val = this.context[this.name];

        if (el) {
          val.push(el);
        } else {
          val = val.filter(e => e !== el);
        }

        this.context[this.name] = val;
      }
    }
    /**
     * Store the element.
     */


    didInstall() {
      Ember.run.next(this, () => {
        this.value = this.element;
      });
    }
    /**
     * Stop storing the element.
     */


    willDestroy() {
      Ember.run.next(this, () => {
        this.value = undefined;
      });
    }

  }

  _exports.default = RefModifier;
});