define("mobile-web/components/r-tag-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UDHHnRM5",
    "block": "[[[10,0],[15,0,[30,0,[\"style\",\"tagList\"]]],[14,\"data-test-tag-list\",\"\"],[12],[1,\"\\n  \"],[18,1,[[28,[37,1],null,[[\"tagClass\"],[[30,0,[\"style\",\"tag\"]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\"]]",
    "moduleName": "mobile-web/components/r-tag-list/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});