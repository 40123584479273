define("mobile-web/services/performance-metric", ["exports", "mobile-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  class PerformanceMetricService extends Ember.Service {
    constructor(...args) {
      super(...args);
      this.reportedToRaygun = false;
      this.timingKeys = ['scriptsLoaded', 'applicationInit', 'applicationAfterModel'];
    }

    init() {
      super.init();

      if (window.Olo.scriptsLoaded) {
        this.timings = {
          scriptsLoaded: Math.floor(window.Olo.scriptsLoaded)
        };
      }
    } // Do not re-order the contents of this array


    pushTiming(key) {
      if (!this.timings) {
        return;
      }

      if (!this.timingKeys.includes(key)) {
        throw new Error(`Invalid timingKey ${key}`);
      }

      if (this.timings[key] === undefined) {
        this.timings = _extends({}, this.timings, {
          [key]: Math.floor(performance.now())
        });
      }
    }

    async reportTimings() {
      if (!this.timings) {
        return;
      }

      const raygunTimings = this.timingKeys.reduce((prev, curr, index) => _extends({}, prev, {
        [`custom${index + 1}`]: this.timings[curr]
      }), {});

      if (_environment.default.enableRaygun) {
        rg4js('trackEvent', {
          type: 'customTimings',
          timings: raygunTimings
        });
      } else {
        /* eslint-disable no-console */
        console.table(this.timings);
      }

      this.reportedToRaygun = true;
    }

  }

  _exports.default = PerformanceMetricService;
});