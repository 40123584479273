define("mobile-web/components/r-form-field-input/component", ["exports", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some"], function (_exports, _, _isSome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO: remove eslint-disabling by reworking as class with optional types

  /* eslint-disable no-null/no-null */
  class FormFieldInput extends Ember.Component.extend({
    // Passed in
    tagName: 'input',
    autocomplete: undefined,
    autofocus: undefined,
    aria: null,
    inputValue: null,
    inputMode: null,
    class: '',
    name: '',
    min: null,
    max: null,
    size: undefined,
    readonly: undefined,
    disabled: undefined,
    checked: undefined,
    tabindex: null,
    type: 'text',
    placeholder: null,
    novalidate: undefined,
    invalid: undefined,
    hasValidationMessage: false,
    // This needs to return a string because `aria-invalid` requires value to be "true" or "false"
    _invalid: Ember.computed('invalid', function () {
      if (this.invalid) {
        return 'true';
      }

      return 'false';
    }),
    classNameBindings: ['class'],
    attributeBindings: ['_invalid:aria-invalid', 'aria.describedby:aria-describedby', 'autocomplete', 'autofocus', 'checked', 'disabled', 'inputMode:inputmode', 'inputValue:value', 'max', 'maxlength', 'min', 'minlength', 'name:data-test-form-field-input', 'name:data-test-selector', 'name', 'novalidate', 'placeholder', 'readonly', 'size', 'tabindex'],

    init() {
      this._super(...arguments);

      const ariaAttrs = this.aria;
      const attributeBindings = this.attributeBindings;
      let extraAttrs = [];

      if ((0, _isSome.default)(ariaAttrs)) {
        const ariaBindings = ariaAttrs ? Object.keys(ariaAttrs).map(a => `aria.${a}:aria-${a}`) : [];
        extraAttrs = extraAttrs.concat([], ariaBindings);
      }

      if (this.tagName === 'input') {
        extraAttrs.push('type');
      }

      this.set('attributeBindings', attributeBindings.concat(extraAttrs));
    },

    change(e) {
      Ember.run.schedule('render', this, this.fixValue);
      const value = e.target.value;
      this.set('inputValue', value);
      this.onChange(value);
    },

    focusIn(_e) {
      if ((0, _.isFunction)(this.onFocus)) {
        this.onFocus();
      }
    },

    input(e) {
      Ember.run.schedule('render', this, this.fixValue);
      const value = e.target.value;
      this.set('inputValue', value);
      const onInput = this.onInput;
      return (0, _.isFunction)(onInput) ? onInput(value) : (0, _.identity)(value);
    }

  }) {
    fixValue() {
      const input = this.element;

      if ((input instanceof HTMLInputElement || input instanceof HTMLTextAreaElement) && input.value !== this.inputValue) {
        input.value = this.inputValue;
      }
    }

  }

  _exports.default = FormFieldInput;
});