define("mobile-web/components/routes/reset-password-route/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vBFcDtS7",
    "block": "[[[10,0],[15,0,[30,0,[\"style\",\"root\"]]],[14,\"data-test-routes-resetPasswordRoute\",\"\"],[12],[1,\"\\n  \"],[10,\"h1\"],[14,\"data-test-routes-resetPasswordRoute-header\",\"\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"mwc.resetPassword.header\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,2],[15,0,[30,0,[\"style\",\"instructions\"]]],[14,\"data-test-routes-resetPasswordRoute-instructions\",\"\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"mwc.resetPassword.instructions\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[11,\"form\"],[24,\"autocomplete\",\"on\"],[4,[38,1],[\"submit\",[28,[37,2],[[30,0,[\"resetPasswordTask\"]]],null]],null],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@name\",\"@class\",\"@autocomplete\",\"@label\",\"@value\",\"@onChange\",\"@validationMessages\"],[\"newPassword\",[30,0,[\"style\",\"field\"]],\"new-password\",[28,[37,0],[\"mwc.resetPassword.newPasswordLabel\"],null],[30,0,[\"newPassword\"]],[28,[37,4],[[28,[37,5],[[30,0,[\"newPassword\"]]],null]],null],[30,0,[\"validationResult\",\"error\",\"newPassword\"]]]],null],[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@name\",\"@class\",\"@autocomplete\",\"@minlength\",\"@label\",\"@value\",\"@onChange\",\"@validationMessages\"],[\"confirmPassword\",[30,0,[\"style\",\"field\"]],\"new-password\",false,[28,[37,0],[\"mwc.resetPassword.confirmPasswordLabel\"],null],[30,0,[\"confirmPassword\"]],[28,[37,4],[[28,[37,5],[[30,0,[\"confirmPassword\"]]],null]],null],[30,0,[\"validationResult\",\"error\",\"confirmPassword\"]]]],null],[1,\"\\n\\n    \"],[8,[39,6],[[16,0,[30,0,[\"style\",\"button\"]]],[16,\"disabled\",[30,0,[\"isSubmitDisabled\"]]],[24,4,\"submit\"]],[[\"@testSelector\",\"@fullWidth\"],[\"submit\",true]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,0],[\"mwc.changePassword.submitButton\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"t\",\"on\",\"perform\",\"r-password\",\"fn\",\"mut\",\"r-button\"]]",
    "moduleName": "mobile-web/components/routes/reset-password-route/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});