define("mobile-web/components/r-back-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BbsT6+g7",
    "block": "[[[8,[39,0],[[24,\"data-test-backButton\",\"\"]],[[\"@label\",\"@showLabel\",\"@icon\",\"@onClick\"],[[28,[37,1],null,[[\"text\"],[[28,[37,2],[\"mwc.header.backAltText\"],null]]]],false,[28,[37,1],null,[[\"svg\",\"ariaLabel\",\"position\"],[[30,0,[\"icon\"]],\"\",\"left\"]]],[30,0,[\"back\"]]]],null]],[],false,[\"r-header-button\",\"hash\",\"t\"]]",
    "moduleName": "mobile-web/components/r-back-button/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});