define("mobile-web/components/routes/forgot-password-route/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_14r0nw",
    "instruction": "_instruction_14r0nw",
    "button": "_button_14r0nw"
  };
  _exports.default = _default;
});