define("mobile-web/components/r-product-customization/choice/component", ["exports", "@glimmer/component", "mobile-web/lib/menu", "mobile-web/lib/runloop", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-product-customization/choice/style"], function (_exports, _component, _menu, _runloop, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProductCustomizationChoice = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class ProductCustomizationChoice extends _component.default {
    // Service injections
    // Passthroughs
    // Tracked properties
    // Getters and setters
    get basketChoice() {
      var _this$args$basketChoi;

      return (_this$args$basketChoi = this.args.basketChoice) != null ? _this$args$basketChoi : this._basketChoice;
    }

    get optionGroup() {
      return this.args.choice.optionGroup;
    }

    get canSelect() {
      return this.optionGroup.canSelectNewChoices;
    }

    get disabled() {
      var _this$basketChoice;

      return !this.canSelect && ((_this$basketChoice = this.basketChoice) == null ? void 0 : _this$basketChoice.quantity) === 0;
    }

    get name() {
      if (this.args.name) {
        return this.args.name;
      }

      const choice = this.args.choice;

      if (choice.displayGroup) {
        var _choice$optionGroup;

        if ((_choice$optionGroup = choice.optionGroup) != null && _choice$optionGroup.hasChoiceQuantities) {
          return choice.displayGroup.groupName;
        }

        return choice.displayGroup.optionName;
      }

      return choice.name;
    }

    get hideExtraAttributes() {
      var _this$args$hideExtraA, _choice$optionGroup2;

      const choice = this.args.choice;
      return (_this$args$hideExtraA = this.args.hideExtraAttributes) != null ? _this$args$hideExtraA : choice.displayGroup && !((_choice$optionGroup2 = choice.optionGroup) != null && _choice$optionGroup2.hasChoiceQuantities);
    }

    get showPrice() {
      const choice = this.args.choice;
      return !this.hideExtraAttributes && (0, _isSome.default)(choice.priceDifference) && choice.priceDifference !== 0 && !this.optionGroup.hideChoicePrices;
    }

    get showCalories() {
      var _this$args$basketProd, _this$args$basketProd2;

      const choice = this.args.choice;
      return !this.hideExtraAttributes && (0, _isSome.default)(choice.calorieLabel) && ((_this$args$basketProd = (_this$args$basketProd2 = this.args.basketProduct.vendor) == null ? void 0 : _this$args$basketProd2.settings.showCalories) != null ? _this$args$basketProd : false);
    }

    get showYieldedContent() {
      var _this$args$showYielde;

      return (_this$args$showYielde = this.args.showYieldedContent) != null ? _this$args$showYielde : false;
    }

    get showQuantity() {
      var _this$basketChoice2;

      return ((_this$basketChoice2 = this.basketChoice) == null ? void 0 : _this$basketChoice2.isSelected) === true && this.optionGroup.hasChoiceQuantities && (this.optionGroup.minChoiceQuantity !== this.optionGroup.maxChoiceQuantity || this.optionGroup.minChoiceQuantity > 1);
    }

    get maxChoiceQuantity() {
      return Math.min(this.optionGroup.maxChoiceQuantity, this.optionGroup.maxAggregateQuantity);
    }

    get choiceLimit() {
      var _this$basketChoice$qu, _this$basketChoice3;

      const maxAggregateQuantity = this.optionGroup.maxAggregateQuantity;
      const aggregateQuantity = this.optionGroup.aggregateQuantity;
      const currentQuantity = (_this$basketChoice$qu = (_this$basketChoice3 = this.basketChoice) == null ? void 0 : _this$basketChoice3.quantity) != null ? _this$basketChoice$qu : 0;
      return maxAggregateQuantity - aggregateQuantity + currentQuantity;
    } // Constructor


    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "_basketChoice", _descriptor3, this);

      if (!this.args.basketChoice) {
        // The `next` is needed because we reference the basketChoices array in the optionGroup
        // component, and Ember doesn't like you to change arrays in the same runloop that you
        // reference them.
        (0, _runloop.safeNext)(this, () => {
          const {
            choice,
            isNew
          } = (0, _menu.getOrCreateBasketChoice)(this.store, this.args.basketProduct, this.args.choice);
          this._basketChoice = choice;

          if (isNew && (0, _menu.isParentNew)(this.args.basketProduct, this.args.choice)) {
            var _this$args$choice$opt;

            (_this$args$choice$opt = this.args.choice.optionGroup) == null ? void 0 : _this$args$choice$opt.splitChoiceQuantities();
          }

          (0, _menu.loadNestedMenuItems)(this.store, this.args.basketProduct, this.args.choice, false);
        });
      }
    } // Other methods
    // Tasks
    // Actions


    click(e) {
      e.stopPropagation();
      const basketChoice = this.basketChoice;

      if (basketChoice) {
        const canSelect = basketChoice.quantity === 0 && this.canSelect;
        const canDeselect = basketChoice.quantity > 0;

        if (canSelect || canDeselect) {
          var _this$args$onClick, _this$args;

          (_this$args$onClick = (_this$args = this.args).onClick) == null ? void 0 : _this$args$onClick.call(_this$args, basketChoice);
        }
      }
    }

    updateQuantity(quantity) {
      if (this.basketChoice) {
        var _this$args$choice$opt2;

        this.basketChoice.quantity = quantity;
        (_this$args$choice$opt2 = this.args.choice.optionGroup) == null ? void 0 : _this$args$choice$opt2.quantityChanged();
      }
    }

    preventPropagation(e) {
      e.stopPropagation();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_basketChoice", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "click", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateQuantity", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "updateQuantity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "preventPropagation", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "preventPropagation"), _class.prototype)), _class));
  _exports.default = ProductCustomizationChoice;
});