define("mobile-web/lib/data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Data = _exports.isError = _exports.Error = _exports.isLoaded = _exports.Loaded = _exports.isLoading = _exports.Loading = _exports.Type = void 0;
  let Type;
  _exports.Type = Type;

  (function (Type) {
    Type[Type["Loading"] = 0] = "Loading";
    Type[Type["Loaded"] = 1] = "Loaded";
    Type[Type["Error"] = 2] = "Error";
  })(Type || (_exports.Type = Type = {}));

  class Loading {
    constructor() {
      this.type = Type.Loading;
    }

  }

  _exports.Loading = Loading;

  const isLoading = t => t.type === Type.Loading;

  _exports.isLoading = isLoading;

  class Loaded {
    constructor(value) {
      this.type = Type.Loaded;
      this.value = value;
    }

    static withValue(value) {
      return new Loaded(value);
    }

  }

  _exports.Loaded = Loaded;

  const isLoaded = t => t.type === Type.Loaded;

  _exports.isLoaded = isLoaded;

  class Error {
    constructor(value) {
      this.type = Type.Error;
      this.value = value;
    }

    static withValue(value) {
      return new Error(value);
    }

  }

  _exports.Error = Error;

  const isError = t => t.type === Type.Error;

  _exports.isError = isError;
  const Data = {
    Type,
    Loading,
    Loaded,
    Error,
    isLoading,
    isLoaded,
    isError
  };
  _exports.Data = Data;
  var _default = Data;
  _exports.default = _default;
});