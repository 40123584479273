define("mobile-web/components/routes/check-in-route/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "62q61Kis",
    "block": "[[[11,0],[16,0,[30,0,[\"style\",\"container\"]]],[24,\"data-test-check-in\",\"\"],[4,[38,0],[[30,0,[\"onInsert\"]]],null],[12],[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"style\",\"content\"]]],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@key\"],[\"RESP_MANUAL_FIRE_POSTCHECKIN_HEADER\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1,[\"value\"]],[[[1,\"        \"],[10,\"h1\"],[14,\"data-test-check-in-manual-fire-header\",\"\"],[12],[1,\"\\n          \"],[1,[30,1,[\"value\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[1]]]]],[1,\"\\n\\n    \"],[8,[39,1],null,[[\"@key\"],[\"MANUAL_FIRE_LABEL\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,2,[\"isLoaded\"]],[[[1,\"        \"],[8,[39,3],null,[[\"@heading\",\"@class\"],[[28,[37,4],[\"mwc.postCheckout.instructionsHeader\"],[[\"label\"],[[30,2,[\"value\"]]]]],[30,0,[\"style\",\"section\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[30,3,[\"subsection\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[39,5],null,null,[[\"content\"],[[[[1,\"\\n                \"],[10,2],[15,0,[30,0,[\"style\",\"instructions\"]]],[14,\"data-test-check-in-manual-fire-instructions\",\"\"],[12],[1,\"\\n                  \"],[8,[39,1],null,[[\"@key\"],[\"MANUAL_FIRE_PICKUP_INSTRUCTIONS\"]],null],[1,\"\\n                \"],[13],[1,\"\\n              \"]],[]]]]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"]],[3]]]]],[1,\"\\n\\n        \"],[10,0],[15,0,[30,0,[\"style\",\"thanksMessageDetails\"]]],[14,\"data-test-check-in-thanks-detail\",\"\"],[12],[1,\"\\n          \"],[8,[39,6],null,null,null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[2]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"content\",\"labelContent\",\"h\"],false,[\"did-insert\",\"x-content\",\"if\",\"r-heading-section\",\"t\",\"r-post-checkout/r-subsection\",\"r-post-checkout/r-thanks-message\"]]",
    "moduleName": "mobile-web/components/routes/check-in-route/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});