define("mobile-web/instance-initializers/polyfill", ["exports", "smoothscroll-polyfill", "intersection-observer"], function (_exports, _smoothscrollPolyfill, _intersectionObserver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _smoothscrollPolyfill.default.polyfill();
  }

  var _default = {
    name: 'polyfill',
    initialize
  };
  _exports.default = _default;
});