define("mobile-web/components/r-contact-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LAHOrH3O",
    "block": "[[[8,[39,0],null,[[\"@name\",\"@header\",\"@onEdit\",\"@editLabel\"],[[28,[37,1],[[30,0,[\"headerKey\"]]],null],[28,[37,1],[[30,0,[\"headerKey\"]]],null],[52,[30,0,[\"isEditable\"]],[30,0,[\"edit\"]]],[30,0,[\"editLabelKey\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,2],[15,0,[30,0,[\"style\",\"truncate\"]]],[14,\"data-test-contactInfo-name\",\"\"],[15,\"title\",[28,[37,1],[\"mwc.contactInfo.fullName\"],[[\"firstName\",\"lastName\"],[[30,1,[\"firstName\"]],[30,1,[\"lastName\"]]]]]],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"mwc.contactInfo.fullName\"],[[\"firstName\",\"lastName\"],[[30,1,[\"firstName\"]],[30,1,[\"lastName\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,2],[15,0,[28,[37,3],[[30,0,[\"style\",\"truncate\"]],[30,0,[\"style\",\"field\"]]],null]],[14,\"data-test-contactInfo-email\",\"\"],[15,\"title\",[30,1,[\"emailAddress\"]]],[12],[1,\"\\n    \"],[1,[30,1,[\"emailAddress\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,2],[15,0,[28,[37,3],[[30,0,[\"style\",\"truncate\"]],[30,0,[\"style\",\"field\"]]],null]],[14,\"data-test-contactInfo-contactNumber\",\"\"],[12],[1,\"\\n    \"],[1,[28,[35,4],[[30,1,[\"contactNumber\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[28,[37,5],[[30,2],[30,0,[\"showModal\"]]],null],[[[1,\"    \"],[8,[39,6],null,[[\"@customer\",\"@errorMessage\",\"@onSubmit\",\"@onClose\",\"@isFullyEditable\",\"@isOptInVisible\"],[[30,1],[30,2],[30,0,[\"handleChange\"]],[30,0,[\"closeModal\"]],[30,3],[30,4]]],null],[1,\"\\n\"]],[]],null]],[]]]]],[1,\"\\n\"]],[\"@customer\",\"@errorMessage\",\"@isFullyEditable\",\"@isOptInVisible\"],false,[\"r-editable-section\",\"t\",\"if\",\"classes\",\"format-phone\",\"or\",\"r-edit-contact-info-modal\"]]",
    "moduleName": "mobile-web/components/r-contact-info/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});