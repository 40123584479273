define("mobile-web/models/billing-scheme", ["exports", "ember-data", "mobile-web/decorators/member-action", "mobile-web/lib/payment", "mobile-web/lib/perimeterx"], function (_exports, _emberData, _memberAction, _payment, _perimeterx) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.BalanceCode = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * We are setting a very high default value here, since it should be overwritten
   * by the back end, and if it isn't it'll never actually be hit by the user
   */
  const UPPER_LIMIT = 1000;
  const LOWER_LIMIT = 0;
  let BalanceCode;
  _exports.BalanceCode = BalanceCode;

  (function (BalanceCode) {
    BalanceCode["Success"] = "Success";
    BalanceCode["CannotCheck"] = "CannotCheck";
    BalanceCode["MissingPin"] = "MissingPin";
    BalanceCode["InvalidPin"] = "InvalidPin";
  })(BalanceCode || (_exports.BalanceCode = BalanceCode = {}));

  let BillingScheme = (_dec = _emberData.default.attr('string'), _dec2 = _emberData.default.attr('string'), _dec3 = _emberData.default.attr('boolean'), _dec4 = _emberData.default.attr('boolean'), _dec5 = _emberData.default.attr('number', {
    defaultValue: LOWER_LIMIT
  }), _dec6 = _emberData.default.attr('number', {
    defaultValue: UPPER_LIMIT
  }), _dec7 = _emberData.default.attr('number', {
    defaultValue: LOWER_LIMIT
  }), _dec8 = _emberData.default.attr('number', {
    defaultValue: UPPER_LIMIT
  }), _dec9 = _emberData.default.attr('string'), _dec10 = _emberData.default.attr(), _dec11 = _emberData.default.attr(), _dec12 = _emberData.default.hasMany('billing-membership', {
    async: false
  }), _dec13 = (0, _memberAction.default)({
    type: 'post',
    // @ts-ignore - we are intentionally using a fake type to not conflict with other adapter uses
    urlType: 'balance'
  }), _dec14 = (0, _memberAction.default)({
    type: 'post',
    // @ts-ignore - we are intentionally using a fake type to not conflict with other adapter uses
    urlType: 'pinRequired'
  }), (_class = class BillingScheme extends _emberData.default.Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "description", _descriptor2, this);

      _initializerDefineProperty(this, "isGiftCard", _descriptor3, this);

      _initializerDefineProperty(this, "allowsPin", _descriptor4, this);

      _initializerDefineProperty(this, "pinLengthLowerLimit", _descriptor5, this);

      _initializerDefineProperty(this, "pinLengthUpperLimit", _descriptor6, this);

      _initializerDefineProperty(this, "accountNumberLengthLowerLimit", _descriptor7, this);

      _initializerDefineProperty(this, "accountNumberLengthUpperLimit", _descriptor8, this);

      _initializerDefineProperty(this, "provider", _descriptor9, this);

      _initializerDefineProperty(this, "customFieldValues", _descriptor10, this);

      _initializerDefineProperty(this, "customSettings", _descriptor11, this);

      _initializerDefineProperty(this, "memberships", _descriptor12, this);

      _initializerDefineProperty(this, "_getBalance", _descriptor13, this);

      _initializerDefineProperty(this, "getPinIsRequired", _descriptor14, this);
    }

    get singleUseOnly() {
      return this.provider === _payment.Provider.LevelUp || this.provider === _payment.Provider.PassThrough;
    }

    getBalance(payload) {
      return this._getBalance(payload).catch(err => {
        if ((0, _perimeterx.asPerimeterXResponse)(err)) {
          throw err;
        }

        return {
          code: BalanceCode.CannotCheck
        };
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isGiftCard", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "allowsPin", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "pinLengthLowerLimit", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "pinLengthUpperLimit", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "accountNumberLengthLowerLimit", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "accountNumberLengthUpperLimit", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "provider", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "customFieldValues", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "customSettings", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "memberships", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "_getBalance", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "getPinIsRequired", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BillingScheme;
});