define("mobile-web/components/r-get-directions-button/component", ["exports", "@glimmer/component", "mobile-web/lib/location/address", "mobile-web/lib/utilities/classes", "mobile-web/services/analytics", "mobile-web/components/r-get-directions-button/style"], function (_exports, _component, _address, _classes, _analytics, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let GetDirectionsButton = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class GetDirectionsButton extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "device", _descriptor2, this);

      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get rootClass() {
      return (0, _classes.classes)(_style.default.root, {
        [_style.default.minor]: this.args.isMinor
      });
    }

    get href() {
      return _address.default.toMapsApplicationLink(this.args.address, this.device.isIOS);
    } // Constructor
    // Other methods
    // Tasks
    // Actions


    track() {
      Ember.run.scheduleOnce('afterRender', this, this._track);
    }

    _track() {
      this.analytics.trackEvent(_analytics.AnalyticsEvents.GetDirections, () => ({}), {
        isLink: true,
        domQuery: '.get-directions-button'
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "device", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "track", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "track"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_track", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "_track"), _class.prototype)), _class));
  _exports.default = GetDirectionsButton;
});