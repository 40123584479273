define("mobile-web/components/r-save-favorite-modal/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "favoriteIcon": "_favoriteIcon_f4e2wf",
    "text": "_text_f4e2wf",
    "haveAccountPrompt": "_haveAccountPrompt_f4e2wf _text_f4e2wf"
  };
  _exports.default = _default;
});