define("mobile-web/components/r-application-loading/locations/component", ["exports", "@glimmer/component", "mobile-web/components/r-application-loading/locations/style"], function (_exports, _component, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApplicationLoadingLocations extends _component.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    } // Tracked properties
    // Getters and setters
    // Constructor
    // Other methods
    // Tasks
    // Actions


  }

  _exports.default = ApplicationLoadingLocations;
});