define("mobile-web/services/channel", ["exports", "mobile-web/lib/country"], function (_exports, Country) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.readOnly('data.content'), _dec4 = Ember.computed.alias('current.settings'), _dec5 = Ember.computed.alias('data.currentCountry'), _dec6 = Ember.computed.alias('bootstrap.data'), _dec7 = Ember.computed('bootstrap.data', 'content', 'data'), _dec8 = Ember.computed('countrySettings'), _dec9 = Ember.computed('settings.channelCountrySettingsByCountryCode'), _dec10 = Ember.computed('countrySettings'), _dec11 = Ember.computed('countries', 'currentCountry', 'firstCountry'), _dec12 = Ember.computed('channel.{countrySettings,currentCountry}', 'countrySettings', 'currentCountry', 'firstCountry'), _dec13 = Ember.computed('current'), (_class = class ChannelService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "bootstrap", _descriptor2, this);

      _initializerDefineProperty(this, "content", _descriptor3, this);

      _initializerDefineProperty(this, "settings", _descriptor4, this);

      _initializerDefineProperty(this, "currentCountry", _descriptor5, this);

      _initializerDefineProperty(this, "data", _descriptor6, this);
    }

    get current() {
      var _this$content;

      if (Ember.isNone(this.data)) {
        return undefined;
      }

      const store = this.store;
      const bootstrapChannel = Ember.get(this.data, 'channel');

      if (Ember.isNone(bootstrapChannel)) {
        throw new Error('Bootstrap data is missing channel configuration.');
      }

      const channel = store.peekRecord('channel', bootstrapChannel.id);
      const content = (_this$content = this.content) != null ? _this$content : [];

      if (content.length) {
        store.pushPayload('content', {
          content
        });
      }

      return channel;
    }

    get firstCountry() {
      return Object.keys(this.countrySettings)[0];
    }

    get countrySettings() {
      var _this$settings$channe, _this$settings;

      return (_this$settings$channe = (_this$settings = this.settings) == null ? void 0 : _this$settings.channelCountrySettingsByCountryCode) != null ? _this$settings$channe : Country.defaultSettings();
    }

    get countries() {
      const codes = Object.keys(this.countrySettings);
      return codes.map(code => ({
        code,
        name: this.countrySettings[code].name
      }));
    }

    get countryConfig() {
      var _this$currentCountry;

      return {
        current: (_this$currentCountry = this.currentCountry) != null ? _this$currentCountry : this.firstCountry,
        supported: this.countries
      };
    }

    get currentCountrySettings() {
      const countrySettings = this.countrySettings;
      const currentCountrySettings = countrySettings[this.currentCountry || this.firstCountry];
      return currentCountrySettings || countrySettings[this.firstCountry];
    }

    get heroImageUrl() {
      return this.buildCdnImageUrl('/hero/mobile.png');
    }

    buildCdnImageUrl(path) {
      var _this$settings2;

      const current = this.current;

      if (!current) {
        return '';
      }

      const cacheVersion = (_this$settings2 = this.settings) == null ? void 0 : _this$settings2.cacheVersion;
      const cacheQueryString = Ember.isNone(cacheVersion) ? '' : `?v=${cacheVersion}`;
      return `${current.cdnUrl}/img/${current.internalName}${path}${cacheQueryString}`;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "bootstrap", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "content", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentCountry", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "data", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "current", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "current"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "firstCountry", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "firstCountry"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "countrySettings", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "countrySettings"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "countries", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "countries"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "countryConfig", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "countryConfig"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "currentCountrySettings", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "currentCountrySettings"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "heroImageUrl", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "heroImageUrl"), _class.prototype)), _class));
  _exports.default = ChannelService;
});