define("mobile-web/components/r-svg/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-svg/style"], function (_exports, _glimmerComponent, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Svg = (_dec = Ember.inject.service, (_class = class Svg extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get ariaLabel() {
      if ((0, _isSome.isSome)(this.args.ariaLabel)) {
        // drop the `aria-label` attribute when it's empty because `[role]` will
        // be set to `presentation` and the label is unnecessary.
        return this.args.ariaLabel || undefined;
      }

      const keyName = `mwc.iconAriaLabel.${Ember.String.camelize(this.iconName)}`;
      return this.intl.exists(keyName) ? this.intl.t(keyName) : undefined;
    }

    get containerClass() {
      var _this$args$class;

      return [this.style.container, (_this$args$class = this.args.class) != null ? _this$args$class : ''].join(' ');
    }

    get iconClass() {
      var _this$args$iconClass;

      return [this.style.icon, (_this$args$iconClass = this.args.iconClass) != null ? _this$args$iconClass : ''].join(' ');
    }

    get iconName() {
      return [this.args.icon, this.args.halo ? '-halo' : ''].join('');
    }

    get role() {
      return this.args.ariaLabel === '' ? 'presentation' : 'img';
    } // Constructor
    // Other methods
    // Tasks
    // Actions


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Svg;
});