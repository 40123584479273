define("mobile-web/routes/index", ["exports", "ember-object-update", "mobile-web/decorators/indexed", "mobile-web/lib/utilities/headers"], function (_exports, _emberObjectUpdate, _indexed, _headers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IndexRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.alias('channel.current'), _dec4 = Ember.computed.readOnly('session.isLoggedIn'), (0, _indexed.default)(_class = (_class2 = class IndexRoute extends Ember.Route {
    constructor(...args) {
      super(...args);
      this.previousRoute = undefined;
      this.headTags = [];

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "channel", _descriptor2, this);

      _initializerDefineProperty(this, "currentChannel", _descriptor3, this);

      _initializerDefineProperty(this, "isLoggedIn", _descriptor4, this);
    }

    activate() {
      var _this$currentChannel;

      const url = `https://${(_this$currentChannel = this.currentChannel) == null ? void 0 : _this$currentChannel.settings.fullSiteUrl}`;
      (0, _emberObjectUpdate.default)(this, 'headTags', (0, _headers.setCanonicalUrl)(url));
    }

    model() {
      if (this.isLoggedIn) {
        return this.store.findAll('order-search-result').then(orders => orders.filterBy('canUserCheckin', true));
      }

      return [];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "channel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "currentChannel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "isLoggedIn", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = IndexRoute;
});