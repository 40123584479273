define("mobile-web/components/r-notification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b6+2+O4g",
    "block": "[[[11,0],[16,\"aria-labelledby\",[30,0,[\"messageId\"]]],[24,\"aria-modal\",\"false\"],[16,0,[30,0,[\"notificationClass\"]]],[24,\"data-test-notification\",\"\"],[24,\"role\",\"dialog\"],[4,[38,0],[[30,0,[\"open\"]]],null],[12],[1,\"\\n\"],[41,[33,2],[[[1,\"    \"],[8,[39,3],[[16,0,[30,0,[\"style\",\"icon\"]]]],[[\"@ariaLabel\",\"@halo\",\"@icon\"],[[30,0,[\"iconLabel\"]],true,[30,0,[\"icon\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,1],[15,0,[30,0,[\"style\",\"message\"]]],[14,\"data-test-notification\",\"message\"],[15,1,[30,0,[\"messageId\"]]],[14,\"role\",\"alert\"],[12],[1,[30,0,[\"message\"]]],[13],[1,\"\\n\\n  \"],[11,\"button\"],[16,0,[30,0,[\"style\",\"closeButton\"]]],[24,\"data-test-notification\",\"close\"],[24,4,\"button\"],[4,[38,4],[\"click\",[30,0,[\"close\"]]],null],[12],[1,\"\\n    \"],[8,[39,3],[[16,0,[30,0,[\"style\",\"closeIcon\"]]]],[[\"@ariaLabel\",\"@icon\"],[[28,[37,5],[\"mwc.notifications.close\"],null],\"r-x\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"did-insert\",\"if\",\"icon\",\"r-svg\",\"on\",\"t\"]]",
    "moduleName": "mobile-web/components/r-notification/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});