define("mobile-web/components/r-cart/totals/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "ember-concurrency-ts", "mobile-web/lib/order-criteria", "mobile-web/components/r-cart/totals/style"], function (_exports, _glimmerComponent, _emberConcurrencyTs, _orderCriteria, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CartTotals = (_dec = Ember.inject.service('basket'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, (_class = class CartTotals extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "basketService", _descriptor, this);

      _initializerDefineProperty(this, "features", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get isProcessing() {
      return (0, _emberConcurrencyTs.taskFor)(this.basketService.updateTipTask).isRunning;
    }

    get isDelivery() {
      return (0, _orderCriteria.isDeliveryMode)(this.args.basket.handoffMode);
    }
    /**
     * Normally vendorDiscount is a positive number (e.g. 5 off),
     * this method makes it a negative number (e.g. -5 to the total)
     */


    get vendorDiscount() {
      // comp card discounts are included in vendorDiscount, but we pretend it is a payment method not a discount
      // so in this context we have to remove any comp card discount from the vendorDiscount
      return this.args.basket.compCardDiscount - this.args.basket.vendorDiscount;
    }

    get discountLabel() {
      var _ref, _this$args$basket$cou, _this$args$basket$rew;

      return (_ref = this.args.basket.hasCoupon ? (_this$args$basket$cou = this.args.basket.coupon) == null ? void 0 : _this$args$basket$cou.code : (_this$args$basket$rew = this.args.basket.reward) == null ? void 0 : _this$args$basket$rew.label) != null ? _ref : '';
    }

    get feesTotal() {
      const fees = this.args.basket.fees.map(fee => {
        var _fee$amount;

        return (_fee$amount = fee.amount) != null ? _fee$amount : 0;
      });
      const taxes = this.args.basket.taxes.map(tax => {
        var _tax$totalTax;

        return (_tax$totalTax = tax.totalTax) != null ? _tax$totalTax : 0;
      });
      return fees.concat(taxes).reduce((acc, cur) => acc + cur);
    } // Constructor
    // Other methods
    // Tasks
    // Actions


    onTooltipHover() {
      if (this.session.viewedCustomFeesTooltip === true) return;
      this.session.viewedCustomFeesTooltip = true;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "basketService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "features", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onTooltipHover", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onTooltipHover"), _class.prototype)), _class));
  _exports.default = CartTotals;
});