define("mobile-web/components/r-notifications/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T2D88FV2",
    "block": "[[[11,0],[16,0,[30,0,[\"style\",\"notifications\"]]],[17,1],[24,\"data-test-notifications\",\"\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"notifications\"]]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@notification\",\"@testMode\"],[[30,2],[30,3]]],null],[1,\"\\n\"]],[2]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"notification\",\"@testMode\"],false,[\"each\",\"-track-array\",\"r-notification\"]]",
    "moduleName": "mobile-web/components/r-notifications/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});