define("mobile-web/components/r-skeleton-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QKm87S4F",
    "block": "[[[41,[51,[30,0,[\"imageLoaded\"]]],[[[1,\"  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"img\"]],null,null,null],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"]],[]],null],[41,[30,2],[[[1,\"  \"],[11,\"img\"],[16,\"src\",[30,2]],[16,\"alt\",[30,3]],[16,0,[30,0,[\"imageClass\"]]],[16,\"data-test-skeletonImage\",[30,4]],[17,5],[4,[38,3],[\"load\",[30,0,[\"setImageLoaded\"]]],null],[12],[13],[1,\"\\n\"]],[]],null]],[\"placeholder\",\"@src\",\"@alt\",\"@testName\",\"&attrs\"],false,[\"unless\",\"content-placeholders\",\"if\",\"on\"]]",
    "moduleName": "mobile-web/components/r-skeleton-image/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});