define("mobile-web/components/r-vendor-card/component", ["exports", "@ember-decorators/component", "mobile-web/components/r-info-tag/component", "mobile-web/lib/time/timezone", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-vendor-card/style"], function (_exports, _component, _component2, _timezone, _, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let VendorCard = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed('model.hasOnlineOrdering'), _dec5 = Ember.computed('day', 'model'), _dec6 = Ember.computed('model.distance'), _dec7 = Ember._action, _dec(_class = (_class2 = class VendorCard extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "channel", _descriptor2, this);

      this.headingLevel = 2;
      this.style = _style.default;
    }

    get orderLabelText() {
      return this.model.hasOnlineOrdering ? this.intl.t('mwc.vendorCard.beginOrderText') : this.intl.t('mwc.vendorCard.viewMenuText');
    }

    get tags() {
      const {
        displayNationalMenu,
        hasOnlineOrdering,
        name,
        utcOffset
      } = this.model;
      const value = [...this.model.tags(this.day)];

      if (utcOffset !== _timezone.default.localOffset()) {
        value.push({
          type: _component2.Type.Cautionary,
          content: _timezone.default.offsetDifferenceMessage(name, utcOffset)
        });
      }

      if (displayNationalMenu && !hasOnlineOrdering) {
        value.push({
          type: _component2.Type.Cautionary,
          content: this.intl.t('mwc.vendorCard.noOnlineOrdering')
        });
      }

      return value;
    }

    get hasDistance() {
      return (0, _isSome.default)(this.model.distance);
    }

    init() {
      super.init();
      (false && !((0, _.isObject)(this.model)) && Ember.assert('`model` is required', (0, _.isObject)(this.model)));
      (false && !((0, _.isObject)(this.day)) && Ember.assert('`day` is required', (0, _.isObject)(this.day)));
      (false && !((0, _isSome.default)(this.buttons) || (0, _.isString)(this.destRoute)) && Ember.assert('`destRoute` or `buttons` is required', (0, _isSome.default)(this.buttons) || (0, _.isString)(this.destRoute)));
    }

    onToggleFavorite() {
      this.model.toggleFavorite();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "channel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "orderLabelText", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "orderLabelText"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "tags", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "tags"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "hasDistance", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "hasDistance"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onToggleFavorite", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "onToggleFavorite"), _class2.prototype)), _class2)) || _class);
  _exports.default = VendorCard;
});