define("mobile-web/models/basket-choice", ["exports", "ember-data", "mobile-web/decorators/saved-attributes", "mobile-web/lib/utilities/_"], function (_exports, _emberData, _savedAttributes, _) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isBasketChoice = isBasketChoice;
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const LABEL_SEPARATOR = '.';
  const LABEL_MAX_LENGTH = 120;
  let BasketChoiceModel = (_dec = _emberData.default.attr('string'), _dec2 = _emberData.default.attr('number', {
    defaultValue: 0
  }), _dec3 = _emberData.default.attr('boolean', {
    defaultValue: true
  }), _dec4 = _emberData.default.belongsTo('choice'), _dec5 = _emberData.default.belongsTo('basket-product', {
    async: false
  }), _dec6 = _emberData.default.attr('array', {
    defaultValue: () => []
  }), _dec7 = Ember.computed.gt('quantity', 0), _dec8 = Ember.computed('choice.optionGroup.parentChoice'), _dec9 = Ember.computed('choice.optionGroups'), _dec10 = Ember.computed('childrenLabel', 'isSelected', 'name', 'optionGroups.length'), _dec11 = Ember.computed('optionGroups.@each.completedOptionsLabel'), _dec12 = Ember.computed('childrenLabel'), (_class = class BasketChoiceModel extends _emberData.default.Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "quantity", _descriptor2, this);

      _initializerDefineProperty(this, "displayInBasket", _descriptor3, this);

      _initializerDefineProperty(this, "choice", _descriptor4, this);

      _initializerDefineProperty(this, "basketProduct", _descriptor5, this);

      _initializerDefineProperty(this, "customFieldValues", _descriptor6, this);

      _initializerDefineProperty(this, "savedAttributes", _descriptor7, this);

      _initializerDefineProperty(this, "isSelected", _descriptor8, this);
    }

    get parentChoice() {
      var _this$choice$get;

      return (_this$choice$get = this.choice.get('optionGroup')) == null ? void 0 : _this$choice$get.get('parentChoice');
    }

    get optionGroups() {
      return this.choice.get('optionGroups');
    }

    get choiceLabel() {
      if (this.isSelected) {
        if (!this.optionGroups || this.optionGroups.length === 0) {
          return this.name;
        }

        return `${this.name}${LABEL_SEPARATOR} ${this.childrenLabel}`;
      }

      return '';
    }

    get childrenLabel() {
      if (this.optionGroups) {
        return this.optionGroups.map(og => og.completedOptionsLabel).filter(label => label !== '').join(`${LABEL_SEPARATOR} `);
      }

      return '';
    }

    get completedChoiceLabel() {
      const completedLabel = (0, _.isEmpty)(this.childrenLabel) ? this.childrenLabel : `${this.childrenLabel}${LABEL_SEPARATOR}`;
      return completedLabel.length > LABEL_MAX_LENGTH ? `${completedLabel.substr(0, completedLabel.lastIndexOf('.', LABEL_MAX_LENGTH))}...` : completedLabel;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "quantity", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "displayInBasket", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "choice", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "basketProduct", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "customFieldValues", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "savedAttributes", [_savedAttributes.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isSelected", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "parentChoice", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "parentChoice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "optionGroups", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "optionGroups"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "choiceLabel", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "choiceLabel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "childrenLabel", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "childrenLabel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "completedChoiceLabel", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "completedChoiceLabel"), _class.prototype)), _class));
  _exports.default = BasketChoiceModel;

  function isBasketChoice(product) {
    return 'displayInBasket' in product;
  }
});