define("mobile-web/components/r-sign-in-create-account-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AvcEM9PB",
    "block": "[[[10,0],[15,0,[30,0,[\"style\",\"root\"]]],[14,\"data-test-sign-in-create-account-form\",\"\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@class\",\"@label\",\"@name\",\"@onChange\",\"@segments\",\"@value\"],[[30,0,[\"style\",\"modeSwitcher\"]],\"Sign in mode\",\"sign-in-mode\",[28,[37,1],[[30,0],\"switchModes\"],[[\"value\"],[\"value\"]]],[30,0,[\"modes\"]],[30,0,[\"mode\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"isSignInMode\"]],[[[1,\"    \"],[8,[39,3],null,[[\"@headingLevel\",\"@onSubmit\"],[[30,0,[\"headingLevel\"]],[30,0,[\"onSignIn\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,4],null,[[\"@guestUser\",\"@headingLevel\",\"@isUpgrade\",\"@lastOrderId\",\"@onSubmit\",\"@onSubmitError\",\"@optIn\"],[[30,0,[\"guestUser\"]],[30,0,[\"headingLevel\"]],[30,0,[\"isUpgrade\"]],[30,0,[\"lastOrderId\"]],[30,0,[\"onCreateAccount\"]],[30,0,[\"onCreateAccountError\"]],[30,0,[\"optIn\"]]]],null],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[],false,[\"r-segmented-control\",\"action\",\"if\",\"r-sign-in-form\",\"r-create-account-form\"]]",
    "moduleName": "mobile-web/components/r-sign-in-create-account-form/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});