define("mobile-web/components/r-product-customization/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "row": "_row_qq2jd6",
    "shortRow": "_shortRow_qq2jd6 _row_qq2jd6",
    "breadcrumbs": "_breadcrumbs_qq2jd6",
    "root": "_root_qq2jd6",
    "stickyElement": "_stickyElement_qq2jd6",
    "column": "_column_qq2jd6",
    "grid": "_grid_qq2jd6",
    "invalidOptionGroup": "_invalidOptionGroup_qq2jd6",
    "border-fade": "_border-fade_qq2jd6"
  };
  _exports.default = _default;
});