define("mobile-web/templates/frequently-asked-questions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xUl3Fzjs",
    "block": "[[[8,[39,0],null,[[\"@type\",\"@key\"],[\"html\",\"FAQ_HTML\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@model\"],[[30,1,[\"value\"]]]],null],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"x\"],false,[\"x-content\",\"r-static-page\"]]",
    "moduleName": "mobile-web/templates/frequently-asked-questions.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});