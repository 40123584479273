define("mobile-web/components/r-flyout/component", ["exports", "@glimmer/component", "mobile-web/lib/animation", "mobile-web/lib/runloop", "mobile-web/lib/scroll", "mobile-web/lib/utilities/classes", "mobile-web/lib/utilities/guids", "mobile-web/lib/utilities/keys", "mobile-web/components/r-application-frame/component", "mobile-web/components/r-flyout/style"], function (_exports, _component, _animation, _runloop, _scroll, _classes, _guids, _keys, _component2, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Flyout = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class Flyout extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "focus", _descriptor, this);

      this.ids = (0, _guids.guids)(this, 'close', 'heading');
      this.style = _style.default;

      _initializerDefineProperty(this, "escapeHandler", _descriptor2, this);

      _initializerDefineProperty(this, "hasOpened", _descriptor3, this);

      _initializerDefineProperty(this, "overlayElement", _descriptor4, this);

      _initializerDefineProperty(this, "rootElement", _descriptor5, this);
    }

    // Getters and setters
    get overlayClass() {
      return (0, _classes.classes)(this.style.overlay, this.style[this.args.location], {
        [this.style.hidden]: !(this.args.isOpen && this.hasOpened)
      });
    } // Constructor
    // Other methods
    // Tasks
    // Actions


    open() {
      if (!this.hasOpened) {
        this.hasOpened = true;
      }

      if (!this.args.allowBackgroundScroll) {
        (0, _scroll.disableDocumentScroll)();
      }

      this.focus.setLastFocus();
      (0, _runloop.safeLater)(this, () => {
        var _document$getElementB;

        const closeButton = document.getElementById(this.ids.close);
        this.focus.addTrap(this.rootElement, closeButton);
        closeButton.focus();
        const escapeHandler = (0, _keys.bindActionToKeyEvent)(_keys.Key.Escape, this.close);
        document.addEventListener('keydown', escapeHandler);
        this.escapeHandler = escapeHandler;
        this.overlayElement.addEventListener('click', this.overlayClick);
        (_document$getElementB = document.getElementById(_component2.APPLICATION_FRAME_ID)) == null ? void 0 : _document$getElementB.setAttribute('aria-hidden', 'true');
      }, _animation.ANIMATION_GRADUAL_IN_TIME);
    }

    close() {
      var _document$getElementB2;

      (_document$getElementB2 = document.getElementById(_component2.APPLICATION_FRAME_ID)) == null ? void 0 : _document$getElementB2.removeAttribute('aria-hidden');
      this.overlayElement.removeEventListener('click', this.overlayClick);
      document.removeEventListener('keydown', this.escapeHandler);
      this.escapeHandler = undefined;
      this.focus.removeTrap(this.rootElement);
      this.focus.focusLast();
      (0, _scroll.enableDocumentScroll)();
      this.args.onClose();
    }

    overlayClick(e) {
      if (e.target === this.overlayElement) {
        this.close();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "focus", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "escapeHandler", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hasOpened", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "overlayElement", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "rootElement", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "open", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "open"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "overlayClick", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "overlayClick"), _class.prototype)), _class));
  _exports.default = Flyout;
});