define("mobile-web/routes/secure/checkout/auth", ["exports", "mobile-web/lib/routing", "mobile-web/services/error", "mobile-web/services/user-feedback"], function (_exports, _routing, _error, _userFeedback) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CheckoutRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed.readOnly('session.isLoggedIn'), _dec6 = Ember.computed.readOnly('basket.basket.vendor'), (_class = class CheckoutRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "channel", _descriptor, this);

      _initializerDefineProperty(this, "basket", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "userFeedback", _descriptor4, this);

      this.titleToken = 'Checkout';

      _initializerDefineProperty(this, "isLoggedIn", _descriptor5, this);

      _initializerDefineProperty(this, "basketVendor", _descriptor6, this);
    }

    beforeModel(transition) {
      if (Ember.isNone(this.basket.basket)) {
        this.userFeedback.add({
          type: _userFeedback.Type.Warning,
          title: _error.DEFAULT_TITLE,
          message: _error.NO_BASKET_ERR,
          actions: []
        });
        return this.transitionTo('application');
      }

      if (this.isLoggedIn) {
        return this.transitionTo('secure.checkout');
      }

      return super.beforeModel(transition);
    }

    model() {
      const vendor = this.basketVendor;

      if (vendor) {
        Ember.set(this, 'previousRoute', (0, _routing.vendorToMenuRoute)(vendor));
      }

      if (!this.isLoggedIn) {
        return this.session.guestUser;
      }

      return {};
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "userFeedback", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isLoggedIn", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "basketVendor", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CheckoutRoute;
});