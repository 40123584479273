define("mobile-web/components/r-checkout/booking-and-receiving-customer/component", ["exports", "@glimmer/component", "ember-animated/transitions/fade", "mobile-web/lib/animation", "mobile-web/lib/utilities/_", "mobile-web/models/user", "mobile-web/components/r-checkout/booking-and-receiving-customer/style"], function (_exports, _component, _fade, _animation, _, _user, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CheckoutBookingAndReceivingCustomer = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class CheckoutBookingAndReceivingCustomer extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "channel", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "storage", _descriptor3, this);

      _initializerDefineProperty(this, "basket", _descriptor4, this);

      this.transition = _fade.default;
      this.animationDuration = (0, _animation.getDuration)(300);
      this.style = _style.default;

      _initializerDefineProperty(this, "showModal", _descriptor5, this);
    }

    // Getters and setters
    get bookingCustomer() {
      return this.session.user;
    }

    get missingPhoneNumber() {
      var _this$channel$setting, _this$bookingCustomer;

      return ((_this$channel$setting = this.channel.settings) == null ? void 0 : _this$channel$setting.requirePhoneNumber) && (0, _.isEmpty)((_this$bookingCustomer = this.bookingCustomer) == null ? void 0 : _this$bookingCustomer.contactNumber);
    }

    get isFullyEditable() {
      return !(this.bookingCustomer instanceof _user.default);
    } // Constructor
    // Other methods
    // Tasks
    // Actions


    onBookingCustomerChange(userData) {
      Ember.setProperties(this.bookingCustomer, _extends({}, userData, {
        firstName: userData.firstName,
        lastName: userData.lastName,
        emailAddress: userData.emailAddress,
        contactNumber: userData.contactNumber
      }));
      this.session.set('localGuestUser', userData);
    }

    onReceivingCustomerChange(userData) {
      this.storage.set('receivingCustomer', userData);
      this.closeModal();
    }

    removeReceivingCustomer() {
      this.storage.set('receivingCustomer', undefined);
    }

    openModal() {
      this.showModal = true;
    }

    closeModal() {
      this.showModal = false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showModal", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onBookingCustomerChange", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onBookingCustomerChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onReceivingCustomerChange", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onReceivingCustomerChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeReceivingCustomer", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "removeReceivingCustomer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openModal", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "openModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype)), _class));
  _exports.default = CheckoutBookingAndReceivingCustomer;
});