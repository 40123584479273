define("mobile-web/adapters/basket", ["exports", "mobile-web/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BasketAdapter extends _application.default {
    ajax(url, type, options) {
      if (type.toLowerCase() === 'transfer' && options) {
        const vendorId = options.data.vendorId;
        delete options.data.vendorId;
        return super.ajax(`${url}?vendorId=${vendorId}`, 'PUT', options);
      }

      return super.ajax(url, type, options);
    }

    urlForCreateRecord(modelName, snapshot) {
      const baseUrl = super.urlForCreateRecord(modelName, snapshot);
      const baseModel = snapshot.record;
      return `${baseUrl}?vendorId=${baseModel.vendor.get('id')}`;
    }

  }

  _exports.default = BasketAdapter;
});