define("mobile-web/components/r-confirm-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wN/6DvXx",
    "block": "[[[41,[30,0,[\"isOpen\"]],[[[1,\"  \"],[8,[39,1],[[24,\"data-test-confirmModal\",\"\"]],[[\"@testSelector\",\"@title\",\"@onClose\",\"@buttons\"],[[30,0,[\"confirmArgs\",\"testSelector\"]],[30,0,[\"confirmArgs\",\"title\"]],[30,0,[\"close\"]],\"yield\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1,[\"body\"]],[[[1,\"      \"],[8,[30,1,[\"section\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[1,[30,0,[\"confirmArgs\",\"content\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],[[[41,[30,1,[\"footer\"]],[[[1,\"      \"],[8,[39,2],[[16,0,[30,1,[\"buttonClass\"]]]],[[\"@onClick\",\"@testSelector\",\"@variant\"],[[30,0,[\"close\"]],[30,0,[\"confirmArgs\",\"cancelTestSelector\"]],\"minor\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[52,[30,0,[\"confirmArgs\",\"cancelLabel\"]],[30,0,[\"confirmArgs\",\"cancelLabel\"]],[28,[37,3],[\"mwc.confirmModal.cancelLabel\"],null]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[39,2],[[16,0,[30,1,[\"buttonClass\"]]]],[[\"@onClick\",\"@testSelector\",\"@variant\"],[[30,0,[\"confirm\"]],[30,0,[\"confirmArgs\",\"buttonTestSelector\"]],\"destructive\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,0,[\"confirmArgs\",\"buttonLabel\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[]],null]],[]]],[1,\"  \"]],[1]]]]],[1,\"\\n\"]],[]],null]],[\"modal\"],false,[\"if\",\"r-modal\",\"r-button\",\"t\"]]",
    "moduleName": "mobile-web/components/r-confirm-modal/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});