define("mobile-web/components/r-checkbox/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_m728wy",
    "text": "_text_m728wy",
    "hidden": "_hidden_m728wy",
    "input": "_input_m728wy",
    "inputAccent": "_inputAccent_m728wy"
  };
  _exports.default = _default;
});