define("mobile-web/components/r-form-edit-button/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "button": "_button_1n4kac _container_v279ns _standard_v279ns",
    "labelInputContainer": "_labelInputContainer_1n4kac _inlineLabelInputContainer_v279ns _labelInputContainerBase_v279ns",
    "label": "_label_1n4kac _inlineLabel_v279ns",
    "labelIcon": "_labelIcon_1n4kac _icon_v279ns",
    "labelText": "_labelText_1n4kac _inlineLabelText_v279ns",
    "input": "_input_1n4kac _inlineSelectInput_v279ns _selectInputBase_v279ns _inputBase_v279ns _inlineInputBase_v279ns",
    "placeholder": "_placeholder_1n4kac _inlineSelectInput_v279ns _selectInputBase_v279ns _inputBase_v279ns _inlineInputBase_v279ns",
    "icon": "_icon_1n4kac",
    "responsive": "_responsive_1n4kac _responsive_v279ns"
  };
  _exports.default = _default;
});