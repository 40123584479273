define("mobile-web/routes/forgot-password", ["exports", "ember-object-update", "mobile-web/lib/utilities/headers"], function (_exports, _emberObjectUpdate, _headers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ForgotPasswordRoute = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('channel.current'), (_class = class ForgotPasswordRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "channel", _descriptor, this);

      this.headTags = [];
      this.titleToken = 'Forgot Password';

      _initializerDefineProperty(this, "currentChannel", _descriptor2, this);
    }

    activate() {
      var _this$channel$setting;

      if (!((_this$channel$setting = this.channel.settings) != null && _this$channel$setting.isResponsive)) {
        var _this$currentChannel;

        const url = `https://${(_this$currentChannel = this.currentChannel) == null ? void 0 : _this$currentChannel.settings.fullSiteUrl}/user/forgotpassword`;
        (0, _emberObjectUpdate.default)(this, 'headTags', (0, _headers.setCanonicalUrl)(url));
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentChannel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ForgotPasswordRoute;
});