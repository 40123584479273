define("mobile-web/components/r-credit-card-modal/loading/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_zg9fmy",
    "cardTypes": "_cardTypes_zg9fmy",
    "input": "_input_zg9fmy",
    "form": "_form_zg9fmy",
    "isCreditCardModal": "_isCreditCardModal_zg9fmy"
  };
  _exports.default = _default;
});