define("mobile-web/components/routes/dispatch-summary-route/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9ehjVn4N",
    "block": "[[[10,0],[15,0,[30,0,[\"style\",\"root\"]]],[14,\"data-test-routes-dispatchSummaryRoute\",\"\"],[12],[1,\"\\n\"],[41,[30,0,[\"statuses\",\"length\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@models\"],[[30,0,[\"statuses\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,2],[[16,0,[30,1,[\"itemClass\"]]]],[[\"@testSelector\"],[\"status\"]],[[\"content\",\"buttons\"],[[[[1,\"\\n          \"],[10,0],[15,\"data-test-routes-dispatchSummaryRoute-card\",[30,1,[\"model\",\"id\"]]],[15,0,[30,0,[\"style\",\"content\"]]],[12],[1,\"\\n            \"],[10,0],[12],[1,\"\\n              \"],[10,2],[15,\"data-test-routes-dispatchSummaryRoute-deliveryService\",[30,1,[\"model\",\"id\"]]],[15,0,[30,0,[\"style\",\"deliveryService\"]]],[12],[1,\"\\n                \"],[1,[30,1,[\"model\",\"deliveryService\"]]],[1,\"\\n              \"],[13],[1,\"\\n              \"],[10,2],[15,\"data-test-routes-dispatchSummaryRoute-description\",[30,1,[\"model\",\"id\"]]],[12],[1,\"\\n                \"],[1,[30,1,[\"model\",\"statusDescription\"]]],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"]],[]],[[[1,\"\\n          \"],[8,[39,3],[[16,0,[30,2,[\"class\"]]]],[[\"@testSelector\",\"@label\",\"@onClick\"],[\"statusDetails\",[28,[37,4],[\"mwc.dispatchStatus.detailsButton\"],null],[28,[37,5],[[30,0,[\"goToDetails\"]],[30,1,[\"model\"]]],null]]],null],[1,\"\\n        \"]],[2]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"grid\",\"button\"],false,[\"if\",\"r-card/grid\",\"r-card\",\"r-card/button\",\"t\",\"fn\"]]",
    "moduleName": "mobile-web/components/routes/dispatch-summary-route/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});