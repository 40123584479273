define("mobile-web/components/r-form-edit-button/component", ["exports", "@glimmer/component", "mobile-web/lib/utilities/classes", "mobile-web/components/r-form-edit-button/style"], function (_exports, _component, _classes, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FormEditButton = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class FormEditButton extends _component.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;

      _initializerDefineProperty(this, "isEditorOpen", _descriptor, this);
    }

    // Getters and setters
    get placeholder() {
      var _this$args$placeholde;

      return (_this$args$placeholde = this.args.placeholder) != null ? _this$args$placeholde : '';
    }

    get ariaLabel() {
      return `Edit ${this.args.label}, ${this.args.value}`;
    }

    get labelClassName() {
      return (0, _classes.classes)(_style.default.label, this.args.labelClass);
    }

    get labelTextClass() {
      return (0, _classes.classes)(_style.default.labelText, this.args.responsiveLabel ? _style.default.responsive : '');
    } // Constructor
    // Other methods
    // Tasks
    // Actions


    handleClick() {
      const onClick = this.args.onClick;

      if (onClick) {
        onClick(this.openEditor);
      } else {
        this.openEditor();
      }
    }

    openEditor() {
      this.isEditorOpen = true;
    }

    closeEditor() {
      this.isEditorOpen = false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isEditorOpen", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleClick", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openEditor", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "openEditor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeEditor", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "closeEditor"), _class.prototype)), _class));
  _exports.default = FormEditButton;
});