define("mobile-web/lib/order-criteria", ["exports", "dayjs", "mobile-web/lib/utilities/is-some"], function (_exports, _dayjs, _isSome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.updateOrderCriteria = _exports.isForToday = _exports.getHandoffLabel = _exports.getTimeWantedTypeLabel = _exports.getTimeWantedTypeById = _exports.handoffMatches = _exports.isAtStore = _exports.isDelivery = _exports.isAdvance = _exports.isAtStoreMode = _exports.isDeliveryMode = _exports.getHandoffMode = _exports.getHandoffKey = _exports.DRIVETHRU_KEY = _exports.DISPATCH_KEY = _exports.DELIVERY_MODE_KEYS = void 0;
  const DELIVERY_MODE_KEYS = ['D', 'T', 'Z'];
  _exports.DELIVERY_MODE_KEYS = DELIVERY_MODE_KEYS;
  const DISPATCH_KEY = 'T';
  _exports.DISPATCH_KEY = DISPATCH_KEY;
  const DRIVETHRU_KEY = 'U';
  _exports.DRIVETHRU_KEY = DRIVETHRU_KEY;
  const HandoffMap = {
    P: 'CounterPickup',
    CounterPickup: 'P',
    C: 'CurbsidePickup',
    CurbsidePickup: 'C',
    U: 'DriveThru',
    DriveThru: 'U',
    I: 'DineIn',
    DineIn: 'I',
    D: 'Delivery',
    Delivery: 'D',
    T: 'Dispatch',
    Dispatch: 'T',
    Z: 'MultiDelivery',
    MultiDelivery: 'Z',
    N: 'Unspecified',
    Unspecified: 'N'
  };

  const getHandoffKey = h => {
    var _HandoffMap$h;

    return (_HandoffMap$h = HandoffMap[h]) != null ? _HandoffMap$h : 'U';
  };

  _exports.getHandoffKey = getHandoffKey;

  const getHandoffMode = k => {
    var _HandoffMap$k;

    return (_HandoffMap$k = HandoffMap[k]) != null ? _HandoffMap$k : 'Unspecified';
  };
  /**
   * The rest of this file is various utility methods to help you in dealing with Order Criteria objects.
   * These can be used for type narrowing or for various common checks.
   */


  _exports.getHandoffMode = getHandoffMode;

  const isDeliveryMode = h => h === 'Delivery' || h === 'Dispatch' || h === 'MultiDelivery';

  _exports.isDeliveryMode = isDeliveryMode;

  const isAtStoreMode = h => (0, _isSome.default)(h) && h !== 'Unspecified' && !isDeliveryMode(h);

  _exports.isAtStoreMode = isAtStoreMode;

  const isAdvance = c => (c == null ? void 0 : c.timeWantedType) === 'Advance';

  _exports.isAdvance = isAdvance;

  const isDelivery = c => isDeliveryMode(c == null ? void 0 : c.handoffMode);

  _exports.isDelivery = isDelivery;

  const isAtStore = c => isAtStoreMode(c == null ? void 0 : c.handoffMode); // `MultiDelivery` is a special case, in that it can count as either `Dispatch` or `Delivery`


  _exports.isAtStore = isAtStore;

  const handoffMatches = (h1, h2) => h1 === h2 || isDeliveryMode(h1) && h2 === 'MultiDelivery' || h1 === 'MultiDelivery' && isDeliveryMode(h2);

  _exports.handoffMatches = handoffMatches;
  const TimeWantedMap = {
    1: 'Advance',
    2: 'Immediate',
    4: 'ManualFire'
  };

  const getTimeWantedTypeById = id => {
    var _TimeWantedMap$id;

    return (_TimeWantedMap$id = TimeWantedMap[id]) != null ? _TimeWantedMap$id : 'Immediate';
  };

  _exports.getTimeWantedTypeById = getTimeWantedTypeById;

  const getTimeWantedTypeLabel = (t, store) => {
    var _store$peekAll$find$l, _store$peekAll$find;

    return (_store$peekAll$find$l = (_store$peekAll$find = store.peekAll('time-wanted-mode').find(tw => tw.type === t)) == null ? void 0 : _store$peekAll$find.label) != null ? _store$peekAll$find$l : t;
  };

  _exports.getTimeWantedTypeLabel = getTimeWantedTypeLabel;

  const getHandoffLabel = (h, store) => {
    var _store$peekAll$find$l2, _store$peekAll$find2;

    return (_store$peekAll$find$l2 = (_store$peekAll$find2 = store.peekAll('handoff-mode').find(handoffMode => handoffMatches(handoffMode.type, h))) == null ? void 0 : _store$peekAll$find2.label) != null ? _store$peekAll$find$l2 : h;
  };

  _exports.getHandoffLabel = getHandoffLabel;

  const isForToday = oc => oc.timeWantedType !== 'Advance' || oc.timeWanted === undefined || oc.timeWanted.isSame((0, _dayjs.default)(), 'day'); // The individual pieces of OrderCriteria are not `tracked`, so in order to get anything
  // to respond to an update we have to completely reassign the object. Using `Object.assign`
  // like this both updates the data and triggers an update.


  _exports.isForToday = isForToday;

  const updateOrderCriteria = (ctx, prop, data) => {
    ctx[prop] = Object.assign(ctx[prop], data, {
      isDefault: false
    });
  };

  _exports.updateOrderCriteria = updateOrderCriteria;
});