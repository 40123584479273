define("mobile-web/components/r-breadcrumbs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fAhadDkq",
    "block": "[[[11,0],[16,0,[30,0,[\"style\",\"root\"]]],[24,\"data-test-breadcrumbs\",\"\"],[17,1],[12],[1,\"\\n\"],[41,[28,[37,1],[\"isTablet\"],null],[[[42,[28,[37,3],[[28,[37,3],[[30,2]],null]],null],null,[[[41,[28,[37,4],[[30,3],\"route\"],null],[[[1,\"        \"],[10,1],[15,0,[30,0,[\"style\",\"crumb\"]]],[12],[1,\"\\n          \"],[10,1],[12],[1,\"\\n            \"],[8,[39,5],[[16,0,[30,0,[\"style\",\"link\"]]]],[[\"@route\",\"@models\",\"@testSelector\"],[[30,3,[\"route\"]],[30,3,[\"models\"]],[30,3,[\"label\"]]]],[[\"default\"],[[[[1,\"\\n              \"],[1,[30,3,[\"label\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,2],[15,0,[30,0,[\"style\",\"crumb\"]]],[14,\"data-test-breadcrumbs-crumb\",\"\"],[12],[1,\"\\n          \"],[1,[30,3,[\"label\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]]],[3]],null]],[]],[[[1,\"    \"],[10,1],[15,0,[30,0,[\"style\",\"crumb\"]]],[12],[1,\"\\n      \"],[10,1],[12],[1,\"\\n        \"],[8,[39,5],null,[[\"@route\",\"@models\",\"@testSelector\"],[[30,0,[\"backCrumb\",\"route\"]],[30,0,[\"backCrumb\",\"models\"]],[30,0,[\"backCrumb\",\"label\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,0,[\"backCrumb\",\"label\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"@breadcrumbs\",\"crumb\"],false,[\"if\",\"media\",\"each\",\"-track-array\",\"has-key\",\"r-link-to\"]]",
    "moduleName": "mobile-web/components/r-breadcrumbs/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});