define("mobile-web/components/r-distance-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y/1P29jO",
    "block": "[[[10,1],[15,0,[30,0,[\"style\",\"hidden\"]]],[14,\"data-test-distanceDisplay-aria\",\"\"],[12],[1,[30,0,[\"ariaLabel\"]]],[13],[1,\"\\n\"],[10,1],[14,\"aria-hidden\",\"true\"],[14,\"data-test-distanceDisplay\",\"\"],[12],[1,[30,0,[\"label\"]]],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "mobile-web/components/r-distance-display/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});