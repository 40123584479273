define("mobile-web/routes/secure/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ResetPasswordRoute extends Ember.Route {
    constructor(...args) {
      super(...args);
      this.titleToken = 'Reset Password';
    }

  }

  _exports.default = ResetPasswordRoute;
});