define("mobile-web/components/r-skeleton-image/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "hidden": "_hidden_i4srh6"
  };
  _exports.default = _default;
});