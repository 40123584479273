define("mobile-web/components/routes/secure/my-account-route/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "container": "_container_2kttmn",
    "content": "_content_2kttmn",
    "externalText": "_externalText_2kttmn",
    "twoColumns": "_twoColumns_2kttmn",
    "columns": "_columns_2kttmn",
    "column": "_column_2kttmn",
    "section": "_section_2kttmn",
    "link": "_link_2kttmn",
    "icon": "_icon_2kttmn",
    "cardContainer": "_cardContainer_2kttmn",
    "card": "_card_2kttmn",
    "description": "_description_2kttmn",
    "subText": "_subText_2kttmn"
  };
  _exports.default = _default;
});