define("mobile-web/helpers/assert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(([message, assertion]) => {
    (false && !(assertion) && Ember.assert(message, assertion));
  });

  _exports.default = _default;
});