define("mobile-web/services/challenge", ["exports", "ember-concurrency", "ember-concurrency-ts", "mobile-web/lib/hybrid-util", "mobile-web/lib/perimeterx", "mobile-web/lib/utilities/_"], function (_exports, _emberConcurrency, _emberConcurrencyTs, _hybridUtil, _perimeterx, _) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChallengeService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class ChallengeService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "bootstrap", _descriptor, this);

      _initializerDefineProperty(this, "device", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "args", _descriptor4, this);
    }

    // Getters and setters
    get isOpen() {
      return !!this.args;
    }
    /**
     * We are only expecting this to be true when the endpoints that load user data,
     * which are called as part of bootrap init, are challenged.
     * If the bootstrap data endpoint itself is challenged, we can't recover.
     */


    get initBootsrapChallenged() {
      return this.bootstrap.initBootstrapFailed && this.isOpen;
    } // Methods
    // Tasks


    *request(request, onError) {
      try {
        yield request();
      } catch (e) {
        const response = (0, _perimeterx.asPerimeterXResponse)(e);

        if (response) {
          this.args = {
            response,
            retry: request,
            onError
          };
        } else {
          if (onError) {
            onError(e);
          } else {
            throw e;
          }
        }
      }
    } // Actions


    routeError(transition, response) {
      this.args = {
        response,
        transition,
        onCancel: () => {
          this.router.transitionTo('index');
        }
      };

      if (transition.to.name.includes('secure')) {
        this.router.transitionTo('secure.challenge');
      } else {
        this.router.transitionTo('challenge');
      }
    } // `| HTMLElement` is because we call `setup` in {{did-insert}}, which passes an element.
    // `appendBlockScript` is really only for testing convenience


    setup(appendBlockScript) {
      const r = this.args.response;
      const w = window;
      w._pxAppId = r.appId;
      w._pxJsClientSrc = `${this.device.isHybrid ? (0, _hybridUtil.host)() : ''}${r.jsClientSrc}`;
      w._pxFirstPartyEnabled = r.firstPartyEnabled;
      w._pxVid = r.vid;
      w._pxUuid = r.uuid;
      w._pxHostUrl = `${this.device.isHybrid ? (0, _hybridUtil.host)() : ''}${r.hostUrl}`;

      w._pxOnCaptchaSuccess = () => {
        // @todo: this could get `false` as an argument if the captcha was not passed
        this.close(true);
      }; // If we're on hybrid, set the pxcookie so we can pick it up in hybrid-util and send it with requests


      if (this.device.isHybrid) {
        w[`${r.appId}_asyncInit`] = px => {
          px.Events.on('risk', (risk, name) => {
            localStorage.setItem('pxcookie', `${name}=${risk}`);
          });
        };
      }

      const script = document.createElement('script');

      if (this.device.isHybrid) {
        script.src = `${(0, _hybridUtil.host)()}${r.blockScript}`;
      } else {
        script.src = r.blockScript;
      }

      if ((0, _.isFunction)(appendBlockScript)) {
        appendBlockScript(script);
      } else {
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    }

    open(response) {
      this.args = {
        response,
        retry: _.noop
      };
    }

    async close(retry) {
      if (!this.args) {
        return;
      }

      if (!retry) {
        var _this$args$onCancel, _this$args;

        (_this$args$onCancel = (_this$args = this.args).onCancel) == null ? void 0 : _this$args$onCancel.call(_this$args);
        this.args = undefined;
        return;
      }

      if ('retry' in this.args) {
        (0, _emberConcurrencyTs.taskFor)(this.request).perform(this.args.retry, this.args.onError);
      } else {
        if (this.initBootsrapChallenged) {
          await this.bootstrap.initBootstrap();
        }

        this.args.transition.retry();
      }

      this.args = undefined;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "bootstrap", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "device", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "args", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "request", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "request"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "routeError", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "routeError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setup", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "open", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "open"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype)), _class));
  _exports.default = ChallengeService;
});