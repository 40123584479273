define("mobile-web/components/r-checkout/payment/membership/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uU/W0bj9",
    "block": "[[[8,[39,0],[[16,0,[30,0,[\"style\",\"cardContainer\"]]]],[[\"@testSelector\"],[\"membership\"]],[[\"content\",\"buttons\"],[[[[1,\"\\n    \"],[10,0],[14,\"data-test-checkout-payment-membership\",\"\"],[15,0,[30,0,[\"style\",\"card\"]]],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@class\",\"@icon\"],[[30,0,[\"style\",\"icon\"]],[30,0,[\"icon\"]]]],null],[1,\"\\n      \"],[10,0],[12],[1,\"\\n        \"],[10,2],[14,\"data-test-checkout-payment-membership-description\",\"\"],[15,0,[30,0,[\"style\",\"description\"]]],[12],[1,\"\\n          \"],[1,[30,1,[\"description\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"],[41,[30,1,[\"canCheckBalance\"]],[[[1,\"          \"],[10,2],[14,\"data-test-checkout-payment-membership-balance\",\"\"],[15,0,[30,0,[\"style\",\"subText\"]]],[12],[1,\"\\n            \"],[1,[28,[35,3],[\"mwc.checkout.giftCardBalance\"],[[\"amount\"],[[28,[37,4],[[30,1,[\"balance\"]]],null]]]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,5],[[16,0,[30,2,[\"class\"]]]],[[\"@testSelector\",\"@label\",\"@onClick\"],[\"editMembership\",[28,[37,3],[\"mwc.checkout.editMembershipButton\"],null],[30,3]]],null],[1,\"\\n    \"],[8,[39,5],[[16,0,[30,2,[\"class\"]]]],[[\"@testSelector\",\"@label\",\"@onClick\"],[\"use\",[28,[37,3],[[30,0,[\"useLabel\"]]],null],[30,4]]],null],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[\"@membership\",\"button\",\"@onEdit\",\"@onUse\"],false,[\"r-card\",\"r-svg\",\"if\",\"t\",\"format-currency\",\"r-card/button\"]]",
    "moduleName": "mobile-web/components/r-checkout/payment/membership/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});