define("mobile-web/services/focus-manager", ["exports", "mobile-web/lib/focus"], function (_exports, _focus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CategoryName = void 0;
  let CategoryName;
  _exports.CategoryName = CategoryName;

  (function (CategoryName) {
    CategoryName["OptionGroupError"] = "option-group-error";
  })(CategoryName || (_exports.CategoryName = CategoryName = {}));

  /**
   * The FocusManagerService manages focus across collections of components.
   *
   * Components can be registered with the service in categories, and the service
   * is able to focus the first or last component within the category.
   *
   * @example
   * // form field component:
   * this.focusManager.register('invalid-field', {
   *   target: this.input,
   *   allowFocus: () => this.invalid,
   * });
   *
   * // form submit handler:
   * if (this.hasError) {
   *   this.focusManager.focusFirst('invalid-field');
   * }
   */
  class FocusManagerService extends Ember.Service {
    constructor(...args) {
      super(...args);
      this.categories = new Map();
    }

    /**
     * Register a focus target for a specific category
     *
     * @param categoryName The name of the category in which the target should be
     * registered.
     * @param targetOrFocusData The element to focus or an object with additional
     * focus options.
     */
    register(categoryName, targetOrFocusData) {
      const category = this.getCategory(categoryName);
      category.add(targetOrFocusData);
    }
    /**
     * Deregister a focus target from a specific category
     *
     * @param categoryName The name of the category in which the target should be
     * deregistered.
     * @param targetOrFocusData The element or object that was previously
     * registered.
     */


    deregister(categoryName, targetOrFocusData) {
      const category = this.getCategory(categoryName);
      category.delete(targetOrFocusData);
    }
    /**
     * Focus the first registered target that is able to receive focus.
     *
     * @param categoryName The name of the category to focus.
     */


    focusFirst(categoryName) {
      const category = this.getCategory(categoryName);

      for (const targetOrFocusData of category) {
        if (this.tryFocus(targetOrFocusData)) {
          break;
        }
      }
    }
    /**
     * Focus the last registered target that is able to receive focus.
     *
     * @param categoryName The name of the category to focus.
     */


    focusLast(categoryName) {
      const category = [...this.getCategory(categoryName)].reverse();

      for (const targetOrFocusData of category) {
        if (this.tryFocus(targetOrFocusData)) {
          break;
        }
      }
    }

    getCategory(categoryName) {
      var _this$categories$get;

      const category = (_this$categories$get = this.categories.get(categoryName)) != null ? _this$categories$get : new Set();

      if (!this.categories.has(categoryName)) {
        this.categories.set(categoryName, category);
      }

      return category;
    }

    tryFocus(targetOrFocusData) {
      var _data$allowFocus;

      if (targetOrFocusData instanceof HTMLElement) {
        (0, _focus.focusWithScroll)(targetOrFocusData);
        return true;
      }

      const data = targetOrFocusData;

      if (!((_data$allowFocus = data.allowFocus == null ? void 0 : data.allowFocus()) != null ? _data$allowFocus : true)) {
        return false;
      }

      if (data.target) {
        (0, _focus.focusWithScroll)(data.target);
      }

      data.onFocus == null ? void 0 : data.onFocus();
      return true;
    }

  }

  _exports.default = FocusManagerService;
});