define("mobile-web/services/vendor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let VendorService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed('_vendor'), _dec6 = Ember.computed.none('vendor'), _dec7 = Ember.computed.alias('vendor.currency'), _dec8 = Ember.computed.alias('vendor.slug'), (_class = class VendorService extends Ember.Service {
    get vendor() {
      return this._vendor;
    }

    set vendor(value) {
      Ember.set(this, '_vendor', value);

      if (value) {
        this.storage.set('lastVendorSlug', value.slug);
      }
    }

    async ensureVendorLoaded(vendorSlug) {
      const slug = vendorSlug || this.vendorSlug;
      const currentVendor = this.vendor;

      const setupVendor = vendor => {
        if (this.storage.lastVendorSlug !== slug) {
          this.storage.ignorePrecheckError = false;
        }

        this.basket.onVendorUpdate(vendor.id);
        Ember.set(this, 'vendor', vendor);
        return vendor;
      };

      const store = this.store;
      let vendor;

      if (!Ember.isNone(currentVendor) && currentVendor.slug === slug) {
        vendor = await store.findRecord('vendor', currentVendor.id);
      }

      if (!vendor) {
        vendor = store.peekAll('vendor').find(v => v.slug === slug);
      }

      if (!vendor || vendor.wasSideloaded) {
        vendor = await store.queryRecord('vendor', {
          slug
        });
      }

      return setupVendor(vendor);
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "storage", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "vendorIsNotLoaded", _descriptor5, this);

      _initializerDefineProperty(this, "currency", _descriptor6, this);

      _initializerDefineProperty(this, "vendorSlug", _descriptor7, this);

      this.router.on('routeDidChange', () => {
        const targetName = this.router.currentRouteName;
        const isMenuRoute = targetName.indexOf('menu') > -1;
        const isRewardsSearchRoute = targetName === 'rewards-search';
        const isThankYouRoute = targetName === 'thank-you';

        if (!isMenuRoute && !isRewardsSearchRoute && !isThankYouRoute && Ember.isNone(this.basket.basket)) {
          if (this.vendor) {
            Ember.set(this, 'vendor', undefined);
          }
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "vendor", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "vendor"), _class.prototype), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "vendorIsNotLoaded", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "currency", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "vendorSlug", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = VendorService;
});