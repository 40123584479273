define("mobile-web/serializers/favorite-choice", ["exports", "mobile-web/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FavoriteChoiceSerializer extends _application.default {
    constructor(...args) {
      super(...args);
      this.primaryKey = 'optionId';
    }

  }

  _exports.default = FavoriteChoiceSerializer;
});