define("mobile-web/components/r-heading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q6vcD6io",
    "block": "[[[41,[28,[37,1],[[30,1],1],null],[[[1,\"  \"],[11,\"h1\"],[17,2],[12],[18,3,null],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1],2],null],[[[1,\"  \"],[11,\"h2\"],[17,2],[12],[18,3,null],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1],3],null],[[[1,\"  \"],[11,\"h3\"],[17,2],[12],[18,3,null],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1],4],null],[[[1,\"  \"],[11,\"h4\"],[17,2],[12],[18,3,null],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1],5],null],[[[1,\"  \"],[11,\"h5\"],[17,2],[12],[18,3,null],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,\"h6\"],[17,2],[12],[18,3,null],[13],[1,\"\\n\"]],[]]]],[]]]],[]]]],[]]]],[]]]],[\"@level\",\"&attrs\",\"&default\"],false,[\"if\",\"eq\",\"yield\"]]",
    "moduleName": "mobile-web/components/r-heading/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});