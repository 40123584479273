define("mobile-web/components/r-product-customization/display-group/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_b7rvgt",
    "grid": "_grid_b7rvgt _grid_8v5ypg _grid_qq2jd6",
    "header": "_header_b7rvgt"
  };
  _exports.default = _default;
});