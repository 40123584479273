define("mobile-web/components/r-svg/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "icon": "_icon_1odd4l",
    "container": "_container_1odd4l"
  };
  _exports.default = _default;
});