define("mobile-web/serializers/time-wanted-mode", ["exports", "mobile-web/lib/order-criteria", "mobile-web/serializers/application"], function (_exports, _orderCriteria, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TimeWantedModeSerializer extends _application.default {
    normalize(modelClass, resourceHash, prop) {
      resourceHash.type = (0, _orderCriteria.getTimeWantedTypeById)(resourceHash.value);
      return super.normalize(modelClass, resourceHash, prop);
    }

  }

  _exports.default = TimeWantedModeSerializer;
});