define("mobile-web/components/r-href/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2rosjL4g",
    "block": "[[[11,3],[16,6,[52,[51,[33,1]],[33,2]]],[16,\"target\",[36,3]],[16,0,[28,[37,4],[[30,0,[\"style\",\"a\"]],[30,1]],null]],[16,\"onclick\",[36,5]],[16,\"rel\",[36,6]],[16,\"data-test-href\",[36,7]],[17,2],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\",\"&default\"],false,[\"unless\",\"disabled\",\"href\",\"target\",\"classes\",\"onClick\",\"rel\",\"testSelector\",\"yield\"]]",
    "moduleName": "mobile-web/components/r-href/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});