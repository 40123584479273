define("mobile-web/templates/components/hero-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kr+HMbat",
    "block": "[[[10,\"img\"],[15,\"src\",[30,0,[\"heroImage\"]]],[15,\"alt\",[30,0,[\"heroImageAlt\"]]],[14,\"data-test-hero-image\",\"\"],[12],[13]],[],false,[]]",
    "moduleName": "mobile-web/templates/components/hero-image.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});