define("mobile-web/components/r-post-checkout/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_rjtn4c",
    "handoffSummaryNew": "_handoffSummaryNew_rjtn4c",
    "handoffMessageNew": "_handoffMessageNew_rjtn4c",
    "minorText": "_minorText_rjtn4c",
    "sectionContainer": "_sectionContainer_rjtn4c",
    "handoffDetails": "_handoffDetails_rjtn4c",
    "section": "_section_rjtn4c",
    "orderSummarySection": "_orderSummarySection_rjtn4c _section_rjtn4c",
    "simpleSection": "_simpleSection_rjtn4c _section_rjtn4c",
    "buttonItem": "_buttonItem_rjtn4c",
    "reorderButton": "_reorderButton_rjtn4c",
    "buttonContainer": "_buttonContainer_rjtn4c",
    "thanksMessageDetails": "_thanksMessageDetails_rjtn4c"
  };
  _exports.default = _default;
});