define("mobile-web/helpers/trusted-unsafe-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.trustedUnsafeHtml = void 0;

  const trustedUnsafeHtml = ([string]) => Ember.String.htmlSafe(string);

  _exports.trustedUnsafeHtml = trustedUnsafeHtml;

  var _default = Ember.Helper.helper(trustedUnsafeHtml);

  _exports.default = _default;
});