define("mobile-web/lib/animation", ["exports", "mobile-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.animateTo = animateTo;
  _exports.getDuration = getDuration;
  _exports.ANIMATION_GRADUAL_IN_TIME = void 0;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  const ANIMATION_GRADUAL_IN_TIME = getDuration(350); // see https://www.youtube.com/watch?v=9-6CKCz58A8&t=14m26s for detailed explanation

  _exports.ANIMATION_GRADUAL_IN_TIME = ANIMATION_GRADUAL_IN_TIME;

  function animateTo(element, keyframes, options) {
    return new Promise((resolve, reject) => {
      try {
        const animation = element.animate(keyframes, _extends({}, options, {
          fill: 'both'
        }));
        animation.addEventListener('finish', () => {
          try {
            // @ts-ignore
            animation.commitStyles();
            animation.cancel();
            resolve(undefined);
          } catch (e) {
            reject(e);
          }
        });
        animation.addEventListener('cancel', reject);
      } catch (e) {
        reject(e);
      }
    });
  }

  function getDuration(desiredDuration) {
    return _environment.default.environment === 'test' ? 0 : desiredDuration;
  }
});