define("mobile-web/components/r-guest-checkout-form/component", ["exports", "@glimmer/component", "mobile-web/lib/contact-number-rules", "mobile-web/lib/utilities/_", "mobile-web/lib/validation", "mobile-web/components/r-guest-checkout-form/style"], function (_exports, _component, _contactNumberRules, _, _validation, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _dec6, _dec7, _dec8, _class3, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Model = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, (_class = class Model {
    constructor() {
      _initializerDefineProperty(this, "firstName", _descriptor, this);

      _initializerDefineProperty(this, "lastName", _descriptor2, this);

      _initializerDefineProperty(this, "emailAddress", _descriptor3, this);

      _initializerDefineProperty(this, "contactNumber", _descriptor4, this);

      _initializerDefineProperty(this, "optIn", _descriptor5, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "firstName", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "lastName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "emailAddress", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "contactNumber", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "optIn", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  })), _class));
  let GuestCheckoutForm = (_dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, (_class3 = class GuestCheckoutForm extends _component.default {
    // Service injections
    // Passthroughs
    // Tracked properties
    // Getters and setters
    get requirePhoneNumber() {
      var _this$args$requirePho;

      return (_this$args$requirePho = this.args.requirePhoneNumber) != null ? _this$args$requirePho : true;
    }

    get isSubmitDisabled() {
      const model = this.model;
      return !model.firstName || !model.lastName || !model.emailAddress || !model.contactNumber && this.requirePhoneNumber;
    }

    get validationConfig() {
      const commonBindings = [{
        targetProp: 'firstName',
        ruleName: 'notBlank',
        message: 'First name must be set'
      }, {
        targetProp: 'lastName',
        ruleName: 'notBlank',
        message: 'Last name must be set'
      }, {
        targetProp: 'emailAddress',
        ruleName: 'email',
        message: 'Email address must be set and valid'
      }];

      if (this.requirePhoneNumber) {
        commonBindings.push({
          targetProp: 'contactNumber',
          ruleName: 'phone',
          message: 'Phone number must be set and valid'
        });
      }

      return {
        bindings: commonBindings
      };
    } // Constructor


    constructor(owner, args) {
      var _this$args$guestUser, _this$args$optIn;

      super(owner, args);
      this.phoneMask = _contactNumberRules.phoneMask;
      this.style = _style.default;

      _initializerDefineProperty(this, "model", _descriptor6, this);

      _initializerDefineProperty(this, "validationResult", _descriptor7, this);

      const model = new Model();
      Object.assign(model, (_this$args$guestUser = this.args.guestUser) != null ? _this$args$guestUser : {});
      model.optIn = (_this$args$optIn = this.args.optIn) != null ? _this$args$optIn : false;
      this.model = model;
    } // Other methods
    // Tasks
    // Actions


    confirm(e) {
      e.preventDefault();
      this.validationResult = _validation.default.validate(this.model, this.validationConfig);

      if (this.validationResult.isOk()) {
        this.args.onSubmit((0, _.pick)(this.model, 'firstName', 'lastName', 'emailAddress', 'contactNumber', 'optIn'));
      }
    }

  }, (_descriptor6 = _applyDecoratedDescriptor(_class3.prototype, "model", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class3.prototype, "validationResult", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "confirm", [_dec8], Object.getOwnPropertyDescriptor(_class3.prototype, "confirm"), _class3.prototype)), _class3));
  _exports.default = GuestCheckoutForm;
});