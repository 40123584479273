define("mobile-web/components/r-checkout/order-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UXA3Fmxc",
    "block": "[[[8,[39,0],null,[[\"@name\",\"@header\",\"@onEdit\"],[\"Order Type\",[28,[37,1],[\"mwc.checkout.orderTypeHeader\"],null],[30,0,[\"openModal\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"style\",\"container\"]]],[12],[1,\"\\n    \"],[10,2],[15,0,[30,0,[\"style\",\"key\"]]],[14,\"data-test-checkout-orderType-label\",\"Type\"],[12],[1,[28,[35,1],[\"mwc.checkout.orderTypeLabel\"],null]],[13],[1,\"\\n    \"],[10,2],[15,0,[30,0,[\"style\",\"value\"]]],[14,\"data-test-checkout-orderType-value\",\"Type\"],[12],[1,[28,[35,2],null,[[\"type\",\"criteria\"],[\"handoff\",[30,0,[\"orderCriteria\"]]]]]],[13],[1,\"\\n    \"],[10,2],[15,0,[30,0,[\"style\",\"key\"]]],[14,\"data-test-checkout-orderType-label\",\"When\"],[12],[1,[28,[35,1],[\"mwc.checkout.orderTimeLabel\"],null]],[13],[1,\"\\n    \"],[10,2],[15,0,[30,0,[\"style\",\"value\"]]],[14,\"data-test-checkout-orderType-value\",\"When\"],[12],[1,[30,0,[\"orderTime\"]]],[13],[1,\"\\n\"],[41,[30,0,[\"isDelivery\"]],[[[1,\"      \"],[10,2],[15,0,[30,0,[\"style\",\"key\"]]],[14,\"data-test-checkout-orderType-label\",\"To\"],[12],[1,[28,[35,1],[\"mwc.checkout.orderDeliveryAddressLabel\"],null]],[13],[1,\"\\n      \"],[10,2],[15,0,[30,0,[\"style\",\"value\"]]],[14,\"data-test-checkout-orderType-value\",\"To\"],[12],[1,\"\\n        \"],[8,[39,4],null,[[\"@model\"],[[30,0,[\"orderCriteria\",\"deliveryAddress\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[18,1,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@display\"],[[30,0,[\"orderCriteriaService\",\"updateTask\",\"isRunning\"]]]],null],[1,\"\\n\"]],[\"&default\"],false,[\"r-editable-section\",\"t\",\"criteria-label\",\"if\",\"r-address-display\",\"yield\",\"loading-indicator\"]]",
    "moduleName": "mobile-web/components/r-checkout/order-type/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});