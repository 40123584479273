define("mobile-web/components/r-product-customization/option-group/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "grid": "_grid_8v5ypg _grid_qq2jd6",
    "isInModal": "_isInModal_8v5ypg",
    "fullWidth": "_fullWidth_8v5ypg",
    "toggle": "_toggle_8v5ypg",
    "root": "_root_8v5ypg",
    "isNested": "_isNested_8v5ypg",
    "highlight": "_highlight_8v5ypg",
    "border-fade": "_border-fade_8v5ypg",
    "toggleButton": "_toggleButton_8v5ypg",
    "help": "_help_8v5ypg",
    "helpText": "_helpText_8v5ypg",
    "content": "_content_8v5ypg _row_qq2jd6",
    "collapseContent": "_collapseContent_8v5ypg",
    "formationGroups": "_formationGroups_8v5ypg _grid_8v5ypg _grid_qq2jd6",
    "optionGroup": "_optionGroup_8v5ypg"
  };
  _exports.default = _default;
});