define("mobile-web/components/r-feedback-banner/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_tqfw75",
    "pop-in": "_pop-in_tqfw75",
    "neutral": "_neutral_tqfw75",
    "negative": "_negative_tqfw75",
    "positive": "_positive_tqfw75",
    "warning": "_warning_tqfw75",
    "icon": "_icon_tqfw75",
    "ieIcon": "_ieIcon_tqfw75",
    "closeAnimation": "_closeAnimation_tqfw75",
    "pop-out": "_pop-out_tqfw75",
    "message": "_message_tqfw75",
    "actions": "_actions_tqfw75",
    "action": "_action_tqfw75",
    "upperMessage": "_upperMessage_tqfw75",
    "minor": "_minor_tqfw75"
  };
  _exports.default = _default;
});