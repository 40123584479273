define("mobile-web/components/r-breadcrumbs/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "mobile-web/components/r-breadcrumbs/style"], function (_exports, _glimmerComponent, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function isLinkableCrumb(breadcrumb) {
    return 'route' in breadcrumb;
  }

  class Breadcrumbs extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get backCrumb() {
      return this.args.breadcrumbs.filter(isLinkableCrumb).pop();
    } // Constructor
    // Other methods
    // Tasks
    // Actions


  }

  _exports.default = Breadcrumbs;
});