define("mobile-web/components/routes/dispatch-status-route/component", ["exports", "@ember-decorators/component", "mobile-web/components/r-map-window/component", "mobile-web/config/environment", "mobile-web/lib/dayjs", "mobile-web/lib/order", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some", "mobile-web/models/dispatch-status", "mobile-web/services/analytics", "mobile-web/components/routes/dispatch-status-route/style"], function (_exports, _component, _component2, _environment, _dayjs, _order, _, _isSome, _dispatchStatus, _analytics, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const CHECK_MAPS_ERROR_INTERVAL = 1000;
  const REFRESH_INTERVAL = _environment.default.environment === 'development' ? 5000 : 60000;

  const getEstimatedArrival = estimatedDropoffTime => {
    const now = (0, _dayjs.default)();
    const dropoffEstimate = (0, _dayjs.default)(estimatedDropoffTime);
    const time = dropoffEstimate.diff(now, 'm');

    if (time < 1) {
      return 1;
    }

    return Math.round(time);
  };

  const NO_MAP_STATUSES = [_dispatchStatus.State.DeliveryError, _dispatchStatus.State.OrderError, _dispatchStatus.State.PickupError, _dispatchStatus.State.DeliverySucceeded, _dispatchStatus.State.ReturnInProgress, _dispatchStatus.State.ReturnCompleted];
  let DispatchStatusRoute = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember.computed('mwcIntl.intl', 'order.timeReadyLocal', 'status.{estimatedDropoffTimeLocal,status}'), _dec11 = Ember.computed('status.{driverName,status}'), _dec12 = Ember.computed('status.{deliveryService,status}'), _dec13 = Ember.computed('order.{deliveryAddress,vendor}', 'status.{driverName,latitude,longitude,status}'), _dec14 = Ember.computed('showMap', 'status.{latitude,longitude,status}'), _dec(_class = (_class2 = class DispatchStatusRoute extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "channel", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "mwcIntl", _descriptor4, this);

      _initializerDefineProperty(this, "intl", _descriptor5, this);

      _initializerDefineProperty(this, "error", _descriptor6, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "showMap", _descriptor7, this);

      _initializerDefineProperty(this, "showTracking", _descriptor8, this);

      this.consecutiveMapUpdates = 0;
    }

    // Computed properties
    get statusMessageParts() {
      const state = this.status.status;

      if (state === _dispatchStatus.State.DeliverySucceeded) {
        return {
          prefix: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.completedLabel'),
          dateTime: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.completedTime')
        };
      } else if (state === _dispatchStatus.State.ReturnInProgress) {
        return {
          prefix: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.returningLabel'),
          dateTime: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.returningTime')
        };
      } else if (state === _dispatchStatus.State.ReturnCompleted) {
        return {
          prefix: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.returnedLabel'),
          dateTime: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.returnedTime')
        };
      } else if (state === _dispatchStatus.State.PickupSucceeded) {
        const time = getEstimatedArrival(this.status.estimatedDropoffTimeLocal);

        if (time >= 60) {
          const endTime = (0, _dayjs.default)(this.status.estimatedDropoffTimeLocal).format('h:mm A');
          const startTime = (0, _dayjs.default)(this.status.estimatedDropoffTimeLocal).subtract(10, 'm').format('h:mm A');
          return {
            prefix: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.estimatedDelivery'),
            dateTime: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.estimatedDropoffRange', {
              endTime,
              startTime
            })
          };
        }

        return {
          prefix: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.estimatedArrival'),
          dateTime: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.estimatedDropoffTime', {
            time
          })
        };
      }

      const timeReady = (0, _dayjs.default)(this.order.timeReadyLocal);
      const relativeDate = this.mwcIntl.relativeDate(timeReady, {
        titleCase: false
      });
      const dateTime = this.mwcIntl.intl.t('mwc.postCheckout.time', {
        date: relativeDate,
        time: timeReady
      });
      return (0, _order.handoffMessageParts)(this.order, dateTime);
    }

    get displayDriverInfo() {
      return (0, _isSome.default)(this.status.driverName) && this.status.status === _dispatchStatus.State.PickupSucceeded;
    }

    get isPickupInProgress() {
      return this.status.status === _dispatchStatus.State.PickupInProgress && (0, _isSome.default)(this.status.deliveryService);
    }

    get mapMarkers() {
      const vendor = this.order.vendor;
      const deliveryAddress = this.order.deliveryAddress;

      if (!(0, _isSome.default)(deliveryAddress == null ? void 0 : deliveryAddress.latitude)) {
        return [];
      }

      const addressMarker = {
        name: 'Delivery Address',
        lat: deliveryAddress.latitude,
        lng: deliveryAddress.longitude,
        icon: (0, _component2.getMapSymbol)(_component2.MapIcons.Person, '#2C7D3C'),
        halo: false
      };
      const markers = [addressMarker];

      if (this.status.status === _dispatchStatus.State.PickupSucceeded && (0, _isSome.default)(this.status.latitude) && (0, _isSome.default)(this.status.longitude)) {
        markers.push({
          name: `${this.status.driverName}'s location`,
          lat: this.status.latitude,
          lng: this.status.longitude,
          icon: (0, _component2.getMapSymbol)(_component2.MapIcons.Car, '#006AD1'),
          halo: true
        });
        addressMarker.halo = true;
      } else {
        markers.push({
          name: vendor.name,
          lat: vendor.latitude,
          lng: vendor.longitude,
          icon: (0, _component2.getMapSymbol)(_component2.MapIcons.Store, '#006AD1')
        });
      }

      return markers;
    }

    get updatesIconData() {
      let className, showIcon, iconLabel, ariaLabel;

      switch (this.status.status) {
        case _dispatchStatus.State.OrderReceived:
          className = _style.default.updatesActive;
          showIcon = true;
          iconLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesIconLabel');
          ariaLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesAriaLabel');
          break;

        case _dispatchStatus.State.PickupInProgress:
          className = _style.default.updatesActive;
          showIcon = true;
          iconLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesIconLabel');
          ariaLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesAriaLabel');
          break;

        case _dispatchStatus.State.PickupSucceeded:
          if ((0, _isSome.default)(this.status.latitude) && (0, _isSome.default)(this.status.longitude) || !this.showMap) {
            className = _style.default.updatesActive;
            iconLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesIconLabel');
          } else {
            className = _style.default.updatesActiveNoLocation;
            iconLabel = this.intl.t('mwc.dispatchTracker.noDriverCoordinates');
          }

          showIcon = true;
          ariaLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesAriaLabel');
          break;

        case _dispatchStatus.State.DeliverySucceeded:
          className = _style.default.updatesInactive;
          showIcon = true;
          iconLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesIconLabel');
          ariaLabel = this.intl.t('mwc.dispatchTracker.inactiveUpdatesAriaLabel');
          break;

        case _dispatchStatus.State.ReturnInProgress:
          className = _style.default.updatesActive;
          showIcon = true;
          iconLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesIconLabel');
          ariaLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesAriaLabel');
          break;

        default:
          // All various error states fall into here
          className = _style.default.updatesInactive;
          showIcon = false;
          iconLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesIconLabel');
          ariaLabel = this.intl.t('mwc.dispatchTracker.inactiveUpdatesAriaLabel');
          break;
      }

      return {
        className,
        showIcon,
        iconLabel,
        ariaLabel
      };
    } // Init


    init() {
      super.init();
      (false && !((0, _isSome.default)(this.status)) && Ember.assert('`status` is required', (0, _isSome.default)(this.status)));
      (false && !((0, _isSome.default)(this.order)) && Ember.assert('`order` is required', (0, _isSome.default)(this.order)));
      this.updateShowTracking(); // If the initial status is a terminal status, the map is not needed

      this.showMap = !(0, _.isEmpty)(this.googleMapsApiKey) && !NO_MAP_STATUSES.includes(this.status.status);

      if (_environment.default.environment !== 'test') {
        Ember.run.later(this, this.refresh, REFRESH_INTERVAL);
        Ember.run.later(this, this.checkMapsError, CHECK_MAPS_ERROR_INTERVAL);
      }
    }

    checkMapsError() {
      var _document;

      if (this.isDestroyed || this.isDestroying) {
        return;
      } // We don't have an event to hook into if there is an API key error, so scrape the DOM for the element :(


      if ((_document = document) != null && _document.querySelector('.gm-err-title')) {
        var _this$channel$current;

        this.showMap = false;
        this.error.sendExternalError(new Error('Google Maps referrer not allowed: ' + ((_this$channel$current = this.channel.current) == null ? void 0 : _this$channel$current.name) + '. Add ' + document.domain + ' to Google Maps API Console '));
      } else {
        Ember.run.later(this, this.checkMapsError, CHECK_MAPS_ERROR_INTERVAL);
      }
    }

    get googleMapsApiKey() {
      var _this$channel$setting;

      return (_this$channel$setting = this.channel.settings) != null && _this$channel$setting.disableDispatchDriverMap ? '' : _environment.default.DEFAULT_GOOGLE_MAPS_API_KEY;
    } // Other methods


    updateShowTracking() {
      // This is not using a `computed` because using `order.dispatchStatuses.[]`
      // does not re-compute as a dependent key. Referencing `this.order.dispatchStatuses`
      // inside a computed to set this value will trigger a fetch of those statuses which
      // is not needed to update the UI state. Also that fetch requires `adapterOptions`.
      this.showTracking = this.order.hasMany('dispatchStatuses').ids().length > 1;
    }

    refresh() {
      const isNotDestroyed = !this.isDestroyed && !this.isDestroying;
      const dataOptions = {
        reload: true,
        adapterOptions: {
          orderId: this.order.id,
          hash: this.hash
        }
      };

      const updateOrder = () => this.store.findRecord('order', this.order.id, dataOptions).then(() => this.updateShowTracking());

      if (isNotDestroyed && this.status.courierStatus === _dispatchStatus.CourierStatus.Active) {
        // this.status is a live Ember Data object, so when we do this
        // store.findRecord with reload: true, any updates will automagically
        // be reflected in this.status. We can't do this.status.reload() because
        // of the adapterOptions we need to pass.
        this.store.findRecord('dispatch-status', this.status.id, dataOptions).then(() => {
          if (!(0, _.isEmpty)(this.googleMapsApiKey)) {
            this.showMap = !NO_MAP_STATUSES.includes(this.status.status);
          }

          if (this.showMap) {
            this.analytics.trackEvent(_analytics.AnalyticsEvents.LoadDispatchMapData, () => ({
              [_analytics.AnalyticsProperties.ConsecutiveLoads]: ++this.consecutiveMapUpdates,
              [_analytics.AnalyticsProperties.OrderId]: this.order.id,
              [_analytics.AnalyticsProperties.OrderID]: this.order.id
            }));
          }
        }); // Refresh the order as well to show tracking CTA for additional statuses

        if (!this.showTracking) {
          updateOrder();
        }

        if (_environment.default.environment !== 'test') {
          Ember.run.later(this, this.refresh, REFRESH_INTERVAL);
        }
      } else if (isNotDestroyed && !this.showTracking && this.status.courierStatus !== _dispatchStatus.CourierStatus.Completed) {
        // The current status is no longer active
        // Continue to poll for additional statuses.
        updateOrder();

        if (_environment.default.environment !== 'test') {
          Ember.run.later(this, this.refresh, REFRESH_INTERVAL);
        }
      }
    } // Actions


  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "analytics", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "channel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "mwcIntl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "error", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "showMap", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "showTracking", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "statusMessageParts", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "statusMessageParts"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "displayDriverInfo", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "displayDriverInfo"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isPickupInProgress", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "isPickupInProgress"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "mapMarkers", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "mapMarkers"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updatesIconData", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "updatesIconData"), _class2.prototype)), _class2)) || _class);
  _exports.default = DispatchStatusRoute;
});