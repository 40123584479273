define("mobile-web/lib/utilities/headers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setCanonicalUrl = void 0;

  const setCanonicalUrl = href => headTags => headTags.concat({
    type: 'link',
    tagId: 'canonical-link',
    attrs: {
      href,
      rel: 'canonical'
    }
  });

  _exports.setCanonicalUrl = setCanonicalUrl;
});