define("mobile-web/components/r-modal/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "overlay": "_overlay_1u092y",
    "modal": "_modal_1u092y",
    "fullHeight": "_fullHeight_1u092y",
    "header": "_header_1u092y",
    "title": "_title_1u092y",
    "closeButton": "_closeButton_1u092y",
    "closeButtonIcon": "_closeButtonIcon_1u092y",
    "body": "_body_1u092y",
    "footer": "_footer_1u092y",
    "button": "_button_1u092y",
    "errorMessage": "_errorMessage_1u092y",
    "errorIcon": "_errorIcon_1u092y"
  };
  _exports.default = _default;
});