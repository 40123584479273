define("mobile-web/serializers/billing-membership", ["exports", "mobile-web/lib/payment/card", "mobile-web/lib/utilities/is-some", "mobile-web/serializers/base-json-serializer"], function (_exports, _card, _isSome, _baseJsonSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BillingMembershipSerializer extends _baseJsonSerializer.default {
    constructor(...args) {
      super(...args);
      this.modelName = 'billing-membership';
    }

    normalize(model, resourceHash) {
      // This field is never set in production, but does get set from Mirage
      // so we need to clean it up to match our normal server response.
      delete resourceHash.billingScheme; // In different contexts, the id can come back as either `id` or `billingAccountId`,
      // so we need to normalize on just one of those.

      if ((0, _isSome.isSome)(resourceHash.billingAccountId)) {
        resourceHash.id = resourceHash.billingAccountId;
        delete resourceHash.billingAccountId;
      }

      const cardType = resourceHash.cardType;

      if ((0, _isSome.isSome)(cardType)) {
        switch (cardType.toLowerCase()) {
          case 'visa':
            resourceHash.cardType = _card.Type.VISA;
            break;

          case 'discover':
            resourceHash.cardType = _card.Type.Discover;
            break;

          case 'mastercard':
            resourceHash.cardType = _card.Type.Mastercard;
            break;

          case 'amex':
            resourceHash.cardType = _card.Type.AMEX;
            break;

          default:
            resourceHash.cardType = _card.Type.Unknown;
            break;
        }
      } // Some old saved cards have country codes saved uppercase instead of the expected lowercase.


      const country = resourceHash.country;

      if ((0, _isSome.isSome)(country)) {
        resourceHash.country = country.toLowerCase();
      }

      return super.normalize(model, resourceHash);
    }

  }

  _exports.default = BillingMembershipSerializer;
});