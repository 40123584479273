define("mobile-web/components/routes/outage-route/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y2sMXbiL",
    "block": "[[[10,0],[15,0,[30,0,[\"style\",\"root\"]]],[12],[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"style\",\"container\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"healthCheck\",\"isHealthy\"]],[[[1,\"      \"],[10,\"h1\"],[15,0,[30,0,[\"style\",\"header\"]]],[14,\"data-test-outage-resolvedHeader\",\"\"],[12],[1,\"\\n          \"],[1,[28,[35,1],[\"mwc.outage.resolvedHeader\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,2],[14,\"data-test-outage-upDescription\",\"\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"mwc.outage.upDescription\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[15,0,[30,0,[\"style\",\"status\"]]],[14,\"data-test-outage-upStatus\",\"\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"mwc.outage.upStatus\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,2],[[16,0,[30,0,[\"style\",\"link\"]]]],[[\"@route\"],[\"index\"]],[[\"default\"],[[[[1,\"\\n        Back to home\\n      \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,\"h1\"],[15,0,[30,0,[\"style\",\"header\"]]],[14,\"data-test-outage-errorHeader\",\"\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"mwc.outage.errorHeader\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,2],[14,\"data-test-outage-downDescription\",\"\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"mwc.outage.downDescription\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[15,0,[30,0,[\"style\",\"status\"]]],[14,\"data-test-outage-downStatus\",\"\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"mwc.outage.downStatus\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,3],[[24,\"data-test-outage-loadingSpinner\",\"\"]],[[\"@class\"],[[30,0,[\"style\",\"spinnerIcon\"]]]],null],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"t\",\"r-link-to\",\"r-icons/loading-spinner\"]]",
    "moduleName": "mobile-web/components/routes/outage-route/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});