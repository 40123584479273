define("mobile-web/components/r-application-frame/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UcbzaEma",
    "block": "[[[41,[51,[30,0,[\"isLoading\"]]],[[[1,\"  \"],[10,0],[15,1,[30,0,[\"announcerContainerId\"]]],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@class\"],[[30,0,[\"style\",\"announcer\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,2],null,[[\"@onLogin\",\"@onLogout\"],[[30,1],[30,2]]],null],[1,\"\\n  \"],[8,[39,3],null,null,null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,0],[15,1,[30,0,[\"uniqueId\"]]],[15,0,[30,0,[\"style\",\"root\"]]],[12],[1,\"\\n  \"],[8,[39,4],null,[[\"@model\"],[[30,0,[\"headerModel\"]]]],null],[1,\"\\n  \"],[10,0],[15,0,[28,[37,5],[[30,0,[\"style\",\"body\"]],[30,0,[\"routeClass\"]]],null]],[12],[18,3,null],[13],[1,\"\\n\\n\"],[41,[51,[30,0,[\"isLoading\"]]],[[[1,\"    \"],[8,[39,7],[[16,0,[30,0,[\"style\",\"footer\"]]]],[[\"@model\"],[[30,0,[\"footerModel\"]]]],null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[41,[51,[30,0,[\"isLoading\"]]],[[[1,\"  \"],[8,[39,8],null,null,null],[1,\"\\n\\n  \"],[8,[39,9],null,null,null],[1,\"\\n  \"],[8,[39,10],null,null,null],[1,\"\\n  \"],[8,[39,11],null,null,null],[1,\"\\n  \"],[8,[39,12],null,null,null],[1,\"\\n  \"],[8,[39,13],null,null,null],[1,\"\\n  \"],[8,[39,14],null,null,null],[1,\"\\n\"],[41,[28,[37,16],[\"isMobile\"],null],[[[1,\"    \"],[8,[39,17],[[24,\"data-test-application-frame-notifications\",\"\"],[16,0,[30,0,[\"style\",\"notifications\"]]]],[[\"@filter\"],[[30,0,[\"notificationsFilter\"]]]],null],[1,\"\\n\"]],[]],null]],[]],null],[8,[39,18],null,null,null],[1,\"\\n\"]],[\"@onLogin\",\"@onLogout\",\"&default\"],false,[\"unless\",\"route-announcer\",\"r-side-menu\",\"r-cart\",\"r-header\",\"classes\",\"yield\",\"r-footer\",\"r-feedback-banners\",\"r-edit-order-criteria-modal\",\"r-basket-transfer-modal\",\"r-challenge-modal\",\"r-confirm-modal\",\"r-product-modal\",\"r-static-content-modal\",\"if\",\"media\",\"r-notifications\",\"head-layout\"]]",
    "moduleName": "mobile-web/components/r-application-frame/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});