define("mobile-web/routes/outage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ServerErrorRoute extends Ember.Route {}

  _exports.default = ServerErrorRoute;
});