define("mobile-web/adapters/country", ["exports", "mobile-web/lib/hybrid-util", "mobile-web/adapters/application"], function (_exports, _hybridUtil, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CountryAdapter extends _application.default.extend({}) {
    currentCountryURL() {
      return `${(0, _hybridUtil.host)()}/${this.namespace}/countries/current`;
    }

    post(countryCode) {
      const URL = `${this.currentCountryURL()}?countryCode=${countryCode.toUpperCase()}`;
      return this.ajax(URL, 'POST');
    }

  }

  _exports.default = CountryAdapter;
});