define("mobile-web/components/routes/menu/vendor/products-route/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ae9xpX40",
    "block": "[[[11,0],[16,0,[30,0,[\"style\",\"root\"]]],[24,\"data-test-routes-menu-vendor-productsRoute\",\"\"],[4,[38,0],[[30,0,[\"openModal\"]]],null],[12],[1,\"\\n\"],[41,[51,[30,1,[\"displayInModal\"]]],[[[1,\"    \"],[8,[39,2],null,[[\"@basketProduct\",\"@product\",\"@displayInModal\",\"@previousRoute\",\"@onSave\",\"@isLoading\"],[[30,1,[\"basketProduct\"]],[30,1,[\"product\"]],[30,1,[\"displayInModal\"]],[30,1,[\"previousRoute\"]],[30,0,[\"transitionToVendor\"]],[30,2]]],null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@model\",\"@isLoading\"],false,[\"did-insert\",\"unless\",\"r-product-customization\"]]",
    "moduleName": "mobile-web/components/routes/menu/vendor/products-route/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});