define("mobile-web/components/r-discount-form/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "grow": "_grow_boq7hz",
    "noGrow": "_noGrow_boq7hz",
    "container": "_container_boq7hz",
    "removeContainer": "_removeContainer_boq7hz",
    "couponDescription": "_couponDescription_boq7hz",
    "confirmationContainer": "_confirmationContainer_boq7hz _grow_boq7hz",
    "confirmationContainerButtons": "_confirmationContainerButtons_boq7hz",
    "cancelButton": "_cancelButton_boq7hz _grow_boq7hz",
    "removeButton": "_removeButton_boq7hz _grow_boq7hz",
    "icon": "_icon_boq7hz",
    "label": "_label_boq7hz",
    "labelText": "_labelText_boq7hz",
    "headingLabel": "_headingLabel_boq7hz"
  };
  _exports.default = _default;
});