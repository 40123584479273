define("mobile-web/lib/computed/index", ["exports", "mobile-web/lib/computed/local", "mobile-web/lib/computed/session"], function (_exports, _local, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "local", {
    enumerable: true,
    get: function get() {
      return _local.default;
    }
  });
  Object.defineProperty(_exports, "session", {
    enumerable: true,
    get: function get() {
      return _session.default;
    }
  });
});