define("mobile-web/routes/opt-out-guide", ["exports", "mobile-web/lib/utilities/transform-content"], function (_exports, _transformContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class OptOutGuideRoute extends Ember.Route {
    model() {
      return Ember.RSVP.hash({
        content: this.loadContent(),
        title: this.store.findRecord('content', 'ONLINE_TRACKING_PAGE_LINK_NAME')
      });
    }

    titleToken(model) {
      return Ember.get(model.title, 'value');
    }

    async loadContent() {
      const brandContent = await this.store.findRecord('content', 'ONLINE_TRACKING_PAGE_BRAND_HTML');
      const baseContent = await this.store.findRecord('content', 'ONLINE_TRACKING_PAGE_HTML');
      return (0, _transformContent.transformContent)(Ember.get(baseContent, 'value'), _transformContent.ContentType.HTML, {
        BrandContent: Ember.get(brandContent, 'value')
      });
    }

  }

  _exports.default = OptOutGuideRoute;
});