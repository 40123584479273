define("mobile-web/components/r-google-login-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+1Z9rqVP",
    "block": "[[[11,\"button\"],[24,\"data-test-googleLoginButton\",\"\"],[16,\"data-test-loginProvider\",[30,1,[\"slug\"]]],[16,0,[30,0,[\"style\",\"googleLoginButton\"]]],[24,4,\"button\"],[4,[38,0],[\"click\",[28,[37,1],[[30,2],[30,1]],null]],null],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@ariaLabel\",\"@class\",\"@icon\"],[\"\",[30,0,[\"style\",\"icon\"]],\"r-google-logo-block\"]],null],[1,\"\\n  \"],[10,1],[15,0,[30,0,[\"style\",\"copy\"]]],[12],[1,[28,[35,3],[\"mwc.social.googleLogin\"],null]],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@provider\",\"@onClick\"],false,[\"on\",\"fn\",\"r-svg\",\"t\"]]",
    "moduleName": "mobile-web/components/r-google-login-button/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});