define("mobile-web/components/r-card/button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "77xrdgbC",
    "block": "[[[11,\"button\"],[16,0,[30,0,[\"baseStyle\"]]],[16,\"data-test-card-button\",[30,0,[\"testSelector\"]]],[24,4,\"button\"],[17,1],[4,[38,0],[\"click\",[30,2]],null],[12],[1,\"\\n  \"],[10,1],[15,0,[30,0,[\"style\",\"label\"]]],[12],[1,[30,3]],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@onClick\",\"@label\"],false,[\"on\"]]",
    "moduleName": "mobile-web/components/r-card/button/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});