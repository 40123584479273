define("mobile-web/components/r-cart/product/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_gmd6s1",
    "productContainer": "_productContainer_gmd6s1",
    "productWithRemove": "_productWithRemove_gmd6s1",
    "product": "_product_gmd6s1",
    "loadingIcon": "_loadingIcon_gmd6s1",
    "removeButton": "_removeButton_gmd6s1",
    "removeIcon": "_removeIcon_gmd6s1",
    "choiceList": "_choiceList_gmd6s1",
    "quantity": "_quantity_gmd6s1",
    "name": "_name_gmd6s1",
    "toggleChoicesButton": "_toggleChoicesButton_gmd6s1",
    "extraInfo": "_extraInfo_gmd6s1",
    "editable": "_editable_gmd6s1",
    "isCart": "_isCart_gmd6s1"
  };
  _exports.default = _default;
});