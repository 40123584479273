define("mobile-web/components/r-post-checkout/r-create-account/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "chIe40Ql",
    "block": "[[[10,\"aside\"],[15,\"title\",[28,[37,0],[\"mwc.postCheckout.createAccountLabel\"],null]],[15,0,[36,1]],[14,\"data-test-post-checkout-create-account\",\"\"],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@icon\"],[[50,\"r-svg\",0,null,[[\"icon\",\"ariaLabel\"],[\"r-account-circle\",\"\"]]]]],[[\"content\",\"buttons\"],[[[[1,\"\\n      \"],[10,2],[12],[1,\"\\n        \"],[8,[39,4],null,[[\"@key\",\"@defaultMessage\"],[\"RESP_POSTCHECKOUT_CREATEACCOUNT_PROMPT\",[28,[37,0],[\"mwc.saveFavorite.createAccountMessage\"],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]],[[[1,\"\\n      \"],[8,[30,1,[\"buttonContainer\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],[[16,0,[30,1,[\"buttonClass\"]]]],[[\"@label\",\"@onClick\",\"@variant\",\"@testSelector\"],[[28,[37,0],[\"mwc.postCheckout.createAccountLabel\"],null],[30,2],\"minor\",\"create-account-button\"]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"s\",\"@upgradeAccount\"],false,[\"t\",\"class\",\"r-post-checkout/r-subsection\",\"component\",\"x-content\",\"r-button\"]]",
    "moduleName": "mobile-web/components/r-post-checkout/r-create-account/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});