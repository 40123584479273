define("mobile-web/components/r-time-wanted-form/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "laterContent": "_laterContent_nybkbj",
    "timeWantedAtStore": "_timeWantedAtStore_nybkbj"
  };
  _exports.default = _default;
});