define("mobile-web/components/r-editable-section/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "headerRow": "_headerRow_1l5d2n",
    "heading": "_heading_1l5d2n",
    "description": "_description_1l5d2n",
    "button": "_button_1l5d2n"
  };
  _exports.default = _default;
});