define("mobile-web/lib/payment/method", ["exports", "mobile-web/lib/payment", "mobile-web/lib/utilities/is-some", "mobile-web/models/billing-membership", "mobile-web/lib/utilities/capitalize"], function (_exports, _payment, _isSome, _billingMembership, _capitalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fromScheme = fromScheme;
  _exports.fromMembership = fromMembership;
  _exports.fromCompCard = fromCompCard;
  _exports.fromOloPay = fromOloPay;
  _exports.fromCard = fromCard;
  _exports.default = _exports.Method = _exports.isMembership = _exports.Variant = void 0;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  /**
   * We use this for the UI. The server doesn't actually care about its value.
   */
  let Variant;
  _exports.Variant = Variant;

  (function (Variant) {
    Variant["Cash"] = "Cash";
    Variant["CompCard"] = "CompCard";
    Variant["NewCard"] = "NewCard";
    Variant["Membership"] = "Membership";
    Variant["LevelUp"] = "LevelUp";
    Variant["OloPay"] = "OloPay";
  })(Variant || (_exports.Variant = Variant = {}));

  function fromScheme(scheme) {
    if (scheme.provider === _payment.Provider.PassThrough) {
      return {
        variant: Variant.Cash,
        amount: 0,
        schemeId: scheme.id,
        canIncludeTip: false,
        includesTip: false,
        customFieldValues: [],
        membershipId: _billingMembership.CASH_MEMBERSHIP_ID,
        description: scheme.description,
        hasManualEdit: false
      };
    }

    (false && !(scheme.memberships.length === 1) && Ember.assert(`Only call fromScheme with schemes that have only one membership`, scheme.memberships.length === 1));
    return fromMembership(scheme.memberships.objectAt(0));
  }

  function fromMembership(membership) {
    const common = {
      amount: 0,
      schemeId: membership.billingScheme.id,
      canIncludeTip: true,
      includesTip: false,
      customFieldValues: [],
      description: membership.description,
      hasManualEdit: false
    };

    if (membership.billingScheme.provider === _payment.Provider.LevelUp) {
      return _extends({}, common, {
        variant: Variant.LevelUp,
        membershipId: membership.id
      });
    }

    return _extends({}, common, {
      variant: Variant.Membership,
      membershipId: membership.id
    });
  }

  function fromCompCard(card) {
    return {
      amount: card.amount,
      balance: card.amount,
      schemeId: '',
      description: card.description,
      canIncludeTip: false,
      includesTip: false,
      customFieldValues: [],
      hasManualEdit: false,
      variant: Variant.CompCard
    };
  }

  function fromOloPay(oloPayData) {
    const {
      cardType,
      cardLastFour
    } = oloPayData;
    const description = `${(0, _capitalize.default)(cardType)} x-${cardLastFour}`;
    return {
      variant: Variant.OloPay,
      description,
      amount: 0,
      canIncludeTip: true,
      includesTip: false,
      customFieldValues: [],
      hasManualEdit: false,
      schemeId: oloPayData.schemeId,
      paymentCard: _extends({}, oloPayData)
    };
  }

  function fromCard(card, scheme, membership) {
    const description = (0, _payment.isCreditCard)(card) ? `x-${card.creditCardNumber.slice(-4)}` : `${scheme.description} x${card.giftCardNumber.slice(-4)}`;
    const schemeId = scheme.id;
    const newCard = {
      amount: 0,
      schemeId,
      canIncludeTip: true,
      includesTip: false,
      customFieldValues: [],
      variant: Variant.NewCard,
      description,
      paymentCard: _extends({}, card, {
        schemeId
      }),
      hasManualEdit: false
    };

    if ((0, _isSome.isSome)(membership)) {
      newCard.fromMembershipId = membership.id;
    }

    return newCard;
  }

  const isMembership = method => method.hasOwnProperty('membershipId');
  /** A server-friendly representation of a user's selected payment source.
  
    A `Method` includes all information necessary for the server to process a
    payment, including whether the payment method in question has a tip attached
    */


  _exports.isMembership = isMembership;
  const Method = {
    CASH_MEMBERSHIP_ID: _billingMembership.CASH_MEMBERSHIP_ID,
    fromScheme
  };
  _exports.Method = Method;
  var _default = Method;
  _exports.default = _default;
});