define("mobile-web/components/r-carousel/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_13rl0h",
    "carousel": "_carousel_13rl0h",
    "header": "_header_13rl0h",
    "headings": "_headings_13rl0h",
    "heading": "_heading_13rl0h",
    "hiddenHeading": "_hiddenHeading_13rl0h",
    "subheading": "_subheading_13rl0h",
    "viewAllLink": "_viewAllLink_13rl0h",
    "description": "_description_13rl0h",
    "carouselContainer": "_carouselContainer_13rl0h",
    "scrollButtonContainer": "_scrollButtonContainer_13rl0h",
    "scrollLeftButtonContainer": "_scrollLeftButtonContainer_13rl0h _scrollButtonContainer_13rl0h",
    "scrollRightButtonContainer": "_scrollRightButtonContainer_13rl0h _scrollButtonContainer_13rl0h",
    "scrollButton": "_scrollButton_13rl0h",
    "hover": "_hover_13rl0h",
    "icon": "_icon_13rl0h",
    "item": "_item_13rl0h"
  };
  _exports.default = _default;
});