define("mobile-web/serializers/billing-details", ["exports", "ember-data", "mobile-web/models/billing-details"], function (_exports, _emberData, _billingDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BillingDetailsSerializer extends _emberData.default.JSONSerializer.extend(_emberData.default.EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);
      this.attrs = {
        billingSchemes: {
          embedded: 'always'
        }
      };
    }

    normalize(modelClass, resourceHash) {
      // Ember expects all models to have an ID, so we make one up.
      resourceHash.id = _billingDetails.BILLING_DETAILS_ID; // We need every boolean property to be present and have a value;
      // if we don't, Ember Data treats the undefined property as not having been updated.

      resourceHash.allowCardsOnFile = resourceHash.allowCardsOnFile || false;
      resourceHash.allowSplitPayments = resourceHash.allowSplitPayments || false;
      resourceHash.canPayCash = resourceHash.canPayCash || false;
      resourceHash.doCreditCardStreetAddressValidation = resourceHash.doCreditCardStreetAddressValidation || false;
      resourceHash.showTip = resourceHash.showTip || false;
      return super.normalize(modelClass, resourceHash);
    }

  }

  _exports.default = BillingDetailsSerializer;
});