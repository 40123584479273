define("mobile-web/components/routes/secure/checkout/auth-route/component", ["exports", "@glimmer/component", "mobile-web/lib/errors", "mobile-web/lib/order-criteria", "mobile-web/lib/routing", "mobile-web/components/routes/secure/checkout/auth-route/style"], function (_exports, _component, _errors, _orderCriteria, _routing, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * The contents of this class were copied from
   * /app/controllers/secure/checkout.js
   * which was then deleted. For history on the file, look
   * at the 0.3.9 release tag.
   */
  let AuthRouteComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class AuthRouteComponent extends _component.default {
    // Service injections
    // Passthroughs
    // Tracked properties
    // Getters and setters
    get hasLoginProvider() {
      var _this$loginProviders;

      return !!((_this$loginProviders = this.loginProviders) != null && _this$loginProviders.length);
    }

    get signOnAllowed() {
      return this.hasLoginProvider || !!this.supportsOloLogin;
    }

    get guestDetails() {
      return this.session.user;
    }

    get guestCheckoutAllowed() {
      var _this$channel$setting;

      return (_this$channel$setting = this.channel.settings) == null ? void 0 : _this$channel$setting.guestOrdering;
    }

    get defaultToGuestOrdering() {
      var _this$channel$setting2;

      return (_this$channel$setting2 = this.channel.settings) == null ? void 0 : _this$channel$setting2.defaultToGuestOrdering;
    }

    get hideGuestCheckout() {
      return !this.showGuestCheckout;
    }

    get supportsGuestToggling() {
      return !!this.guestCheckoutAllowed && this.signOnAllowed;
    }

    get showMarketingOptIn() {
      var _this$channel$setting3;

      return !((_this$channel$setting3 = this.channel.settings) != null && _this$channel$setting3.hideMarketingOptIn);
    }

    get showGuestMarketingOptIn() {
      var _this$channel$setting4;

      return !!((_this$channel$setting4 = this.channel.settings) != null && _this$channel$setting4.showGuestMarketingOptIn) && this.showMarketingOptIn;
    }

    get optIn() {
      var _this$channel$current;

      return !!((_this$channel$current = this.channel.currentCountrySettings) != null && _this$channel$current.optIn) && this.showGuestMarketingOptIn;
    }

    get supportsOloLogin() {
      var _this$channel$setting5;

      return (_this$channel$setting5 = this.channel.settings) == null ? void 0 : _this$channel$setting5.supportsOloLogin;
    }

    get loginProviders() {
      var _this$channel$current2;

      return (_this$channel$current2 = this.channel.current) == null ? void 0 : _this$channel$current2.loginProviders;
    }

    get requirePhoneNumber() {
      var _this$basket$basket, _this$channel$setting6;

      return (0, _orderCriteria.isDeliveryMode)((_this$basket$basket = this.basket.basket) == null ? void 0 : _this$basket$basket.handoffMode) || ((_this$channel$setting6 = this.channel.settings) == null ? void 0 : _this$channel$setting6.requirePhoneNumber);
    }

    get showGuestToggle() {
      return this.supportsGuestToggling && (this.supportsOloLogin || this.hideGuestCheckout);
    } // Constructor


    constructor(owner, args) {
      var _this$guestCheckoutAl;

      super(owner, args);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      _initializerDefineProperty(this, "channel", _descriptor2, this);

      _initializerDefineProperty(this, "error", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "session", _descriptor5, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "currentMode", _descriptor6, this);

      _initializerDefineProperty(this, "showGuestCheckout", _descriptor7, this);

      this.showGuestCheckout = ((_this$guestCheckoutAl = this.guestCheckoutAllowed) != null ? _this$guestCheckoutAl : false) && (this.session.isEditingOrder || this.defaultToGuestOrdering || !this.signOnAllowed);
    } // Other methods


    goToPayment() {
      this.router.transitionTo('secure.checkout');
    }

    invalidLogin(reason) {
      this.error.sendUserMessage({
        detail: reason
      });
    } // Tasks
    // Actions


    afterCreateAccount() {
      this.goToPayment();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    afterCreateAccountError(error) {
      this.error.sendUserMessage(error);
    }

    externalLogin(provider) {
      this.session.set('nextRoute', (0, _routing.currentTransitionArgs)(this.router.currentRoute));
      this.session.externalLogin(provider.slug);
    }

    login(email, password) {
      this.session.internalLogin(email, password, () => this.goToPayment(), (0, _errors.buildOloErrorHandler)('login-failure', reason => this.invalidLogin(reason)));
    }

    saveGuestUser(user) {
      this.session.setGuestUser(user).then(() => {
        this.goToPayment();
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "error", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "currentMode", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showGuestCheckout", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "afterCreateAccount", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "afterCreateAccount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterCreateAccountError", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "afterCreateAccountError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "externalLogin", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "externalLogin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "login", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "login"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveGuestUser", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "saveGuestUser"), _class.prototype)), _class));
  _exports.default = AuthRouteComponent;
});