define("mobile-web/components/r-card/button/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "mobile-web/components/r-card/button/style"], function (_exports, _glimmerComponent, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Variant = void 0;
  let Variant;
  _exports.Variant = Variant;

  (function (Variant) {
    Variant["Normal"] = "normal";
    Variant["Destructive"] = "destructive";
  })(Variant || (_exports.Variant = Variant = {}));

  class CardButton extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get baseStyle() {
      var _this$args$variant;

      return _style.default[(_this$args$variant = this.args.variant) != null ? _this$args$variant : Variant.Normal];
    }

    get testSelector() {
      var _this$args$testSelect;

      return (_this$args$testSelect = this.args.testSelector) != null ? _this$args$testSelect : '';
    } // Constructor
    // Other methods
    // Tasks
    // Actions


  }

  _exports.default = CardButton;
});