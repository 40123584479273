define("mobile-web/serializers/option-group", ["exports", "ember-data", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some", "mobile-web/lib/utilities/math"], function (_exports, _emberData, _, _isSome, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OptionGroupSerializer = (_dec = Ember.inject.service, (_class = class OptionGroupSerializer extends _emberData.default.RESTSerializer {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "features", _descriptor, this);
    }

    normalize(modelClass, resourceHash, prop) {
      // Quality of life renames here. Renaming properties to more
      // correctly reflect the purpose of the property.
      if ((0, _isSome.default)(resourceHash.isMandatory)) {
        resourceHash.forceSingleSelect = resourceHash.isMandatory;
        delete resourceHash.isMandatory;
      }

      if ((0, _isSome.default)(resourceHash.minSelects)) {
        resourceHash.configuredMinSelect = resourceHash.minSelects;
        delete resourceHash.minSelects;
      }

      if ((0, _isSome.default)(resourceHash.maxSelects)) {
        resourceHash.configuredMaxSelect = resourceHash.maxSelects;
        delete resourceHash.maxSelects;
      }

      resourceHash.isAvailable = (0, _.isArray)(resourceHash.choices) && resourceHash.choices.length > 0; // Quantity Mucking

      resourceHash.treatAsProductQuantity = resourceHash.hasChoiceQuantities === true && Ember.isNone(resourceHash.minAggregateQuantity);

      if (Ember.isNone(resourceHash.choiceQuantityIncrement) || resourceHash.choiceQuantityIncrement < 1) {
        resourceHash.choiceQuantityIncrement = 1;
      }

      if (Ember.isNone(resourceHash.minChoiceQuantity)) {
        resourceHash.minChoiceQuantity = 0;
      }

      if (Ember.isNone(resourceHash.maxChoiceQuantity)) {
        resourceHash.maxChoiceQuantity = 0;
      }

      if (Ember.isNone(resourceHash.minAggregateQuantity)) {
        resourceHash.minAggregateQuantity = 0;
      }

      if (Ember.isNone(resourceHash.maxAggregateQuantity)) {
        resourceHash.maxAggregateQuantity = 0;
      } // Align to choiceQuantityIncrement


      if (resourceHash.choiceQuantityIncrement > 1) {
        const step = resourceHash.choiceQuantityIncrement;
        resourceHash.minChoiceQuantity = (0, _math.roundUpToNext)(step, resourceHash.minChoiceQuantity);
        resourceHash.maxChoiceQuantity = (0, _math.roundDownToPrevious)(step, resourceHash.maxChoiceQuantity);
        resourceHash.minAggregateQuantity = (0, _math.roundUpToNext)(step, resourceHash.minAggregateQuantity);
        resourceHash.maxAggregateQuantity = (0, _math.roundDownToPrevious)(step, resourceHash.maxAggregateQuantity);
      }

      resourceHash.configuredMinAggregateQuantity = resourceHash.minAggregateQuantity;
      delete resourceHash.minAggregateQuantity;
      resourceHash.configuredMaxAggregateQuantity = resourceHash.maxAggregateQuantity;
      delete resourceHash.maxAggregateQuantity;

      if ((0, _isSome.default)(resourceHash.helpText)) {
        delete resourceHash.helpText;
      }

      return super.normalize(modelClass, resourceHash, prop);
    }

    normalizeFindManyResponse(store, primaryModelClass, payload, id, requestType) {
      const adapter = this.store.adapterFor('option-group');
      const findManyIds = adapter.findManyIds;

      if (findManyIds) {
        if (!payload.optionGroups) {
          payload.optionGroups = [];
        }

        payload.optionGroups.forEach(og => {
          findManyIds.removeObject(og.id.toString());
        });
        findManyIds.forEach(ogId => {
          payload.optionGroups.push({
            id: ogId
          });
        });
        delete adapter.findManyIds;
      }

      return super.normalizeFindManyResponse(store, primaryModelClass, payload, id, requestType);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "features", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OptionGroupSerializer;
});