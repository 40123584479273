define("mobile-web/components/r-footer/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "placeholder": "_placeholder_tm2r1w",
    "sideMenu": "_sideMenu_tm2r1w",
    "footer": "_footer_tm2r1w",
    "bottom": "_bottom_tm2r1w",
    "appStoreBadge": "_appStoreBadge_tm2r1w",
    "footerLinks": "_footerLinks_tm2r1w",
    "contactUsLink": "_contactUsLink_tm2r1w",
    "contentInfo": "_contentInfo_tm2r1w",
    "appVersion": "_appVersion_tm2r1w"
  };
  _exports.default = _default;
});