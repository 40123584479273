define("mobile-web/components/r-checkout/loyalty-scheme/component", ["exports", "@glimmer/component", "ember-concurrency", "ember-concurrency-ts", "mobile-web/lib/utilities/is-some", "mobile-web/services/user-feedback", "mobile-web/components/r-checkout/loyalty-scheme/style"], function (_exports, _component, _emberConcurrency, _emberConcurrencyTs, _isSome, _userFeedback, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CheckoutLoyaltyScheme = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_class = class CheckoutLoyaltyScheme extends _component.default {
    // Service injections
    // Passthroughs
    // Tracked properties
    // Getters and setters
    get isAnyTaskRunning() {
      return (0, _emberConcurrencyTs.taskFor)(this.getAccountTask).isRunning || (0, _emberConcurrencyTs.taskFor)(this.addRewardTask).isRunning || (0, _emberConcurrencyTs.taskFor)(this.removeRewardTask).isRunning || (0, _emberConcurrencyTs.taskFor)(this.removeCouponTask).isRunning;
    }

    get appliedReward() {
      return (0, _isSome.default)(this.account) && (0, _isSome.default)(this.basket.basket.reward) ? this.account.qualifyingLoyaltyRewards.findBy('externalReference', this.basket.basket.reward.externalReference) : undefined;
    }

    get unappliedRewards() {
      return (0, _isSome.default)(this.account) ? this.account.qualifyingLoyaltyRewards.filter(reward => reward !== this.appliedReward) : [];
    }

    get hideToggle() {
      return (0, _isSome.default)(this.account) && this.account.qualifyingLoyaltyRewards.length === 1;
    }

    get removeDiscountTitle() {
      return this.basket.basket.hasCoupon ? 'mwc.checkout.removeCouponTitle' : 'mwc.checkout.removeRewardTitle';
    }

    get removeDiscountText() {
      return this.basket.basket.hasCoupon ? 'mwc.checkout.removeCouponText' : 'mwc.checkout.removeRewardText';
    } // Constructor


    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "basket", _descriptor3, this);

      _initializerDefineProperty(this, "bus", _descriptor4, this);

      _initializerDefineProperty(this, "challenge", _descriptor5, this);

      _initializerDefineProperty(this, "loyalty", _descriptor6, this);

      _initializerDefineProperty(this, "userFeedback", _descriptor7, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "account", _descriptor8, this);

      _initializerDefineProperty(this, "isExpanded", _descriptor9, this);

      _initializerDefineProperty(this, "linkId", _descriptor10, this);

      (0, _emberConcurrencyTs.taskFor)(this.getAccountTask).perform();
    } // Other methods
    // Tasks


    *getAccountTask() {
      const memberships = this.store.peekAll('loyalty-membership');
      const linkedMembership = memberships.findBy('schemeName', this.args.scheme.schemeName);

      if ((0, _isSome.default)(linkedMembership)) {
        yield (0, _emberConcurrencyTs.taskFor)(this.challenge.request).perform(async () => {
          this.account = await this.store.queryRecord('loyalty-account', {
            membershipId: linkedMembership.membershipId,
            vendorId: this.args.scheme.vendor.id
          });
        });
      }
    }

    *addRewardTask(reward) {
      yield this.basket.basket.applyReward({
        membershipId: reward.membershipId,
        reference: reward.externalReference
      });
      reward.set('isApplied', true);
      this.isExpanded = false;

      if (this.args.onBasketChange) {
        yield this.args.onBasketChange(() => {
          // It's hard right now to type the class such that tasks can know about other tasks.
          (0, _emberConcurrencyTs.taskFor)(this.removeRewardTask).perform(reward);
        });
      }

      this.bus.trigger('adjustAmounts');
    }

    *removeRewardTask(reward) {
      yield this.basket.basket.removeReward({
        membershipId: reward.membershipId,
        reference: reward.externalReference
      });
      reward.set('isApplied', false);
      this.isExpanded = true;

      if (this.args.onBasketChange) {
        yield this.args.onBasketChange();
      }

      this.bus.trigger('adjustAmounts');
    }

    *removeCouponTask() {
      yield this.basket.basket.removeCoupon();
    } // Actions


    linkMembership() {
      this.loyalty.linkMembershipAccount(this.linkId, this.args.scheme).then(() => {
        this.userFeedback.add({
          type: _userFeedback.Type.Positive,
          title: this.intl.t('mwc.feedbackBanner.successTitle'),
          message: this.intl.t('mwc.checkout.linkMembershipSuccessMessage', {
            provider: this.args.scheme.loyaltyProvider
          })
        });
        (0, _emberConcurrencyTs.taskFor)(this.getAccountTask).perform();
      });
    }

    applyReward(reward) {
      const basket = this.basket.basket;

      if (basket.hasCoupon || basket.hasReward) {
        this.bus.trigger('confirm', {
          title: this.intl.t(this.removeDiscountTitle),
          content: this.intl.t(this.removeDiscountText),
          buttonLabel: this.intl.t('mwc.checkout.confirmRemoveDiscountButton'),
          cancelLabel: this.intl.t('mwc.checkout.cancelRemoveDiscountButton'),
          onConfirm: () => this.removeDiscount(reward),
          testSelector: 'removeDiscount',
          buttonTestSelector: 'confirmRemoveDiscount',
          cancelTestSelector: 'cancelRemoveDiscount'
        });
      } else {
        (0, _emberConcurrencyTs.taskFor)(this.addRewardTask).perform(reward);
      }
    }

    removeReward() {
      if ((0, _isSome.default)(this.appliedReward)) {
        return (0, _emberConcurrencyTs.taskFor)(this.removeRewardTask).perform(this.appliedReward);
      }

      return Ember.RSVP.resolve();
    }

    removeDiscount(reward) {
      const basket = this.basket.basket;
      const taskResult = basket.hasCoupon ? (0, _emberConcurrencyTs.taskFor)(this.removeCouponTask).perform() : (0, _emberConcurrencyTs.taskFor)(this.removeRewardTask).perform(this.appliedReward);
      taskResult.then(() => (0, _emberConcurrencyTs.taskFor)(this.addRewardTask).perform(reward)).then(() => {
        reward.set('isApplied', true);
        this.isExpanded = false;
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "bus", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "challenge", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "loyalty", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "userFeedback", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "account", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isExpanded", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "linkId", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "getAccountTask", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "getAccountTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addRewardTask", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "addRewardTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeRewardTask", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "removeRewardTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeCouponTask", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "removeCouponTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "linkMembership", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "linkMembership"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "applyReward", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "applyReward"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeReward", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "removeReward"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeDiscount", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "removeDiscount"), _class.prototype)), _class));
  _exports.default = CheckoutLoyaltyScheme;
});