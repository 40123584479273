define("mobile-web/components/r-checkout/loyalty-scheme/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "section": "_section_1gora2",
    "linkContainer": "_linkContainer_1gora2",
    "linkField": "_linkField_1gora2",
    "rewardHeader": "_rewardHeader_1gora2",
    "cardContainer": "_cardContainer_1gora2",
    "card": "_card_1gora2",
    "label": "_label_1gora2",
    "exp": "_exp_1gora2"
  };
  _exports.default = _default;
});