define("mobile-web/components/routes/menu/vendor-route/component", ["exports", "@ember-decorators/component", "@olo/pay", "dayjs", "ember-concurrency-ts", "fast-fuzzy", "mobile-web/components/r-button/component", "mobile-web/lib/dayjs", "mobile-web/lib/order-criteria", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/device-detect", "mobile-web/lib/utilities/is-some", "mobile-web/models/vendor-search-result", "mobile-web/services/user-feedback", "mobile-web/components/routes/menu/vendor-route/style"], function (_exports, _component, _pay, _dayjs, _emberConcurrencyTs, _fastFuzzy, _component2, _dayjs2, _orderCriteria, _, _deviceDetect, _isSome, _vendorSearchResult, _userFeedback, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MenuVendorRoute = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember.inject.service, _dec11 = Ember.inject.service, _dec12 = Ember.inject.service, _dec13 = Ember.computed('storage.showFullMenu', 'vendor'), _dec14 = Ember.computed('products.[]'), _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec(_class = (_class2 = class MenuVendorRoute extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      _initializerDefineProperty(this, "challenge", _descriptor2, this);

      _initializerDefineProperty(this, "channel", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "reorder", _descriptor5, this);

      _initializerDefineProperty(this, "router", _descriptor6, this);

      _initializerDefineProperty(this, "session", _descriptor7, this);

      _initializerDefineProperty(this, "orderCriteria", _descriptor8, this);

      _initializerDefineProperty(this, "storage", _descriptor9, this);

      _initializerDefineProperty(this, "store", _descriptor10, this);

      _initializerDefineProperty(this, "userFeedback", _descriptor11, this);

      this.searchActive = false;
      this.style = _style.default;
    }

    // Computed properties
    get products() {
      return this.store.peekAll('product').filter(p => p.vendor === this.vendor && (this.storage.showFullMenu || p.isAvailable));
    }

    get productSearcher() {
      return new _fastFuzzy.Searcher(this.products, {
        keySelector: obj => obj.name
      });
    }

    get showTypeahead() {
      return !_deviceDetect.IS_IE;
    } // Init


    init() {
      super.init();
      (false && !((0, _isSome.default)(this.vendor)) && Ember.assert('`vendor` is required', (0, _isSome.default)(this.vendor)));

      if (this.vendor.settings.isOloPay) {
        (0, _pay.initializeOloPay)();
      }
    } // Other methods


    showPreCheckBanner(title, message) {
      this.userFeedback.add({
        type: _userFeedback.Type.Negative,
        title,
        message,
        actions: [{
          label: this.intl.t('mwc.vendor.changeLocationButton'),
          fn: () => {
            this.router.transitionTo('vendor-search-results');
          },
          variant: _component2.Variant.Main
        }, {
          label: this.intl.t('mwc.vendor.proceedToMenuButton'),
          fn: () => {
            this.storage.ignorePrecheckError = true;
          },
          variant: _component2.Variant.Minor,
          cancelAction: true
        }]
      });
    }

    getBusinessHoursMessage() {
      // Alert the user when the next available hours are if available
      const nextOpenTime = (0, _dayjs.default)(this.vendor.nextOpenTime);
      const day = nextOpenTime.calendar((0, _dayjs.default)(), _dayjs2.DAY_FORMAT);
      return this.intl.t('mwc.vendor.businessHoursUnavailable', {
        day,
        vendorName: this.vendor.name,
        time: nextOpenTime.format('h:mm A')
      });
    } // Actions


    doReorder(order, index) {
      this.reorder.reorder(order, {
        index
      });
    }

    goToOrderDetails(orderId) {
      this.router.transitionTo('order-summary', orderId);
    }

    onInsert() {
      const scheduledForToday = !(0, _isSome.default)(this.orderCriteria.criteria) || (0, _orderCriteria.isForToday)(this.orderCriteria.criteria);

      if (this.vendor.overrideReason && scheduledForToday) {
        this.showPreCheckBanner('', this.vendor.overrideReason.reason);
      } else if (!this.skipPreCheck) {
        this.preCheck();
      }
    }

    preCheck() {
      const shouldDisplayBusinessHoursMsg = !this.vendor.isOpen && this.vendor.nextOpenTime;
      const criteria = this.orderCriteria.searchOrderCriteria;
      const basket = this.basket.basket;

      if (criteria) {
        (0, _emberConcurrencyTs.taskFor)(this.challenge.request).perform(async () => {
          const response = await this.vendor.preCheck(criteria);

          if (!response.isValid && !this.storage.ignorePrecheckError) {
            const isTimeWantedError = response.failureCategory === _vendorSearchResult.FailureCategory.TimeWanted;
            this.showPreCheckBanner(this.intl.t('mwc.vendor.preCheckWarningTitle'), shouldDisplayBusinessHoursMsg && isTimeWantedError ? this.getBusinessHoursMessage() : response.errorMessage);
          } else if (response.isValid) {
            if (this.basket.basket) {
              this.orderCriteria.updateBasket();
            }
          }
        });
      } else if (!this.storage.ignorePrecheckError && (!basket || !basket.isAdvance) && !(0, _.isEmpty)(this.vendor.unavailableMessage)) {
        if (shouldDisplayBusinessHoursMsg) {
          this.showPreCheckBanner(this.intl.t('mwc.vendor.preCheckWarningTitle'), this.getBusinessHoursMessage());
        } else {
          this.showPreCheckBanner(this.vendor.unavailableMessage, this.intl.t('mwc.vendor.unavailableMessage'));
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "basket", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "challenge", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "channel", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "reorder", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "orderCriteria", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "storage", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "userFeedback", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "products", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "products"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "productSearcher", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "productSearcher"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "doReorder", [_dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "doReorder"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "goToOrderDetails", [_dec16], Object.getOwnPropertyDescriptor(_class2.prototype, "goToOrderDetails"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onInsert", [_dec17], Object.getOwnPropertyDescriptor(_class2.prototype, "onInsert"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "preCheck", [_dec18], Object.getOwnPropertyDescriptor(_class2.prototype, "preCheck"), _class2.prototype)), _class2)) || _class);
  _exports.default = MenuVendorRoute;
});