define("mobile-web/components/r-card/link/component", ["exports", "mobile-web/components/r-card/link/style"], function (_exports, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CardLink extends Ember.LinkComponent.extend({
    classNames: [_style.default.link],
    attributeBindings: ['elementId:data-test-card-link']
  }) {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    }

  }

  _exports.default = CardLink;
});