define("mobile-web/components/r-address-display/component", ["exports", "@glimmer/component", "mobile-web/lib/a11y", "mobile-web/lib/location/address", "mobile-web/components/r-address-display/style"], function (_exports, _component, _a11y, _address, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AddressDisplay extends _component.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get streetAddress2() {
      const m = this.args.model;
      return (0, _address.isAddress)(m) ? m.building : m.streetAddress2;
    }

    get crossStreet() {
      const m = this.args.model;
      return (0, _address.isVendorAddress)(m) && !this.args.noCrossStreet ? m.crossStreet : undefined;
    }

    get zip() {
      const m = this.args.model;
      return (0, _address.isAddress)(m) ? m.zipCode : m.postalCode;
    }

    get zipSR() {
      return (0, _a11y.splitNumbers)(this.zip);
    } // Constructor
    // Other methods
    // Tasks
    // Actions


  }

  _exports.default = AddressDisplay;
});