define("mobile-web/helpers/format-currency", ["exports", "@glint/environment-ember-loose/ember-component/helper", "true-myth/maybe"], function (_exports, _helper, _maybe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FormatCurrency = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class FormatCurrency extends _helper.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "vendor", _descriptor2, this);
    }

    compute(params, namedArgs = {}) {
      const amount = _maybe.default.of(params).andThen(_maybe.default.head).andThen(value => isNaN(value) ? (0, _maybe.nothing)() : (0, _maybe.just)(value)).unwrapOr(0);

      const format = namedArgs.format || this.vendor.currency || 'USD';
      return this.intl.formatNumber(amount, {
        style: 'currency',
        currency: format,
        minimumFractionDigits: namedArgs.hideDecimal && Number.isInteger(amount) ? 0 : 2,
        maximumFractionDigits: 2
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "vendor", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = FormatCurrency;
});