define("mobile-web/plugins/serve-apple-signin/index", ["exports", "@capacitor/core"], function (_exports, _core) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ServeAppleSignin = (0, _core.registerPlugin)('ServeAppleSignin');
  var _default = ServeAppleSignin;
  _exports.default = _default;
});