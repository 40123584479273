define("mobile-web/services/storage", ["exports", "mobile-web/config/environment", "mobile-web/lib/computed", "mobile-web/lib/utilities/device-detect", "mobile-web/lib/utilities/is-some"], function (_exports, _environment, _computed, _deviceDetect, _isSome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STORE_NAME = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const DB_NAME = 'data-cache';
  const STORE_NAME = 'dataCache';
  _exports.STORE_NAME = STORE_NAME;
  const DB_VERSION = 1;
  let StorageService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class StorageService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "error", _descriptor, this);

      _initializerDefineProperty(this, "indexeddb", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "features", _descriptor4, this);

      _initializerDefineProperty(this, "hybridEverOpened", _descriptor5, this);

      _initializerDefineProperty(this, "forceChallengePattern", _descriptor6, this);

      _initializerDefineProperty(this, "orderSubmission", _descriptor7, this);

      _initializerDefineProperty(this, "ignorePrecheckError", _descriptor8, this);

      _initializerDefineProperty(this, "lastOrderVendorSlug", _descriptor9, this);

      _initializerDefineProperty(this, "lastPreCheckValid", _descriptor10, this);

      _initializerDefineProperty(this, "lastVendorSlug", _descriptor11, this);

      _initializerDefineProperty(this, "receivingCustomer", _descriptor12, this);

      _initializerDefineProperty(this, "showFullMenu", _descriptor13, this);

      _initializerDefineProperty(this, "cacheCleaned", _descriptor14, this);

      _initializerDefineProperty(this, "dataCache", _descriptor15, this);

      this.dataCacheBorked = false;
    }

    get dbDataCache() {
      if (this.dataCacheBorked) {
        // Empty resolve because we didn't fail on this call (which should have rejected)
        // but rather are not even going to try because we are borked.
        return Ember.RSVP.resolve();
      }

      return this.indexeddb.open({
        name: DB_NAME,
        version: DB_VERSION,
        onUpgradeNeeded: db => {
          if (!db.objectStoreNames.contains(STORE_NAME)) {
            db.createObjectStore(STORE_NAME);
          }
        }
      });
    }

    async rehydrateStore() {
      if (this.features.flags['persistent-storage'] && !_deviceDetect.IS_IE && !_environment.default.isHybrid) {
        try {
          const indexeddb = this.indexeddb;
          const store = this.store; // At the beginning of every session, make sure we are starting fresh with our cache

          if (!this.cacheCleaned) {
            await indexeddb.delete(DB_NAME).catch(err => {
              this.error.sendExternalError(err, undefined, ['persistent-storage']);
              this.dataCacheBorked = true;
              return undefined;
            });
            this.cacheCleaned = !this.dataCacheBorked;
          }

          if (!this.dataCacheBorked) {
            const modelsToLoad = {};
            const db = await this.dbDataCache.catch(err => {
              this.error.sendExternalError(err, undefined, ['persistent-storage']);
              this.dataCacheBorked = true;
              return undefined;
            });

            if (db) {
              const dataCacheStore = indexeddb.start(db, STORE_NAME, 'readonly');
              const keys = await indexeddb.getAllKeys(dataCacheStore); // Might be able to do something more performant here in the future if performance is an issue.
              // Something like `await Promise.all(keys.map(modelKey => indexeddb.getObject(dataCacheStore, modelKey)))`

              for (const modelKey of keys) {
                var _await$indexeddb$getO;

                const modelMap = (_await$indexeddb$getO = await indexeddb.getObject(dataCacheStore, modelKey)) != null ? _await$indexeddb$getO : {};
                Object.keys(modelMap).forEach(modelId => {
                  const cacheData = modelMap[modelId];
                  const data = store.push(store.normalize(modelKey, cacheData.data));
                  data.eachRelationship((name, details) => {
                    const options = details.options;

                    if (options.async === false) {
                      const modelName = details.type;

                      if (details.kind === 'belongsTo') {
                        const id = data.belongsTo(name).id();

                        if ((0, _isSome.default)(id)) {
                          var _modelsToLoad$modelNa;

                          modelsToLoad[modelName] = (_modelsToLoad$modelNa = modelsToLoad[modelName]) != null ? _modelsToLoad$modelNa : [];
                          modelsToLoad[modelName].push(id);
                        }
                      } else {
                        data.hasMany(name).ids().forEach(id => {
                          if ((0, _isSome.default)(id)) {
                            var _modelsToLoad$modelNa2;

                            modelsToLoad[modelName] = (_modelsToLoad$modelNa2 = modelsToLoad[modelName]) != null ? _modelsToLoad$modelNa2 : [];
                            modelsToLoad[modelName].push(id);
                          }
                        });
                      }
                    }
                  });

                  if (cacheData.options.backgroundReload) {
                    store.findRecord(modelKey, modelId, {
                      backgroundReload: true
                    });
                  }
                });
              }

              for (const modelKey of Object.keys(modelsToLoad)) {
                for (const modelId of modelsToLoad[modelKey]) {
                  await store.findRecord(modelKey, modelId);
                }
              }
            }
          }
        } catch (err) {
          /**
           * We shouldn't be able to get to this point with the error handling in the method.
           * Just in case I'm not as clever as I think I am (unlikely, I know, but roll with it)
           * catch all the things and silently report them.
           */
          this.error.sendExternalError(err, undefined, ['persistent-storage']);
          this.dataCacheBorked = true;
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "error", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "indexeddb", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "features", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hybridEverOpened", [_computed.local], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "forceChallengePattern", [_computed.session], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "orderSubmission", [_computed.session], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "ignorePrecheckError", [_computed.session], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "lastOrderVendorSlug", [_computed.session], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "lastPreCheckValid", [_computed.session], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "lastVendorSlug", [_computed.session], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "receivingCustomer", [_computed.session], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "showFullMenu", [_computed.session], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "cacheCleaned", [_computed.session], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "dataCache", [_computed.session], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = StorageService;
});