define("mobile-web/initializers/add-announcer-to-router", ["exports", "mobile-web/router"], function (_exports, _router) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'add-announcer-to-router',

    initialize() {
      _router.default.reopen({
        announcer: Ember.inject.service('announcer'),

        init() {
          this._super(...arguments);

          this.on('routeDidChange', () => {
            this._timerId = Ember.run.later(() => {
              if (this.isDestroying || this.isDestroyed) {
                return;
              }

              let pageTitle = document.title.trim();
              let serviceMessage = this.get('announcer.message');
              let message = `${pageTitle} ${serviceMessage}`;
              this.get('announcer').announce(message, 'polite');
            }, 100);
          });
        },

        willDestroy() {
          Ember.run.cancel(this._timerId);

          this._super();
        }

      });
    }

  };
  _exports.default = _default;
});