define("mobile-web/components/r-href/component", ["exports", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-href/style"], function (_exports, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Target = void 0;
  let Target;
  _exports.Target = Target;

  (function (Target) {
    Target["Self"] = "_self";
    Target["Blank"] = "_blank";
    Target["Parent"] = "_parent";
    Target["Top"] = "_top";
  })(Target || (_exports.Target = Target = {}));

  class Href extends Ember.Component.extend({
    tagName: ''
  }) {
    constructor(...args) {
      super(...args);
      this.target = Target.Self;
      this.testSelector = '';
      this.rel = 'noopener';
      this.style = _style.default;
    }

    init() {
      super.init();
      (false && !((0, _isSome.default)(this.href)) && Ember.assert('`href` is required', (0, _isSome.default)(this.href)));
    }

  }

  _exports.default = Href;
});