define("mobile-web/components/r-application-loading/menu/component", ["exports", "@glimmer/component", "mobile-web/components/r-application-loading/menu/style"], function (_exports, _component, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApplicationLoadingMenu extends _component.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get eachArray() {
      return this.args.isViewAll ? [1] : [1, 2];
    } // Constructor
    // Other methods
    // Tasks
    // Actions


  }

  _exports.default = ApplicationLoadingMenu;
});