define("mobile-web/components/r-cart/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "ember-concurrency", "ember-concurrency-ts", "mobile-web/components/r-button/component", "mobile-web/lib/errors", "mobile-web/lib/order-criteria", "mobile-web/lib/self-invoking-callback", "mobile-web/lib/time/format", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some", "mobile-web/lib/utilities/math", "mobile-web/services/analytics", "mobile-web/services/error", "mobile-web/services/global-events", "mobile-web/services/notifications", "mobile-web/services/user-feedback", "mobile-web/components/r-cart/style"], function (_exports, _glimmerComponent, _emberConcurrency, _emberConcurrencyTs, _component, _errors, _orderCriteria, _selfInvokingCallback, _format, _, _isSome, _math, _analytics, _error, _globalEvents, _notifications, _userFeedback, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const CART_ID = 'OLO_CART';
  let Cart = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember.inject.service, _dec11 = Ember.inject.service, _dec12 = Ember.inject.service, _dec13 = Ember.inject.service, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, (_class = class Cart extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "basket", _descriptor2, this);

      _initializerDefineProperty(this, "bus", _descriptor3, this);

      _initializerDefineProperty(this, "error", _descriptor4, this);

      _initializerDefineProperty(this, "features", _descriptor5, this);

      _initializerDefineProperty(this, "globalEvents", _descriptor6, this);

      _initializerDefineProperty(this, "intl", _descriptor7, this);

      _initializerDefineProperty(this, "mwcIntl", _descriptor8, this);

      _initializerDefineProperty(this, "orderCriteria", _descriptor9, this);

      _initializerDefineProperty(this, "router", _descriptor10, this);

      _initializerDefineProperty(this, "store", _descriptor11, this);

      _initializerDefineProperty(this, "userFeedback", _descriptor12, this);

      _initializerDefineProperty(this, "vendor", _descriptor13, this);

      this.cartId = CART_ID;
      this.style = _style.default;

      _initializerDefineProperty(this, "cartElement", _descriptor14, this);

      _initializerDefineProperty(this, "debugInput", _descriptor15, this);

      this.tapClearTimer = undefined;
      this.tapCount = 0;
      this.proceedToCheckoutTask = (0, _emberConcurrencyTs.taskFor)(this.proceedToCheckoutTaskInstance);
    }

    // Getters and setters
    get outsideVendorMenu() {
      return (0, _isSome.default)(this.vendor.vendor) && this.router.currentRouteName.indexOf('menu') === -1;
    }

    get handoffMinimum() {
      const basket = this.basket.basket;

      if ((0, _isSome.default)(basket)) {
        var _basket$vendor$get, _basket$vendor$get2;

        const minimumDelivery = (_basket$vendor$get = basket.vendor.get('minimumDeliveryOrder')) != null ? _basket$vendor$get : 0;
        const minimumPickup = (_basket$vendor$get2 = basket.vendor.get('minimumPickupOrder')) != null ? _basket$vendor$get2 : 0;
        const {
          handoffMode
        } = basket; // Dispatch does not use vendor minimumDeliveryOrder.

        if (handoffMode === 'Dispatch') {
          return 0;
        }

        if (handoffMode === 'Delivery') {
          return minimumDelivery;
        }

        return minimumPickup;
      }

      return 0;
    }

    get remainingAmount() {
      const basket = this.basket.basket;

      if ((0, _isSome.default)(basket) && this.handoffMinimum > basket.subTotal) {
        return (0, _math.roundDecimals)(this.handoffMinimum - basket.subTotal);
      }

      return 0;
    }

    get orderTimeLabel() {
      if (!this.orderCriteria.basketOrderCriteria) {
        return '';
      }

      const criteria = this.orderCriteria.basketOrderCriteria;

      if ((0, _orderCriteria.isAdvance)(criteria) && criteria.timeWanted) {
        var _this$basket$basket, _this$basket$basket$v;

        let timeLabel = this.mwcIntl.relativeDateTime(criteria.timeWanted);

        if ((_this$basket$basket = this.basket.basket) != null && (_this$basket$basket$v = _this$basket$basket.vendor) != null && _this$basket$basket$v.get('utcOffset')) {
          const vendorOffset = this.basket.basket.vendor.get('utcOffset');

          if (criteria.timeWanted.utcOffset() !== vendorOffset * 60) {
            timeLabel += ` (${this.intl.t('mwc.calendar.timeWantedAtStore', {
              time: criteria.timeWanted.utcOffset(vendorOffset * 60).format(_format.default.TIME_FORMAT)
            })})`;
          }
        }

        return timeLabel;
      }

      if (criteria.timeWantedType === 'Immediate') {
        return this.intl.t('mwc.cart.immediateLabel', {
          description: (0, _orderCriteria.getTimeWantedTypeLabel)(criteria.timeWantedType, this.store),
          leadTime: this.basket.basket.leadTimeEstimate || 0
        });
      }

      return (0, _orderCriteria.getTimeWantedTypeLabel)(criteria.timeWantedType, this.store);
    }

    get showMinimum() {
      return this.remainingAmount > 0;
    }

    get isCheckoutDisabled() {
      return this.showMinimum;
    }

    get basketProducts() {
      var _this$basket$basket$b, _this$basket$basket2;

      return (_this$basket$basket$b = (_this$basket$basket2 = this.basket.basket) == null ? void 0 : _this$basket$basket2.basketProducts.toArray()) != null ? _this$basket$basket$b : [];
    }

    // Other methods
    goToCheckout() {
      this.analytics.trackEvent(_analytics.AnalyticsEvents.ProceedToCheckout);
      this.router.transitionTo('secure.checkout.auth');
    }

    notificationsFilter(notification) {
      switch (notification.type) {
        case _notifications.NotificationType.ProductRemoved:
        case _notifications.NotificationType.ProductUpdated:
        case _notifications.NotificationType.UpsellAdded:
          return true;

        default:
          return false;
      }
    } // Tasks


    *proceedToCheckoutTaskInstance() {
      try {
        const response = yield this.basket.basket.validate();

        if (response.success) {
          // Trigger the checkout event. Pass the basket data and a callback to
          // be invoked when tracking checkout is complete. Create a self-invoking
          // callback that will run after 750ms if it isn't called before then.
          // The use-case for this is GTM or another tag library needing to complete
          // emitting the `checkout` event _before_ we navigate to the checkout page.
          if (this.globalEvents.has(_globalEvents.GlobalEventName.Checkout)) {
            this.globalEvents.trigger(_globalEvents.GlobalEventName.Checkout, this.basket.basket.serializeForGlobalData(), (0, _selfInvokingCallback.createSelfInvokingCallback)(() => this.goToCheckout(), 750));
          } else {
            this.goToCheckout();
          }
        }
      } catch (e) {
        if ((0, _error.errorForUser)(e) && e.errors[0].category === _errors.ErrorCategory.CouponRequirementsNotMet) {
          const message = this.intl.t('mwc.cart.invalidCoupon.error', {
            error: e.errors[0].message
          });
          this.userFeedback.add({
            type: _userFeedback.Type.Negative,
            title: _error.DEFAULT_TITLE,
            message,
            actions: [{
              label: this.intl.t('mwc.cart.invalidCoupon.keep'),
              fn: _.noop,
              variant: _component.Variant.Minor,
              cancelAction: true
            }, {
              label: this.intl.t('mwc.cart.invalidCoupon.remove'),
              fn: async () => {
                var _this$basket$basket3;

                await ((_this$basket$basket3 = this.basket.basket) == null ? void 0 : _this$basket$basket3.removeCoupon());
                this.checkout();
              },
              variant: _component.Variant.Main
            }]
          });
        } else {
          this.error.reportError(e);
        }
      }
    } // Actions


    checkout() {
      this.proceedToCheckoutTask.perform();
    }

    addMoreItems() {
      this.basket.close();
      this.router.transitionTo('menu.vendor', this.basket.basket.vendor.get('slug'));
    }

    startOrder() {
      this.basket.close();

      if (this.router.currentRouteName === 'thank-you') {
        this.router.transitionTo('menu.vendor', this.vendor.vendorSlug);
      }
    }

    async edit(basketProduct) {
      const product = await basketProduct.get('product');

      if (!product.hasChoices) {
        this.bus.trigger('showProductModal', {
          product,
          basketProduct,
          onClose: () => {
            this.basket.open();
          }
        });
        return;
      }

      this.router.transitionTo('basket.basket-products.edit', basketProduct.id);
    }

    clearTapCount() {
      this.tapCount = 0;
    }

    click() {
      clearTimeout(this.tapClearTimer);
      this.tapCount++;

      if (this.tapCount >= 10) {
        var _this$basket$basket4;

        // reveal Launch Darkly user key
        if (this.features.userKey) {
          // eslint-disable-next-line no-console
          console.log('%c LD Key: ', 'color:yellowgreen', this.features.userKey);
        } // reveal basket guid


        const guid = (_this$basket$basket4 = this.basket.basket) == null ? void 0 : _this$basket$basket4.guid;

        if (guid !== undefined) {
          var _this$debugInput;

          (_this$debugInput = this.debugInput) == null ? void 0 : _this$debugInput.select();
          document.execCommand('copy'); // eslint-disable-next-line no-alert

          alert(`Basket ID (${guid}) has been copied to clipboard!`);
        }

        this.clearTapCount();
      } else {
        this.tapClearTimer = setTimeout(this.clearTapCount, 1000);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "bus", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "error", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "features", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "globalEvents", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "mwcIntl", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "orderCriteria", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "userFeedback", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "vendor", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "cartElement", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "debugInput", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "proceedToCheckoutTaskInstance", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "proceedToCheckoutTaskInstance"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkout", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "checkout"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addMoreItems", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "addMoreItems"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startOrder", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "startOrder"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "edit", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearTapCount", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "clearTapCount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "click", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype)), _class));
  _exports.default = Cart;
});