define("mobile-web/components/r-animated-modal/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "modal": "_modal_119cw0",
    "slideUp": "_slideUp_119cw0",
    "open": "_open_119cw0",
    "closed": "_closed_119cw0",
    "fade": "_fade_119cw0"
  };
  _exports.default = _default;
});