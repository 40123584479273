define("mobile-web/components/r-custom-icon/component", ["exports", "@glint/environment-ember-loose/glimmer-component"], function (_exports, _glimmerComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CustomIcon extends _glimmerComponent.default {}

  _exports.default = CustomIcon;
});