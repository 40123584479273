define("mobile-web/services/focus", ["exports", "true-myth", "mobile-web/components/r-application-frame/component", "mobile-web/lib/a11y", "mobile-web/lib/utilities/_"], function (_exports, _trueMyth, _component, _a11y, _) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FocusService = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class FocusService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "device", _descriptor, this);

      this.lastFocus = _trueMyth.Maybe.nothing();
      this._handlers = [];
    }

    /**
     * Trap focus within a given element, and set focus within it.
     *
     * @param trapEl  The element within which to trap focus.
     * @param focusEl The first element to focus. If it is not supplied, the focus
     *                is set to `trapEl` instead.
     */
    addTrap(trapEl, focusEl = trapEl) {
      if (this._handlers.length) {
        const previousHandler = this._handlers[this._handlers.length - 1];
        document.removeEventListener('focus', previousHandler.fn, true);
      }

      const fn = (0, _a11y.handleFocus)(trapEl, focusEl);
      this._handlers = this._handlers.concat({
        el: trapEl,
        fn
      });
      document.addEventListener('focus', fn, true);
    }
    /**
     * Remove focus trap from an element.
     * @param trappedEl The previously focus-trapped element to untrap.
     */


    removeTrap(trappedEl) {
      this._handlers.filter(({
        el
      }) => el === trappedEl).forEach(({
        fn
      }) => document.removeEventListener('focus', fn, true));

      this._handlers = this._handlers.filter(({
        el
      }) => el !== trappedEl);
    }

    willDestroy() {
      super.willDestroy();

      for (const {
        fn
      } of this._handlers) {
        document.removeEventListener('focus', fn, true);
      }
    }
    /**
     * Set the focus item to return to at a later point.
     *
     * @param element the item to focus, if not the item currently focused in the
     *                document.
     */


    setLastFocus(element) {
      const focusEl = element || document.activeElement; // In theory, we should *always* be able to treat this as never being `null`
      // or `undefined`. In practice however, IE11 *does* appear to sometimes
      // allow `document.activeElement` to be `null` or `undefined`. Using
      // `Maybe.of` lets us handle this gracefully: in the normal case, it works
      // correctly, constructing `Just(focusEl)`, but when that bug happens (for
      // whatever reason?!) we are safe and do not throw an exception.

      Ember.set(this, 'lastFocus', _trueMyth.Maybe.of(focusEl));
    }
    /**
     * Return focus to the previously focused element.
     */


    focusLast() {
      const refocus = this.lastFocus.match({
        Just: el => () => {
          this.focus(el);
        },
        Nothing: () => _.noop
      });
      Ember.run.once(this, refocus);
    }

    resetFocus() {
      // VoiceOver has issues where focus can end up in the middle of the screen after a transition.
      // To workaround, we force focus onto the announcer after transition.
      if (this.device.isHybridIOS) {
        Ember.run.scheduleOnce('afterRender', this, this.resetVoiceOverFocus);
      }
    }

    resetVoiceOverFocus() {
      const max = 50;
      let i = 0;
      const interval = setInterval(() => {
        var _document$getElementB;

        if (i >= max) {
          clearInterval(interval);
        }

        const announcer = (_document$getElementB = document.getElementById(_component.ANNOUNCER_CONTAINER_ID)) == null ? void 0 : _document$getElementB.children[0];

        if (announcer) {
          this.focus(announcer);
          clearInterval(interval);
        }

        i++;
      }, 200);
    }

    focus(el) {
      if (el && 'focus' in el) {
        if (this.device.isIOS) {
          setVoiceOverFocus(el);
        } else {
          el.focus();
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "device", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "resetVoiceOverFocus", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "resetVoiceOverFocus"), _class.prototype)), _class));
  _exports.default = FocusService;

  // https://linkedlist.ch/ref/17/
  function setVoiceOverFocus(element) {
    const focusInterval = 10; // ms, time between function calls

    const focusTotalRepetitions = 10; // number of repetitions

    element.setAttribute('tabindex', '0');
    element.blur();
    let focusRepetitions = 0;
    const interval = window.setInterval(() => {
      element.focus();
      focusRepetitions++;

      if (focusRepetitions >= focusTotalRepetitions) {
        window.clearInterval(interval);
      }
    }, focusInterval);
  }
});