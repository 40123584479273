define("mobile-web/components/r-application-frame/component", ["exports", "@glimmer/component", "mobile-web/components/r-footer/component", "mobile-web/components/r-header/component", "mobile-web/lib/data", "mobile-web/lib/utilities/_", "mobile-web/services/notifications", "mobile-web/components/r-application-frame/style"], function (_exports, _component, _component2, _component3, _data, _, _notifications, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ANNOUNCER_CONTAINER_ID = _exports.APPLICATION_FRAME_ID = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const APPLICATION_FRAME_ID = 'root';
  _exports.APPLICATION_FRAME_ID = APPLICATION_FRAME_ID;
  const ANNOUNCER_CONTAINER_ID = 'olo-announcer';
  _exports.ANNOUNCER_CONTAINER_ID = ANNOUNCER_CONTAINER_ID;
  let ApplicationFrame = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, (_class = class ApplicationFrame extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "channel", _descriptor, this);

      _initializerDefineProperty(this, "loading", _descriptor2, this);

      _initializerDefineProperty(this, "orderCriteria", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "session", _descriptor5, this);

      _initializerDefineProperty(this, "title", _descriptor6, this);

      _initializerDefineProperty(this, "features", _descriptor7, this);

      _initializerDefineProperty(this, "media", _descriptor8, this);

      this.uniqueId = APPLICATION_FRAME_ID;
      this.announcerContainerId = ANNOUNCER_CONTAINER_ID;
      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get isLoading() {
      var _this$args$isLoading;

      return (_this$args$isLoading = this.args.isLoading) != null ? _this$args$isLoading : false;
    }

    get routeClass() {
      return this.loading.isSplash ? _style.default.index : undefined;
    }

    get headerState() {
      const currentRouteName = this.router.currentRouteName;

      if (currentRouteName === 'application' || currentRouteName === 'index') {
        return _component3.State.Home;
      } else if (/^secure\.checkout/.test(currentRouteName)) {
        return _component3.State.Checkout;
      }

      return _component3.State.Default;
    }

    get footerModel() {
      if (this.args.errorData) {
        return _data.Error.withValue(this.args.errorData.message);
      }

      if (!this.channel.current) {
        return new _data.Loading();
      }

      return _data.Loaded.withValue({
        location: _component2.Location.Bottom
      });
    }

    get headerModel() {
      var _this$args$onLogin, _this$args$onLogout, _this$channel$setting, _this$channel$setting2;

      if (this.args.errorData) {
        return _data.Error.withValue(this.args.errorData.message);
      }

      if (!this.channel.current) {
        return new _data.Loading();
      }

      return _data.Loaded.withValue({
        isRestrictedFlow: this.session.isRestrictedFlow,
        isEmbedded: this.session.isEmbeddedMode,
        loggedIn: this.session.isLoggedIn,
        onLogin: (_this$args$onLogin = this.args.onLogin) != null ? _this$args$onLogin : _.noop,
        onLogout: (_this$args$onLogout = this.args.onLogout) != null ? _this$args$onLogout : _.noop,
        pageTitle: this.title.pageTitle,
        showOffersInbox: (_this$channel$setting = (_this$channel$setting2 = this.channel.settings) == null ? void 0 : _this$channel$setting2.showOffersInbox) != null ? _this$channel$setting : false,
        signOnAllowed: this.session.signOnAllowed,
        state: this.headerState
      });
    } // Constructor
    // Other methods


    notificationsFilter(notification) {
      switch (notification.type) {
        case _notifications.NotificationType.CartTransferred:
        case _notifications.NotificationType.ProductAdded:
          return true;

        default:
          return false;
      }
    } // Tasks
    // Actions


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "loading", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "orderCriteria", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "features", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "media", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ApplicationFrame;
});