define("mobile-web/components/r-external-login-providers/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_wlmfp9",
    "loginProviders": "_loginProviders_wlmfp9"
  };
  _exports.default = _default;
});