define("mobile-web/lib/scroll", ["exports", "mobile-web/lib/dom"], function (_exports, _dom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = scroll;
  _exports.onScrollStop = onScrollStop;
  _exports.disableDocumentScroll = disableDocumentScroll;
  _exports.enableDocumentScroll = enableDocumentScroll;
  _exports.getScrollParent = void 0;

  function scroll(action) {
    let ticking = false;
    return () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          action();
          ticking = false;
        });
        ticking = true;
      }
    };
  }

  function onScrollStop(el, action) {
    let timer;

    const onScroll = () => {
      Ember.run.cancel(timer);
      timer = Ember.run.later(() => {
        el.removeEventListener('scroll', onScroll);
        action();
      }, 100);
    };

    onScroll();
    el.addEventListener('scroll', onScroll);
  }

  function disableDocumentScroll() {
    const documentScroll = document.documentElement.scrollTop;
    document.documentElement.classList.add('disable-scroll');
    document.body.scrollTop = documentScroll;
  }

  function enableDocumentScroll() {
    const bodyScroll = document.body.scrollTop;
    document.documentElement.classList.remove('disable-scroll');
    document.documentElement.scrollTop = bodyScroll;
  }

  const scrollParents = new WeakMap();
  /**
   * Get the first scrollable parent of the specified element
   *
   * @param el the element
   * @returns the scrollable parent element
   */

  const getScrollParent = el => {
    if (scrollParents.has(el)) {
      const scrollParent = scrollParents.get(el);

      if (scrollParent) {
        return scrollParent;
      }
    }

    for (const parent of (0, _dom.getParents)(el)) {
      if (parent.scrollHeight > parent.clientHeight) {
        scrollParents.set(el, parent);
        return parent;
      }
    }

    return undefined;
  };

  _exports.getScrollParent = getScrollParent;
});