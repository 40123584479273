define("mobile-web/components/r-header-button/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "minor": "_minor_1cjn7h",
    "major": "_major_1cjn7h",
    "buttonContent": "_buttonContent_1cjn7h",
    "label": "_label_1cjn7h",
    "small": "_small_1cjn7h _label_1cjn7h",
    "large": "_large_1cjn7h _label_1cjn7h",
    "iconWrapper": "_iconWrapper_1cjn7h",
    "icon": "_icon_1cjn7h",
    "left": "_left_1cjn7h _iconWrapper_1cjn7h",
    "right": "_right_1cjn7h _iconWrapper_1cjn7h"
  };
  _exports.default = _default;
});