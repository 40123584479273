define("mobile-web/helpers/format-dayjs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(([value, format]) => value.format(format));

  _exports.default = _default;
});