define("mobile-web/controllers/order-controller", ["exports", "mobile-web/services/user-feedback"], function (_exports, _userFeedback) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OrderController = (_dec = Ember.inject.service('user-feedback'), _dec2 = Ember.inject.service('vendor'), _dec3 = Ember._action, (_class = class OrderController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "userFeedback", _descriptor, this);

      _initializerDefineProperty(this, "vendor", _descriptor2, this);
    }

    onCancelOrder() {
      this.userFeedback.add({
        type: _userFeedback.Type.Neutral,
        title: 'Cancellation Complete',
        message: 'Your order has been successfully cancelled.'
      });
      this.transitionToRoute('application');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "userFeedback", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "vendor", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onCancelOrder", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onCancelOrder"), _class.prototype)), _class));
  _exports.default = OrderController;
});