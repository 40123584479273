define("mobile-web/serializers/upsell-item", ["exports", "ember-data", "mobile-web/lib/utilities/is-some", "mobile-web/lib/utilities/numeric", "mobile-web/models/vendor"], function (_exports, _emberData, _isSome, _numeric, _vendor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UpsellItemSerializer extends _emberData.default.JSONSerializer {
    normalize(modelClass, resourceHash) {
      var _resourceHash$labels;

      if ((0, _isSome.default)(resourceHash.cost)) {
        resourceHash.cost = (0, _numeric.currencyToNumber)(resourceHash.cost);
      }

      if (Ember.isNone(resourceHash.quantity)) {
        resourceHash.quantity = 0;
      }

      (_resourceHash$labels = resourceHash.labels) == null ? void 0 : _resourceHash$labels.forEach(label => {
        if (label.image && !label.icon) {
          let icon = {
            id: '',
            alt: '',
            name: label.code,
            url: label.image,
            height: 0,
            width: 0
          };

          switch (label.code) {
            case _vendor.LabelCode.NYC:
              //Need to hard code id here to match types. Should be ok, we never reference id in ember data.
              icon.id = 2;
              icon.alt = 'High Sodium';
              icon.height = 20;
              icon.width = 20;
              break;

            case _vendor.LabelCode.PHL:
              icon.id = 4;
              icon.alt = 'PHL High Sodium';
              icon.height = 15;
              icon.width = 121;
              break;

            case _vendor.LabelCode.Prop65:
              icon.id = 3;
              icon.alt = 'Toxic - California Prop 65';
              icon.height = 20;
              icon.width = 20;
              break;

            default:
              icon = undefined;
              break;
          }

          if (icon) {
            label.icon = icon;
            delete label.image;
          }
        }
      });
      return super.normalize(modelClass, resourceHash);
    }

  }

  _exports.default = UpsellItemSerializer;
});