define("mobile-web/templates/menu/vendor/rewards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EqF6P8lU",
    "block": "[[[8,[39,0],null,[[\"@vendor\",\"@accounts\",\"@showInfoModal\"],[[30,0,[\"model\",\"vendor\"]],[30,0,[\"model\",\"accounts\"]],[30,0,[\"showInfoModal\"]]]],null]],[],false,[\"routes/menu/vendor/rewards-route\"]]",
    "moduleName": "mobile-web/templates/menu/vendor/rewards.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});