define("mobile-web/serializers/upsell-group", ["exports", "ember-data", "mobile-web/decorators/persistent"], function (_exports, _emberData, _persistent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let UpsellGroupSerializer = (_dec = (0, _persistent.default)(), _dec(_class = class UpsellGroupSerializer extends _emberData.default.JSONSerializer.extend(_emberData.default.EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);
      this.attrs = {
        items: {
          embedded: 'always'
        }
      };
    }

    normalize(modelClass, resourceHash) {
      return super.normalize(modelClass, resourceHash);
    }

  }) || _class);
  _exports.default = UpsellGroupSerializer;
});