define("mobile-web/routes/rewards-search", ["exports", "mobile-web/decorators/authenticated", "mobile-web/lib/computed", "mobile-web/routes/vendor-search-results"], function (_exports, _authenticated, _computed, _vendorSearchResults) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let RewardsSearchRoute = (_dec = Ember.inject.service, _dec2 = Ember._action, (0, _authenticated.default)(_class = (_class2 = class RewardsSearchRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "vendor", _descriptor, this);

      this.titleToken = 'Choose a Location to View Offers';

      _initializerDefineProperty(this, "localVendorSearchParams", _descriptor2, this);
    }

    beforeModel(transition) {
      super.beforeModel(transition);
      const isNewSearch = transition.to.queryParams.hasOwnProperty('search') && transition.to.queryParams.search === 'new';
      const vendorService = this.vendor;

      if (isNewSearch) {
        Ember.set(vendorService, 'vendor', undefined);
        return true;
      }

      const currentVendor = Ember.get(vendorService, 'vendor');

      if (!Ember.isNone(currentVendor)) {
        transition.abort();
        this.transitionTo('menu.vendor.rewards', currentVendor.slug);
      }

      return true;
    }

    model() {
      return _vendorSearchResults.search.call(this);
    }

    searchDidChange() {
      this.refresh();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "vendor", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "localVendorSearchParams", [_computed.local], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "searchDidChange", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "searchDidChange"), _class2.prototype)), _class2)) || _class);
  _exports.default = RewardsSearchRoute;
});