define("mobile-web/routes/open-source-licenses", ["exports", "mobile-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class OpenSourceLicensesRoute extends Ember.Route {
    constructor(...args) {
      super(...args);
      this.titleToken = 'Open Source Licenses';
    }

    async model() {
      const licensesResponse = await fetch(`${_environment.default.scriptBaseUrl}assets/open-source-licenses.json?t=${Date.now()}`);
      return JSON.parse(await licensesResponse.text());
    }

  }

  _exports.default = OpenSourceLicensesRoute;
});