define("mobile-web/components/r-order-criteria-btn/component", ["exports", "@glimmer/component", "mobile-web/lib/a11y", "mobile-web/lib/order-criteria", "mobile-web/lib/utilities/_", "mobile-web/components/r-order-criteria-btn/style"], function (_exports, _component, _a11y, _orderCriteria, _, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OrderCriteriaBtn = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed('args.searchOnly', 'orderCriteria.{basketOrderCriteria,criteria,searchOrderCriteria}'), _dec7 = Ember._action, (_class = class OrderCriteriaBtn extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "mwcIntl", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "orderCriteria", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "vendor", _descriptor5, this);

      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get criteria() {
      var _ref;

      return (_ref = this.args.searchOnly ? this.orderCriteria.searchOrderCriteria : this.orderCriteria.basketOrderCriteria) != null ? _ref : this.orderCriteria.criteria;
    }

    get orderCriteriaUninitialized() {
      var _this$criteria$isDefa;

      return (_this$criteria$isDefa = this.criteria.isDefault) != null ? _this$criteria$isDefa : false;
    }

    get isAdvance() {
      return (0, _orderCriteria.isAdvance)(this.criteria);
    }

    get isAtStore() {
      return (0, _orderCriteria.isAtStore)(this.criteria);
    }

    get isDelivery() {
      return (0, _orderCriteria.isDelivery)(this.criteria);
    }

    get isUnknownTime() {
      return (0, _orderCriteria.isAdvance)(this.criteria) && !this.criteria.timeWanted;
    }

    get location() {
      var _this$criteria$delive, _this$criteria$delive2;

      return (0, _orderCriteria.isDelivery)(this.criteria) ? (0, _.compact)([(_this$criteria$delive = this.criteria.deliveryAddress) == null ? void 0 : _this$criteria$delive.streetAddress, (_this$criteria$delive2 = this.criteria.deliveryAddress) == null ? void 0 : _this$criteria$delive2.building]).join(', ') : (0, _orderCriteria.isAtStore)(this.criteria) ? this.criteria.searchAddress : '';
    }

    get currentVendor() {
      return !this.args.searchOnly ? this.vendor.vendor : undefined;
    }

    get deliveryAddress() {
      var _this$criteria$delive3, _this$criteria$delive4;

      return (0, _orderCriteria.isDelivery)(this.criteria) ? (_this$criteria$delive3 = (_this$criteria$delive4 = this.criteria.deliveryAddress) == null ? void 0 : _this$criteria$delive4.streetAddress) != null ? _this$criteria$delive3 : '' : '';
    }

    get ariaLabel() {
      const baseLabel = this.intl.t('mwc.orderCriteria.editAriaLabel');

      if (this.orderCriteriaUninitialized) {
        return baseLabel;
      }

      const m = this.criteria;
      const a = (0, _a11y.splitNumbers)(this.location);
      const handoff = `${(0, _orderCriteria.getHandoffLabel)(m.handoffMode, this.store)} ${a}`;
      const time = (0, _orderCriteria.isAdvance)(m) && m.timeWanted ? this.mwcIntl.relativeDateTime(m.timeWanted) : (0, _orderCriteria.getTimeWantedTypeLabel)(m.timeWantedType, this.store);
      return `${baseLabel}, ${handoff}, ${time}`;
    } // Constructor
    // Other methods
    // Tasks
    // Actions


    changeLocation() {
      this.orderCriteria.set('showAddressModal', true);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "mwcIntl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "orderCriteria", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "vendor", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "criteria", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "criteria"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeLocation", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "changeLocation"), _class.prototype)), _class));
  _exports.default = OrderCriteriaBtn;
});