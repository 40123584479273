define("mobile-web/lib/hybrid-util", ["exports", "@ionic-native/http", "mobile-web/config/environment"], function (_exports, _http, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mapFetch = mapFetch;
  _exports.channelSlug = channelSlug;
  _exports.host = host;
  _exports.fetchOAuthCallback = fetchOAuthCallback;
  _exports.fetchNativeAppleLogin = fetchNativeAppleLogin;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function channelSlug() {
    return window.Olo.channelSlug;
  }

  function host() {
    return _environment.default.isHybrid ? window.Olo.hybridAppHost : '';
  }

  function mapFetchResponse(resp, redirected) {
    const {
      status,
      headers = {},
      url,
      data,
      error
    } = resp;
    const body = error || data;
    const parsedHeaders = new Headers(Object.entries(headers));
    const ok = status >= 200 && status < 300;

    const _blob = new Blob([data]);

    const response = {
      _bodyText: body,
      _bodyInit: body,
      _bodyBlob: _blob,
      status,
      headers: parsedHeaders,
      url,
      body,
      bodyUsed: body ? true : false,
      arrayBuffer: () => Promise.reject(new Error('arrayBuffer not supported by the hybrid app.')),
      blob: () => Promise.resolve(_blob),
      formData: () => Promise.reject(new Error('formData not supported by the hybrid app.')),
      json: () => JSON.parse(body),
      text: () => body,
      ok,
      redirected,
      statusText: ok ? 'OK' : '',
      clone: () => _extends({}, response),
      trailer: Promise.resolve(parsedHeaders),
      type: 'basic'
    };
    return response;
  }

  function handleFetchResponse(resp, verb, body, headers, redirected = false) {
    if (resp.status > 300 && resp.status < 310) {
      return runFetch(verb, resp.headers.location, body, headers, true);
    }

    return mapFetchResponse(resp, redirected);
  }

  function runFetch(verb, url, body, headers, redirected = false) {
    return _http.HTTP[verb](url, body, headers).then(resp => handleFetchResponse(resp, verb, body, headers, redirected)).catch(resp => handleFetchResponse(resp, verb, body, headers, redirected));
  }

  async function fetchOAuthCallback(url, isAppleCallback) {
    _http.HTTP.setFollowRedirect(false);

    _http.HTTP.setCookie(window.Olo.hybridAppHost, 'serve_netcore=1');

    try {
      return await (isAppleCallback ? _http.HTTP.post(url, {}, {}) : _http.HTTP.get(url, {}, {}));
    } catch (e) {
      if (!e || e.status !== 302) {
        throw new Error('OAuth callback failed');
      }

      return undefined;
    }
  }

  async function fetchNativeAppleLogin(identityToken, firstName, lastName, state) {
    _http.HTTP.setDataSerializer('json');

    const searchParams = firstName && lastName ? new URLSearchParams({
      identityToken,
      user: JSON.stringify({
        name: {
          firstName,
          lastName
        }
      }),
      state
    }).toString() : new URLSearchParams({
      identityToken,
      state
    }).toString();
    return await _http.HTTP.post(`${window.Olo.hybridAppHost}/user/oauthcallback?${searchParams}`, {}, {
      'X-Olo-Request': '1'
    });
  }

  async function mapFetch({
    method,
    headers,
    body,
    url
  }) {
    // Dirty hack to avoid a race condition in Capacitor
    // If the plugin is called before Capacitor's injected scripts have finished running, it will explode
    // Create a microtask and wait for it to run before calling the plugin
    // For some reason this only happens when loading assets from s3, instead of localhost
    // Maybe Capacitor's internal proxy already accounts for this case?
    // @todo: look at how to remove this because it adds an extra frame to every HTTP request
    await new Promise(resolve => setTimeout(resolve, 0));

    _http.HTTP.setDataSerializer('json');

    _http.HTTP.setFollowRedirect(false);

    _http.HTTP.setCookie(window.Olo.hybridAppHost, 'serve_netcore=1');

    const verb = method == null ? void 0 : method.toLowerCase();

    if (!['post', 'put', 'patch'].includes(verb)) {
      const query = url.match(/([^?]{1,})([?]{1}([^?]{1,}))/gi);

      if (query && query[1]) {
        url = query[0];
        body = query[1];
      }
    } else {
      const origBody = body;
      body = body && typeof body === 'string' ? JSON.parse(body) : body || {}; // Undo Ember's serialization

      if (typeof body !== 'object') {
        body = origBody; // the JSON parsed body will have had all its quotes removed, so put it back

        _http.HTTP.setDataSerializer('utf8');
      }
    }

    return runFetch(verb, url, body, _extends({}, headers, {
      'X-PX-COOKIE': localStorage.getItem('pxcookie') || '',
      // This localstorage item gets set in challenge.ts after a user passes a PX challenge
      'User-Agent': navigator.userAgent // This is set for the browser, but needs to be reused with this plugin

    }));
  }
});