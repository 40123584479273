define("mobile-web/routes/secure/checkout", ["exports", "@olo/pay", "mobile-web/components/r-digital-wallets-button/component", "mobile-web/config/environment"], function (_exports, _pay, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let _class = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class2 = class _class2 extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      _initializerDefineProperty(this, "device", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "vendor", _descriptor4, this);
    }

    beforeModel(transition) {
      if (Ember.isNone(this.session.user)) {
        this.transitionTo('secure.checkout.auth');
      } else if (Ember.isNone(this.basket.basket)) {
        // Abort can be removed after MWC-2631
        transition.abort();
        this.transitionTo('application');
      }
    }

    async model() {
      var _this$vendor$vendor;

      // Clear out the isEditingOrder flag, since we aren't editing anymore
      this.session.set('isEditingOrder', undefined); // Ensure billing details are loaded in the model hook

      const bd = await this.basket.basket.billingDetails;
      /**
       * Determines if `CreditCardElements` and `DigitalWallets` can be used.
       * This will replace the regular credit card inputs with iframe inputs
       * and show a Google Pay (Chrome) or Apple Pay (Safari) payment button
       * as a method at checkout.
       */

      const useOloPay = !!((_this$vendor$vendor = this.vendor.vendor) != null && _this$vendor$vendor.settings.isOloPay); // Olo Pay digital wallets do not work on Serve Apps

      const tryDigitalWallet = useOloPay && !this.device.isHybrid;

      if (tryDigitalWallet) {
        const schemes = bd.get('billingSchemes');
        const digitalWallets = new _pay.DigitalWallets(_environment.default.STRIPE_ENVIRONMENT);
        const canRender = await digitalWallets.canRenderButton();
        const applePayScheme = schemes.find(s => {
          var _s$customSettings;

          return (_s$customSettings = s.customSettings) == null ? void 0 : _s$customSettings.some(setting => setting.name === 'service' && setting.value === _component.DigitalWalletType.ApplePay);
        });
        const googlePayScheme = schemes.find(s => {
          var _s$customSettings2;

          return (_s$customSettings2 = s.customSettings) == null ? void 0 : _s$customSettings2.some(setting => setting.name === 'service' && setting.value === _component.DigitalWalletType.GooglePay);
        });
        const useApplePay = Boolean((canRender == null ? void 0 : canRender.applePay) && applePayScheme); // Safari

        const useGooglePay = Boolean((canRender == null ? void 0 : canRender.googlePay) && googlePayScheme); // Chrome

        if (useApplePay) {
          var _applePayScheme$id;

          return {
            useOloPay,
            digitalWallets,
            digitalWalletType: _component.DigitalWalletType.ApplePay,
            digitalWalletId: (_applePayScheme$id = applePayScheme == null ? void 0 : applePayScheme.id) != null ? _applePayScheme$id : ''
          };
        }

        if (useGooglePay) {
          var _googlePayScheme$id;

          return {
            useOloPay,
            digitalWallets,
            digitalWalletType: _component.DigitalWalletType.GooglePay,
            digitalWalletId: (_googlePayScheme$id = googlePayScheme == null ? void 0 : googlePayScheme.id) != null ? _googlePayScheme$id : ''
          };
        }
      }

      return {
        useOloPay
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "basket", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "device", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "vendor", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));

  _exports.default = _class;
});