define("mobile-web/models/billing-membership", ["exports", "ember-data", "mobile-web/decorators/member-action"], function (_exports, _emberData, _memberAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CASH_MEMBERSHIP_ID = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * For Cash schemes, there is always 1 membership, and it has a special hardcoded ID of 'Cash'.
   */
  const CASH_MEMBERSHIP_ID = 'Cash';
  _exports.CASH_MEMBERSHIP_ID = CASH_MEMBERSHIP_ID;
  let BillingMembership = (_dec = Ember.inject.service, _dec2 = _emberData.default.attr('string'), _dec3 = _emberData.default.belongsTo('billing-scheme', {
    async: false
  }), _dec4 = _emberData.default.attr('string'), _dec5 = _emberData.default.attr('string'), _dec6 = _emberData.default.attr('boolean'), _dec7 = _emberData.default.attr('boolean'), _dec8 = _emberData.default.attr('number'), _dec9 = _emberData.default.attr('string'), _dec10 = _emberData.default.attr('boolean'), _dec11 = _emberData.default.attr('string'), _dec12 = _emberData.default.attr('string'), _dec13 = _emberData.default.attr('string'), _dec14 = _emberData.default.attr('string'), _dec15 = _emberData.default.attr('string'), _dec16 = _emberData.default.attr('boolean'), _dec17 = (0, _memberAction.default)({
    type: 'put',
    path: 'setdefault',

    before() {
      return {
        isDefault: true
      };
    },

    async after() {
      await this.store.findAll('billing-membership', {
        reload: true
      });
    }

  }), (_class = class BillingMembership extends _emberData.default.Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "description", _descriptor2, this);

      _initializerDefineProperty(this, "billingScheme", _descriptor3, this);

      _initializerDefineProperty(this, "notes", _descriptor4, this);

      _initializerDefineProperty(this, "cardType", _descriptor5, this);

      _initializerDefineProperty(this, "canRemove", _descriptor6, this);

      _initializerDefineProperty(this, "canCheckBalance", _descriptor7, this);

      _initializerDefineProperty(this, "balance", _descriptor8, this);

      _initializerDefineProperty(this, "zip", _descriptor9, this);

      _initializerDefineProperty(this, "isDefault", _descriptor10, this);

      _initializerDefineProperty(this, "streetAddress1", _descriptor11, this);

      _initializerDefineProperty(this, "streetAddress2", _descriptor12, this);

      _initializerDefineProperty(this, "city", _descriptor13, this);

      _initializerDefineProperty(this, "state", _descriptor14, this);

      _initializerDefineProperty(this, "country", _descriptor15, this);

      _initializerDefineProperty(this, "isGiftCard", _descriptor16, this);

      _initializerDefineProperty(this, "makeDefault", _descriptor17, this);
    }

    async tryLoadBalance() {
      if (this.isGiftCard && this.canCheckBalance) {
        const giftCard = await this.store.adapterFor('billing-membership').getGiftCard(this.id);
        this.balance = giftCard.balance;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "billingScheme", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "notes", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "cardType", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "canRemove", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "canCheckBalance", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "balance", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "zip", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isDefault", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "streetAddress1", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "streetAddress2", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "city", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "state", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "country", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "isGiftCard", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "makeDefault", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BillingMembership;
});