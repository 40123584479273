define("mobile-web/components/r-application-frame/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "announcer": "_announcer_n4p923",
    "root": "_root_n4p923",
    "body": "_body_n4p923",
    "index": "_index_n4p923",
    "footer": "_footer_n4p923",
    "notifications": "_notifications_n4p923"
  };
  _exports.default = _default;
});