define("mobile-web/components/r-application-loading/auth/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_ko8e1z",
    "mainHeader": "_mainHeader_ko8e1z",
    "subHeader": "_subHeader_ko8e1z",
    "input": "_input_ko8e1z",
    "button": "_button_ko8e1z",
    "form": "_form_ko8e1z",
    "isCreateAccount": "_isCreateAccount_ko8e1z",
    "isCheckoutAuth": "_isCheckoutAuth_ko8e1z"
  };
  _exports.default = _default;
});