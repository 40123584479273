define("mobile-web/adapters/vendor", ["exports", "mobile-web/lib/hybrid-util", "mobile-web/adapters/application"], function (_exports, _hybridUtil, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class VendorAdapter extends _application.default {
    shouldBackgroundReloadRecord(_store, snapshot) {
      /* Reload if there is a vendor record but not associated Menu Data */
      return Ember.isEmpty(snapshot.record.categories);
    }

    urlForQueryRecord(query) {
      return `${(0, _hybridUtil.host)()}/${this.namespace}/vendors/${query.slug}`;
    } // Strips out the slug from the query params


    sortQueryParams() {
      return [];
    }

  }

  _exports.default = VendorAdapter;
});