define("mobile-web/components/r-collapse/component", ["exports", "@glimmer/component", "ember-animated/transitions/fade", "mobile-web/lib/animation", "mobile-web/components/r-collapse/style"], function (_exports, _component, _fade, _animation, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Collapse = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class Collapse extends _component.default {
    // Service injections
    // Passthroughs
    // Tracked properties
    // Getters and setters
    get canToggle() {
      var _this$args$canToggle;

      return (_this$args$canToggle = this.args.canToggle) != null ? _this$args$canToggle : true;
    }

    get expanded() {
      var _this$args$expanded;

      return (_this$args$expanded = this.args.expanded) != null ? _this$args$expanded : this.localExpanded;
    }

    get headingLevel() {
      var _this$args$headingLev;

      return (_this$args$headingLev = this.args.headingLevel) != null ? _this$args$headingLev : 2;
    }

    get hideToggle() {
      var _this$args$hideToggle;

      return (_this$args$hideToggle = this.args.hideToggle) != null ? _this$args$hideToggle : false;
    }

    get testSelector() {
      var _this$args$testSelect;

      return (_this$args$testSelect = this.args.testSelector) != null ? _this$args$testSelect : '';
    } // Constructor


    constructor(owner, args) {
      var _this$args$startExpan;

      super(owner, args);

      _initializerDefineProperty(this, "focusManager", _descriptor, this);

      this.animationDuration = (0, _animation.getDuration)(200);
      this.style = _style.default;
      this.transition = _fade.default;

      _initializerDefineProperty(this, "localExpanded", _descriptor2, this);

      this.localExpanded = (_this$args$startExpan = this.args.startExpanded) != null ? _this$args$startExpan : false;
    } // Other methods
    // Tasks
    // Actions


    deregister() {
      if (this.args.focusCategory && this.focusData) {
        this.focusManager.deregister(this.args.focusCategory, this.focusData);
      }
    }

    register() {
      if (this.args.focusCategory) {
        // Disabled buttons can't receive focus, so focus the heading instead
        const getTarget = () => {
          var _this$button;

          return (_this$button = this.button) != null && _this$button.disabled ? this.heading : this.button;
        };

        this.focusData = {
          allowFocus: this.args.allowFocus,
          onFocus: this.args.onFocus,

          get target() {
            return getTarget();
          }

        };
        this.focusManager.register(this.args.focusCategory, this.focusData);
      }
    }

    setButton(button) {
      this.button = button;
    }

    setHeading(heading) {
      this.heading = heading;
    }

    toggle() {
      if (this.args.onToggle) {
        this.args.onToggle(!this.args.expanded);
      } else {
        this.localExpanded = !this.localExpanded;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "focusManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "localExpanded", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "deregister", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "deregister"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "register", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "register"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setButton", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setButton"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setHeading", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setHeading"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggle", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype)), _class));
  _exports.default = Collapse;
});