define("mobile-web/instance-initializers/splash-screen", ["exports", "mobile-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  async function initialize() {
    if (_environment.default.isHybrid) {
      const {
        SplashScreen
      } = await emberAutoImportDynamic("@capacitor/splash-screen");
      SplashScreen.hide();
    }
  }

  var _default = {
    name: 'splash-screen',
    initialize
  };
  _exports.default = _default;
});