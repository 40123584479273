define("mobile-web/components/r-skeleton-image/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "mobile-web/components/r-skeleton-image/style"], function (_exports, _glimmerComponent, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SkeletonImage = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = class SkeletonImage extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;

      _initializerDefineProperty(this, "imageLoaded", _descriptor, this);

      _initializerDefineProperty(this, "imageClass", _descriptor2, this);
    }

    // Getters and setters
    // Constructor
    // Other methods
    // Tasks
    // Actions
    setImageLoaded() {
      this.imageLoaded = true;
      this.imageClass = this.args.parentClass;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "imageLoaded", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "imageClass", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return `${this.args.parentClass} ${this.style.hidden}`;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setImageLoaded", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setImageLoaded"), _class.prototype)), _class));
  _exports.default = SkeletonImage;
});