define("mobile-web/components/r-static-page/component", ["exports", "@ember-decorators/component", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-static-page/style"], function (_exports, _component, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let StaticPage = (_dec = (0, _component.tagName)(''), _dec(_class = class StaticPage extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    }

    init() {
      super.init();
      (false && !((0, _isSome.isSome)(this.model)) && Ember.assert('`model` is required', (0, _isSome.isSome)(this.model)));
    }

  }) || _class);
  _exports.default = StaticPage;
});