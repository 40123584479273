define("mobile-web/components/r-post-checkout/r-subsection/r-button-container/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_13l7wp"
  };
  _exports.default = _default;
});