define("mobile-web/components/routes/menu/category-route/component", ["exports", "@ember-decorators/component", "mobile-web/lib/utilities/is-some", "mobile-web/services/global-events", "mobile-web/components/routes/menu/category-route/style"], function (_exports, _component, _isSome, _globalEvents, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MenuCategoryRoute = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember.computed('category.products.@each.isLoaded'), _dec7 = Ember.computed('category.products', 'products.@each.isAvailable', 'storage.showFullMenu'), _dec8 = Ember.computed('category.name', 'category.vendor.{name,slug}'), _dec9 = Ember._action, _dec(_class = (_class2 = class MenuCategoryRoute extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "channel", _descriptor, this);

      _initializerDefineProperty(this, "storage", _descriptor2, this);

      _initializerDefineProperty(this, "globalEvents", _descriptor3, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "productCards", _descriptor4, this);

      this.previouslyVisibleProductIds = [];
    }

    // Computed properties
    get isLoaded() {
      return this.category.products.every(p => p.isLoaded);
    }

    get visibleProducts() {
      return this.category.products.filter(p => this.storage.showFullMenu || p.isAvailable);
    }

    get breadcrumbs() {
      const vendor = this.category.vendor;
      return [{
        label: `${vendor.name} Menu`,
        route: 'menu.vendor',
        models: [vendor.slug]
      }, {
        label: this.category.name
      }];
    } // Init


    init() {
      super.init();
      (false && !((0, _isSome.default)(this.category)) && Ember.assert('`category` is required', (0, _isSome.default)(this.category)));
    } // Other methods
    // Actions


    handleProductsVisible(visible) {
      const [products, ids] = visible.reduce((acc, el) => {
        var _this$visibleProducts;

        const idx = el.getAttribute('data-idx');
        const product = (0, _isSome.default)(idx) && ((_this$visibleProducts = this.visibleProducts.objectAt(+idx)) == null ? void 0 : _this$visibleProducts.serializeForGlobalData());

        if (product && !this.previouslyVisibleProductIds.includes(product.id)) {
          acc[0].push(product);
          acc[1].push(product.id);
        }

        return acc;
      }, [[], []]);
      this.previouslyVisibleProductIds = this.previouslyVisibleProductIds.concat(ids);

      if (products.length) {
        this.globalEvents.trigger(_globalEvents.GlobalEventName.ProductsVisible, products);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "channel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "storage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "globalEvents", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "productCards", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "isLoaded", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "isLoaded"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "visibleProducts", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "visibleProducts"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "breadcrumbs", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "breadcrumbs"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleProductsVisible", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "handleProductsVisible"), _class2.prototype)), _class2)) || _class);
  _exports.default = MenuCategoryRoute;
});