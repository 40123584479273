define("mobile-web/decorators/persistent", ["exports", "mobile-web/config/environment", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/device-detect", "mobile-web/services/storage"], function (_exports, _environment, _, _deviceDetect, _storage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Tells a serializer to store off any data received in a local indexedDB database,
   * which can then be rehydrated into the store on a hard reload.
   *
   * Due to implementation restrictions, if you are using any mixins at all that
   * override `normalize` (such as EmbeddedRecordsMixin), you *must* also have
   * `normalize` overridden in your serializer, even if it only calls `super.normalize`.
   */
  var _default = (options = {}) => serializer => {
    const original = serializer.prototype.normalize; // @ts-ignore - type of primaryModelClass is incorrect in DefinitelyTyped

    serializer.prototype.normalize = function (modelClass, ogResourceHash, prop) {
      const features = Ember.getOwner(this).lookup('service:features');

      if (features.flags['persistent-storage'] && !_deviceDetect.IS_IE && !_environment.default.isHybrid) {
        const resourceHash = (0, _.cloneDeep)(ogResourceHash);
        const modelName = modelClass.modelName.toString();
        const storage = Ember.getOwner(this).lookup('service:storage');
        const indexeddb = Ember.getOwner(this).lookup('service:indexeddb');
        storage.dbDataCache.then(async db => {
          if (db) {
            var _await$indexeddb$getO;

            const dataCacheStore = indexeddb.start(db, _storage.STORE_NAME, 'readwrite');
            const modelMap = (_await$indexeddb$getO = await indexeddb.getObject(dataCacheStore, modelName)) != null ? _await$indexeddb$getO : {};
            const key = String(resourceHash[this.primaryKey]).toUpperCase();
            modelMap[key] = {
              options,
              data: resourceHash
            };
            indexeddb.put(dataCacheStore, modelName, modelMap);
          }
        }).catch(err => {
          // Swallow the error so the user does not break over caching issues
          const error = Ember.getOwner(this).lookup('service:error');
          error == null ? void 0 : error.sendExternalError(err, undefined, ['persistent-storage']);
        });
      }

      return original.call(this, modelClass, ogResourceHash, prop);
    };
  };

  _exports.default = _default;
});