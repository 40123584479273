define("mobile-web/components/r-post-checkout/r-order-location/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "button": "_button_16t7i6"
  };
  _exports.default = _default;
});