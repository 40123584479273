define("mobile-web/adapters/order-search-result", ["exports", "mobile-web/adapters/application", "mobile-web/adapters/order"], function (_exports, _application, _order) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class OrderSearchResultAdapter extends _application.default {
    urlForFindAll() {
      const baseUrl = this.buildURL('orders');
      return `${baseUrl}/recent`;
    }

    buildURL(_modelName, id, _snapshot, requestType) {
      const url = (0, _order.buildOrderURL)(this.namespace, requestType, id);
      return url != null ? url : super.buildURL(...arguments);
    }

    shouldBackgroundReloadAll() {
      return false;
    }

  }

  _exports.default = OrderSearchResultAdapter;
});