define("mobile-web/components/r-product-customization/top-level-option-groups/component", ["exports", "@glimmer/component", "mobile-web/lib/menu", "mobile-web/lib/utilities/_", "mobile-web/components/r-product-customization/top-level-option-groups/style"], function (_exports, _component, _menu, _, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProductCustomizationTopLevelOptionGroups = (_dec = Ember._tracked, (_class = class ProductCustomizationTopLevelOptionGroups extends _component.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;

      _initializerDefineProperty(this, "openFormationGroup", _descriptor, this);
    }

    // Getters and setters
    get inModal() {
      var _this$args$inModal;

      return (_this$args$inModal = this.args.inModal) != null ? _this$args$inModal : false;
    }

    get groupedOptionGroups() {
      return (0, _menu.groupOptionGroups)(this.args.optionGroups);
    }

    get hasFormationGroups() {
      return this.args.optionGroups.find(og => !(0, _.isEmpty)(og.formationGroup));
    }

    get isLoaded() {
      return this.args.optionGroups.every(og => og.isLoaded);
    }

    get useStickyNav() {
      return this.args.optionGroups.length > 1 && !this.inModal && !this.hasFormationGroups && this.isLoaded;
    } // Constructor
    // Other methods
    // Tasks
    // Actions


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "openFormationGroup", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  })), _class));
  _exports.default = ProductCustomizationTopLevelOptionGroups;
});