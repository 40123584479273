define("mobile-web/components/r-application-loading/search/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "header": "_header_1mjlgv",
    "grid": "_grid_1mjlgv",
    "cardTop": "_cardTop_1mjlgv",
    "cardBottom": "_cardBottom_1mjlgv"
  };
  _exports.default = _default;
});