define("mobile-web/components/r-button/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "button": "_button_1mqy5a",
    "highlight": "_highlight_1mqy5a",
    "main": "_main_1mqy5a",
    "minor": "_minor_1mqy5a",
    "destructive": "_destructive_1mqy5a",
    "softDisabled": "_softDisabled_1mqy5a",
    "hasHighlightContent": "_hasHighlightContent_1mqy5a",
    "text": "_text_1mqy5a",
    "animatedContainer": "_animatedContainer_1mqy5a",
    "icon": "_icon_1mqy5a",
    "newIconFormat": "_newIconFormat_1mqy5a",
    "iconSvg": "_iconSvg_1mqy5a",
    "size-100": "_size-100_1mqy5a",
    "size-200": "_size-200_1mqy5a",
    "size-300": "_size-300_1mqy5a",
    "size-400": "_size-400_1mqy5a",
    "size-500": "_size-500_1mqy5a",
    "size-600": "_size-600_1mqy5a",
    "size-700": "_size-700_1mqy5a",
    "size-800": "_size-800_1mqy5a",
    "size-900": "_size-900_1mqy5a",
    "size-1000": "_size-1000_1mqy5a",
    "fullWidth": "_fullWidth_1mqy5a"
  };
  _exports.default = _default;
});