define("mobile-web/components/r-popover-item/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "mobile-web/components/r-popover-item/style"], function (_exports, _glimmerComponent, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PopoverItem extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    }

  }

  _exports.default = PopoverItem;
});