define("mobile-web/components/r-checkout/payment/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_waikgc",
    "heading": "_heading_waikgc",
    "section": "_section_waikgc",
    "button": "_button_waikgc",
    "splitTotals": "_splitTotals_waikgc",
    "amount": "_amount_waikgc",
    "goodAmount": "_goodAmount_waikgc _amount_waikgc",
    "badAmount": "_badAmount_waikgc _amount_waikgc",
    "icon": "_icon_waikgc",
    "card": "_card_waikgc",
    "cardContainer": "_cardContainer_waikgc",
    "formWrapper": "_formWrapper_waikgc",
    "description": "_description_waikgc",
    "subText": "_subText_waikgc",
    "tipDisclaimer": "_tipDisclaimer_waikgc"
  };
  _exports.default = _default;
});