define("mobile-web/components/r-application-loading/checkout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8C4cyKYM",
    "block": "[[[8,[39,0],[[16,0,[30,0,[\"style\",\"root\"]]]],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"header\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"style\",\"grid\"]]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[28,[37,3],[1,2,3,4,5,6],null]],null]],null],null,[[[1,\"      \"],[10,0],[12],[1,\"\\n        \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"sectionHeader\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n        \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"sectionContent\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n        \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"sectionContent\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n        \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"sectionContent\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[1]]]]]],[\"placeholder\"],false,[\"content-placeholders\",\"each\",\"-track-array\",\"array\"]]",
    "moduleName": "mobile-web/components/r-application-loading/checkout/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});