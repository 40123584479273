define("mobile-web/components/routes/menu/vendor/products-route/component", ["exports", "@glimmer/component", "mobile-web/components/routes/menu/vendor/products-route/style"], function (_exports, _component, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MenuVendorProductsRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class MenuVendorProductsRoute extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      _initializerDefineProperty(this, "bus", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    // Constructor
    // Other methods
    // Tasks
    // Actions
    transitionToVendor() {
      var _this$args$model$bask;

      const isUpdating = ((_this$args$model$bask = this.args.model.basketProduct) == null ? void 0 : _this$args$model$bask.get('isNew')) === false;
      this.router.transitionTo('menu.vendor', this.args.model.product.vendor.slug, {
        queryParams: {
          openBasket: isUpdating || undefined
        }
      });
    }

    openModal() {
      const {
        displayInModal,
        product,
        basketProduct,
        previousRoute
      } = this.args.model;

      if (displayInModal) {
        this.bus.trigger('showProductModal', {
          product,
          basketProduct,
          previousRoute,
          onClose: this.transitionToVendor
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "bus", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "transitionToVendor", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToVendor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openModal", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "openModal"), _class.prototype)), _class));
  _exports.default = MenuVendorProductsRoute;
});