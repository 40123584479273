define("mobile-web/components/routes/menu/vendor/products-route/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_kvt19s"
  };
  _exports.default = _default;
});