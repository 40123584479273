define("mobile-web/serializers/custom-link", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CustomLinkSerializer extends _emberData.default.RESTSerializer {
    constructor(...args) {
      super(...args);
      this.primaryKey = 'linkLabelText';
    }

  }

  _exports.default = CustomLinkSerializer;
});