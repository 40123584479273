define("mobile-web/templates/menu/vendor/products", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R8tjmlNu",
    "block": "[[[8,[39,0],null,[[\"@isLoading\",\"@model\"],[[30,0,[\"isLoading\"]],[30,0,[\"model\"]]]],null],[1,\"\\n\"]],[],false,[\"routes/menu/vendor/products-route\"]]",
    "moduleName": "mobile-web/templates/menu/vendor/products.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});