define("mobile-web/lib/utilities/keys", ["exports", "mobile-web/lib/utilities/is-some"], function (_exports, _isSome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bindActionToKeyEvent = _exports.isKey = _exports.isToggleKey = _exports.TOGGLE_KEY_CODES = _exports.TOGGLE_KEYS = _exports.KEY_CODES = _exports.Key = void 0;
  let Key;
  _exports.Key = Key;

  (function (Key) {
    Key["ArrowUp"] = "ArrowUp";
    Key["ArrowDown"] = "ArrowDown";
    Key["Enter"] = "Enter";
    Key["Escape"] = "Escape";
    Key["PageDown"] = "PageDown";
    Key["Tab"] = "Tab";
    Key["Space"] = "Space";
  })(Key || (_exports.Key = Key = {}));

  const KEY_CODES = {
    ArrowUp: 38,
    ArrowDown: 40,
    Enter: 13,
    Escape: 27,
    PageDown: 34,
    Tab: 9,
    Space: 32
  };
  _exports.KEY_CODES = KEY_CODES;
  const TOGGLE_KEYS = [Key.Enter, Key.Space];
  _exports.TOGGLE_KEYS = TOGGLE_KEYS;
  const TOGGLE_KEY_CODES = [KEY_CODES.Enter, KEY_CODES.Space];
  _exports.TOGGLE_KEY_CODES = TOGGLE_KEY_CODES;

  const isToggleKey = keyEvent => (0, _isSome.default)(keyEvent.key) && TOGGLE_KEYS.includes(keyEvent.key) || (0, _isSome.default)(keyEvent.keyCode) && TOGGLE_KEY_CODES.includes(keyEvent.keyCode) || (0, _isSome.default)(keyEvent.which) && TOGGLE_KEY_CODES.includes(keyEvent.which);

  _exports.isToggleKey = isToggleKey;

  const isKey = (key, keyEvent) => (0, _isSome.default)(keyEvent.key) && keyEvent.key === Key[key] || (0, _isSome.default)(keyEvent.keyCode) && keyEvent.keyCode === KEY_CODES[key] || (0, _isSome.default)(keyEvent.which) && keyEvent.which === KEY_CODES[key];

  _exports.isKey = isKey;

  const bindActionToKeyEvent = (key, handler) => keyEvent => isKey(key, keyEvent) ? Ember.run(handler) : keyEvent;

  _exports.bindActionToKeyEvent = bindActionToKeyEvent;
});