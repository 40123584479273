define("mobile-web/components/routes/check-in-route/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "container": "_container_soi9tf",
    "content": "_content_soi9tf",
    "instructions": "_instructions_soi9tf _handoffMessage_gddu46",
    "section": "_section_soi9tf _section_rjtn4c",
    "thanksMessageDetails": "_thanksMessageDetails_soi9tf _thanksMessageDetails_rjtn4c"
  };
  _exports.default = _default;
});