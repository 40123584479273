define("mobile-web/decorators/indexed", ["exports", "mobile-web/lib/utilities/_"], function (_exports, _) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(route) {
    const original = route.prototype.afterModel;

    route.prototype.afterModel = function (resolvedModel, transition) {
      const config = Ember.getOwner(this).lookup('service:config');
      const shouldIndex = (0, _.isFunction)(this.shouldIndex) ? this.shouldIndex : _.stubTrue;

      if (config.allowSearchEngineIndexing && shouldIndex.call(this)) {
        const headTags = this.headTags || [];
        this.set('headTags', headTags.concat({
          tagId: 'allow-indexing',
          type: 'meta',
          attrs: {
            name: 'robots',
            content: 'all'
          }
        }));
      }

      original.call(this, resolvedModel, transition);
    };
  }
});