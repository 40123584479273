define("mobile-web/decorators/member-action", ["exports", "ember-api-actions"], function (_exports, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const memberAction = options => (target, key) => {
    if (!options.path) {
      options.path = '';
    } // eslint-disable-next-line @typescript-eslint/no-explicit-any


    const action = (0, _emberApiActions.memberAction)(options);
    Object.defineProperty(target, key, {
      get() {
        return action;
      }

    });
    return {};
  };

  var _default = memberAction;
  _exports.default = _default;
});