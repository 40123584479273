define("mobile-web/components/r-product-customization/top-level-option-groups/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_1fcebn",
    "navClass": "_navClass_1fcebn",
    "formationGroups": "_formationGroups_1fcebn _grid_qq2jd6",
    "optionGroup": "_optionGroup_1fcebn",
    "inModal": "_inModal_1fcebn"
  };
  _exports.default = _default;
});