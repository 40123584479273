define("mobile-web/components/r-credit-card-modal/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_14tcps",
    "availableCardTypes": "_availableCardTypes_14tcps",
    "input": "_input_14tcps",
    "inputRow": "_inputRow_14tcps",
    "inactiveCard": "_inactiveCard_14tcps",
    "hidden": "_hidden_14tcps"
  };
  _exports.default = _default;
});