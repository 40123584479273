define("mobile-web/components/r-cart/choice/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BxLg0OFT",
    "block": "[[[10,\"li\"],[15,0,[30,0,[\"style\",\"root\"]]],[14,\"data-test-cart-choice\",\"\"],[12],[1,\"\\n  \"],[10,1],[15,0,[30,0,[\"style\",\"quantity\"]]],[12],[1,\"\\n    \"],[1,[30,0,[\"quantity\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,1],[12],[1,\"\\n\"],[1,\"    \"],[1,[30,1,[\"name\"]]],[41,[30,0,[\"customFieldValue\"]],[[[1,\":\\n      \"],[10,1],[14,\"data-test-cart-choice-customFieldValue\",\"\"],[12],[1,\"\\\"\"],[1,[30,0,[\"customFieldValue\"]]],[1,\"\\\"\"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@basketChoice\"],false,[\"if\"]]",
    "moduleName": "mobile-web/components/r-cart/choice/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});