define("mobile-web/services/features", ["exports", "launchdarkly-js-client-sdk", "uuid", "mobile-web/config/environment", "mobile-web/lib/computed"], function (_exports, _launchdarklyJsClientSdk, _uuid, _environment, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FeaturesService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class FeaturesService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "bootstrap", _descriptor, this);

      _initializerDefineProperty(this, "storage", _descriptor2, this);

      _initializerDefineProperty(this, "device", _descriptor3, this);

      _initializerDefineProperty(this, "vendor", _descriptor4, this);

      _initializerDefineProperty(this, "ldUserId", _descriptor5, this);

      this.retryDelay = 1000;

      _initializerDefineProperty(this, "connected", _descriptor6, this);

      _initializerDefineProperty(this, "_flags", _descriptor7, this);

      this._customUserData = {};
    }

    get userKey() {
      var _this$bootstrap$data;

      return ((_this$bootstrap$data = this.bootstrap.data) == null ? void 0 : _this$bootstrap$data.mixpanelUniqueId) || this.ldUserId;
    }

    get flags() {
      return this.connected ? this._flags : this.defaultFlags;
    }

    get ldUser() {
      var _this$ldUserId, _this$bootstrap$data$, _this$bootstrap$data2;

      return {
        key: this.userKey,
        custom: _extends({
          uniqueId: (_this$ldUserId = this.ldUserId) != null ? _this$ldUserId : '',
          channel: (_this$bootstrap$data$ = (_this$bootstrap$data2 = this.bootstrap.data) == null ? void 0 : _this$bootstrap$data2.channel.name) != null ? _this$bootstrap$data$ : '',
          hostname: window.location.hostname,
          platform: this.device.platform,
          viewport: this.device.viewport
        }, this._customUserData)
      };
    }
    /*
     * When LD becomes unavailable, all FFs become `false`.
     * If they should have a different default value, add them here.
     */


    get defaultFlags() {
      return {};
    }

    async setupFeatureFlags() {
      if (!_environment.default.LAUNCH_DARKLY) {
        return Promise.resolve();
      } // eslint-disable-next-line ember/no-observers


      Ember.addObserver(this.device, 'viewport', this.identifyUser);

      if (!this.ldUserId) {
        this.ldUserId = (0, _uuid.v4)();
      } // have to import this way so we can stub this out in the test


      this.ldClient = _launchdarklyJsClientSdk.default.initialize(_environment.default.LAUNCH_DARKLY, this.ldUser, {
        bootstrap: 'localStorage'
      });
      this.ldClient.on('ready', this.handleFlagsChange);
      this.ldClient.on('change', this.handleFlagsChange);
      this.ldClient.on('error', this.handleFlagsError);
      this.ldClient.on('failed', this.handleFlagsError);
      return this.ldInit = this.ldClient.waitUntilReady();
    }

    updateUser(key, value) {
      this._customUserData[key] = value;
      this.identifyUser();
    }

    handleFlagsChange() {
      var _this$ldClient;

      this.connected = true;
      this._flags = ((_this$ldClient = this.ldClient) == null ? void 0 : _this$ldClient.allFlags()) || {};
    }

    handleFlagsError() {
      this.connected = false;
      this.retryFetchFlags();
    }
    /**
     * If LD can't initialize, retry at a slowly increasing interval.
     * `identify` is one of the few LD calls that will throw an error
     * if it can't reach the server, so by polling for it we force LD
     * to continously fire `change` or `error` events (handled above).
     * If we don't do this, even when the connection comes back the
     * flag values won't update until some user property changes.
     */


    retryFetchFlags() {
      clearTimeout(this.retryTimer);
      this.retryDelay *= 1.125;
      this.retryTimer = setTimeout(async () => {
        try {
          await this.ldClient.identify(this.ldUser);
          this.handleFlagsChange();
        } catch (e) {}
      }, this.retryDelay);
    }

    identifyUser() {
      var _this$ldInit;

      (_this$ldInit = this.ldInit) == null ? void 0 : _this$ldInit.then(() => {
        if (this.connected) {
          this.ldClient.identify(this.ldUser);
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "bootstrap", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "device", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "vendor", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "ldUserId", [_computed.local], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "connected", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_flags", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleFlagsChange", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "handleFlagsChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleFlagsError", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "handleFlagsError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "identifyUser", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "identifyUser"), _class.prototype)), _class));
  _exports.default = FeaturesService;
});