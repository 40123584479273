define("mobile-web/serializers/region", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RegionSerializer extends _emberData.default.JSONSerializer {
    constructor(...args) {
      super(...args);
      this.primaryKey = 'code';
    }

  }

  _exports.default = RegionSerializer;
});