define("mobile-web/components/r-cart/product-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X2VjpqWL",
    "block": "[[[10,0],[14,\"data-test-cart-productList\",\"\"],[15,0,[52,[30,1],[30,0,[\"style\",\"isCart\"]]]],[12],[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"ul\"],[15,0,[30,0,[\"style\",\"productList\"]]],[12],[1,\"\\n\"],[6,[39,2],[[30,0,[\"shownProducts\"]]],[[\"use\",\"duration\"],[[30,0,[\"transition\"]],[30,0,[\"animationDuration\"]]]],[[\"default\"],[[[[1,\"        \"],[10,\"li\"],[15,0,[30,0,[\"style\",\"product\"]]],[12],[1,\"\\n          \"],[8,[39,3],null,[[\"@product\",\"@currency\",\"@editable\",\"@onEdit\",\"@onClose\"],[[30,2],[30,3],[30,1],[30,4],[30,5]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]]]]],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n\"],[6,[39,4],[[30,0,[\"showExpandButton\"]]],[[\"use\",\"duration\"],[[30,0,[\"transition\"]],[30,0,[\"animationDuration\"]]]],[[\"default\"],[[[[1,\"      \"],[8,[39,5],[[16,0,[30,0,[\"style\",\"expandButton\"]]],[24,\"data-test-cart-productList-showMore\",\"\"]],[[\"@variant\",\"@onClick\"],[\"minor\",[30,0,[\"expandProducts\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,6],[\"mwc.cart.showMoreItemsLabel\"],[[\"count\"],[[30,0,[\"hiddenProductCount\"]]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"  \"]],[]]]]],[1,\"\\n\"],[13]],[\"@editable\",\"product\",\"@currency\",\"@onEdit\",\"@onClose\"],false,[\"if\",\"animated-container\",\"animated-each\",\"r-cart/product\",\"animated-if\",\"r-button\",\"t\"]]",
    "moduleName": "mobile-web/components/r-cart/product-list/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});