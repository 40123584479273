define("mobile-web/components/r-link-rewards/schemes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4xLZetUg",
    "block": "[[[10,0],[15,0,[30,0,[\"style\",\"root\"]]],[14,\"data-test-linkRewards-schemes\",\"\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"linkableSchemes\"]]],null]],null],null,[[[1,\"    \"],[10,0],[15,0,[30,0,[\"style\",\"row\"]]],[14,\"data-test-linkRewards-schemes-linkAccount\",\"\"],[12],[1,\"\\n      \"],[10,2],[14,\"data-test-linkRewards-schemes-linkRewards-label\",\"\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"mwc.linkRewards.linkAccountLabel\"],[[\"name\"],[[30,1,[\"schemeName\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,3],null,[[\"@scheme\",\"@onLink\"],[[30,1],[30,2]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"linkedSchemes\"]]],null]],null],null,[[[44,[[30,3,[\"0\"]],[30,3,[\"1\"]]],[[[1,\"      \"],[10,0],[15,0,[30,0,[\"style\",\"row\"]]],[14,\"data-test-linkRewards-schemes-viewRewards\",\"\"],[12],[1,\"\\n        \"],[10,2],[14,\"data-test-linkRewards-schemes-viewRewards-label\",\"\"],[12],[1,\"\\n          \"],[1,[28,[35,2],[\"mwc.linkRewards.viewRewardsLabel\"],[[\"name\"],[[30,4,[\"schemeName\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[39,5],null,[[\"@onClick\",\"@variant\",\"@testSelector\",\"@fullWidth\"],[[28,[37,6],[[30,0,[\"viewRewards\"]],[30,4]],null],\"minor\",[28,[37,7],[\"viewRewards-\",[30,4,[\"id\"]]],null],true]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,2],[[28,[37,7],[\"mwc.linkRewards.\",[52,[30,5],\"rewardsAvailableButton\",\"viewRewardsButton\"]],null]],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[4,5]]]],[3]],null],[13],[1,\"\\n\"]],[\"scheme\",\"@onLink\",\"linkedScheme\",\"scheme\",\"hasQualifyingRewards\"],false,[\"each\",\"-track-array\",\"t\",\"r-link-rewards/form\",\"let\",\"r-button\",\"fn\",\"concat\",\"if\"]]",
    "moduleName": "mobile-web/components/r-link-rewards/schemes/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});