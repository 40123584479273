define("mobile-web/lib/time/weekly-schedule", ["exports", "mobile-web/components/r-info-tag/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toTags = toTags;
  _exports.hoursForDay = hoursForDay;
  _exports.default = _exports.WeeklySchedule = _exports.ScheduleAvailabilityKeywords = void 0;
  let ScheduleAvailabilityKeywords;
  _exports.ScheduleAvailabilityKeywords = ScheduleAvailabilityKeywords;

  (function (ScheduleAvailabilityKeywords) {
    ScheduleAvailabilityKeywords["Closed"] = "Closed";
    ScheduleAvailabilityKeywords["AllDay"] = "All Day";
    ScheduleAvailabilityKeywords["Midnight"] = "Midnight";
  })(ScheduleAvailabilityKeywords || (_exports.ScheduleAvailabilityKeywords = ScheduleAvailabilityKeywords = {}));

  function toTags(weeklySchedule, day = weeklySchedule.currentWeekDay) {
    return weeklySchedule.calendars.reduce((tags, {
      scheduleDescription: description,
      schedule
    }) => {
      const type = _component.Type.Neutral;
      const daySchedule = schedule.find(s => s.weekDay === day);

      if (daySchedule) {
        const content = `${description} Hours: ${daySchedule.description}`;
        tags.push({
          type,
          content
        });
      }

      return tags;
    }, []);
  }

  function hoursForDay(day, calendar) {
    var _calendar$schedule$fi;

    return (calendar == null ? void 0 : (_calendar$schedule$fi = calendar.schedule.find(s => s.weekDay === day)) == null ? void 0 : _calendar$schedule$fi.description) || ScheduleAvailabilityKeywords.Closed;
  }

  const WeeklySchedule = {
    toTags
  }; // Declaration-merge the type and the object.

  _exports.WeeklySchedule = WeeklySchedule;
  var _default = WeeklySchedule;
  _exports.default = _default;
});