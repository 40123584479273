define("mobile-web/lib/errors", ["exports", "ember-test-friendly-error-handler"], function (_exports, _emberTestFriendlyErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.squelchAllErrorHandlers = squelchAllErrorHandlers;
  _exports.buildOloErrorHandler = buildOloErrorHandler;
  _exports.ErrorCategory = _exports.FriendlyError = void 0;
  const ERROR_HANDLERS = ['Ember.onerror', 'Olo.reportError', 'app-load-failure', 'apply-discount-failure', 'external-login-failure', 'login-failure', 'register-submit-error', 'remove-discount-error', 'set-country-error'];

  function squelchAllErrorHandlers() {
    ERROR_HANDLERS.forEach(_emberTestFriendlyErrorHandler.squelchErrorHandlerFor);
  }

  function buildOloErrorHandler(label, // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback) {
    return (0, _emberTestFriendlyErrorHandler.default)(label, callback);
  }

  class FriendlyError extends Error {
    constructor(messageHeader, friendlyMessage) {
      super(friendlyMessage);
      this.messageHeader = messageHeader;
      this.friendlyMessage = friendlyMessage;
    }

  } // Source: https://github.com/ololabs/platform/blob/develop/MoboLogic/src/FailCategory.cs


  _exports.FriendlyError = FriendlyError;
  let ErrorCategory;
  _exports.ErrorCategory = ErrorCategory;

  (function (ErrorCategory) {
    ErrorCategory["None"] = "None";
    ErrorCategory["Unspecified"] = "Unspecified";
    ErrorCategory["OloInternalError"] = "OloInternalError";
    ErrorCategory["OloInternalDatabaseError"] = "OloInternalDatabaseError";
    ErrorCategory["AlreadySubmitted"] = "AlreadySubmitted";
    ErrorCategory["CapacityThrottled"] = "CapacityThrottled";
    ErrorCategory["CouponRequirementsNotMet"] = "CouponRequirementsNotMet";
    ErrorCategory["CouponRedemptionFailed"] = "CouponRedemptionFailed";
    ErrorCategory["CircuitBroken"] = "CircuitBroken";
    ErrorCategory["DispatchTimeout"] = "DispatchTimeout";
    ErrorCategory["DispatchApiError"] = "DispatchApiError";
    ErrorCategory["DispatchProvidersUnavailable"] = "DispatchProvidersUnavailable";
    ErrorCategory["DispatchQuoteChanged"] = "DispatchQuoteChanged";
    ErrorCategory["DispatchInvalidCustomerInformation"] = "DispatchInvalidCustomerInformation";
    ErrorCategory["DispatchInvalidVendorInformation"] = "DispatchInvalidVendorInformation";
    ErrorCategory["ExpoDeviceCodeInvalid"] = "ExpoDeviceCodeInvalid";
    ErrorCategory["ExpoUserInactive"] = "ExpoUserInactive";
    ErrorCategory["ExpoUserInformationInvalid"] = "ExpoUserInformationInvalid";
    ErrorCategory["FaxServiceUnavailable"] = "FaxServiceUnavailable";
    ErrorCategory["FraudRisk"] = "FraudRisk";
    ErrorCategory["FraudNetworkRisk"] = "FraudNetworkRisk";
    ErrorCategory["HandoffRequirementsNotMet"] = "HandoffRequirementsNotMet";
    ErrorCategory["IllegalInput"] = "IllegalInput";
    ErrorCategory["LoyaltyUnspecified"] = "LoyaltyUnspecified";
    ErrorCategory["LoyaltyMappingError"] = "LoyaltyMappingError";
    ErrorCategory["LoyaltyRedemptionDeclined"] = "LoyaltyRedemptionDeclined";
    ErrorCategory["LoyaltyServiceUnavailable"] = "LoyaltyServiceUnavailable";
    ErrorCategory["LoyaltyMisconfiguration"] = "LoyaltyMisconfiguration";
    ErrorCategory["LoyaltyVendorMisconfiguration"] = "LoyaltyVendorMisconfiguration";
    ErrorCategory["LoyaltyInvalidOrder"] = "LoyaltyInvalidOrder";
    ErrorCategory["LoyaltyAccessDenied"] = "LoyaltyAccessDenied";
    ErrorCategory["LoyaltyResponseTimeout"] = "LoyaltyResponseTimeout";
    ErrorCategory["LoyaltyProviderApiError"] = "LoyaltyProviderApiError";
    ErrorCategory["LoyaltyInternalError"] = "LoyaltyInternalError";
    ErrorCategory["LoyaltyMembershipInputError"] = "LoyaltyMembershipInputError";
    ErrorCategory["LoyaltyAccountInactive"] = "LoyaltyAccountInactive";
    ErrorCategory["LoyaltyUserError"] = "LoyaltyUserError";
    ErrorCategory["LoyaltyExpected"] = "LoyaltyExpected";
    ErrorCategory["LoyaltyUnexpected"] = "LoyaltyUnexpected";
    ErrorCategory["MenuItemOutOfHours"] = "MenuItemOutOfHours";
    ErrorCategory["MenuItemEightySixed"] = "MenuItemEightySixed";
    ErrorCategory["MenuItemDeleted"] = "MenuItemDeleted";
    ErrorCategory["MenuItemFiltered"] = "MenuItemFiltered";
    ErrorCategory["MenuItemNotOffered"] = "MenuItemNotOffered";
    ErrorCategory["OrderingNotSupported"] = "OrderingNotSupported";
    ErrorCategory["OutsideDeliveryRange"] = "OutsideDeliveryRange";
    ErrorCategory["PastStartMakingTime"] = "PastStartMakingTime";
    ErrorCategory["OrderRequirementsNotMet"] = "OrderRequirementsNotMet";
    ErrorCategory["PaymentUnspecified"] = "PaymentUnspecified";
    ErrorCategory["PaymentAccountExpired"] = "PaymentAccountExpired";
    ErrorCategory["PaymentAuthExpired"] = "PaymentAuthExpired";
    ErrorCategory["PaymentCardTypeNotSupported"] = "PaymentCardTypeNotSupported";
    ErrorCategory["PaymentCardExpired"] = "PaymentCardExpired";
    ErrorCategory["PaymentSaleDeclined"] = "PaymentSaleDeclined";
    ErrorCategory["PaymentInsufficientBalance"] = "PaymentInsufficientBalance";
    ErrorCategory["PaymentTippingNotAllowed"] = "PaymentTippingNotAllowed";
    ErrorCategory["PaymentGatewayUnavailable"] = "PaymentGatewayUnavailable";
    ErrorCategory["PaymentOloGatewayUnavailable"] = "PaymentOloGatewayUnavailable";
    ErrorCategory["PaymentMerchantMisconfiguration"] = "PaymentMerchantMisconfiguration";
    ErrorCategory["PaymentRateLimited"] = "PaymentRateLimited";
    ErrorCategory["PaymentRequirementsNotMet"] = "PaymentRequirementsNotMet";
    ErrorCategory["PaymentTotalMismatch"] = "PaymentTotalMismatch";
    ErrorCategory["PaymentUserSubmittedTotalMismatch"] = "PaymentUserSubmittedTotalMismatch";
    ErrorCategory["PaymentTipMismatch"] = "PaymentTipMismatch";
    ErrorCategory["PaymentTypeNotSupported"] = "PaymentTypeNotSupported";
    ErrorCategory["PaymentTransactionDeclined"] = "PaymentTransactionDeclined";
    ErrorCategory["PaymentTransactionDeclinedAvs"] = "PaymentTransactionDeclinedAvs";
    ErrorCategory["PaymentTransactionDeclinedCvv"] = "PaymentTransactionDeclinedCvv";
    ErrorCategory["PaymentTransactionDeclinedPin"] = "PaymentTransactionDeclinedPin";
    ErrorCategory["PaymentTransactionDeclinedFraud"] = "PaymentTransactionDeclinedFraud";
    ErrorCategory["PaymentTransactionDeclinedPartial"] = "PaymentTransactionDeclinedPartial";
    ErrorCategory["PaymentTransactionDeclinedMultiple"] = "PaymentTransactionDeclinedMultiple";
    ErrorCategory["PaymentTransactionInvalid"] = "PaymentTransactionInvalid";
    ErrorCategory["PaymentTransactionFailure"] = "PaymentTransactionFailure";
    ErrorCategory["PaymentTransactionUnknown"] = "PaymentTransactionUnknown";
    ErrorCategory["PaymentTransactionOther"] = "PaymentTransactionOther";
    ErrorCategory["PaymentInquiryDeclined"] = "PaymentInquiryDeclined";
    ErrorCategory["PaymentBillingModesMismatch"] = "PaymentBillingModesMismatch";
    ErrorCategory["PaymentOperationAborted"] = "PaymentOperationAborted";
    ErrorCategory["PaymentMismatchedUserBillingAccount"] = "PaymentMismatchedUserBillingAccount";
    ErrorCategory["PaymentPrepaymentRequired"] = "PaymentPrepaymentRequired";
    ErrorCategory["PosUnspecified"] = "PosUnspecified";
    ErrorCategory["PosMenuItemEightySixed"] = "PosMenuItemEightySixed";
    ErrorCategory["PosCheckClosed"] = "PosCheckClosed";
    ErrorCategory["PosCloudUnavailable"] = "PosCloudUnavailable";
    ErrorCategory["PosMappingError"] = "PosMappingError";
    ErrorCategory["PosIncompatible"] = "PosIncompatible";
    ErrorCategory["PosInvalidPlu"] = "PosInvalidPlu";
    ErrorCategory["PosNotLicensed"] = "PosNotLicensed";
    ErrorCategory["PosOffline"] = "PosOffline";
    ErrorCategory["PosRejectedOrder"] = "PosRejectedOrder";
    ErrorCategory["PosTaxMismatch"] = "PosTaxMismatch";
    ErrorCategory["PosUnavailable"] = "PosUnavailable";
    ErrorCategory["PosInternalContention"] = "PosInternalContention";
    ErrorCategory["PosInternalError"] = "PosInternalError";
    ErrorCategory["PosInternalMisconfiguration"] = "PosInternalMisconfiguration";
    ErrorCategory["PosResponseTimeout"] = "PosResponseTimeout";
    ErrorCategory["PosMisconfiguration"] = "PosMisconfiguration";
    ErrorCategory["PosDiscountError"] = "PosDiscountError";
    ErrorCategory["PosAccessDenied"] = "PosAccessDenied";
    ErrorCategory["ProductModifierRequired"] = "ProductModifierRequired";
    ErrorCategory["ProductModifierCardinality"] = "ProductModifierCardinality";
    ErrorCategory["TaxRatesMisconfiguration"] = "TaxRatesMisconfiguration";
    ErrorCategory["TooFarInAdvance"] = "TooFarInAdvance";
    ErrorCategory["VendorDisabled"] = "VendorDisabled";
    ErrorCategory["VendorOutOfHours"] = "VendorOutOfHours";
    ErrorCategory["WantedTimeNotSpecified"] = "WantedTimeNotSpecified";
    ErrorCategory["TimeWantedModeNotSpecified"] = "TimeWantedModeNotSpecified";
    ErrorCategory["NotificationServiceError"] = "NotificationServiceError";
    ErrorCategory["ConfigurationError"] = "ConfigurationError";
    ErrorCategory["DiscountServiceMappingError"] = "DiscountServiceMappingError";
    ErrorCategory["LoginProviderUnspecified"] = "LoginProviderUnspecified";
    ErrorCategory["LoginProviderRejectedUpdate"] = "LoginProviderRejectedUpdate";
    ErrorCategory["GroupOrderRequirementsNotMet"] = "GroupOrderRequirementsNotMet";
    ErrorCategory["GroupOrderNotFound"] = "GroupOrderNotFound";
    ErrorCategory["GroupOrderChildOrderNotFound"] = "GroupOrderChildOrderNotFound";
    ErrorCategory["GroupOrderNotInitialized"] = "GroupOrderNotInitialized";
    ErrorCategory["GroupOrderEmailFailure"] = "GroupOrderEmailFailure";
    ErrorCategory["GroupOrderSubmissionError"] = "GroupOrderSubmissionError";
    ErrorCategory["GroupOrderClosed"] = "GroupOrderClosed";
    ErrorCategory["GroupOrderLocked"] = "GroupOrderLocked";
    ErrorCategory["GroupOrderCancellationError"] = "GroupOrderCancellationError";
    ErrorCategory["RailsMarkup"] = "RailsMarkup";
  })(ErrorCategory || (_exports.ErrorCategory = ErrorCategory = {}));
});