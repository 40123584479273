define("mobile-web/components/r-notification/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "notification": "_notification_1980d4",
    "expand": "_expand_1980d4",
    "success": "_success_1980d4",
    "info": "_info_1980d4",
    "warning": "_warning_1980d4",
    "error": "_error_1980d4",
    "hybrid": "_hybrid_1980d4",
    "opening": "_opening_1980d4",
    "idling": "_idling_1980d4",
    "closing": "_closing_1980d4",
    "icon": "_icon_1980d4",
    "message": "_message_1980d4",
    "closeButton": "_closeButton_1980d4",
    "closeIcon": "_closeIcon_1980d4"
  };
  _exports.default = _default;
});