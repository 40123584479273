define("mobile-web/components/r-static-page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1q1RmOYN",
    "block": "[[[10,0],[14,\"data-test-static-page\",\"\"],[15,0,[30,0,[\"style\",\"content\"]]],[12],[1,\"\\n  \"],[1,[34,0]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"model\"]]",
    "moduleName": "mobile-web/components/r-static-page/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});