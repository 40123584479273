define("mobile-web/models/favorite", ["exports", "ember-data", "mobile-web/adapters/favorite", "mobile-web/decorators/member-action", "mobile-web/lib/utilities/_", "mobile-web/models/basket"], function (_exports, _emberData, _favorite, _memberAction, _, _basket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FavoriteModel = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = _emberData.default.attr('string'), _dec5 = _emberData.default.attr('string'), _dec6 = _emberData.default.attr('number'), _dec7 = _emberData.default.attr('string'), _dec8 = _emberData.default.attr('string'), _dec9 = _emberData.default.attr('boolean'), _dec10 = _emberData.default.attr('string'), _dec11 = _emberData.default.hasMany('favorite-product', {
    async: false
  }), _dec12 = (0, _memberAction.default)({
    type: 'post',
    urlType: _favorite.FavoriteRequestType.Basket,
    after: _basket.pushBasketPayload
  }), _dec13 = Ember.computed('productSummary', 'isDisabled', 'disabledMessage'), (_class = class FavoriteModel extends _emberData.default.Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "bus", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "description", _descriptor4, this);

      _initializerDefineProperty(this, "productSummary", _descriptor5, this);

      _initializerDefineProperty(this, "vendorId", _descriptor6, this);

      _initializerDefineProperty(this, "vendorName", _descriptor7, this);

      _initializerDefineProperty(this, "vendorSlug", _descriptor8, this);

      _initializerDefineProperty(this, "isDisabled", _descriptor9, this);

      _initializerDefineProperty(this, "disabledMessage", _descriptor10, this);

      _initializerDefineProperty(this, "favoriteProducts", _descriptor11, this);

      _initializerDefineProperty(this, "createBasket", _descriptor12, this);
    }

    get summary() {
      return this.isDisabled ? (0, _.isEmpty)(this.disabledMessage) ? this.intl.t('mwc.basket.disabledMessage') : this.disabledMessage : this.productSummary;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "bus", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "productSummary", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "vendorId", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "vendorName", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "vendorSlug", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isDisabled", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "disabledMessage", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "favoriteProducts", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "createBasket", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "summary", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "summary"), _class.prototype)), _class));
  _exports.default = FavoriteModel;
});