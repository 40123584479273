define("mobile-web/controllers/arrival", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ArrivalController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      this.queryParams = ['hash', 'utm_medium'];
    }

  }

  _exports.default = ArrivalController;
});