define("mobile-web/lib/time/format", ["exports", "mobile-web/lib/dayjs", "mobile-web/lib/strings"], function (_exports, _dayjs, _strings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.day = day;
  _exports.default = _exports.TIME_FORMAT = void 0;
  const TIME_FORMAT = 'h:mm A';
  _exports.TIME_FORMAT = TIME_FORMAT;

  /**
   * Format a Dayjs as a day
   * @param value The Dayjs to format
   * @param options Additional options
   */
  function day(value, options = {}) {
    if ((0, _dayjs.default)().dayOfYear() === value.dayOfYear()) {
      return options.todayLabel || _strings.TODAY;
    } else if ((0, _dayjs.default)().add(1, 'day').dayOfYear() === value.dayOfYear()) {
      return options.tomorrowLabel || _strings.TOMORROW;
    }

    return value.format(options.format || 'L');
  }

  var _default = {
    TIME_FORMAT,
    day
  };
  _exports.default = _default;
});