define("mobile-web/models/vendor", ["exports", "ember-data", "lodash.pick", "mobile-web/decorators/member-action", "mobile-web/lib/order-criteria", "mobile-web/lib/utilities/is-some", "mobile-web/services/user-feedback"], function (_exports, _emberData, _lodash, _memberAction, _orderCriteria, _isSome, _userFeedback) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getShortOverrideDesc = getShortOverrideDesc;
  _exports.toggleVendorFavorite = toggleVendorFavorite;
  _exports.default = _exports.VendorStatus = _exports.LabelCode = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _dec43, _dec44, _dec45, _dec46, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39, _descriptor40, _descriptor41, _descriptor42, _descriptor43, _descriptor44;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  let LabelCode;
  _exports.LabelCode = LabelCode;

  (function (LabelCode) {
    LabelCode["NYC"] = "NYCHighSodium";
    LabelCode["PHL"] = "PHLSodiumWarning";
    LabelCode["Prop65"] = "Prop65Toxic";
  })(LabelCode || (_exports.LabelCode = LabelCode = {}));

  function getShortOverrideDesc(reason, intl) {
    return reason.name === 'Default' ? intl.t('mwc.vendor.closedNoReason') : intl.t('mwc.vendor.closedForReason', {
      reason: reason.name
    });
  }

  function buildPreCheckPayload(criteria) {
    var _criteria$deliveryAdd, _criteria$deliveryAdd2, _criteria$deliveryAdd3, _criteria$deliveryAdd4, _criteria$deliveryAdd5;

    const timeWantedType = criteria.timeWantedType;
    const timeWanted = (0, _orderCriteria.isAdvance)(criteria) && criteria.timeWanted ? criteria.timeWanted.toDate() : undefined;
    const handoffMode = criteria.handoffMode;
    const addressFields = (0, _orderCriteria.isDelivery)(criteria) ? {
      addressId: (_criteria$deliveryAdd = criteria.deliveryAddress) == null ? void 0 : _criteria$deliveryAdd.id,
      address: (_criteria$deliveryAdd2 = criteria.deliveryAddress) == null ? void 0 : _criteria$deliveryAdd2.streetAddress,
      building: (_criteria$deliveryAdd3 = criteria.deliveryAddress) == null ? void 0 : _criteria$deliveryAdd3.building,
      city: (_criteria$deliveryAdd4 = criteria.deliveryAddress) == null ? void 0 : _criteria$deliveryAdd4.city,
      zipCode: (_criteria$deliveryAdd5 = criteria.deliveryAddress) == null ? void 0 : _criteria$deliveryAdd5.zipCode
    } : {};
    return _extends({
      timeWanted,
      timeWantedType,
      handoffMode
    }, addressFields);
  }

  function toggleVendorFavorite() {
    if (!this.session.isLoggedIn) {
      this.userFeedback.add({
        type: _userFeedback.Type.Warning,
        title: this.intl.t('mwc.errors.loginRequiredTitle'),
        message: this.intl.t('mwc.errors.favoriteVendorLoginRequiredMessage'),
        actions: [{
          label: this.intl.t('mwc.errors.loginRequiredAction'),
          fn: () => {
            this.session.transitionToLogin();
          }
        }]
      });
    } else {
      var _this$store$peekRecor;

      this.toggleProperty('isFavorite');
      this.toggleFavoriteAction().catch(e => {
        this.error.reportError(e);
        this.toggleProperty('isFavorite');
      }); // Need to match favorite value on related model if present

      (_this$store$peekRecor = this.store // eslint-disable-next-line no-use-before-define
      . // eslint-disable-next-line no-use-before-define
      peekRecord(this instanceof Vendor ? 'vendor-search-result' : 'vendor', this.id)) == null ? void 0 : _this$store$peekRecor.toggleProperty('isFavorite');
    }
  }
  /**
   * Technically there are more statuses, but the others should never end up in the front-end.
   * For reference, those other statuses are in Mobo.QuickService.VendorStatus.
   */


  let VendorStatus;
  _exports.VendorStatus = VendorStatus;

  (function (VendorStatus) {
    VendorStatus["Public"] = "Public";
    VendorStatus["Private"] = "Private";
  })(VendorStatus || (_exports.VendorStatus = VendorStatus = {}));

  let Vendor = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = _emberData.default.attr('string'), _dec8 = _emberData.default.attr('string'), _dec9 = _emberData.default.attr('string'), _dec10 = _emberData.default.attr('string'), _dec11 = _emberData.default.attr('object'), _dec12 = _emberData.default.attr('number'), _dec13 = _emberData.default.attr('number'), _dec14 = _emberData.default.attr('number'), _dec15 = _emberData.default.attr('object'), _dec16 = _emberData.default.attr('object'), _dec17 = _emberData.default.attr('boolean'), _dec18 = _emberData.default.attr('array'), _dec19 = _emberData.default.attr('boolean', {
    defaultValue: false
  }), _dec20 = _emberData.default.attr('boolean', {
    defaultValue: false
  }), _dec21 = _emberData.default.attr('boolean', {
    defaultValue: false
  }), _dec22 = _emberData.default.attr('boolean', {
    defaultValue: false
  }), _dec23 = _emberData.default.attr('boolean', {
    defaultValue: true
  }), _dec24 = _emberData.default.attr('boolean', {
    defaultValue: true
  }), _dec25 = _emberData.default.attr('boolean', {
    defaultValue: true
  }), _dec26 = _emberData.default.attr('boolean', {
    defaultValue: false
  }), _dec27 = _emberData.default.attr('string'), _dec28 = _emberData.default.attr('string'), _dec29 = _emberData.default.attr('number', {
    defaultValue: 0
  }), _dec30 = _emberData.default.attr('number', {
    defaultValue: 0
  }), _dec31 = _emberData.default.attr('boolean', {
    defaultValue: false
  }), _dec32 = _emberData.default.attr('string'), _dec33 = _emberData.default.attr('string'), _dec34 = _emberData.default.attr('string'), _dec35 = _emberData.default.attr('boolean', {
    defaultValue: true
  }), _dec36 = _emberData.default.attr('object'), _dec37 = _emberData.default.belongsTo('channel'), _dec38 = _emberData.default.attr('array'), _dec39 = _emberData.default.hasMany('category', {
    async: true
  }), _dec40 = _emberData.default.hasMany('loyalty-scheme', {
    async: false
  }), _dec41 = _emberData.default.hasMany('product', {
    async: true
  }), _dec42 = _emberData.default.hasMany('upsell-group', {
    async: false
  }), _dec43 = Ember.computed('settings.acceptedCreditCardTypes.[]'), _dec44 = Ember.computed('loyaltySchemes.@each.compCardsEnabled'), _dec45 = (0, _memberAction.default)({
    type: 'post',
    path: 'toggleasfavorite'
  }), _dec46 = (0, _memberAction.default)({
    type: 'post',
    path: 'pre-check',
    before: buildPreCheckPayload,

    after(response) {
      this.storage.set('lastPreCheckValid', response.isValid);
      return response;
    }

  }), (_class = class Vendor extends _emberData.default.Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "storage", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "userFeedback", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _initializerDefineProperty(this, "store", _descriptor5, this);

      _initializerDefineProperty(this, "error", _descriptor6, this);

      _initializerDefineProperty(this, "name", _descriptor7, this);

      _initializerDefineProperty(this, "slug", _descriptor8, this);

      _initializerDefineProperty(this, "status", _descriptor9, this);

      _initializerDefineProperty(this, "phoneNumber", _descriptor10, this);

      _initializerDefineProperty(this, "address", _descriptor11, this);

      _initializerDefineProperty(this, "latitude", _descriptor12, this);

      _initializerDefineProperty(this, "longitude", _descriptor13, this);

      _initializerDefineProperty(this, "utcOffset", _descriptor14, this);

      _initializerDefineProperty(this, "weeklySchedule", _descriptor15, this);

      _initializerDefineProperty(this, "settings", _descriptor16, this);

      _initializerDefineProperty(this, "isFavorite", _descriptor17, this);

      _initializerDefineProperty(this, "locationAttributes", _descriptor18, this);

      _initializerDefineProperty(this, "allowAdvanceOrders", _descriptor19, this);

      _initializerDefineProperty(this, "allowImmediateOrders", _descriptor20, this);

      _initializerDefineProperty(this, "allowManualFireOrders", _descriptor21, this);

      _initializerDefineProperty(this, "allowCoupons", _descriptor22, this);

      _initializerDefineProperty(this, "displayNationalMenu", _descriptor23, this);

      _initializerDefineProperty(this, "hasOnlineOrdering", _descriptor24, this);

      _initializerDefineProperty(this, "isAcceptingOrders", _descriptor25, this);

      _initializerDefineProperty(this, "menuIsReadOnly", _descriptor26, this);

      _initializerDefineProperty(this, "externalReference", _descriptor27, this);

      _initializerDefineProperty(this, "customOrderPrefix", _descriptor28, this);

      _initializerDefineProperty(this, "minimumPickupOrder", _descriptor29, this);

      _initializerDefineProperty(this, "minimumDeliveryOrder", _descriptor30, this);

      _initializerDefineProperty(this, "hasFreeDelivery", _descriptor31, this);

      _initializerDefineProperty(this, "currency", _descriptor32, this);

      _initializerDefineProperty(this, "unavailableMessage", _descriptor33, this);

      _initializerDefineProperty(this, "nextOpenTime", _descriptor34, this);

      _initializerDefineProperty(this, "isOpen", _descriptor35, this);

      _initializerDefineProperty(this, "overrideReason", _descriptor36, this);

      _initializerDefineProperty(this, "channel", _descriptor37, this);

      _initializerDefineProperty(this, "disclaimers", _descriptor38, this);

      _initializerDefineProperty(this, "categories", _descriptor39, this);

      _initializerDefineProperty(this, "loyaltySchemes", _descriptor40, this);

      _initializerDefineProperty(this, "products", _descriptor41, this);

      _initializerDefineProperty(this, "embeddedProductGroups", _descriptor42, this);

      _initializerDefineProperty(this, "toggleFavoriteAction", _descriptor43, this);

      _initializerDefineProperty(this, "preCheckAction", _descriptor44, this);
    }

    get sortedAcceptedCreditCardTypes() {
      const types = [...this.settings.acceptedCreditCardTypes];
      return types.sort().reverse();
    }

    get compCardsEnabled() {
      return (0, _isSome.default)(this.loyaltySchemes.find(s => s.compCardsEnabled));
    }

    toggleFavorite() {
      toggleVendorFavorite.call(this);
    }

    preCheck(criteria) {
      return this.preCheckAction(criteria);
    }

    serializeForGlobalData() {
      return (0, _lodash.default)(this, 'id', 'address', 'allowAdvanceOrders', 'allowCoupons', 'allowImmediateOrders', 'allowManualFireOrders', 'currency', 'displayNationalMenu', 'externalReference', 'isAcceptingOrders', 'isFavorite', 'latitude', 'longitude', 'minimumDeliveryOrder', 'minimumPickupOrder', 'name', 'phoneNumber', 'slug', 'status', 'utcOffset');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "userFeedback", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "error", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "slug", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "phoneNumber", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "address", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "latitude", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "longitude", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "utcOffset", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "weeklySchedule", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "isFavorite", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "locationAttributes", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "allowAdvanceOrders", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "allowImmediateOrders", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "allowManualFireOrders", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "allowCoupons", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "displayNationalMenu", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "hasOnlineOrdering", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "isAcceptingOrders", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "menuIsReadOnly", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "externalReference", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "customOrderPrefix", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "minimumPickupOrder", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "minimumDeliveryOrder", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "hasFreeDelivery", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "currency", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "unavailableMessage", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "nextOpenTime", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "isOpen", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "overrideReason", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = _applyDecoratedDescriptor(_class.prototype, "disclaimers", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = _applyDecoratedDescriptor(_class.prototype, "categories", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor40 = _applyDecoratedDescriptor(_class.prototype, "loyaltySchemes", [_dec40], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor41 = _applyDecoratedDescriptor(_class.prototype, "products", [_dec41], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor42 = _applyDecoratedDescriptor(_class.prototype, "embeddedProductGroups", [_dec42], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "sortedAcceptedCreditCardTypes", [_dec43], Object.getOwnPropertyDescriptor(_class.prototype, "sortedAcceptedCreditCardTypes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "compCardsEnabled", [_dec44], Object.getOwnPropertyDescriptor(_class.prototype, "compCardsEnabled"), _class.prototype), _descriptor43 = _applyDecoratedDescriptor(_class.prototype, "toggleFavoriteAction", [_dec45], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor44 = _applyDecoratedDescriptor(_class.prototype, "preCheckAction", [_dec46], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Vendor;
});