define("mobile-web/components/r-vendor-info/component", ["exports", "@ember-decorators/component", "mobile-web/lib/dayjs", "mobile-web/lib/location/address", "mobile-web/lib/order-criteria", "mobile-web/lib/time/calendar", "mobile-web/lib/time/weekly-schedule", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some", "mobile-web/models/vendor", "mobile-web/components/r-vendor-info/style"], function (_exports, _component, _dayjs, _address, _orderCriteria, _calendar, _weeklySchedule, _, _isSome, _vendor, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const days = Object.values(_calendar.Weekday);
  const schedulePrefix = 'mwc.vendorInfo.scheduleAvailability.';

  function convertToTime(currentTimeWithTZ, targetTime) {
    //currentTimeWithTZ format: 2020-01-31T11:30:20-04:00
    //                                    |replacing|
    // Dayjs does not have good timezone support and cannot get the start of a day
    // in a certain timezone. It also requires extra plugins to support parsing.
    // So, this returns the Dayjs format of ?X?X:(?xx?)(am/pm) on the day passed in.
    let newTime;

    if (targetTime === _weeklySchedule.ScheduleAvailabilityKeywords.Midnight) {
      newTime = '00:00:00';
    } else {
      const hasMins = targetTime.includes(':');
      const isPm = targetTime.includes('pm'); // Strips postfix and converts hours to XX format

      const hours = (Number(targetTime.split(':')[0].replace(/\D/g, '')) % 12 + (isPm ? 12 : 0)).toString().padStart(2, '0');
      const mins = hasMins ? targetTime.split(':')[1].substring(0, 2) : '00';
      newTime = hours + ':' + mins + ':00';
    }

    return currentTimeWithTZ.substring(0, 11) + newTime + currentTimeWithTZ.substring(19);
  }

  function midnightToLowerCase(time) {
    return time.replace('Midnight', 'midnight');
  }

  let VendorInfo = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember.computed.alias('channel.settings.favorites'), _dec11 = Ember.computed('device.isIOS', 'vendor.address'), _dec12 = Ember.computed('(this.vendor.address).crossStreet', 'vendor.address.crossStreet'), _dec13 = Ember.computed('vendor.name'), _dec14 = Ember.computed('vendor.utcOffset'), _dec15 = Ember.computed('orderCriteria.criteria.handoffMode', 'orderCriteria.hasCriteria'), _dec16 = Ember.computed('orderCriteria.criteria.handoffMode', 'orderCriteria.hasCriteria', 'vendor.{minimumDeliveryOrder,minimumPickupOrder}'), _dec17 = Ember.computed('router.currentRouteName'), _dec18 = Ember.computed('orderCriteria.{basketOrderCriteria,criteria,searchOrderCriteria}', 'vendor'), _dec19 = Ember.computed('orderCriteria.criteria.isDefault', 'orderCriteria.hasCriteria', 'vendor.{overrideReason,utcOffset,weeklySchedule}'), _dec20 = Ember._action, _dec21 = Ember._action, _dec(_class = (_class2 = class VendorInfo extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "orderCriteria", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "basket", _descriptor5, this);

      _initializerDefineProperty(this, "error", _descriptor6, this);

      _initializerDefineProperty(this, "channel", _descriptor7, this);

      _initializerDefineProperty(this, "device", _descriptor8, this);

      this.showMenuLink = false;
      this.style = _style.default;

      _initializerDefineProperty(this, "allowFavorites", _descriptor9, this);
    }

    get mapsUrl() {
      return _address.default.toMapsApplicationLink(this.vendor.address, this.device.isIOS);
    }

    get crossStreet() {
      const crossStreet = this.vendor.address.crossStreet;
      return (0, _.isEmpty)(crossStreet) ? '' : `(${crossStreet})`;
    }

    get title() {
      return `${this.vendor.name} Menu`;
    }

    get showTimezoneWarning() {
      return (0, _dayjs.default)().utcOffset() !== this.vendor.utcOffset * 60;
    }

    get handoffMinTagDescription() {
      // minimumDeliveryOrder and minimumPickupOrder are only enforced
      // by the supportedModes below
      const supportedModes = ['CounterPickup', 'CurbsidePickup', 'Delivery'];

      if (this.orderCriteria.criteria) {
        const handoff = this.orderCriteria.criteria.handoffMode;
        return supportedModes.includes(handoff) ? (0, _orderCriteria.getHandoffLabel)(handoff, this.store) : undefined;
      }

      return undefined;
    }

    get handoffMinTagAmount() {
      if (this.orderCriteria.criteria) {
        const handoff = this.orderCriteria.criteria.handoffMode;
        return handoff === 'Delivery' ? this.vendor.minimumDeliveryOrder : this.vendor.minimumPickupOrder;
      }

      return undefined;
    }

    get showInfoAsLink() {
      return this.router.currentRouteName === 'menu.vendor.index';
    }

    get vendorDistance() {
      if (this.orderCriteria.criteria) {
        var _oc$deliveryAddress;

        const oc = this.orderCriteria.criteria;

        if ((0, _orderCriteria.isDelivery)(oc) && (_oc$deliveryAddress = oc.deliveryAddress) != null && _oc$deliveryAddress.id) {
          const address = this.store.peekRecord('address', oc.deliveryAddress.id);

          if ((0, _isSome.isSome)(address)) {
            try {
              return (0, _address.distanceBetweenCoords)(address, this.vendor);
            } catch (e) {
              this.error.sendExternalError(e);
            }
          }
        }
      }

      return undefined;
    }

    get availabilityText() {
      if (this.vendor.overrideReason) {
        return (0, _vendor.getShortOverrideDesc)(this.vendor.overrideReason, this.intl);
      }

      const schedule = this.vendor.weeklySchedule;
      let weekSchedule = schedule.calendars.find(c => c.scheduleDescription === _calendar.CalendarType.Business) || schedule.calendars[0];
      let mode;

      if (!this.orderCriteria.criteria.isDefault) {
        const oc = this.orderCriteria.criteria;
        mode = (0, _orderCriteria.getHandoffLabel)(oc.handoffMode, this.store);
        const ocWeekSchedule = schedule.calendars.find(c => c.scheduleDescription === mode);

        if (ocWeekSchedule) {
          weekSchedule = ocWeekSchedule;
        } else if (oc.handoffMode !== 'CounterPickup') {
          // When not using a handoff specific schedule, do not display
          // handoff specific message - except with Counter Pickup,
          // as it derives from the Business schedule
          mode = undefined;
        }
      } // All times based around vendor time zone


      const userTimeAtStore = (0, _dayjs.default)().utcOffset(this.vendor.utcOffset);
      let currentWeekDay = schedule.currentWeekDay;

      if (userTimeAtStore.day() !== days.indexOf(currentWeekDay)) {
        // This should never happen. The day at the store must be the day for the user
        // at the store in the store's timezone.
        // Could send at around midnight for user if not refreshed,
        // so want to restore to "fresh" value if required
        this.error.sendExternalError(new Error('Vendor current weekday incongruent with user time'));
        currentWeekDay = days[userTimeAtStore.day()];
      } // currentWeekDay is already relative to vendor time zone


      const hours = (0, _weeklySchedule.hoursForDay)(currentWeekDay, weekSchedule);

      if (hours === _weeklySchedule.ScheduleAvailabilityKeywords.Closed) {
        return mode ? this.intl.t(schedulePrefix + 'handoffTodayClosed', {
          mode
        }) : this.intl.t(schedulePrefix + 'todayClosed');
      }

      let start = '',
          end = '',
          startTime = '',
          endTime = '';

      if (hours !== _weeklySchedule.ScheduleAvailabilityKeywords.AllDay) {
        start = hours.split('-')[0];
        end = hours.split('-')[1];
        startTime = convertToTime(userTimeAtStore.format(), start);
        endTime = // Ending at midnight is actually 12am on the next day
        end === _weeklySchedule.ScheduleAvailabilityKeywords.Midnight ? convertToTime(userTimeAtStore.add(1, 'd').format(), end) : convertToTime(userTimeAtStore.format(), end);
      } // Workaround for MWC-3921
      // to support multi-day availability spans


      if (hours.includes(',')) {
        return mode ? this.intl.t(schedulePrefix + 'handoffTodayMultiRange', {
          mode,
          range: hours
        }) : this.intl.t(schedulePrefix + 'todayMultiRange', {
          range: hours
        });
      } else if (hours.includes('(') || (0, _dayjs.default)(startTime).unix() > (0, _dayjs.default)(endTime).unix()) {
        // Two day spans wrapped in parentheses when hour overlap
        // Otherwise, end hour must be < start hour
        start = midnightToLowerCase(start);
        end = midnightToLowerCase(end);
        return mode ? this.intl.t(schedulePrefix + 'handoffTodayOpenNowVerbose', {
          mode,
          start,
          end
        }) : this.intl.t(schedulePrefix + 'todayOpenNow', {
          start,
          end
        });
      }

      if (hours === _weeklySchedule.ScheduleAvailabilityKeywords.AllDay || (0, _dayjs.default)().isBetween(startTime, endTime) && end === _weeklySchedule.ScheduleAvailabilityKeywords.Midnight) {
        return this.consecutiveDayAvailabilityMessaging(weekSchedule, mode, currentWeekDay, start, end);
      }

      start = midnightToLowerCase(start);
      end = midnightToLowerCase(end);

      if ((0, _dayjs.default)().isBetween(startTime, endTime)) {
        return mode ? this.intl.t(schedulePrefix + 'handoffTodayOpenNow', {
          mode,
          end
        }) : this.intl.t(schedulePrefix + 'todayOpenNow', {
          start,
          end
        });
      } else if ((0, _dayjs.default)().isBefore(startTime)) {
        return mode ? this.intl.t(schedulePrefix + 'handoffBeforeOpening', {
          mode,
          start
        }) : this.intl.t(schedulePrefix + 'beforeOpening', {
          start
        });
      }

      return mode ? this.intl.t(schedulePrefix + 'handoffNowTodayClosed', {
        mode
      }) : this.intl.t(schedulePrefix + 'nowTodayClosed');
    }

    init() {
      super.init();
      (false && !((0, _isSome.isSome)(this.vendor)) && Ember.assert('VendorInfo: `vendor` is required', (0, _isSome.isSome)(this.vendor)));
      (false && !(this.allowFavorites ? (0, _.isFunction)(this.onToggleFavorite) : true) && Ember.assert('VendorInfo: `onToggleFavorite` function is required if `allowFavorites` is true', this.allowFavorites ? (0, _.isFunction)(this.onToggleFavorite) : true));
    }

    closeInfoModal() {
      Ember.set(this, 'showInfoModal', undefined);
    }

    viewMenu() {
      this.router.transitionTo('menu.vendor', this.vendor.slug);
    }

    consecutiveDayAvailabilityMessaging(weekSchedule, mode, currentWeekDay, start, end) {
      // Checking for 24/7 availability
      if (weekSchedule.schedule.filterBy('description', _weeklySchedule.ScheduleAvailabilityKeywords.AllDay).length === days.length) {
        return mode ? this.intl.t(schedulePrefix + 'handoffOpen24/7', {
          mode
        }) : this.intl.t(schedulePrefix + 'open24/7');
      }

      const index = days.indexOf(currentWeekDay);
      let pointer = -1;

      for (let i = 1; i < days.length; i++) {
        pointer = (i + index) % days.length;
        const dayHours = (0, _weeklySchedule.hoursForDay)(days[pointer], weekSchedule);

        if (dayHours !== _weeklySchedule.ScheduleAvailabilityKeywords.AllDay && (dayHours === _weeklySchedule.ScheduleAvailabilityKeywords.Closed || dayHours.split('-')[0] !== _weeklySchedule.ScheduleAvailabilityKeywords.Midnight)) {
          pointer = (i - 1 + index) % days.length;
          break;
        } else if (dayHours !== _weeklySchedule.ScheduleAvailabilityKeywords.AllDay) {
          break;
        }
      }

      start = midnightToLowerCase(start);
      end = midnightToLowerCase(end);
      const day = days[pointer];
      const lastConsecutiveOpenDayHours = (0, _weeklySchedule.hoursForDay)(day, weekSchedule);

      if (day === currentWeekDay) {
        if (lastConsecutiveOpenDayHours === _weeklySchedule.ScheduleAvailabilityKeywords.AllDay) {
          return mode ? this.intl.t(schedulePrefix + 'handoffTodayOpenAllDay', {
            mode
          }) : this.intl.t(schedulePrefix + 'todayOpenAllDay');
        }

        return mode ? this.intl.t(schedulePrefix + 'handoffTodayOpenNow', {
          mode,
          end
        }) : this.intl.t(schedulePrefix + 'todayOpenNow', {
          start,
          end
        });
      }

      if (lastConsecutiveOpenDayHours === _weeklySchedule.ScheduleAvailabilityKeywords.AllDay) {
        return mode ? this.intl.t(schedulePrefix + 'handoffConsecutiveAllDay', {
          mode,
          day
        }) : this.intl.t(schedulePrefix + 'consecutiveAllDay', {
          day
        });
      }

      end = lastConsecutiveOpenDayHours.split('-')[1];
      return mode ? this.intl.t(schedulePrefix + 'handoffConsecutiveTime', {
        mode,
        end,
        day
      }) : this.intl.t(schedulePrefix + 'consecutiveTime', {
        end,
        day
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "orderCriteria", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "basket", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "error", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "channel", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "device", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "allowFavorites", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "mapsUrl", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "mapsUrl"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "crossStreet", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "crossStreet"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "title", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "title"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "showTimezoneWarning", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "showTimezoneWarning"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handoffMinTagDescription", [_dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "handoffMinTagDescription"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handoffMinTagAmount", [_dec16], Object.getOwnPropertyDescriptor(_class2.prototype, "handoffMinTagAmount"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "showInfoAsLink", [_dec17], Object.getOwnPropertyDescriptor(_class2.prototype, "showInfoAsLink"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "vendorDistance", [_dec18], Object.getOwnPropertyDescriptor(_class2.prototype, "vendorDistance"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "availabilityText", [_dec19], Object.getOwnPropertyDescriptor(_class2.prototype, "availabilityText"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "closeInfoModal", [_dec20], Object.getOwnPropertyDescriptor(_class2.prototype, "closeInfoModal"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "viewMenu", [_dec21], Object.getOwnPropertyDescriptor(_class2.prototype, "viewMenu"), _class2.prototype)), _class2)) || _class);
  _exports.default = VendorInfo;
});