define("mobile-web/components/r-sticky-element/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HHT+48wj",
    "block": "[[[10,0],[15,1,[30,0,[\"uniqueId\"]]],[15,0,[28,[37,0],[[30,0,[\"style\",\"placeholder\"]],\" \",[30,1]],null]],[14,\"data-test-stickyElement-placeholder\",\"\"],[12],[1,\"\\n  \"],[10,0],[14,\"data-test-stickyElement\",\"\"],[15,0,[30,0,[\"stickyElementClass\"]]],[15,5,[30,0,[\"stickyStyle\"]]],[12],[1,\"\\n    \"],[18,2,[[28,[37,2],null,[[\"isSticky\"],[[28,[37,3],[[30,0,[\"isSticky\"]],false],null]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@placeholderClass\",\"&default\"],false,[\"concat\",\"yield\",\"hash\",\"not-eq\"]]",
    "moduleName": "mobile-web/components/r-sticky-element/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});