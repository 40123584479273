define("mobile-web/components/routes/order-summary-route/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k9KUvlv6",
    "block": "[[[10,0],[14,\"data-test-routes-orderSummaryRoute\",\"\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@order\",\"@showSocialIntegration\",\"@allowReorder\",\"@onUserOrderEvent\",\"@onCancelOrder\",\"@saveFavorite\"],[[30,1],false,true,[28,[37,1],[[30,0],[30,0,[\"onUserOrderEvent\"]]],null],[28,[37,1],[[30,0],[30,0,[\"onCancelOrder\"]]],null],[30,0,[\"saveFavorite\"]]]],null],[1,\"\\n\\n\"],[13],[1,\"\\n\"]],[\"@order\"],false,[\"r-post-checkout\",\"action\"]]",
    "moduleName": "mobile-web/components/routes/order-summary-route/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});