define("mobile-web/components/r-menu-category/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "imageContainer": "_imageContainer_qmuk4f",
    "image": "_image_qmuk4f",
    "carouselPlaceholder": "_carouselPlaceholder_qmuk4f",
    "productPlaceholder": "_productPlaceholder_qmuk4f"
  };
  _exports.default = _default;
});