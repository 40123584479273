define("mobile-web/components/r-svg/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a8CJivYu",
    "block": "[[[11,1],[16,0,[30,0,[\"containerClass\"]]],[16,\"data-test-svg\",[30,1]],[17,2],[12],[1,\"\\n\"],[1,\"  \"],[1,[28,[35,0],[[30,0,[\"iconName\"]]],[[\"aria-label\",\"class\",\"role\"],[[30,0,[\"ariaLabel\"]],[30,0,[\"iconClass\"]],[30,0,[\"role\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@icon\",\"&attrs\"],false,[\"svg-jar\"]]",
    "moduleName": "mobile-web/components/r-svg/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});