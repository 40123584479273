define("mobile-web/components/r-account-button/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "favoriteOrders": "_favoriteOrders_twqq3v",
    "embeddedRecentOrders": "_embeddedRecentOrders_twqq3v",
    "embeddedFavoriteOrders": "_embeddedFavoriteOrders_twqq3v",
    "embeddedAccountSettings": "_embeddedAccountSettings_twqq3v",
    "embeddedOffersInbox": "_embeddedOffersInbox_twqq3v",
    "embeddedSignOut": "_embeddedSignOut_twqq3v"
  };
  _exports.default = _default;
});