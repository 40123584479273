define("mobile-web/components/r-checkout/additional-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U67aRFTr",
    "block": "[[[8,[39,0],null,[[\"@defaultMessage\",\"@key\"],[\"mwc.checkout.additionalDetailsHeader\",\"RESP_CUSTOM_FIELDS_LABEL\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@name\",\"@header\"],[\"Additional Details\",[30,1,[\"value\"]]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,2]],null]],null],null,[[[1,\"      \"],[8,[39,4],[[24,\"data-test-checkout-additionalDetails-customField\",\"\"]],[[\"@class\",\"@name\",\"@label\",\"@inputValue\",\"@maxlength\",\"@optional\",\"@showCount\",\"@validationMessages\"],[[30,0,[\"style\",\"field\"]],[28,[37,5],[\"customField-\",[30,3,[\"id\"]]],null],[30,3,[\"label\"]],[30,3,[\"value\"]],[30,3,[\"maxLength\"]],[28,[37,6],[[30,3,[\"isRequired\"]]],null],true,[30,3,[\"valueMessages\"]]]],null],[1,\"\\n\"]],[3]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"x\",\"@customFields\",\"customField\"],false,[\"x-content\",\"r-editable-section\",\"each\",\"-track-array\",\"r-form-field\",\"concat\",\"not\"]]",
    "moduleName": "mobile-web/components/r-checkout/additional-details/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});