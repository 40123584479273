define("mobile-web/templates/components/r-icons/r-restaurant-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/52Cxo+P",
    "block": "[[[10,0],[15,0,[36,0]],[12],[1,\"\\n  \"],[1,[28,[35,1],[\"r-restaurant-menu\"],[[\"class\"],[[30,0,[\"style\",\"icon\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"rootClass\",\"svg-jar\"]]",
    "moduleName": "mobile-web/templates/components/r-icons/r-restaurant-menu.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});