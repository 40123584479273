define("mobile-web/components/r-no-vendor-results/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wN8ycn6p",
    "block": "[[[10,0],[14,\"data-test-vendor-search\",\"no-results\"],[15,0,[30,0,[\"wrapperClass\"]]],[12],[1,\"\\n\"],[41,[33,1],[[[1,\"    \"],[10,2],[14,\"data-test-vendor-search\",\"no-search\"],[12],[1,\"\\n     \"],[1,[28,[35,2],[\"mwc.vendorCard.enterAddressMessage\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[33,3],[[[1,\"    \"],[10,2],[14,\"data-test-vendor-search\",\"no-search\"],[15,0,[30,0,[\"style\",\"content\"]]],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"mwc.locations.noResults\"],null]],[1,\"\\n      \"],[8,[39,4],[[24,\"data-test-link-to-locations\",\"\"],[4,[38,5],[\"click\",[28,[37,6],[\"View All Locations?\",[28,[37,7],null,[[\"Link Location\"],[\"No Results Page\"]]]],null]],null]],[[\"@route\"],[\"locations\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,2],[\"mwc.locations.noResultsReturnLink\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,2],[14,\"data-test-vendor-search\",\"no-search\"],[15,0,[30,0,[\"style\",\"content\"]]],[12],[1,\"\\n      \"],[1,[28,[35,8],null,[[\"type\",\"key\",\"defaultMessage\"],[\"html\",\"NO_VENDORS_NEAR_USER\",\"Either edit your search or broaden your search radius to find locations that work for you.\"]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]],[]]],[13]],[],false,[\"if\",\"noSearch\",\"t\",\"noRegionResults\",\"r-link-to\",\"on\",\"track-event\",\"hash\",\"x-content\"]]",
    "moduleName": "mobile-web/components/r-no-vendor-results/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});