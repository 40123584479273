define("mobile-web/routes/locations", ["exports", "mobile-web/config/environment", "mobile-web/lib/state"], function (_exports, _environment, _state) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LocationsRoute = (_dec = Ember.inject.service, (_class = class LocationsRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "channel", _descriptor, this);

      this.titleToken = 'Locations';
    }

    beforeModel(transition) {
      var _this$channel$setting;

      if (!((_this$channel$setting = this.channel.settings) != null && _this$channel$setting.showParticipatingRestaurants)) {
        transition.abort();
        this.transitionTo('index');
      }
    }

    model() {
      const modelRegions = this.store.findAll('region', {});
      const map = modelRegions.then(async regions => {
        const countries = new Set();
        const allStates = (0, _state.allStatesWithCountry)();
        regions.forEach(region => countries.add(allStates[region.id].countryCode));
        const mapSrc = `${_environment.default.scriptBaseUrl}assets/images/locations-map/r-${Array.from(countries).sort().join('-')}.svg`;

        try {
          var _svg$outerHTML;

          const r = await fetch(mapSrc);

          if (!r.ok) {
            return '';
          }

          const markup = await r.text();
          const fragment = document.createRange().createContextualFragment(markup);
          const svg = fragment.querySelector('svg');
          svg == null ? void 0 : svg.setAttribute('role', 'presentation');
          return (_svg$outerHTML = svg == null ? void 0 : svg.outerHTML) != null ? _svg$outerHTML : '';
        } catch (e) {
          // Allows loading of route despite failed non-essential asset load.
          return '';
        }
      });
      return Ember.RSVP.hash({
        regions: modelRegions,
        map
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = LocationsRoute;
});