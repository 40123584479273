define("mobile-web/models/order", ["exports", "ember-data", "lodash.pick", "mobile-web/adapters/order", "mobile-web/decorators/member-action", "mobile-web/lib/dayjs", "mobile-web/lib/order-criteria", "mobile-web/models/basket", "mobile-web/models/basket-product"], function (_exports, _emberData, _lodash, _order, _memberAction, _dayjs, _orderCriteria, _basket, _basketProduct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.OrderStatus = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _dec43, _dec44, _dec45, _dec46, _dec47, _dec48, _dec49, _dec50, _dec51, _dec52, _dec53, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39, _descriptor40, _descriptor41, _descriptor42, _descriptor43, _descriptor44, _descriptor45, _descriptor46, _descriptor47, _descriptor48, _descriptor49;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    attr,
    belongsTo,
    hasMany,
    Model
  } = _emberData.default; // https://github.com/ololabs/platform/blob/develop/MoboLogic/src/Data%20Structures/Enums.cs

  let OrderStatus;
  _exports.OrderStatus = OrderStatus;

  (function (OrderStatus) {
    OrderStatus["Pending"] = "Pending";
    OrderStatus["New"] = "New";
    OrderStatus["FaxPending"] = "FaxPending";
    OrderStatus["Emailed"] = "Emailed";
    OrderStatus["InProgress"] = "InProgress";
    OrderStatus["Closed"] = "Closed";
    OrderStatus["Failed"] = "Failed";
    OrderStatus["Cancelled"] = "Cancelled";
    OrderStatus["Scheduled"] = "Scheduled";
  })(OrderStatus || (_exports.OrderStatus = OrderStatus = {}));

  let OrderModel = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = attr('string'), _dec5 = attr('string'), _dec6 = attr('boolean'), _dec7 = attr('boolean'), _dec8 = attr('boolean'), _dec9 = attr('boolean'), _dec10 = attr('boolean'), _dec11 = attr('string'), _dec12 = attr('number'), _dec13 = attr('array'), _dec14 = attr('number'), _dec15 = attr('string'), _dec16 = attr('string'), _dec17 = attr('string'), _dec18 = attr('boolean'), _dec19 = attr('boolean'), _dec20 = attr('string'), _dec21 = attr('string'), _dec22 = attr('string'), _dec23 = attr('string'), _dec24 = attr('number'), _dec25 = attr('string'), _dec26 = attr('number'), _dec27 = attr('string'), _dec28 = attr('string'), _dec29 = attr('string'), _dec30 = attr('number'), _dec31 = attr('number'), _dec32 = attr('number'), _dec33 = attr('string'), _dec34 = attr('number'), _dec35 = attr('number'), _dec36 = attr('boolean', {
    defaultValue: false
  }), _dec37 = _emberData.default.attr('array'), _dec38 = _emberData.default.attr('number'), _dec39 = attr('boolean', {
    defaultValue: false
  }), _dec40 = _emberData.default.attr('array', {
    defaultValue: () => []
  }), _dec41 = belongsTo('vendor', {
    async: false
  }), _dec42 = hasMany('basket-product', {
    async: false
  }), _dec43 = hasMany('billing-component', {
    async: false
  }), _dec44 = hasMany('dispatch-status'), _dec45 = belongsTo('order-failure', {
    async: false
  }), _dec46 = _emberData.default.belongsTo('address', {
    async: false
  }), _dec47 = Ember.computed('deliveryMode'), _dec48 = Ember.computed('deliveryMode'), _dec49 = Ember.computed('deliveryMode', 'supportedArrivalMessageHandoffModes'), _dec50 = Ember.computed('canManualFire', 'canSupportArrival', 'timeReadyLocal'), _dec51 = (0, _memberAction.default)({
    type: 'post',
    urlType: _order.OrderRequestType.Reorder,
    after: _basket.pushBasketPayload
  }), _dec52 = (0, _memberAction.default)({
    type: 'post',
    urlType: _order.OrderRequestType.Edit,
    after: _basket.pushBasketPayload
  }), _dec53 = (0, _memberAction.default)({
    type: 'post',
    urlType: _order.OrderRequestType.Arrival
  }), (_class = class OrderModel extends Model {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "bus", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "mwcIntl", _descriptor3, this);

      _initializerDefineProperty(this, "orderGuid", _descriptor4, this);

      _initializerDefineProperty(this, "displayId", _descriptor5, this);

      _initializerDefineProperty(this, "canCancel", _descriptor6, this);

      _initializerDefineProperty(this, "canManualFire", _descriptor7, this);

      _initializerDefineProperty(this, "canModify", _descriptor8, this);

      _initializerDefineProperty(this, "canReorder", _descriptor9, this);

      _initializerDefineProperty(this, "canUserCheckin", _descriptor10, this);

      _initializerDefineProperty(this, "currency", _descriptor11, this);

      _initializerDefineProperty(this, "currentTotal", _descriptor12, this);

      _initializerDefineProperty(this, "customFieldValues", _descriptor13, this);

      _initializerDefineProperty(this, "deliveryCharge", _descriptor14, this);

      _initializerDefineProperty(this, "deliveryMode", _descriptor15, this);

      _initializerDefineProperty(this, "feedbackUrl", _descriptor16, this);

      _initializerDefineProperty(this, "handoffDescription", _descriptor17, this);

      _initializerDefineProperty(this, "isAdvance", _descriptor18, this);

      _initializerDefineProperty(this, "isFavorite", _descriptor19, this);

      _initializerDefineProperty(this, "manualFireHandoffInstructions", _descriptor20, this);

      _initializerDefineProperty(this, "orderHash", _descriptor21, this);

      _initializerDefineProperty(this, "status", _descriptor22, this);

      _initializerDefineProperty(this, "statusDescription", _descriptor23, this);

      _initializerDefineProperty(this, "subTotal", _descriptor24, this);

      _initializerDefineProperty(this, "thanksHandoffInstructions", _descriptor25, this);

      _initializerDefineProperty(this, "tip", _descriptor26, this);

      _initializerDefineProperty(this, "timePlacedLocal", _descriptor27, this);

      _initializerDefineProperty(this, "timePlacedUtc", _descriptor28, this);

      _initializerDefineProperty(this, "timeReadyLocal", _descriptor29, this);

      _initializerDefineProperty(this, "totalCost", _descriptor30, this);

      _initializerDefineProperty(this, "utcOffset", _descriptor31, this);

      _initializerDefineProperty(this, "vendorDiscount", _descriptor32, this);

      _initializerDefineProperty(this, "vendorName", _descriptor33, this);

      _initializerDefineProperty(this, "vendorTax", _descriptor34, this);

      _initializerDefineProperty(this, "vendorTotal", _descriptor35, this);

      _initializerDefineProperty(this, "guestNewsletterOptIn", _descriptor36, this);

      _initializerDefineProperty(this, "fees", _descriptor37, this);

      _initializerDefineProperty(this, "feesTotal", _descriptor38, this);

      _initializerDefineProperty(this, "canSupportArrival", _descriptor39, this);

      _initializerDefineProperty(this, "supportedArrivalMessageHandoffModes", _descriptor40, this);

      _initializerDefineProperty(this, "vendor", _descriptor41, this);

      _initializerDefineProperty(this, "basketProducts", _descriptor42, this);

      _initializerDefineProperty(this, "billingComponents", _descriptor43, this);

      _initializerDefineProperty(this, "dispatchStatuses", _descriptor44, this);

      _initializerDefineProperty(this, "orderFailure", _descriptor45, this);

      _initializerDefineProperty(this, "deliveryAddress", _descriptor46, this);

      _initializerDefineProperty(this, "reorder", _descriptor47, this);

      _initializerDefineProperty(this, "edit", _descriptor48, this);

      _initializerDefineProperty(this, "arrived", _descriptor49, this);
    }

    get isDelivery() {
      return _orderCriteria.DELIVERY_MODE_KEYS.includes(this.deliveryMode);
    }

    get isDriveThru() {
      return this.deliveryMode === _orderCriteria.DRIVETHRU_KEY;
    }

    get requireArrivalMessage() {
      return this.supportedArrivalMessageHandoffModes.includes(this.deliveryMode);
    }

    get isPastOrder() {
      return (!this.canManualFire || !this.canSupportArrival) && (0, _dayjs.default)().subtract(1, 'day').isAfter(this.timeReadyLocal);
    }

    // See EcommerceOrderModel
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    serializeForEcommerce() {
      const order = this.serialize();
      const vendor = this.vendor;
      const transactionId = this.id;
      const transactionAffiliation = order.vendorName;
      const transactionTotal = (order.vendorTotal || 0).toFixed(2);
      const transactionTax = (order.vendorTax || 0).toFixed(2);
      const transactionShipping = '0.00';
      const vendorAddress = vendor.address;
      const transactionZipCode = vendorAddress ? vendorAddress.postalCode : undefined;
      const transactionStoreNumber = order.vendor;
      const transactionStoreReference = vendor.externalReference;
      const transactionHandoff = order.handoffDescription;
      const transactionRevenue = (order.subTotal || 0).toFixed(2);
      const transactionCurrency = this.currency;
      const basketProducts = order.basketProducts || [];
      const transactionProducts = (0, _basketProduct.combineMatchingBasketProductsForEcommerce)(basketProducts.map(bp => this.store.peekRecord('basket-product', bp)).filter(product => product).map(product => product.serializeForEcommerce(transactionId)));
      return {
        transactionId,
        transactionAffiliation,
        transactionTotal,
        transactionTax,
        transactionShipping,
        transactionZipCode,
        transactionStoreNumber,
        transactionStoreReference,
        transactionProducts,
        transactionHandoff,
        transactionRevenue,
        transactionCurrency
      };
    }

    serializeForGlobalData() {
      return _extends({}, (0, _lodash.default)(this, 'id', 'orderGuid', 'displayId', 'currency', 'currentTotal', 'deliveryCharge', 'deliveryMode', 'handoffDescription', 'isAdvance', 'isFavorite', 'manualFireHandoffInstructions', 'status', 'statusDescription', 'subTotal', 'thanksHandoffInstructions', 'tip', 'timePlacedLocal', 'timePlacedUtc', 'timeReadyLocal', 'totalCost', 'utcOffset', 'vendorDiscount', 'vendorName', 'vendorTax', 'vendorTotal', 'guestNewsletterOptIn', 'fees', 'feesTotal'), {
        basketProducts: // eslint-disable-next-line ember/no-get, ember/classic-decorator-no-classic-methods
        this.get('isLoaded') && this.basketProducts.map(bp => bp.serializeForGlobalData()),
        // eslint-disable-next-line ember/no-get, ember/classic-decorator-no-classic-methods
        vendor: this.get('isLoaded') && this.vendor.serializeForGlobalData()
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "bus", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "mwcIntl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "orderGuid", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "displayId", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "canCancel", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "canManualFire", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "canModify", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "canReorder", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "canUserCheckin", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "currency", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "currentTotal", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "customFieldValues", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "deliveryCharge", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "deliveryMode", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "feedbackUrl", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "handoffDescription", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "isAdvance", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "isFavorite", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "manualFireHandoffInstructions", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "orderHash", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "statusDescription", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "subTotal", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "thanksHandoffInstructions", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "tip", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "timePlacedLocal", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "timePlacedUtc", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "timeReadyLocal", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "totalCost", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "utcOffset", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "vendorDiscount", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "vendorName", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "vendorTax", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "vendorTotal", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "guestNewsletterOptIn", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "fees", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = _applyDecoratedDescriptor(_class.prototype, "feesTotal", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = _applyDecoratedDescriptor(_class.prototype, "canSupportArrival", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor40 = _applyDecoratedDescriptor(_class.prototype, "supportedArrivalMessageHandoffModes", [_dec40], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor41 = _applyDecoratedDescriptor(_class.prototype, "vendor", [_dec41], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor42 = _applyDecoratedDescriptor(_class.prototype, "basketProducts", [_dec42], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor43 = _applyDecoratedDescriptor(_class.prototype, "billingComponents", [_dec43], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor44 = _applyDecoratedDescriptor(_class.prototype, "dispatchStatuses", [_dec44], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor45 = _applyDecoratedDescriptor(_class.prototype, "orderFailure", [_dec45], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor46 = _applyDecoratedDescriptor(_class.prototype, "deliveryAddress", [_dec46], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isDelivery", [_dec47], Object.getOwnPropertyDescriptor(_class.prototype, "isDelivery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isDriveThru", [_dec48], Object.getOwnPropertyDescriptor(_class.prototype, "isDriveThru"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "requireArrivalMessage", [_dec49], Object.getOwnPropertyDescriptor(_class.prototype, "requireArrivalMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isPastOrder", [_dec50], Object.getOwnPropertyDescriptor(_class.prototype, "isPastOrder"), _class.prototype), _descriptor47 = _applyDecoratedDescriptor(_class.prototype, "reorder", [_dec51], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor48 = _applyDecoratedDescriptor(_class.prototype, "edit", [_dec52], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor49 = _applyDecoratedDescriptor(_class.prototype, "arrived", [_dec53], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OrderModel;
});