define("mobile-web/components/routes/secure/change-password-route/component", ["exports", "@ember-decorators/component", "ember-concurrency", "mobile-web/lib/utilities/_", "mobile-web/lib/validation", "mobile-web/services/user-feedback", "mobile-web/components/routes/secure/change-password-route/style"], function (_exports, _component, _emberConcurrency, _, _validation, _userFeedback, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChangePasswordRoute = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.computed('currentPassword', 'newPassword', 'confirmPassword'), _dec9 = Ember._action, _dec(_class = (_class2 = class ChangePasswordRoute extends Ember.Component.extend({
    changePasswordTask: (0, _emberConcurrency.task)(function* () {
      this.set('validationResult', _validation.default.validate(this, this.validationConfig));

      if (this.validationResult.isOk()) {
        try {
          yield this.store.collectionAction('user', 'changePassword', {
            currentPassword: this.currentPassword,
            newPassword: this.newPassword,
            confirmPassword: this.confirmPassword
          });
          this.userFeedback.add({
            type: _userFeedback.Type.Positive,
            title: this.intl.t('mwc.changePassword.successTitle'),
            message: this.intl.t('mwc.changePassword.successMessage')
          });
          yield this.session.logout();
          this.router.transitionTo('secure.login');
        } catch (e) {
          this.error.reportError(e);
        }
      }
    })
  }) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "userFeedback", _descriptor3, this);

      _initializerDefineProperty(this, "error", _descriptor4, this);

      _initializerDefineProperty(this, "session", _descriptor5, this);

      _initializerDefineProperty(this, "router", _descriptor6, this);

      this.currentPassword = '';
      this.newPassword = '';
      this.confirmPassword = '';
      this.validationConfig = {
        bindings: [{
          targetProp: 'newPassword',
          ruleName: 'minLength',
          message: this.intl.t('mwc.changePassword.errors.minLength')
        }, {
          targetProp: 'confirmPassword',
          ruleName: 'match',
          match: 'newPassword',
          message: this.intl.t('mwc.changePassword.errors.passwordsMatch')
        }]
      };
      this.style = _style.default;
    }

    // Computed properties
    get isSubmitDisabled() {
      return (0, _.isEmpty)(this.currentPassword) || (0, _.isEmpty)(this.newPassword) || (0, _.isEmpty)(this.confirmPassword);
    } // Init
    // Other methods
    // Actions


    onCancel() {
      this.router.transitionTo('secure.my-account');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "userFeedback", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "error", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "isSubmitDisabled", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "isSubmitDisabled"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onCancel", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "onCancel"), _class2.prototype)), _class2)) || _class);
  _exports.default = ChangePasswordRoute;
});