define("mobile-web/routes/order-summary", ["exports", "mobile-web/decorators/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  let OrderSummaryRoute = (0, _authenticated.default)(_class = class OrderSummaryRoute extends Ember.Route {
    constructor(...args) {
      super(...args);
      this.previousRoute = {
        label: 'Recent Orders',
        route: 'recent-orders',
        models: []
      };
    }

    titleToken(model) {
      return `Order #${model.order.get('displayId')} Summary`;
    }

    model(params) {
      return this.store.findRecord('order', params.order_id, {
        reload: true
      }).then(order => {
        const failure = order.get('orderFailure');
        return Ember.RSVP.hash({
          order,
          failure
        });
      });
    }

  }) || _class;

  _exports.default = OrderSummaryRoute;
});