define("mobile-web/components/r-cart/choice/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "mobile-web/lib/utilities/is-some", "mobile-web/models/basket-choice", "mobile-web/components/r-cart/choice/style"], function (_exports, _glimmerComponent, _isSome, _basketChoice, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CartChoice extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get customFieldValue() {
      if ((0, _basketChoice.isBasketChoice)(this.args.basketChoice)) {
        const basketProduct = this.args.basketChoice.basketProduct; // Even though the types indicate that basketProduct is always set,
        // there is one scenario where it is possible it isn't - if we are
        // removing a product, we might get here before the component is
        // removed. So, a quick sanity check keeps us from null crashing
        // into a wall of tears and sadness.

        if ((0, _isSome.default)(basketProduct)) {
          const matchingValues = basketProduct.customValues.filter(c => (0, _basketChoice.isBasketChoice)(this.args.basketChoice) && c.choiceId.toString() === this.args.basketChoice.belongsTo('choice').id());
          return matchingValues.length ? matchingValues[0].value : '';
        }
      }

      return '';
    }

    get quantity() {
      return (0, _basketChoice.isBasketChoice)(this.args.basketChoice) && this.args.basketChoice.savedAttributes.quantity > 1 ? `${this.args.basketChoice.savedAttributes.quantity}` : '';
    } // Constructor
    // Other methods
    // Tasks
    // Actions


  }

  _exports.default = CartChoice;
});