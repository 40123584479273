define("mobile-web/routes/application", ["exports", "mobile-web/config/environment", "mobile-web/lib/hybrid-util", "mobile-web/lib/perimeterx", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/clear-raygun"], function (_exports, _environment, _hybridUtil, _perimeterx, _, _clearRaygun) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function getMwcRoot() {
    var _find;

    return (_find = [...document.querySelectorAll('script')].find(x => /\/vendor/i.test(x.src))) == null ? void 0 : _find.src.replace(/\/assets\/vendor.*/, '/');
  }

  function flexGapSupported() {
    var _flex$parentNode;

    // Create a flex container with row-gap set
    const flex = document.createElement('div');
    flex.style.display = 'flex';
    flex.style.flexDirection = 'column';
    flex.style.rowGap = '1px';
    flex.style.position = 'absolute'; // Create two, elements inside it

    flex.appendChild(document.createElement('div'));
    flex.appendChild(document.createElement('div')); // Append to the DOM (needed to obtain scrollHeight)

    document.body.appendChild(flex); // Flex container should be 1px high due to the row-gap

    const isSupported = flex.scrollHeight === 1; // Remove element from the DOM after you are done with it

    (_flex$parentNode = flex.parentNode) == null ? void 0 : _flex$parentNode.removeChild(flex);
    return isSupported;
  }

  function optionalFeatureSetup() {
    if (flexGapSupported()) {
      document.documentElement.classList.add('flexbox-gap');
    } else {
      document.documentElement.classList.add('no-flexbox-gap');
    }
  }

  function focusRingSetup() {
    document.documentElement.classList.add('no-focus-outline');
    document.body.addEventListener('keyup', e => {
      /* tab */
      if (e.which === 9) {
        document.documentElement.classList.remove('no-focus-outline');
      }
    });
    document.body.addEventListener('mousedown', () => {
      document.documentElement.classList.add('no-focus-outline');
    });
    document.body.addEventListener('touchend', () => {
      document.documentElement.classList.add('no-focus-outline');
    });
  }

  let ApplicationRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service('error'), _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember.inject.service, _dec11 = Ember.inject.service, _dec12 = Ember.inject.service, _dec13 = Ember.inject.service, _dec14 = Ember.inject.service, _dec15 = Ember.inject.service, _dec16 = Ember.inject.service('title'), _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, (_class = class ApplicationRoute extends Ember.Route {
    get currentChannel() {
      return this.channel.current;
    }

    get headTags() {
      return [{
        tagId: 'allow-indexing',
        type: 'meta',
        attrs: {
          name: 'robots',
          content: 'noindex'
        }
      }];
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "accessibility", _descriptor, this);

      _initializerDefineProperty(this, "analytics", _descriptor2, this);

      _initializerDefineProperty(this, "basket", _descriptor3, this);

      _initializerDefineProperty(this, "bootstrap", _descriptor4, this);

      _initializerDefineProperty(this, "challenge", _descriptor5, this);

      _initializerDefineProperty(this, "channel", _descriptor6, this);

      _initializerDefineProperty(this, "errorService", _descriptor7, this);

      _initializerDefineProperty(this, "features", _descriptor8, this);

      _initializerDefineProperty(this, "focus", _descriptor9, this);

      _initializerDefineProperty(this, "globalData", _descriptor10, this);

      _initializerDefineProperty(this, "globalEvents", _descriptor11, this);

      _initializerDefineProperty(this, "intl", _descriptor12, this);

      _initializerDefineProperty(this, "performanceMetric", _descriptor13, this);

      _initializerDefineProperty(this, "router", _descriptor14, this);

      _initializerDefineProperty(this, "session", _descriptor15, this);

      _initializerDefineProperty(this, "titleService", _descriptor16, this);

      this.performanceMetric.pushTiming('applicationInit');
    } // TODO: seems like this should pretty much all just be... `model()`.


    async beforeModel() {
      (0, _clearRaygun.default)();
      focusRingSetup();
      optionalFeatureSetup();

      if (typeof Intl === 'undefined') {
        // @ts-ignore
        await emberAutoImportDynamic("intl"); // @ts-ignore

        await emberAutoImportDynamic("intl/locale-data/jsonp/en.js");
      }

      if (typeof Intl !== 'undefined' && typeof Intl.PluralRules === 'undefined') {
        await emberAutoImportDynamic("@formatjs/intl-pluralrules/polyfill"); // @ts-ignore

        await emberAutoImportDynamic("@formatjs/intl-pluralrules/locale-data/en");
      } // @ts-ignore


      if (typeof Intl !== 'undefined' && typeof Intl.getCanonicalLocales === 'undefined') {
        await emberAutoImportDynamic("@formatjs/intl-getcanonicallocales/polyfill");
      }

      if (typeof URLSearchParams === 'undefined') {
        // @ts-ignore
        await emberAutoImportDynamic("url-search-params-polyfill");
      }

      if (typeof window.ResizeObserver === 'undefined') {
        const module = await emberAutoImportDynamic("@juggle/resize-observer");
        window.ResizeObserver = module.ResizeObserver;
      } // This will use translations from the browser's launguage if we have that language supported.
      // If not, it will fall back to en-us as the default.


      this.intl.setLocale([window.navigator.language, 'en-us']); // Set ENV at runtime for lazy-loading

      _environment.default.scriptBaseUrl = getMwcRoot();

      if (this.challenge.initBootsrapChallenged) {
        // If we proceeded, we would end up in a challenge loop.
        return Promise.resolve();
      }

      return this.bootstrap.initBootstrap().then(() => this.session.refreshServeAppToken());
    }

    afterModel() {
      var _this$channel$setting;

      this.performanceMetric.pushTiming('applicationAfterModel');
      this.performanceMetric.reportTimings();

      if ((_this$channel$setting = this.channel.settings) != null && _this$channel$setting.isResponsive) {
        // rg4js does not support additive tag changes so we need to
        // manually include previously initialized values
        rg4js('withTags', [(0, _hybridUtil.channelSlug)(), 'isResponsive']);
      }

      this.router.on('routeDidChange', async () => {
        Ember.run.scheduleOnce('afterRender', this, this._handleCartState);
        Ember.run.scheduleOnce('afterRender', this, this._trackPage);
        this.accessibility.setup();
        this.globalData.setup();
        this.globalEvents.setup();
        this.analytics.setup(this.bootstrap.data);
        this.focus.resetFocus();
      });
    }

    setupController(controller, model, transition) {
      super.setupController(controller, model, transition);

      if (!Ember.isEmpty(controller.src)) {
        this.session.set('src', controller.src);
      }

      if (!Ember.isEmpty(controller.restrictedFlow)) {
        this.session.set('restrictedFlow', controller.restrictedFlow === 'true');
      }
    }

    _trackPage() {
      const page = this.router.currentURL;
      this.analytics.trackPageview(page);
      rg4js('trackEvent', {
        type: 'pageView',
        path: page
      });
    }

    _handleCartState() {
      // eslint-disable-next-line ember/no-controller-access-in-routes
      this.controllerFor('application').handleCartState();
    }

    title(tokens) {
      const suffix = 'Order Online';
      tokens = (0, _.flatten)(tokens); // Some tokens may already include this (specifically the vendor menu title which comes from dictionary)

      if (!tokens.includes(suffix)) {
        tokens.push(suffix);
      }

      const title = tokens.join(' - ');
      this.titleService.set('documentTitle', title); // Pretty sure this should be removed once we stop treating the logo as an `h1`...

      const pageTitle = tokens.slice(0, -1).join(' - ');
      if (pageTitle) this.titleService.set('pageTitle', pageTitle);
      return title;
    } // Do not delete or rename. This is used by
    // https://github.com/mike-north/ember-cli-document-title-northm


    titleToken() {
      return this.currentChannel ? this.currentChannel.name : '';
    }

    willTransition() {
      this.basket.close();
    }
    /**
     * This handler is triggered when there is an error during route processing,
     * likely originating from a beforeModel, model, or afterModel hook. If we
     * return true from this method, the error.hbs template will be rendered
     * within the application.hbs (or the application-error.hbs template will be
     * rendered standalone if the error originated with the application route).
     *
     * Note that the onerror event in the error service will NOT be triggered by
     * any error that results in this method being called, so any user notifications
     * or raygun logging must be handled in this method.
     */


    error(error, transition) {
      const response = typeof error === 'object' ? (0, _perimeterx.asPerimeterXResponse)(error) : undefined;

      if (response) {
        this.challenge.routeError(transition, response);
        return false;
      } // One possible reason to hit the application error hook is that bootstrap
      // data failed to load entirely. In that case, none of the other special
      // scenarios matter, and the error is not recoverable in any way; we simply
      // need to show the error template.


      if (!this.store.peekRecord('bootstrap-data', (0, _hybridUtil.channelSlug)())) {
        return true;
      }

      this.errorService.reportError(error); // if the current transition hasn't been aborted (by transitioning elsewhere)
      // and the current transition isn't already targeting the home page,
      // redirect to the home page.
      // @ts-ignore -- using private API :(

      if (!transition.isAborted && transition.targetName !== 'index') {
        transition.abort();
        this.transitionTo('application');
        return false;
      }

      return true;
    }

    reloadModel() {
      this.refresh();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "accessibility", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "bootstrap", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "challenge", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "errorService", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "features", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "focus", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "globalData", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "globalEvents", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "performanceMetric", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "titleService", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "willTransition", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "error", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "error"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reloadModel", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "reloadModel"), _class.prototype)), _class));
  _exports.default = ApplicationRoute;
});