define("mobile-web/components/r-footer/component", ["exports", "@glimmer/component", "@capacitor/storage", "mobile-web/config/environment", "mobile-web/lib/app-store", "mobile-web/lib/data", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/classes", "mobile-web/lib/utilities/http", "mobile-web/components/r-footer/style"], function (_exports, _component, _storage, _environment, _appStore, _data, _, _classes, _http, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Location = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Location;
  _exports.Location = Location;

  (function (Location) {
    Location["SideMenu"] = "sideMenu";
    Location["Bottom"] = "bottom";
  })(Location || (_exports.Location = Location = {}));

  let Footer = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class Footer extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "bus", _descriptor, this);

      _initializerDefineProperty(this, "channel", _descriptor2, this);

      _initializerDefineProperty(this, "device", _descriptor3, this);

      _initializerDefineProperty(this, "media", _descriptor4, this);

      _initializerDefineProperty(this, "session", _descriptor5, this);

      _initializerDefineProperty(this, "sideMenu", _descriptor6, this);

      _initializerDefineProperty(this, "store", _descriptor7, this);

      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get isSideMenu() {
      return _data.default.isLoaded(this.args.model) && this.args.model.value.location === Location.SideMenu;
    }

    get isBottom() {
      return !this.isSideMenu;
    }

    get footerClass() {
      return (0, _classes.classes)(this.style.footer, this.isSideMenu ? this.style.sideMenu : this.style.bottom);
    }

    get orderedCustomLinks() {
      return this.store.peekAll('custom-link').sortBy('displayOrder');
    }

    get isLoaded() {
      return _data.default.isLoaded(this.args.model);
    }

    get notEmbedded() {
      return !this.isLoaded || !this.session.isEmbeddedMode;
    }

    get notRestricted() {
      return !this.isLoaded || !this.session.isRestrictedFlow;
    }

    get showVisitDesktop() {
      var _this$channel$setting;

      return !!this.visitDesktopUrl && this.notEmbedded && this.notRestricted && !((_this$channel$setting = this.channel.settings) != null && _this$channel$setting.isResponsive) && !this.device.isHybrid;
    }

    get showAccessibility() {
      var _this$channel$setting2;

      return this.isLoaded && !!((_this$channel$setting2 = this.channel.settings) != null && _this$channel$setting2.enableAudioEye);
    }

    get visitDesktopUrl() {
      var _this$channel$setting3;

      const url = (_this$channel$setting3 = this.channel.settings) == null ? void 0 : _this$channel$setting3.fullSiteUrl;
      return this.isLoaded && url ? `${(0, _http.ensureProtocol)(url)}?stay=y` : '';
    }

    get showParentSiteUrl() {
      var _this$channel$setting4;

      return (this.device.isHybrid || !this.media.isTablet) && this.isLoaded && !(0, _.isEmpty)((_this$channel$setting4 = this.channel.settings) == null ? void 0 : _this$channel$setting4.parentSiteUrl) && !(0, _.isEmpty)(this.parentSiteDisplayUrl) && !this.session.isEmbeddedMode && !this.session.isRestrictedFlow;
    }

    get parentSiteDisplayUrl() {
      var _this$channel$setting5, _this$channel$setting6;

      return this.isLoaded ? (0, _http.displayUrl)((_this$channel$setting5 = (_this$channel$setting6 = this.channel.settings) == null ? void 0 : _this$channel$setting6.parentSiteUrl) != null ? _this$channel$setting5 : '') : '';
    }

    get reviewInStoreUrl() {
      if (!this.isLoaded || !this.sideMenu.isEnabled || !this.device.storeId || this.device.isWebOnly) {
        return undefined;
      }

      return this.device.isAndroid ? (0, _appStore.googleStoreListingLink)(this.device.storeId) : this.device.isIOS ? (0, _appStore.appleStoreListingLink)(this.device.storeId) : undefined;
    }

    get showBadgesInTop() {
      return this.isLoaded && !this.sideMenu.isEnabled;
    }

    get showBadgesInBottom() {
      return this.isLoaded && this.sideMenu.isEnabled;
    }

    get appVersion() {
      var _this$device$nativeAp, _window$Olo$appVersio, _window$Olo$appVersio2;

      const native = (_this$device$nativeAp = this.device.nativeAppVersion) != null ? _this$device$nativeAp : '';
      const ember = (_window$Olo$appVersio = (_window$Olo$appVersio2 = window.Olo.appVersion) == null ? void 0 : _window$Olo$appVersio2.split('-')[0]) != null ? _window$Olo$appVersio : '';
      return native + ' (' + ember + ')';
    } // Constructor
    // Other methods


    showDevMenu() {
      var _window$Olo$isAdHoc;

      return (_environment.default.environment === 'development' || ((_window$Olo$isAdHoc = window.Olo.isAdHoc) != null ? _window$Olo$isAdHoc : false)) && _environment.default.isHybrid;
    } // Tasks
    // Actions


    showModal(type) {
      this.bus.trigger('showStaticContent', {
        type
      });
      this.sideMenu.close();
    }

    async openAppStoreListing() {
      if (!this.reviewInStoreUrl) {
        return;
      }

      await this.device.launchApp(this.reviewInStoreUrl);
    }

    async showDevPrompt() {
      if (!this.showDevMenu()) {
        return;
      }

      const baseUrlKey = 'JS_BASE_URL';
      const {
        value
      } = await _storage.Storage.get({
        key: baseUrlKey
      }); // eslint-disable-next-line no-alert

      const inputBaseUrl = window.prompt('Enter backend url. Clear the input value to remove the existing url. Force close the app after pressing "OK"', value != null ? value : '');
      await _storage.Storage.set({
        key: baseUrlKey,
        value: inputBaseUrl != null ? inputBaseUrl : ''
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "bus", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "device", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "media", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "sideMenu", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showModal", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "showModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openAppStoreListing", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "openAppStoreListing"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showDevPrompt", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "showDevPrompt"), _class.prototype)), _class));
  _exports.default = Footer;
});