define("mobile-web/components/r-app-store-badges/badge/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qli207WJ",
    "block": "[[[11,1],[16,0,[30,0,[\"rootClass\"]]],[16,\"data-test-badge\",[30,1]],[17,2],[4,[38,0],[[30,0,[\"track\"]]],null],[12],[1,\"\\n  \"],[10,3],[15,1,[30,0,[\"linkId\"]]],[15,6,[30,0,[\"href\"]]],[15,0,[30,0,[\"style\",\"link\"]]],[15,\"data-test-badge-link\",[30,1]],[12],[1,\"\\n    \"],[10,\"img\"],[15,\"src\",[30,0,[\"imgSrc\"]]],[15,\"alt\",[28,[37,1],[[30,0,[\"imgAltTextKey\"]]],null]],[15,0,[30,0,[\"style\",\"image\"]]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@type\",\"&attrs\"],false,[\"did-insert\",\"t\"]]",
    "moduleName": "mobile-web/components/r-app-store-badges/badge/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});