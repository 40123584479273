define("mobile-web/serializers/basket-choice", ["exports", "mobile-web/decorators/persistent", "mobile-web/serializers/application"], function (_exports, _persistent, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let BasketChoiceSerializer = (_dec = (0, _persistent.default)(), _dec(_class = class BasketChoiceSerializer extends _application.default {
    keyForRelationship(key, typeClass, method) {
      if (key === 'choice') {
        return 'choiceId';
      }

      return super.keyForRelationship(key, typeClass, method);
    }

  }) || _class);
  _exports.default = BasketChoiceSerializer;
});