define("mobile-web/components/r-product-customization/footer/component", ["exports", "@glimmer/component", "ember-concurrency-ts", "mobile-web/services/focus-manager", "mobile-web/services/global-events", "mobile-web/services/notifications", "mobile-web/components/r-product-customization/footer/style"], function (_exports, _component, _emberConcurrencyTs, _focusManager, _globalEvents, _notifications, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProductCustomizationFooter = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class ProductCustomizationFooter extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      _initializerDefineProperty(this, "channel", _descriptor2, this);

      _initializerDefineProperty(this, "features", _descriptor3, this);

      _initializerDefineProperty(this, "focusManager", _descriptor4, this);

      _initializerDefineProperty(this, "intl", _descriptor5, this);

      _initializerDefineProperty(this, "store", _descriptor6, this);

      _initializerDefineProperty(this, "router", _descriptor7, this);

      _initializerDefineProperty(this, "notifications", _descriptor8, this);

      _initializerDefineProperty(this, "globalEvents", _descriptor9, this);

      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get showPrice() {
      var _this$channel$setting;

      return !this.isLoading && ((_this$channel$setting = this.channel.settings) == null ? void 0 : _this$channel$setting.showProductPriceTicker);
    }

    get price() {
      return this.args.basketProduct.estimatedUnitCost * this.args.basketProduct.quantity;
    }

    get isLoading() {
      return this.args.isLoading || (0, _emberConcurrencyTs.taskFor)(this.args.basketProduct.saveTask).isRunning;
    }

    get isDisabled() {
      return this.isLoading || !this.args.basketProduct.canSubmit;
    }

    get hasError() {
      return !this.isLoading && !this.args.basketProduct.canSubmit;
    } // Constructor
    // Other methods


    goToError() {
      if (this.hasError) {
        this.focusManager.focusFirst(_focusManager.CategoryName.OptionGroupError);
      }
    } // Tasks
    // Actions


    addToCart() {
      if (this.isDisabled) {
        this.goToError();
        return;
      }

      const isNew = this.args.basketProduct.get('isNew');
      (0, _emberConcurrencyTs.taskFor)(this.args.basketProduct.saveTask).perform({
        eventName: this.args.displayInModal ? 'Zero Modifiers Product Modal' : 'Product Customize',
        onSuccess: () => {
          var _this$args$onSave, _this$args;

          (_this$args$onSave = (_this$args = this.args).onSave) == null ? void 0 : _this$args$onSave.call(_this$args);
          const message = this.intl.t(isNew ? 'mwc.notifications.added' : 'mwc.notifications.updated', {
            quantity: this.args.basketProduct.quantity
          });
          const type = isNew ? _notifications.NotificationType.ProductAdded : _notifications.NotificationType.ProductUpdated;
          this.notifications.success({
            message,
            type
          });
          this.globalEvents.trigger(_globalEvents.GlobalEventName.AddToCart, this.args.basketProduct.serializeForGlobalData());
        }
      });
    }

    updateQuantity(val) {
      this.args.basketProduct.quantity = val;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "features", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "focusManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "globalEvents", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addToCart", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "addToCart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateQuantity", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "updateQuantity"), _class.prototype)), _class));
  _exports.default = ProductCustomizationFooter;
});