define("mobile-web/components/r-post-checkout/r-share-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4/crd6mD",
    "block": "[[[10,\"aside\"],[15,\"title\",[28,[37,0],[\"mwc.postCheckout.shareOrderLabel\"],null]],[15,0,[30,0,[\"rootClass\"]]],[14,\"data-test-post-checkout-share-section\",\"\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@key\",\"@substitutions\"],[\"THANKS_SOCIALTEXT\",[36,2]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@icon\"],[[50,\"r-svg\",0,null,[[\"icon\",\"ariaLabel\"],[\"r-quote\",\"\"]]]]],[[\"content\",\"buttons\"],[[[[1,\"\\n        \"],[10,2],[12],[1,[30,1,[\"value\"]]],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[30,2,[\"buttonContainer\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[18,3,[[28,[37,6],null,[[\"buttonClass\",\"quote\"],[[30,2,[\"buttonClass\"]],[30,1,[\"value\"]]]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[2]]]]],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"content\",\"s\",\"&default\"],false,[\"t\",\"x-content\",\"substitutions\",\"r-post-checkout/r-subsection\",\"component\",\"yield\",\"hash\"]]",
    "moduleName": "mobile-web/components/r-post-checkout/r-share-section/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});