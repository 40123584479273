define("mobile-web/components/r-modal/component", ["exports", "mobile-web/lib/runloop", "mobile-web/lib/scroll", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-modal/style"], function (_exports, _runloop, _scroll, _, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  // eslint-disable-next-line ember/require-tagless-components
  let Modal = (_dec = Ember.computed('elementId', 'testSelector'), _dec2 = Ember.computed('buttons.main'), _dec3 = Ember.computed('buttons.other'), (_class = class Modal extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.wrapperClass = '';
      this.style = _style.default;
      this.actions = {
        close() {
          this.onClose();
        },

        bodyDidInsert(el) {
          if (this.bodyDidInsert) {
            (0, _runloop.safeNext)(this, () => {
              var _this$bodyDidInsert;

              (_this$bodyDidInsert = this.bodyDidInsert) == null ? void 0 : _this$bodyDidInsert.call(this, el);
            });
          }

          (0, _scroll.disableDocumentScroll)();
        },

        bodyWillDestroy() {
          (0, _scroll.enableDocumentScroll)();
        }

      };
    }

    get dataTestTag() {
      return this.testSelector || this.elementId;
    }

    get mainButton() {
      return typeof this.buttons === 'object' ? this.buttons.main : undefined;
    }

    get secondaryButton() {
      return typeof this.buttons === 'object' ? this.buttons.other : undefined;
    }

    get headingId() {
      return `${Ember.guidFor(this)}-heading`;
    }

    init() {
      super.init();
      (false && !((0, _.isFunction)(this.onClose)) && Ember.assert('`onClose` is required', (0, _.isFunction)(this.onClose)));
      (false && !((0, _isSome.default)(this.title)) && Ember.assert('`title` is required', (0, _isSome.default)(this.title)));
      (false && !(typeof this.buttons === 'object' ? !Ember.isNone(this.buttons.main) : true) && Ember.assert('`buttons` must have a `main` if present', typeof this.buttons === 'object' ? !Ember.isNone(this.buttons.main) : true));
    } // eslint-disable-next-line ember/no-actions-hash


  }, (_applyDecoratedDescriptor(_class.prototype, "dataTestTag", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "dataTestTag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mainButton", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "mainButton"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "secondaryButton", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "secondaryButton"), _class.prototype)), _class));
  _exports.default = Modal;
});