define("mobile-web/components/r-external-login-providers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z+dxBBp5",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,0],[15,0,[30,0,[\"style\",\"root\"]]],[14,\"data-test-externalLoginProviders\",\"\"],[12],[1,\"\\n    \"],[10,0],[15,0,[30,0,[\"style\",\"loginProviders\"]]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],null,[[[41,[28,[37,3],[[30,2,[\"slug\"]],\"apple\"],null],[[[1,\"          \"],[8,[39,4],null,[[\"@provider\",\"@onClick\"],[[30,2],[30,0,[\"doExternalLogin\"]]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,3],[[30,2,[\"slug\"]],\"facebook\"],null],[[[1,\"          \"],[8,[39,5],null,[[\"@provider\",\"@onClick\"],[[30,2],[30,0,[\"doExternalLogin\"]]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,3],[[30,2,[\"slug\"]],\"google\"],null],[[[1,\"          \"],[8,[39,6],null,[[\"@provider\",\"@onClick\"],[[30,2],[30,0,[\"doExternalLogin\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[39,7],null,[[\"@provider\",\"@onClick\"],[[30,2],[30,0,[\"doExternalLogin\"]]]],null],[1,\"\\n        \"]],[]]]],[]]]],[]]]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@loginProviders\",\"provider\"],false,[\"if\",\"each\",\"-track-array\",\"eq\",\"r-apple-login-button\",\"r-facebook-login-button\",\"r-google-login-button\",\"r-external-login-button\"]]",
    "moduleName": "mobile-web/components/r-external-login-providers/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});