define("mobile-web/components/r-edit-order-criteria-modal/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "ember-concurrency", "ember-concurrency-ts", "mobile-web/components/r-address-form/component", "mobile-web/lib/dayjs", "mobile-web/lib/location/address", "mobile-web/lib/order-criteria", "mobile-web/lib/runloop", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/guids", "mobile-web/lib/utilities/is-some", "mobile-web/services/analytics", "mobile-web/components/r-edit-order-criteria-modal/style"], function (_exports, _glimmerComponent, _emberConcurrency, _emberConcurrencyTs, AddressForm, _dayjs, _address, _orderCriteria, _runloop, _, _guids, _isSome, _analytics, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EditOrderCriteriaModal = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember.inject.service, _dec11 = Ember.inject.service, _dec12 = Ember.inject.service, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember.computed('addressForm.streetAddress', 'addressForm.city', 'addressForm.zipCode'), _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, (_class = class EditOrderCriteriaModal extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "basket", _descriptor2, this);

      _initializerDefineProperty(this, "bus", _descriptor3, this);

      _initializerDefineProperty(this, "challenge", _descriptor4, this);

      _initializerDefineProperty(this, "channel", _descriptor5, this);

      _initializerDefineProperty(this, "error", _descriptor6, this);

      _initializerDefineProperty(this, "intl", _descriptor7, this);

      _initializerDefineProperty(this, "orderCriteria", _descriptor8, this);

      _initializerDefineProperty(this, "router", _descriptor9, this);

      _initializerDefineProperty(this, "session", _descriptor10, this);

      _initializerDefineProperty(this, "store", _descriptor11, this);

      _initializerDefineProperty(this, "vendor", _descriptor12, this);

      this.ids = (0, _guids.guids)(this, 'form');
      this.style = _style.default;

      _initializerDefineProperty(this, "showChangeLocation", _descriptor13, this);

      _initializerDefineProperty(this, "addressForm", _descriptor14, this);

      _initializerDefineProperty(this, "editingCriteria", _descriptor15, this);

      this.saveTask = (0, _emberConcurrencyTs.taskFor)(this.saveGenerator);
    }

    // Getters and setters
    get showVendorModes() {
      return this.orderCriteria.showVendorModes;
    }

    get showPrivateModes() {
      return this.orderCriteria.showPrivateModes;
    }

    get handoffModes() {
      var _this$channel$current, _this$channel$current2, _this$vendor$vendor$s, _this$vendor$vendor, _this$vendor$vendor$s2, _vendorModes$map$filt;

      const allModes = this.store.peekAll('handoff-mode'); // Theoretically we should have been able just to do `allModes.filter(...)`,
      // but electing here to be resilient against possible back-end changes.

      const publicModes = (_this$channel$current = (_this$channel$current2 = this.channel.current) == null ? void 0 : _this$channel$current2.handoffModes.filter(h => !h.isPrivate)) != null ? _this$channel$current : [];
      const vendorModes = (_this$vendor$vendor$s = (_this$vendor$vendor = this.vendor.vendor) == null ? void 0 : (_this$vendor$vendor$s2 = _this$vendor$vendor.settings) == null ? void 0 : _this$vendor$vendor$s2.supportedHandoffModes) != null ? _this$vendor$vendor$s : [];
      return this.showVendorModes ? (_vendorModes$map$filt = vendorModes.map(h => allModes.find(m => (0, _orderCriteria.handoffMatches)(m.type, h))).filter(_isSome.default).filter(h => this.showPrivateModes || !h.isPrivate)) != null ? _vendorModes$map$filt : [] : publicModes;
    } // TODO: migrate this to use shared logic to determine what timeWantedModes are supported


    get timeWantedTypes() {
      var _this$selectedHandoff, _this$selectedHandoff2, _this$selectedHandoff3;

      return (_this$selectedHandoff = (_this$selectedHandoff2 = this.selectedHandoffModeModel) == null ? void 0 : (_this$selectedHandoff3 = _this$selectedHandoff2.timeWantedModes) == null ? void 0 : _this$selectedHandoff3.filter(timeMode => {
        if (!this.showPrivateModes && timeMode.isPrivate) {
          return false;
        }

        const type = timeMode.type; // If we have a vendor, we must consider what the vendor supports

        if (!this.isSearch || this.vendor.vendor) {
          var _this$basket$basket$v, _this$basket$basket, _this$basket$basket$v2;

          const vendor = (_this$basket$basket$v = (_this$basket$basket = this.basket.basket) == null ? void 0 : (_this$basket$basket$v2 = _this$basket$basket.vendor) == null ? void 0 : _this$basket$basket$v2.content) != null ? _this$basket$basket$v : this.vendor.vendor;

          switch (type) {
            case 'Advance':
              return vendor.allowAdvanceOrders;

            case 'Immediate':
              return vendor.allowImmediateOrders;

            case 'ManualFire':
              return vendor.allowManualFireOrders;

            default:
              return true;
          }
        }

        return true;
      })) != null ? _this$selectedHandoff : [];
    }

    get selectedHandoffModeModel() {
      return this.handoffModes.find(hm => {
        var _this$editingCriteria;

        return (0, _orderCriteria.handoffMatches)(hm.type, (_this$editingCriteria = this.editingCriteria) == null ? void 0 : _this$editingCriteria.handoffMode);
      });
    }

    get selectedHandoffMode() {
      var _this$selectedHandoff4;

      return (_this$selectedHandoff4 = this.selectedHandoffModeModel) == null ? void 0 : _this$selectedHandoff4.type;
    }

    set selectedHandoffMode(hm) {
      if (this.editingCriteria && hm) {
        var _this$handoffModes$fi;

        const selectedHandoffModel = (_this$handoffModes$fi = this.handoffModes.find(model => model.type === hm)) != null ? _this$handoffModes$fi : this.handoffModes[0];
        (0, _orderCriteria.updateOrderCriteria)(this, 'editingCriteria', {
          handoffMode: selectedHandoffModel.type
        }); // See if the new handoff mode supports the currently selected time mode

        if (!selectedHandoffModel.timeWantedModes.any(tm => {
          var _this$selectedTimeWan;

          return tm.type === ((_this$selectedTimeWan = this.selectedTimeWantedMode) == null ? void 0 : _this$selectedTimeWan.type);
        })) {
          this.changeTimeWantedMode(selectedHandoffModel.timeWantedModes.firstObject);
        }
      }
    }

    get selectedTimeWantedMode() {
      return this.timeWantedTypes.find(t => {
        var _this$editingCriteria2;

        return t.type === ((_this$editingCriteria2 = this.editingCriteria) == null ? void 0 : _this$editingCriteria2.timeWantedType);
      });
    }

    get selectedTimeWanted() {
      return (0, _orderCriteria.isAdvance)(this.editingCriteria) ? this.editingCriteria.timeWanted : undefined;
    }

    get isSearch() {
      return this.orderCriteria.isSearch;
    }

    get isDelivery() {
      return (0, _orderCriteria.isDelivery)(this.editingCriteria);
    }

    get showAddress() {
      return this.isDelivery || !this.router.isActive('menu');
    }

    get modalTitle() {
      return this.intl.t(`mwc.orderCriteria.${this.isSearch ? 'editSearchTitle' : 'editOrderTitle'}`);
    }

    get addresses() {
      return this.store.peekAll('address');
    }

    get searchAddress() {
      return (0, _orderCriteria.isAtStore)(this.editingCriteria) ? this.editingCriteria.searchAddress : '';
    }

    set searchAddress(address) {
      if ((0, _orderCriteria.isAtStore)(this.editingCriteria)) {
        this.editingCriteria.searchAddress = address;
      }
    }

    get isAdvance() {
      return (0, _orderCriteria.isAdvance)(this.editingCriteria);
    }

    get isAddressValid() {
      return (0, _address.isValid)(this.addressForm);
    }

    get valid() {
      if ((0, _orderCriteria.isAdvance)(this.editingCriteria) && !this.editingCriteria.timeWanted) {
        return false;
      }

      if ((0, _orderCriteria.isDelivery)(this.editingCriteria)) {
        return this.isAddressValid;
      }

      if (!this.showAddress) {
        return true;
      }

      return (0, _orderCriteria.isAtStore)(this.editingCriteria) && !(0, _.isEmpty)(this.editingCriteria.searchAddress);
    } // Other methods


    focusOnError() {
      // TODO: Use the FocusManager service
      const focusEl = document.querySelector('[data-focusWhen="errorTrigger"]');
      focusEl == null ? void 0 : focusEl.setAttribute('tabindex', '0');
      focusEl == null ? void 0 : focusEl.focus();
    } // Tasks


    *saveGenerator() {
      if ((0, _orderCriteria.isDelivery)(this.editingCriteria)) {
        if ((0, _.isEmpty)(this.addressForm.id)) {
          try {
            var _this$basket$basket2;

            yield this.session.updateCountryCodeFromZip(this.addressForm.zipCode);
            const addressModel = yield this.store.collectionAction('address', 'addDeliveryAddress', _extends({}, this.addressForm, {
              basketId: (_this$basket$basket2 = this.basket.basket) == null ? void 0 : _this$basket$basket2.id
            }));
            this.addressForm.id = addressModel.id;
          } catch (e) {
            this.error.reportError(e);
          }
        }

        if (!(0, _.isEmpty)(this.addressForm.id)) {
          (0, _orderCriteria.updateOrderCriteria)(this, 'editingCriteria', {
            deliveryAddress: this.store.peekRecord('address', this.addressForm.id)
          });
        }
      }

      const vendor = this.router.isActive('vendor-search-results') ? undefined : this.vendor.vendor;

      if ((0, _orderCriteria.isAtStore)(this.editingCriteria) && (0, _.isEmpty)(this.editingCriteria.searchAddress) && vendor) {
        (0, _orderCriteria.updateOrderCriteria)(this, 'editingCriteria', {
          searchAddress: vendor.address.postalCode
        });
      }

      yield (0, _emberConcurrencyTs.taskFor)(this.challenge.request).perform(async () => {
        const result = this.editingCriteria ? await (vendor == null ? void 0 : vendor.preCheck(this.editingCriteria)) : undefined;

        if (!result || result.isValid) {
          let success = true;

          if (this.isSearch) {
            this.orderCriteria.set('searchOrderCriteria', this.editingCriteria);
          } else {
            await this.orderCriteria.updateBasket().catch(e => {
              success = false;
              this.error.reportError(e);
            });
          }

          if (success) {
            this.orderCriteria.closeModal(this.editingCriteria);
            this.bus.trigger('adjustAmounts');
            this.analytics.trackEvent(_analytics.AnalyticsEvents.ChangeOrderCriteria, () => ({
              [_analytics.AnalyticsProperties.ChangeLocationShown]: this.showChangeLocation,
              [_analytics.AnalyticsProperties.TimeWantedShown]: this.timeWantedTypes.length > 0
            }));
          }
        } else {
          this.showChangeLocation = true;
          this.orderCriteria.orderCriteriaError = result.errorMessage;
          (0, _runloop.safeNext)(this, this.focusOnError);
        }
      });
    } // Actions


    setup() {
      var _this$editingCriteria5, _this$addresses$objec;

      const currentCriteria = this.isSearch ? this.orderCriteria.searchOrderCriteria : this.orderCriteria.basketOrderCriteria;
      this.editingCriteria = (0, _.cloneDeep)(currentCriteria != null ? currentCriteria : _extends({}, this.orderCriteria.criteria, {
        isDefault: false
      }));
      const timeWantedMode = this.timeWantedTypes.find(tw => {
        var _this$editingCriteria3;

        return tw.type === ((_this$editingCriteria3 = this.editingCriteria) == null ? void 0 : _this$editingCriteria3.timeWantedType);
      });

      if (timeWantedMode) {
        this.changeTimeWantedMode(timeWantedMode);
      }

      if ((0, _orderCriteria.isAdvance)(this.editingCriteria)) {
        var _this$editingCriteria4;

        this.changeTimeWanted((_this$editingCriteria4 = this.editingCriteria.timeWanted) != null ? _this$editingCriteria4 : (0, _dayjs.default)());
      }

      if (this.editingCriteria.handoffMode === 'Unspecified') {
        this.selectedHandoffMode = this.orderCriteria.defaultOrderCriteria.handoffMode;
      }

      const currentValue = (0, _orderCriteria.isDelivery)(this.editingCriteria) ? // can happen if default handoff is delivery or if `handoff=delivery` is
      // specified in the query string
      (_this$editingCriteria5 = this.editingCriteria.deliveryAddress) != null ? _this$editingCriteria5 : _address.EMPTY : (_this$addresses$objec = this.addresses.objectAt(0)) != null ? _this$addresses$objec : {};
      const mode = this.addresses.length > 0 ? AddressForm.Mode.UseExisting : AddressForm.Mode.AddNew;
      this.addressForm = {
        id: currentValue.id || '',
        mode,
        streetAddress: currentValue.streetAddress || '',
        building: currentValue.building || '',
        city: currentValue.city || '',
        zipCode: currentValue.zipCode || ''
      };
    }

    changeLocation() {
      this.orderCriteria.closeModal();
      this.orderCriteria.showAddressModal = true;
    }

    changeTimeWantedMode(newMode) {
      (0, _orderCriteria.updateOrderCriteria)(this, 'editingCriteria', {
        timeWantedType: newMode.type
      });
    }

    changeTimeWanted(newTimeWanted) {
      if ((0, _orderCriteria.isAdvance)(this.editingCriteria)) {
        (0, _orderCriteria.updateOrderCriteria)(this, 'editingCriteria', {
          timeWanted: newTimeWanted
        });
      }
    }

    changeSearchAddress(searchAddress) {
      if ((0, _orderCriteria.isAtStore)(this.editingCriteria)) {
        (0, _orderCriteria.updateOrderCriteria)(this, 'editingCriteria', {
          searchAddress
        });
      }
    }

    save(e) {
      e == null ? void 0 : e.preventDefault();
      this.saveTask.perform();
    }

    cancel() {
      this.orderCriteria.closeModal();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "bus", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "challenge", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "error", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "orderCriteria", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "vendor", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "showChangeLocation", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "addressForm", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "editingCriteria", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isAddressValid", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "isAddressValid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveGenerator", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveGenerator"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setup", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeLocation", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "changeLocation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeTimeWantedMode", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "changeTimeWantedMode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeTimeWanted", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "changeTimeWanted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeSearchAddress", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "changeSearchAddress"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype)), _class));
  _exports.default = EditOrderCriteriaModal;
});