define("mobile-web/lib/plugins/keyboard/index", ["exports", "@capacitor/core"], function (_exports, _core) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Keyboard = (0, _core.registerPlugin)('Keyboard');
  var _default = Keyboard;
  _exports.default = _default;
});