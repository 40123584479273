define("mobile-web/components/r-geolocation-prompt/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "text": "_text_m0oqy0",
    "container": "_container_m0oqy0"
  };
  _exports.default = _default;
});