define("mobile-web/components/r-card/grid/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "list": "_list_1yth7r",
    "item": "_item_1yth7r",
    "twoColumns": "_twoColumns_1yth7r",
    "threeColumns": "_threeColumns_1yth7r",
    "fourColumns": "_fourColumns_1yth7r",
    "fiveColumns": "_fiveColumns_1yth7r",
    "hidden": "_hidden_1yth7r"
  };
  _exports.default = _default;
});