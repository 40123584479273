define("mobile-web/components/r-popover/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "_content_1h9q4y",
    "fade-in": "_fade-in_1h9q4y",
    "fade-out": "_fade-out_1h9q4y"
  };
  _exports.default = _default;
});