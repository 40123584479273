define("mobile-web/controllers/application", ["exports", "@capacitor/app", "@capacitor/core", "@capacitor/status-bar", "@capacitor/storage", "mobile-web/lib/plugins/keyboard", "mobile-web/lib/routing", "mobile-web/services/device", "mobile-web/styles/routes/application"], function (_exports, _app, _core, _statusBar, _storage, _keyboard, _routing, _device, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ApplicationController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember.inject.service, _dec11 = Ember.inject.service, _dec12 = Ember.inject.service, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, (_class = class ApplicationController extends Ember.Controller {
    // Service injections
    // Tracked properties
    // Constructor
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "basket", _descriptor, this);

      _initializerDefineProperty(this, "bus", _descriptor2, this);

      _initializerDefineProperty(this, "channel", _descriptor3, this);

      _initializerDefineProperty(this, "device", _descriptor4, this);

      _initializerDefineProperty(this, "loyalty", _descriptor5, this);

      _initializerDefineProperty(this, "media", _descriptor6, this);

      _initializerDefineProperty(this, "router", _descriptor7, this);

      _initializerDefineProperty(this, "session", _descriptor8, this);

      _initializerDefineProperty(this, "sideMenu", _descriptor9, this);

      _initializerDefineProperty(this, "vendor", _descriptor10, this);

      _initializerDefineProperty(this, "window", _descriptor11, this);

      _initializerDefineProperty(this, "intl", _descriptor12, this);

      this.queryParams = ['openBasket', 'restrictedFlow', 'src'];
      this.style = _application.default;

      _initializerDefineProperty(this, "openBasket", _descriptor13, this);

      _initializerDefineProperty(this, "restrictedFlow", _descriptor14, this);

      _initializerDefineProperty(this, "src", _descriptor15, this);
    } // Init


    init() {
      super.init();

      if (this.device.isHybrid) {
        _app.App.addListener('backButton', () => this.handleBackButton()).then(listener => this.backButtonListener = listener);

        if (this.device.isHybridIOS) {
          _keyboard.default.addListener('keyboardWillShow', async () => await _statusBar.StatusBar.hide()).then(listener => this.keyboardWillShowListener = listener);

          _keyboard.default.addListener('keyboardWillHide', async () => await _statusBar.StatusBar.show()).then(listener => this.keyboardWillHideListener = listener);

          _keyboard.default.addListener('fontSizeDidChange', () => this.setPreferredFontSize()).then(listener => this.fontSizeDidChangeListener = listener);

          this.setPreferredFontSize();
        }

        _app.App.addListener('appStateChange', async state => await this.handleAppStateChange(state)).then(listener => this.appStateChangeListener = listener);
      }
    } // WillDestroy


    willDestroy() {
      var _this$backButtonListe, _this$keyboardWillSho, _this$keyboardWillHid, _this$appStateChangeL, _this$fontSizeDidChan;

      super.willDestroy();
      (_this$backButtonListe = this.backButtonListener) == null ? void 0 : _this$backButtonListe.remove();
      (_this$keyboardWillSho = this.keyboardWillShowListener) == null ? void 0 : _this$keyboardWillSho.remove();
      (_this$keyboardWillHid = this.keyboardWillHideListener) == null ? void 0 : _this$keyboardWillHid.remove();
      (_this$appStateChangeL = this.appStateChangeListener) == null ? void 0 : _this$appStateChangeL.remove();
      (_this$fontSizeDidChan = this.fontSizeDidChangeListener) == null ? void 0 : _this$fontSizeDidChan.remove();
    } // Other methods


    handleCartState() {
      if (this.openBasket === 'true') {
        this.basket.open();
        this.openBasket = undefined;
      }
    }

    handleBackButton() {
      if (this.sideMenu.isActive) {
        this.sideMenu.close();
      } else if (this.basket.isOpen) {
        this.basket.close();
      } else if (this.router.currentRouteName === 'index') {
        this.device.exitApp();
      } else {
        history.back();
      }
    }

    async handleAppStateChange(state) {
      if (state.isActive) {
        await this.handleAppForegrounded();
      } else {
        await this.handleAppBackgrounded();
      }
    }

    async handleAppForegrounded() {
      await this.device.checkForNativeUpdate();
    }

    async handleAppBackgrounded() {
      await _storage.Storage.set({
        key: _device.LAST_BACKGROUNDED_KEY,
        value: Date.now().toString()
      });
    }

    async setPreferredFontSize() {
      if (_core.Capacitor.isPluginAvailable('TextZoom')) {
        const {
          TextZoom
        } = await emberAutoImportDynamic("@capacitor/text-zoom");
        TextZoom.getPreferred().then(result => TextZoom.set({
          value: result.value
        }));
      }
    } // Tasks
    // Actions


    externalLogin(provider) {
      const session = this.session;
      session.set('nextRoute', (0, _routing.currentTransitionArgs)(this.router.currentRoute));
      session.externalLogin(provider.slug);
    }

    internalLogin() {
      this.session.transitionToLogin();
    }

    goToLogin() {
      if (this.session.internalSignOnAllowed) {
        this.internalLogin();
      } else if (this.session.hasLoginProvider) {
        const provider = this.session.loginProviders[0];
        this.externalLogin(provider);
      }
    }

    logout() {
      return this.session.logout().then(() => {
        this.basket.clear();
        this.sideMenu.close();
        this.window.location().assign('/');
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "bus", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "device", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "loyalty", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "media", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "sideMenu", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "vendor", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "window", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "openBasket", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "restrictedFlow", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "src", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "externalLogin", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "externalLogin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "internalLogin", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "internalLogin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToLogin", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "goToLogin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "logout", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "logout"), _class.prototype)), _class));
  _exports.default = ApplicationController;
});