define("mobile-web/routes/dispatch-summary", ["exports", "mobile-web/lib/utilities/is-some"], function (_exports, _isSome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DispatchSummaryRoute = (_dec = Ember.inject.service, (_class = class DispatchSummaryRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      this.queryParams = {
        id: {
          refreshModel: true
        }
      };
    }

    async model(params) {
      const orderId = params.order_id;
      this.hash = params.hash;

      if ((0, _isSome.default)(params.id)) {
        this.router.replaceWith('dispatch-status', orderId, params.id, {
          queryParams: {
            id: undefined
          }
        });
      }

      return this.store.findRecord('order', orderId, {
        adapterOptions: {
          hash: this.hash
        }
      }).then(o => Ember.RSVP.hash({
        o,
        orderId: Ember.RSVP.resolve(orderId),
        statuses: this.store.query('dispatch-status', {
          orderId,
          hash: this.hash
        })
      }));
    }

    afterModel(resolvedModel) {
      if (resolvedModel.statuses.length === 1) {
        this.router.replaceWith('dispatch-status', resolvedModel.orderId, resolvedModel.statuses.get('firstObject').id, {
          queryParams: {
            hash: this.hash
          }
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = DispatchSummaryRoute;
});