define("mobile-web/templates/additional-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S0cnqUjl",
    "block": "[[[10,0],[14,0,\"additional-info\"],[14,\"data-test-customContent\",\"\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[[33,1,[\"htmlContent\"]]],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"]],[],false,[\"trusted-unsafe-html\",\"model\"]]",
    "moduleName": "mobile-web/templates/additional-info.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});