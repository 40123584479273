define("mobile-web/services/loyalty", ["exports", "ember-concurrency", "ember-object-update", "true-myth", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some", "mobile-web/services/error"], function (_exports, _emberConcurrency, _emberObjectUpdate, _trueMyth, _, _isSome, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Throw an error (to catch in the global error logger) if a user-facing error
   * cannot be constructed for use with `Result`.
   *
   * This is useful for cases where we expect a user-facing error to be available:
   * if it is not, we want to take full advantage of the top-level error handling.
   */
  const throwOrResultErr = error => {
    if (!(0, _error.errorForUser)(error)) {
      throw error;
    }

    return _trueMyth.Result.err((0, _error.extractErrorDetail)(error).unwrapOr(_error.MOBO_ERR));
  };

  const loadAccount = (store, vendorId, membershipId) => {
    const existingAccount = store.peekAll('loyalty-account').find(la => la.membershipId === membershipId && la.vendorId === vendorId);
    const account = (0, _isSome.default)(existingAccount) ? Ember.RSVP.resolve(existingAccount) : store.queryRecord('loyalty-account', {
      membershipId,
      vendorId
    });
    return account.then(_trueMyth.Maybe.of).then(mla => _trueMyth.Result.ok(mla)).catch(throwOrResultErr);
  };

  const toLoyaltyModelResults = (store, vendorId, memberships) => Ember.RSVP.all(memberships.map(m => Ember.RSVP.hash({
    membership: m,
    accountResult: loadAccount(store, vendorId, m.membershipId)
  }).then(({
    membership,
    accountResult
  }) => accountResult.match({
    Ok: account => _trueMyth.Result.ok({
      membership,
      account
    }),
    Err: error => _trueMyth.Result.err(error)
  }))));

  const fullyLoadedDataToStatus = fld => ({
    schemeProviderName: fld.membership.schemeProviderName,
    membershipId: _trueMyth.Maybe.just(fld.membership.membershipId.toString())
  });
  /**
   * The full Loyalty model accounts for failure to load membership data or to
   * load account data, since they are two separate API requests which may fail.
   */


  let LoyaltyService = (_dec = Ember.inject.service, (_class = class LoyaltyService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      this.rewardsLinkStatuses = [];
    }

    linkMembershipAccount(memberNumber, scheme) {
      const membership = this.store.createRecord('loyalty-membership', {
        membershipId: memberNumber,
        scheme,
        schemeName: scheme.schemeName
      });
      return membership.save().catch(e => {
        membership.destroyRecord();
        throw e;
      });
    }

    loadMemberships() {
      const loyaltyMemberships = this.store.peekAll('loyalty-membership');
      const updates = loyaltyMemberships.map(membership => ({
        schemeProviderName: membership.schemeProviderName,
        membershipId: _trueMyth.Maybe.just(membership.membershipId.toString())
      }));

      this._updateRewardsLinkStatus(updates);

      return Ember.RSVP.resolve(loyaltyMemberships);
    }

    unlinkMembership(membershipId) {
      const match = status => status.membershipId.mapOr(false, mId => mId === membershipId.toString());

      return _trueMyth.Maybe.of(this.rewardsLinkStatuses.find(match)).match({
        Just: status => {
          const updatedStatus = Object.assign({}, status, {
            membershipId: _trueMyth.Maybe.nothing()
          });
          return this._updateRewardsLinkStatus([updatedStatus]);
        },
        Nothing: () => this.rewardsLinkStatuses
      });
    }
    /**
     * @private
     * Our typings don't currently allow for truly private properties on classes
     * which extend `EmberObject`.
     */


    _updateRewardsLinkStatus(change) {
      const result = (0, _emberObjectUpdate.default)(this, 'rewardsLinkStatuses', rls => (0, _.unionBy)(change, rls, 'schemeProviderName'));
      return result;
    }

    *loadModel(vendorId) {
      const store = this.store;
      const memberships = store.peekAll('loyalty-membership');
      return yield toLoyaltyModelResults(store, vendorId, memberships.toArray()).then(results => {
        const updates = results.filter(_trueMyth.Result.isOk).map(_trueMyth.Result.unsafelyUnwrap).map(fullyLoadedDataToStatus);

        this._updateRewardsLinkStatus(updates);

        return _trueMyth.Result.ok(results);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadModel", [_emberConcurrency.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadModel"), _class.prototype)), _class));
  _exports.default = LoyaltyService;
});