define("mobile-web/routes/menu/vendor", ["exports", "ember-concurrency-ts", "ember-object-update", "true-myth/result", "mobile-web/decorators/indexed", "mobile-web/lib/order-criteria", "mobile-web/lib/utilities/headers", "mobile-web/lib/utilities/transform-content", "mobile-web/models/order-search-result"], function (_exports, _emberConcurrencyTs, _emberObjectUpdate, _result, _indexed, _orderCriteria, _headers, _transformContent, _orderSearchResult) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let VendorRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service('vendor'), _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service('error'), _dec8 = Ember.computed.alias('channel.current'), _dec9 = Ember.computed.readOnly('session.isLoggedIn'), _dec10 = Ember._action, (0, _indexed.default)(_class = (_class2 = class VendorRoute extends Ember.Route {
    constructor(...args) {
      super(...args);
      this.previousRoute = {
        label: 'Locations',
        route: 'vendor-search-results',
        models: []
      };
      this.headTags = [];

      _initializerDefineProperty(this, "loyalty", _descriptor, this);

      _initializerDefineProperty(this, "orderCriteria", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "vendorService", _descriptor4, this);

      _initializerDefineProperty(this, "channel", _descriptor5, this);

      _initializerDefineProperty(this, "basket", _descriptor6, this);

      _initializerDefineProperty(this, "errorService", _descriptor7, this);

      _initializerDefineProperty(this, "currentChannel", _descriptor8, this);

      _initializerDefineProperty(this, "isLoggedIn", _descriptor9, this);
    }

    async setupController(controller, model, transition) {
      super.setupController(controller, model, transition);

      if (controller.handoff !== undefined) {
        controller.set('skipPreCheck', this.basket.basket !== undefined);
        const handoffMode = this.getSearchHandoffMode(controller.handoff);
        this.orderCriteria.updateSearchOrderCriteria(handoffMode);
      }

      if (this.isTruthy(controller.openCriteria)) {
        this.orderCriteria.openModal();
      }

      Ember.run.next(this, () => {
        controller.set('handoff', undefined);
        controller.set('openCriteria', undefined);
      });
    }

    beforeModel(transition) {
      var _this$channel$setting;

      if (!((_this$channel$setting = this.channel.settings) != null && _this$channel$setting.isResponsive)) {
        var _transition$to$parent;

        const vendor = (_transition$to$parent = transition.to.parent) == null ? void 0 : _transition$to$parent.params.vendor_slug;

        if (vendor) {
          var _this$currentChannel;

          const url = `https://${(_this$currentChannel = this.currentChannel) == null ? void 0 : _this$currentChannel.settings.fullSiteUrl}/menu/${vendor}`;
          (0, _emberObjectUpdate.default)(this, 'headTags', (0, _headers.setCanonicalUrl)(url));
        }
      }
    } // Do not delete or rename. This is used by
    // https://github.com/mike-north/ember-cli-document-title-northm


    async titleToken(model) {
      try {
        const content = await this.store.findRecord('content', 'MENU_TITLE', {
          reload: false
        });
        const loadedValue = content == null ? void 0 : content.value;
        return (0, _transformContent.transformContent)(loadedValue, _transformContent.ContentType.TEXT, {
          VendorName: model.vendor.name,
          City: model.vendor.address.city,
          State: model.vendor.address.state
        }).split(' - ');
      } catch (_unused) {
        return [model.vendor.name];
      }
    }

    async model(params) {
      const isLoggedIn = this.isLoggedIn;
      const vendor = await this.vendorService.ensureVendorLoaded(params.vendor_slug);
      const loadedLoyaltyData = isLoggedIn ? (await (0, _emberConcurrencyTs.taskFor)(this.loyalty.loadModel).perform(vendor.id)).unwrapOr([]).filter(_result.default.isOk).map(_result.Ok.unwrap) : [];
      const loyalty = loadedLoyaltyData.map(data => ({
        account: data.account,
        schemeName: data.membership.schemeName
      }));
      const recentOrders = this.session.isLoggedIn ? this.store.findAll('order-search-result').then(results => results.filter(r => r.vendorSlug === params.vendor_slug && r.status === _orderSearchResult.OrderSearchResultStatus.Complete)) : undefined;

      if (!this.orderCriteria.criteria.isDefault) {
        const oc = this.orderCriteria.criteria;

        if ((0, _orderCriteria.isDelivery)(oc) && oc.deliveryAddress && !oc.deliveryAddress.id) {
          var _this$basket$basket;

          await this.session.updateCountryCodeFromZip(oc.deliveryAddress.zipCode);
          const newAddress = await this.store.collectionAction('address', 'addDeliveryAddress', _extends({}, oc.deliveryAddress, {
            basketId: (_this$basket$basket = this.basket.basket) == null ? void 0 : _this$basket$basket.id
          }));
          oc.deliveryAddress.id = newAddress.id;
        }
      }

      return Ember.RSVP.hash({
        vendor,
        loyalty,
        recentOrders
      });
    }

    shouldIndex() {
      const vendorService = this.vendorService;
      const vendor = vendorService.vendor;
      return !Ember.isNone(vendor) && vendor.status.toLowerCase() === 'public';
    }

    isTruthy(val) {
      const falsey = ['0', 'false', 'no', 'off']; //I understand this doesn't work with some languages.

      return val !== undefined && !falsey.includes(val.toLocaleLowerCase());
    }

    getSearchHandoffMode(handoff) {
      switch (handoff.toLocaleLowerCase()) {
        case 'counterpickup':
          return 'CounterPickup';

        case 'curbsidepickup':
          return 'CurbsidePickup';

        case 'delivery':
          return 'MultiDelivery';

        case 'drivethru':
          return 'DriveThru';

        case 'dinein':
          return 'DineIn';

        default:
          return 'Unspecified';
      }
    }

    refreshVendorModel() {
      this.refresh();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "loyalty", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "orderCriteria", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "vendorService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "channel", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "basket", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "errorService", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "currentChannel", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "isLoggedIn", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "refreshVendorModel", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "refreshVendorModel"), _class2.prototype)), _class2)) || _class);
  _exports.default = VendorRoute;
});