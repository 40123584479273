define("mobile-web/components/r-link-rewards/component", ["exports", "@ember-decorators/component", "mobile-web/components/r-link-rewards/style"], function (_exports, _component, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LinkRewards = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service('vendor'), _dec3 = Ember.inject.service, _dec4 = Ember.computed.alias('vendorService.vendor.loyaltySchemes'), _dec5 = Ember.computed.notEmpty('schemes'), _dec6 = Ember.computed('memberships.@each.isNew'), _dec7 = Ember.computed('linkedSchemeNames.length', 'schemes.length'), _dec(_class = (_class2 = class LinkRewards extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "vendorService", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      this.memberships = [];
      this.isOpen = false;
      this.style = _style.default;

      _initializerDefineProperty(this, "schemes", _descriptor3, this);

      _initializerDefineProperty(this, "show", _descriptor4, this);
    }

    /**
     * Returns a list with all scheme names that have been linked.
     * Excludes memberships with isNew because they haven't been
     * saved yet. Does a uniq because you can have more than
     * 1 membership for a given scheme.
     */
    get linkedSchemeNames() {
      return this.memberships.filter(m => !m.isNew).map(m => m.schemeName).uniq();
    }

    get fullyLinked() {
      return this.linkedSchemeNames.length === this.schemes.length;
    } // Init


    init() {
      super.init();
      this.set('memberships', this.store.peekAll('loyalty-membership'));
    } // Other methods
    // Actions


  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "vendorService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "schemes", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "show", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "linkedSchemeNames", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "linkedSchemeNames"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "fullyLinked", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "fullyLinked"), _class2.prototype)), _class2)) || _class);
  _exports.default = LinkRewards;
});