define("mobile-web/adapters/application", ["exports", "ember-data", "@capacitor/core", "mobile-web/config/environment", "mobile-web/lib/hybrid-util", "mobile-web/services/error"], function (_exports, _emberData, _core, _environment, _hybridUtil, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const HEADER = '__RequestVerificationToken';

  const getToken = () => {
    var _document$querySelect, _document$querySelect2;

    return (_document$querySelect = (_document$querySelect2 = document.querySelector(`input[name=${HEADER}]`)) == null ? void 0 : _document$querySelect2.value) != null ? _document$querySelect : '';
  };

  let ApplicationAdapter = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember.computed('currentPath', 'device.{viewport,isHybrid,platform}', 'session.serveAppToken', 'storage.forceChallengePattern', 'viewport'), (_class = class ApplicationAdapter extends _emberData.default.RESTAdapter {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "media", _descriptor, this);

      _initializerDefineProperty(this, "storage", _descriptor2, this);

      _initializerDefineProperty(this, "device", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      this.namespace = 'api';
      this.host = (0, _hybridUtil.host)();

      _initializerDefineProperty(this, "currentPath", _descriptor5, this);
    }

    get headers() {
      var _this$currentPath;

      const headers = {
        [HEADER]: getToken(),
        Accept: 'application/json, */*',
        'X-Requested-With': 'XMLHttpRequest',
        'X-Olo-Request': '1',
        'X-Olo-Viewport': this.device.viewport,
        'X-Olo-App-Platform': this.device.platform
      };
      const pattern = this.storage.forceChallengePattern;

      if (pattern && new RegExp(pattern).test((_this$currentPath = this.currentPath) != null ? _this$currentPath : '')) {
        headers['X-PX-Block'] = '1';
      }

      if (this.device.isHybrid && this.session.serveAppToken) {
        headers['X-Olo-Serve-User-Token'] = this.session.serveAppToken;
      }

      return headers;
    }

    shouldBackgroundReloadRecord(_store, _snapshot) {
      return false;
    }
    /**
     * The RESTAdapter uses this method to parse any error responses that come through the system.
     * This is a private API method, which is sad, but overriding it allows us to customize
     * the shape of the error responses we get from Ember Data.
     *
     * The payloadData parameter comes to us as a string, but it is *usually* a stringified JSON
     * object. We do a JSON.parse on it to get the actual error shape, and then massage it to the
     * shape we want. Thr try/catch is here to make the JSON.parse call a safe call, because we
     * don't want to be throwing errors from our error handler. If things go wrong, we just let
     * Ember Data do its normal thing by calling up the super method.
     */


    normalizeErrorResponse(status, _headers, payload) {
      try {
        if (payload && 'blockScript' in payload) {
          return [{
            status,
            message: JSON.stringify(payload)
          }];
        }

        if ((payload == null ? void 0 : payload.errorCode) === _error.Errors.ForUser || (payload == null ? void 0 : payload.errorCode) === 'MessageForUser') {
          return [{
            status,
            title: _error.Errors.ForUser,
            message: payload.message,
            category: payload.category
          }];
        } // @ts-ignore


        return super.normalizeErrorResponse(status, _headers, payload);
      } catch (_unused) {
        // @ts-ignore
        return super.normalizeErrorResponse(status, _headers, payload);
      }
    }

    generatedDetailedMessage(status, headers, payload, requestData) {
      // @ts-ignore using private API :(
      return super.generatedDetailedMessage(status, headers, JSON.stringify(payload), requestData);
    } // TODO: Remove with extreme prejudice. MWC-3421
    // ^ probably unlikely until Ember gives us some public way to do this :(


    _fetchRequest(options) {
      const fetchMethod = !_environment.default.useMirage && _environment.default.isHybrid && _core.Capacitor.isNativePlatform() ? // If this is a hybrid app, we need to use the Cordova HTTP plugin to get around CORS issues
      _hybridUtil.mapFetch : // @ts-ignore private API :(
      super._fetchRequest;
      return fetchMethod(options).then(r => {
        var _r$_bodyBlob;

        // We have to do this because we have a handful of endpoints that return no body content
        // with a status code of 200. Ember really doesn't like that, and ends up throwing an error.
        // Endpoints that return no body should be using a status code of 204.
        // This manipulates the response to pretend like it is a good response until we have a chance
        // to make a PLAT PR that actually makes them be good responses.
        if ((r._bodyText === '' || ((_r$_bodyBlob = r._bodyBlob) == null ? void 0 : _r$_bodyBlob.size) === 0) && r.status === 200) {
          // @ts-ignore
          r.status = 204;
        }

        return r;
      });
    } // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any


    ajax(url, type, options) {
      this.currentPath = url;
      return super.ajax(url, type, options);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "media", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "device", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentPath", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "headers", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "headers"), _class.prototype)), _class));
  _exports.default = ApplicationAdapter;
});