define("mobile-web/components/r-card/grid/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vsnP6oYn",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[11,\"ul\"],[16,0,[30,0,[\"listClass\"]]],[24,\"data-test-card-grid\",\"\"],[4,[38,1],[[30,0,[\"onInsert\"]]],null],[4,[38,2],[[30,0,[\"onDestroy\"]]],null],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@items\",\"@initialInsertion\",\"@use\"],[[30,0,[\"cards\"]],true,[30,0,[\"transition\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[18,3,[[28,[37,5],null,[[\"itemClass\",\"model\",\"idx\"],[[30,0,[\"style\",\"item\"]],[30,1],[30,2]]]]]],[1,\"\\n    \"]],[1,2]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"card\",\"idx\",\"&default\"],false,[\"animated-container\",\"did-insert\",\"will-destroy\",\"animated-each\",\"yield\",\"hash\"]]",
    "moduleName": "mobile-web/components/r-card/grid/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});