define("mobile-web/lib/menu", ["exports", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/device-detect"], function (_exports, _, _deviceDetect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createDefaultBasketChoices = createDefaultBasketChoices;
  _exports.createBasketChoice = createBasketChoice;
  _exports.isParentNew = isParentNew;
  _exports.getOrCreateBasketChoice = getOrCreateBasketChoice;
  _exports.firstInvalidOptionGroup = firstInvalidOptionGroup;
  _exports.loadNestedMenuItems = loadNestedMenuItems;
  _exports.groupChoices = groupChoices;
  _exports.groupOptionGroups = groupOptionGroups;

  async function createDefaultBasketChoicesHelper(store, basketProduct, optionGroups, createdChoices) {
    const ogs = await optionGroups;

    for (let i = 0; i < ogs.length; i++) {
      const og = ogs.objectAt(i);

      for (let j = 0; j < og.choices.length; j++) {
        const c = og.choices.objectAt(j);

        if (c.isDefault && !createdChoices.includes(c.id)) {
          createBasketChoice(store, basketProduct, c);
          createdChoices.push(c.id);
        }

        if (createdChoices.includes(c.id)) {
          await createDefaultBasketChoicesHelper(store, basketProduct, c.optionGroups, createdChoices);
        }
      }
    }
  }
  /**
   * This creates basket-choice model objects for each default choice
   * in the supplied option groups. This needs to happen when a basket
   * product is first created, in order to have any default choices
   * selected. It also needs to happen when you change a selection,
   * to have any default choices underneath the new selection be
   * selected.
   * @param store Ember Data store
   * @param basketProduct The basket product to tie the new basket choices to
   * @param optionGroups Any default choices in these option groups will have their
   * entire tree of nested choices searched. Any of those choices which are default
   * will have basket choices created for them (if one doesn't already exist).
   */


  async function createDefaultBasketChoices(store, basketProduct, optionGroups) {
    const createdChoices = store.peekAll('basket-choice').reduce((choiceIds, bc) => {
      var _bc$basketProduct;

      const choiceId = bc.belongsTo('choice').id();

      if (((_bc$basketProduct = bc.basketProduct) == null ? void 0 : _bc$basketProduct.id) === basketProduct.id && !choiceIds.includes(choiceId)) {
        return [...choiceIds, choiceId];
      }

      return choiceIds;
    }, []);
    await createDefaultBasketChoicesHelper(store, basketProduct, optionGroups, createdChoices);
  }

  function createBasketChoice(store, basketProduct, c) {
    return store.createRecord('basket-choice', {
      name: c.name,
      quantity: isParentNew(basketProduct, c) ? c.defaultQuantity : 0,
      customFieldValues: (0, _.cloneDeep)(c.customFieldValues),
      basketProduct,
      choice: c
    });
  }

  function isParentNew(basketProduct, c) {
    const parentBasketChoice = basketProduct.basketChoices.find(bc => {
      var _c$optionGroup, _c$optionGroup$parent;

      return bc.belongsTo('choice').id() === ((_c$optionGroup = c.optionGroup) == null ? void 0 : (_c$optionGroup$parent = _c$optionGroup.parentChoice) == null ? void 0 : _c$optionGroup$parent.id);
    });
    const parent = parentBasketChoice != null ? parentBasketChoice : basketProduct;
    return !!parent.isNew;
  }

  function getOrCreateBasketChoice(store, basketProduct, c) {
    const existingChoice = store.peekAll('basket-choice').find(bc => {
      var _bc$basketProduct2;

      return bc.belongsTo('choice').id() === c.id && ((_bc$basketProduct2 = bc.basketProduct) == null ? void 0 : _bc$basketProduct2.id) === basketProduct.id;
    });

    if (existingChoice) {
      return {
        choice: existingChoice,
        isNew: false
      };
    }

    const newChoice = createBasketChoice(store, basketProduct, c);
    return {
      choice: newChoice,
      isNew: true
    };
  }

  function firstInvalidOptionGroup(groups) {
    if (!(groups != null && groups.isFulfilled)) {
      return undefined;
    }

    const invalidGroups = groups.content.filter(g => !g.canSubmit);

    if (invalidGroups.length) {
      const first = invalidGroups[0];
      const selectedChoices = first.selectedBasketChoices;

      for (let i = 0; i < selectedChoices.length; i++) {
        const choice = selectedChoices.objectAt(i).choice.content;
        const invalid = firstInvalidOptionGroup(choice.optionGroups);

        if (invalid) {
          return invalid;
        }
      }

      return first;
    }

    return undefined;
  }
  /**
   * This function accomplishes three purposes:
   * 1. It loads option groups one level below where the user is currently at.
   *    This allows for no lag between when they select a choice with children
   *    and when we display those children.
   * 2. It allows formation groups with default choices to render their complete
   *    label without having to open the formation group.
   * 3. It correctly creates basket-choice objects for all defaulted choices under
   *    a formation group. This is needed because there was an assumption that
   *    defaulted choices would always render to the user, but formation groups
   *    break that assumption and we need to make sure we still have all the data
   *    we need.
   * Note: Internet Explorer cannot handle preloading nested choices.
   */


  async function loadNestedMenuItems(store, basketProduct, choice, forceRecurse) {
    const groups = await choice.optionGroups;
    groups.forEach(og => {
      og.choices.forEach(ogc => {
        const {
          choice: basketChoice
        } = getOrCreateBasketChoice(store, basketProduct, ogc);

        if (basketChoice.isSelected && (forceRecurse || !(0, _.isEmpty)(og.formationGroup)) && !_deviceDetect.IS_IE) {
          loadNestedMenuItems(store, basketProduct, basketChoice.choice.content, true);
        }
      });
    });
  }

  function groupChoices(optionGroup) {
    const displayGroups = optionGroup.displayGroups;
    return optionGroup.choices.reduce((results, c) => {
      if (c.displayGroup) {
        const match = displayGroups.find(g => g.name === c.displayGroup.optionName);

        if (match && !results.find(r => r.name === match.name)) {
          results.push(match);
        }
      } else {
        results.push(c);
      }

      return results;
    }, []);
  }

  function groupOptionGroups(optionGroups) {
    return optionGroups.reduce((groups, og) => {
      const lastGroup = groups[groups.length - 1];
      const isFormationGroup = !(0, _.isEmpty)(og.formationGroup);

      if (!lastGroup || lastGroup.isFormationGroup !== isFormationGroup) {
        groups.push({
          id: `${og.id}`,
          optionGroups: [og],
          isFormationGroup
        });
        return groups;
      }

      lastGroup.optionGroups.push(og);
      lastGroup.id += `-${og.id}`;
      return groups;
    }, []);
  }
});