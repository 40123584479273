define("mobile-web/controllers/secure/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ResetPasswordController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      this.queryParams = ['id'];
    }

  }

  _exports.default = ResetPasswordController;
});