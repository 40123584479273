define("mobile-web/components/routes/index-route/component", ["exports", "@ember-decorators/component", "mobile-web/lib/computed", "mobile-web/lib/order-criteria", "mobile-web/lib/utilities/_", "mobile-web/services/analytics", "mobile-web/services/user-feedback", "mobile-web/components/routes/index-route/style"], function (_exports, _component, _computed, _orderCriteria, _, _analytics, _userFeedback, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IndexRoute = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember.inject.service, _dec11 = Ember.inject.service, _dec12 = Ember.inject.service, _dec13 = Ember.computed.alias('channel.current'), _dec14 = Ember.computed.alias('session.isLoggedIn'), _dec15 = Ember.computed('currentChannel.name'), _dec16 = Ember.computed('days.length', 'vendorSearchAvailable'), _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec(_class = (_class2 = class IndexRoute extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "channel", _descriptor, this);

      _initializerDefineProperty(this, "device", _descriptor2, this);

      _initializerDefineProperty(this, "basket", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "orderCriteria", _descriptor5, this);

      _initializerDefineProperty(this, "session", _descriptor6, this);

      _initializerDefineProperty(this, "userFeedback", _descriptor7, this);

      _initializerDefineProperty(this, "store", _descriptor8, this);

      _initializerDefineProperty(this, "router", _descriptor9, this);

      _initializerDefineProperty(this, "analytics", _descriptor10, this);

      _initializerDefineProperty(this, "features", _descriptor11, this);

      this.vendorSearchAvailable = true;
      this.savingAddress = false;
      this.savingAddressLabel = 'Saving Address';
      this.style = _style.default;

      _initializerDefineProperty(this, "currentChannel", _descriptor12, this);

      _initializerDefineProperty(this, "isLoggedIn", _descriptor13, this);

      _initializerDefineProperty(this, "localVendorSearchParams", _descriptor14, this);
    }

    get savedAddresses() {
      return this.store.peekAll('address');
    }

    get responsiveHeaderSubstitutions() {
      var _this$currentChannel$, _this$currentChannel;

      return {
        channelname: (_this$currentChannel$ = (_this$currentChannel = this.currentChannel) == null ? void 0 : _this$currentChannel.name) != null ? _this$currentChannel$ : ''
      };
    }

    get vendorSearchUnavailableWarning() {
      if (this.vendorSearchAvailable) {
        return '';
      }

      return this.intl.t('mwc.splash.notAvailableWarningMessageGeneric');
    } // Init


    init() {
      var _this$currentChannel2;

      super.init(); // The underlying code will crash if there are no public handoff modes passed in,
      // since it is impossible to search for vendors if none are public.

      if ((_this$currentChannel2 = this.currentChannel) != null && _this$currentChannel2.handoffModes.filter(x => !x.isPrivate).length) {
        this.showWarnings();
        this.device.checkForNativeUpdate(true);
      }
    } // Other methods


    showWarnings() {
      if (this.vendorSearchUnavailableWarning) {
        this.userFeedback.add({
          type: _userFeedback.Type.Warning,
          title: this.intl.t('mwc.splash.notAvailableWarningTitle'),
          message: this.vendorSearchUnavailableWarning
        });
      }
    }

    async handleSubmit(criteria) {
      if (this.isLoggedIn && (0, _orderCriteria.isDelivery)(criteria) && criteria.deliveryAddress) {
        if ((0, _.isEmpty)(criteria.deliveryAddress.id)) {
          try {
            var _this$basket$basket;

            this.savingAddress = true;
            await this.session.updateCountryCodeFromZip(criteria.deliveryAddress.zipCode);
            const newAddress = await this.store.collectionAction('address', 'addDeliveryAddress', _extends({}, criteria.deliveryAddress, {
              basketId: (_this$basket$basket = this.basket.basket) == null ? void 0 : _this$basket$basket.id
            }));

            if (newAddress) {
              criteria.deliveryAddress = newAddress;
            }
          } finally {
            this.savingAddress = false;
          }
        }
      }

      this.analytics.trackEvent(_analytics.AnalyticsEvents.SearchForLocations, () => ({
        [_analytics.AnalyticsProperties.SelectedHandoffMode]: criteria.handoffMode.toString(),
        [_analytics.AnalyticsProperties.SelectedTimeWantedMode]: _analytics.TIME_WANTED_ANALYTICS_LABELS[criteria.timeWantedType]
      }));
      this.orderCriteria.searchOrderCriteria = criteria;
      this.showVendorResults(criteria);
      return Ember.RSVP.resolve();
    }

    showVendorResults(criteria) {
      var _criteria$deliveryAdd, _address$id, _ref, _address$streetAddres, _address$building, _address$city, _address$zipCode;

      const address = (0, _orderCriteria.isDelivery)(criteria) ? (_criteria$deliveryAdd = criteria.deliveryAddress) != null ? _criteria$deliveryAdd : {} : {};
      const searchAddress = (0, _orderCriteria.isAtStore)(criteria) ? criteria.searchAddress : undefined;
      const searchCoords = (0, _orderCriteria.isAtStore)(criteria) ? criteria.searchCoords : undefined;
      this.localVendorSearchParams = {
        addressId: (_address$id = address.id) != null ? _address$id : '',
        address: (_ref = (_address$streetAddres = address.streetAddress) != null ? _address$streetAddres : searchAddress) != null ? _ref : '',
        building: (_address$building = address.building) != null ? _address$building : '',
        city: (_address$city = address.city) != null ? _address$city : '',
        zipCode: (_address$zipCode = address.zipCode) != null ? _address$zipCode : '',
        handoffMode: criteria.handoffMode,
        timeWantedType: criteria.timeWantedType,
        timeWanted: (0, _orderCriteria.isAdvance)(criteria) && criteria.timeWanted ? criteria.timeWanted.toISOString() : undefined,
        latitude: searchCoords == null ? void 0 : searchCoords.latitude,
        longitude: searchCoords == null ? void 0 : searchCoords.longitude
      };

      if ((0, _orderCriteria.isDelivery)(criteria)) {
        this.router.transitionTo('menu');
      } else {
        this.router.transitionTo('vendor-search-results');
      }
    }

    onSearchUnavailable() {
      Ember.set(this, 'vendorSearchAvailable', false);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "channel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "device", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "basket", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "orderCriteria", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "userFeedback", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "analytics", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "features", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "currentChannel", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "isLoggedIn", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class2.prototype, "localVendorSearchParams", [_computed.local], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "responsiveHeaderSubstitutions", [_dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "responsiveHeaderSubstitutions"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "vendorSearchUnavailableWarning", [_dec16], Object.getOwnPropertyDescriptor(_class2.prototype, "vendorSearchUnavailableWarning"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleSubmit", [_dec17], Object.getOwnPropertyDescriptor(_class2.prototype, "handleSubmit"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "showVendorResults", [_dec18], Object.getOwnPropertyDescriptor(_class2.prototype, "showVendorResults"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onSearchUnavailable", [_dec19], Object.getOwnPropertyDescriptor(_class2.prototype, "onSearchUnavailable"), _class2.prototype)), _class2)) || _class);
  _exports.default = IndexRoute;
});