define("mobile-web/routes/dispatch-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DispatchStatusRoute extends Ember.Route {
    async model(params) {
      const dispatchStatus = this.store.findRecord('dispatch-status', params.status_id, {
        reload: true,
        adapterOptions: {
          orderId: params.order_id,
          hash: params.hash
        }
      });
      return this.store.findRecord('order', params.order_id, {
        adapterOptions: {
          hash: params.hash
        }
      }).then(order => Ember.RSVP.hash({
        order,
        dispatchStatus
      }));
    }

  }

  _exports.default = DispatchStatusRoute;
});