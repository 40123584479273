define("mobile-web/components/loading-indicator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1R8PoVAW",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,0],[16,0,[52,[30,2],[30,0,[\"style\",\"loadingIndicator\"]]]],[24,\"data-test-loading-indicator\",\"\"],[17,3],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"      \"],[10,0],[15,0,[30,0,[\"style\",\"loadingText\"]]],[14,\"data-focusWhen\",\"loading\"],[12],[1,[30,0,[\"label\"]]],[1,\"…\"],[13],[1,\"\\n      \"],[10,0],[15,0,[30,0,[\"style\",\"spinner\"]]],[14,\"aria-hidden\",\"true\"],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@class\"],[[30,0,[\"style\",\"spinnerIcon\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[6,[39,2],null,[[\"translucentOverlay\",\"clickOutsideToClose\",\"overlayClass\",\"containerClass\",\"aria-labelledby\"],[true,false,[30,0,[\"style\",\"overlay\"]],[30,0,[\"style\",\"loadingIndicator\"]],[30,0,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[15,1,[30,0,[\"id\"]]],[15,0,[30,0,[\"style\",\"loadingText\"]]],[14,\"data-focusWhen\",\"loading\"],[12],[1,[30,0,[\"label\"]]],[1,\"…\"],[13],[1,\"\\n        \"],[10,0],[15,0,[30,0,[\"style\",\"spinner\"]]],[14,\"aria-hidden\",\"true\"],[12],[1,\"\\n          \"],[8,[39,1],null,[[\"@iconClass\"],[[30,0,[\"style\",\"spinnerIcon\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]]]]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@display\",\"@inline\",\"&attrs\"],false,[\"if\",\"r-icons/loading-spinner\",\"modal-dialog\"]]",
    "moduleName": "mobile-web/components/loading-indicator/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});