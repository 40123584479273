define("mobile-web/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g4vVoxob",
    "block": "[[[10,\"h1\"],[12],[1,\"An error has occurred\"],[13]],[],false,[]]",
    "moduleName": "mobile-web/templates/error.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});