define("mobile-web/templates/third-party", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rTULCFcE",
    "block": "[[[1,\"\\n\"]],[],false,[]]",
    "moduleName": "mobile-web/templates/third-party.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});