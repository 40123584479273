define("mobile-web/components/r-heading/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "mobile-web/components/r-heading/style"], function (_exports, _glimmerComponent, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @summary Dynamically creates an h1-6 element.
   *
   * Only use this if you need to switch between <h#> elements.
   *
   * @example <caption>Create a dynamic heading</caption>
   *
   * <RHeading
   *   @level={{this.level}}
   *   class={{this.style.heading}}
   * >
   *   Example heading
   * </RHeading>
   */
  class Heading extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    } // Tracked properties
    // Getters and setters
    // Constructor
    // Other methods
    // Tasks
    // Actions


  }

  _exports.default = Heading;
});