define("mobile-web/lib/utilities/ga", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bootUpUAEngine = void 0;

  // Google Analytics loading as a separate file so we can mock it
  // All the ts-ignore because this is Google's crap.
  const bootUpUAEngine = () => new Promise((res, rej) => {
    /* eslint-disable */
    (function (i, s, o, g, r, a, m) {
      // @ts-ignore
      i.GoogleAnalyticsObject = r; // @ts-ignore

      i[r] = // @ts-ignore
      i[r] || function () {
        // @ts-ignore
        (i[r].q = i[r].q || []).push(arguments);
      }, // @ts-ignore
      i[r].l = Number(new Date()); // `Number` instead of `1 *` for TypeScript not to yell.
      // @ts-ignore

      a = s.createElement(o), m = s.getElementsByTagName(o)[0]; // @ts-ignore

      a.async = 1; // @ts-ignore

      a.src = g; // @ts-ignore

      a.onload = res; // @ts-ignore

      a.onerror = rej; // @ts-ignore

      m.parentNode.insertBefore(a, m);
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
    /* eslint-enable */

  });

  _exports.bootUpUAEngine = bootUpUAEngine;
});