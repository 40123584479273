define("mobile-web/routes/secure/login", ["exports", "ember-object-update", "mobile-web/lib/utilities/headers"], function (_exports, _emberObjectUpdate, _headers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LoginRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class LoginRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "channel", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      this.titleToken = 'Login';
      this.headTags = [{
        tagId: 'allow-indexing',
        type: 'meta',
        attrs: {
          name: 'robots',
          content: 'noindex'
        }
      }];
    }

    beforeModel(transition) {
      if (!this.session.internalSignOnAllowed) {
        transition.abort();
        return this.transitionTo('application');
      }

      return super.beforeModel(transition);
    }

    model(params) {
      const guestUser = this.session.guestUser;
      const lastOrderId = this.session.lastOrderId;
      return params.upgrade ? {
        guestUser,
        lastOrderId
      } : {
        guestUser: undefined,
        lastOrderId: undefined
      };
    }

    activate() {
      var _this$channel$setting;

      if (!((_this$channel$setting = this.channel.settings) != null && _this$channel$setting.isResponsive)) {
        var _this$channel$setting2;

        const url = `https://${(_this$channel$setting2 = this.channel.settings) == null ? void 0 : _this$channel$setting2.fullSiteUrl}/user/login`;
        (0, _emberObjectUpdate.default)(this, 'headTags', (0, _headers.setCanonicalUrl)(url));
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "channel", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = LoginRoute;
});