define("mobile-web/serializers/loyalty-membership", ["exports", "mobile-web/lib/utilities/is-some", "mobile-web/serializers/base-json-serializer"], function (_exports, _isSome, _baseJsonSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LoyaltyMembershipSerializer extends _baseJsonSerializer.default {
    constructor(...args) {
      super(...args);
      this.primaryKey = 'membershipId';
      this.modelName = 'loyalty-membership';
    }

    serialize(s, options) {
      const snapshot = s;

      if ((0, _isSome.default)(snapshot.record.scheme)) {
        return {
          member: snapshot.record.membershipId,
          schemeId: snapshot.record.scheme.id,
          vendorId: snapshot.record.scheme.vendor.get('id')
        };
      }

      return super.serialize(snapshot, options);
    }

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      return super.normalizeResponse(store, primaryModelClass, payload.loyaltyMembership || payload.loyaltyMemberships || payload, id, requestType);
    }

  }

  _exports.default = LoyaltyMembershipSerializer;
});