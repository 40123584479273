define("mobile-web/components/routes/locations-route/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_c01hv5",
    "rootCrumb": "_rootCrumb_c01hv5",
    "map": "_map_c01hv5",
    "mapGraphic": "_mapGraphic_c01hv5",
    "mapSvg": "_mapSvg_c01hv5",
    "activeRegion": "_activeRegion_c01hv5",
    "container": "_container_c01hv5",
    "heading": "_heading_c01hv5",
    "intlHeading": "_intlHeading_c01hv5",
    "collapse": "_collapse_c01hv5",
    "listContainer": "_listContainer_c01hv5",
    "link": "_link_c01hv5",
    "content": "_content_c01hv5"
  };
  _exports.default = _default;
});