define("mobile-web/services/bus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This interface intentionally left empty. It is meant to be extended in other files:
   *
   * declare module 'mobile-web/services/bus' {
   *   interface EventRegistry {
   *     // ...
   *   }
   * }
  }
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  class BusService extends Ember.Service.extend(Ember.Evented) {
    on(name, method) {
      return super.on(name, method);
    }

    trigger(name, arg) {
      super.trigger(name, arg);
    }

  }

  _exports.default = BusService;
});