define("mobile-web/components/r-checkout/tip/component", ["exports", "@glimmer/component", "ember-concurrency-ts", "mobile-web/lib/order-criteria", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-checkout/tip/style"], function (_exports, _component, _emberConcurrencyTs, _orderCriteria, _, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const OTHER_VALUE = 0;
  const DEFAULT_TIP_PERCENTAGES = [{
    label: '15%',
    value: 0.15
  }, {
    label: '20%',
    value: 0.2
  }, {
    label: '25%',
    value: 0.25
  }];
  let CheckoutTip = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class CheckoutTip extends _component.default {
    // Service injections
    // Passthroughs
    // Tracked properties
    // Getters and setters
    get percentages() {
      return [...DEFAULT_TIP_PERCENTAGES, {
        label: this.intl.t('mwc.checkout.otherLabel'),
        value: OTHER_VALUE
      }];
    }

    get filteredMethods() {
      var _this$args$methods;

      return (_this$args$methods = this.args.methods) == null ? void 0 : _this$args$methods.filter(method => method.canIncludeTip);
    }

    get percentage() {
      if (Ember.isNone(this.amount)) {
        return undefined;
      }

      const match = this.percentages.find(p => this.percentageAmount(p.value) === this.amount);
      return (0, _isSome.default)(match) ? match.value : OTHER_VALUE;
    }

    get isDelivery() {
      return (0, _orderCriteria.isDeliveryMode)(this.basket.basket.handoffMode);
    } // Constructor


    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "features", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "basket", _descriptor3, this);

      this.style = _style.default;

      _initializerDefineProperty(this, "amount", _descriptor4, this);

      const basket = this.basket.basket;

      if (basket.tip > 0) {
        this.amount = basket.tip;
      } else if (basket.suggestedTip > 0) {
        this.setPercentage(basket.suggestedTip / 100);
      }
    } // Other methods


    percentageAmount(percentage) {
      return +(percentage * this.basket.basket.subTotal).toFixed(2);
    } // Tasks
    // Actions


    setPercentage(percentage) {
      const amount = this.percentageAmount(typeof percentage === 'number' ? percentage : percentage.value);
      (0, _emberConcurrencyTs.taskFor)(this.basket.updateTipTask).perform(amount);
      this.amount = amount;
    }

    amountChanged(amount) {
      this.amount = amount;

      if (this.basket.basket.tip !== amount) {
        (0, _emberConcurrencyTs.taskFor)(this.basket.updateTipTask).perform(amount);
      }
    }

    updateMethod(method) {
      if ((0, _.isFunction)(this.args.onMethodChange) && (0, _isSome.default)(this.args.methods)) {
        const newMethods = this.args.methods.map(m => {
          if (m === method) {
            return _extends({}, m, {
              includesTip: true
            });
          }

          return _extends({}, m, {
            includesTip: false
          });
        });
        this.args.onMethodChange(newMethods);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "features", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "amount", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setPercentage", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setPercentage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "amountChanged", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "amountChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateMethod", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "updateMethod"), _class.prototype)), _class));
  _exports.default = CheckoutTip;
});