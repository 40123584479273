define("mobile-web/components/r-cart/upsell-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6YUn/p72",
    "block": "[[[11,0],[16,0,[30,0,[\"style\",\"root\"]]],[24,\"tabindex\",\"0\"],[24,\"data-test-cart-upsellItem\",\"\"],[4,[38,0],[\"click\",[30,0,[\"addUpsellItem\"]]],null],[4,[38,0],[\"keypress\",[30,0,[\"handleKeyPress\"]]],null],[12],[1,\"\\n\"],[41,[30,1,[\"image\"]],[[[1,\"    \"],[10,\"img\"],[15,0,[30,0,[\"style\",\"image\"]]],[15,\"src\",[30,1,[\"image\"]]],[14,\"alt\",\"\"],[14,\"data-test-cart-upsellItem-image\",\"\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,\"h5\"],[15,0,[30,0,[\"style\",\"name\"]]],[14,\"data-test-cart-upsellItem-name\",\"\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"],[41,[30,1,[\"calorieLabel\"]],[[[1,\"    \"],[10,2],[15,0,[30,0,[\"style\",\"calories\"]]],[14,\"data-test-cart-upsellItem-calories\",\"\"],[12],[1,[30,1,[\"calorieLabel\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,1,[\"labels\"]]],null]],null],null,[[[41,[30,2,[\"icon\"]],[[[1,\"      \"],[10,0],[15,0,[30,0,[\"style\",\"row\"]]],[14,\"data-test-upsellItem-label\",\"\"],[12],[1,\"\\n        \"],[8,[39,4],null,[[\"@icon\"],[[30,2,[\"icon\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[2]],null],[1,\"\\n  \"],[10,2],[15,0,[30,0,[\"style\",\"cost\"]]],[14,\"data-test-cart-upsellItem-cost\",\"\"],[12],[1,[28,[35,5],[[30,1,[\"cost\"]]],null]],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@upsellItem\",\"label\"],false,[\"on\",\"if\",\"each\",\"-track-array\",\"r-custom-icon\",\"format-currency\"]]",
    "moduleName": "mobile-web/components/r-cart/upsell-item/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});