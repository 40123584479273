define("mobile-web/components/r-info-tag/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tdXSP8KI",
    "block": "[[[10,0],[15,0,[30,0,[\"rootClass\"]]],[15,\"data-test-info-tag\",[30,0,[\"type\"]]],[12],[1,\"\\n  \"],[10,1],[14,\"data-test-info-tag\",\"label\"],[12],[1,[34,0]],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"label\"]]",
    "moduleName": "mobile-web/components/r-info-tag/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});