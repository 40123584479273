define("mobile-web/adapters/loyalty-scheme", ["exports", "mobile-web/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LoyaltySchemeAdapter extends _application.default {
    pathForType() {
      return 'loyalty/schemes';
    }

  }

  _exports.default = LoyaltySchemeAdapter;
});