define("mobile-web/serializers/content", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ContentSerializer extends _emberData.default.RESTSerializer {
    constructor(...args) {
      super(...args);
      this.isNewSerializerAPI = true;
      this.primaryKey = 'key';
    }

  }

  _exports.default = ContentSerializer;
});