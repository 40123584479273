define("mobile-web/routes/menu/vendor/rewards", ["exports", "mobile-web/decorators/authenticated", "mobile-web/lib/routing", "mobile-web/lib/utilities/is-some"], function (_exports, _authenticated, _routing, _isSome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let RewardsRoute = (_dec = Ember.inject.service, (0, _authenticated.default)(_class = (_class2 = class RewardsRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "vendor", _descriptor, this);

      this.titleToken = 'Offers & Rewards';
    }

    model(params) {
      const vendorService = this.vendor;
      return vendorService.ensureVendorLoaded(params.vendor_slug).then(vendor => {
        Ember.set(this, 'previousRoute', (0, _routing.vendorToMenuRoute)(vendor));
        const accounts = Ember.RSVP.all(this.store.peekAll('loyalty-membership').map(m => this.store.queryRecord('loyalty-account', {
          membershipId: m.membershipId,
          vendorId: vendor.id
        })));
        return Ember.RSVP.hash({
          accounts,
          vendor
        }).then(model => ({
          accounts: model.accounts.filter(_isSome.default),
          vendor: model.vendor
        }));
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "vendor", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = RewardsRoute;
});