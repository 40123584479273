define("mobile-web/components/r-sticky-nav/section/component", ["exports", "@ember-decorators/component", "mobile-web/lib/utilities/_", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-sticky-nav/section/style"], function (_exports, _component, _, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sectionId = sectionId;
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function sectionId(sectionElement) {
    return sectionElement.getAttribute('data-section-id');
  } // eslint-disable-next-line ember/require-tagless-components


  let StickyNavSection = (_dec = (0, _component.tagName)('div'), _dec2 = (0, _component.attribute)('data-test-stickyNav-section'), _dec3 = (0, _component.attribute)('data-section-id'), _dec4 = Ember.computed('elementId', 'title'), _dec(_class = (_class2 = class StickyNavSection extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.isHidden = false;
      this.style = _style.default;

      _initializerDefineProperty(this, "tabindex", _descriptor, this);
    }

    // needs tabindex to have a value in order to focus - see MWC-3294
    get id() {
      // Some titles have double quotes, and that breaks when doing a querySelector call.
      return Ember.String.dasherize(this.title + this.elementId).replace(/"/g, '');
    }

    get element() {
      return document.getElementById(this.elementId);
    } // Init


    init() {
      super.init();
      (false && !((0, _isSome.isSome)(this.stickyNav)) && Ember.assert('`stickyNav` is required', (0, _isSome.isSome)(this.stickyNav)));
      (false && !(!(0, _.isEmpty)(this.title)) && Ember.assert('`title` is required', !(0, _.isEmpty)(this.title)));
    } // eslint-disable-next-line ember/require-super-in-lifecycle-hooks, ember/no-component-lifecycle-hooks


    didInsertElement() {
      this.stickyNav.registerSection(this);
    } // Other methods
    // Actions


  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "tabindex", [_component.attribute], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return -1;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "id", [_dec2, _dec3, _dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "id"), _class2.prototype)), _class2)) || _class);
  _exports.default = StickyNavSection;
});