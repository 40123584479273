define("mobile-web/components/r-facebook-share-button/component", ["exports", "@glimmer/component", "mobile-web/lib/popup", "mobile-web/components/r-facebook-share-button/style"], function (_exports, _component, _popup, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const FACEBOOK_SHARE_URL = 'https://www.facebook.com/sharer/sharer.php';

  function firstHashTag(text) {
    const matches = text.match(/#\w+/);
    return matches ? matches[0] : '';
  }

  let FacebookShareButton = (_dec = Ember._action, (_class = class FacebookShareButton extends _component.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    }

    // Tracked properties
    // Getters and setters
    get quote() {
      var _this$args$quote;

      return (_this$args$quote = this.args.quote) != null ? _this$args$quote : '';
    } // Constructor
    // Other methods
    // Tasks
    // Actions


    share() {
      const hashtags = encodeURIComponent(firstHashTag(this.quote));
      const text = encodeURIComponent(this.quote);
      const url = `${FACEBOOK_SHARE_URL}?u=${this.args.link}&hashtag=${hashtags}&quote=${text}`;
      window.open(url, 'facebook', _popup.POPUP_OPTIONS);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "share", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "share"), _class.prototype)), _class));
  _exports.default = FacebookShareButton;
});