define("mobile-web/components/r-post-checkout/r-feedback-section/component", ["exports", "@ember-decorators/component", "mobile-web/lib/utilities/_", "mobile-web/components/r-post-checkout/r-feedback-section/style"], function (_exports, _component, _, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let FeedbackSection = (_dec = (0, _component.tagName)(''), _dec2 = Ember.computed('class'), _dec3 = Ember._action, _dec(_class = (_class2 = class FeedbackSection extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.class = '';
      this.style = _style.default;
    }

    get rootClass() {
      return [Ember.get(this, 'class'), _style.default.root].join(' ');
    }

    init() {
      super.init();
      (false && !((0, _.isString)(this.url)) && Ember.assert('`url` is required', (0, _.isString)(this.url)));
    }

    giveFeedback() {
      window.location.href = Ember.get(this, 'url');
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "rootClass", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "rootClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "giveFeedback", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "giveFeedback"), _class2.prototype)), _class2)) || _class);
  _exports.default = FeedbackSection;
});