define("mobile-web/serializers/billing-scheme", ["exports", "mobile-web/lib/payment", "mobile-web/serializers/base-json-serializer"], function (_exports, _payment, _baseJsonSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BillingSchemeSerializer extends _baseJsonSerializer.default {
    constructor(...args) {
      super(...args);
      this.attrs = {
        memberships: {
          embedded: 'always'
        }
      };
      this.modelName = 'billing-scheme';
    }

    normalize(modelClass, resourceHash) {
      if (resourceHash.provider === _payment.Provider.PassThrough) {
        resourceHash.memberships = [];
      }

      return super.normalize(modelClass, resourceHash);
    }

  }

  _exports.default = BillingSchemeSerializer;
});