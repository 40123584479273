define("mobile-web/components/r-header-logo/component", ["exports", "@ember-decorators/component", "mobile-web/breakpoints", "mobile-web/lib/utilities/guids", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-header-logo/style"], function (_exports, _component, _breakpoints, _guids, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let HeaderLogo = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.computed('channel.current', 'style.logo'), _dec10 = Ember.computed('channel.settings.parentSiteUrl'), _dec11 = Ember.computed('device.isWeb', 'media.isDesktop', 'media.matchers.desktop', 'session.isRestrictedFlow'), _dec(_class = (_class2 = class HeaderLogo extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.role = 'banner';
      this.class = '';
      this.useHref = false;
      this.style = _style.default;

      _initializerDefineProperty(this, "channel", _descriptor, this);

      _initializerDefineProperty(this, "cmsContent", _descriptor2, this);

      _initializerDefineProperty(this, "device", _descriptor3, this);

      _initializerDefineProperty(this, "media", _descriptor4, this);

      _initializerDefineProperty(this, "features", _descriptor5, this);

      _initializerDefineProperty(this, "session", _descriptor6, this);

      _initializerDefineProperty(this, "store", _descriptor7, this);
    }

    get componentStyles() {
      const pixels = `${_breakpoints.TABLET}px`;
      const cmsImageUrls = this.getCmsImage();
      const regImageUrl = cmsImageUrls[0] || [this.buildImageUrl('/logo/logo-narrow.svg'), this.buildImageUrl('/logo/logo-narrow.png')].join(', ');
      const tabletImageUrl = cmsImageUrls[1] || [this.buildImageUrl('/logo/logo-wide.svg'), this.buildImageUrl('/logo/logo-wide.png'), this.buildImageUrl('/logo/logo-wide-fallback.png')].join(', ');
      const logoStyles = `
      .${this.style.logo} {
        background-image: ${regImageUrl};
        max-width: 320px;
      }
      @media (min-width: ${pixels}) {
        .${this.style.logo} {
          background-image: ${tabletImageUrl};
        }
      }
      @media (max-width: ${pixels}) {
        .${this.style.logo} {
          max-width: 210px;
        }
      }
    `;
      return Ember.String.htmlSafe(logoStyles);
    }

    get href() {
      var _this$channel$setting, _this$channel$setting2;

      return (_this$channel$setting = (_this$channel$setting2 = this.channel.settings) == null ? void 0 : _this$channel$setting2.parentSiteUrl) != null ? _this$channel$setting : '/';
    }

    get ids() {
      return (0, _guids.guids)(this, 'label');
    }

    init() {
      super.init();
      (false && !((0, _isSome.default)(this.label)) && Ember.assert('`label` is required', (0, _isSome.default)(this.label)));
    } // Used to display the logo but disable the linking functionality


    get disabled() {
      return !(this.device.isWeb && this.media.isDesktop) || this.session.isRestrictedFlow;
    }

    getCmsImage() {
      var _this$cmsContent$getC, _this$cmsContent$getC2;

      const mobileUri = (_this$cmsContent$getC = this.cmsContent.getContent('logoImage')) == null ? void 0 : _this$cmsContent$getC.imageUriMobile;
      const tabletUri = (_this$cmsContent$getC2 = this.cmsContent.getContent('logoImage')) == null ? void 0 : _this$cmsContent$getC2.imageUriTablet;

      if (mobileUri || tabletUri) {
        return [`url(${mobileUri != null ? mobileUri : tabletUri})`, `url(${tabletUri != null ? tabletUri : mobileUri})`];
      }

      return '';
    }

    buildImageUrl(path) {
      if (this.channel.current) {
        return `url(${this.channel.buildCdnImageUrl(path)})`;
      }

      return '';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "channel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "cmsContent", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "device", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "media", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "features", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "session", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "componentStyles", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "componentStyles"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "href", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "href"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "disabled", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "disabled"), _class2.prototype)), _class2)) || _class);
  _exports.default = HeaderLogo;
});