define("mobile-web/instance-initializers/a11y-announcer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    const announcer = appInstance.lookup('service:announcer');
    announcer.set('announceTone', 'assertive');
  }

  var _default = {
    name: 'a11y-announcer',
    initialize
  };
  _exports.default = _default;
});