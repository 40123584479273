define("mobile-web/serializers/base-json-serializer", ["exports", "ember-data", "mobile-web/lib/utilities/is-some"], function (_exports, _emberData, _isSome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BaseJSONSerializer extends _emberData.default.JSONSerializer.extend(_emberData.default.EmbeddedRecordsMixin) {
    // This is only here to support tests
    pushPayload(store, payload) {
      (false && !((0, _isSome.default)(this.modelName)) && Ember.assert('`modelName` must be set when extending BaseJSONSerializer', (0, _isSome.default)(this.modelName)));
      const data = this.normalizeResponse(store, store.modelFor(this.modelName), payload, payload.id, 'findRecord');
      store.push(data);
    }

  }

  _exports.default = BaseJSONSerializer;
});