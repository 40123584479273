define("mobile-web/lib/payment/card", ["exports", "true-myth/maybe", "mobile-web/lib/utilities/_"], function (_exports, _maybe, _) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.detectType = detectType;
  _exports.matchBrandWithType = matchBrandWithType;
  _exports.default = _exports.expPipe = _exports.expMask = _exports.ccMask = _exports.Type = void 0;
  let Type;
  _exports.Type = Type;

  (function (Type) {
    Type["VISA"] = "VISA";
    Type["Discover"] = "Discover";
    Type["Mastercard"] = "Mastercard";
    Type["AMEX"] = "AMEX";
    Type["Unknown"] = "Unknown";
  })(Type || (_exports.Type = Type = {}));

  /** Generate an inclusive range of numbers converted to strings. */
  const rangeOfStrings = (start, end) => (0, _.range)(start, end + 1).map(v => v.toString());

  const RULES = [[Type.VISA, [['4']]], [Type.Discover, [['6011'], rangeOfStrings(622126, 622925), rangeOfStrings(644, 649), ['65'], ['8']]], [Type.Mastercard, [rangeOfStrings(50, 55), rangeOfStrings(222100, 272099)]], [Type.AMEX, [['34'], ['37']]]];

  const cardMatchesRule = card => leadingStrings => leadingStrings.some(leadingString => card.startsWith(`${leadingString}`));

  const toMatchingType = card => (matched, [type, leadingStrings]) => matched.or(leadingStrings.some(cardMatchesRule(card)) ? (0, _maybe.just)(type) : (0, _maybe.nothing)());

  function detectType(card) {
    return Ember.isNone(card) ? (0, _maybe.nothing)() : RULES.reduce(toMatchingType(card), (0, _maybe.nothing)());
  }

  function matchBrandWithType(brand) {
    switch (brand) {
      case 'visa':
        return Type.VISA;

      case 'mastercard':
        return Type.Mastercard;

      case 'amex':
        return Type.AMEX;

      case 'discover':
        return Type.Discover;

      default:
        return Type.Unknown;
    }
  } // Input Masking types for credit card forms


  const DEFAULT_CC_MASK = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
  const AMEX_CC_MASK = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/];

  const ccMask = rawValue => detectType(rawValue).match({
    Just: type => {
      if (type === Type.AMEX) {
        return AMEX_CC_MASK;
      }

      return DEFAULT_CC_MASK;
    },
    Nothing: () => DEFAULT_CC_MASK
  });

  _exports.ccMask = ccMask;

  const expMask = rawValue => {
    // These 3 special cases are to handle different autofill/paste possibilities
    if (/^\d{2}\/\d{4}$/.test(rawValue)) {
      return [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    }

    if (/^\d{1}\/\d{4}$/.test(rawValue)) {
      return ['0', /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    }

    if (/^\d{1}\/\d{2}$/.test(rawValue)) {
      return ['0', /\d/, '/', /\d/, /\d/];
    }

    return [/\d/, /\d/, '/', /\d/, /\d/];
  };

  _exports.expMask = expMask;

  const expPipe = conformedValue => {
    if (/^\d{2}\/\d{4}$/.test(conformedValue)) {
      const [month, year] = conformedValue.split('/');
      return `${month}/${year.slice(2)}`;
    }

    if (conformedValue.length === 1 && Number(conformedValue) > 1) {
      return {
        value: `0${conformedValue}`,
        indexesOfPipedChars: [0]
      };
    }

    return conformedValue;
  };

  _exports.expPipe = expPipe;
  const Card = {
    detectType,
    Type
  };
  var _default = Card;
  _exports.default = _default;
});