define("mobile-web/lib/contact-number-rules", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.applyPhoneMask = _exports.phoneMask = void 0;

  const phoneMask = rawValue => {
    if (rawValue.charAt(0) === '+' || rawValue.charAt(0) === '1') {
      return ['+', /\d/, ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }

    return ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  };

  _exports.phoneMask = phoneMask;

  const applyPhoneMask = rawValue => {
    if (rawValue.match(/^\d{10}$/)) {
      return rawValue.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '($1) $2-$3');
    } else if (rawValue.match(/^\d{11}$/)) {
      return rawValue.replace(/(\d)(\d\d\d)(\d\d\d)(\d\d\d\d)/, '+$1 ($2) $3-$4');
    }

    return rawValue;
  };

  _exports.applyPhoneMask = applyPhoneMask;
});