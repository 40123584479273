define("mobile-web/components/routes/recent-orders-route/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_dwiiec",
    "empty": "_empty_dwiiec",
    "icon": "_icon_dwiiec",
    "emptyText": "_emptyText_dwiiec",
    "emptyButton": "_emptyButton_dwiiec",
    "heading": "_heading_dwiiec"
  };
  _exports.default = _default;
});