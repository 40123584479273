define("mobile-web/serializers/product", ["exports", "mobile-web/lib/utilities/is-some", "mobile-web/serializers/application"], function (_exports, _isSome, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.normalizeDeliveryMode = normalizeDeliveryMode;
  _exports.default = void 0;

  function normalizeDeliveryMode(deliveryMode) {
    switch (deliveryMode) {
      case 'Pickup':
        return 'CounterPickup';

      case 'Curbside':
        return 'CurbsidePickup';

      case 'Delivery':
        return 'Delivery';

      case 'Dispatch':
        return 'Dispatch';

      case 'DriveThru':
        return 'DriveThru';

      case 'DineIn':
        return 'DineIn';

      default:
        return 'Unspecified';
    }
  }

  function normalizeUnavailableHandoffModes(deliveryModes) {
    return deliveryModes.map(normalizeDeliveryMode);
  }

  class ProductSerializer extends _application.default {
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if ((0, _isSome.default)(payload.optionGroups)) {
        payload.product.optionGroups = payload.optionGroups.map(g => g.id);
      }

      return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
    }

    normalize(modelClass, resourceHash, prop) {
      if ((0, _isSome.default)(resourceHash.unavailableHandoffModes)) {
        resourceHash.unavailableHandoffModes = normalizeUnavailableHandoffModes(resourceHash.unavailableHandoffModes);
      }

      return super.normalize(modelClass, resourceHash, prop);
    }

  }

  _exports.default = ProductSerializer;
});