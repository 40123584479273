define("mobile-web/components/routes/favorites-route/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_1iiw04",
    "content": "_content_1iiw04",
    "delete": "_delete_1iiw04",
    "deleteIcon": "_deleteIcon_1iiw04",
    "description": "_description_1iiw04",
    "summary": "_summary_1iiw04",
    "empty": "_empty_1iiw04",
    "icon": "_icon_1iiw04",
    "emptyText": "_emptyText_1iiw04",
    "emptyButton": "_emptyButton_1iiw04",
    "heading": "_heading_1iiw04",
    "modalHeaderDescription": "_modalHeaderDescription_1iiw04",
    "vendorLink": "_vendorLink_1iiw04",
    "buttonContainer": "_buttonContainer_1iiw04",
    "expandButton": "_expandButton_1iiw04"
  };
  _exports.default = _default;
});