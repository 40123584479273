define("mobile-web/lib/utilities/guids", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.guids = void 0;

  /**
   * @summary
   * Generate a hash of unique IDs for a component.
   *
   * @param obj the object to associate the unique IDs with
   *
   * @param keys the keys to use for additional unique IDs. `'id'` is not allowed as a key as it is set automatically.
   *
   * @returns the hash of unique IDs
   *
   * @example <caption>Each key parameter becomes a key on the hash</caption>
   *
   * guids(this, 'foo', 'bar', 'baz')
   * // {
   * //   id: 'ember123',
   * //   foo: 'ember123-foo',
   * //   bar: 'ember-123-bar',
   * //   baz: 'ember-123-baz'
   * // }
   */
  const guids = (obj, ...keys) => {
    const guid = Ember.guidFor(obj);
    const ids = {
      id: guid
    };

    for (const key of keys) {
      ids[key] = `${guid}-${key}`;
    }

    return ids;
  };

  _exports.guids = guids;
});