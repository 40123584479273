define("mobile-web/components/r-flyout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vW37B5Yo",
    "block": "[[[41,[28,[37,1],[[30,1],[30,0,[\"hasOpened\"]]],null],[[[1,\"  \"],[11,0],[16,0,[30,0,[\"overlayClass\"]]],[4,[38,2],[[30,0],\"overlayElement\"],null],[12],[1,\"\\n    \"],[11,0],[16,\"aria-labelledby\",[30,0,[\"ids\",\"heading\"]]],[16,0,[30,0,[\"style\",\"root\"]]],[4,[38,2],[[30,0],\"rootElement\"],null],[12],[1,\"\\n      \"],[11,0],[16,0,[30,0,[\"style\",\"tag\"]]],[17,2],[12],[1,\"\\n        \"],[18,3,[[28,[37,4],null,[[\"close\",\"closeId\",\"headingId\"],[[30,0,[\"close\"]],[30,0,[\"ids\",\"close\"]],[30,0,[\"ids\",\"heading\"]]]]]]],[1,\"\\n        \"],[10,0],[15,0,[30,0,[\"style\",\"content\"]]],[12],[1,\"\\n          \"],[18,4,null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[11,0],[4,[38,5],[[30,0,[\"open\"]]],null],[4,[38,6],[[30,0,[\"close\"]]],null],[12],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@isOpen\",\"&attrs\",\"&header\",\"&content\"],false,[\"if\",\"or\",\"ref\",\"yield\",\"hash\",\"did-insert\",\"will-destroy\"]]",
    "moduleName": "mobile-web/components/r-flyout/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});