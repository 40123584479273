define("mobile-web/components/r-popover-content/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "mobile-web/lib/utilities/_", "mobile-web/components/r-popover-content/style"], function (_exports, _glimmerComponent, _, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PopoverContent = (_dec = Ember._action, (_class = class PopoverContent extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    }

    get onClick() {
      var _this$args$onClick;

      return (_this$args$onClick = this.args.onClick) != null ? _this$args$onClick : _.noop;
    }

    get uniqueId() {
      return Ember.guidFor(this);
    }

    didInsert() {
      Ember.run.later(this, this.positionArrow, 1);
    }

    positionArrow() {
      const ARROW_WIDTH = 16; // hard-coded copy of value (`$spacing` * 2) from SCSS
      // We can't do anything if the content or arrow elements don't exist. (They
      // always should, but better to do nothing than to trigger an error.)

      const contentEl = document.querySelector(`[data-popover-content="${this.uniqueId}"]`);
      const arrowEl = document.querySelector(`[data-popover-content-arrow="${this.uniqueId}"]`);

      if (Ember.isNone(contentEl) || Ember.isNone(arrowEl)) {
        return;
      }

      const triggerCenter = this.args.target.offsetWidth / 2;
      const triggerLeftEdge = this.args.target.getBoundingClientRect().left;
      const contentLeftEdge = contentEl.getBoundingClientRect().left;
      const leftEdgeDiff = contentLeftEdge < triggerLeftEdge ? triggerLeftEdge - contentLeftEdge : 0;
      const offset = leftEdgeDiff + triggerCenter - ARROW_WIDTH / 2;
      arrowEl.setAttribute('style', `left: ${offset}px;`);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "didInsert", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype)), _class));
  _exports.default = PopoverContent;
});