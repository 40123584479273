define("mobile-web/templates/open-source-licenses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4vVyQ2is",
    "block": "[[[8,[39,0],null,[[\"@licenses\"],[[30,0,[\"model\"]]]],null]],[],false,[\"routes/open-source-licenses-route\"]]",
    "moduleName": "mobile-web/templates/open-source-licenses.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});