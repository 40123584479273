define("mobile-web/components/r-sign-in-create-account-form/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "_root_1wsd0t",
    "modeSwitcher": "_modeSwitcher_1wsd0t"
  };
  _exports.default = _default;
});