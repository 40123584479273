define("mobile-web/services/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LoadingService = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = class LoadingService extends Ember.Service {
    get isAuth() {
      const isLogin = this.matches('secure.login', url => url.startsWith('/login'));
      return isLogin || this.isAuthCheckout || this.isAuthCreateAccount;
    }

    get isAuthCheckout() {
      return this.matches('secure.checkout.auth', '/checkout/auth');
    }

    get isAuthCreateAccount() {
      return this.matches('secure.signup', '/login', 'mode=1');
    }

    get isCheckout() {
      return this.matches('secure.checkout', '/checkout');
    }

    get isLocations() {
      return this.matches('locations', '/locations');
    }

    get isMenu() {
      const m = s => s.includes('menu') && !s.includes('reward');

      return this.matches(m, m);
    }

    get isMenuProduct() {
      const m = s => s.includes('products');

      return this.matches(m, m);
    }

    get isMenuViewAll() {
      return this.matches('menu.category', url => url.includes('categories'));
    }

    get isMyAccount() {
      return this.matches('secure.my-account', '/my-account');
    }

    get isPassword() {
      return this.isPasswordChange || this.isPasswordForgot || this.isPasswordReset;
    }

    get isPasswordChange() {
      return this.matches('secure.my-account.change-password', '/my-account/change-password');
    }

    get isPasswordForgot() {
      return this.matches('forgot-password', '/forgot-password');
    }

    get isPasswordReset() {
      return this.matches('secure.reset-password', '/resetpassword');
    }

    get isSearch() {
      return this.matches(to => ['favorites', 'recent-orders', 'region-results', 'rewards-search', 'vendor-search-results', 'vendor.rewards'].includes(to), url => ['/favorites', '/locations', '/recent-orders', '/rewards', '/search'].some(s => url.includes(s)));
    }

    get isSplash() {
      return this.router.currentRouteName === 'index';
    }

    get isThankYou() {
      return this.matches(to => ['order-summary', 'thank-you'].includes(to), url => ['/orders', '/thank-you'].some(s => url.startsWith(s)));
    }

    constructor(properties) {
      super(properties);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "transition", _descriptor2, this);

      this.router.on('routeWillChange', this.transitioning);
    }

    matches(transitionToMatch, urlToMatch, queryParamsToMatch) {
      if (this.transition) {
        return typeof transitionToMatch === 'string' ? this.transition.to.name === transitionToMatch : transitionToMatch(this.transition.to.name);
      }

      if (!this.router.currentURL) {
        return false;
      }

      if (typeof urlToMatch === 'string') {
        const urlParts = this.router.currentURL.split('?');
        return urlParts[0] === urlToMatch && (!queryParamsToMatch || urlParts[1] === queryParamsToMatch);
      }

      return urlToMatch(this.router.currentURL);
    }

    transitioning(transition) {
      // Types incorrectly say that `to` is always defined.
      // Checking here means we need not check again in getter
      if (transition.to && transition.to.name !== 'application_loading') {
        this.transition = transition;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "transition", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "transitioning", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "transitioning"), _class.prototype)), _class));
  _exports.default = LoadingService;
});