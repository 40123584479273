define("mobile-web/components/r-post-checkout/r-handoff-message/component", ["exports", "@ember-decorators/component", "mobile-web/lib/utilities/is-some", "mobile-web/components/r-post-checkout/r-handoff-message/style"], function (_exports, _component, _isSome, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let HandoffMessage = (_dec = (0, _component.tagName)(''), _dec(_class = class HandoffMessage extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    }

    // Computed properties
    // Init
    init() {
      super.init();
      (false && !((0, _isSome.default)(this.handoffLabel)) && Ember.assert('`handoffLabel` is required', (0, _isSome.default)(this.handoffLabel)));
      (false && !((0, _isSome.default)(this.handoffInstructions)) && Ember.assert('`handoffInstructions` is required', (0, _isSome.default)(this.handoffInstructions)));
      (false && !((0, _isSome.default)(this.isPastOrder)) && Ember.assert('`isPastOrder` is required', (0, _isSome.default)(this.isPastOrder)));
      (false && !((0, _isSome.default)(this.isPendingManualFire)) && Ember.assert('`isPendingManualFire` is required', (0, _isSome.default)(this.isPendingManualFire)));
      (false && !((0, _isSome.default)(this.canSupportArrival)) && Ember.assert('`canSupportArrival` is required', (0, _isSome.default)(this.canSupportArrival)));
      (false && !((0, _isSome.default)(this.isDriveThru)) && Ember.assert('`isDriveThru` is required', (0, _isSome.default)(this.isDriveThru)));
      (false && !((0, _isSome.default)(this.substitutions)) && Ember.assert('`substitutions` is required', (0, _isSome.default)(this.substitutions)));
    } // Other methods
    // Actions


  }) || _class);
  _exports.default = HandoffMessage;
});