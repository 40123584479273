define("mobile-web/services/user-feedback", ["exports", "ember-object-update", "mobile-web/lib/utilities/_"], function (_exports, _emberObjectUpdate, _2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ICONS = _exports.Type = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Type;
  _exports.Type = Type;

  (function (Type) {
    Type["Neutral"] = "neutral";
    Type["Positive"] = "positive";
    Type["Warning"] = "warning";
    Type["Negative"] = "negative";
  })(Type || (_exports.Type = Type = {}));

  var Icon;

  (function (Icon) {
    Icon["Info"] = "r-info";
    Icon["Exclamation"] = "r-exclamation";
    Icon["Cancel"] = "r-cancel";
    Icon["Check"] = "r-checkmark";
  })(Icon || (Icon = {}));

  const ICONS = {
    [Type.Neutral]: Icon.Info,
    [Type.Negative]: Icon.Cancel,
    [Type.Positive]: Icon.Check,
    [Type.Warning]: Icon.Exclamation
  };
  _exports.ICONS = ICONS;
  let UserFeedback = (_dec = Ember.computed.readOnly('_stack'), _dec2 = Ember.computed.or('isDestroying', 'isDestroyed'), (_class = class UserFeedback extends Ember.Service {
    constructor(...args) {
      super(...args);
      this._stack = [];

      _initializerDefineProperty(this, "messages", _descriptor, this);

      _initializerDefineProperty(this, "tearingDown", _descriptor2, this);
    }

    add({
      type,
      title,
      message,
      actions = []
    }) {
      return !this.tearingDown ? (0, _emberObjectUpdate.default)(this, '_stack', m => (0, _2.uniqBy)(m.concat({
        type,
        title,
        message,
        actions
      }), 'message')) : this._stack;
    }

    clear(message) {
      if (!this.tearingDown) {
        (0, _emberObjectUpdate.default)(this, '_stack', stack => (0, _2.filter)(stack, m => !(0, _2.isEqual)(m, message)));
      }
    }

    clearAll() {
      if (!this.tearingDown) {
        (0, _emberObjectUpdate.default)(this, '_stack', _ => []);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "messages", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "tearingDown", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = UserFeedback;
});