define("mobile-web/lib/vendor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VendorResultRoutes = void 0;
  let VendorResultRoutes;
  _exports.VendorResultRoutes = VendorResultRoutes;

  (function (VendorResultRoutes) {
    VendorResultRoutes["Menu"] = "menu.vendor";
    VendorResultRoutes["Rewards"] = "menu.vendor.rewards";
  })(VendorResultRoutes || (_exports.VendorResultRoutes = VendorResultRoutes = {}));
});