define("mobile-web/components/r-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zGIy/wY5",
    "block": "[[[11,\"button\"],[16,\"aria-disabled\",[52,[28,[37,1],[[30,1],\"softDisabled\"],null],\"true\"]],[16,0,[30,0,[\"rootClass\"]]],[16,\"data-test-button\",[30,0,[\"dataTestButton\"]]],[17,2],[4,[38,2],[\"click\",[30,0,[\"onClick\"]]],null],[12],[1,\"\\n  \"],[10,1],[15,0,[28,[37,3],[[30,3],[30,0,[\"style\",\"text\"]]],null]],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"      \"],[1,[30,4]],[1,\"\\n\"]],[]],[[[1,\"      \"],[18,9,null],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[41,[28,[37,5],[[30,5],0],null],[[[1,\"    \"],[10,1],[14,\"data-test-button-highlight\",\"\"],[15,0,[30,0,[\"style\",\"highlight\"]]],[12],[1,\"\\n\"],[41,[30,6],[[[1,\"        \"],[10,1],[12],[1,[28,[35,6],[[30,5]],null]],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,7],[[16,0,[30,0,[\"style\",\"animatedContainer\"]]]],null,[[\"default\"],[[[[1,\"\\n\"],[6,[39,8],null,[[\"value\",\"rules\",\"duration\"],[[30,5],[30,0,[\"rules\"]],[30,0,[\"duration\"]]]],[[\"default\"],[[[[1,\"            \"],[10,1],[12],[1,[28,[35,6],[[30,7]],null]],[13],[1,\"\\n\"]],[7]]]]],[1,\"        \"]],[]]]]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[41,[30,8],[[[1,\"    \"],[46,[30,8],null,[[\"class\"],[[28,[37,3],[[30,0,[\"style\",\"newIconFormat\"]],[30,0,[\"iconClass\"]]],null]]],null],[1,\"\\n\"]],[]],null],[13]],[\"@variant\",\"&attrs\",\"@textClass\",\"@label\",\"@highlightContent\",\"@disableAnimation\",\"v\",\"@icon\",\"&default\"],false,[\"if\",\"eq\",\"on\",\"classes\",\"yield\",\"gte\",\"format-currency\",\"animated-container\",\"animated-value\",\"component\"]]",
    "moduleName": "mobile-web/components/r-button/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});