define("mobile-web/components/r-popover/component", ["exports", "@glint/environment-ember-loose/glimmer-component", "mobile-web/components/r-popover/style"], function (_exports, _glimmerComponent, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let Popover = (_dec = Ember._action, (_class = class Popover extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;
    }

    get uniqueId() {
      return Ember.guidFor(this);
    } // workaround for the fact that ember-basic-dropdown doesn't let us set
    // arbitrary `data-` attributes on it


    get triggerSelectorClass() {
      return `popover-trigger-${this.uniqueId}`;
    }

    didInsert() {
      var _document$querySelect;

      const el = (_document$querySelect = document.querySelector(`.${this.triggerSelectorClass}`)) == null ? void 0 : _document$querySelect.firstElementChild;

      if (el) {
        Ember.set(this, 'triggerEl', el);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "didInsert", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype)), _class));
  _exports.default = Popover;
});