define("mobile-web/components/r-expandable-text/component", ["exports", "@glimmer/component", "mobile-web/components/r-expandable-text/style"], function (_exports, _component, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExpandableText = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class ExpandableText extends _component.default {
    constructor(...args) {
      super(...args);
      this.style = _style.default;

      _initializerDefineProperty(this, "isCollapsed", _descriptor, this);

      _initializerDefineProperty(this, "contentElement", _descriptor2, this);
    }

    // Getters and setters
    get contentHeight() {
      var _this$contentElement$, _this$contentElement;

      return (_this$contentElement$ = (_this$contentElement = this.contentElement) == null ? void 0 : _this$contentElement.scrollHeight) != null ? _this$contentElement$ : 0;
    }

    get maxHeight() {
      var _this$args$maxHeight;

      return (_this$args$maxHeight = this.args.maxHeight) != null ? _this$args$maxHeight : 80;
    }

    get contentStyle() {
      return !this.args.collapsible ? undefined : this.isCollapsed ? Ember.String.htmlSafe(`max-height: ${this.maxHeight}px;`) : Ember.String.htmlSafe(`max-height: ${this.contentHeight}px;`);
    }

    get showExpand() {
      return !!this.args.collapsible && !!this.contentElement && this.isOverflowing;
    }

    get isOverflowing() {
      var _this$contentElement$2, _this$contentElement2;

      return ((_this$contentElement$2 = (_this$contentElement2 = this.contentElement) == null ? void 0 : _this$contentElement2.scrollHeight) != null ? _this$contentElement$2 : 0) > this.maxHeight;
    } // Constructor
    // Other methods
    // Tasks
    // Actions


    setup(el) {
      window.addEventListener('resize', this.resize);
      this.contentElement = el;

      if (this.args.collapsible) {
        this.isCollapsed = this.isOverflowing;
      }
    }

    teardown() {
      window.removeEventListener('resize', this.resize);
    }

    resize() {
      this.contentElement = this.contentElement; // eslint-disable-line no-self-assign
    }

    update() {
      this.resize();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isCollapsed", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      var _this$args$collapsibl;

      return (_this$args$collapsibl = this.args.collapsible) != null ? _this$args$collapsibl : false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "contentElement", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "teardown", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "teardown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resize", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "resize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "update", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype)), _class));
  _exports.default = ExpandableText;
});