define("mobile-web/helpers/data/is-error", ["exports", "mobile-web/lib/data"], function (_exports, _data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dataIsError = dataIsError;
  _exports.default = void 0;

  function dataIsError(params) {
    (false && !(!Ember.isEmpty(params)) && Ember.assert('a Data property must be passed to `dataIsError`', !Ember.isEmpty(params)));
    return (0, _data.isError)(params[0]);
  }

  var _default = Ember.Helper.helper(dataIsError);

  _exports.default = _default;
});