define("mobile-web/templates/application-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XeW/Frl7",
    "block": "[[[8,[39,0],null,[[\"@isLoading\"],[true]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,null,null],[1,\"\\n\"]],[]]]]]],[],false,[\"r-application-frame\",\"r-application-loading\"]]",
    "moduleName": "mobile-web/templates/application-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});