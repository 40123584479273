define("mobile-web/adapters/category", ["exports", "ember-data-url-templates", "mobile-web/lib/hybrid-util", "mobile-web/adapters/application"], function (_exports, _emberDataUrlTemplates, _hybridUtil, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CategoryAdapter = (_dec = Ember.inject.service, (_class = class CategoryAdapter extends _application.default.extend(_emberDataUrlTemplates.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "vendor", _descriptor, this);

      this.coalesceFindRequests = true;
      this.urlTemplate = (0, _hybridUtil.host)() + '/{+namespace}/vendors/{vendorId}/categories/{categoryId}';
      this.urlSegments = {
        vendorId(_type, _id, snapshot) {
          var _vendor$record$get;

          const record = 'length' in snapshot ? snapshot[0] : snapshot;
          const vendor = record.belongsTo('vendor');

          if (Ember.isNone(vendor) && Ember.isNone(this.vendor.vendor)) {
            throw new Error('Vendor Id for Category Not Found');
          } // The line above errors if the conditions here don't hold; `!` is safe
          // accordingly.


          return (_vendor$record$get = vendor == null ? void 0 : vendor.record.get('id')) != null ? _vendor$record$get : this.vendor.vendor.id;
        },

        categoryId(_type, _id, snapshot) {
          return snapshot.id;
        },

        namespace() {
          return this.namespace;
        }

      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "vendor", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CategoryAdapter;
});