define("mobile-web/components/r-menu-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ujLnT+pJ",
    "block": "[[[8,[39,0],[[24,\"data-test-menuButton\",\"\"]],[[\"@label\",\"@showLabel\",\"@icon\",\"@onClick\"],[[28,[37,1],null,[[\"text\"],[[28,[37,2],[\"mwc.header.menuAltText\"],null]]]],false,[28,[37,1],null,[[\"svg\",\"ariaLabel\",\"position\"],[\"hamburger\",\"\",\"left\"]]],[30,0,[\"onClick\"]]]],null]],[],false,[\"r-header-button\",\"hash\",\"t\"]]",
    "moduleName": "mobile-web/components/r-menu-button/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});