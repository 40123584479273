define("mobile-web/components/r-checkout/tip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TMDYab3h",
    "block": "[[[41,[28,[37,1],[[30,1,[\"length\"]],1],null],[[[1,\"  \"],[8,[39,2],[[16,0,[30,0,[\"style\",\"methodContainer\"]]]],[[\"@name\",\"@header\"],[\"Tip Method\",[28,[37,3],[\"mwc.checkout.tipMethodHeader\"],null]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"filteredMethods\"]]],null]],null],null,[[[1,\"      \"],[8,[39,6],null,[[\"@name\",\"@label\",\"@class\",\"@checked\",\"@onChange\"],[\"tipMethod\",[30,2,[\"description\"]],[30,0,[\"style\",\"method\"]],[30,2,[\"includesTip\"]],[28,[37,7],[[30,0,[\"updateMethod\"]],[30,2]],null]]],null],[1,\"\\n\"]],[2]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[]],null],[8,[39,2],null,[[\"@name\",\"@header\",\"@description\"],[\"Tip\",[28,[37,3],[\"mwc.checkout.tipHeader\"],null],[52,[30,0,[\"isDelivery\"]],[28,[37,3],[\"mwc.checkout.driverTip\"],null],[28,[37,3],[\"mwc.checkout.staffTip\"],null]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,8],null,[[\"@name\",\"@segments\",\"@onChange\",\"@value\",\"@class\"],[\"percentages\",[30,0,[\"percentages\"]],[30,0,[\"setPercentage\"]],[30,0,[\"percentage\"]],[30,0,[\"style\",\"percentages\"]]]],null],[1,\"\\n  \"],[8,[39,9],null,[[\"@name\",\"@label\",\"@inputValue\",\"@onInput\"],[\"amount\",[28,[37,3],[\"mwc.checkout.tipAmountLabel\"],null],[30,0,[\"amount\"]],[30,0,[\"amountChanged\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@methods\",\"method\"],false,[\"if\",\"gt\",\"r-editable-section\",\"t\",\"each\",\"-track-array\",\"r-radio\",\"fn\",\"r-segmented-control\",\"r-money-form-field\"]]",
    "moduleName": "mobile-web/components/r-checkout/tip/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});