define("mobile-web/services/global-data", ["exports", "mobile-web/lib/utilities/_"], function (_exports, _) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ProductClickFrom = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProductClickFrom;
  _exports.ProductClickFrom = ProductClickFrom;

  (function (ProductClickFrom) {
    ProductClickFrom["CartUpsell"] = "cart-upsell";
    ProductClickFrom["Category"] = "category";
    ProductClickFrom["VendorMenu"] = "vendor-menu";
  })(ProductClickFrom || (_exports.ProductClickFrom = ProductClickFrom = {}));

  function deepFreeze(obj) {
    if (!obj) return undefined; // Retrieve the property names defined on object

    const propNames = Object.getOwnPropertyNames(obj); // Freeze properties before freezing self

    for (const name of propNames) {
      const value = obj[name];

      if (value && typeof value === 'object') {
        deepFreeze(value);
      }
    }

    return Object.freeze(obj);
  }

  let GlobalDataService = (_dec = Ember.inject.service('basket'), _dec2 = Ember.inject.service('vendor'), _dec3 = Ember.inject.service('router'), _dec4 = Ember.inject.service('error'), _dec5 = Ember.inject.service('session'), _dec6 = Ember.inject.service('device'), _dec7 = Ember.inject.service, (_class = class GlobalDataService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "basketService", _descriptor, this);

      _initializerDefineProperty(this, "vendorService", _descriptor2, this);

      _initializerDefineProperty(this, "routerService", _descriptor3, this);

      _initializerDefineProperty(this, "errorService", _descriptor4, this);

      _initializerDefineProperty(this, "sessionService", _descriptor5, this);

      _initializerDefineProperty(this, "deviceService", _descriptor6, this);

      _initializerDefineProperty(this, "store", _descriptor7, this);

      this.initialized = false;
    }

    updateVendor() {
      var _this$vendorService, _this$vendorService$v;

      this.updateData('vendor', deepFreeze((_this$vendorService = this.vendorService) == null ? void 0 : (_this$vendorService$v = _this$vendorService.vendor) == null ? void 0 : _this$vendorService$v.serializeForGlobalData()));
    }

    updateBasket() {
      var _this$basketService, _this$basketService$b;

      this.updateData('basket', deepFreeze((_this$basketService = this.basketService) == null ? void 0 : (_this$basketService$b = _this$basketService.basket) == null ? void 0 : _this$basketService$b.serializeForGlobalData()));
    }

    updateUser() {
      var _this$sessionService;

      this.updateData('user', deepFreeze((_this$sessionService = this.sessionService) == null ? void 0 : _this$sessionService.serializeUserForGlobalData()));
    }

    updateDevice() {
      var _this$deviceService;

      this.updateData('device', deepFreeze((_this$deviceService = this.deviceService) == null ? void 0 : _this$deviceService.serializeDeviceForGlobalData()));
    }
    /**
     * Update the product object by pulling data from the store.
     */


    updateProduct() {
      const {
        name,
        paramNames,
        params
      } = this.routerService.currentRoute || {};

      if (name === 'menu.vendor.products' && paramNames.includes('product_id')) {
        try {
          const product = this.store.peekRecord('product', params.product_id);
          this.updateData('product', product ? deepFreeze(product.serializeForGlobalData()) : undefined);
        } catch (e) {
          this.updateData('product', undefined);
          this.errorService.sendExternalError(e);
        }
      } else {
        this.updateData('product', undefined);
      }
    }
    /**
     * Update the order object by pulling data from the store.
     */


    updateOrder() {
      const {
        name,
        paramNames,
        params
      } = this.routerService.currentRoute || {};

      if (name === 'thank-you' && paramNames.includes('order_id')) {
        try {
          const order = this.store.peekRecord('order', params.order_id);
          this.updateData('order', order ? deepFreeze(order.serializeForGlobalData()) : undefined);
        } catch (e) {
          this.updateData('order', undefined);
          this.errorService.sendExternalError(e);
        }
      } else {
        this.updateData('order', undefined);
      }
    }

    get data() {
      return window.Olo.data;
    }

    set data(data) {
      window.Olo = window.Olo || {};
      window.Olo.data = data;
    }

    /**
     * Create a sealed data object so that the shape of it cannot be modified.
     * Listen for changes to services and then update the data.
     */
    setup() {
      if (this.initialized) {
        return;
      }

      this.initialized = true;
      this.updateData({
        basket: undefined,
        vendor: undefined,
        product: undefined,
        order: undefined,
        device: undefined,
        user: undefined
      });
      this.setupVendorObservers();
      this.setupProductObservers();
      this.setupOrderObservers();
      this.setupBasketObservers();
      this.setupSessionObservers();
      this.updateDevice();
    }

    setupVendorObservers() {
      this.vendorService.addObserver('vendor', this, this.updateVendor); // eslint-disable-line ember/no-observers

      this.updateVendor();
    }

    setupProductObservers() {
      this.routerService.on('routeDidChange', this.updateProduct.bind(this));
      this.updateProduct();
    }

    setupOrderObservers() {
      this.routerService.on('routeDidChange', this.updateOrder.bind(this));
      this.updateOrder();
    }
    /**
     * Listen for changes to the basket or its products.
     * Because the `basketProducts` array can be nuked, each time the basket itself changes
     * we need to remove and re-add listeners to the last known instance of `basketProducts`.
     */


    setupBasketObservers() {
      let lastBasketProducts; // Options to use when observing basket product array changes.

      const opts = {
        willChange: _.noop,
        didChange: this.updateBasket.bind(this)
      }; // When the basket updates, remove and re-add observers to basketProducts since
      // the array _could_ have been completely removed and replaced.

      const updateBasket = () => {
        var _this$basketService$b2;

        if (lastBasketProducts) lastBasketProducts.removeArrayObserver(this, opts);
        lastBasketProducts = (_this$basketService$b2 = this.basketService.basket) == null ? void 0 : _this$basketService$b2.basketProducts.addArrayObserver(this, opts);
        this.updateBasket();
      };

      this.basketService.addObserver('basket', this, updateBasket); // eslint-disable-line ember/no-observers

      updateBasket();
    }

    setupSessionObservers() {
      // Observers don't work on plain getters that depend on tracked properties;
      // we have to observe the dependent tracked properties directly
      this.sessionService.addObserver('currentUser', this, this.updateUser); // eslint-disable-line ember/no-observers

      this.sessionService.addObserver('localGuestUser', this, this.updateUser); // eslint-disable-line ember/no-observers

      this.updateUser();
    }
    /**
     * Update data on the window.
     * Tried doing this in a less verbose way by adding the `@tracked` annotation to
     * these properties and using the `@observes` decorator, but this causes the whole application
     * to take longer to initialize for some reason and that breaks all sorts of tests.
     */


    updateData(...args) {
      const vals = args.length === 1 ? args[0] : {
        [args[0]]: args[1]
      };
      this.data = Object.seal(_extends({}, this.data, vals)); // eslint-disable-next-line ember/classic-decorator-no-classic-methods

      this.notifyPropertyChange('data');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "basketService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "vendorService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "routerService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "errorService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sessionService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "deviceService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GlobalDataService;
});