define("mobile-web/lib/order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.handoffMessageParts = void 0;

  const handoffMessageParts = (order, dateTime) => {
    const handoff = order.handoffDescription.toLocaleLowerCase();
    const tenseVerb = order.isPastOrder ? 'was' : 'is';

    if (order.isDelivery) {
      return order.isAdvance ? {
        prefix: `Your ${handoff} order ${tenseVerb} scheduled to arrive`,
        dateTime
      } : {
        prefix: `Your ${handoff} order ${tenseVerb} estimated to arrive`,
        dateTime
      };
    }

    return {
      prefix: `Your order ${order.isPastOrder ? 'was' : 'will be'} ready for ${handoff} at`,
      dateTime
    };
  };

  _exports.handoffMessageParts = handoffMessageParts;
});