define("mobile-web/components/r-create-account-form/component", ["exports", "@glimmer/component", "ember-concurrency-ts", "mobile-web/adapters/account", "mobile-web/components/r-static-content-modal/component", "mobile-web/lib/contact-number-rules", "mobile-web/lib/errors", "mobile-web/lib/strings", "mobile-web/lib/utilities/_", "mobile-web/lib/validation", "mobile-web/services/analytics", "mobile-web/components/r-create-account-form/style"], function (_exports, _component, _emberConcurrencyTs, _account, _component2, _contactNumberRules, _errors, _strings, _, _validation, _analytics, _style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _class3, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Model = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, (_class = class Model {
    constructor() {
      _initializerDefineProperty(this, "firstName", _descriptor, this);

      _initializerDefineProperty(this, "lastName", _descriptor2, this);

      _initializerDefineProperty(this, "email", _descriptor3, this);

      _initializerDefineProperty(this, "contactNumber", _descriptor4, this);

      _initializerDefineProperty(this, "password", _descriptor5, this);

      _initializerDefineProperty(this, "confirmPassword", _descriptor6, this);

      _initializerDefineProperty(this, "acceptedTerms", _descriptor7, this);

      _initializerDefineProperty(this, "lastOrderId", _descriptor8, this);

      _initializerDefineProperty(this, "optIn", _descriptor9, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "firstName", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "lastName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "email", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "contactNumber", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "password", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "confirmPassword", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "acceptedTerms", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "lastOrderId", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "optIn", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  })), _class));
  const VALIDATION_CONFIG = {
    bindings: [{
      targetProp: 'acceptedTerms',
      ruleName: 'truthful',
      message: 'Please check the Terms & Conditions box'
    }, {
      targetProp: 'firstName',
      ruleName: 'notBlank',
      message: 'First name must be set'
    }, {
      targetProp: 'lastName',
      ruleName: 'notBlank',
      message: 'Last name must be set'
    }, {
      targetProp: 'email',
      ruleName: 'email',
      message: 'Email address must be set and valid'
    }, {
      targetProp: 'contactNumber',
      ruleName: 'phone',
      message: 'Phone number must be set and valid'
    }, {
      targetProp: 'password',
      ruleName: 'minLength',
      message: _strings.VALIDATION_PASSWORD_LENGTH
    }]
  };
  let CreateAccountForm = (_dec10 = Ember.inject.service, _dec11 = Ember.inject.service, _dec12 = Ember.inject.service, _dec13 = Ember.inject.service, _dec14 = Ember.inject.service, _dec15 = Ember.inject.service, _dec16 = Ember.inject.service, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember.computed('channel.currentCountry'), _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, (_class3 = class CreateAccountForm extends _component.default {
    // Service injections
    // Passthroughs
    // Tracked properties
    // Getters and setters
    get displayUpgradeForm() {
      return !!this.args.lastOrderId && !!this.args.isUpgrade && !!this.args.guestUser;
    }

    get _currentCountry() {
      return this.channel.currentCountry;
    } // eslint-disable-next-line @typescript-eslint/no-empty-function


    set _currentCountry(_value) {}

    get displayCountrySelector() {
      return this.channel.countries.length > 1;
    }

    get headingLevel() {
      var _this$args$headingLev;

      return (_this$args$headingLev = this.args.headingLevel) != null ? _this$args$headingLev : 1;
    }

    get isSubmitDisabled() {
      const model = this.model;
      return !model.acceptedTerms || !model.firstName || !model.lastName || !model.email || !model.contactNumber || !model.password;
    } // Constructor


    constructor(owner, args) {
      var _this$args$guestUser, _this$args$guestUser$, _this$args$guestUser2, _this$args$optIn;

      super(owner, args);

      _initializerDefineProperty(this, "bus", _descriptor10, this);

      _initializerDefineProperty(this, "challenge", _descriptor11, this);

      _initializerDefineProperty(this, "channel", _descriptor12, this);

      _initializerDefineProperty(this, "session", _descriptor13, this);

      _initializerDefineProperty(this, "store", _descriptor14, this);

      _initializerDefineProperty(this, "analytics", _descriptor15, this);

      _initializerDefineProperty(this, "error", _descriptor16, this);

      this.phoneMask = _contactNumberRules.phoneMask;
      this.style = _style.default;

      _initializerDefineProperty(this, "model", _descriptor17, this);

      _initializerDefineProperty(this, "validationResult", _descriptor18, this);

      const model = new Model();
      Object.assign(model, (0, _.pick)((_this$args$guestUser = this.args.guestUser) != null ? _this$args$guestUser : {}, 'firstName', 'lastName', 'contactNumber'));
      model.email = (_this$args$guestUser$ = (_this$args$guestUser2 = this.args.guestUser) == null ? void 0 : _this$args$guestUser2.emailAddress) != null ? _this$args$guestUser$ : '';
      model.lastOrderId = this.args.lastOrderId;
      model.optIn = (_this$args$optIn = this.args.optIn) != null ? _this$args$optIn : false;
      this.model = model;
    } // Other methods
    // Tasks
    // Actions


    showTerms() {
      this.bus.trigger('showStaticContent', {
        type: _component2.StaticContentType.USER_AGREEMENT,
        buttonLabel: 'Acknowledge Terms',
        onButtonClick: () => {
          this.model.acceptedTerms = true;
        }
      });
    }

    submit(e) {
      e.preventDefault();
      this.validationResult = _validation.default.validate(this.model, VALIDATION_CONFIG);

      if (this.validationResult.isErr()) {
        return;
      }

      const model = this.model;
      model.confirmPassword = model.password;
      const adapter = this.store.adapterFor('account');
      const userAction = this.displayUpgradeForm ? _account.AccountAction.Upgrade : _account.AccountAction.Register;
      (0, _emberConcurrencyTs.taskFor)(this.challenge.request).perform(() => adapter[userAction](model).then(async response => {
        const {
          user
        } = response;
        await this.session.setUserFromPayload(user);

        try {
          await this.analytics.trackEvent(_analytics.AnalyticsEvents.CreateAccount);
        } catch (err) {
          this.error.sendExternalError(err);
        }

        return this.args.onSubmit();
      }), (0, _errors.buildOloErrorHandler)('register-submit-error', error => {
        this.args.onSubmitError(error);
      }));
    }

    handleCountryChange(code) {
      this.store.adapterFor('country').post(code).then(() => {
        var _this$channel$country, _this$channel$country2;

        this.channel.set('currentCountry', code);
        const optIn = (_this$channel$country = (_this$channel$country2 = this.channel.countrySettings[code]) == null ? void 0 : _this$channel$country2.optIn) != null ? _this$channel$country : false;
        this.model.optIn = optIn;
      }).catch((0, _errors.buildOloErrorHandler)('set-country-error', () => ({})));
    }

  }, (_descriptor10 = _applyDecoratedDescriptor(_class3.prototype, "bus", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class3.prototype, "challenge", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class3.prototype, "channel", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class3.prototype, "session", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class3.prototype, "store", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class3.prototype, "analytics", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class3.prototype, "error", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class3.prototype, "model", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class3.prototype, "validationResult", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "_currentCountry", [_dec19], Object.getOwnPropertyDescriptor(_class3.prototype, "_currentCountry"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "showTerms", [_dec20], Object.getOwnPropertyDescriptor(_class3.prototype, "showTerms"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "submit", [_dec21], Object.getOwnPropertyDescriptor(_class3.prototype, "submit"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "handleCountryChange", [_dec22], Object.getOwnPropertyDescriptor(_class3.prototype, "handleCountryChange"), _class3.prototype)), _class3));
  _exports.default = CreateAccountForm;
});